import { gql } from "@apollo/client";

import { ListWidget } from "../list";

export const EventV2ApplicationListWidget = ({ obj, filter, ...props }) => {
  return (
    <ListWidget
      resource="eventV2Application"
      fields={[
        {
          name: "member",
          type: "object",
          resource: "member",
          filterKey: "member_Overlap",
        },
        {
          name: "internalState",
          labelKey: "admin.field.applicationState",
          type: "choice",
          enumType: "EventV2ApplicationState",
          filterKey: "internalState_Overlap",
        },
        {
          name: "name",
          labelKey: "admin.field.name",
          filterKey: "name_Text",
          getter: (obj) => obj.name || obj.member.user.name || "-",
        },
        {
          name: "birthDate",
          type: "date",
          labelKey: "admin.field.birthDate",
        },
        {
          name: "premiumMembershipIdentifier",
          labelKey: "admin.field.membershipIdentifier",
          filterKey: "premiumMembershipIdentifier_Text",
          getter: (obj) => obj.premiumMembershipIdentifier || "-",
        },
        {
          name: "phone",
          type: "phone",
          labelKey: "admin.field.phone",
          getter: (obj) => obj.phone || obj.member.user.phone || "-",
        },
        {
          name: "memberPenaltyCount",
          labelKey: "admin.field.penaltyCount",
          type: "integer",
        },
        {
          name: "member.isActive",
          labelKey: "admin.field.memberIsActive",
          type: "boolean",
        },
        {
          name: "createdAt",
          labelKey: "admin.field.appliedAt",
          type: "datetime",
          sortKey: "CREATED_AT",
        },
      ]}
      query={QUERY}
      filter={filter}
      {...props}
    />
  );
};

// ==

const QUERY = gql`
  query eventV2ApplicationsForEventV2ApplicationListWidget(
    $filter: EventV2ApplicationFilterInput
    $sort: [EventV2ApplicationSortInput]
    $page: PageInput
  ) {
    eventV2Applications(filter: $filter, sort: $sort, page: $page) {
      objects {
        id
        member {
          id
          nickname
          user {
            id
            name
            phone
            email
          }
          isActive
        }
        event {
          id
          submissionCategory
        }
        state
        internalState
        premiumMembershipIdentifier
        name
        phone
        email
        birthDate
        memberPenaltyCount
        isActive
        ...TrackedFields
      }
      ...ListFields
    }
  }
  ${ListWidget.fragments.list}
  ${ListWidget.fragments.tracked}
`;
