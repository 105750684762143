import { useState } from "react";

import { resolveCallable } from "../utils/helpers";

export const useLocalStorage = (key, initial) => {
  const [storedValue, setStoredValue] = useState(() => {
    if (typeof window === "undefined") {
      return resolveCallable(initial);
    }
    try {
      const item = window.localStorage.getItem(key);
      if (item) {
        return JSON.parse(item);
      }
      if (initial) {
        const resolvedInitial = resolveCallable(initial);
        window.localStorage.setItem(key, JSON.stringify(resolvedInitial));
        return resolvedInitial;
      }
    } catch (error) {
      console.error(error);
    }
  });

  const setValue = (value) => {
    try {
      const valueToStore = value instanceof Function ? value(storedValue) : value;
      setStoredValue(valueToStore);
      if (typeof window !== "undefined") {
        window.localStorage.setItem(key, JSON.stringify(valueToStore));
      }
    } catch (error) {
      console.error(error);
    }
  };

  const removeValue = () => {
    try {
      setStoredValue(null);
      if (typeof window !== "undefined") {
        window.localStorage.removeItem(key);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return [storedValue, setValue, removeValue];
};
