import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";

import { gql, useMutation } from "@apollo/client";

import { MailOutlined, MobileOutlined, NumberOutlined } from "@ant-design/icons";
import { Alert, Button, Input, Layout, Typography, message } from "antd";

import { useAuthentication } from "../../hooks/auth";
import {
  AUTHORIZATION_FRAGMENT,
  MUTATION_ERROR_FRAGMENT,
} from "../../utils/gql/fragments";
import { validateEmail } from "../../utils/validators";

const { Content } = Layout;
const { Title } = Typography;

export const Login = () => {
  const { t } = useTranslation();
  const [tokens, setTokens] = useState({
    emailToken: null,
    phoneToken: null,
  });

  const [emailData, setEmailData] = useState({});
  const [phoneData, setPhoneData] = useState({});
  const [enableInputEmail, setEnableInputEmail] = useState(true);
  const [enableInputEmailCode, setEnableInputEmailCode] = useState(false);
  const [enableInputPhone, setEnableInputPhone] = useState(false);
  const [enableInputPhoneCode, setEnableInputPhoneCode] = useState(false);

  const [visibleInputEmailCode, setVisibleInputEmailCode] = useState(false);
  const [visibleInputPhone, setVisibleInputPhone] = useState(false);
  const [visibleInputPhoneCode, setVisibleInputPhoneCode] = useState(false);

  const refInputEmail = useRef(null);
  const refInputEmailCode = useRef(null);
  const refInputPhone = useRef(null);
  const refInputPhoneCode = useRef(null);

  const [messageApi, contextHolder] = message.useMessage();

  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state || {};

  const [sendEmail] = useMutation(SEND_EMAIL_MUTATION);
  const [confirmEmail] = useMutation(CONFIRM_EMAIL_MUTATION);
  const [sendPhone] = useMutation(SEND_PHONE_MUTATION);
  const [confirmPhone] = useMutation(CONFIRM_PHONE_MUTATION);
  const [adminLogin] = useMutation(LOGIN_MUTATION);

  const { setToken } = useAuthentication();

  const resetHandler = useCallback(() => {
    setTokens({ emailToken: null, phoneToken: null });
    setEmailData({
      email: "",
      code: "",
    });
    setPhoneData({
      phone: "",
      code: "",
    });
    setEnableInputEmail(true);
    setEnableInputEmailCode(false);
    setEnableInputPhone(false);
    setEnableInputPhoneCode(false);

    setVisibleInputEmailCode(false);
    setVisibleInputPhone(false);
    setVisibleInputPhoneCode(false);
    setTimeout(() => {
      refInputEmail.current.focus();
    }, 100);
  }, [
    setEmailData,
    setPhoneData,
    setEnableInputEmail,
    setEnableInputEmailCode,
    setEnableInputPhone,
    setEnableInputPhoneCode,
    setVisibleInputEmailCode,
    setVisibleInputPhone,
    setVisibleInputPhoneCode,
    refInputEmail,
  ]);

  const sendEmailHandler = () => {
    sendEmail({
      variables: { ...emailData },
      onCompleted: ({ sendEmailVerificationCode }) => {
        const isOk = !!sendEmailVerificationCode?.ok === true;
        if (isOk === true) {
          setEnableInputEmail(false);
          setEnableInputEmailCode(true);
          setVisibleInputEmailCode(true);
          setTimeout(() => {
            refInputEmailCode.current.focus();
          }, 100);
          messageApi.info(t("admin.page.auth.enterVerificationCode"));
          return;
        }
        messageApi.error(
          t("admin.page.auth.failedToSendEmailVerificationCode", {
            message: sendEmailVerificationCode?.errors[0].messages[0],
          }),
        );
      },
    });
  };

  const confirmEmailHandler = () => {
    confirmEmail({
      variables: { ...emailData },
      onCompleted: ({ confirmEmailVerificationCode }) => {
        const isOk = !!confirmEmailVerificationCode?.ok === true;
        const { emailToken, phoneVerificationRequired } = confirmEmailVerificationCode;
        if (isOk === true) {
          if (phoneVerificationRequired === false) {
            messageApi.error(t("admin.page.auth.verificationFailed"));
            resetHandler();
            return;
          }
          setTokens({
            ...tokens,
            emailToken,
          });
          messageApi.info(t("admin.page.auth.emailVerificationCompleted"));
          setEnableInputEmailCode(false);
          setEnableInputPhone(true);
          setVisibleInputPhone(true);
          setTimeout(() => {
            refInputPhone.current.focus();
          }, 100);
          return;
        }
        messageApi.error(
          t("admin.page.auth.failedToVerifyEmail", {
            message: confirmEmailVerificationCode?.errors[0].messages[0],
          }),
        );
        refInputEmailCode.current.select();
        return;
      },
    });
  };

  const sendPhoneHandler = () => {
    sendPhone({
      variables: { ...phoneData },
      onCompleted: ({ sendPhoneVerificationCode }) => {
        const isOk = !!sendPhoneVerificationCode?.ok === true;
        if (isOk === true) {
          setEnableInputPhone(false);
          setEnableInputPhoneCode(true);
          setVisibleInputPhoneCode(true);
          setTimeout(() => {
            refInputPhoneCode.current.focus();
          }, 100);
          messageApi.info(t("admin.page.auth.enterVerificationCode"));
          return;
        }
        messageApi.error(
          t("admin.page.auth.failedToSendPhoneVerificationCode", {
            message: sendPhoneVerificationCode?.errors[0].messages[0],
          }),
        );
        refInputPhoneCode.current.select();
      },
    });
  };

  const confirmPhoneHandler = () => {
    confirmPhone({
      variables: { ...phoneData },
      onCompleted: ({ confirmPhoneVerificationCode }) => {
        const isOk = !!confirmPhoneVerificationCode?.ok === true;
        const { phoneToken, userExists } = confirmPhoneVerificationCode;
        if (userExists === false) {
          messageApi.error(t("admin.page.auth.verificationFailed"));
          resetHandler();
          return;
        }
        if (isOk === true) {
          setTokens({
            ...tokens,
            phoneToken,
          });
          messageApi.success(t("admin.page.auth.phoneVerificationCompleted"));
          return;
        }
        messageApi.error(
          t("admin.page.auth.failedToVerifyPhone", {
            message: confirmPhoneVerificationCode?.errors[0].messages[0],
          }),
        );
        return;
      },
    });
  };

  useEffect(() => {
    const loginHandler = () => {
      adminLogin({
        variables: { ...tokens },
        onCompleted: ({ login }) => {
          const isOk = !!login?.ok === true;
          const token = login?.token;
          if (isOk === true) {
            setToken(token);
            const next = new URLSearchParams(location.search).get("next");
            navigate(next || "/", { replace: true });
            return;
          }
          messageApi.error(
            t("admin.page.auth.failedToLogin", {
              message: login?.errors[0].messages[0],
            }),
          );
          resetHandler();
          return;
        },
      });
    };

    if (tokens.emailToken && tokens.phoneToken) {
      loginHandler();
    }
  }, [
    location.search,
    messageApi,
    navigate,
    resetHandler,
    tokens,
    adminLogin,
    setToken,
  ]);

  return (
    <Layout style={styles.layoutStyle}>
      {contextHolder}
      <Content style={styles.contentStyle}>
        <div>
          {state?.msg && (
            <Alert message={state?.msg} type={state?.type} showIcon closable />
          )}
          <Title level={2}>Fans Admin</Title>
          <Input
            ref={refInputEmail}
            prefix={<MailOutlined style={{ marginRight: "10px" }} />}
            type="text"
            size="large"
            autoCorrect="off"
            spellCheck={false}
            autoCapitalize="off"
            autoFocus
            required
            placeholder={t("admin.page.auth.inputEmail")}
            value={emailData.email}
            onChange={(e) => setEmailData({ email: e.target.value })}
            onPressEnter={(e) => {
              e.preventDefault();
              const isEmail = validateEmail(e.target.value);
              if (isEmail) {
                sendEmailHandler();
                refInputEmail.current.status = "success";
              } else {
                messageApi.error(t("admin.page.auth.enterValidEmail"));
                refInputEmail.current.select();
              }
            }}
            style={styles.inputStyle}
            disabled={!enableInputEmail}
          />
          <Input
            ref={refInputEmailCode}
            prefix={<NumberOutlined style={{ marginRight: "10px" }} />}
            type="text"
            size="large"
            autoCorrect="off"
            spellCheck={false}
            autoCapitalize="off"
            required
            placeholder={t("admin.page.auth.inputVerificationCode")}
            value={emailData.code}
            onChange={(e) => setEmailData({ ...emailData, code: e.target.value })}
            onPressEnter={(e) => {
              e.preventDefault();
              confirmEmailHandler();
            }}
            style={
              visibleInputEmailCode
                ? styles.inputTokenActivatedStyle
                : styles.inputTokenHiddenStyle
            }
            disabled={!enableInputEmailCode}
          />

          {/* == */}

          <Input
            ref={refInputPhone}
            prefix={<MobileOutlined style={{ marginRight: "10px" }} />}
            type="text"
            size="large"
            autoCorrect="off"
            spellCheck={false}
            autoCapitalize="off"
            required
            placeholder={t("admin.page.auth.inputPhone")}
            value={phoneData.phone}
            onChange={(e) => setPhoneData({ phone: e.target.value })}
            onPressEnter={(e) => {
              e.preventDefault();
              // TODO: Validate phone number
              sendPhoneHandler();
              refInputEmail.current.status = "success";
            }}
            style={
              visibleInputPhone
                ? styles.inputTokenActivatedStyle
                : styles.inputTokenHiddenStyle
            }
            disabled={!enableInputPhone}
          />
          <Input
            ref={refInputPhoneCode}
            prefix={<NumberOutlined style={{ marginRight: "10px" }} />}
            type="text"
            size="large"
            autoCorrect="off"
            spellCheck={false}
            autoCapitalize="off"
            required
            placeholder={t("admin.page.auth.inputVerificationCode")}
            value={phoneData.code}
            onChange={(e) => setPhoneData({ ...phoneData, code: e.target.value })}
            onPressEnter={(e) => {
              e.preventDefault();
              confirmPhoneHandler();
            }}
            style={
              visibleInputPhoneCode
                ? styles.inputTokenActivatedStyle
                : styles.inputTokenHiddenStyle
            }
            disabled={!enableInputPhoneCode}
          />
          <Button
            type="primary"
            size="large"
            block
            style={styles.btnStyle}
            onClick={() => {
              if (enableInputEmail) {
                sendEmailHandler();
                return;
              }
              if (enableInputEmailCode) {
                confirmEmailHandler();
                return;
              }
              if (enableInputPhone) {
                sendPhoneHandler();
                return;
              }
              if (enableInputPhoneCode) {
                confirmPhoneHandler();
                return;
              }
            }}
          >
            Verify
          </Button>
        </div>
      </Content>
    </Layout>
  );
};

// ==

const SEND_EMAIL_MUTATION = gql`
  mutation sendEmailVerificationCode($email: String!) {
    sendEmailVerificationCode(email: $email) {
      ok
      errors {
        ...MutationErrorFields
      }
    }
  }
  ${MUTATION_ERROR_FRAGMENT}
`;

const CONFIRM_EMAIL_MUTATION = gql`
  mutation confirmEmailVerificationCode($email: String!, $code: String!) {
    confirmEmailVerificationCode(email: $email, code: $code) {
      ok
      errors {
        ...MutationErrorFields
      }
      emailToken
      userExists
      phoneVerificationRequired
    }
  }
  ${MUTATION_ERROR_FRAGMENT}
`;
const SEND_PHONE_MUTATION = gql`
  mutation sendPhoneVerificationCode($phone: String!) {
    sendPhoneVerificationCode(phone: $phone) {
      ok
      errors {
        ...MutationErrorFields
      }
    }
  }
  ${MUTATION_ERROR_FRAGMENT}
`;

const CONFIRM_PHONE_MUTATION = gql`
  mutation confirmPhoneVerificationCode($phone: String!, $code: String!) {
    confirmPhoneVerificationCode(phone: $phone, code: $code) {
      ok
      errors {
        ...MutationErrorFields
      }
      phoneToken
      userExists
    }
  }
  ${MUTATION_ERROR_FRAGMENT}
`;

const LOGIN_MUTATION = gql`
  mutation login($emailToken: String, $phoneToken: String) {
    login(emailToken: $emailToken, phoneToken: $phoneToken) {
      ok
      errors {
        ...MutationErrorFields
      }
      token
      authorization {
        ...AuthorizationFields
      }
    }
  }
  ${MUTATION_ERROR_FRAGMENT}
  ${AUTHORIZATION_FRAGMENT}
`;

// ==

const styles = {
  layoutStyle: {
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
    alignContent: "center",
    justifyContent: "center",
    alignItems: "center",
  },
  contentStyle: {
    maxWidth: "520px",
    width: "350px",
    display: "flex",
    flexDirection: "column",
    flexWrap: "nowrap",
    justifyContent: "center",
  },
  inputStyle: {
    marginTop: "20px",
  },
  inputTokenHiddenStyle: {
    display: "none",
    visibility: "hidden",
  },
  inputTokenActivatedStyle: {
    visibility: "visible",
    marginTop: "20px",
  },
  btnStyle: {
    marginTop: "40px",
  },
};
