import { forwardRef } from "react";
import { useTranslation } from "react-i18next";

import { DatePicker as AntdDatePicker } from "antd";

import { disabledDate } from "../../utils/datetime";

export const DatePicker = forwardRef(
  ({ placeholder = undefined, style, ...props }, ref) => {
    const { t } = useTranslation();
    const showToday = props.showToday ?? true;
    const useHolidays = props.useHolidays ?? false;

    return (
      <AntdDatePicker
        {...props}
        showToday={showToday}
        placeholder={placeholder ?? "-- " + t("admin.common.placeholderDate")}
        disabledDate={useHolidays ? disabledDate : null}
        style={{ ...styles.container, ...style }}
        ref={ref}
      />
    );
  },
);

// ==

const styles = {
  container: {
    width: "100%",
  },
};
