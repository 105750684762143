import { forwardRef } from "react";

import { MenuOutlined } from "@ant-design/icons";

export const DragHandle = forwardRef(({ style, ...props }, ref) => (
  <MenuOutlined
    style={{
      ...styles.handle,
      ...style,
    }}
    ref={ref}
    {...props}
  />
));

// ==

const styles = {
  handle: {
    color: "#ccc",
  },
};
