import { gql } from "@apollo/client";

import { DetailPage } from "../../components/pages";

export const ExternalPremiumMembershipDetail = () => (
  <DetailPage
    resource="externalPremiumMembership"
    fields={[
      {
        name: "id",
      },
      {
        name: "premium.group",
        labelKey: "admin.resource.group.singular",
        type: "object",
        resource: "group",
        filterKey: "premium_Group_Overlap",
      },
      {
        name: "premium",
        type: "object",
        resource: "premium",
        filterKey: "premium_Overlap",
      },
      {
        name: "provider",
        type: "choice",
        enumType: "ExternalPremiumMembershipProvider",
        filterKey: "provider_Overlap",
      },
      {
        name: "identifier",
        filterKey: "identifier_Exact",
        sortKey: "IDENTIFIER",
      },
      {
        name: "isRegistered",
        type: "boolean",
        filterKey: "isRegistered_Exact",
      },
      {
        name: "username",
        filterKey: "username_Exact",
      },
      {
        name: "email",
        type: "email",
        filterKey: "email_Exact",
      },
      {
        name: "name",
        filterKey: "name_Text",
      },
      {
        name: "birthDate",
        type: "date",
      },
      ...DetailPage.fields.tracked,
    ]}
    query={QUERY}
    extraActions={DetailPage.extraActions.delete}
  />
);

// ==

const QUERY = gql`
  query externalPremiumMembershipForExternalPremiumMembershipDetail($id: ID!) {
    externalPremiumMembership(filter: { id_Overlap: [$id] }) {
      id
      premium {
        id
        name
        group {
          id
          name
        }
      }
      provider
      identifier
      isRegistered
      username
      email
      name
      birthDate
      ...TrackedFields
    }
  }
  ${DetailPage.fragments.tracked}
`;
