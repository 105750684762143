import { gql } from "@apollo/client";

import { ListWidget } from "../list";

export const MediaLayoutSectionListWidget = (props) => (
  <ListWidget
    resource="mediaLayoutSection"
    fields={[
      {
        name: "id",
      },
      {
        name: "layout",
        type: "object",
        resource: "mediaLayout",
        filterKey: "layout_Overlap",
      },
      {
        name: "title",
        filterKey: "title_Text",
      },
      {
        name: "category",
        type: "choice",
        enumType: "MediaLayoutSectionCategory",
        filterKey: "category_Overlap",
      },
      {
        name: "source",
        type: "choice",
        enumType: "MediaLayoutSectionSource",
        filterKey: "source_Overlap",
      },
      {
        name: "playlist",
        type: "object",
        resource: "playlist",
        filterKey: "playlist_Overlap",
      },
      {
        name: "clip",
        type: "object",
        resource: "clip",
        filterKey: "clip_Overlap",
      },
      {
        name: "premium",
        type: "object",
        resource: "premium",
        filterKey: "premium_Overlap",
      },
    ]}
    query={QUERY}
    hideDetail
    hideCreate
    hideUpdate
    {...props}
  />
);

// ==

const QUERY = gql`
  query mediaLayoutSectionsForMediaLayoutSectionListWidget(
    $filter: MediaLayoutSectionFilterInput
    $sort: [MediaLayoutSectionSortInput]
    $page: PageInput
  ) {
    mediaLayoutSections(filter: $filter, sort: $sort, page: $page) {
      objects {
        id
        layout {
          id
          name
        }
        category
        source
        title
        playlist {
          id
          name
        }
        clip {
          id
          title
        }
        premium {
          id
          name
        }
      }
      ...ListFields
    }
  }
  ${ListWidget.fragments.list}
`;
