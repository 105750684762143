import { gql } from "@apollo/client";

import { ActivateButton } from "../../components/actions";
import { DetailPage } from "../../components/pages";
import { Can } from "../../components/permission";
import { OfflineStoreItemVariantListWidget } from "../../components/widgets";

export const OfflineStoreItemDetail = () => (
  <DetailPage
    resource="offlineStoreItem"
    fields={[
      {
        name: "id",
      },
      {
        name: "offlineStore.name",
      },
      {
        name: "nameContent",
        labelKey: "admin.field.name",
        type: "translatable",
      },
      {
        name: "detailUrl",
      },
      {
        name: "availability",
        enumType: "OfflineStoreItemAvailability",
        type: "choice",
      },
      {
        name: "isSingular",
        type: "boolean",
      },
      {
        name: "restrictions",
        type: "categoryCount",
        enumType: "OfflineStoreItemRestrictionCategory",
      },
      {
        name: "image",
        type: "imageMeta",
        spaceDirection: "horizontal",
      },
      ...DetailPage.fields.canActivate,
      {
        name: "activateAt",
        type: "datetime",
      },
      ...DetailPage.fields.tracked,
    ]}
    query={QUERY}
    extraActions={({ obj, refetch, resource }) => {
      return (
        <>
          <Can
            action={obj.isActive ? "inactivate" : "activate"}
            targetType={resource.typeName}
            targetId={obj.id}
          >
            <ActivateButton
              targetType={resource.typeName}
              targetId={obj.id}
              value={obj.isActive}
              onChange={() => refetch()}
            ></ActivateButton>
          </Can>
        </>
      );
    }}
    tabs={[
      {
        path: "",
        labelKey: "admin.resource.offlineStoreItemVariants.plural",
        permission: {
          action: "list",
          targetType: "OFFLINE_STORE_ITEM_VARIANT",
        },
        children: ({ obj }) => (
          <OfflineStoreItemVariantListWidget
            baseFilter={{
              item_Overlap: [obj.id],
            }}
          />
        ),
      },
    ]}
  />
);

// ==

const QUERY = gql`
  query offlineStoreItemForOfflineStoreItemDetail($id: ID!) {
    offlineStoreItem(filter: { id_Overlap: [$id] }) {
      offlineStore {
        id
        name
      }
      id
      name
      nameContent {
        ...TranslatableContentFields
      }
      availability
      price
      image {
        ...DetailImageFields
      }
      detailUrl
      isSingular
      availability
      restrictions {
        id
        category
        count
      }
      variants {
        id
        label
        availability
      }
      ...CanActivateFields
      ...TrackedFields
    }
  }
  ${DetailPage.fragments.translatableContent}
  ${DetailPage.fragments.image}
  ${DetailPage.fragments.canActivate}
  ${DetailPage.fragments.tracked}
`;
