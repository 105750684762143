import { useState } from "react";
import { useTranslation } from "react-i18next";

import { gql, useMutation } from "@apollo/client";

import { YoutubeOutlined } from "@ant-design/icons";
import { Button, Modal, Space, Typography } from "antd";

import { useApp } from "../../hooks/app";
import { MUTATION_ERROR_FRAGMENT } from "../../utils/gql/fragments";

const { Text } = Typography;

export const SyncGroupClipsButton = ({
  groupId,
  onChange,
  size = "middle",
  onlyIcon = false,
}) => {
  const { t } = useTranslation();

  const variables = {
    id: groupId,
  };
  const onCompleted = (response) => {
    const { ok, errors, object } = Object.values(response)[0];
    if (ok) {
      message.success(t("admin.message.requestSuccess"));
      onChange?.(object);
      setIsModalOpen(false);
    } else {
      message.error(
        t("admin.message.requestFailure", {
          message: errors[0].messages[0],
        }),
      );
    }
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [sync, { loading }] = useMutation(MUTATION, {
    variables,
    onCompleted,
  });
  const { message } = useApp();

  const onClick = () => setIsModalOpen(true);
  const onOk = () => sync();
  const onCancel = () => setIsModalOpen(false);

  return (
    <>
      <Button icon={<YoutubeOutlined />} onClick={onClick} size={size}>
        {onlyIcon ? null : t("admin.common.sync")}
      </Button>
      <Modal
        title={t(`admin.common.syncWithYoutube`)}
        open={isModalOpen}
        confirmLoading={loading}
        okText={t("admin.common.sync")}
        okButtonProps={{
          icon: <YoutubeOutlined />,
        }}
        cancelText={t("admin.common.cancel")}
        onOk={onOk}
        onCancel={onCancel}
      >
        <Space direction="vertical">
          <Text style={styles.message}>
            {t("admin.component.syncGroupClipsButton.message")}
          </Text>
        </Space>
      </Modal>
    </>
  );
};

// ==

const MUTATION = gql`
  mutation syncGroupClipsWithYoutubeForSyncGroupClipsButton($id: ID!) {
    syncGroupClipsWithYoutube(groupId: $id) {
      ok
      errors {
        ...MutationErrorFields
      }
    }
  }
  ${MUTATION_ERROR_FRAGMENT}
`;

// ==

const styles = {
  message: {
    whiteSpace: "pre-line",
  },
};
