import { gql } from "@apollo/client";

import { DetailPage } from "../../components/pages";

export const BannedPhraseDetail = () => (
  <DetailPage
    resource="bannedPhrase"
    fields={[
      {
        name: "id",
      },
      {
        name: "phrase",
      },
      {
        name: "validationCategory",
        type: "choice",
        enumType: "StringMatchCategory",
      },
      ...DetailPage.fields.tracked,
    ]}
    query={QUERY}
  />
);

// ==

const QUERY = gql`
  query bannedPhraseForBannedPhraseDetail($id: ID!) {
    bannedPhrase(filter: { id_Overlap: [$id] }) {
      id
      phrase
      validationCategory
      ...TrackedFields
    }
  }
  ${DetailPage.fragments.tracked}
`;
