import { gql } from "@apollo/client";

import { ListPage, ListPageWithGroup } from "../../components/pages";

export const EventPostList = () => (
  <ListPageWithGroup
    groupFilterKey="event_Group_Overlap"
    resource="eventPost"
    subtitleKey="admin.page.eventPost.subtitle"
    fields={[
      {
        name: "id",
      },
      {
        name: "event.title",
        type: "string",
      },
      {
        name: "body",
        truncate: true,
      },
      {
        name: "activateAt",
        type: "datetime",
      },
      ...ListPage.fields.tracked,
    ]}
    query={QUERY}
    defaultSort={[
      {
        type: "CREATED_AT",
        direction: "DESC",
      },
    ]}
    extraRowActions={ListPage.extraRowActions.canActivate}
  />
);

// ==

const QUERY = gql`
  query eventPostsForEventPostList(
    $filter: EventPostFilterInput
    $sort: [EventPostSortInput]
    $page: PageInput
  ) {
    eventPosts(filter: $filter, sort: $sort, page: $page) {
      objects {
        id
        event {
          id
          title
          group {
            id
            name
          }
        }
        body
        ...TrackedFields
        ...CanActivateFields
      }
      ...ListFields
    }
  }
  ${ListPage.fragments.list}
  ${ListPage.fragments.tracked}
  ${ListPage.fragments.canActivate}
`;
