import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
  Link,
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";

import { useQuery } from "@apollo/client";

import { ArrowLeftOutlined, EditOutlined, RedoOutlined } from "@ant-design/icons";
import {
  Breadcrumb,
  Button,
  Card,
  Col,
  Divider,
  Row,
  Space,
  Tabs,
  Typography,
} from "antd";

import { Can } from "../../components/permission";
import { useApp } from "../../hooks/app";
import { useFields } from "../../hooks/fields";
import { useAbilities } from "../../hooks/permission";
import {
  CAN_ACTIVATE_FRAGMENT,
  CAN_HIDE_FRAGMENT,
  DETAIL_CLIP_CAPTION_FRAGMENT,
  DETAIL_FILE_FRAGMENT,
  DETAIL_IMAGE_FRAGMENT,
  DETAIL_MEDIA_FRAGMENT,
  DETAIL_VIDEO_FRAGMENT,
  TIMESTAMPED_FRAGMENT,
  TRACKED_FRAGMENT,
  TRANSLATABLE_CONTENT_FRAGMENT,
  TRANSLATABLE_FILES_FRAGMENT,
} from "../../utils/gql/fragments";
import { resolveCallable } from "../../utils/helpers";
import {
  ActivateButton,
  ApproveButton,
  DeleteButton,
  ForceHideButton,
} from "../actions";

export const DetailPage = ({
  resource: resourceName,
  fields: originalFields,
  query,
  tabs: originalTabs = undefined,
  hideUpdate = false,
  hideRefresh = false,
  extraActions = undefined,
  children = undefined,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const { resources, message } = useApp();
  const { loading, data, refetch } = useQuery(query, {
    variables: { id: params.id },
    notifyOnNetworkStatusChange: true,
  });

  const resource = resources[resourceName];
  const hasForm = !!resource.formComponent;
  const obj = data ? Object.values(data)[0] : null;

  const tabAbilityKeys = useMemo(
    () => originalTabs?.map((tab) => tab.permission ?? { action: "list" }) ?? [],
    [originalTabs],
  );
  const { abilities: tabAbilities } = useAbilities(tabAbilityKeys);
  const { fields } = useFields({
    resource: resourceName,
    fields: originalFields,
  });

  if (!obj || !fields || !tabAbilities) {
    return null;
  }

  const tabs = originalTabs?.filter((tab, i) => tabAbilities[i]);
  const tabKey = location.pathname;
  const hasContent = tabs?.length > 0 || children;

  return (
    <Space direction="vertical" size="middle" style={styles.container}>
      <Breadcrumb
        items={[
          { title: <Link to="/">{t("admin.common.home")}</Link> },
          { title: <Link to={resource.listUrl}>{resource.labelPlural}</Link> },
          { title: resource.labelGetter(obj) },
        ]}
      />
      <Row justify="space-between">
        <Space align="center">
          <Link onClick={() => history.back()}>
            <Button type="text" icon={<ArrowLeftOutlined />} />
          </Link>
          <Typography.Title level={2} style={styles.title}>
            {resource.labelGetter(obj)}
          </Typography.Title>
        </Space>
        <Space>
          {extraActions?.({ obj, resource, loading, refetch, navigate })}
          {hasForm && !hideUpdate && (
            <Can action="update" targetType={resource.typeName} targetId={obj.id}>
              <Link to={resource.updateUrlGetter(obj)}>
                <Button icon={<EditOutlined />}>{t("admin.common.edit")}</Button>
              </Link>
            </Can>
          )}
          {!hideRefresh && (
            <Can action="read" targetType={resource.typeName} targetId={obj.id}>
              <Button
                icon={<RedoOutlined />}
                disabled={loading}
                loading={loading}
                onClick={() =>
                  refetch().then(() =>
                    message.success(t("admin.message.refreshSuccess")),
                  )
                }
              >
                {t("admin.common.refresh")}
              </Button>
            </Can>
          )}
        </Space>
      </Row>
      <Space direction="vertical" size={0} split={<Divider />} style={styles.container}>
        <Row gutter={24}>
          <Col lg={hasContent ? 7 : 24}>
            <Card>
              <Space direction="vertical" size="small" style={styles.fields}>
                {fields.map((field, i) => (
                  <Row key={i} gutter={16}>
                    <Col span={hasContent ? 8 : 3}>
                      <Typography.Text type="secondary">{field.label}</Typography.Text>
                    </Col>
                    <Col span={hasContent ? 16 : 21}>
                      <div style={styles.fieldValue}>{field.render(field, obj)}</div>
                    </Col>
                  </Row>
                ))}
              </Space>
            </Card>
          </Col>
          {hasContent && (
            <Col lg={17}>
              {tabs && (
                <>
                  <div>
                    <Tabs
                      activeKey={tabKey}
                      items={tabs.map(
                        ({ path, label, labelKey, hidden = () => false }) => {
                          if (hidden?.({ obj })) return;
                          return {
                            key: [
                              resource.detailUrlGetter(obj),
                              ...(path ? [path] : []),
                            ].join("/"),
                            label: label ?? (labelKey ? t(labelKey) : null),
                          };
                        },
                      )}
                      onChange={(key) => navigate(key)}
                    />
                  </div>
                  <Routes>
                    {tabs.map(({ path, children }) => (
                      <Route
                        key={path}
                        path={path}
                        element={children({ obj, refetch, resource, loading })}
                      />
                    ))}
                  </Routes>
                </>
              )}
              {children &&
                resolveCallable(children, { obj, refetch, resource, loading })}
            </Col>
          )}
        </Row>
      </Space>
    </Space>
  );
};

// ==

DetailPage.fragments = {
  tracked: TRACKED_FRAGMENT,
  timestamped: TIMESTAMPED_FRAGMENT,
  canActivate: CAN_ACTIVATE_FRAGMENT,
  canHide: CAN_HIDE_FRAGMENT,
  file: DETAIL_FILE_FRAGMENT,
  image: DETAIL_IMAGE_FRAGMENT,
  video: DETAIL_VIDEO_FRAGMENT,
  clipCaption: DETAIL_CLIP_CAPTION_FRAGMENT,
  media: DETAIL_MEDIA_FRAGMENT,
  translatableContent: TRANSLATABLE_CONTENT_FRAGMENT,
  translatableFiles: TRANSLATABLE_FILES_FRAGMENT,
};

DetailPage.fields = {
  timestamped: [
    {
      name: "updatedAt",
      type: "datetime",
    },
    {
      name: "createdAt",
      type: "datetime",
    },
  ],
  tracked: [
    {
      name: "updatedAt",
      type: "datetime",
    },
    {
      name: "updatedBy",
      type: "object",
      resource: "user",
    },
    {
      name: "createdAt",
      type: "datetime",
    },
    {
      name: "createdBy",
      type: "object",
      resource: "user",
    },
  ],
  canActivate: [
    {
      type: "boolean",
      name: "isActive",
    },
    {
      type: "datetime",
      name: "firstActivatedAt",
    },
  ],
  canHide: [
    {
      type: "boolean",
      name: "isHidden",
    },
    {
      type: "boolean",
      name: "isHiddenStatusForced",
    },
  ],
};

DetailPage.extraActions = {
  delete: ({ obj, resource, navigate }) => (
    <Can action="delete" targetType={resource.typeName} targetId={obj.id}>
      <DeleteButton
        targetType={resource.typeName}
        targetId={obj.id}
        onDelete={() => navigate(resource.listUrl)}
      ></DeleteButton>
    </Can>
  ),
  canActivate: ({ obj, refetch, resource }) => (
    <Can
      action={obj.isActive ? "inactivate" : "activate"}
      targetType={resource.typeName}
      targetId={obj.id}
    >
      <ActivateButton
        targetType={resource.typeName}
        targetId={obj.id}
        value={obj.isActive}
        onChange={() => refetch()}
      ></ActivateButton>
    </Can>
  ),
  canApprove: ({ obj, refetch, resource }) => (
    <Can
      action={obj.isApproved ? "disapprove" : "approve"}
      targetType={resource.typeName}
      targetId={obj.id}
    >
      <ApproveButton
        targetType={resource.typeName}
        targetId={obj.id}
        value={obj.isApproved}
        onChange={() => refetch()}
      ></ApproveButton>
    </Can>
  ),

  canHide: ({ obj, refetch, resource }) => (
    <Can
      action={obj.isHidden ? "unhide" : "hide"}
      targetType={resource.typeName}
      targetId={obj.id}
    >
      <ForceHideButton
        targetType={resource.typeName}
        targetId={obj.id}
        value={obj.isHidden}
        onChange={() => refetch()}
      ></ForceHideButton>
    </Can>
  ),
};

// ==

const styles = {
  container: {
    display: "flex",
  },
  title: {
    margin: 0,
  },
  fields: {
    display: "flex",
  },
  fieldValue: {
    wordBreak: "break-all",
  },
};
