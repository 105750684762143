import { forwardRef, useMemo } from "react";

import { Checkbox, Space } from "antd";

import { useEnum } from "../../hooks/enum";

export const EnumCheckbox = forwardRef(
  (
    {
      type,
      filterOptions = (options) => options,
      onChange,
      direction = "vertical",
      size = 4,
      ...props
    },
    ref,
  ) => {
    const { values } = useEnum(type);
    const options = useMemo(
      () =>
        values?.map(({ name, description }) => ({
          value: name,
          label: description,
        })),
      [values],
    );

    return (
      <Checkbox.Group
        style={styles.container}
        ref={ref}
        onChange={(e) => onChange?.(e)}
        {...props}
      >
        <Space direction={direction} size={size}>
          {(options ? filterOptions(options) : undefined)?.map(({ value, label }) => (
            <Checkbox key={value} value={value}>
              {label}
            </Checkbox>
          ))}
        </Space>
      </Checkbox.Group>
    );
  },
);

// ==

const styles = {
  container: {
    width: "100%",
  },
};
