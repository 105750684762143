import React, { forwardRef, useEffect, useState } from "react";

import { UploadOutlined } from "@ant-design/icons";
import { Button, Upload } from "antd";
import type { UploadFile, UploadProps } from "antd/lib/upload/interface";

import { DndContext, PointerSensor, useSensor } from "@dnd-kit/core";
import type { DragEndEvent } from "@dnd-kit/core/dist/types";
import {
  SortableContext,
  arrayMove,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import type { Prettify, StrictOmit } from "ts-essentials";

import { UploadedFileMeta, useS3Upload } from "@/hooks/upload-s3";
import { arrayify, deepEqual, extractFileName } from "@/utils/helpers";

export type MediaUploadProps = Prettify<
  StrictOmit<UploadProps, "fileList" | "onChange" | "customRequest"> & {
    multiple?: boolean;
    sync?: boolean;
    value?: UploadedFileMeta | UploadedFileMeta[];
    onChange?: (value: UploadedFileMeta | UploadedFileMeta[]) => void;
    onUploadBegin?: () => void;
    onUploadEnd?: () => void;
  }
>;

export const MediaUpload = forwardRef<any, MediaUploadProps>(
  (
    {
      value,
      onChange,
      onUploadBegin,
      onUploadEnd,
      multiple = false,
      sync = true,
      ...props
    },
    ref,
  ) => {
    const [isUploading, setIsUploading] = useState(false);
    const [fileList, setFileList] = useState<UploadFile[]>(
      arrayify(value).map(toAntdFile),
    );
    const sensor = useSensor(PointerSensor, {
      activationConstraint: {
        distance: 10,
      },
    });
    const customRequest = useS3Upload({ sync });

    useEffect(() => {
      const oldValues = fileList.filter((file) => !!file.response).map(fromAntdFile);
      const newValues = arrayify(value);

      if (!deepEqual(oldValues, newValues)) {
        setFileList(newValues.map(toAntdFile));
      }
    }, [value, multiple]); // eslint-disable-line

    // ==

    const handleFileListChange = (newFileList: UploadFile[]) => {
      setFileList(newFileList);
      const newValues = newFileList.filter((file) => !!file.response).map(fromAntdFile);
      const newValue = multiple ? newValues : newValues[0] ?? null;
      if (newFileList.length !== newValues.length) return;
      onChange?.(newValue);
    };
    const handleDragEnd = ({ active, over }: DragEndEvent) => {
      if (active.id !== over?.id) {
        const activeIndex = fileList.findIndex((i) => i.uid === active.id);
        const overIndex = fileList.findIndex((i) => i.uid === over?.id);
        const newFileList = arrayMove(fileList, activeIndex, overIndex);
        handleFileListChange(newFileList);
      }
    };
    const handleChange: UploadProps["onChange"] = ({ file, fileList }) => {
      const newFileList = [...(multiple ? fileList : fileList.slice(-1))];
      handleFileListChange(newFileList);

      if (!isUploading && file.status == "uploading") {
        setIsUploading(true);
        onUploadBegin?.();
      }

      if (
        isUploading &&
        !fileList.filter((file) => file.status == "uploading").length
      ) {
        setIsUploading(false);
        onUploadEnd?.();
      }
    };

    return (
      <DndContext sensors={[sensor]} onDragEnd={handleDragEnd}>
        <SortableContext
          items={fileList.map((i) => i.uid)}
          strategy={verticalListSortingStrategy}
        >
          <Upload
            listType="picture"
            itemRender={(originNode, file) => (
              <DraggableUploadListItem originNode={originNode} file={file} />
            )}
            fileList={fileList}
            onChange={handleChange}
            customRequest={customRequest}
            multiple={multiple}
            ref={ref}
            {...props}
          >
            <Button icon={<UploadOutlined />} disabled={props.disabled}>
              Upload
            </Button>
          </Upload>
        </SortableContext>
      </DndContext>
    );
  },
);

// ==
interface DraggableUploadListItemProps {
  originNode: React.ReactElement;
  file: UploadFile;
}

const DraggableUploadListItem: React.FC<DraggableUploadListItemProps> = ({
  originNode,
  file,
}) => {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } =
    useSortable({
      id: file.uid,
    });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    cursor: "move",
  };

  return (
    <div
      ref={setNodeRef}
      style={style}
      className={isDragging ? "is-dragging" : ""}
      {...attributes}
      {...listeners}
    >
      {file.status === "error" && isDragging ? originNode.props.children : originNode}
    </div>
  );
};

// ==

const toAntdFile: (value: UploadedFileMeta) => UploadFile = ({
  m3u8Url: _,
  thumbnailUrl,
  url,
  downloadUrl,
  ...value
}) => ({
  uid: value.key,
  name: extractFileName(value.key),
  url: downloadUrl ?? url,
  response: value,
  thumbUrl: thumbnailUrl,
});

const fromAntdFile = (value: UploadFile) => value.response as UploadedFileMeta;
