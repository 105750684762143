import { gql } from "@apollo/client";

import { ListWidget } from "../list";

export const PremiumListWidget = (props) => (
  <ListWidget
    resource="premium"
    fields={[
      {
        name: "id",
      },
      {
        name: "group",
        type: "object",
        resource: "group",
        filterKey: "group_Overlap",
      },
      {
        name: "name",
      },
      {
        name: "nickname",
        filterKey: "nickname_Exact",
        sortKey: "NICKNAME",
      },
      {
        name: "startAt",
        type: "datetime",
      },
      {
        name: "endAt",
        type: "datetime",
      },
      {
        name: "phase",
        type: "choice",
        enumType: "PremiumPhase",
      },
      {
        name: "membershipCount",
        type: "integer",
        filterKey: "membershipCount_Range",
        sortKey: "MEMBERSHIP_COUNT",
      },
      {
        name: "status",
        type: "choice",
        enumType: "PremiumStatus",
        filterKey: "status_Overlap",
      },
      {
        name: "activateAt",
        type: "datetime",
      },
      ...ListWidget.fields.tracked,
    ]}
    query={QUERY}
    extraRowActions={ListWidget.extraRowActions.canActivate}
    {...props}
  />
);

// ==

const QUERY = gql`
  query premiumsForPremiumListWidget(
    $filter: PremiumFilterInput
    $sort: [PremiumSortInput]
    $page: PageInput
  ) {
    premiums(filter: $filter, sort: $sort, page: $page) {
      objects {
        id
        group {
          id
          name
        }
        name
        nickname
        startAt
        endAt
        phase
        membershipCount
        status
        ...TrackedFields
        ...CanActivateFields
      }
      ...ListFields
    }
  }
  ${ListWidget.fragments.list}
  ${ListWidget.fragments.tracked}
  ${ListWidget.fragments.canActivate}
`;
