import { gql } from "@apollo/client";

export const MUTATION_ERROR_FRAGMENT = gql`
  fragment MutationErrorFields on MutationError {
    code
    messages
  }
`;

export const AUTHORIZATION_FRAGMENT = gql`
  fragment AuthorizationFields on Authorization {
    isAuthenticated
    user {
      id
      email
      phone
      name
      staff {
        id
        isSuper
        roleCategories
        permittedGroups {
          id
          name
        }
        permittedCompanies {
          id
          name
        }
        allPermittedGroups {
          id
          name
        }
      }
    }
  }
`;

export const LIST_FRAGMENT = gql`
  fragment ListFields on List {
    count {
      value
      relation
    }
    page {
      hasNext
      hasPrevious
      startCursor
      endCursor
      startIndex
      endIndex
    }
  }
`;

export const TIMESTAMPED_FRAGMENT = gql`
  fragment TimestampedFields on Timestamped {
    updatedAt
    createdAt
  }
`;

export const TRACKED_FRAGMENT = gql`
  fragment TrackedFields on Tracked {
    updatedAt
    updatedBy {
      id
      email
      name
    }
    createdAt
    createdBy {
      id
      email
      name
    }
  }
`;

export const CAN_ACTIVATE_FRAGMENT = gql`
  fragment CanActivateFields on CanActivate {
    isActive
    activateAt
    inactivateAt
    doesSendPush
    firstActivatedAt
  }
`;

export const CAN_HIDE_FRAGMENT = gql`
  fragment CanHideFields on CanHide {
    isHidden
    isHiddenStatusForced
  }
`;

export const FILE_FRAGMENT = gql`
  fragment FileFields on File {
    key
    mime
    size
    # ==
    url
  }
`;

export const CLIP_CAPTION_FRAGMENT = gql`
  fragment ClipCaptionFields on ClipCaption {
    language {
      code
      name
      nativeName
    }
    caption {
      key
      mime
      size
      # ==
      url
    }
  }
`;

export const FORM_CLIP_CAPTION_FRAGMENT = gql`
  fragment FormClipCaptionFields on ClipCaption {
    language {
      id
    }
    caption {
      key
      mime
      size
      # ==
      url
    }
  }
`;

export const DETAIL_CLIP_CAPTION_FRAGMENT = gql`
  fragment DetailClipCaptionFields on ClipCaption {
    id
    language {
      code
      name
      nativeName
    }
    caption {
      url
    }
  }
`;

export const LIST_FILE_FRAGMENT = gql`
  fragment ListFileFields on File {
    url
  }
`;

export const DETAIL_FILE_FRAGMENT = gql`
  fragment DetailFileFields on File {
    url
  }
`;

export const IMAGE_FRAGMENT = gql`
  fragment ImageFields on Image {
    key
    mime
    size
    width
    height
    # ==
    url
    thumbnailUrl(width: 50, height: 50)
  }
`;

export const LIST_IMAGE_FRAGMENT = gql`
  fragment ListImageFields on Image {
    width
    height
    # ==
    url
    thumbnailUrl(width: 50, height: 50)
    moderationLabel
    confidenceLevel
  }
`;

export const DETAIL_IMAGE_FRAGMENT = gql`
  fragment DetailImageFields on Image {
    width
    # ==
    url
    moderationLabel
    confidenceLevel
  }
`;

export const VIDEO_FRAGMENT = gql`
  fragment VideoFields on Video {
    key
    mime
    size
    width
    height
    duration
    bitrate
    m3u8Key
    thumbnailKey
    # ==
    url
    thumbnailUrl(width: 100, height: 100)
    m3u8Url
  }
`;

export const LIST_VIDEO_FRAGMENT = gql`
  fragment ListVideoFields on Video {
    width
    # ==
    m3u8Url
    thumbnailUrl(width: 100, height: 100)
  }
`;

export const DETAIL_VIDEO_FRAGMENT = gql`
  fragment DetailVideoFields on Video {
    width
    # ==
    m3u8Url
    thumbnailUrl(width: 300, height: 300)
  }
`;

export const MEDIA_FRAGMENT = gql`
  fragment MediaFields on Media {
    key
    mime
    size
    # ==
    url
    ... on Image {
      width
      height
      # ==
      thumbnailUrl(width: 100, height: 100)
    }
    ... on Video {
      width
      height
      duration
      bitrate
      m3u8Key
      thumbnailKey
      # ==
      m3u8Url
      thumbnailUrl(width: 100, height: 100)
    }
  }
`;

export const LIST_MEDIA_FRAGMENT = gql`
  fragment ListMediaFields on Media {
    mime
    ... on File {
      url
    }
    ... on Image {
      width
      # ==
      url
      thumbnailUrl(width: 100, height: 100)
    }
    ... on Video {
      width
      # ==
      m3u8Url
      thumbnailUrl(width: 100, height: 100)
    }
  }
`;

export const DETAIL_MEDIA_FRAGMENT = gql`
  fragment DetailMediaFields on Media {
    mime
    ... on File {
      url
    }
    ... on Image {
      width
      # ==
      url
      moderationLabel
      confidenceLevel
    }
    ... on Video {
      width
      # ==
      m3u8Url
      thumbnailUrl(width: 300, height: 300)
    }
  }
`;

export const MEMBER_SUSPENSION_FRAGMENT = gql`
  fragment MemberSuspensionFields on Member {
    id
    suspensionCategory
    suspensionReason
    suspensionPeriod
    suspensionMemo
  }
`;

// ==

export const TRANSLATABLE_CONTENT_FRAGMENT = gql`
  fragment TranslatableContentFields on TranslatableContent {
    values {
      id
      language
      value
    }
  }
`;

export const TRANSLATABLE_FILES_FRAGMENT = gql`
  fragment TranslatableFilesContentFields on TranslatableFilesContent {
    values {
      id
      language
      values {
        key
        mime
        size
        width
        height
        # ==
        url
      }
    }
  }
`;

export const GROUP_THEME_FRAGMENT = gql`
  fragment GroupThemeFields on GroupTheme {
    id
    group {
      id
    }
    name
    coverImage {
      ...ImageFields
    }
    coverRatio
    logoImage {
      ...ImageFields
    }
    logoPosition
    previewMedias {
      ...MediaFields
    }
    status
    ...TrackedFields
    ...CanActivateFields
  }
  ${IMAGE_FRAGMENT}
  ${MEDIA_FRAGMENT}
  ${TRACKED_FRAGMENT}
  ${CAN_ACTIVATE_FRAGMENT}
`;

export const GROUP_MAIN_SCREEN_FRAGMENT = gql`
  fragment GroupMainScreenFields on GroupMainScreen {
    id
    group {
      id
    }
    name
    status
    gridImage {
      ...ImageFields
    }
    logoImage {
      ...ImageFields
    }
    defaultProfileImage {
      ...ImageFields
    }
    ...TrackedFields
    ...CanActivateFields
  }
  ${IMAGE_FRAGMENT}
  ${TRACKED_FRAGMENT}
  ${CAN_ACTIVATE_FRAGMENT}
`;

export const MEDIA_LAYOUT_SECTION_FRAGMENT = gql`
  fragment MediaLayoutSectionFields on MediaLayoutSection {
    id
    playlist {
      id
    }
    clip {
      id
    }
    premium {
      id
    }
    category
    source
    title
    titleContent {
      ...TranslatableContentFields
    }
    subtitle
    subtitleContent {
      ...TranslatableContentFields
    }
    order
    ...TrackedFields
  }
  ${TRACKED_FRAGMENT}
  ${TRANSLATABLE_CONTENT_FRAGMENT}
`;

export const MEDIA_LAYOUT_FRAGMENT = gql`
  fragment MediaLayoutFields on MediaLayout {
    id
    group {
      id
    }
    name
    sections {
      ...MediaLayoutSectionFields
    }
    status
    ...TrackedFields
    ...CanActivateFields
  }
  ${MEDIA_LAYOUT_SECTION_FRAGMENT}
  ${TRACKED_FRAGMENT}
  ${CAN_ACTIVATE_FRAGMENT}
`;

export const SCHEDULE_FRAGMENT = gql`
  fragment ScheduleFields on Schedule {
    id
    group {
      id
    }
    artists {
      id
    }
    category
    scheduledAt
    isAllDay
    title
    titleContent {
      ...TranslatableContentFields
    }
    body
    bodyContent {
      ...TranslatableContentFields
    }
    location
    doesSendPushOnSchedule
    status
    ...TrackedFields
    ...CanActivateFields
  }
  ${TRACKED_FRAGMENT}
  ${CAN_ACTIVATE_FRAGMENT}
  ${TRANSLATABLE_CONTENT_FRAGMENT}
`;

export const CLIP_FRAGMENT = gql`
  fragment ClipFields on Clip {
    id
    group {
      id
    }
    member {
      id
    }
    premium {
      id
    }
    category
    title
    titleContent {
      ...TranslatableContentFields
    }
    body
    bodyContent {
      ...TranslatableContentFields
    }
    thumbnail {
      ...ImageFields
    }
    video {
      ...VideoFields
    }
    youtubeClipUrl
    youtubeClipDuration
    youtubeLastSyncedAt
    doesAllowComment
    status
    imageCount
    bookmarkCount
    commentCount
    likeCount
    ...TrackedFields
    ...CanActivateFields
  }
  ${IMAGE_FRAGMENT}
  ${VIDEO_FRAGMENT}
  ${TRACKED_FRAGMENT}
  ${CAN_ACTIVATE_FRAGMENT}
  ${TRANSLATABLE_CONTENT_FRAGMENT}
`;

export const PLAYLIST_FRAGMENT = gql`
  fragment PlaylistFields on Playlist {
    id
    group {
      id
    }
    name
    category
    youtubePlaylistUrl
    youtubeLastSyncedAt
    clipCount
    imageCount
    videoCount
    ...TrackedFields
  }
  ${TRACKED_FRAGMENT}
`;

export const TRANSLATION_VALUE_FRAGMENT = gql`
  fragment TranslationValueFields on TranslationValue {
    id
    code {
      id
      code
    }
    language
    value
    status
    ...TrackedFields
  }
  ${TRACKED_FRAGMENT}
`;

export const TRANSLATION_CODE_FRAGMENT = gql`
  fragment TranslationCodeFields on TranslationCode {
    id
    code
    context
    images {
      ...ImageFields
    }
    memo
    englishStatus
    koreanStatus
    chineseStatus
    japaneseStatus
    values {
      ...TranslationValueFields
    }
    lastValueUpdatedAt
    ...TrackedFields
  }
  ${IMAGE_FRAGMENT}
  ${TRACKED_FRAGMENT}
  ${TRANSLATION_VALUE_FRAGMENT}
`;

export const PREMIUM_MEMBERSHIP_FRAGMENT = gql`
  fragment PremiumMembershipFields on PremiumMembership {
    identifier
    email
    phone
    name
    birthDate
  }
`;
