import { gql } from "@apollo/client";

import { DetailPage } from "../../components/pages";

export const EventPostDetail = () => (
  <DetailPage
    resource="eventPost"
    fields={[
      {
        name: "id",
      },
      {
        name: "event.group",
        type: "object",
        resource: "group",
      },
      {
        name: "event",
        type: "object",
        resource: "event",
      },
      {
        name: "body",
        labelKey: "admin.field.body",
        markdown: true,
      },
      ...DetailPage.fields.canActivate,
      {
        name: "activateAt",
        type: "datetime",
      },
      ...DetailPage.fields.tracked,
    ]}
    query={QUERY}
    extraActions={DetailPage.extraActions.canActivate}
  />
);

// ==

const QUERY = gql`
  query eventPostForEventPostDetail($id: ID!) {
    eventPost(filter: { id_Overlap: [$id] }) {
      id
      event {
        id
        title
        group {
          id
          name
        }
      }
      body
      ...TrackedFields
      ...CanActivateFields
    }
  }
  ${DetailPage.fragments.tracked}
  ${DetailPage.fragments.canActivate}
`;
