import { gql } from "@apollo/client";

import { FormPage } from "../../components/pages";

export const PremiumMembershipForm = () => (
  <FormPage
    resource="premiumMembership"
    fields={[
      {
        name: "premium",
        type: "object",
        resource: "premium",
        disabled: ({ isUpdate }) => isUpdate,
      },
      {
        name: "member",
        type: "object",
        resource: "member",
        disabled: ({ isUpdate }) => isUpdate,
      },
      {
        name: "external",
        type: "object",
        resource: "externalPremiumMembership",
        disabled: ({ isUpdate }) => isUpdate,
      },
      {
        name: "identifier",
      },
      {
        name: "email",
        type: "email",
      },
      {
        name: "phone",
        type: "phone",
      },
      {
        name: "name",
      },
      {
        name: "birthDate",
        type: "date",
      },
    ]}
    query={QUERY}
    createMutation={CREATE_MUTATION}
    updateMutation={UPDATE_MUTATION}
  />
);

// ==

const FORM_FIELDS_FRAGMENT = gql`
  fragment PremiumMembershipFormFields on PremiumMembership {
    id
    premium {
      id
    }
    member {
      id
    }
    name
    email
    birthDate
    external {
      id
    }
    identifier
    email
    phone
    name
    birthDate
  }
`;

const QUERY = gql`
  query premiumMembershipForPremiumMembershipForm($id: ID!) {
    premiumMembership(filter: { id_Overlap: [$id] }) {
      ...PremiumMembershipFormFields
    }
  }
  ${FORM_FIELDS_FRAGMENT}
`;

const CREATE_MUTATION = gql`
  mutation createPremiumMembershipForPremiumMembershipForm(
    $data: PremiumMembershipMutationInput!
  ) {
    createPremiumMembership(data: $data) {
      ok
      errors {
        ...MutationErrorFields
      }
      object {
        ...PremiumMembershipFormFields
      }
    }
  }
  ${FormPage.fragments.mutationError}
  ${FORM_FIELDS_FRAGMENT}
`;

const UPDATE_MUTATION = gql`
  mutation updatePremiumMembershipForPremiumMembershipForm(
    $data: PremiumMembershipMutationInput!
    $id: ID!
  ) {
    updatePremiumMembership(data: $data, id: $id, partial: true) {
      ok
      errors {
        ...MutationErrorFields
      }
      object {
        ...PremiumMembershipFormFields
      }
    }
  }
  ${FormPage.fragments.mutationError}
  ${FORM_FIELDS_FRAGMENT}
`;
