import { gql } from "@apollo/client";

import { FormPage } from "../../components/pages";

export const GroupMainScreenForm = () => (
  <FormPage
    resource="groupMainScreen"
    fields={[
      {
        type: "fieldset",
        label: "",
        fields: [
          {
            name: "group",
            type: "object",
            resource: "group",
            disabled: ({ isUpdate }) => isUpdate,
          },
          {
            name: "name",
          },
          {
            name: "defaultProfileImage",
            type: "imageMeta",
            descriptionKey: "admin.form.guide.defaultProfileImage",
          },
          {
            name: "gridImage",
            type: "imageMeta",
            labelKey: "admin.field.mainGridImage",
          },
          {
            name: "logoImage",
            type: "imageMeta",
            labelKey: "admin.field.mainLogoImage",
          },
        ],
      },
      ...FormPage.fields.canActivate,
    ]}
    query={QUERY}
    createMutation={CREATE_MUTATION}
    updateMutation={UPDATE_MUTATION}
  />
);

// ==

const FORM_FIELDS_FRAGMENT = gql`
  fragment GroupMainScreenFormFields on GroupMainScreen {
    id
    group {
      id
      name
    }
    name
    defaultProfileImage {
      ...ImageFields
    }
    gridImage {
      ...ImageFields
    }
    logoImage {
      ...ImageFields
    }
    ...CanActivateFields
  }
  ${FormPage.fragments.canActivate}
  ${FormPage.fragments.image}
`;

const QUERY = gql`
  query GroupMainScreenForGroupMainScreenForm($id: ID!) {
    groupMainScreen(filter: { id_Overlap: [$id] }) {
      ...GroupMainScreenFormFields
    }
  }
  ${FORM_FIELDS_FRAGMENT}
`;

const CREATE_MUTATION = gql`
  mutation createGroupMainScreenForGroupMainScreenForm(
    $data: GroupMainScreenMutationInput!
  ) {
    createGroupMainScreen(data: $data) {
      ok
      errors {
        ...MutationErrorFields
      }
      object {
        ...GroupMainScreenFormFields
      }
    }
  }
  ${FormPage.fragments.mutationError}
  ${FORM_FIELDS_FRAGMENT}
`;

const UPDATE_MUTATION = gql`
  mutation updateGroupMainScreenForGroupMainScreenForm(
    $data: GroupMainScreenMutationInput!
    $id: ID!
  ) {
    updateGroupMainScreen(data: $data, id: $id, partial: true) {
      ok
      errors {
        ...MutationErrorFields
      }
      object {
        ...GroupMainScreenFormFields
      }
    }
  }
  ${FormPage.fragments.mutationError}
  ${FORM_FIELDS_FRAGMENT}
`;
