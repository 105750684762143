import { gql } from "@apollo/client";

import { getValueFromJsonString, isEmpty } from "../../utils/helpers";
import { BoothWaitListBulkEnterButton } from "../actions/BoothWaitListBulkEnterButton.jsx";
import { BoothWaitListCallButton } from "../actions/BoothWaitListCallButton.jsx";
import { ListWidget } from "../list";
import { Can } from "../permission";

export const BoothWaitListListWidget = (props) => (
  <ListWidget
    resource="boothWaitListEntry"
    fields={[
      {
        name: "booth",
        type: "object",
        resource: "booth",
        filterKey: "booth_Overlap",
      },
      {
        name: "groupedNumber",
        type: "integer",
        labelKey: "admin.field.boothWaitListGroupedNumber",
        filterKey: "groupedNumber_Range",
      },
      {
        name: "order",
        type: "integer",
        labelKey: "admin.field.boothWaitListOrder",
        filterKey: "order_Range",
      },
      {
        name: "nickname",
        // type: "object",
        // resource: "member",
        getter: (obj) => obj.member.nickname,
        // filterKey: "member_Overlap",
      },
      {
        name: "name",
        // type: "object",
        // resource: "user",
        getter: (obj) =>
          getValueFromJsonString("name", obj.premiumMembershipSnapshot) ??
          obj.member.user.name,
        // filterKey: "member_User_Overlap",
      },
      {
        name: "membershipIdentifier",
        labelKey: "admin.field.membershipIdentifier",
        getter: ({ premiumMembershipSnapshot }) =>
          getValueFromJsonString("identifier", premiumMembershipSnapshot),
      },
      {
        name: "member.user.phone",
        type: "phone",
        labelKey: "admin.field.phone",
        filterKey: "member_User_Phone_Overlap",
      },
      {
        name: "email",
        type: "email",
        labelKey: "admin.field.email",
      },
      {
        name: "status",
        type: "choice",
        enumType: "BoothWaitListStatus",
        filterKey: "status_Overlap",
      },
      {
        name: "entranceCode",
        labelKey: "admin.field.boothWaitListEntranceCode",
        filterKey: "entranceCode_Exact",
      },
      {
        name: "createdAt",
        type: "datetime",
        filterKey: "createdAt_Range",
      },
    ]}
    query={QUERY}
    hideList
    hideCreate
    hideDetail
    isRowSelectable={true}
    extraActions={({ refetch, selectedRowKeys, setSelectedRowKeys }) => (
      <>
        <Can action="update" targetType="BOOTH_WAIT_LIST_ENTRY">
          <BoothWaitListCallButton
            boothId={props.booth.id}
            onChange={() => refetch()}
            selectedRowKeys={selectedRowKeys}
            setSelectedRowKeys={setSelectedRowKeys}
            disabled={
              props.booth.operationStatus === "CLOSED" || isEmpty(selectedRowKeys)
            }
          />
        </Can>
        <Can action="update" targetType="BOOTH_WAIT_LIST_ENTRY">
          <BoothWaitListBulkEnterButton
            boothId={props.booth.id}
            onChange={() => refetch()}
            selectedRowKeys={selectedRowKeys}
            setSelectedRowKeys={setSelectedRowKeys}
            disabled={
              props.booth.operationStatus === "CLOSED" || isEmpty(selectedRowKeys)
            }
          />
        </Can>
      </>
    )}
    {...props}
  />
);

// ==

const QUERY = gql`
  query boothWaitListForBoothWaitListListWidget(
    $filter: BoothWaitListEntryFilterInput
    $sort: [BoothWaitListEntrySortInput]
    $page: PageInput
  ) {
    boothWaitList(filter: $filter, sort: $sort, page: $page) {
      objects {
        id
        booth {
          id
          title
          operationStatus
          waitStatus
        }
        member {
          id
          nickname
          user {
            id
            name
            email
            phone
          }
          isActive
        }
        premiumMembershipSnapshot
        status
        order
        groupedNumber
        entranceCode
        createdAt
        email
      }
      ...ListFields
    }
  }
  ${ListWidget.fragments.list}
`;
