import React, { useRef } from "react";
import ReactQuill from "react-quill";

export const HTMLField = ({ value }) => {
  return (
    <ReactQuill
      modules={{
        toolbar: false,
        clipboard: {
          matchVisual: false,
        },
      }}
      readOnly={true}
      value={value}
    />
  );
};
