import { gql } from "@apollo/client";

import { FormPage } from "../../components/pages";

export const GroupFeatureFlagForm = () => (
  <FormPage
    resource="groupFeatureFlag"
    fields={[
      {
        type: "fieldset",
        label: "",
        fields: [
          {
            name: "group",
            type: "object",
            resource: "group",
            disabled: ({ isUpdate }) => isUpdate,
          },
          {
            name: "category",
            type: "choice",
            enumType: "GroupFeatureFlagCategory",
          },
        ],
      },
      ...FormPage.fields.canActivate,
    ]}
    query={QUERY}
    createMutation={CREATE_MUTATION}
    updateMutation={UPDATE_MUTATION}
  />
);

// ==

const FORM_FIELDS_FRAGMENT = gql`
  fragment GroupFeatureFlagFormFields on GroupFeatureFlag {
    id
    group {
      id
    }
    category
    ...CanActivateFields
  }
  ${FormPage.fragments.canActivate}
`;

const QUERY = gql`
  query groupFeatureFlagForGroupFeatureFlagForm($id: ID!) {
    groupFeatureFlag(filter: { id_Overlap: [$id] }) {
      ...GroupFeatureFlagFormFields
    }
  }
  ${FORM_FIELDS_FRAGMENT}
`;

const CREATE_MUTATION = gql`
  mutation createGroupFeatureFlagForGroupFeatureFlagForm(
    $data: GroupFeatureFlagMutationInput!
  ) {
    createGroupFeatureFlag(data: $data) {
      ok
      errors {
        ...MutationErrorFields
      }
      object {
        ...GroupFeatureFlagFormFields
      }
    }
  }
  ${FormPage.fragments.mutationError}
  ${FORM_FIELDS_FRAGMENT}
`;

const UPDATE_MUTATION = gql`
  mutation updateGroupFeatureFlagForGroupFeatureFlagForm(
    $data: GroupFeatureFlagMutationInput!
    $id: ID!
  ) {
    updateGroupFeatureFlag(data: $data, id: $id, partial: true) {
      ok
      errors {
        ...MutationErrorFields
      }
      object {
        ...GroupFeatureFlagFormFields
      }
    }
  }
  ${FormPage.fragments.mutationError}
  ${FORM_FIELDS_FRAGMENT}
`;
