import { FC } from "react";
import { useTranslation } from "react-i18next";

import { Button, Input } from "antd";

import { FilterProps } from "@/types/filter/FilterProps";
import { isEmpty } from "@/utils/helpers";

import { FilterButtonContainer, FilterDivider, FilterInputContainer } from "./styled";

interface Props extends FilterProps {
  title: string;
}

export const TextFilter: FC<Props> = ({
  setSelectedKeys,
  selectedKeys,
  confirm,
  clearFilters,
  // ==
  title,
}) => {
  const { t } = useTranslation();
  return (
    <div onKeyDown={(e) => e.stopPropagation()}>
      <FilterInputContainer>
        <Input
          placeholder={`Search ${title}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => confirm()}
        />
      </FilterInputContainer>
      <FilterDivider />
      <FilterButtonContainer>
        <Button
          type="link"
          size="small"
          disabled={isEmpty(selectedKeys)}
          onClick={clearFilters}
        >
          {t("admin.common.reset")}
        </Button>
        <Button type="primary" onClick={() => confirm()} size="small">
          {t("admin.common.ok")}
        </Button>
      </FilterButtonContainer>
    </div>
  );
};
