import { gql } from "@apollo/client";

import { FormPage } from "../../components/pages";

export const BulkEmailForm = () => (
  <FormPage
    resource="bulkEmail"
    fields={[
      {
        type: "fieldset",
        labelKey: "admin.fieldset.content",
        fields: [
          {
            name: "titleContent",
            labelKey: "admin.field.title",
            type: "translatable",
          },
          {
            name: "bodyContent",
            labelKey: "admin.field.body",
            type: "translatable",
            // markdown: true,
            rows: 50,
          },
        ],
      },
      ...FormPage.fields.canActivate,
    ]}
    query={QUERY}
    createMutation={CREATE_MUTATION}
    updateMutation={UPDATE_MUTATION}
  />
);

// ==

const FORM_FIELDS_FRAGMENT = gql`
  fragment BulkEmailFormFields on BulkEmail {
    id
    title
    titleContent {
      ...TranslatableContentFields
    }
    body
    bodyContent {
      ...TranslatableContentFields
    }
    ...CanActivateFields
  }
  ${FormPage.fragments.canActivate}
  ${FormPage.fragments.translatableContent}
`;

const QUERY = gql`
  query bulkEmailsForBulkEmailForm($id: ID!) {
    bulkEmail(filter: { id_Overlap: [$id] }) {
      ...BulkEmailFormFields
    }
  }
  ${FORM_FIELDS_FRAGMENT}
`;

const CREATE_MUTATION = gql`
  mutation createBulkEmailForBulkEmailForm($data: BulkEmailMutationInput!) {
    createBulkEmail(data: $data) {
      ok
      errors {
        ...MutationErrorFields
      }
      object {
        ...BulkEmailFormFields
      }
    }
  }
  ${FormPage.fragments.mutationError}
  ${FORM_FIELDS_FRAGMENT}
`;

const UPDATE_MUTATION = gql`
  mutation updateBulkEmailForBulkEmailForm($data: BulkEmailMutationInput!, $id: ID!) {
    updateBulkEmail(data: $data, id: $id, partial: true) {
      ok
      errors {
        ...MutationErrorFields
      }
      object {
        ...BulkEmailFormFields
      }
    }
  }
  ${FormPage.fragments.mutationError}
  ${FORM_FIELDS_FRAGMENT}
`;
