import { gql } from "@apollo/client";

import { DetailPage } from "../../components/pages";

export const HashtagDetail = () => (
  <DetailPage
    resource="hashtag"
    fields={[
      {
        name: "id",
      },
      {
        name: "group",
        type: "object",
        resource: "group",
      },
      {
        name: "name",
      },
      {
        name: "isHighlighted",
        type: "boolean",
      },
      ...DetailPage.fields.canActivate,
      ...DetailPage.fields.tracked,
    ]}
    query={QUERY}
    extraActions={DetailPage.extraActions.canActivate}
  />
);

// ==

const QUERY = gql`
  query hashtagForHashtagDetail($id: ID!) {
    hashtag(filter: { id_Overlap: [$id] }) {
      id
      group {
        id
        name
      }
      name
      isHighlighted
      ...TrackedFields
      ...CanActivateFields
    }
  }
  ${DetailPage.fragments.tracked}
  ${DetailPage.fragments.canActivate}
`;
