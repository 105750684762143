import { forwardRef, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { gql, useLazyQuery } from "@apollo/client";

import { Button, Space, Typography } from "antd";

import { isArray } from "../../utils/helpers";
import { SelectClipAction } from "../actions";
import { ClipThumbnail } from "../media";

const { Text } = Typography;

export const DrawerSelectClip = forwardRef(
  ({ value, onChange, multiple = false, ...props }, ref) => {
    const { t } = useTranslation();
    const [selected, setSelected] = useState([]);
    const [fetchForSelected] = useLazyQuery(QUERY);

    const normalizedValue = useMemo(() => normalize(value), [value]);

    useEffect(() => {
      if (!normalizedValue.length) {
        setSelected([]);
      } else {
        fetchForSelected({
          variables: {
            filter: {
              id_Overlap: normalizedValue,
            },
          },
          onCompleted: (response) => setSelected(response.allClips),
        });
      }
    }, [normalizedValue, setSelected, fetchForSelected]);

    //

    return (
      <Space direction="vertical">
        <Space direction="vertical">
          {selected?.map((clip) => (
            <Space key={clip.id} align="start">
              <ClipThumbnail clip={clip} />
              <Text>{clip.title}</Text>
            </Space>
          ))}
        </Space>
        <Space>
          <SelectClipAction
            onSelect={(values) =>
              onChange(multiple ? values : values.length > 0 ? values[0] : null)
            }
            multiple={multiple}
            {...props}
          >
            {({ openDrawer, disabled }) => (
              <Button disabled={disabled} onClick={openDrawer}>
                {t("admin.common.actionTitle", {
                  action: multiple
                    ? t("admin.common.add")
                    : selected.length > 0
                    ? t("admin.common.change")
                    : t("admin.common.select"),
                  name: t("admin.resource.clip.singular"),
                })}
              </Button>
            )}
          </SelectClipAction>
          {selected.length > 0 && (
            <Button danger onClick={() => onChange(multiple ? [] : null)}>
              {t("admin.common.clear")}
            </Button>
          )}
        </Space>
      </Space>
    );
  },
);

// ==

const normalize = (value) => (isArray(value) ? value : value ? [value] : []);

// ==

const QUERY = gql`
  query allClipsForDrawerSelectClip($filter: ClipFilterInput) {
    allClips(filter: $filter) {
      id
      title
      ...ClipThumbnailFields
    }
  }
  ${ClipThumbnail.fragments.clip}
`;
