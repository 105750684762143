import { useState } from "react";
import { useTranslation } from "react-i18next";

import { gql, useMutation } from "@apollo/client";

import { SendOutlined } from "@ant-design/icons";
import { Button, Modal, Typography } from "antd";

import { useApp } from "../../hooks/app";
import { MUTATION_ERROR_FRAGMENT } from "../../utils/gql/fragments";

export const SendBulkEmailButton = ({
  template,
  onSuccess = undefined,
  onlyIcon = false,
  ...props
}) => {
  const { t } = useTranslation();

  const variables = {
    template: template,
  };

  const onCompleted = (response) => {
    const { ok, errors } = Object.values(response)[0];
    if (ok) {
      message.success(t("admin.message.requestSuccess"));
      onSuccess?.();
      onCancel();
    } else {
      message.error(
        t("admin.message.requestFailure", {
          message: errors[0].messages[0],
        }),
      );
    }
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [send, { loading }] = useMutation(SEND_BULK_EMAIL_MUTATION, {
    variables,
    onCompleted,
  });
  const { message } = useApp();

  // ==

  const onClick = () => setIsModalOpen(true);
  const onOk = () => send();
  const onCancel = () => setIsModalOpen(false);

  return (
    <>
      <Button icon={<SendOutlined />} onClick={onClick} {...props}>
        {onlyIcon ? null : t("admin.button.sendBulkEmails")}
      </Button>
      <Modal
        title={t("admin.modal.sendBulkEmails.title")}
        open={isModalOpen}
        confirmLoading={loading}
        okText={t(`admin.common.confirmation`)}
        okButtonProps={{
          icon: <SendOutlined />,
        }}
        cancelText={t("admin.common.cancel")}
        onOk={onOk}
        onCancel={onCancel}
      >
        <Typography.Text>
          {t("admin.component.activateButton.inactivateMessage")}
        </Typography.Text>
      </Modal>
    </>
  );
};

// ==

const SEND_BULK_EMAIL_MUTATION = gql`
  mutation sendBulkEmailsForSendBulkEmailsButton($template: String!) {
    sendBulkEmails(template: $template) {
      ok
      errors {
        ...MutationErrorFields
      }
    }
  }
  ${MUTATION_ERROR_FRAGMENT}
`;
