import { gql } from "@apollo/client";

import {
  DownloadBannedPhrasesButton,
  UploadBannedPhrasesButton,
} from "../../components/actions";
import { ListPage } from "../../components/pages";
import { Can } from "../../components/permission";

export const BannedPhraseList = () => (
  <ListPage
    resource="bannedPhrase"
    subtitle="Manage banned phrases in Fans'."
    fields={[
      {
        name: "id",
      },
      {
        name: "phrase",
      },
      {
        name: "validationCategory",
        type: "choice",
        enumType: "StringMatchCategory",
        filterKey: "validationCategory_Overlap",
      },
      ...ListPage.fields.tracked,
    ]}
    query={QUERY}
    extraActions={({ filter, sort, result }) => (
      <>
        <Can action="download" targetType="BANNED_PHRASE">
          <DownloadBannedPhrasesButton
            count={result?.count}
            filter={filter}
            sort={sort}
            type="text"
            onlyIcon
          />
        </Can>
        <Can action="upload" targetType="BANNED_PHRASE">
          <UploadBannedPhrasesButton type="text" onlyIcon />
        </Can>
      </>
    )}
    extraRowActions={ListPage.extraRowActions.delete}
  />
);

// ==

const QUERY = gql`
  query bannedPhrasesforBannedPhraseList(
    $filter: BannedPhraseFilterInput
    $sort: [BannedPhraseSortInput]
    $page: PageInput
  ) {
    bannedPhrases(filter: $filter, sort: $sort, page: $page) {
      objects {
        id
        phrase
        validationCategory
        ...TrackedFields
      }
      ...ListFields
    }
  }
  ${ListPage.fragments.list}
  ${ListPage.fragments.tracked}
`;
