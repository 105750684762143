import { useSearchParams } from "react-router-dom";

import { gql } from "@apollo/client";

import { DetailPage } from "../../components/pages";
import { TRACKED_FRAGMENT } from "../../utils/gql/fragments";
import { TranslationRequestTargetContentPanel } from "./TranslationRequestTargetContentPanel";

export const TranslationRequestTargetContentDetail = () => {
  const [searchParams] = useSearchParams();
  const fromWhere = searchParams.get("from");
  const isRequestManagementContext = fromWhere === "request";

  const fields = [
    {
      name: "translationRequest.id",
      label: "Request ID",
    },
    {
      name: "id",
      label: "ID",
    },
    {
      name: "translationRequest.group.name",
      type: "object",
      resource: "group",
      getter: (value) => value.translationRequest.group,
    },
    {
      name: "translationRequest.title",
      labelKey: "admin.field.title",
    },
    {
      name: "translationRequest.category",
      labelKey: "admin.field.category",
    },
    {
      name: "translationRequest.requestor",
      labelKey: "admin.field.requestor",
      type: "object",
      resource: "staff",
      getter: (value) => value.translationRequest.requestor,
    },
    {
      name: "assignee",
      type: "object",
      resource: "staff",
      getter: (value) => value.assignee,
    },
    {
      name: "requestStatus",
      type: "choice",
      enumType: "TranslationRequestStatus",
    },
    {
      name: "progressStatus",
      type: "choice",
      enumType: "TranslationProgressStatus",
      hidden: isRequestManagementContext,
    },
    {
      name: "translationRequest.inputSource",
      labelKey: "admin.field.inputSource",
      type: "choice",
      enumType: "TranslationRequestInputSource",
    },
    {
      name: "translationRequest.targetWordCountAll",
      labelKey: "admin.field.targetWordCountAll",
      type: "number",
      getter: (value) =>
        value.translationRequest.targetContents.reduce(
          (acc, cur) => acc + cur.targetWordCount,
          0,
        ),
    },
    {
      name: "targetWordCount",
      type: "number",
    },
    {
      name: "translationRequest.sourceLanguage",
      labelKey: "admin.field.sourceLanguage",
      type: "choice",
      enumType: "ServiceLanguage",
    },
    {
      name: "targetLanguage",
      type: "choice",
      enumType: "ServiceLanguage",
    },
    {
      name: "translationRequest.dueAt",
      labelKey: "admin.field.dueAt",
      type: "datetime",
      format: "YYYY-MM-DD",
    },
    {
      name: "assignedAt",
      type: "datetime",
    },
    {
      name: "createdAt",
      type: "datetime",
    },
    {
      name: "finalSubmittedAt",
      type: "datetime",
    },
    {
      name: "transmissionConfirmedAt",
      type: "datetime",
    },
    {
      name: "closedAt",
      type: "datetime",
    },
  ];

  return (
    <DetailPage
      resource="translationRequestTargetContent"
      fields={fields}
      query={QUERY}
      hideUpdate={true}
      tabs={[
        {
          path: "",
          label: "",
          children: ({ obj }) => (
            <TranslationRequestTargetContentPanel
              targetContent={obj}
              showForm={false}
            />
          ),
        },
      ]}
    />
  );
};

// ==

const QUERY = gql`
  query translationRequestTargetContentForDetail($id: ID!) {
    translationRequestTargetContent(filter: { id_Overlap: [$id] }) {
      translationRequest {
        id
        group {
          id
          name
        }
        requestor {
          id
          user {
            name
          }
        }
        sourceLanguage
        title
        category
        inputSource
        plainText
        uploadFiles {
          key
          mime
          size
          url
          downloadUrl
        }
        targetContents {
          targetWordCount
        }
        dueAt
        additionalRequestComment
      }
      assignee {
        id
        user {
          name
        }
      }
      revisions {
        author {
          id
          user {
            name
            email
          }
        }
        progressStatus
        memo
        createdAt
      }
      id
      targetLanguage
      requestStatus
      progressStatus
      plainText
      uploadFiles {
        key
        mime
        size
        url
        downloadUrl
      }
      reopenCount
      targetWordCount
      assignedAt
      finalSubmittedAt
      transmissionConfirmedAt
      closedAt
      ...TrackedFields
    }
  }
  ${TRACKED_FRAGMENT}
`;
