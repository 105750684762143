import { useTranslation } from "react-i18next";

import { gql } from "@apollo/client";

import { FormPage } from "../../components/pages";

export const PremiumForm = () => {
  const { t } = useTranslation();
  return (
    <FormPage
      resource="premium"
      fields={[
        {
          type: "fieldset",
          fields: [
            {
              name: "group",
              type: "object",
              resource: "group",
              disabled: ({ isUpdate }) => isUpdate,
            },
            {
              name: "nameContent",
              labelKey: "admin.field.name",
              type: "translatable",
            },
            {
              name: "nickname",
              descriptionKey: "admin.form.guide.nickname",
              controlProps: {
                maxLength: 8,
              },
            },
            {
              name: "code",
              descriptionKey: "admin.form.guide.code",
              controlProps: {
                maxLength: 20,
              },
            },
          ],
        },
        {
          type: "fieldset",
          labelKey: "admin.fieldset.membershipPeriod",
          fields: [
            {
              name: "startAt",
              type: "datetime",
            },
            {
              name: "endAt",
              type: "datetime",
              showNullable: true,
              nullableText: t("admin.common.noEndDate"),
            },
          ],
        },
        {
          type: "fieldset",
          labelKey: "admin.fieldset.membershipBenefits",
          fields: [
            // {
            //   name: "cardImage",
            //   type: "imageMeta",
            //   descriptionKey: "admin.form.guide.cardImagePremium",
            // },
            {
              name: "cardImageHorizontal",
              type: "imageMeta",
            },
            {
              name: "cardImageVertical",
              type: "imageMeta",
            },
            {
              name: "doesProvideCard",
              type: "boolean",
            },
            {
              name: "doesProvidePackage",
              type: "boolean",
            },
            {
              name: "doesProvideContent",
              type: "boolean",
            },
            {
              name: "doesProvideEvent",
              type: "boolean",
            },
            {
              name: "doesProvideTicket",
              type: "boolean",
            },
          ],
        },
        ...FormPage.fields.canActivate,
      ]}
      query={QUERY}
      createMutation={CREATE_MUTATION}
      updateMutation={UPDATE_MUTATION}
    />
  );
};

// ==

const FORM_FIELDS_FRAGMENT = gql`
  fragment PremiumFormFields on Premium {
    id
    group {
      id
      name
      code
    }
    name
    nameContent {
      ...TranslatableContentFields
    }
    code
    nickname
    startAt
    endAt
    cardImage {
      ...ImageFields
    }
    cardImageHorizontal {
      ...ImageFields
    }
    cardImageVertical {
      ...ImageFields
    }
    doesProvideCard
    doesProvidePackage
    doesProvideContent
    doesProvideEvent
    doesProvideTicket
    ...CanActivateFields
  }
  ${FormPage.fragments.canActivate}
  ${FormPage.fragments.image}
  ${FormPage.fragments.translatableContent}
`;

const QUERY = gql`
  query premiumForPremiumForm($id: ID!) {
    premium(filter: { id_Overlap: [$id] }) {
      ...PremiumFormFields
    }
  }
  ${FORM_FIELDS_FRAGMENT}
`;

const CREATE_MUTATION = gql`
  mutation createPremiumForPremiumForm($data: PremiumMutationInput!) {
    createPremium(data: $data) {
      ok
      errors {
        ...MutationErrorFields
      }
      object {
        ...PremiumFormFields
      }
    }
  }
  ${FormPage.fragments.mutationError}
  ${FORM_FIELDS_FRAGMENT}
`;

const UPDATE_MUTATION = gql`
  mutation updatePremiumForPremiumForm($data: PremiumMutationInput!, $id: ID!) {
    updatePremium(data: $data, id: $id, partial: true) {
      ok
      errors {
        ...MutationErrorFields
      }
      object {
        ...PremiumFormFields
      }
    }
  }
  ${FormPage.fragments.mutationError}
  ${FORM_FIELDS_FRAGMENT}
`;
