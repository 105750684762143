import { useCallback } from "react";
import { useTranslation } from "react-i18next";

import { gql } from "@apollo/client";

import type { UploadRequestOption } from "rc-upload/lib/interface";

import { getApolloClient } from "@/utils/gql";
import { stripDunderKeys, stripEmptyValues } from "@/utils/helpers";

type UseS3UploadOptions = {
  /**
   * onSuccess will be called with the metadata of the uploaded file.
   * @default true
   */
  sync?: boolean;
};

export type UploadedFileMeta = {
  key: string;
  mime: string;
  size?: number;
  width?: number;
  height?: number;
  duration?: number;
  bitrate?: number;
  m3u8Key?: string;
  thumbnailKey?: string;
  m3u8Url?: string;
  thumbnailUrl?: string;
  url?: string;
  downloadUrl?: string;
};

export const useS3Upload = (options: UseS3UploadOptions) => {
  const { t } = useTranslation();
  const { sync = true } = options;

  return useCallback(
    async ({
      file,
      onSuccess,
      onError,
      onProgress,
    }: Pick<UploadRequestOption, "file" | "onSuccess" | "onError" | "onProgress">) => {
      const client = getApolloClient();
      const execute = async () => {
        // Generate signed URL for uploading
        const { data } = await client.query({
          query: UPLOAD_URL_QUERY,
          variables: { name: (file as File).name, contentType: (file as File).type },
          fetchPolicy: "no-cache",
        });

        const { signedUrl, key, fields } = data.uploadUrlPost;
        const parsedFields = JSON.parse(fields);
        const formData = new FormData();

        if (!signedUrl) {
          throw new Error(t("admin.message.mediaUploadFailureSignedUrl"));
        }
        onProgress?.({ percent: 30 });

        Object.keys(parsedFields).forEach((key) => {
          formData.append(key, parsedFields[key]);
        });
        formData.append("file", file);

        // Upload to S3
        const response = await fetch(signedUrl, {
          method: "POST",
          body: formData,
        });

        if (!response.ok) {
          throw new Error(t("admin.message.mediaUploadFailureUpload"));
        }

        if (!sync) {
          return {
            key,
            mime: (file as File).type || "application/octet-stream",
          };
        }

        onProgress?.({ percent: 70 });

        // Retrieve meta data
        const metaDataResult = await client.query({
          query: UPLOAD_META_QUERY,
          variables: { key },
          fetchPolicy: "no-cache",
        });

        const uploadMeta = metaDataResult.data.uploadMeta;

        if (!uploadMeta) {
          throw new Error(t("admin.message.mediaUploadFailureMeta"));
        }

        return stripEmptyValues(stripDunderKeys(uploadMeta));
      };
      try {
        const result = await execute();
        onSuccess?.(result);
        return result;
      } catch (error) {
        onError?.(error as Error);
      }
    },
    [sync],
  );
};

const UPLOAD_URL_QUERY = gql`
  query uploadUrlPost($name: String!, $contentType: String!) {
    uploadUrlPost(name: $name, contentType: $contentType) {
      signedUrl
      key
      fields
    }
  }
`;

const UPLOAD_META_QUERY = gql`
  query uploadMeta($key: String!) {
    uploadMeta(key: $key) {
      key
      mime
      size
      width
      height
      duration
      bitrate
      m3u8Key
      thumbnailKey
      url
    }
  }
`;
