import { forwardRef } from "react";

import { gql } from "@apollo/client";

import { Typography } from "antd";

import { ObjectSelect } from "../fields";

export const SelectEventV2Participant = forwardRef(
  ({ value, onChange, ...props }, ref) => {
    return (
      <ObjectSelect
        resource="eventV2Application"
        placeholder="-- Member"
        query={QUERY}
        baseFilter={{
          event_Overlap: [props.data.event],
          isActive_Exact: true,
        }}
        optionRender={(eventV2Application) => (
          <>
            {eventV2Application.name ||
              eventV2Application.member.user.name ||
              eventV2Application.member.nickname}
            <Typography.Text type="secondary" style={styles.secondary}>
              {eventV2Application.member.group.name}
            </Typography.Text>
            <Typography.Text type="secondary" style={styles.secondary}>
              {eventV2Application.birthDate}
            </Typography.Text>
          </>
        )}
        optionGetter={(eventV2Application) => ({
          value: eventV2Application.id,
          label:
            eventV2Application.name ||
            eventV2Application.member.user.name ||
            eventV2Application.member.nickname,
        })}
        value={value}
        onChange={onChange}
        ref={ref}
        {...props}
      />
    );
  },
);

// ==

const QUERY = gql`
  query membersForSelectEventV2Participant($filter: EventV2ApplicationFilterInput) {
    eventV2Applications(filter: $filter, sort: [{ type: RELEVANCE, direction: DESC }]) {
      objects {
        id
        name
        birthDate
        member {
          id
          nickname
          user {
            id
            name
          }
          group {
            id
            name
          }
        }
      }
    }
  }
`;

// ==

const styles = {
  secondary: {
    marginLeft: 8,
    fontSize: "0.8em",
  },
};
