import { useState } from "react";

import { useAuthentication } from "./auth";
import { useLocalStorage } from "./localStorage";

const allGroupAccessibleRoles = ["ADMIN", "EXTERNAL_MODERATOR", "INTERNAL_MD_MANAGER"];

export const useUniversalGroup = () => {
  const { user } = useAuthentication();
  const groups = user.staff.allPermittedGroups;
  const hasMultipleGroups =
    groups.length > 1 ||
    user.staff.isSuper ||
    user.staff.roleCategories.some((category) =>
      allGroupAccessibleRoles.includes(category),
    );
  const [cache, setCache] = useLocalStorage(
    "group",
    groups.length === 1 ? groups[0].id : null,
  );
  const [state, setState] = useState(cache);

  return {
    group: state,
    setGroup: (value) => {
      setCache(value);
      setState(value);
    },
    hasMultipleGroups,
  };
};
