import { gql } from "@apollo/client";

import { ListWidget } from "../list";

export const LanguageListWidget = (props) => (
  <ListWidget
    resource="language"
    fields={[
      {
        name: "id",
      },
      {
        name: "code",
        sortKey: "CODE",
      },
      {
        name: "name",
        filterKey: "name_Text",
        sortKey: "NAME",
      },
      {
        name: "nativeName",
        filterKey: "nativeName_Text",
      },
      {
        name: "isActiveForService",
        type: "boolean",
        filterKey: "isActiveForService_Exact",
      },
      {
        name: "isActiveForTranslation",
        type: "boolean",
        filterKey: "isActiveForTranslation_Exact",
      },
    ]}
    query={QUERY}
    {...props}
  />
);

// ==

const QUERY = gql`
  query languagesForLanguageListWidget(
    $filter: LanguageFilterInput
    $sort: [LanguageSortInput]
    $page: PageInput
  ) {
    languages(filter: $filter, sort: $sort, page: $page) {
      objects {
        id
        code
        name
        nativeName
        isActiveForService
        isActiveForTranslation
      }
      ...ListFields
    }
  }
  ${ListWidget.fragments.list}
`;
