import React from "react";

import { Tabs } from "antd";
import styled from "styled-components";

import { ServerEnum, useEnum } from "@/hooks/enum";

type Props = {
  hideTab?: boolean;
  activeKey?: string;
  onChangeActiveKey?: (activeKey: string) => void;
  render?: (language: ServerEnum) => React.ReactNode;
};

export const LanguageTab: React.FC<Props> = (props) => {
  const { render, activeKey, hideTab, onChangeActiveKey } = props;
  const { values: languages } = useEnum("ServiceLanguage");

  return (
    <StyledTabs
      className={hideTab ? "hide-tab" : ""}
      activeKey={activeKey}
      onChange={onChangeActiveKey}
      items={
        languages?.map((language) => {
          return {
            key: language.name,
            label: language.description,
            children: render?.(language) ?? null,
          };
        }) ?? []
      }
    />
  );
};

const StyledTabs = styled(Tabs).attrs({
  type: "card",
})`
  &.hide-tab .ant-tabs-nav {
    display: none;
  }
`;
