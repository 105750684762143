import { gql } from "@apollo/client";

import { ListPage } from "../../components/pages";

export const BulkEmailList = () => (
  <ListPage
    resource="bulkEmail"
    fields={[
      {
        name: "id",
      },
      {
        name: "title",
        truncate: true,
      },
      ...ListPage.fields.tracked,
    ]}
    query={QUERY}
    extraRowActions={ListPage.extraRowActions.canActivate}
  />
);

// ==

const QUERY = gql`
  query bulkEmailsForBulkEmailList(
    $filter: BulkEmailFilterInput
    $sort: [BulkEmailSortInput]
    $page: PageInput
  ) {
    bulkEmails(filter: $filter, sort: $sort, page: $page) {
      objects {
        id
        title
        ...TrackedFields
        ...CanActivateFields
      }
      ...ListFields
    }
  }
  ${ListPage.fragments.list}
  ${ListPage.fragments.tracked}
  ${ListPage.fragments.canActivate}
`;
