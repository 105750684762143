import { gql } from "@apollo/client";

import { DetailPage } from "../../components/pages";

export const CountryDetail = () => (
  <DetailPage
    resource="country"
    fields={[
      {
        name: "id",
      },
      {
        name: "continent",
        type: "object",
        resource: "continent",
      },
      {
        name: "subcontinent",
        type: "object",
        resource: "subcontinent",
      },
      {
        name: "code",
      },
      {
        name: "name",
      },
      {
        name: "phonePrefix",
      },
      ...DetailPage.fields.canActivate,
    ]}
    query={QUERY}
    extraActions={DetailPage.extraActions.canActivate}
  />
);

// ==

const QUERY = gql`
  query countryForCountryDetail($id: ID!) {
    country(filter: { id_Overlap: [$id] }) {
      id
      continent {
        id
        name
      }
      subcontinent {
        id
        name
      }
      phonePrefix
      code
      name
      isActive
      ...CanActivateFields
    }
  }
  ${DetailPage.fragments.canActivate}
`;
