import { forwardRef, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { Alert, Checkbox, DatePicker, Divider, Radio, Space, Typography } from "antd";

import { DateTimePicker } from "@/components/fields";
import type { CompositeFieldProps } from "@/types/form";
import { deepEqual, fromDay, toDay } from "@/utils/helpers";

const { Text } = Typography;

type Props = CompositeFieldProps<{
  isActive: boolean;
  activateAt?: string | null;
  inactivateAt?: string | null;
  doesSendPush?: boolean;
  firstActivatedAt?: string | null;
}> & {
  canNotInactivate?: boolean;
  canScheduleInactivation?: boolean;
  canChangeFirstActivatedAt?: boolean;
};

export const ActivationControl = forwardRef<HTMLDivElement, Props>(
  (
    {
      value,
      onChange,
      canScheduleInactivation = false,
      canChangeFirstActivatedAt = false,
      canNotInactivate = false,
      disabled = false,
    },
    ref,
  ) => {
    const wasActive = useRef(value?.isActive);
    disabled = disabled || (canNotInactivate && wasActive.current);
    const {
      isActive,
      activateAt = null,
      inactivateAt = null,
      doesSendPush,
      firstActivatedAt = null,
    } = value || {};
    const prevIsActive = useRef(isActive);
    const { t } = useTranslation();
    const [category, setCategory] = useState(
      isActive ? ACTIVE : activateAt ? SCHEDULED : INACTIVE,
    );
    const [activateAtValue, setActivateAtValue] = useState(activateAt);
    const [firstActivatedAtValue, setFirstActivatedAtValue] =
      useState(firstActivatedAt);
    const [inactivateAtValue, setInactivateAtValue] = useState(inactivateAt);
    const [doesSendPushValue, setDoesSendPushValue] = useState(doesSendPush || false);

    useEffect(() => {
      const newValue = {
        isActive: category === ACTIVE,
        activateAt: category === SCHEDULED ? activateAtValue : null,
        firstActivatedAt: canChangeFirstActivatedAt ? firstActivatedAtValue : null,
        inactivateAt: category === SCHEDULED ? inactivateAtValue : null,
        doesSendPush: doesSendPushValue,
      };
      if (
        !deepEqual(newValue, {
          isActive,
          activateAt,
          firstActivatedAt,
          inactivateAt,
          doesSendPush,
        })
      ) {
        onChange?.(newValue);
      }
    }, [
      isActive,
      activateAt,
      firstActivatedAt,
      inactivateAt,
      doesSendPush,
      onChange,
      // ==
      category,
      activateAtValue,
      firstActivatedAtValue,
      inactivateAtValue,
      doesSendPushValue,
    ]);

    return (
      <Space direction="vertical" size="large" style={styles.container}>
        <Space>
          <Radio.Group
            value={category}
            onChange={(e) => setCategory(e.target.value)}
            disabled={disabled}
            ref={ref}
          >
            <Radio value={ACTIVE}>
              {t("admin.component.activationControl.active")}
            </Radio>
            <Radio value={INACTIVE}>
              {t("admin.component.activationControl.inactive")}
            </Radio>
            <Radio value={SCHEDULED}>
              {t("admin.component.activationControl.scheduled")}
            </Radio>
          </Radio.Group>
          {!prevIsActive.current && (
            <DatePicker
              value={toDay(activateAt)}
              onChange={(date) => setActivateAtValue(fromDay(date))}
              disabled={disabled || category !== "scheduled"}
              showTime
              showToday
              placement="bottomRight"
              placeholder={"-- " + t("admin.common.placeholderActivationDate")}
            />
          )}
          {canScheduleInactivation && (
            <DatePicker
              value={toDay(inactivateAt)}
              onChange={(date) => setInactivateAtValue(fromDay(date))}
              disabled={disabled || category !== "scheduled"}
              showTime
              showToday
              placement="bottomRight"
              placeholder={"-- " + t("admin.common.placeholderInactivationDate")}
            />
          )}
        </Space>
        {canChangeFirstActivatedAt && (
          <>
            <Divider style={styles.divider} />
            <Space direction="vertical" size="large">
              <Alert
                type="warning"
                message={t("admin.component.activationControl.firstActivatedAtWarning")}
              />
              <Space direction="vertical">
                <Text>{t("admin.field.firstActivatedAt")}</Text>
                <DateTimePicker
                  value={toDay(firstActivatedAtValue)}
                  onChange={(date) => setFirstActivatedAtValue(fromDay(date))}
                />
              </Space>
            </Space>
          </>
        )}
        <Space>
          <Checkbox
            checked={doesSendPushValue}
            onChange={(e) => setDoesSendPushValue(e.target.checked)}
            disabled={disabled || category === INACTIVE}
          >
            {category === ACTIVE &&
              t("admin.component.activationControl.doesSendPushActive")}
            {category === INACTIVE &&
              t("admin.component.activationControl.doesSendPushInactive")}
            {category === SCHEDULED &&
              t("admin.component.activationControl.doesSendPushScheduled")}
          </Checkbox>
        </Space>
      </Space>
    );
  },
);

// ==

const ACTIVE = "active";
const INACTIVE = "inactive";
const SCHEDULED = "scheduled";

// ==

const styles = {
  container: {
    width: "100%",
  },
  divider: {
    margin: 0,
  },
};
