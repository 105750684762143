import { gql } from "@apollo/client";

import { ListWidget } from "../list";

export const EventV2WinnerListWidget = ({ filter, ...props }) => (
  <ListWidget
    resource="eventV2Winner"
    fields={[
      {
        name: "order",
        type: "integer",
        sortKey: "ORDER",
      },
      {
        name: "state",
        labelKey: "admin.field.applicationState",
        type: "choice",
        enumType: "EventV2WinnerState",
        filterKey: "state_Overlap",
      },
      {
        name: "member",
        type: "object",
        resource: "member",
        filterKey: "member_Overlap",
      },
      {
        name: "application.name",
        labelKey: "admin.field.name",
        getter: (obj) => obj.application.name || obj.member.user.name || "-",
        filterKey: "application_Name_Text",
      },
      {
        name: "application.birthDate",
        labelKey: "admin.field.birthDate",
      },
      {
        name: "application.premiumMembershipIdentifier",
        labelKey: "admin.field.membershipIdentifier",
        getter: (obj) => obj.application.premiumMembershipIdentifier || "-",
        filterKey: "application_PremiumMembershipIdentifier_Text",
      },
      {
        name: "application.phone",
        labelKey: "admin.field.phone",
      },
      {
        name: "application.memberPenaltyCount",
        labelKey: "admin.field.penaltyCount",
        type: "integer",
      },
      {
        name: "member.isActive",
        labelKey: "admin.field.memberIsActive",
        type: "boolean",
      },
      {
        name: "application.createdAt",
        labelKey: "admin.field.appliedAt",
        type: "datetime",
        sortKey: "CREATED_AT",
      },
    ]}
    query={QUERY}
    filter={filter}
    {...props}
  />
);

// ==

const QUERY = gql`
  query eventV2WinnersForEventV2WinnerListWidget(
    $filter: EventV2WinnerFilterInput
    $sort: [EventV2WinnerSortInput]
    $page: PageInput
  ) {
    eventV2Winners(filter: $filter, sort: $sort, page: $page) {
      objects {
        id
        member {
          id
          nickname
          penaltyCount
          isActive
          user {
            id
            name
            phone
            email
          }
        }
        event {
          id
          submissionCategory
        }
        application {
          id
          name
          phone
          birthDate
          premiumMembershipIdentifier
          memberPenaltyCount
          createdAt
        }
        order
        state
        attendanceState
      }
      ...ListFields
    }
  }
  ${ListWidget.fragments.list}
`;
