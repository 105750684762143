import { gql } from "@apollo/client";

import { DetailPage } from "../../components/pages";

export const TranslationValueDetail = () => (
  <DetailPage
    resource="translationValue"
    fields={[
      {
        name: "id",
      },
      {
        name: "code",
        type: "object",
        resource: "translationCode",
      },
      {
        name: "language",
        type: "choice",
        enumType: "ServiceLanguage",
      },
      {
        name: "value",
      },
      {
        name: "status",
        type: "choice",
        enumType: "TranslationStatus",
      },
      ...DetailPage.fields.tracked,
    ]}
    query={QUERY}
    extraActions={DetailPage.extraActions.delete}
  />
);

// ==

const QUERY = gql`
  query translationValueForTranslationValueDetail($id: ID!) {
    translationValue(filter: { id_Overlap: [$id] }) {
      id
      code {
        id
        code
      }
      language
      value
      status
      ...TrackedFields
    }
  }
  ${DetailPage.fragments.tracked}
`;
