import { gql } from "@apollo/client";

import { DownloadExternalPremiumMembershipsButton } from "../actions";
import { ListWidget } from "../list";
import { Can } from "../permission";

export const ExternalPremiumMembershipListWidget = (props) => (
  <ListWidget
    resource="externalPremiumMembership"
    fields={[
      {
        name: "id",
      },
      {
        name: "premium.group",
        label: "admin.resource.group.singular",
        type: "object",
        resource: "group",
        filterKey: "premium_Group_Overlap",
      },
      {
        name: "premium",
        type: "object",
        resource: "premium",
        filterKey: "premium_Overlap",
      },
      {
        name: "provider",
        type: "choice",
        enumType: "ExternalPremiumMembershipProvider",
        filterKey: "provider_Overlap",
      },
      {
        name: "identifier",
        filterKey: "identifier_Exact",
        sortKey: "IDENTIFIER",
      },
      {
        name: "isRegistered",
        type: "boolean",
        filterKey: "isRegistered_Exact",
      },
      {
        name: "username",
        filterKey: "username_Exact",
      },
      {
        name: "email",
        type: "email",
        filterKey: "email_Exact",
      },
      {
        name: "name",
        filterKey: "name_Text",
      },
      {
        name: "birthDate",
        type: "date",
      },
      ...ListWidget.fields.tracked,
    ]}
    query={QUERY}
    extraActions={({ filter, sort, result }) => (
      <Can action="download" targetType="EXTERNAL_PREMIUM_MEMBERSHIP">
        <DownloadExternalPremiumMembershipsButton
          count={result?.count}
          filter={filter}
          sort={sort}
          type="text"
          onlyIcon
        />
      </Can>
    )}
    extraRowActions={ListWidget.extraRowActions.delete}
    hideCreate
    hideDetail
    {...props}
  />
);

// ==

const QUERY = gql`
  query externalPremiumMembershipsForExternalPremiumMembershipListWidget(
    $filter: ExternalPremiumMembershipFilterInput
    $sort: [ExternalPremiumMembershipSortInput]
    $page: PageInput
  ) {
    externalPremiumMemberships(filter: $filter, sort: $sort, page: $page) {
      objects {
        id
        premium {
          id
          name
          group {
            id
            name
          }
        }
        provider
        identifier
        isRegistered
        username
        email
        name
        birthDate
        ...TrackedFields
      }
      ...ListFields
    }
  }
  ${ListWidget.fragments.list}
  ${ListWidget.fragments.tracked}
`;
