import { forwardRef } from "react";

import { gql } from "@apollo/client";

import { Typography } from "antd";

import { ObjectSelect } from "../fields";

export const SelectExternalPremiumMembership = forwardRef((props, ref) => (
  <ObjectSelect
    resource="externalPremiumMembership"
    query={QUERY}
    optionRender={(membership) => (
      <>
        {membership.identifier}
        <Typography.Text type="secondary" style={styles.secondary}>
          {membership.provider}
        </Typography.Text>
      </>
    )}
    ref={ref}
    {...props}
  />
));

// ==

const QUERY = gql`
  query externalPremiumMembershipsForSelectExternalPremiumMembership(
    $filter: ExternalPremiumMembershipFilterInput
  ) {
    externalPremiumMemberships(
      filter: $filter
      sort: [{ type: RELEVANCE, direction: DESC }]
    ) {
      objects {
        id
        identifier
        provider
      }
    }
  }
`;

// ==

const styles = {
  secondary: {
    marginLeft: 8,
    fontSize: "0.8em",
  },
};
