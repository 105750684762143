import { forwardRef, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { Select } from "antd";

import { useEnum } from "../../hooks/enum";

export const EnumSelect = forwardRef(
  (
    {
      type,
      placeholder = undefined,
      multiple = false,
      filterOptions = (options) => options,
      ...props
    },
    ref,
  ) => {
    const { t } = useTranslation();
    const { loading, values } = useEnum(type);
    const options = useMemo(
      () =>
        values?.map(({ name, description }) => ({
          value: name,
          label: description,
        })),
      [values],
    );

    return (
      <Select
        allowClear
        showSearch
        filterOption={(input, option) =>
          (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
        }
        mode={multiple ? "multiple" : undefined}
        loading={loading}
        options={options ? filterOptions(options) : undefined}
        placeholder={placeholder ?? "-- " + t("admin.common.select")}
        style={styles.container}
        ref={ref}
        {...props}
      />
    );
  },
);

// ==

const styles = {
  container: {
    width: "100%",
  },
};
