import { gql } from "@apollo/client";

import { DetailPage } from "../../components/pages";
import { CountryListWidget, SubcontinentListWidget } from "../../components/widgets";

export const ContinentDetail = () => (
  <DetailPage
    resource="continent"
    fields={[
      {
        name: "id",
      },
      {
        name: "code",
      },
      {
        name: "name",
      },
      {
        name: "subcontinentCount",
        type: "integer",
      },
      {
        name: "countryCount",
        type: "integer",
      },
      ...DetailPage.fields.canActivate,
    ]}
    query={QUERY}
    extraActions={DetailPage.extraActions.canActivate}
    tabs={[
      {
        path: "",
        labelKey: "admin.resource.country.plural",
        permission: {
          action: "list",
          targetType: "COUNTRY",
        },
        children: ({ obj }) => (
          <CountryListWidget
            baseFilter={{
              continent_Overlap: [obj.id],
            }}
            hiddenFields={["continent"]}
          />
        ),
      },
      {
        path: "subcontinents",
        labelKey: "admin.resource.subcontinent.plural",
        permission: {
          action: "list",
          targetType: "SUBCONTINENT",
        },
        children: ({ obj }) => (
          <SubcontinentListWidget
            baseFilter={{
              continent_Overlap: [obj.id],
            }}
            createSearchParams={{ continent: obj.id }}
            hiddenFields={["continent"]}
          />
        ),
      },
    ]}
  />
);

// ==

const QUERY = gql`
  query continentForContinentDetail($id: ID!) {
    continent(filter: { id_Overlap: [$id] }) {
      id
      code
      name
      subcontinentCount
      countryCount
      ...CanActivateFields
    }
  }
  ${DetailPage.fragments.canActivate}
`;
