import { gql } from "@apollo/client";

import { DownloadPremiumMembershipsButton } from "../../components/actions";
import { Can } from "../../components/permission";
import { ListWidget } from "../list";

export const PremiumMembershipListWidget = (props) => (
  <ListWidget
    resource="premiumMembership"
    fields={[
      {
        name: "id",
      },
      {
        name: "premium.group",
        labelKey: "admin.resource.group.singular",
        type: "object",
        resource: "group",
        filterKey: "premium_Group_Overlap",
      },
      {
        name: "premium",
        type: "object",
        resource: "premium",
        filterKey: "premium_Overlap",
      },
      {
        name: "member",
        type: "object",
        resource: "member",
        filterKey: "member_Overlap",
      },
      {
        name: "identifier",
        filterKey: "identifier_Exact",
        sortKey: "IDENTIFIER",
      },
      {
        name: "email",
        type: "email",
        filterKey: "email_Exact",
      },
      {
        name: "phone",
        type: "phone",
        filterKey: "phone_Exact",
      },
      {
        name: "name",
        filterKey: "name_Text",
      },
      {
        name: "birthDate",
        type: "date",
      },
      {
        name: "createdAt",
        labelKey: "admin.field.verifiedAt",
        type: "datetime",
        sortKey: "CREATED_AT",
      },
    ]}
    query={QUERY}
    extraActions={({ filter, sort, result }) => (
      <Can action="download" targetType="PREMIUM_MEMBERSHIP">
        <DownloadPremiumMembershipsButton
          count={result?.count}
          filter={filter}
          sort={sort}
          type="text"
          onlyIcon
        />
      </Can>
    )}
    extraRowActions={ListWidget.extraRowActions.delete}
    hideCreate
    hideDetail
    {...props}
  />
);

// ==

const QUERY = gql`
  query premiumMembershipsForPremiumMembershipListWidget(
    $filter: PremiumMembershipFilterInput
    $sort: [PremiumMembershipSortInput]
    $page: PageInput
  ) {
    premiumMemberships(filter: $filter, sort: $sort, page: $page) {
      objects {
        id
        premium {
          id
          name
          group {
            id
            name
          }
        }
        member {
          id
          nickname
        }
        identifier
        email
        phone
        name
        birthDate
        ...TrackedFields
      }
      ...ListFields
    }
  }
  ${ListWidget.fragments.list}
  ${ListWidget.fragments.tracked}
`;
