import { gql } from "@apollo/client";

import { ListPage, ListPageWithGroup } from "../../components/pages";

export const EventV2List = () => (
  <ListPageWithGroup
    groupFilterKey="group_Overlap"
    resource="eventV2"
    subtitle="Manage events in community."
    fields={[
      {
        name: "id",
      },
      {
        name: "group",
        type: "object",
        resource: "group",
        filterKey: "group_Overlap",
      },
      {
        name: "isHighlighted",
        type: "boolean",
        filterKey: "isHighlighted_Exact",
      },
      {
        name: "title",
      },
      {
        name: "category",
        type: "choice",
        enumType: "EventV2Category",
        filterKey: "category_Overlap",
      },
      {
        name: "submissionCategory",
        type: "choice",
        enumType: "EventV2SubmissionCategory",
        filterKey: "submissionCategory_Overlap",
      },
      {
        name: "applicationOpenAt",
        type: "datetime",
      },
      {
        name: "applicationCloseAt",
        type: "datetime",
      },
      {
        name: "applyStatus",
        type: "choice",
        enumType: "EventV2ApplyStatus",
      },
      {
        name: "winnerAnnouncedAt",
        type: "datetime",
      },
      {
        name: "status",
        type: "choice",
        enumType: "EventV2Status",
        filterKey: "status_Overlap",
      },
      ...ListPage.fields.tracked,
    ]}
    query={QUERY}
    extraRowActions={ListPage.extraRowActions.canActivate}
  />
);

// ==

const QUERY = gql`
  query eventsV2ForEventV2List(
    $filter: EventV2FilterInput
    $sort: [EventV2SortInput]
    $page: PageInput
  ) {
    eventsV2(filter: $filter, sort: $sort, page: $page) {
      objects {
        id
        group {
          id
          name
        }
        isHighlighted
        title
        category
        submissionCategory
        applicationOpenAt
        applicationCloseAt
        winnerAnnouncedAt
        status
        applyStatus
        ...TrackedFields
        ...CanActivateFields
      }
      ...ListFields
    }
  }
  ${ListPage.fragments.list}
  ${ListPage.fragments.tracked}
  ${ListPage.fragments.canActivate}
`;
