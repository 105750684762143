import { gql } from "@apollo/client";

import { ActivationControl } from "../../components/fields";
import { FormPage } from "../../components/pages";

export const GroupForm = () => (
  <FormPage
    resource="group"
    fields={[
      {
        type: "fieldset",
        fields: [
          {
            name: "name",
          },
          {
            name: "code",
            descriptionKey: "admin.form.guide.codeGroup",
          },
          {
            name: "company",
            type: "object",
            resource: "company",
            disabled: ({ isUpdate }) => isUpdate,
          },
          {
            name: "owner",
            type: "object",
            resource: "staff",
            descriptionKey: "admin.form.guide.owner",
          },
          {
            name: "debutDate",
            type: "date",
          },
          {
            name: "isPremiumOnly",
            type: "boolean",
            resource: "isPremiumOnly",
            disabled: ({ data }) => !["StrayKids", "hsk"].includes(data.code),
          },
        ],
      },
      {
        type: "fieldset",
        fields: [
          {
            name: "reservedNicknames",
            multiple: true,
            descriptionKey: "admin.form.guide.reservedNicknames",
          },
        ],
      },
      {
        type: "fieldset",
        labelKey: "admin.fieldset.sns",
        descriptionKey: "admin.form.guide.snsGroup",
        fields: [
          {
            name: "facebookUrl",
            type: "url",
          },
          {
            name: "instagramUrl",
            type: "url",
          },
          {
            name: "snapchatUrl",
            type: "url",
          },
          {
            name: "tiktokUrl",
            type: "url",
          },
          {
            name: "twitterUrl",
            type: "url",
          },
          {
            name: "weiboUrl",
            type: "url",
          },
          {
            name: "youtubeUrl",
            type: "url",
          },
        ],
      },
      {
        type: "fieldset",
        labelKey: "admin.field.visibility",
        fields: [
          {
            type: "composite",
            names: ["isActive", "activateAt", "doesSendPush"],
            defaultValue: { isActive: false, doesSendPush: false },
            permission: ({ obj, resource }) => ({
              action: "activate",
              targetType: resource.typeName,
              targetId: obj?.id,
            }),
            render: ({ controllerField }) => (
              <ActivationControl {...controllerField} canNotInactivate />
            ),
            confirmation: {
              shouldConfirm: (data) => Boolean(data.doesSendPush),
              confirmLabelKey: "admin.component.doesSendPush.confirm",
              message: "admin.component.doesSendPush.confirmMessage",
            },
          },
        ],
      },
    ]}
    query={QUERY}
    createMutation={CREATE_MUTATION}
    updateMutation={UPDATE_MUTATION}
  />
);

// ==

const FORM_FIELDS_FRAGMENT = gql`
  fragment GroupFormFields on Group {
    id
    company {
      id
    }
    owner {
      id
    }
    code
    name
    debutDate
    isPremiumOnly
    facebookUrl
    instagramUrl
    snapchatUrl
    tiktokUrl
    twitterUrl
    weiboUrl
    youtubeUrl
    reservedNicknames
    ...CanActivateFields
  }
  ${FormPage.fragments.canActivate}
`;

const QUERY = gql`
  query groupForGroupForm($id: ID!) {
    group(filter: { id_Overlap: [$id] }) {
      ...GroupFormFields
    }
  }
  ${FORM_FIELDS_FRAGMENT}
`;

const CREATE_MUTATION = gql`
  mutation createGroupForGroupForm($data: GroupMutationInput!) {
    createGroup(data: $data) {
      ok
      errors {
        ...MutationErrorFields
      }
      object {
        ...GroupFormFields
      }
    }
  }
  ${FormPage.fragments.mutationError}
  ${FORM_FIELDS_FRAGMENT}
`;

const UPDATE_MUTATION = gql`
  mutation updateGroupForGroupForm($data: GroupMutationInput!, $id: ID!) {
    updateGroup(data: $data, id: $id, partial: true) {
      ok
      errors {
        ...MutationErrorFields
      }
      object {
        ...GroupFormFields
      }
    }
  }
  ${FormPage.fragments.mutationError}
  ${FORM_FIELDS_FRAGMENT}
`;
