import { useCallback, useMemo } from "react";

import { useAbilities } from "@/hooks/permission";
import type { BuiltField, BuiltFlatField, FieldManipulator } from "@/types/field";
import type { RecordType } from "@/types/primitives";
import type { BuiltResource } from "@/types/resource";
import { zipObject } from "@/utils/helpers";

type UseFieldsPermissionResult =
  | {
      examined: false;
    }
  | {
      examined: true;
      editableField: Record<string, boolean | null>;
      isFieldForbidden: (field: BuiltField) => boolean;
      injectForbiddenToField: FieldManipulator;
    };

export function useFieldsPermission(
  fields: BuiltFlatField[],
  resource: BuiltResource,
  skip: boolean,
  obj: RecordType | null,
): UseFieldsPermissionResult {
  const abilityKeys = useMemo(
    () =>
      !skip && obj ? fields.map((field) => field.permission({ obj, resource })) : null,
    [fields, skip, obj, resource],
  );
  const { abilities } = useAbilities(abilityKeys);
  const editableField = useMemo(() => {
    if (skip)
      return zipObject(
        fields.map((f) => f.id),
        fields.map(() => true),
      );
    if (!abilities) return null;
    return zipObject(
      fields.map((f) => f.id),
      abilities,
    );
  }, [fields, skip, abilities]);

  const isFieldPermitted = useCallback(
    (field: BuiltField) => editableField?.[field.id] !== false,
    [editableField],
  );

  const injectForbiddenToField = useCallback(
    (field: BuiltField): BuiltField => {
      if (isFieldPermitted(field)) return field;
      return {
        ...field,
        forbidden: true,
        disabled: true,
      };
    },
    [editableField],
  );

  if (!editableField) return { examined: false };
  return {
    examined: true,
    editableField,
    isFieldForbidden: isFieldPermitted,
    injectForbiddenToField,
  };
}
