import type { ForwardedRef, MouseEvent, ReactElement, ReactNode } from "react";
import { forwardRef, useCallback, useEffect, useMemo } from "react";

import { type DocumentNode, useLazyQuery } from "@apollo/client";

import { Select, Tag } from "antd";

import type { BaseSelectRef } from "rc-select";
import type { CustomTagProps } from "rc-select/lib/BaseSelect";
import { useDebouncedCallback } from "use-debounce";

import type { GqlFilter, GqlQueryResultObject } from "@/gql/types";
import { useApp } from "@/hooks/app";
import { useRecentObjects } from "@/hooks/utils";
import type { ResourceName } from "@/resources";
import type { EmptyObject } from "@/types/primitives";
import { arrayify as normalize } from "@/utils/helpers";

type OptionGetterFn<V> = (obj: V) => {
  value: string; // should be an id (string)
  label: ReactNode; // should be a name, or something similar
};

export type ObjectSelectRef = BaseSelectRef;
export type ObjectSelectFieldProps<Query extends DocumentNode> = {
  debounce?: number;
  placeholder?: string;
  optionGetter?: OptionGetterFn<GqlQueryResultObject<Query>>;
  baseFilter?: GqlFilter<Query> | EmptyObject;
  optionRender?: (obj: GqlQueryResultObject<Query>) => ReactNode;
  disabled?: boolean;
} & (
  | {
      value?: string[];
      multiple: true;
      onChange?: (value: string[] | null) => void;
    }
  | {
      value?: string;
      multiple?: false;
      onChange?: (value: string | null) => void;
    }
);
type ObjectSelectProps<Query extends DocumentNode> = ObjectSelectFieldProps<Query> & {
  resource: ResourceName;
  query: Query;
};

const ObjectSelect = forwardRef(
  <Q extends DocumentNode>(
    props: ObjectSelectProps<Q>,
    ref: ForwardedRef<ObjectSelectRef>,
  ) => {
    const {
      resource: resourceName,
      query,
      value,
      baseFilter = {},
      optionRender = undefined,
      placeholder = undefined,
      optionGetter: originalOptionGetter = undefined,
      debounce = 300,
      multiple,
      onChange: __,
      ...restProps
    } = props;
    const [fetch, { loading, data }] = useLazyQuery(query);
    const { resources } = useApp();

    const resource = resources[resourceName];
    const objects = useMemo(
      () => (data?.[resource.typePlural]?.objects ?? []) as GqlQueryResultObject<Q>[],
      [data, resource],
    );
    const { objectByKey } = useRecentObjects(objects, resource.keyGetter);
    const optionGetter = useMemo(
      () =>
        originalOptionGetter ??
        ((obj: GqlQueryResultObject<Q>) => ({
          value: resource.keyGetter(obj),
          label: resource.labelGetter(obj),
        })),
      [originalOptionGetter, resource],
    );
    const normalizedValue = useMemo(() => normalize(value), [value]);
    const onSearchDebounced = useDebouncedCallback(
      (query) =>
        fetch({
          variables: {
            filter: {
              ...baseFilter,
              auto: query,
            },
          },
        }),
      debounce,
    );
    const tagRender = useCallback(
      (props: CustomTagProps) => {
        const { value, closable, onClose } = props;
        const object = objectByKey(value);
        const label = object ? resource.labelGetter(object) : value;
        const onPreventMouseDown = (event: MouseEvent<HTMLSpanElement>) => {
          event.preventDefault();
          event.stopPropagation();
        };
        return (
          <Tag
            className="ant-select-selection-item"
            onMouseDown={onPreventMouseDown}
            closable={closable}
            onClose={onClose}
          >
            <div className="text-sm">{label}</div>
          </Tag>
        );
      },
      [resource, objectByKey],
    );

    useEffect(() => {
      /* fetch if value given as default value, or onChange from outside */
      const unknownObjectIds = normalizedValue.filter((id) => !objectByKey(id));
      if (!unknownObjectIds.length) return;
      fetch({ variables: { filter: { id_Overlap: unknownObjectIds } } }).then();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [normalizedValue]);

    return (
      <Select
        className="w-full"
        allowClear
        showSearch
        loading={loading}
        mode={multiple ? "multiple" : undefined}
        filterOption={false}
        placeholder={placeholder || `-- ${resource.label}`}
        value={value}
        tagRender={tagRender}
        onChange={(selected) => {
          const values = normalize(selected);
          if (props.multiple) {
            props.onChange?.(values);
          } else {
            props.onChange?.(values.length > 0 ? values[0] : null);
          }
        }}
        onSearch={onSearchDebounced}
        onFocus={() =>
          fetch({
            variables: {
              filter: {
                ...baseFilter,
              },
            },
          })
        }
        ref={ref}
        {...restProps}
      >
        {objects.map((object) => {
          const { value, label } = optionGetter(object);
          return (
            <Select.Option key={value} value={value} label={label}>
              {optionRender ? optionRender(object) : label}
            </Select.Option>
          );
        })}
      </Select>
    );
  },
);

/* for using generic in forwardRef components */
const _ObjectSelect = ObjectSelect as <Q extends DocumentNode>(
  props: ObjectSelectProps<Q> & { ref: ForwardedRef<ObjectSelectRef> },
) => ReactElement;
ObjectSelect.displayName = "ObjectSelect";

export { _ObjectSelect as ObjectSelect };
