import { gql } from "@apollo/client";

import { ListPage, ListPageWithGroup } from "../../components/pages";

export const PostList = () => (
  <ListPageWithGroup
    groupFilterKey="group_Overlap"
    resource="post"
    fields={[
      {
        name: "id",
      },
      {
        name: "group",
        type: "object",
        resource: "group",
        filterKey: "group_Overlap",
      },
      {
        name: "body",
        filterKey: "body_Text",
      },
      {
        name: "language",
        type: "object",
        resource: "language",
        filterKey: "language_Overlap",
      },
      {
        name: "member",
        labelKey: "admin.field.nickname",
        type: "object",
        resource: "member",
        filterKey: "member_Overlap",
      },
      {
        name: "category",
        type: "choice",
        enumType: "PostCategory",
        filterKey: "category_Overlap",
      },
      {
        name: "premium",
        labelKey: "admin.field.premiumOnly",
        type: "boolean",
        getter: (obj) => Boolean(obj.premium),
        filterKey: "premium_Exists",
      },
      {
        name: "commentCount",
        type: "integer",
        filterKey: "commentCount_Range",
        sortKey: "COMMENT_COUNT",
      },
      {
        name: "likeCount",
        type: "integer",
        filterKey: "likeCount_Range",
        sortKey: "LIKE_COUNT",
      },
      {
        name: "reportCount",
        type: "integer",
        filterKey: "reportCount_Range",
        sortKey: "REPORT_COUNT",
      },
      {
        name: "status",
        type: "choice",
        enumType: "PostStatus",
        filterKey: "status_Overlap",
      },
      ...ListPage.fields.canHide,
      ...ListPage.fields.timestamped,
    ]}
    query={QUERY}
    extraRowActions={ListPage.extraRowActions.canActivate}
    hideCreate
  />
);

// ==

const QUERY = gql`
  query postsForPostList(
    $filter: PostFilterInput
    $sort: [PostSortInput]
    $page: PageInput
  ) {
    posts(filter: $filter, sort: $sort, page: $page) {
      objects {
        id
        group {
          id
          name
        }
        body: bodySnippet
        language {
          id
          name
        }
        member {
          id
          nickname
        }
        premium {
          id
        }
        category
        commentCount
        likeCount
        reportCount
        status
        ...TrackedFields
        ...CanActivateFields
        ...CanHideFields
      }
      ...ListFields
    }
  }
  ${ListPage.fragments.list}
  ${ListPage.fragments.tracked}
  ${ListPage.fragments.canActivate}
  ${ListPage.fragments.canHide}
`;
