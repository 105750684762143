import { useState } from "react";
import { useTranslation } from "react-i18next";

import { gql, useMutation } from "@apollo/client";

import { CheckCircleOutlined } from "@ant-design/icons";
import { Button, Modal, Typography } from "antd";

import { useApp } from "../../hooks/app";
import { MUTATION_ERROR_FRAGMENT } from "../../utils/gql/fragments";
import { capitalize } from "../../utils/helpers";

export const ApproveButton = ({
  targetType,
  targetId,
  value,
  onChange,
  size = "middle",
  onlyIcon = false,
}) => {
  const { t } = useTranslation();

  const variables = {
    type: targetType,
    id: targetId,
    data: {},
  };
  const onCompleted = (response) => {
    const { ok, errors, object } = Object.values(response)[0];
    if (ok) {
      message.success(t("admin.message.processSuccess"));
      onChange?.(object);
      setIsModalOpen(false);
    } else {
      message.error(
        t("admin.message.processFailure", {
          message: errors[0].messages[0],
        }),
      );
    }
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [approve, { loading }] = useMutation(APPROVE_MUTATION, {
    variables,
    onCompleted,
  });
  const { message } = useApp();

  const action = t("admin.component.approveButton.approve");
  const onClick = () => setIsModalOpen(true);
  const onOk = () => approve();
  const onCancel = () => setIsModalOpen(false);

  return (
    <>
      <Button
        type={value ? "default" : "primary"}
        ghost
        danger={value}
        icon={<CheckCircleOutlined />}
        onClick={onClick}
        size={size}
      >
        {onlyIcon ? null : capitalize(action)}
      </Button>
      <Modal
        title={t(`admin.common.confirmation`)}
        open={isModalOpen}
        confirmLoading={loading}
        okText={capitalize(action)}
        okButtonProps={{
          icon: <CheckCircleOutlined />,
          danger: value,
        }}
        cancelText={t("admin.common.cancel")}
        onOk={onOk}
        onCancel={onCancel}
      >
        <Typography.Text>{t("admin.component.approveButton.message")}</Typography.Text>
      </Modal>
    </>
  );
};

// ==

const APPROVE_MUTATION = gql`
  mutation approveForApproveButton($type: ModelDocumentType!, $id: ID!) {
    approve(targetType: $type, targetId: $id) {
      ok
      errors {
        ...MutationErrorFields
      }
    }
  }
  ${MUTATION_ERROR_FRAGMENT}
`;
