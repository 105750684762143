import { useEffect } from "react";

export function useResizeObserver<T, K>(
  element: Element | null | undefined,
  lambda: () => K,
) {
  useEffect(() => {
    if (!element) return;
    const resizeObserver = new ResizeObserver(() => {
      lambda();
    });
    resizeObserver.observe(element);
    return () => {
      resizeObserver.disconnect();
    };
  }, [element, lambda]);
}
