import { gql } from "@apollo/client";

import { DetailPage } from "../../components/pages";
import { RoleListWidget } from "../../components/widgets";

export const StaffDetail = () => (
  <DetailPage
    resource="staff"
    fields={[
      {
        name: "id",
      },
      {
        name: "user.email",
        labelKey: "admin.field.email",
        type: "email",
      },
      {
        name: "phone",
        labelKey: "admin.field.phone",
        type: "phone",
      },
      {
        name: "user.name",
        labelKey: "admin.field.name",
      },
      {
        name: "roleCategories",
        labelKey: "admin.resource.role.plural",
        type: "choice",
        enumType: "RoleCategory",
      },
      {
        name: "permittedGroups",
        labelKey: "admin.resource.group.plural",
        type: "object",
        resource: "group",
      },
      {
        name: "permittedCompanies",
        labelKey: "admin.resource.company.plural",
        type: "object",
        resource: "company",
      },
      ...DetailPage.fields.canActivate,
      ...DetailPage.fields.tracked,
    ]}
    query={QUERY}
    extraActions={DetailPage.extraActions.canActivate}
    tabs={[
      {
        path: "",
        labelKey: "admin.resource.role.plural",
        permission: {
          action: "list",
          targetType: "ROLE",
        },
        children: ({ obj }) => (
          <RoleListWidget
            baseFilter={{
              staff_Overlap: [obj.id],
            }}
            createSearchParams={{ staff: obj.id }}
            hiddenFields={["staff", "staff.user.email"]}
          />
        ),
      },
    ]}
  />
);

// ==

const QUERY = gql`
  query staffForStaffDetail($id: ID!) {
    staff(filter: { id_Overlap: [$id] }) {
      id
      phone
      user {
        id
        email
        phone
        name
      }
      roleCategories
      permittedGroups {
        id
        name
      }
      permittedCompanies {
        id
        name
      }
      ...TrackedFields
      ...CanActivateFields
    }
  }
  ${DetailPage.fragments.tracked}
  ${DetailPage.fragments.canActivate}
`;
