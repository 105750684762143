import { gql } from "@apollo/client";

import { ActivateButton } from "../../components/actions";
import { DetailPage } from "../../components/pages";
import { Can } from "../../components/permission";
import { BoothListWidget } from "../../components/widgets/BoothListWidget.jsx";

export const VenueDetail = () => (
  <DetailPage
    resource="venue"
    fields={[
      {
        name: "id",
      },
      {
        name: "group",
        type: "object",
        resource: "group",
      },
      {
        name: "titleContent",
        labelKey: "admin.field.title",
        type: "translatable",
      },
      ...DetailPage.fields.canActivate,
      ...DetailPage.fields.tracked,
    ]}
    query={QUERY}
    extraActions={({ obj, refetch, resource }) => {
      return (
        <>
          <Can
            action={obj.isActive ? "inactivate" : "activate"}
            targetType={resource.typeName}
            targetId={obj.id}
          >
            <ActivateButton
              targetType={resource.typeName}
              targetId={obj.id}
              value={obj.isActive}
              onChange={() => refetch()}
            ></ActivateButton>
          </Can>
        </>
      );
    }}
    tabs={[
      {
        path: "",
        labelKey: "admin.resource.booth.plural",
        permission: {
          action: "list",
          targetType: "BOOTH",
        },
        children: ({ obj }) => (
          <BoothListWidget
            baseFilter={{
              venue_Overlap: [obj.id],
            }}
            createSearchParams={{
              group: obj.group.id,
              venue: obj.id,
            }}
            hiddenFields={["venue"]}
          />
        ),
      },
    ]}
  />
);

// ==

const QUERY = gql`
  query venueForVenueDetail($id: ID!) {
    venue(filter: { id_Overlap: [$id] }) {
      id
      group {
        id
        name
      }
      titleContent {
        ...TranslatableContentFields
      }
      ...TrackedFields
      ...CanActivateFields
    }
  }
  ${DetailPage.fragments.canActivate}
  ${DetailPage.fragments.tracked}
  ${DetailPage.fragments.translatableContent}
`;
