import dayjs, { Dayjs } from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isoWeek from "dayjs/plugin/isoWeek";

// Load plugins

dayjs.extend(isoWeek);
dayjs.extend(isSameOrAfter);

// https://github.com/hyunbinseo/holidays-kr/tree/main/public
const HOLIDAYS = {
  2024: {
    "2024-01-01": ["1월 1일"],
    "2024-02-09": ["설날 전날"],
    "2024-02-10": ["설날"],
    "2024-02-11": ["설날 다음 날"],
    "2024-02-12": ["대체공휴일(설날)"],
    "2024-03-01": ["3ㆍ1절"],
    "2024-04-10": ["제22대국회의원선거"],
    "2024-05-05": ["어린이날"],
    "2024-05-06": ["대체공휴일(어린이날)"],
    "2024-05-15": ["부처님 오신 날"],
    "2024-06-06": ["현충일"],
    "2024-08-15": ["광복절"],
    "2024-09-16": ["추석 전날"],
    "2024-09-17": ["추석"],
    "2024-09-18": ["추석 다음 날"],
    "2024-10-01": ["임시공휴일"],
    "2024-10-03": ["개천절"],
    "2024-10-09": ["한글날"],
    "2024-12-25": ["기독탄신일"],
  },
  2025: {
    "2025-01-01": ["1월 1일"],
    "2025-01-28": ["설날 전날"],
    "2025-01-29": ["설날"],
    "2025-01-30": ["설날 다음 날"],
    "2025-03-01": ["3ㆍ1절"],
    "2025-03-03": ["대체공휴일(3ㆍ1절)"],
    "2025-05-05": ["어린이날", "부처님 오신 날"],
    "2025-05-06": ["대체공휴일(부처님 오신 날)"],
    "2025-06-06": ["현충일"],
    "2025-08-15": ["광복절"],
    "2025-10-03": ["개천절"],
    "2025-10-05": ["추석 전날"],
    "2025-10-06": ["추석"],
    "2025-10-07": ["추석 다음 날"],
    "2025-10-08": ["대체공휴일(추석)"],
    "2025-10-09": ["한글날"],
    "2025-12-25": ["기독탄신일"],
  },
};
const HOLIDAY_DATES = Object.values(HOLIDAYS).reduce<string[]>((acc, yearHolidays) => {
  return acc.concat(Object.keys(yearHolidays));
}, []);

export function isUTCString(value: string) {
  const utcRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d{3})?Z$/;
  return utcRegex.test(value);
}

export function isHoliday(date: Dayjs) {
  return HOLIDAY_DATES.some((holiday) => date.isSame(dayjs(holiday), "day"));
}

export function calculateValidBusinessDate(daysToAdd: number) {
  let count = 0;
  let currentDate = dayjs();

  while (count < daysToAdd) {
    currentDate = currentDate.add(1, "day");
    if (currentDate.isoWeekday() < 6 && !isHoliday(currentDate)) {
      count++;
    }
  }

  return currentDate;
}

export function disabledDate(current: Dayjs | null, businessDaysToAdd = 3) {
  if (!current) return false;

  const oneDayAfterBusinessDays = businessDaysToAdd + 1;
  const validBusinessDate = calculateValidBusinessDate(oneDayAfterBusinessDays);

  // Except weekends
  if (current.isoWeekday() > 5) return true;

  // Except holidays
  if (isHoliday(current)) return true;

  // Except business days
  return !current.isSameOrAfter(validBusinessDate, "day");
}

export function getLocalDateRange(
  startDate: Dayjs,
  endDate: Dayjs,
  isoString = false,
  format = "YYYY-MM-DD",
) {
  const startOfDay = startDate
    .set("hour", 0)
    .set("minute", 0)
    .set("second", 0)
    .set("millisecond", 0);
  const endOfDay = endDate
    .add(1, "day")
    .set("hour", 0)
    .set("minute", 0)
    .set("second", 0)
    .set("millisecond", 0);

  return [
    isoString ? startOfDay.toISOString() : startOfDay.format(format),
    isoString ? endOfDay.toISOString() : endOfDay.format(format),
  ];
}
