import { forwardRef } from "react";

import { Select } from "antd";

export const MultipleInput = forwardRef(({ value, onChange, ...props }, ref) => {
  return (
    <Select
      mode="tags"
      value={value}
      onChange={(value) => onChange?.(value)}
      open={false}
      ref={ref}
      style={styles.container}
      {...props}
    />
  );
});

// ==

const styles = {
  container: {
    width: "100%",
  },
};
