import { gql } from "@apollo/client";

import { FormPage } from "../../components/pages";

export const CountryForm = () => (
  <FormPage
    resource="country"
    fields={[
      {
        name: "subcontinent",
        type: "object",
        resource: "subcontinent",
      },
      {
        name: "code",
        helpKey: "admin.form.guide.codeCountry",
      },
      {
        name: "name",
      },
      {
        name: "phonePrefix",
      },
    ]}
    query={QUERY}
    createMutation={CREATE_MUTATION}
    updateMutation={UPDATE_MUTATION}
  />
);

// ==

const FORM_FIELDS_FRAGMENT = gql`
  fragment CountryFormFields on Country {
    id
    subcontinent {
      id
    }
    code
    name
    phonePrefix
  }
`;

const QUERY = gql`
  query countryForCountryForm($id: ID!) {
    country(filter: { id_Overlap: [$id] }) {
      ...CountryFormFields
    }
  }
  ${FORM_FIELDS_FRAGMENT}
`;

const CREATE_MUTATION = gql`
  mutation createCountryForCountryForm($data: CountryMutationInput!) {
    createCountry(data: $data) {
      ok
      errors {
        ...MutationErrorFields
      }
      object {
        ...CountryFormFields
      }
    }
  }
  ${FormPage.fragments.mutationError}
  ${FORM_FIELDS_FRAGMENT}
`;

const UPDATE_MUTATION = gql`
  mutation updateCountryForCountryForm($data: CountryMutationInput!, $id: ID!) {
    updateCountry(data: $data, id: $id, partial: true) {
      ok
      errors {
        ...MutationErrorFields
      }
      object {
        ...CountryFormFields
      }
    }
  }
  ${FormPage.fragments.mutationError}
  ${FORM_FIELDS_FRAGMENT}
`;
