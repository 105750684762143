import { gql } from "@apollo/client";

import { MediaUpload } from "../../components/fields";
import { FormPage } from "../../components/pages";
import { pick } from "../../utils/helpers";

export const TranslationRequestForm = () => (
  <FormPage
    resource="translationRequest"
    fields={[
      {
        type: "fieldset",
        label: "",
        fields: [
          {
            name: "group",
            type: "object",
            resource: "group",
            control: "select",
            disableUniversalGroup: true,
            normalizer: (value) => ({
              group: value ? parseInt(value) : null,
            }),
            disabled: ({ isUpdate }) => isUpdate,
          },
          {
            name: "title",
            type: "text",
          },
          {
            name: "sourceLanguage",
            type: "choice",
            enumType: "ServiceLanguage",
            control: "select",
            disabled: true,
            defaultValue: "KOREAN",
          },
          {
            name: "targetLanguage",
            type: "choice",
            enumType: "ServiceLanguage",
            control: "select",
            multiple: true,
            getter: (value) => {
              return Object.keys(value).length === 0
                ? []
                : value.targetContents.map(
                    (targetContent) => targetContent.targetLanguage,
                  );
            },
            normalizer: (value) => ({
              targetContents: value ? value.map((v) => ({ targetLanguage: v })) : value,
            }),
            disabled: ({ isUpdate }) => isUpdate,
            forbidden: ({ isUpdate }) => isUpdate,
            filterOptions: () => (options) =>
              options.filter((option) => option.value !== "KOREAN"),
          },
          {
            name: "category",
            type: "choice",
            control: "select",
            enumType: "TranslationRequestCategory",
            disabled: ({ isUpdate }) => isUpdate,
          },
          {
            name: "inputSource",
            type: "choice",
            enumType: "TranslationRequestInputSource",
            disabled: ({ isUpdate }) => isUpdate,
          },
          {
            name: "plainText",
            type: "text",
            rows: 10,
            hidden: ({ data }) =>
              data.inputSource === "FILE" || data.inputSource === undefined,
          },
          {
            name: "uploadFiles",
            type: "fileMeta",
            hidden: ({ data }) =>
              data.inputSource === "TEXT" || data.inputSource === undefined,
            normalizer: (value) => {
              if (Array.isArray(value) && value.length > 0) {
                return {
                  uploadFiles: value.map((file) => pick(file, ["key", "mime", "size"])),
                };
              } else if (value && Object.keys(value).length > 0) {
                return { uploadFiles: [pick(value, ["key", "mime", "size"])] };
              } else {
                return [];
              }
            },
            render: ({ field, controllerField, setError, clearErrors }) => (
              <MediaUpload
                onUploadBegin={() => setError(field.name, { type: "custom" })}
                onUploadEnd={() => clearErrors(field.name)}
                multiple={field.multiple}
                sync={field.sync}
                accept={".txt,.docx,.doc"}
                {...controllerField}
              />
            ),
          },
          {
            name: "additionalRequestComment",
            rows: 5,
            type: "text",
          },
          {
            name: "dueAt",
            type: "date",
            showToday: false,
            useHolidays: true,
            normalizer: (value) => ({
              dueAt: value ? value.toISOString() : value,
            }),
          },
          {
            name: "additionalRecipients",
            type: "email",
          },
          {
            name: "isActive",
            hidden: true,
            getter: () => true,
          },
        ],
      },
    ]}
    query={QUERY}
    createMutation={CREATE_MUTATION}
    updateMutation={UPDATE_MUTATION}
    nextUrl={"/translation-request-management/list"}
  />
);

// ==

const FORM_FIELDS_FRAGMENT = gql`
  fragment TranslationRequestFormFields on TranslationRequest {
    group {
      id
    }
    requestor {
      id
    }
    category
    inputSource
    title
    sourceLanguage
    dueAt
    additionalRecipients
    additionalRequestComment
    plainText
    isActive
    uploadFiles {
      key
      mime
      size
    }
    targetContents {
      targetLanguage
    }
  }
`;

const QUERY = gql`
  query translationRequestForTranslationRequestForm($id: ID!) {
    translationRequest(filter: { id_Overlap: [$id] }) {
      ...TranslationRequestFormFields
    }
  }
  ${FORM_FIELDS_FRAGMENT}
`;

const CREATE_MUTATION = gql`
  mutation createTranslationRequestForTranslationRequestForm(
    $data: TranslationRequestMutationInput!
  ) {
    createTranslationRequest(data: $data) {
      ok
      errors {
        ...MutationErrorFields
      }
      object {
        ...TranslationRequestFormFields
      }
    }
  }
  ${FormPage.fragments.mutationError}
  ${FORM_FIELDS_FRAGMENT}
`;

const UPDATE_MUTATION = gql`
  mutation updateTranslationRequestForTranslationRequestForm(
    $data: TranslationRequestMutationInput!
    $id: ID!
  ) {
    updateTranslationRequest(data: $data, id: $id, partial: true) {
      ok
      errors {
        ...MutationErrorFields
      }
      object {
        ...TranslationRequestFormFields
      }
    }
  }
  ${FormPage.fragments.mutationError}
  ${FORM_FIELDS_FRAGMENT}
`;
