import { useTranslation } from "react-i18next";

import { gql } from "@apollo/client";

import { DateTimePicker } from "../../components/fields/DateTimePicker";
import { FormPage } from "../../components/pages";
import { SCHEDULE_FRAGMENT } from "../../utils/gql/fragments";

export const ScheduleForm = () => {
  const { t } = useTranslation();

  return (
    <FormPage
      resource="schedule"
      fields={[
        {
          type: "fieldset",
          fields: [
            {
              name: "group",
              type: "object",
              resource: "group",
              disabled: ({ isUpdate }) => isUpdate,
              onChange: ({ resetField }) => resetField("artists"),
            },
            {
              name: "artists",
              type: "object",
              resource: "artist",
              multiple: true,
              disabled: ({ data }) => !data.group,
              baseFilter: ({ data }) => ({ group_Overlap: [data.group] }),
            },
            {
              name: "category",
              type: "choice",
              enumType: "ScheduleCategory",
            },
            {
              name: "scheduledAt",
              type: "datetime",
              showAllDay: true,
              render: ({ controllerField, setValue }) => (
                <DateTimePicker showAllDay {...controllerField} setValue={setValue} />
              ),
            },
            {
              name: "isAllDay",
              type: "boolean",
              hidden: true,
            },
          ],
        },
        {
          type: "fieldset",
          labelKey: "admin.fieldset.content",
          fields: [
            {
              name: "titleContent",
              labelKey: "admin.field.title",
              type: "translatable",
            },
            {
              name: "bodyContent",
              labelKey: "admin.field.body",
              type: "translatable",
              rows: 10,
            },
            {
              name: "location",
            },
          ],
        },
        {
          type: "fieldset",
          labelKey: "admin.field.visibility",
          fields: [
            ...FormPage.fields.canActivate[0].fields,
            {
              name: "doesSendPushOnSchedule",
              label: "",
              type: "boolean",
              control: "checkbox",
              controlLabel: t("admin.form.label.doesSendPushOnSchedule"),
            },
          ],
        },
      ]}
      query={QUERY}
      createMutation={CREATE_MUTATION}
      updateMutation={UPDATE_MUTATION}
    />
  );
};

// ==

const QUERY = gql`
  query scheduleForScheduleForm($id: ID!) {
    schedule(filter: { id_Overlap: [$id] }) {
      id
      group {
        id
      }
      artists {
        id
      }
      category
      isAllDay
      scheduledAt
      title
      titleContent {
        ...TranslatableContentFields
      }
      body
      bodyContent {
        ...TranslatableContentFields
      }
      body
      location
      doesSendPushOnSchedule
      ...CanActivateFields
    }
  }
  ${FormPage.fragments.canActivate}
  ${FormPage.fragments.translatableContent}
`;

const CREATE_MUTATION = gql`
  mutation createScheduleForScheduleForm($data: ScheduleMutationInput!) {
    createSchedule(data: $data) {
      ok
      errors {
        ...MutationErrorFields
      }
      object {
        ...ScheduleFields
      }
    }
  }
  ${FormPage.fragments.mutationError}
  ${SCHEDULE_FRAGMENT}
`;

const UPDATE_MUTATION = gql`
  mutation updateScheduleForScheduleForm($data: ScheduleMutationInput!, $id: ID!) {
    updateSchedule(data: $data, id: $id, partial: true) {
      ok
      errors {
        ...MutationErrorFields
      }
      object {
        ...ScheduleFields
      }
    }
  }
  ${FormPage.fragments.mutationError}
  ${SCHEDULE_FRAGMENT}
`;
