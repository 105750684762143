import { useState } from "react";
import { useTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroll-component";

import { useQuery } from "@apollo/client";

import { SearchOutlined } from "@ant-design/icons";
import { Button, Drawer, Space, Table, Typography } from "antd";

import config from "../../config";
import { useApp } from "../../hooks/app";
import { useDrawerState } from "../../hooks/drawer";
import { useFields } from "../../hooks/fields";
import { isEmpty } from "../../utils/helpers";
import { DebouncedInput } from "../fields";
import { SelectPlaylist } from "../selects";

const { Text } = Typography;

export const SelectObjectAction = ({
  resource: resourceName,
  onSelect,
  query,
  fields: originalFields,
  baseFilter = undefined,
  multiple = true,
  disabled = false,
  okButtonText = undefined,
  okButtonProps = {},
  drawerWidth = config.defaultDrawerWidth,
  children,
  ...props
}) => {
  const { t } = useTranslation();
  const { resources } = useApp();
  const resource = resources[resourceName];
  const { fields } = useFields({
    resource: resourceName,
    fields: originalFields,
  });
  const { isOpen, openDrawer, closeDrawer, drawerProps } = useDrawerState();
  const [selected, setSelected] = useState([]);
  const [text, setText] = useState("");
  const [playlist, setPlaylist] = useState();
  const { data, fetchMore } = useQuery(query, {
    skip: !isOpen,
    variables: {
      filter: {
        ...baseFilter,
        auto: !isEmpty(text) ? text : undefined,
        playlists_Overlap: playlist ? [playlist] : undefined,
      },
      sort: [
        {
          type: "CREATED_AT",
          direction: "DESC",
        },
      ],
    },
    fetchPolicy: "network-only",
  });

  // ==

  const { objects: resourceObjects, page } = data?.[resource.namePlural] || {};

  const getDrawerBody = () => {
    const elements = document.querySelectorAll(".ant-drawer-body");
    return elements[elements.length - 1];
  };
  const reset = () => {
    setSelected([]);
    setPlaylist(null);
    setText("");
  };
  const loadMore = () =>
    fetchMore({
      variables: { page: { after: page.endCursor } },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        const result = {};
        result[resource.namePlural] = {
          ...fetchMoreResult[resource.namePlural],
          objects: [
            ...prev[resource.namePlural].objects,
            ...fetchMoreResult[resource.namePlural].objects,
          ],
        };
        return result;
      },
    });

  const rowSelection = {
    selected,
    onChange: setSelected,
  };

  const onSubmit = () => {
    onSelect?.(selected);
    reset();
    closeDrawer();
  };
  const onClose = () => {
    reset();
    closeDrawer();
  };

  return (
    <>
      {children?.({ openDrawer, disabled })}
      <Drawer
        title={
          props.title
            ? props.title
            : t("admin.common.actionTitle", {
                action: t("admin.common.select"),
                name: t("admin.resource." + resource.name + ".singular"),
              })
        }
        extra={
          <Space>
            <Button
              type="primary"
              htmlType="submit"
              disabled={selected.length === 0}
              onClick={onSubmit}
              {...okButtonProps}
            >
              {okButtonText ?? t(`admin.common.select`)}
            </Button>
          </Space>
        }
        width={drawerWidth > 1200 ? 1200 : drawerWidth}
        {...drawerProps}
        {...props}
        onClose={onClose}
      >
        <Space direction="vertical" size="large" style={styles.container}>
          <Space direction="vertical" style={styles.container}>
            <DebouncedInput
              prefix={<SearchOutlined />}
              placeholder={t("admin.common.search")}
              value={text}
              onChange={(value) => setText(value)}
            />
            <SelectPlaylist value={playlist} onChange={(value) => setPlaylist(value)} />
          </Space>
          {multiple && (
            <Text>{t("admin.common.selectedCount", { count: selected.length })}</Text>
          )}
          {data && (
            <InfiniteScroll
              dataLength={resourceObjects.length}
              next={loadMore}
              hasMore={page.hasNext}
              scrollableTarget={getDrawerBody()}
            >
              <Table
                columns={fields.map((field) => {
                  return {
                    title: field.label,
                    ...field,
                  };
                })}
                dataSource={resourceObjects}
                pagination={false}
                rowKey="id"
                size="small"
                rowSelection={{
                  type: multiple ? "checkbox" : "radio",
                  ...rowSelection,
                }}
              />
            </InfiniteScroll>
          )}
        </Space>
      </Drawer>
    </>
  );
};

// ==

const styles = {
  container: {
    width: "100%",
  },
  row: {
    width: "100%",
    paddingTop: 4,
    paddingBottom: 4,
  },
  disabled: {
    opacity: 0.3,
  },
};
