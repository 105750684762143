import { gql } from "@apollo/client";

import { Radio } from "antd";

import {
  EnumRadio,
  OfflineStoreItemRestrictionEditor,
  OfflineStoreItemVariantEditor,
} from "../../components/fields";
import { FormPage } from "../../components/pages";

export const OfflineStoreItemForm = () => {
  return (
    <FormPage
      resource="offlineStoreItem"
      fields={[
        {
          type: "fieldset",
          label: "",
          fields: [
            {
              name: "offlineStore",
              type: "object",
              resource: "offlineStore",
              disabled: ({ isUpdate }) => isUpdate,
            },
          ],
        },
        {
          type: "fieldset",
          labelKey: "admin.fieldset.content",
          fields: [
            {
              name: "nameContent",
              labelKey: "admin.field.name",
              type: "translatable",
            },
            {
              name: "price",
              type: "integer",
            },
            {
              name: "order",
              type: "integer",
              disabled: true,
            },
            {
              name: "isSingular",
              render: ({ controllerField }) => (
                <Radio.Group {...controllerField}>
                  <Radio value={true}>single</Radio>
                  <Radio value={false}>multiple</Radio>
                </Radio.Group>
              ),
              readOnly: true,
            },
            {
              name: "availability",
              hidden: ({ data }) => !data.isSingular,
              render: ({ controllerField }) => (
                <EnumRadio
                  {...controllerField}
                  type="OfflineStoreItemVariantAvailability"
                  direction="vertical"
                />
              ),
            },
            {
              name: "variants",
              labelKey: "admin.field.variants",
              hidden: ({ data }) => data.isSingular,
              render: ({ controllerField }) => {
                return <OfflineStoreItemVariantEditor {...controllerField} />;
              },
            },
            {
              name: "image",
              type: "imageMeta",
            },
            {
              name: "restrictions",
              labelKey: "admin.field.restrictions",
              render: ({ controllerField, data }) => (
                <OfflineStoreItemRestrictionEditor
                  isSingular={data.isSingular}
                  {...controllerField}
                />
              ),
            },
            {
              name: "detailUrl",
            },
          ],
        },
        ...FormPage.fields.canActivate,
      ]}
      query={QUERY}
      createMutation={CREATE_MUTATION}
      updateMutation={UPDATE_MUTATION}
    />
  );
};

// ==

const FORM_FIELDS_FRAGMENT = gql`
  fragment OfflineStoreItemFormFields on OfflineStoreItem {
    id
    offlineStore {
      id
      name
    }
    name
    nameContent {
      ...TranslatableContentFields
    }
    price
    image {
      ...ImageFields
    }
    availability
    isSingular
    order
    restrictions {
      id
      category
      count
    }
    variants {
      id
      label
      availability
    }
    detailUrl
    ...CanActivateFields
  }
  ${FormPage.fragments.canActivate}
  ${FormPage.fragments.image}
  ${FormPage.fragments.translatableContent}
`;

const QUERY = gql`
  query offlineStoreItemForOfflineStoreItemForm($id: ID!) {
    offlineStoreItem(filter: { id_Overlap: [$id] }) {
      ...OfflineStoreItemFormFields
    }
  }
  ${FORM_FIELDS_FRAGMENT}
`;

const CREATE_MUTATION = gql`
  mutation createOfflineStoreItemForOfflineStoreItemForm(
    $data: OfflineStoreItemMutationInput!
  ) {
    createOfflineStoreItem(data: $data) {
      ok
      errors {
        ...MutationErrorFields
      }
      object {
        ...OfflineStoreItemFormFields
      }
    }
  }
  ${FormPage.fragments.mutationError}
  ${FORM_FIELDS_FRAGMENT}
`;

const UPDATE_MUTATION = gql`
  mutation updateOfflineStoreItemForOfflineStoreItemForm(
    $data: OfflineStoreItemMutationInput!
    $id: ID!
  ) {
    updateOfflineStoreItem(data: $data, id: $id, partial: true) {
      ok
      errors {
        ...MutationErrorFields
      }
      object {
        ...OfflineStoreItemFormFields
      }
    }
  }
  ${FormPage.fragments.mutationError}
  ${FORM_FIELDS_FRAGMENT}
`;
