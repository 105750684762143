import { forwardRef } from "react";

import { gql } from "@apollo/client";

import { Typography } from "antd";

import { ObjectSelect } from "../fields";

export const SelectArtist = forwardRef((props, ref) => (
  <ObjectSelect
    resource="artist"
    query={QUERY}
    optionRender={(artist) => (
      <>
        {artist.name}
        <Typography.Text type="secondary" style={styles.secondary}>
          {artist.code}
        </Typography.Text>
      </>
    )}
    ref={ref}
    {...props}
  />
));

// ==

const QUERY = gql`
  query artistsForSelectArtist($filter: ArtistFilterInput) {
    artists(filter: $filter, sort: [{ type: RELEVANCE, direction: DESC }]) {
      objects {
        id
        code
        name
      }
    }
  }
`;

// ==

const styles = {
  secondary: {
    marginLeft: 8,
    fontSize: "0.8em",
  },
};
