import { gql } from "@apollo/client";

import {
  DownloadCompanyReservedNicknamesButton,
  UploadCompanyReservedNicknamesButton,
} from "../../components/actions";
import { DetailPage } from "../../components/pages";
import { Can } from "../../components/permission";
import { ArtistListWidget, GroupListWidget } from "../../components/widgets";

export const CompanyDetail = () => (
  <DetailPage
    resource="company"
    fields={[
      {
        name: "id",
      },
      {
        name: "code",
      },
      {
        name: "name",
      },
      {
        name: "owner",
        type: "object",
        resource: "staff",
      },
      {
        name: "groupCount",
        type: "integer",
      },
      {
        name: "artistCount",
        type: "integer",
      },
      ...DetailPage.fields.canActivate,
      ...DetailPage.fields.tracked,
    ]}
    query={QUERY}
    extraActions={(obj) => (
      <>
        {DetailPage.extraActions.canActivate(obj)}
        <Can action="update" targetType="COMPANY">
          <UploadCompanyReservedNicknamesButton type="default" />
        </Can>
        <Can action="update" targetType="COMPANY">
          <DownloadCompanyReservedNicknamesButton type="default" />
        </Can>
      </>
    )}
    tabs={[
      {
        path: "",
        labelKey: "admin.resource.group.plural",
        permission: {
          action: "list",
          targetType: "GROUP",
        },
        children: ({ obj }) => (
          <GroupListWidget
            baseFilter={{
              company_Overlap: [obj.id],
            }}
            createSearchParams={{ company: obj.id }}
            hiddenFields={["company"]}
          />
        ),
      },
      {
        path: "artists",
        labelKey: "admin.resource.artist.plural",
        permission: {
          action: "list",
          targetType: "ARTIST",
        },
        children: ({ obj }) => (
          <ArtistListWidget
            baseFilter={{
              group_Company_Overlap: [obj.id],
            }}
            hiddenFields={["group.company"]}
          />
        ),
      },
    ]}
  />
);

// ==

const QUERY = gql`
  query companyForCompanyDetail($id: ID!) {
    company(filter: { id_Overlap: [$id] }) {
      id
      code
      name
      owner {
        id
        user {
          id
          email
          name
        }
      }
      groupCount
      artistCount
      ...TrackedFields
      ...CanActivateFields
    }
  }
  ${DetailPage.fragments.tracked}
  ${DetailPage.fragments.canActivate}
`;
