import { forwardRef } from "react";

import { gql } from "@apollo/client";

import { ObjectSelect } from "../fields";

export const SelectTranslationCode = forwardRef((props, ref) => (
  <ObjectSelect resource="translationCode" query={QUERY} ref={ref} {...props} />
));

// ==

const QUERY = gql`
  query translationCodesForSelectTranslationCode($filter: TranslationCodeFilterInput) {
    translationCodes(filter: $filter, sort: [{ type: RELEVANCE, direction: DESC }]) {
      objects {
        id
        code
      }
    }
  }
`;
