import { gql } from "@apollo/client";

import { ListWidget } from "../list";

export const BoothNoticeListWidget = (props) => (
  <ListWidget
    resource="boothNotice"
    fields={[
      { name: "id" },
      {
        name: "booth",
        type: "object",
        resource: "booth",
        filterKey: "booth_Overlap",
      },
      {
        name: "title",
        truncate: true,
      },
      ...ListWidget.fields.tracked,
      ...ListWidget.fields.canActivate,
    ]}
    query={QUERY}
    extraRowActions={ListWidget.extraRowActions.canActivate}
    hideList
    {...props}
  />
);

// ==

const QUERY = gql`
  query boothNoticesForBoothNoticeListWidget(
    $filter: BoothNoticeFilterInput
    $sort: [BoothNoticeSortInput]
    $page: PageInput
  ) {
    boothNotices(filter: $filter, sort: $sort, page: $page) {
      objects {
        id
        title
        booth {
          id
          title
        }
        ...TrackedFields
        ...CanActivateFields
      }
      ...ListFields
    }
  }
  ${ListWidget.fragments.list}
  ${ListWidget.fragments.tracked}
  ${ListWidget.fragments.canActivate}
`;
