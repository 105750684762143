import { useEffect } from "react";
import ReactDOM from "react-dom/client";
import { initReactI18next } from "react-i18next";
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";

import * as Sentry from "@sentry/react";
import dayjs from "dayjs";
import "dayjs/locale/en";
import "dayjs/locale/ko";
import advancedFormat from "dayjs/plugin/advancedFormat";
import customParseFormat from "dayjs/plugin/customParseFormat";
import localeData from "dayjs/plugin/localeData";
import relativeTime from "dayjs/plugin/relativeTime";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import weekOfYear from "dayjs/plugin/weekOfYear";
import weekYear from "dayjs/plugin/weekYear";
import weekday from "dayjs/plugin/weekday";
import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import HttpBackend from "i18next-http-backend";

import App from "./App";
import config from "./config";
import enTranslations from "./locales/en.json";
import koTranslations from "./locales/ko.json";
import "./styles/main.css";

if (config.sentryDsn) {
  Sentry.init({
    dsn: config.sentryDsn,
    environment: config.sentryEnvironment,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
        ),
      }),
    ],
    enableTracing: true,
    tracePropagationTargets: [config.graphqlEndpoint],
  });
}

dayjs.extend(customParseFormat);
dayjs.extend(advancedFormat);
dayjs.extend(localeData);
dayjs.extend(relativeTime);
dayjs.extend(timezone);
dayjs.extend(utc);
dayjs.extend(weekOfYear);
dayjs.extend(weekYear);
dayjs.extend(weekday);

fetch(config.metaEndpoint, { headers: { "J-Context": config.defaultContext } })
  .then((response) => response.json())
  .then((data) => {
    i18n
      .use(initReactI18next)
      .use(LanguageDetector)
      .use(HttpBackend)
      .init({
        //resources: {
        //en: { translation: enTranslations },
        //ko: { translation: koTranslations },
        //},
        fallbackLng: "en",
        interpolation: { escapeValue: false },
        // ==
        detection: {
          lookupLocalStorage: config.languageKey,
          convertDetectedLanguage: (lng) => lng.split("-")[0],
        },
        backend: {
          loadPath: data.translationPath,
        },
      });

    dayjs.locale(i18n.language);

    ReactDOM.createRoot(document.getElementById("root")).render(<App />);
  });
