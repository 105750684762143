import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { Breadcrumb, Col, Row, Space } from "antd";

import { SelectGroup } from "../../components/selects";
import { PageHeader } from "../../components/typography";
import { useUniversalGroup } from "../../hooks/universal";

export const PageWithGroup = ({ title, titleKey, subtitle, subtitleKey, children }) => {
  const { t } = useTranslation();
  const { group, setGroup, hasMultipleGroups } = useUniversalGroup();

  // ==

  const handleGroupChange = (value) => setGroup(value);

  return (
    <Space direction="vertical" size="middle" style={styles.container}>
      <Row justify="space-between">
        <Col>
          <Breadcrumb
            items={[
              { title: <Link to="/">{t("admin.common.home")}</Link> },
              { title: <Link to="">{title ?? t(titleKey)}</Link> },
            ]}
          />
        </Col>
        {hasMultipleGroups && (
          <Col>
            <SelectGroup
              value={group}
              onChange={handleGroupChange}
              style={styles.select}
              disableUniversalGroup
            />
          </Col>
        )}
      </Row>
      <PageHeader title={title} subtitle={subtitle} />
      <PageHeader
        title={title}
        titleKey={titleKey}
        subtitle={subtitle}
        subtitleKey={subtitleKey}
      />
      {children({ group })}
    </Space>
  );
};

// ==

const styles = {
  container: {
    display: "flex",
  },
  select: {
    width: 200,
  },
};
