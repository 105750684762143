import { gql } from "@apollo/client";

import {
  GROUP_MAIN_SCREEN_FRAGMENT,
  MUTATION_ERROR_FRAGMENT,
} from "../../utils/gql/fragments";
import { DuplicateButton } from "./DuplicateButton";

export const DuplicateGroupMainScreenButton = ({
  groupMainScreenId,
  size = "middle",
  onlyIcon = false,
}) => {
  return (
    <DuplicateButton
      resourceName="groupMainScreen"
      id={groupMainScreenId}
      mutation={MUTATION}
      buttonMessage="admin.component.duplicateThemeButton.message"
      size={size}
      onlyIcon={onlyIcon}
    />
  );
};
// ==

const MUTATION = gql`
  mutation duplicateGroupMainScreenForDuplicateGroupMainScreenyButton($id: ID!) {
    duplicateGroupMainScreen(id: $id) {
      ok
      errors {
        ...MutationErrorFields
      }
      object {
        ...GroupMainScreenFields
      }
    }
  }
  ${GROUP_MAIN_SCREEN_FRAGMENT}
  ${MUTATION_ERROR_FRAGMENT}
`;
