import { useTranslation } from "react-i18next";

import { Typography } from "antd";

import { PageWithGroup } from "../../components/pages";
import { ScheduleCalendarContent } from "./ScheduleCalendarContent";

const { Text } = Typography;

export const ScheduleCalendar = () => {
  const { t } = useTranslation();
  return (
    <PageWithGroup
      titleKey="admin.page.scheduleCalendar.title"
      subtitleKey="admin.page.scheduleCalendar.subtitle"
    >
      {({ group }) =>
        group ? (
          <ScheduleCalendarContent groupId={group} />
        ) : (
          <Text type="warning">{t("admin.message.selectGroupFirst")}</Text>
        )
      }
    </PageWithGroup>
  );
};
