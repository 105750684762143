import { gql } from "@apollo/client";

import { FormPage } from "../../components/pages";
import { SelectEventV2Participant } from "../../components/selects";

export const EventV2PenaltyForm = () => (
  <FormPage
    resource="eventV2Penalty"
    fields={[
      {
        type: "fieldset",
        labelKey: "admin.fieldset.event",
        fields: [
          {
            name: "event",
            type: "object",
            resource: "eventV2",
            disabled: ({ isUpdate }) => isUpdate,
          },
          {
            name: "application",
            type: "object",
            resource: "eventV2Application",
            filterKey: "id_Overlap",
            disabled: ({ isUpdate }) => isUpdate,
            render: ({ controllerField, data }) => {
              return (
                <SelectEventV2Participant
                  value={controllerField.value}
                  onChange={controllerField.onChange}
                  data={data}
                />
              );
            },
            normalizer: (value) => {
              return { application: { id: value } };
            },
          },
        ],
      },
      {
        type: "fieldset",
        labelKey: "admin.fieldset.detail",
        fields: [
          {
            name: "category",
            type: "object",
            resource: "eventV2PenaltyCategory",
          },
          {
            name: "count",
            type: "integer",
          },
          {
            name: "memo",
            labelKey: "admin.field.memo",
            rows: 10,
          },
        ],
      },
      ...FormPage.fields.canActivate,
    ]}
    query={QUERY}
    createMutation={CREATE_MUTATION}
    updateMutation={UPDATE_MUTATION}
  />
);

// ==

const FORM_FIELDS_FRAGMENT = gql`
  fragment EventV2PenaltyFormFields on EventV2Penalty {
    id
    event {
      id
      title
    }
    member {
      id
    }
    category {
      id
      name
    }
    count
    memo
    ...CanActivateFields
  }
  ${FormPage.fragments.canActivate}
`;

const QUERY = gql`
  query eventV2PenaltyForEventV2PenaltyForm($id: ID!) {
    eventV2Penalty(filter: { id_Overlap: [$id] }) {
      ...EventV2PenaltyFormFields
    }
  }
  ${FORM_FIELDS_FRAGMENT}
`;

const CREATE_MUTATION = gql`
  mutation createEventV2PenaltyForEventV2PenaltyForm(
    $data: EventV2PenaltyMutationInput!
  ) {
    createEventV2Penalty(data: $data) {
      ok
      errors {
        ...MutationErrorFields
      }
      object {
        ...EventV2PenaltyFormFields
      }
    }
  }
  ${FormPage.fragments.mutationError}
  ${FORM_FIELDS_FRAGMENT}
`;

const UPDATE_MUTATION = gql`
  mutation updateEventV2PenaltyForEventV2PenaltyForm(
    $data: EventV2PenaltyMutationInput!
    $id: ID!
  ) {
    updateEventV2Penalty(data: $data, id: $id, partial: true) {
      ok
      errors {
        ...MutationErrorFields
      }
      object {
        ...EventV2PenaltyFormFields
      }
    }
  }
  ${FormPage.fragments.mutationError}
  ${FORM_FIELDS_FRAGMENT}
`;
