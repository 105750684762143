import { gql } from "@apollo/client";

import { ListPage } from "../../components/pages";

export const TranslationCodeList = () => (
  <ListPage
    resource="translationCode"
    fields={[
      {
        name: "id",
      },
      {
        name: "code",
        filterKey: "searchCode_Text",
      },
      {
        name: "context",
        type: "choice",
        enumType: "TranslationContext",
        filterKey: "context_Overlap",
      },
      {
        name: "memo",
        filterKey: "memo_Text",
      },
      {
        name: "lastValueUpdatedAt",
        type: "datetime",
      },
      ...ListPage.fields.tracked,
    ]}
    query={QUERY}
    extraRowActions={ListPage.extraRowActions.delete}
  />
);

// ==

const QUERY = gql`
  query translationCodesForTranslationCodeList(
    $filter: TranslationCodeFilterInput
    $sort: [TranslationCodeSortInput]
    $page: PageInput
  ) {
    translationCodes(filter: $filter, sort: $sort, page: $page) {
      objects {
        id
        code
        context
        memo
        lastValueUpdatedAt
        ...TrackedFields
      }
      ...ListFields
    }
  }
  ${ListPage.fragments.list}
  ${ListPage.fragments.tracked}
`;
