import { gql } from "@apollo/client";

import { EventSurveyQuestionEditor } from "../../components/fields";
import { FormPage } from "../../components/pages";

export const EventForm = () => (
  <FormPage
    resource="event"
    fields={[
      {
        type: "fieldset",
        labelKey: "admin.fieldset.default",
        fields: [
          {
            name: "group",
            type: "object",
            resource: "group",
            disabled: ({ isUpdate }) => isUpdate,
          },
          {
            name: "titleContent",
            labelKey: "admin.field.title",
            type: "translatable",
          },
          {
            name: "eventLocationCategory",
            type: "choice",
            enumType: "EventLocationCategory",
            onChange: ({ value, setValue }) => {
              if (value === "EXTERNAL") {
                setValue("eventLocation", "");
                setValue("submitCategory", "BUTTON");
                setValue("drawCategory", "RANDOM");
                setValue("announceCategory", "DELAYED");
                setValue("winnerCount", 1);
                setValue("applicantGrade", "ALL");
              } else if (value === "OFFLINE") {
                setValue("submitCategory", "BUTTON");
                setValue("drawCategory", "FCFS");
                setValue("announceCategory", "DELAYED");
              } else if (value === "ONLINE") {
                setValue("announceCategory", "DELAYED");
              }
            },
          },
          { name: "eventLocation" },
          {
            name: "eventAt",
            type: "datetime",
          },
        ],
      },
      {
        type: "fieldset",
        labelKey: "admin.fieldset.submitSetting",
        fields: [
          { name: "applyStartedAt", type: "datetime" },
          { name: "applyEndedAt", type: "datetime" },
          {
            name: "submitCategory",
            type: "choice",
            enumType: "EventSubmitCategory",
            onChange: ({ value, setValue, resetValue }) => {
              if (value === "SURVEY") {
                setValue("drawCategory", "RANDOM");
                setValue("announceCategory", "DELAYED");
              } else {
                resetValue("drawCategory");
              }
            },
            disabled: ({ data }) => {
              if (
                data.eventLocationCategory === "EXTERNAL" ||
                data.eventLocationCategory === "OFFLINE"
              ) {
                return true;
              }
            },
            hidden: ({ data }) => data.eventLocationCategory === "EXTERNAL",
          },
          {
            name: "drawCategory",
            type: "choice",
            enumType: "EventDrawCategory",
            disabled: ({ data }) => {
              if (
                data.submitCategory === "SURVEY" ||
                data.eventLocationCategory === "OFFLINE" ||
                data.eventLocationCategory === "EXTERNAL"
              ) {
                return true;
              }
            },
            hidden: ({ data }) => data.eventLocationCategory === "EXTERNAL",
            onChange: ({ data, value, setValue, resetValue }) => {
              if (data.submitCategory === "BUTTON" && value === "RANDOM") {
                setValue("announceCategory", "DELAYED");
              } else {
                resetValue("announceCategory");
              }
              setValue("announceCategory", "DELAYED");
            },
            default: "RANDOM",
          },
          {
            name: "announceCategory",
            type: "choice",
            enumType: "EventAnnounceCategory",
            disabled: true,
            default: "DELAYED",
            hidden: ({ data }) => data.eventLocationCategory === "EXTERNAL",
          },
          {
            name: "winnerCount",
            type: "integer",
            hidden: ({ data }) => data.eventLocationCategory === "EXTERNAL",
            onChange: ({ field, value, setValue }) => {
              if (value < 0) {
                setValue(field.name, 0);
                return 0;
              }
              return value;
            },
          },
          {
            name: "spareCount",
            type: "integer",
            hidden: ({ data }) => data.eventLocationCategory === "EXTERNAL",
            default: 0,
            onChange: ({ field, value, setValue }) => {
              if (!value) setValue(field.name, 0);
              if (value < 0) {
                setValue(field.name, 0);
              }
              return value;
            },
          },
        ],
      },
      {
        type: "fieldset",
        labelKey: "admin.fieldset.applyCondition",
        hidden: ({ data }) => data.eventLocationCategory === "EXTERNAL",
        fields: [
          {
            name: "applicantGrade",
            type: "choice",
            enumType: "EventGradeCategory",
            onChange: ({ data, value, resetValue, setValue }) => {
              if (value === "ALL") {
                resetValue("minAge");
                resetValue("maxAge");
              } else if (value === "MEMBERSHIP" && data.submitCategory === "SURVEY") {
                setValue("survey", {
                  hasNameQuestion: true,
                  hasPhoneQuestion: true,
                  hasBirthDateQuestion: true,
                });
              }
            },
          },
          {
            name: "maxAge",
            type: "integer",
            descriptionKey: "admin.form.guide.checkInteger",
            onChange: ({ field, value, setValue }) => {
              if (value < 0) {
                setValue(field.name, 0);
                return 0;
              }
              return value;
            },
            disabled: ({ data }) => data.applicantGrade === "ALL",
          },
          {
            name: "minAge",
            type: "integer",
            descriptionKey: "admin.form.guide.checkInteger",
            onChange: ({ field, value, setValue }) => {
              if (value < 0) {
                setValue(field.name, 0);
                return 0;
              }
              return value;
            },
            disabled: ({ data }) => data.applicantGrade === "ALL",
          },
          {
            name: "minPenaltyCount",
            type: "integer",
            descriptionKey: "admin.form.guide.checkInteger",
            onChange: ({ field, value, setValue }) => {
              if (value < 0) {
                setValue(field.name, 0);
                return 0;
              }
              return value;
            },
          },
        ],
      },
      {
        type: "fieldset",
        labelKey: "admin.fieldset.eventSurvey",
        descriptionKey: "admin.form.guide.eventSurvey",
        hidden: ({ data }) => data.submitCategory !== "SURVEY",
        fields: [
          {
            name: "survey",
            label: "",
            getter: ({
              hasBirthDateQuestion,
              hasCountryQuestion,
              hasGenderQuestion,
              hasNameQuestion,
              hasPhoneQuestion,
              questions,
            }) => ({
              hasBirthDateQuestion,
              hasCountryQuestion,
              hasGenderQuestion,
              hasNameQuestion,
              hasPhoneQuestion,
              questions,
            }),
            normalizer: (value) => value,
            render: ({ controllerField }) => {
              return <EventSurveyQuestionEditor {...controllerField} />;
            },
          },
        ],
      },
      // ==
      {
        type: "fieldset",
        labelKey: "admin.fieldset.eventNotice",
        fields: [
          {
            name: "noticeTitleContent",
            labelKey: "admin.field.noticeTitle",
            type: "translatable",
          },
          {
            name: "noticeBodyContent",
            labelKey: "admin.field.noticeBody",
            type: "translatable",
            markdown: true,
            rows: 10,
          },
          { name: "isHighlighted", type: "boolean" },
        ],
      },
      ...FormPage.fields.canActivate,
    ]}
    query={QUERY}
    createMutation={CREATE_MUTATION}
    updateMutation={UPDATE_MUTATION}
  />
);

// ==

const FORM_FIELDS_FRAGMENT = gql`
  fragment EventFormFields on Event {
    id
    group {
      id
    }
    tags {
      id
    }
    title
    titleContent {
      ...TranslatableContentFields
    }
    noticeTitle
    noticeTitleContent {
      ...TranslatableContentFields
    }
    noticeBody
    noticeBodyContent {
      ...TranslatableContentFields
    }
    announceCategory
    announcedAt
    submitCategory
    drawCategory
    applicantGrade
    applyEndedAt
    applyStartedAt
    eventAt
    eventLocation
    eventLocationCategory
    isHighlighted
    isCancelAvailable
    maxAge
    minAge
    minPenaltyCount
    winnerCount
    spareCount

    hasBirthDateQuestion
    hasCountryQuestion
    hasGenderQuestion
    hasNameQuestion
    hasPhoneQuestion
    questions {
      id
      title
      description
      placeholder
      category
      isRequired
    }

    ...CanActivateFields
  }
  ${FormPage.fragments.canActivate}
  ${FormPage.fragments.translatableContent}
`;

const QUERY = gql`
  query eventForEventForm($id: ID!) {
    event(filter: { id_Overlap: [$id] }) {
      ...EventFormFields
    }
  }
  ${FORM_FIELDS_FRAGMENT}
`;

const CREATE_MUTATION = gql`
  mutation createEventForEventForm($data: EventMutationInput!) {
    createEvent(data: $data) {
      ok
      errors {
        ...MutationErrorFields
      }
      object {
        ...EventFormFields
      }
    }
  }
  ${FormPage.fragments.mutationError}
  ${FORM_FIELDS_FRAGMENT}
`;

const UPDATE_MUTATION = gql`
  mutation updateEventForEventForm($data: EventMutationInput!, $id: ID!) {
    updateEvent(data: $data, id: $id, partial: true) {
      ok
      errors {
        ...MutationErrorFields
      }
      object {
        ...EventFormFields
      }
    }
  }
  ${FormPage.fragments.mutationError}
  ${FORM_FIELDS_FRAGMENT}
`;
