import { gql } from "@apollo/client";

import { FormPage } from "../../components/pages";
import { GROUP_THEME_FRAGMENT } from "../../utils/gql/fragments";

export const GroupThemeForm = () => (
  <FormPage
    colSpan={24}
    resource="groupTheme"
    fields={[
      {
        type: "fieldset",
        labelKey: "admin.fieldset.basic",
        fields: [
          {
            name: "group",
            type: "object",
            resource: "group",
            disabled: ({ isUpdate }) => isUpdate,
          },
          {
            name: "name",
            labelKey: "admin.field.themeName",
          },
        ],
      },
      {
        type: "fieldset",
        labelKey: "admin.fieldset.color",
        fields: [
          {
            name: "primaryColorLight",
            type: "color",
          },
          {
            name: "primaryColorDark",
            type: "color",
          },
          {
            name: "buttonTextColorModeLight",
            type: "choice",
            enumType: "GroupThemeButtonTextColorMode",
          },
          {
            name: "buttonTextColorModeDark",
            type: "choice",
            enumType: "GroupThemeButtonTextColorMode",
          },
        ],
      },
      {
        type: "fieldset",
        labelKey: "admin.fieldset.homeScreen",
        fields: [
          {
            name: "logoImage",
            type: "imageMeta",
            helpKey: "admin.form.guide.logoImageGroupTheme",
          },
          {
            name: "coverImage",
            type: "imageMeta",
            helpKey: "admin.form.guide.coverImageGroupTheme",
          },
          {
            name: "coverThumbhash",
          },
          {
            name: "coverRatio",
            type: "choice",
            enumType: "GroupThemeCoverRatio",
          },
          {
            name: "logoPosition",
            type: "choice",
            enumType: "GroupThemeLogoPosition",
            helpKey: "admin.form.guide.logoPositionGroupTheme",
          },
        ],
      },
      {
        type: "fieldset",
        labelKey: "admin.fieldset.preview",
        fields: [
          {
            name: "previewMedias",
            type: "genericMeta",
            multiple: true,
          },
        ],
      },
      ...FormPage.fields.canActivate,
    ].map((fieldset) => ({
      ...fieldset,
      fieldsColSpan: 12,
    }))}
    query={QUERY}
    createMutation={CREATE_MUTATION}
    updateMutation={UPDATE_MUTATION}
  />
);

// ==

const QUERY = gql`
  query groupThemeForGroupThemeForm($id: ID!) {
    groupTheme(filter: { id_Overlap: [$id] }) {
      id
      group {
        id
      }
      name
      primaryColorLight
      primaryColorDark
      buttonTextColorModeLight
      buttonTextColorModeDark
      coverImage {
        ...ImageFields
      }
      coverThumbhash
      coverRatio
      logoImage {
        ...ImageFields
      }
      logoPosition
      previewMedias {
        ...MediaFields
      }
      ...CanActivateFields
    }
  }
  ${FormPage.fragments.image}
  ${FormPage.fragments.media}
  ${FormPage.fragments.canActivate}
`;

const CREATE_MUTATION = gql`
  mutation createGroupThemeForGroupThemeForm($data: GroupThemeMutationInput!) {
    createGroupTheme(data: $data) {
      ok
      errors {
        ...MutationErrorFields
      }
      object {
        ...GroupThemeFields
      }
    }
  }
  ${FormPage.fragments.mutationError}
  ${GROUP_THEME_FRAGMENT}
`;

const UPDATE_MUTATION = gql`
  mutation updateGroupThemeForGroupThemeForm(
    $data: GroupThemeMutationInput!
    $id: ID!
  ) {
    updateGroupTheme(data: $data, id: $id, partial: true) {
      ok
      errors {
        ...MutationErrorFields
      }
      object {
        ...GroupThemeFields
      }
    }
  }
  ${FormPage.fragments.mutationError}
  ${GROUP_THEME_FRAGMENT}
`;
