import { forwardRef, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { Button, Col, Input, Row, Space } from "antd";

export const GoogleMapUrlEditor = forwardRef(
  ({ onChange, value: { googleMapUrl } }, ref) => {
    const { t } = useTranslation();
    const mapUrlRef = useRef("");
    const [parsedMapUrl, setParsedMapUrl] = useState(googleMapUrl || "");

    const parseMapUrl = () => {
      const refValue = mapUrlRef.current.input.value;
      const srcMatch = refValue.match(/src="([^"]+)"/);
      setParsedMapUrl(srcMatch ? srcMatch[1] : null);
    };

    const createGoogleMapIframe = () => {
      if (!parsedMapUrl) {
        return "no url";
      }
      return `<iframe src="${parsedMapUrl}" width="70%" height="400px" allowfullscreen="" loading="lazy"></iframe>`;
    };

    useEffect(() => {
      if (parsedMapUrl !== googleMapUrl) {
        onChange?.({ googleMapUrl: parsedMapUrl });
      }
    }, [googleMapUrl, onChange, parsedMapUrl]);

    return (
      <Space direction="vertical" size="middle" ref={ref} style={styles.container}>
        <Row gutter={3}>
          <Col span={20}>
            <Input
              defaultValue={parsedMapUrl && createGoogleMapIframe()}
              ref={mapUrlRef}
              placeholder="Google Map URL"
            />
          </Col>
          <Col span={2}>
            <Button type="primary" onClick={parseMapUrl}>
              {t(`admin.common.ok`)}
            </Button>
          </Col>
        </Row>
        <div dangerouslySetInnerHTML={{ __html: createGoogleMapIframe() }} />
      </Space>
    );
  },
);

const styles = {
  container: {
    width: "100%",
    marginBottom: "16px",
  },
};
