import { gql } from "@apollo/client";

import { DetailPage } from "../../components/pages";
import { CountryListWidget } from "../../components/widgets";

export const SubcontinentDetail = () => (
  <DetailPage
    resource="subcontinent"
    fields={[
      {
        name: "id",
      },
      {
        name: "continent",
        type: "object",
        resource: "continent",
      },
      {
        name: "code",
      },
      {
        name: "name",
      },
      {
        name: "countryCount",
        type: "integer",
      },
      ...DetailPage.fields.canActivate,
    ]}
    query={QUERY}
    extraActions={DetailPage.extraActions.canActivate}
    tabs={[
      {
        path: "",
        labelKey: "admin.resource.country.plural",
        permission: {
          action: "list",
          targetType: "COUNTRY",
        },
        children: ({ obj }) => (
          <CountryListWidget
            baseFilter={{
              subcontinent_Overlap: [obj.id],
            }}
            createSearchParams={{ subcontinent: obj.id }}
            hiddenFields={["continent", "subcontinent"]}
          />
        ),
      },
    ]}
  />
);

// ==

const QUERY = gql`
  query subcontinentForSubcontinentDetail($id: ID!) {
    subcontinent(filter: { id_Overlap: [$id] }) {
      id
      continent {
        id
        name
      }
      code
      name
      countryCount
      ...CanActivateFields
    }
  }
  ${DetailPage.fragments.canActivate}
`;
