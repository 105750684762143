import { forwardRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { DatePicker as AntdDatePicker, Checkbox, Space, Typography } from "antd";

import { useTimezone } from "../../hooks/tz";

const { Text } = Typography;

export const DateTimePicker = forwardRef(
  (
    {
      value,
      setValue,
      onChange,
      disabled = false,
      showAllDay = false,
      showNullable = false,
      showHours = true,
      showMinutes = true,
      showSeconds = true,
      nullableText = undefined,
      placeholder = undefined,
      title = undefined,
      style,
      ...props
    },
    ref,
  ) => {
    const { t } = useTranslation();
    const isMidnight =
      value?.hour() === 0 && value?.minute() === 0 && value?.second() === 0;
    const [isAllDay, setIsAllDay] = useState(showAllDay && isMidnight);
    const [isNullable, setIsNullable] = useState(showNullable && !value);
    const { details } = useTimezone();

    if (!showHours) {
      showMinutes = false;
    }
    if (!showMinutes) {
      showSeconds = false;
    }

    const handleOnChange = (date) => {
      if (!showHours) {
        date = date?.startOf("day");
      } else if (!showMinutes) {
        date = date?.startOf("hour");
      } else if (!showSeconds) {
        date = date?.startOf("minute");
      }
      onChange?.(date);
    };

    return (
      <Space direction="vertical" size="small" style={styles.container}>
        {title && <Text>{title}</Text>}
        <AntdDatePicker
          showTime={!isAllDay}
          format={`YYYY-MM-DD${showHours ? " HH" : ""}${showMinutes ? ":mm" : ""}${
            showSeconds ? ":ss" : ""
          }`}
          value={value}
          onChange={handleOnChange}
          style={{ ...styles.container, ...style }}
          ref={ref}
          {...props}
          disabled={disabled || isNullable}
          placeholder={placeholder ?? "-- " + t("admin.common.placeholderDate")}
        />
        <Text type="secondary" style={styles.secondary}>
          {details.label} {details.text}
        </Text>
        {showAllDay && (
          <Checkbox
            checked={isAllDay}
            onChange={(e) => {
              onChange?.(e.target.checked ? value?.startOf("day") : value);
              setIsAllDay(e.target.checked);
              setValue("isAllDay", e.target.checked);
            }}
          >
            {t("admin.component.dateTimePicker.allDay")}
          </Checkbox>
        )}
        {showNullable && (
          <Checkbox
            checked={isNullable}
            onChange={(e) => {
              onChange?.(null);
              setIsNullable(e.target.checked);
            }}
          >
            {nullableText ?? t("admin.component.dateTimePicker.nullableText")}
          </Checkbox>
        )}
      </Space>
    );
  },
);

// ==

const styles = {
  container: {
    width: "100%",
  },
  secondary: {
    fontSize: "0.9em",
  },
};
