import { gql } from "@apollo/client";

import { formatDuration } from "../../utils/helpers";

export const ClipThumbnail = ({ clip }) => {
  const tag =
    clip.category === "IMAGE"
      ? clip.imageCount && `${clip.imageCount} images`
      : clip.video?.duration && `${formatDuration(clip.video?.duration)}`;

  return (
    <div style={styles.container}>
      {tag && <div style={styles.tag}>{tag}</div>}
      <img src={clip.thumbnail.thumbnailUrl} style={styles.thumbnail} />
    </div>
  );
};

ClipThumbnail.fragments = {
  clip: gql`
    fragment ClipThumbnailFields on Clip {
      thumbnail {
        thumbnailUrl(width: 120, height: 80, mode: CROP)
      }
      video {
        duration
      }
      imageCount
    }
  `,
};

// ==

const styles = {
  container: {
    position: "relative",
    width: 120,
    height: 80,
  },
  tag: {
    position: "absolute",
    bottom: 5,
    right: 5,
    fontSize: "0.75em",
    color: "rgba(255, 255, 255, 0.9)",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    borderRadius: 5,
    padding: 4,
    lineHeight: 1,
  },
  image: {
    width: 120,
    height: 80,
  },
};
