import { useTranslation } from "react-i18next";

import { gql, useMutation } from "@apollo/client";

import { Button } from "antd";

import { useRevalidateQueryFields } from "../../hooks/apollo";
import { useApp } from "../../hooks/app";
import { MUTATION_ERROR_FRAGMENT } from "../../utils/gql/fragments";

export const SelectAttendanceButton = ({ eventId, disabled, selectedRowKeys }) => {
  const [mutate, { data, loading }] = useMutation(MUTATION);
  const { t } = useTranslation();
  const { message } = useApp();
  const revalidate = useRevalidateQueryFields("eventV2Winners");

  const handleButtonClick = async () => {
    const { data } = await mutate({
      variables: {
        winnerIds: selectedRowKeys,
        eventId: eventId,
      },
    });

    if (data) {
      const { selectAttendance } = data;
      if (selectAttendance.ok) {
        message.success(t("admin.message.processSuccess"));
        revalidate();
      } else {
        const errMsg = selectAttendance.errors[0].messages.join("\n");
        message.error(errMsg);
      }
    }
  };
  return (
    <Button loading={loading} disabled={disabled} onClick={handleButtonClick}>
      {t("admin.component.selectAttendanceButton.title")}
    </Button>
  );
};

// =

const MUTATION = gql`
  mutation selectAttendanceForSelectAttendanceButton(
    $winnerIds: [ID!]!
    $eventId: ID!
  ) {
    selectAttendance(winnerIds: $winnerIds, eventId: $eventId) {
      ok
      errors {
        ...MutationErrorFields
      }
    }
  }
  ${MUTATION_ERROR_FRAGMENT}
`;
