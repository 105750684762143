import { useEffect, useState } from "react";

import { gql } from "@apollo/client";

import DataLoader from "dataloader";

import type { EnumValue } from "@/gql/__generated__/graphql";
import { getApolloClient } from "@/utils/gql";
import { isEmpty, zipObject } from "@/utils/helpers";

export type ServerEnum = EnumValue;
export type ServerEnumMapping = Record<string, ServerEnum[]>;

export const useEnum = (type: string) => {
  const [count, setCount] = useState(0);
  const [values, setValues] = useState<ServerEnum[] | null>();

  useEffect(() => {
    if (type) {
      setCount((count) => count + 1);
      enumLoader.load(type).then((values) => {
        setValues(values as ServerEnum[]);
        setCount((count) => count - 1);
      });
    } else {
      setValues(null);
    }
  }, [type]);

  return { loading: count > 0, values };
};

export const useEnums = (types: string[]) => {
  const [count, setCount] = useState(0);
  const [values, setValues] = useState<ServerEnum[][] | null>();
  const [valueMap, setValueMap] = useState<ServerEnumMapping>({});

  useEffect(() => {
    if (!isEmpty(types)) {
      setCount((count) => count + 1);
      enumLoader.loadMany(types).then((values) => {
        setValues(values as ServerEnum[][]);
        setValueMap(zipObject(types, values as ServerEnum[][]));
        setCount((count) => count - 1);
      });
    } else {
      setValues(null);
    }
  }, [types]);

  return { loading: count > 0, values, valueMap };
};

// ==

const enumLoader = new DataLoader(async (keys: ReadonlyArray<string>) => {
  const client = getApolloClient();
  const query = gql`
    query enumValues($names: [String]!) {
      enumValues(names: $names) {
        name
        description
      }
    }
  `;
  const variables = { names: keys as string[] };
  const response = await client.query({ query, variables });
  return response.data.enumValues ?? Array.of(keys.map(() => null));
});
