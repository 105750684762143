import { forwardRef, useEffect } from "react";

import { gql } from "@apollo/client";

import { Typography } from "antd";

import { useUniversalGroup } from "../../hooks/universal";
import { ObjectSelect } from "../fields";

export const SelectGroup = forwardRef(
  (
    { value, onChange, disabled = false, disableUniversalGroup = false, ...props },
    ref,
  ) => {
    const { group, hasMultipleGroups } = useUniversalGroup();

    useEffect(() => {
      if (!disableUniversalGroup && !value && group) {
        onChange?.(group);
      }
    }, [disableUniversalGroup, value, onChange, group]);

    // ==

    return (
      <ObjectSelect
        resource="group"
        query={QUERY}
        optionRender={(group) => (
          <>
            {group.name}
            <Typography.Text type="secondary" style={styles.secondary}>
              {group.code}
            </Typography.Text>
          </>
        )}
        value={value}
        onChange={onChange}
        disabled={disabled || (!disableUniversalGroup && !hasMultipleGroups)}
        ref={ref}
        {...props}
      />
    );
  },
);

// ==

const QUERY = gql`
  query groupsForSelectGroup($filter: GroupFilterInput) {
    groups(filter: $filter, sort: [{ type: RELEVANCE, direction: DESC }]) {
      objects {
        id
        code
        name
      }
    }
  }
`;

// ==

const styles = {
  secondary: {
    marginLeft: 8,
    fontSize: "0.8em",
  },
};
