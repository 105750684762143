import { gql } from "@apollo/client";

import { ListPage } from "../../components/pages";

export const ContinentList = () => (
  <ListPage
    resource="continent"
    fields={[
      {
        name: "id",
      },
      {
        name: "code",
        filterKey: "code_Exact",
        sortKey: "CODE",
      },
      {
        name: "name",
        filterKey: "name_Text",
        sortKey: "NAME",
      },
      {
        name: "subcontinentCount",
        type: "integer",
        filterKey: "subcontinentCount_Range",
        sortKey: "SUBCONTINENT_COUNT",
      },
      {
        name: "countryCount",
        type: "integer",
        filterKey: "countryCount_Range",
        sortKey: "COUNTRY_COUNT",
      },
      ...ListPage.fields.canActivate,
    ]}
    query={QUERY}
    defaultSort={[
      {
        type: "CODE",
        direction: "ASC",
      },
    ]}
    extraRowActions={ListPage.extraRowActions.canActivate}
  />
);

// ==

const QUERY = gql`
  query continentsForContinentList(
    $filter: ContinentFilterInput
    $sort: [ContinentSortInput]
    $page: PageInput
  ) {
    continents(filter: $filter, sort: $sort, page: $page) {
      objects {
        id
        code
        name
        subcontinentCount
        countryCount
        ...CanActivateFields
      }
      ...ListFields
    }
  }
  ${ListPage.fragments.list}
  ${ListPage.fragments.canActivate}
`;
