import { gql } from "@apollo/client";

import { ListPage, ListPageWithGroup } from "../../components/pages";

export const BoothList = () => (
  <ListPageWithGroup
    groupFilterKey="venue_Group_Overlap"
    resource="booth"
    fields={[
      { name: "id" },
      {
        name: "group",
        type: "object",
        resource: "group",
        filterKey: "venue_Group_Overlap",
        getter: ({ venue }) => venue.group,
      },
      {
        name: "title",
        labelKey: "admin.field.boothTitle",
        truncate: true,
      },
      {
        name: "venue",
        type: "object",
        resource: "venue",
        filterKey: "venue_Overlap",
      },
      {
        name: "premium",
        labelKey: "admin.field.premiumOnly",
        type: "boolean",
        getter: (obj) => Boolean(obj.premium),
        filterKey: "premium_Exists",
      },
      {
        name: "operationStatus",
        type: "choice",
        enumType: "BoothOperationStatus",
      },
      {
        name: "waitStatus",
        type: "choice",
        enumType: "BoothWaitStatus",
      },
      {
        name: "exposedStatus",
        type: "choice",
        enumType: "BoothExposedStatus",
      },
      {
        name: "waitListCount",
        type: "integer",
      },
      ...ListPage.fields.tracked,
      ...ListPage.fields.canActivate,
    ]}
    query={QUERY}
    extraRowActions={ListPage.extraRowActions.canActivate}
  />
);

// ==

const QUERY = gql`
  query boothsForBoothList(
    $filter: BoothFilterInput
    $sort: [BoothSortInput]
    $page: PageInput
  ) {
    booths(filter: $filter, sort: $sort, page: $page) {
      objects {
        id
        title
        venue {
          id
          title
          group {
            id
            name
          }
        }
        premium {
          id
        }
        operationStatus
        waitStatus
        exposedStatus
        waitListCount
        ...TrackedFields
        ...CanActivateFields
      }
      ...ListFields
    }
  }
  ${ListPage.fragments.list}
  ${ListPage.fragments.tracked}
  ${ListPage.fragments.canActivate}
`;
