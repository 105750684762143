import React from "react";

import { gql } from "@apollo/client";

import { FormPage } from "../../components/pages";

export const VenueForm = () => (
  <FormPage
    resource="venue"
    fields={[
      {
        type: "fieldset",
        labelKey: "admin.fieldset.basic",
        fields: [
          {
            name: "group",
            type: "object",
            resource: "group",
            disabled: ({ isUpdate }) => isUpdate,
          },
          {
            name: "titleContent",
            labelKey: "admin.field.title",
            type: "translatable",
          },
          {
            name: "staffs",
            type: "object",
            resource: "staff",
            multiple: true,
            getter: ({ staffs }) => staffs?.map((s) => s.staff.id),
            normalizer: (values) => ({
              staffs: (values ?? []).map((v) => ({ staff: v })),
            }),
          },
        ],
      },
      ...FormPage.fields.canActivate,
    ]}
    query={QUERY}
    createMutation={CREATE_MUTATION}
    updateMutation={UPDATE_MUTATION}
  />
);

// ==

const FORM_FIELDS_FRAGMENT = gql`
  fragment VenueFormFields on Venue {
    id
    group {
      id
      code
    }
    staffs {
      id
      venue {
        id
      }
      staff {
        id
        user {
          name
        }
      }
    }
    titleContent {
      ...TranslatableContentFields
    }
    ...CanActivateFields
  }
  ${FormPage.fragments.canActivate}
  ${FormPage.fragments.translatableContent}
`;

const QUERY = gql`
  query VenueForVenueForm($id: ID!) {
    venue(filter: { id_Overlap: [$id] }) {
      ...VenueFormFields
    }
  }
  ${FORM_FIELDS_FRAGMENT}
`;

const CREATE_MUTATION = gql`
  mutation createVenueForVenueForm($data: VenueMutationInput!) {
    createVenue(data: $data) {
      ok
      errors {
        ...MutationErrorFields
      }
      object {
        ...VenueFormFields
      }
    }
  }
  ${FormPage.fragments.mutationError}
  ${FORM_FIELDS_FRAGMENT}
`;

const UPDATE_MUTATION = gql`
  mutation updateVenueForVenueForm($data: VenueMutationInput!, $id: ID!) {
    updateVenue(data: $data, id: $id, partial: true) {
      ok
      errors {
        ...MutationErrorFields
      }
      object {
        ...VenueFormFields
      }
    }
  }
  ${FormPage.fragments.mutationError}
  ${FORM_FIELDS_FRAGMENT}
`;
