import type { ComponentProps, ElementType, PropsWithChildren } from "react";
import { createElement } from "react";

type ConditionalWrapperProps<Wrapper extends ElementType> = PropsWithChildren<{
  /** @default true */
  condition?: boolean;
  wrapper?: Wrapper;
  wrapperProps?: Omit<ComponentProps<Wrapper>, "children">;
}>;

/** If wrapper is exist and condition is true, wrap component. */
export function ConditionalWrapper<Wrapper extends ElementType>({
  condition = true,
  wrapper: Wrapper,
  children,
  wrapperProps,
}: ConditionalWrapperProps<Wrapper>) {
  if (!Wrapper || !condition) return children;
  return createElement(Wrapper, wrapperProps, children);
}
