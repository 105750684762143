import React, { type PropsWithChildren, useEffect, useMemo, useState } from "react";
import { Controller, useFieldArray } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { CloseOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Card, Typography } from "antd";
import styled from "styled-components";

import { DndContext, PointerSensor, useSensor } from "@dnd-kit/core";
import type { DragEndEvent } from "@dnd-kit/core/dist/types";
import {
  SortableContext,
  arrayMove,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { v4 as uuidv4 } from "uuid";

import { ConditionalWrapper, ResolvedText } from "@/components/wrapper";
import type {
  BuiltField,
  CompositeControllerControls,
  CompositeField,
  Field,
  NestedField,
  ServerFieldError,
  SingleField,
  ToBuiltField,
} from "@/types/field";
import type {
  FieldActiveFormProps,
  FieldPassiveFormProps,
  FormStyleMode,
} from "@/types/form";
import type { RecordType } from "@/types/primitives";
import type { CanBeNil } from "@/types/utils";
import {
  clsx,
  getByPath,
  isNull,
  resolveCallable,
  zipObject,
  zipObjectDeep,
} from "@/utils/helpers";

import { FieldMessage } from "./FieldMessage";
import { Fieldset } from "./Fieldset";
import { useFieldContext, useFormContext } from "./context";

const { Text } = Typography;

type Props = {
  /** @default "form" */
  mode?: FormStyleMode;
  fields?: readonly BuiltField[];
  parent?: string[];
  className?: string;
};

type ChildProps<F extends Field = Field> = {
  field: ToBuiltField<F>;
  parent?: string[];
};

// == Field selectors

function renderField(passiveProps: FieldPassiveFormProps) {
  const { field, parent } = passiveProps;
  switch (field.type) {
    case "fieldset":
      return <Fieldset field={field} parent={parent} />;
    case "nested":
      return field.multiple ? (
        <>
          <FieldLabel {...passiveProps} />
          <MultipleNestedField field={field} parent={parent} />
        </>
      ) : (
        // Singular nested field does not show label
        <NestedField field={field} parent={parent} />
      );
    case "composite":
      return <CompositeField field={field} parent={parent} />;
    default:
      return <SingleField field={field as ToBuiltField<SingleField>} parent={parent} />;
  }
}

export const Fields: React.FC<Props> = ({
  mode = "form",
  fields,
  parent = [],
  className,
}) => {
  const { data, errors, isUpdate } = useFormContext();

  return (
    <FieldsContainer className={clsx(`mode-${mode}`, className)}>
      {fields?.map((field) => {
        const passiveProps = { field, parent, data, errors, isUpdate } as const;
        const hidden = resolveCallable(field.hidden, passiveProps);
        if (hidden) return null;

        return (
          <div className="field" key={field.id}>
            {renderField(passiveProps)}
          </div>
        );
      })}
    </FieldsContainer>
  );
};

// == Field commons

const FieldLabel: React.FC<FieldPassiveFormProps> = ({ field, parent }) => {
  if (!field.label || !field.name) return null;
  const fieldName = [...parent, field.name].join(".");
  return (
    <div className="field-label">
      <label htmlFor={fieldName} title={field.label}>
        <Text>{field.label}</Text>
      </label>
    </div>
  );
};

/** Show label, description, helpText with field */
const FieldDescriptorsWrapper = ({
  children,
  passiveProps,
  activeProps,
}: PropsWithChildren<{
  passiveProps: FieldPassiveFormProps;
  activeProps: FieldActiveFormProps;
}>) => {
  const { field } = passiveProps;
  const { help, helpKey, description, descriptionKey, wrapper } = field;

  return (
    <>
      <FieldLabel {...passiveProps} />
      <ResolvedText
        wrapper={FieldMessage}
        wrapperProps={{ type: "fieldDescription" }}
        value={description}
        valueKey={descriptionKey}
        args={passiveProps}
      />
      <ConditionalWrapper wrapper={wrapper} wrapperProps={activeProps}>
        <div className="field-control" style={field.style}>
          {children}
        </div>
      </ConditionalWrapper>
      <ResolvedText
        wrapper={FieldMessage}
        wrapperProps={{ type: "fieldHelp" }}
        value={help}
        valueKey={helpKey}
        args={passiveProps}
      />
    </>
  );
};

// == Field implementations
// TODO: Separate below implementations to each files

const SingleField: React.FC<ChildProps<SingleField>> = ({ field, parent = [] }) => {
  const { errors, control } = useFormContext();
  const { passiveProps, activeProps } = useFieldContext(field, parent);

  const fieldName = [...parent, field.name].join(".");
  const fieldErrorMessage = (getByPath(errors, fieldName) as CanBeNil<ServerFieldError>)
    ?.message;

  return (
    <FieldDescriptorsWrapper activeProps={activeProps} passiveProps={passiveProps}>
      <Controller
        control={control}
        name={fieldName}
        render={({ field: { onChange, ...controllerField } }) =>
          field.render({
            controllerField: {
              ...controllerField,
              status: fieldErrorMessage && "error",
              placeholder: resolveCallable(field.placeholder, passiveProps),
              disabled: resolveCallable(field.disabled, passiveProps),
              onChange: (value: any) => {
                onChange(value);
                field.onChange?.({ value, ...activeProps });
              },
            },
            ...activeProps,
            style: field.style,
          })
        }
      />
      <ResolvedText
        wrapper={FieldMessage}
        wrapperProps={{ type: "fieldError" }}
        value={fieldErrorMessage}
      />
    </FieldDescriptorsWrapper>
  );
};

const CompositeField: React.FC<ChildProps<CompositeField>> = ({
  field,
  parent = [],
}) => {
  const { names } = field;
  const { data, errors, register, unregister, setValue } = useFormContext();
  const { passiveProps, activeProps } = useFieldContext(field, parent);
  const [controls, setControls] = useState<CompositeControllerControls>();
  const fieldNames = useMemo(
    () => names.map((name) => [...parent, name].join(".")),
    [...parent, names],
  );
  useEffect(() => {
    setControls(
      zipObject(
        names,
        // register composite fields to form and key-accessible by each original field names
        fieldNames.map((name) => register(name)),
      ),
    );
    return () => fieldNames.forEach((name) => unregister(name));
  }, [names, fieldNames, register, unregister]);
  const compositeValue = field.getter(data) as RecordType;
  const fieldErrorMessage = useMemo(
    () => fieldNames.map((name) => getByPath(errors, name)?.message ?? "").join(""),
    [errors, fieldNames],
  );
  const handleChange = (newCompositeValue: Record<string, any>) => {
    for (const name in newCompositeValue) {
      if (!names.includes(name)) continue; // skip unregistered fields
      setValue([...parent, name].join("."), newCompositeValue[name], {
        shouldDirty: true,
      });
    }
    const value = { ...compositeValue, ...newCompositeValue };
    field.onChange?.({ value, ...activeProps });
  };

  if (!controls) return null;
  return (
    <FieldDescriptorsWrapper activeProps={activeProps} passiveProps={passiveProps}>
      {field.render({
        controls,
        controllerField: {
          value: compositeValue,
          status: fieldErrorMessage && "error",
          placeholder: resolveCallable(field.placeholder, passiveProps),
          disabled: resolveCallable(field.disabled, passiveProps),
          onChange: handleChange,
        },
        ...activeProps,
      })}
      <ResolvedText
        wrapper={FieldMessage}
        wrapperProps={{ type: "fieldError" }}
        value={fieldErrorMessage}
      />
    </FieldDescriptorsWrapper>
  );
};

const NestedField: React.FC<ChildProps<NestedField>> = ({ field, parent = [] }) => {
  return <Fields fields={field.fields} parent={[...parent, field.name]} />;
};

const MultipleNestedField: React.FC<ChildProps<NestedField>> = ({
  field,
  parent = [],
}) => {
  const fieldName = [...parent, field.name].join(".");

  // ==

  const { t } = useTranslation();
  const sensor = useSensor(PointerSensor, {
    activationConstraint: {
      distance: 10,
    },
  });
  const { control, data } = useFormContext();
  const { replace, append, remove } = useFieldArray({
    control,
    name: fieldName,
  });

  // ==

  const fieldData = getByPath(data, fieldName);

  const addData = () =>
    append(Object.fromEntries(field.fields.map((field) => [field.name, undefined])), {
      shouldFocus: false,
    });
  const removeData = (nestedData: any) =>
    remove(fieldData.findIndex((candidateData: any) => candidateData === nestedData));
  const handleDragEnd = ({ active, over }: DragEndEvent) => {
    if (isNull(over)) return;
    if (active.id !== over.id) {
      const activeIndex = fieldData.findIndex(
        (nestedData: any) => nestedData.__id === active.id,
      );
      const overIndex = fieldData.findIndex(
        (nestedData: any) => nestedData.__id === over.id,
      );

      replace(
        arrayMove(fieldData, activeIndex, overIndex).map((nestedData, i) => ({
          ...(nestedData as any),
          order: i + 1,
        })),
      );
    }
  };

  // ==

  // TODO: Better way?
  fieldData?.forEach((nestedData: any, i: number) => {
    // Assign temporary unique id for sorting
    nestedData.__id ??= uuidv4();
    nestedData.order ??= i + 1;
  });

  return (
    <NestedFieldsContainer>
      <DndContext sensors={[sensor]} onDragEnd={handleDragEnd}>
        <SortableContext
          items={fieldData?.map((nestedData: any) => nestedData.__id) ?? []}
          strategy={verticalListSortingStrategy}
        >
          {fieldData?.map((nestedData: any, i: number) => (
            <Draggable key={nestedData.__id} id={nestedData.__id}>
              <NestedCard>
                <Fields
                  fields={field.fields}
                  parent={[...parent, field.name, `${i}`]}
                />
                <Button
                  type="text"
                  size="small"
                  icon={<CloseOutlined />}
                  onClick={() => removeData(nestedData)}
                  style={styles.nestedRemove}
                />
              </NestedCard>
            </Draggable>
          ))}
        </SortableContext>
      </DndContext>
      <Button icon={<PlusOutlined />} onClick={addData}>
        {t("admin.common.add")}
      </Button>
    </NestedFieldsContainer>
  );
};

const Draggable = ({ id, ...props }: any) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id,
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    cursor: "move",
  };

  return (
    <div ref={setNodeRef} style={style} {...attributes} {...listeners} {...props} />
  );
};

// ==

const FieldsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 24px;
  .field {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 6px;
  }
  &.mode-filter.subfields {
    justify-content: flex-start;
    flex-direction: row;
    .field {
      align-items: center;
      flex: none;
      flex-direction: row;
      .field-label {
        flex: none;
      }
      .field-control {
        min-width: 150px;
        max-width: 350px;
        width: 100%;
      }
    }
  }
`;

const NestedFieldsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 6px;
`;

const NestedCard = styled(Card)`
  position: relative;
`;

const styles = {
  nestedRemove: {
    position: "absolute",
    top: 12,
    right: 12,
    color: "#ccc",
  },
  labelCol: {
    textAlign: "right",
    paddingRight: 24,
    paddingTop: 4,
  },
  labelRow: {
    paddingBottom: 6,
  },
} as const;
