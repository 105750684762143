import { gql } from "@apollo/client";

import { ListWidget } from "../list";

export const HashtagListWidget = (props) => (
  <ListWidget
    resource="hashtag"
    fields={[
      {
        name: "id",
      },
      {
        name: "group",
        type: "object",
        resource: "group",
        filterKey: "group_Overlap",
      },
      {
        name: "name",
        filterKey: "name_Text",
      },
      {
        name: "isHighlighted",
        type: "boolean",
        filterKey: "isHighlighted_Exact",
      },
      ...ListWidget.fields.canActivate,
      ...ListWidget.fields.tracked,
    ]}
    query={QUERY}
    extraRowActions={ListWidget.extraRowActions.canActivate}
    hideCreate
    {...props}
  />
);

// ==

const QUERY = gql`
  query hashtagsForHashtagListWidget(
    $filter: HashtagFilterInput
    $sort: [HashtagSortInput]
    $page: PageInput
  ) {
    hashtags(filter: $filter, sort: $sort, page: $page) {
      objects {
        id
        group {
          id
          name
        }
        name
        isHighlighted
        ...TrackedFields
        ...CanActivateFields
      }
      ...ListFields
    }
  }
  ${ListWidget.fragments.list}
  ${ListWidget.fragments.tracked}
  ${ListWidget.fragments.canActivate}
`;
