import { forwardRef, useEffect, useState } from "react";

import { Input } from "antd";
import type { InputProps, InputRef } from "antd";

import { useDebounce } from "use-debounce";

import { usePrevious } from "@/hooks/previous";
import { Except } from "@/types/utils";

type Props = {
  value: string;
  onChange?: (value: string) => void;
  onPressEnter?: (value: string) => void;
  /** @default 300 */
  delay?: number;
} & Except<InputProps, "value" | "onChange" | "onPressEnter">;

export const DebouncedInput = forwardRef<InputRef, Props>(
  ({ value, onChange, onPressEnter, delay = 300, ...props }, ref) => {
    const [rawValue, setRawValue] = useState(value);
    const [debouncedValue] = useDebounce(rawValue, delay);
    const previousValue = usePrevious(value);

    useEffect(() => {
      if (value !== debouncedValue) {
        if (previousValue !== value) {
          // When the value is changed from outside, we need to update the raw value
          setRawValue(value);
        } else {
          // When the value is changed from inside, we need to update value to the debounced value
          onChange?.(debouncedValue);
        }
      }
    }, [previousValue, value, onChange, debouncedValue]);

    return (
      <Input
        value={rawValue}
        onChange={(e) => setRawValue(e.target.value)}
        onPressEnter={(_) => onPressEnter?.(rawValue)}
        ref={ref}
        {...props}
      />
    );
  },
);
