import { useTranslation } from "react-i18next";
import { Navigate, useLocation } from "react-router-dom";

import { useAuthentication } from "../../hooks/auth";

export const AuthenticationRequired = ({ children }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const { isAuthenticated, user } = useAuthentication();

  if (!isAuthenticated || !user?.staff) {
    const currentUrl = `${location.pathname}${location.search}`;
    const redirectUrl = [
      "/login",
      new URLSearchParams({ next: currentUrl }).toString(),
    ].join("?");
    return (
      <Navigate
        to={redirectUrl}
        replace
        state={{
          // TODO: Use server error message
          type: "warning",
          msg: t("admin.message.loginRequired"),
        }}
      />
    );
  }

  return children;
};
