import Markdown from "react-markdown";
import { useParams } from "react-router-dom";

import { gql, useQuery } from "@apollo/client";

import { Card, Col, Descriptions, Divider, Row, Space, Typography } from "antd";

import dayjs from "dayjs";

import { CAN_ACTIVATE_FRAGMENT, TRACKED_FRAGMENT } from "../../utils/gql/fragments";

const { Title } = Typography;

// ==

const QUERY = gql`
  query EventDetailWidget($id: ID!) {
    event(filter: { id_Overlap: [$id] }) {
      id
      title
      noticeTitle
      noticeBody
      ...TrackedFields
      ...CanActivateFields
    }
  }
  ${TRACKED_FRAGMENT}
  ${CAN_ACTIVATE_FRAGMENT}
`;

// ==

export const EventNoticeWidget = ({
  query = QUERY,
  tabs = undefined,
  children = undefined,
}) => {
  const params = useParams();
  const { data: eventData } = useQuery(query, {
    variables: { id: params.id },
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  });

  const data = eventData?.event;
  const hasContent = tabs?.length > 0 || children;
  const descriptions = [
    {
      key: "1",
      label: "생성일",
      children: dayjs(data?.createdAt).format("YYYY-MM-DD HH:mm"),
    },
    {
      key: "1",
      label: "작성자",
      children: data?.createdBy?.name || "-",
    },
    {
      key: "2",
      label: "수정일",
      children: dayjs(data?.updatedAt).format("YYYY-MM-DD HH:mm"),
    },
    {
      key: "2",
      label: "수정자",
      children: data?.updatedBy?.name || "-",
    },
  ];

  const refinedNoticeBody =
    data?.noticeBody?.replaceAll("&#x20;&#x20;", "\n &nbsp;") || "";

  return (
    <Space direction="vertical" size={0} split={<Divider />} style={styles.container}>
      <Row gutter={24}>
        <Col lg={hasContent ? 6 : 24}>
          <Card>
            <Space direction="vertical" size={0}>
              <Title level={2}>{data?.noticeTitle}</Title>
              <Descriptions
                items={descriptions}
                column={{ xs: 1, sm: 2, md: 3, lg: 3, xl: 4, xxl: 4 }}
                size="small"
              />
              <Divider size="small" />
              <Markdown className="markdown">{refinedNoticeBody}</Markdown>
            </Space>
          </Card>
        </Col>
      </Row>
    </Space>
  );
};

// ==

const styles = {
  container: {
    display: "flex",
  },
  title: {
    margin: 0,
  },
  labelCol: {
    textAlign: "right",
    paddingRight: 24,
  },
};
