import { gql } from "@apollo/client";

import { FormPage } from "../../components/pages";

export const AnnouncementForm = () => (
  <FormPage
    resource="announcement"
    fields={[
      {
        type: "fieldset",
        fields: [
          {
            name: "titleContent",
            labelKey: "admin.field.title",
            type: "translatable",
          },
          {
            name: "bodyContent",
            labelKey: "admin.field.body",
            type: "translatable",
            markdown: true,
            rows: 50,
          },
        ],
      },
      ...FormPage.fields.canActivate,
    ]}
    query={QUERY}
    createMutation={CREATE_MUTATION}
    updateMutation={UPDATE_MUTATION}
  />
);

// ==

const FORM_FIELDS_FRAGMENTS = gql`
  fragment AnnouncementFormFields on Announcement {
    id
    title
    titleContent {
      ...TranslatableContentFields
    }
    body
    bodyContent {
      ...TranslatableContentFields
    }
    status
  }
  ${FormPage.fragments.translatableContent}
`;

const QUERY = gql`
  query announcementForAnnouncementForm($id: ID!) {
    announcement(filter: { id_Overlap: [$id] }) {
      ...AnnouncementFormFields
      ...CanActivateFields
    }
  }
  ${FORM_FIELDS_FRAGMENTS}
  ${FormPage.fragments.canActivate}
`;

const CREATE_MUTATION = gql`
  mutation createAnnouncementForAnnouncementForm($data: AnnouncementMutationInput!) {
    createAnnouncement(data: $data) {
      ok
      errors {
        ...MutationErrorFields
      }
      object {
        ...AnnouncementFormFields
      }
    }
  }
  ${FormPage.fragments.mutationError}
  ${FORM_FIELDS_FRAGMENTS}
`;

const UPDATE_MUTATION = gql`
  mutation updateAnnouncementForAnnouncementForm(
    $data: AnnouncementMutationInput!
    $id: ID!
  ) {
    updateAnnouncement(id: $id, data: $data, partial: true) {
      ok
      errors {
        ...MutationErrorFields
      }
      object {
        ...AnnouncementFormFields
      }
    }
  }
  ${FormPage.fragments.mutationError}
  ${FORM_FIELDS_FRAGMENTS}
`;
