import { gql } from "@apollo/client";

import { DetailPage } from "../../components/pages";

export const AppVersionDetail = () => (
  <DetailPage
    resource="appVersion"
    fields={[
      {
        name: "id",
      },
      {
        name: "revision",
      },
      {
        name: "platform",
        type: "choice",
        enumType: "PlatformCategory",
      },
      ...DetailPage.fields.tracked,
    ]}
    query={QUERY}
  />
);

// ==

const QUERY = gql`
  query appVersionForAppVersionDetail($id: ID!) {
    appVersion(filter: { id_Overlap: [$id] }) {
      id
      revision
      platform
      ...TrackedFields
    }
  }
  ${DetailPage.fragments.tracked}
`;
