import { gql } from "@apollo/client";

import { t } from "i18next";

import { Can } from "../../components/permission";
import { PREMIUM_MEMBERSHIP_FRAGMENT } from "../../utils/gql/fragments";
import { DownloadEventAttendanceButton } from "../actions/DownloadEventAttendanceButton";
import { EventAttendanceButton } from "../actions/EventAttendanceButton";
import { EventPenaltyButton } from "../actions/EventPenaltyButton";
import { ListWidget } from "../list";

const attendanceCategories = {
  attended: "ATTENDED",
  entered: "ENTERED",
};

export const EventAttendanceListWidget = ({ event, ...props }) => {
  const eventId = parseInt(event?.id);

  return (
    <ListWidget
      resource="eventAttendance"
      fields={[
        {
          name: "member",
          type: "object",
          resource: "member",
          filterKey: "member_Overlap",
        },
        {
          name: "winner.order",
          labelKey: "admin.field.order",
          sortKey: "ORDER",
        },
        {
          name: "winner.category",
          type: "choice",
          enumType: "EventWinnerCategory",
          labelKey: "admin.field.category",
          filterKey: "winner_Category_Overlap",
        },
        {
          name: "isAttended",
          type: "boolean",
          labelKey: "admin.field.isAttended",
          filterKey: "isAttend_Exact",
        },
        {
          name: "isEntered",
          type: "boolean",
          labelKey: "admin.field.isEntered",
          filterKey: "isEntered_Exact",
        },
        {
          name: "winner.application.premiumMembership.name",
          labelKey: "admin.field.name",
          filterKey: "winner_Application_PremiumMembership_Name_Text",
          getter: (obj) =>
            obj.winner.application.premiumMembership?.name ||
            obj.member.user.name ||
            "-",
        },
        {
          name: "winner.application.premiumMembership.identifier",
          labelKey: "admin.field.membershipIdentifier",
          filterKey: "winner_Application_PremiumMembership_Identifier_Text",
        },
        {
          name: "winner.application.premiumMembership.birthDate",
          type: "date",
          labelKey: "admin.field.birthDate",
        },
        {
          name: "member.user.phone",
          type: "phone",
          labelKey: "admin.field.phone",
          filterKey: "member_User_Phone_Text",
        },
        {
          name: "member.user.email",
          labelKey: "admin.field.email",
          filterKey: "member_User_Email_Text",
        },
        {
          type: "choice",
          name: "member.status",
          labelKey: "admin.field.memberStatus",
          enumType: "MemberStatus",
        },
        {
          type: "datetime",
          name: "updatedAt",
        },
      ]}
      size={30}
      query={QUERY}
      isRowSelectable={true}
      getCheckboxProps={(record) => ({
        disabled: record?.member?.status !== "ACTIVE",
      })}
      extraActions={({
        filter,
        sort,
        result,
        refetch,
        selectedRowKeys,
        setSelectedRowKeys,
      }) => {
        const attendanceIds = selectedRowKeys.map((item) => parseInt(item, 10));

        return (
          <div style={styles.extraActions}>
            <Can action="download" targetType="EVENT_ATTENDANCE">
              <DownloadEventAttendanceButton
                style={styles.customActionButtons}
                count={result?.count}
                filter={filter}
                sort={sort}
                type="text"
                onlyIcon
              />
            </Can>
            <Can action="update" targetType="EVENT_ATTENDANCE">
              <EventAttendanceButton
                eventId={eventId}
                attendanceType="ATTEND"
                attendanceIds={attendanceIds}
                attendanceCategory={attendanceCategories.attended}
                setSelectedRowKeys={setSelectedRowKeys}
                refetch={refetch}
                buttonText={t("admin.common.attend")}
              />
              <EventAttendanceButton
                eventId={eventId}
                attendanceType="ENTER"
                attendanceIds={attendanceIds}
                attendanceCategory={attendanceCategories.entered}
                setSelectedRowKeys={setSelectedRowKeys}
                refetch={refetch}
                buttonText={t("admin.common.entrance")}
              />
            </Can>
            <Can action="update" targetType="EVENT_PENALTY">
              <EventPenaltyButton
                eventId={eventId}
                attendanceIds={attendanceIds}
                setSelectedRowKeys={setSelectedRowKeys}
                refetch={refetch}
              />
            </Can>
          </div>
        );
      }}
      {...props}
    />
  );
};

// ==

const QUERY = gql`
  query EventAttendanceListWidget(
    $filter: EventAttendanceFilterInput
    $sort: [EventAttendanceSortInput]
    $page: PageInput
  ) {
    eventAttendances(filter: $filter, sort: $sort, page: $page) {
      objects {
        id
        event {
          id
        }
        winner {
          id
          order
          category
        }
        member {
          id
          nickname
          user {
            id
            name
            phone
            email
            birthDate
            isActive
          }
          status
          isActive
        }
        winner {
          application {
            premiumMembership {
              ...PremiumMembershipFields
            }
          }
        }
        isAttended
        attendedAt
        isEntered
        enteredAt
        ...TrackedFields
        ...CanActivateFields
      }
      ...ListFields
    }
  }
  ${ListWidget.fragments.list}
  ${ListWidget.fragments.tracked}
  ${ListWidget.fragments.canActivate}
  ${PREMIUM_MEMBERSHIP_FRAGMENT}
`;

const styles = {
  extraActions: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  customActionButtons: {
    marginRight: "10px",
  },
};
