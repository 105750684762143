import { gql } from "@apollo/client";

import { FormPage } from "../../components/pages";

export const LanguageForm = () => (
  <FormPage
    resource="language"
    fields={[
      {
        name: "code",
        helpKey: "admin.form.guide.codeLanguage",
        disabled: ({ isUpdate }) => isUpdate,
      },
      {
        name: "name",
      },
      {
        name: "nativeName",
      },
      {
        name: "order",
        type: "integer",
        disabled: ({ data, isUpdate }) => data.order !== undefined && !isUpdate,
      },
      {
        name: "isActiveForService",
        type: "boolean",
      },
      {
        name: "isActiveForTranslation",
        type: "boolean",
      },
      {
        name: "isActiveForCaption",
        type: "boolean",
      },
    ]}
    query={QUERY}
    createMutation={CREATE_MUTATION}
    updateMutation={UPDATE_MUTATION}
  />
);

// ==

const FORM_FIELDS_FRAGMENT = gql`
  fragment LanguageFormFields on Language {
    id
    code
    name
    nativeName
    order
    isActiveForService
    isActiveForTranslation
    isActiveForCaption
  }
`;

const QUERY = gql`
  query languageForLanguageForm($id: ID!) {
    language(filter: { id_Overlap: [$id] }) {
      ...LanguageFormFields
    }
  }
  ${FORM_FIELDS_FRAGMENT}
`;

const CREATE_MUTATION = gql`
  mutation createLanguageForLanguageForm($data: LanguageMutationInput!) {
    createLanguage(data: $data) {
      ok
      errors {
        ...MutationErrorFields
      }
      object {
        ...LanguageFormFields
      }
    }
  }
  ${FormPage.fragments.mutationError}
  ${FORM_FIELDS_FRAGMENT}
`;

const UPDATE_MUTATION = gql`
  mutation updateLanguageForLanguageForm($data: LanguageMutationInput!, $id: ID!) {
    updateLanguage(data: $data, id: $id, partial: true) {
      ok
      errors {
        ...MutationErrorFields
      }
      object {
        ...LanguageFormFields
      }
    }
  }
  ${FormPage.fragments.mutationError}
  ${FORM_FIELDS_FRAGMENT}
`;
