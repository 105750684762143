import { type Key, useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { type DocumentNode, useLazyQuery } from "@apollo/client";
import type { WatchQueryFetchPolicy } from "@apollo/client/core/watchQueryOptions";

import { Col, Row, Space, Table } from "antd";
import type { ColProps, TableProps } from "antd";
import type { TableRowSelection } from "antd/es/table/interface";
import styled from "styled-components";

import { FilterForm } from "@/components/form";
import type {
  ResourceButtonOption,
  ResourceButtonType,
} from "@/components/list/action";
import { Can } from "@/components/permission";
import config from "@/config";
import type { GqlFilter, GqlQueryResultObject, GqlResult, GqlSort } from "@/gql/types";
import { useApp } from "@/hooks/app";
import { useFields } from "@/hooks/fields";
import { useAntdSortableFilterFields, useFilterState } from "@/hooks/filter";
import { type RowActionOptions, useRowAction } from "@/hooks/list";
import { useResource } from "@/hooks/resource";
import type { ResourceName } from "@/resources";
import type { BuiltListField, Field } from "@/types/field";
import type { FilterField } from "@/types/filter/FilterField";
import type { ActionRenderer, PageInput } from "@/types/list";
import type { RecordType } from "@/types/primitives";
import type { Except } from "@/types/utils";
import {
  CAN_ACTIVATE_FRAGMENT,
  CAN_HIDE_FRAGMENT,
  LIST_FILE_FRAGMENT,
  LIST_FRAGMENT,
  LIST_IMAGE_FRAGMENT,
  LIST_MEDIA_FRAGMENT,
  LIST_VIDEO_FRAGMENT,
  TIMESTAMPED_FRAGMENT,
  TRACKED_FRAGMENT,
  TRANSLATABLE_CONTENT_FRAGMENT,
  TRANSLATABLE_FILES_FRAGMENT,
} from "@/utils/gql/fragments";

import { ActivateButton, DeleteButton, ForceHideButton } from "../actions";
import { ListAction, ListFilterController } from "./ListAction";
import { Paginator } from "./Paginator";
import { ListCountInfo } from "./action/ListCountInfo";

type ListTableLayout = {
  count: ColProps;
  filterControl: ColProps;
  action: ColProps;
  paginator: ColProps;
  filterForm: ColProps;
  table: ColProps;
};

type Props<
  Q extends DocumentNode,
  Obj extends GqlQueryResultObject<Q>,
  Filter extends GqlFilter<Q>,
> = {
  resource: ResourceName;
  query: Q;
  fields: Field<Obj>[];
  filterFields: FilterField<Filter>[];
  globalSearch?: boolean;
  globalSearchKey?: string;
  defaultPage?: PageInput;
  defaultFilter?: Filter;
  defaultSort?: GqlSort<Q>;
  hiddenFields?: string[];
  size?: number;
  baseFilter?: Filter;
  /** @default true, maybe false if widget */
  hideList?: boolean;
  hideCreate?: boolean;
  hideActions?: boolean;
  extraActions?: ActionRenderer<Obj>;
  isRowSelectable?: boolean;
  getCheckboxProps?: TableRowSelection<Obj>["getCheckboxProps"];
  fetchPolicy?: WatchQueryFetchPolicy;
  /** @default 'primary', maybe 'text' if widget */
  // == List actions
  resourceActionButtonType?: ResourceButtonType;
  resourceCreateOptions?: ResourceButtonOption;
  /** @deprecated use resourceCreateOptions.overrideUrl */
  createUrl?: string;
  /** @deprecated use resourceCreateOptions.additionalSearchParams */
  createSearchParams?: RecordType;
  // ==
  /** override table controllers layout */
  layout?: ListTableLayout;
} & RowActionOptions<Obj> &
  Except<
    TableProps<Obj>,
    | "size"
    | "loading"
    | "columns"
    | "dataSource"
    | "pagination"
    | "onChange"
    | "rowSelection"
  >;

export const ListTable = <
  Q extends DocumentNode,
  Result extends GqlResult<Q>,
  Obj extends GqlQueryResultObject<Q>,
  Filter extends GqlFilter<Q>,
>({
  resource: resourceName,
  query,
  fields: originalFields,
  filterFields,
  globalSearch = true,
  globalSearchKey = "text",
  defaultPage,
  defaultFilter,
  defaultSort,
  hiddenFields,
  size = config.defaultPageSize,
  baseFilter,
  hideActions = false,
  hideList = true,
  hideCreate = false,
  extraActions,
  isRowSelectable = false,
  getCheckboxProps,
  fetchPolicy = "cache-first",
  resourceActionButtonType = "primary",
  resourceCreateOptions,
  layout,
  // == row action options
  hideRowActions,
  hideDetail,
  hideUpdate,
  extraRowActions,
  rowTargetResource,
  // == antd table props
  ...props
}: Props<Q, Obj, Filter>) => {
  const { message } = useApp();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [fetch, { loading, data, previousData, refetch }] = useLazyQuery<Result>(
    query,
    { fetchPolicy },
  );
  const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);
  const [text, setText] = useState("");
  const { resource } = useResource(resourceName);
  const processedFields = useMemo(
    () => originalFields.filter(({ name }) => !name || !hiddenFields?.includes(name)),
    [originalFields, hiddenFields],
  );
  const { fields } = useFields({
    resource: resourceName,
    fields: processedFields,
  });
  const listTableLayout = useMemo(
    () => ({
      ...{
        count: {},
        filterControl: { flex: 1, className: "flex justify-end" },
        action: {},
        filterForm: { span: 24 },
        table: { span: 24 },
        paginator: { span: 24, className: "flex justify-end" },
      },
      ...layout,
    }),
    [layout],
  );
  const defaultValue = useMemo(
    () => ({
      filter: defaultFilter,
      sort: defaultSort,
      page: defaultPage || { first: size },
    }),
    [defaultFilter, defaultSort, defaultPage, size],
  );
  const {
    filter,
    sort,
    page,
    set,
    setPage,
    setFilter,
    pageSize,
    reset: handleReset,
    isResettable,
  } = useFilterState({
    mode: "search-params",
    defaultValue,
  });
  const rowAction = useRowAction<Obj>({
    resource: resourceName,
    hideRowActions,
    hideDetail,
    hideUpdate,
    refetch,
    rowTargetResource,
    extraRowActions,
  });
  const { columns: antdColumns, handleTableChange: handleAntdTableChange } =
    useAntdSortableFilterFields({
      fields: fields as readonly BuiltListField<Obj>[] | null,
      filter,
      sort,
      set,
      rowAction,
    });

  useEffect(() => {
    fetch({ variables: { page, filter: { ...baseFilter, ...filter }, sort } });
  }, [baseFilter, page, filter, sort]); // eslint-disable-line

  useEffect(() => {
    setText(filter?.[globalSearchKey]);
  }, [filter?.[globalSearchKey]]); // eslint-disable-line

  const handleTextChange = useCallback(
    (value: string) => {
      const { [globalSearchKey]: _, ...rest } = filter;
      set({
        filter: { ...rest, ...(value ? { [globalSearchKey]: value } : {}) },
        sort: [
          ...sort.filter((s) => s.type !== "RELEVANCE"),
          ...(value ? [{ type: "RELEVANCE", direction: "DESC" } as const] : []),
        ],
      });
    },
    [filter, sort, globalSearchKey, set],
  );
  const rowSelection = useMemo(
    () =>
      isRowSelectable
        ? {
            selectedRowKeys,
            onChange: (keys: Key[]) => setSelectedRowKeys(keys.map(String)),
            getCheckboxProps,
          }
        : undefined,
    [isRowSelectable, selectedRowKeys, setSelectedRowKeys, getCheckboxProps],
  );
  const handleClickRefetch = useCallback(
    () => refetch().then(() => message.success(t("admin.message.refreshSuccess"))),
    [refetch, message, t],
  );

  // ==

  const result = (data || previousData)?.[resource.typePlural];

  if (!fields || !fields.length) return null;

  return (
    <FlexSpace direction="vertical" size="middle">
      <Row justify="space-between" gutter={[18, 18]} align="bottom">
        <Col {...listTableLayout.count}>
          <ListCountInfo
            count={result?.count}
            labelPlural={resource.labelPlural}
            labelSingular={resource.label}
          />
        </Col>
        <Col {...listTableLayout.filterControl}>
          <ListFilterController
            resource={resourceName}
            list={
              hideList
                ? false
                : {
                    type: resourceActionButtonType,
                    additionalSearchParams: { filter: baseFilter },
                  }
            }
            loading={loading}
            globalSearch={globalSearch}
            searchText={text}
            isResettable={isResettable}
            onSearchTextChange={handleTextChange}
            onReset={handleReset}
            onRefetch={handleClickRefetch}
          />
        </Col>
        <Col {...listTableLayout.action}>
          <ListAction
            resource={resourceName}
            hide={hideActions}
            resourceButtonType={resourceActionButtonType}
            create={hideCreate ? false : resourceCreateOptions}
          >
            {extraActions?.({
              resource,
              refetch,
              navigate,
              selectedRowKeys,
              setSelectedRowKeys,
              filter: { ...baseFilter, ...filter },
              sort,
              page,
              result,
            })}
          </ListAction>
        </Col>
        {filterFields && (
          <Col {...listTableLayout.filterForm}>
            <FilterForm
              query={query}
              defaultValues={defaultFilter}
              resource={resourceName}
              fields={filterFields}
              loading={loading}
              isResettable={isResettable}
              onSubmit={setFilter}
              onReset={handleReset}
            />
          </Col>
        )}
        <Col {...listTableLayout.table}>
          <Table
            loading={loading}
            size="small"
            columns={antdColumns}
            dataSource={result?.objects}
            pagination={false}
            rowKey={resource.keyField}
            onChange={handleAntdTableChange}
            scroll={{ x: "max-content" }}
            rowSelection={rowSelection}
            {...props}
          />
        </Col>
        <Col {...listTableLayout.paginator}>
          {Boolean(result) && (
            <Paginator
              page={result.page}
              count={result.count}
              onPrev={() =>
                setPage({
                  before: result.page.startCursor,
                  last: pageSize,
                })
              }
              onNext={() => setPage({ after: result.page.endCursor, first: pageSize })}
            />
          )}
        </Col>
      </Row>
    </FlexSpace>
  );
};

// ==

ListTable.fragments = {
  list: LIST_FRAGMENT,
  timestamped: TIMESTAMPED_FRAGMENT,
  tracked: TRACKED_FRAGMENT,
  canActivate: CAN_ACTIVATE_FRAGMENT,
  canHide: CAN_HIDE_FRAGMENT,
  file: LIST_FILE_FRAGMENT,
  image: LIST_IMAGE_FRAGMENT,
  video: LIST_VIDEO_FRAGMENT,
  media: LIST_MEDIA_FRAGMENT,
  translatableContent: TRANSLATABLE_CONTENT_FRAGMENT,
  translatableFiles: TRANSLATABLE_FILES_FRAGMENT,
};

ListTable.fields = {
  timestamped: [
    {
      name: "createdAt",
      type: "datetime",
      sortKey: "CREATED_AT",
    },
    {
      name: "updatedAt",
      type: "datetime",
      sortKey: "UPDATED_AT",
    },
  ],
  tracked: [
    {
      name: "createdAt",
      type: "datetime",
      sortKey: "CREATED_AT",
      filterKey: "createdAt_Range",
    },
    {
      name: "createdBy",
      type: "object",
      resource: "user",
      filterKey: "createdBy_Overlap",
    },
    {
      name: "updatedAt",
      type: "datetime",
      sortKey: "UPDATED_AT",
      filterKey: "updatedAt_Range",
    },
    {
      name: "updatedBy",
      type: "object",
      resource: "user",
      filterKey: "updatedBy_Overlap",
    },
  ],
  canActivate: [
    {
      type: "boolean",
      name: "isActive",
      filterKey: "isActive_Exact",
    },
  ],
  canHide: [
    {
      type: "boolean",
      name: "isHidden",
      filterKey: "isHidden_Exact",
    },
    {
      type: "boolean",
      name: "isHiddenStatusForced",
      filterKey: "isHiddenStatusForced_Exact",
    },
  ],
};

ListTable.extraRowActions = {
  delete: ({ obj, resource, refetch }) => (
    <Can action="delete" targetType={resource.typeName} targetId={obj.id}>
      <DeleteButton
        targetType={resource.typeName}
        targetId={obj.id}
        onDelete={() => refetch()}
        size="small"
        onlyIcon
      ></DeleteButton>
    </Can>
  ),
  canActivate: ({ obj, refetch, resource }) => (
    <Can
      action={obj.isActive ? "inactivate" : "activate"}
      targetType={resource.typeName}
      targetId={obj.id}
    >
      <ActivateButton
        targetType={resource.typeName}
        targetId={obj.id}
        value={obj.isActive}
        onChange={() => refetch()}
        size="small"
        onlyIcon
      ></ActivateButton>
    </Can>
  ),
  canHide: ({ obj, refetch, resource }) => (
    <Can
      action={obj.isHidden ? "unhide" : "hide"}
      targetType={resource.typeName}
      targetId={obj.id}
    >
      <ForceHideButton
        targetType={resource.typeName}
        targetId={obj.id}
        value={obj.isHidden}
        onChange={() => refetch()}
        size="small"
        onlyIcon
      ></ForceHideButton>
    </Can>
  ),
};

const FlexSpace = styled(Space)`
  display: flex;
`;
