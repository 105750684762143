import { gql } from "@apollo/client";

import { ListWidget } from "../list";

export const ArtistListWidget = (props) => (
  <ListWidget
    resource="artist"
    fields={[
      {
        name: "id",
      },
      {
        name: "group",
        type: "object",
        resource: "group",
        filterKey: "group_Overlap",
      },
      {
        name: "user.email",
        labelKey: "admin.field.email",
        type: "email",
      },
      {
        name: "name",
        labelKey: "admin.field.stageName",
      },
      {
        name: "nickname",
      },
      {
        name: "birthDate",
        type: "date",
        filterKey: "birthDate_Range",
        sortKey: "BIRTH_DATE",
      },
      {
        name: "user.createdAt",
        labelKey: "admin.field.joinedAt",
        type: "datetime",
      },
      {
        name: "user.lastAuthenticatedAt",
        labelKey: "admin.field.lastAuthenticatedAt",
        type: "datetime",
      },
      ...ListWidget.fields.canActivate,
    ]}
    query={QUERY}
    {...props}
  />
);

// ==

const QUERY = gql`
  query artistsForArtistListWidget(
    $filter: ArtistFilterInput
    $sort: [ArtistSortInput]
    $page: PageInput
  ) {
    artists(filter: $filter, sort: $sort, page: $page) {
      objects {
        id
        group {
          id
          name
        }
        user {
          id
          email
          createdAt
          lastAuthenticatedAt
        }
        name
        birthDate
        nickname
        ...TrackedFields
        ...CanActivateFields
      }
      ...ListFields
    }
  }
  ${ListWidget.fragments.list}
  ${ListWidget.fragments.tracked}
  ${ListWidget.fragments.canActivate}
`;
