import { gql } from "@apollo/client";

import { ListPage, ListPageWithGroup } from "../../components/pages";

export const ScheduleList = () => (
  <ListPageWithGroup
    groupFilterKey="group_Overlap"
    resource="schedule"
    subtitleKey="admin.page.schedule.subtitle"
    fields={[
      {
        name: "id",
      },
      {
        name: "group",
        type: "object",
        resource: "group",
        filterKey: "group_Overlap",
      },
      {
        name: "title",
      },
      {
        name: "artists",
        type: "object",
        resource: "artist",
        filterKey: "artists_Overlap",
      },
      {
        name: "category",
        type: "choice",
        enumType: "ScheduleCategory",
        filterKey: "category_Overlap",
      },
      {
        name: "scheduledAt",
        type: "datetime",
        filterKey: "scheduledAt_Range",
        sortKey: "SCHEDULED_AT",
      },
      {
        name: "status",
        type: "choice",
        enumType: "ScheduleStatus",
        filterKey: "status_Overlap",
      },
      {
        name: "activateAt",
        type: "datetime",
      },
      ...ListPage.fields.tracked,
    ]}
    query={QUERY}
    extraRowActions={ListPage.extraRowActions.canActivate}
  />
);

// ==

const QUERY = gql`
  query schedulesForScheduleList(
    $filter: ScheduleFilterInput
    $sort: [ScheduleSortInput]
    $page: PageInput
  ) {
    schedules(filter: $filter, sort: $sort, page: $page) {
      objects {
        id
        group {
          id
          name
        }
        title
        artists {
          id
          name
        }
        category
        scheduledAt
        status
        ...TrackedFields
        ...CanActivateFields
      }
      ...ListFields
    }
  }
  ${ListPage.fragments.list}
  ${ListPage.fragments.tracked}
  ${ListPage.fragments.canActivate}
`;
