import { gql, useMutation } from "@apollo/client";

import { Button } from "antd";

import { t } from "i18next";

import { useApp } from "../../hooks/app";

export const EventPenaltyActivateButton = ({
  isActive,
  penaltyIds,
  setSelectedRowKeys,
  buttonText,
  refetch,
}) => {
  const [penalty, { loading, error }] = useMutation(MUTATION);
  const { message } = useApp();

  const mutErros = [error];

  mutErros.forEach((err) => {
    if (err) {
      console.error(err);
      message.error(JSON.stringify(err));
    }
  });

  return (
    <Button
      style={styles.customActionButtons}
      type={isActive ? "default" : "primary"}
      danger={!isActive}
      onClick={async () => {
        await refetch();

        const data = await penalty({
          variables: {
            isActive: isActive,
            penaltyIds: penaltyIds,
          },
        });
        const parsed = data?.data?.penaltyEvent;
        if (parsed) {
          if (parsed?.ok) {
            message.success(t("admin.message.processSuccess"));
          } else {
            const errMsg = parsed?.errors[0].messages.join("\n");
            message.error(errMsg);
          }
        }

        setTimeout(async () => {
          await refetch();
        }, 400);
        setSelectedRowKeys([]);
      }}
      loading={loading}
      disabled={penaltyIds.length === 0}
    >
      {buttonText}
    </Button>
  );
};

// ==

const MUTATION = gql`
  mutation PenaltyEvent($isActive: Boolean!, $penaltyIds: [Int!]!) {
    penaltyEvent(isActive: $isActive, penaltyIds: $penaltyIds) {
      ok
      count
      errors {
        code
        title
        messages
      }
    }
  }
`;

const styles = {
  customActionButtons: {
    marginRight: "10px",
  },
};
