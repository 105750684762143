import { useTranslation } from "react-i18next";

import { Alert, Col, Row, Space, Table } from "antd";

import { formatDuration } from "../../utils/helpers";

export const YoutubePlaylistVideoViewer = ({ videos }) => {
  const { t } = useTranslation();
  return (
    <Space direction="vertical" size="large" style={styles.container}>
      <Alert
        type="warning"
        message={t("admin.component.youtubePlaylistVideoViewer.alertMessage")}
      />
      <Row justify="space-between" align="top">
        <Col>
          {t("admin.component.youtubePlaylistVideoViewer.clipCount", {
            count: videos?.length ?? 0,
          })}
        </Col>
      </Row>
      <Table
        rowKey="id"
        columns={[
          {
            title: "Thumbnail",
            dataIndex: "thumbnailUrl",
            render: (thumbnailUrl) => (
              <img src={thumbnailUrl} style={styles.thumbnail} />
            ),
          },
          {
            title: "Title",
            dataIndex: "title",
          },
          {
            title: "Duration",
            dataIndex: "duration",
            render: (duration) => formatDuration(duration),
            align: "right",
          },
        ]}
        dataSource={videos}
        pagination={false}
      />
    </Space>
  );
};

// ==

const styles = {
  container: {
    width: "100%",
  },
  thumbnail: {
    maxWidth: 200,
  },
};
