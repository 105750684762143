import { useTranslation } from "react-i18next";
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";

import { gql, useMutation, useQuery } from "@apollo/client";

import { Button } from "antd";

import { FormDrawer } from "../../components/drawers";
import { DetailPage, FormPage } from "../../components/pages";
import { Can } from "../../components/permission";
import { useApp } from "../../hooks/app";
import { useDrawerState } from "../../hooks/drawer";
import { useAbilities } from "../../hooks/permission";
import { MUTATION_ERROR_FRAGMENT, TRACKED_FRAGMENT } from "../../utils/gql/fragments";
import { TranslationRequestTargetContentPanel } from "./TranslationRequestTargetContentPanel";

export const TranslationRequestTargetContentDetailForm = () => {
  const navigate = useNavigate();
  const { message } = useApp();
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const fromWhere = searchParams.get("from");
  const isRequestManagementContext = fromWhere === "request";
  const params = useParams();
  const { doesRender, openDrawer, closeDrawer, drawerProps } = useDrawerState();
  const { abilities } = useAbilities(permissions);
  const [isAdminStaff] = abilities ?? [false];
  const { refetch } = useQuery(QUERY, {
    variables: { id: params.id },
    notifyOnNetworkStatusChange: true,
  });
  const onSuccess = () => {
    refetch();
    closeDrawer();
  };
  const getDrawerContextInfo = (progressStatus) => {
    if (isRequestManagementContext) {
      if (progressStatus === "COMPLETED")
        return {
          title: t("admin.component.reopenTranslationButton.title"),
          transition: "REJECTED",
          assignable: false,
        };
      else return { title: "NA", transition: "NA", assignable: false };
    } else {
      if (
        progressStatus === "OPENED" ||
        progressStatus === "ASSIGNED" ||
        progressStatus === "REJECTED"
      )
        return {
          title: t("admin.component.assignButton.title"),
          transition: "ASSIGNED",
          assignable: true,
        };
      else if (progressStatus === "REVIEWED")
        return {
          title: t("admin.component.reopenTranslationButton.title"),
          transition: "TRANSLATING",
          assignable: false,
        };
      else return { title: "NA", transition: "NA", assignable: false };
    }
  };
  const handleResponse = (response) => {
    const { ok, errors } = Object.values(response)[0];
    if (ok) {
      refetch()
        .then(() => message.success(t("admin.message.processSuccess")))
        .catch(() => message.error(t("admin.message.processFailure")));
    } else {
      message.error(
        t("admin.message.processFailure", {
          message: errors[0].messages[0],
        }),
      );
    }
  };
  const [mutateStatus, { loading: loadingStatus }] =
    useMutation(UPDATE_STATUS_MUTATION);
  const [mutateInactivation, { loading: loadingInactivation }] =
    useMutation(INACTIVATE_MUTATION);
  const updateStatus = (status) => {
    mutateStatus({
      variables: {
        id: params.id,
        data: { progressStatus: status },
      },
      onCompleted: (response) => {
        handleResponse(response);
      },
    });
  };
  const updateInactivation = () => {
    mutateInactivation({
      variables: {
        type: "TRANSLATION_REQUEST_TARGET_CONTENT",
        id: params.id,
        data: { activationMemo: "Deactivated from admin" },
      },
      onCompleted: (response) => {
        handleResponse(response);
        navigate("/translation-request-management/list");
      },
    });
  };

  const fields = [
    {
      name: "translationRequest.id",
      label: "Request ID",
    },
    {
      name: "id",
      label: "ID",
    },
    {
      name: "translationRequest.group.name",
      type: "object",
      resource: "group",
      getter: (value) => value.translationRequest.group,
    },
    {
      name: "translationRequest.title",
      labelKey: "admin.field.title",
    },
    {
      name: "translationRequest.category",
      labelKey: "admin.field.category",
    },
    {
      name: "translationRequest.requestor",
      labelKey: "admin.field.requestor",
      type: "object",
      resource: "staff",
      getter: (value) => value.translationRequest.requestor,
    },
    {
      name: "assignee",
      type: "object",
      resource: "staff",
      getter: (value) => value.assignee,
    },
    {
      name: "requestStatus",
      type: "choice",
      enumType: "TranslationRequestStatus",
    },
    {
      name: "translationRequest.inputSource",
      labelKey: "admin.field.inputSource",
      type: "choice",
      enumType: "TranslationRequestInputSource",
    },
    // {
    //   name: "translationRequest.sourceWordCount",
    //   labelKey: "admin.field.sourceWordCount",
    //   type: "number",
    // },
    {
      name: "translationRequest.targetWordCountAll",
      labelKey: "admin.field.targetWordCountAll",
      type: "number",
      getter: (value) =>
        value.translationRequest.targetContents.reduce(
          (acc, cur) => acc + cur.targetWordCount,
          0,
        ),
    },
    {
      name: "targetWordCount",
      type: "number",
    },
    {
      name: "translationRequest.sourceLanguage",
      labelKey: "admin.field.sourceLanguage",
      type: "choice",
      enumType: "ServiceLanguage",
    },
    {
      name: "targetLanguage",
      type: "choice",
      enumType: "ServiceLanguage",
    },
    {
      name: "translationRequest.dueAt",
      labelKey: "admin.field.dueAt",
      type: "datetime",
      format: "YYYY-MM-DD",
    },
    {
      name: "assignedAt",
      type: "datetime",
    },
    {
      name: "createdAt",
      type: "datetime",
    },
    {
      name: "finalSubmittedAt",
      type: "datetime",
    },
    {
      name: "transmissionConfirmedAt",
      type: "datetime",
    },
    {
      name: "closedAt",
      type: "datetime",
    },
  ];
  if (!isRequestManagementContext) {
    fields.splice(8, 0, {
      name: "progressStatus",
      type: "choice",
      enumType: "TranslationProgressStatus",
    });
  }

  return (
    <DetailPage
      resource="translationRequestTargetContent"
      fields={fields}
      query={QUERY}
      hideUpdate={true}
      extraActions={({ obj }) => {
        const drawerInfo = getDrawerContextInfo(obj.progressStatus);
        return (
          <>
            <Can action="approve" targetType="TRANSLATION_REQUEST_TARGET_CONTENT">
              <Can
                action="update"
                targetType="TRANSLATION_REQUEST"
                targetId={obj.translationRequest.id}
              >
                {isRequestManagementContext && (
                  <>
                    <Link
                      to={`/translation-requests/${obj.translationRequest.id}/edit`}
                    >
                      <Button disabled={obj.progressStatus != "OPENED"}>
                        {t("admin.component.updateButton.title")}
                      </Button>
                    </Link>
                    <Button
                      onClick={() => updateInactivation()}
                      loading={loadingInactivation}
                      disabled={obj.progressStatus != "OPENED"}
                    >
                      {t("admin.component.inactivateButton.title")}
                    </Button>
                    <Button
                      onClick={() => updateStatus("CLOSED")}
                      loading={loadingStatus}
                      disabled={obj.progressStatus != "COMPLETED"}
                    >
                      {t("admin.component.confirmButton.title")}
                    </Button>
                    <Button
                      onClick={() => openDrawer()}
                      disabled={obj.progressStatus != "COMPLETED"}
                    >
                      {t("admin.component.reopenTranslationButton.title")}
                    </Button>
                  </>
                )}
              </Can>
              {!isRequestManagementContext && !isAdminStaff && (
                <>
                  <Button
                    onClick={() => openDrawer()}
                    disabled={
                      !["OPENED", "ASSIGNED", "REJECTED"].includes(obj.progressStatus)
                    }
                  >
                    {getDrawerContextInfo("ASSIGNED").title}
                  </Button>
                  <Button
                    onClick={() => openDrawer()}
                    disabled={obj.progressStatus !== "REVIEWED"}
                  >
                    {getDrawerContextInfo("REVIEWED").title}
                  </Button>
                  <Button
                    onClick={() => updateStatus("COMPLETED")}
                    loading={loadingStatus}
                    disabled={obj.progressStatus !== "REVIEWED"}
                  >
                    {t("admin.component.confirmTransmissionButton.title")}
                  </Button>
                </>
              )}
            </Can>
            {doesRender && (
              <FormDrawer
                title={drawerInfo.title}
                resource="translationRequestTargetContent"
                fields={[
                  {
                    name: "progressStatus",
                    type: "choice",
                    enumType: "TranslationProgressStatus",
                    control: "select",
                    getter: () => drawerInfo.transition,
                    disabled: true,
                  },
                  {
                    name: "targetLanguage",
                    type: "choice",
                    enumType: "ServiceLanguage",
                    control: "select",
                    disabled: true,
                    forbidden: true,
                  },
                  {
                    name: "assigneeId",
                    type: "object",
                    resource: "staff",
                    labelKey: "admin.field.assignee",
                    baseFilter: {
                      roleCategories_Overlap: [
                        "TRANSLATOR",
                        "TRANSLATION_MANAGER",
                        "TRANSLATION_APPROVER",
                      ],
                    },
                    getter: () => (drawerInfo.assignable ? null : obj.assignee.id),
                    disabled: !drawerInfo.assignable,
                    forbidden: !drawerInfo.assignable,
                  },
                  {
                    name: "memo",
                    type: "text",
                    rows: 5,
                    normalizer: (value) => ({ memo: value ? value.trim() : null }),
                  },
                ]}
                id={params.id}
                query={QUERY}
                updateMutation={UPDATE_STATUS_MUTATION}
                onSuccess={onSuccess}
                drawerProps={drawerProps}
              ></FormDrawer>
            )}
          </>
        );
      }}
      tabs={[
        {
          path: "",
          label: "",
          children: ({ obj }) => (
            <TranslationRequestTargetContentPanel targetContent={obj} showForm={true} />
          ),
        },
      ]}
    />
  );
};

// ==

const permissions = [
  {
    action: "update",
    targetType: "TRANSLATION_REQUEST",
  },
];

// ==

const FORM_FIELDS_FRAGMENT = gql`
  fragment TranslationRequestTargetContentFormFields on TranslationRequestTargetContent {
    translationRequest {
      inputSource
    }
    assignee {
      id
    }
    requestStatus
    progressStatus
    plainText
    uploadFiles {
      key
      mime
      size
      url
    }
  }
`;

const QUERY = gql`
  query translationRequestTargetContentForDetailForm($id: ID!) {
    translationRequestTargetContent(filter: { id_Overlap: [$id] }) {
      translationRequest {
        id
        group {
          id
          name
        }
        requestor {
          id
          user {
            name
          }
        }
        sourceLanguage
        title
        category
        inputSource
        sourceWordCount
        plainText
        uploadFiles {
          key
          mime
          size
          url
          downloadUrl
        }
        targetContents {
          targetWordCount
        }
        dueAt
        additionalRequestComment
      }
      assignee {
        id
        user {
          name
        }
      }
      revisions {
        author {
          id
          user {
            name
            email
          }
        }
        progressStatus
        memo
        createdAt
      }
      id
      targetLanguage
      requestStatus
      progressStatus
      plainText
      uploadFiles {
        key
        mime
        size
        url
        downloadUrl
      }
      reopenCount
      targetWordCount
      assignedAt
      finalSubmittedAt
      transmissionConfirmedAt
      closedAt
      ...TrackedFields
    }
  }
  ${TRACKED_FRAGMENT}
`;

const UPDATE_STATUS_MUTATION = gql`
  mutation updateTranslationRequestTargetContentStatusForm(
    $data: TranslationRequestTargetContentStatusMutationInput!
    $id: ID!
  ) {
    updateTranslationRequestTargetContentStatus(data: $data, id: $id, partial: true) {
      ok
      errors {
        ...MutationErrorFields
      }
      object {
        ...TranslationRequestTargetContentFormFields
      }
    }
  }
  ${FormPage.fragments.mutationError}
  ${FORM_FIELDS_FRAGMENT}
`;

const INACTIVATE_MUTATION = gql`
  mutation inactivateForActivateButton(
    $type: ModelDocumentType!
    $id: ID!
    $data: InactivateMutationInput!
  ) {
    inactivate(type: $type, id: $id, data: $data) {
      ok
      errors {
        ...MutationErrorFields
      }
      object {
        isActive
      }
    }
  }
  ${MUTATION_ERROR_FRAGMENT}
`;
