import { useState } from "react";
import { useTranslation } from "react-i18next";

import { gql, useMutation } from "@apollo/client";

import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";
import { Button, Modal, Typography } from "antd";

import { useApp } from "../../hooks/app";
import { MUTATION_ERROR_FRAGMENT } from "../../utils/gql/fragments";
import { capitalize } from "../../utils/helpers";

export const ForceHideButton = ({
  targetType,
  targetId,
  value,
  onChange,
  size = "middle",
  onlyIcon = false,
}) => {
  const { t } = useTranslation();

  const variables = {
    type: targetType,
    id: targetId,
    data: {},
  };
  const onCompleted = (response) => {
    const { ok, errors, object } = Object.values(response)[0];
    if (ok) {
      message.success(t("admin.message.processSuccess"));
      onChange?.(object);
      setIsModalOpen(false);
    } else {
      message.error(
        t("admin.message.processFailure", {
          message: errors[0].messages[0],
        }),
      );
    }
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [hide, { loading: loadingForHide }] = useMutation(FORCE_HIDE_MUTATION, {
    variables,
    onCompleted,
  });
  const [unhide, { loading: loadingForUnhide }] = useMutation(FORCE_UNHIDE_MUTATION, {
    variables,
    onCompleted,
  });
  const { message } = useApp();

  const loading = loadingForHide || loadingForUnhide;
  const action = value
    ? t("admin.component.forceHideButton.unhide")
    : t("admin.component.forceHideButton.hide");
  const onClick = () => setIsModalOpen(true);
  const onOk = () => (value ? unhide() : hide());
  const onCancel = () => setIsModalOpen(false);

  return (
    <>
      <Button
        type={!value ? "default" : "primary"}
        ghost
        danger={!value}
        icon={value ? <EyeOutlined /> : <EyeInvisibleOutlined />}
        onClick={onClick}
        size={size}
      >
        {onlyIcon ? null : capitalize(action)}
      </Button>
      <Modal
        title={t(`admin.common.confirmation`)}
        open={isModalOpen}
        confirmLoading={loading}
        okText={capitalize(action)}
        okButtonProps={{
          icon: value ? <EyeOutlined /> : <EyeInvisibleOutlined />,
          danger: !value,
        }}
        cancelText={t("admin.common.cancel")}
        onOk={onOk}
        onCancel={onCancel}
      >
        <Typography.Text>
          {value
            ? t("admin.component.forceHideButton.unhideMessage")
            : t("admin.component.forceHideButton.hideMessage")}
        </Typography.Text>
      </Modal>
    </>
  );
};

// ==

const FORCE_HIDE_MUTATION = gql`
  mutation forceHideForHideButton($type: ModelDocumentType!, $id: ID!) {
    forceHide(targetType: $type, targetId: $id) {
      ok
      errors {
        ...MutationErrorFields
      }
    }
  }
  ${MUTATION_ERROR_FRAGMENT}
`;

const FORCE_UNHIDE_MUTATION = gql`
  mutation forceUnhideForHideButton($type: ModelDocumentType!, $id: ID!) {
    forceUnhide(targetType: $type, targetId: $id) {
      ok
      errors {
        ...MutationErrorFields
      }
    }
  }
  ${MUTATION_ERROR_FRAGMENT}
`;
