import { forwardRef } from "react";

import { gql } from "@apollo/client";

import { ObjectSelect } from "../fields";

export const SelectOfflineStore = forwardRef((props, ref) => (
  <ObjectSelect resource="offlineStore" query={QUERY} ref={ref} {...props} />
));

// ==

const QUERY = gql`
  query offlineStoresForSelectOfflineStore($filter: OfflineStoreFilterInput) {
    offlineStores(filter: $filter, sort: [{ type: RELEVANCE, direction: DESC }]) {
      objects {
        id
        name
      }
    }
  }
`;
