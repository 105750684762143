import { gql } from "@apollo/client";

import { SuspendMemberButton, UnsuspendMemberButton } from "../../components/actions";
import { DetailPage } from "../../components/pages";
import { Can } from "../../components/permission";
import {
  AuditHiddenListWidget,
  AuditSuspendedListWidget,
  CommentListWidget,
  MemberEventV2ApplicationListWidget,
  PostListWidget,
  PremiumMembershipListWidget,
} from "../../components/widgets";
import { AuditInactivatedListWidget } from "../../components/widgets/AuditInactivatedListWidget.jsx";

export const MemberDetail = () => (
  <DetailPage
    resource="member"
    fields={[
      {
        name: "id",
      },
      {
        name: "group",
        type: "object",
        resource: "group",
      },
      {
        name: "user.email",
        labelKey: "admin.field.email",
        type: "email",
      },
      {
        name: "user",
        type: "object",
        resource: "user",
      },
      {
        name: "user.phone",
        labelKey: "admin.field.phone",
        type: "phone",
      },
      {
        name: "nickname",
      },
      {
        name: "profileImage",
        type: "imageMeta",
      },
      {
        name: "coverImage",
        type: "imageMeta",
      },
      {
        name: "artist",
        type: "object",
        resource: "artist",
      },
      {
        name: "hasPremiums",
        type: "boolean",
        getter: (obj) => Boolean(obj.premiums?.length),
      },
      {
        name: "premiums",
        type: "object",
        resource: "premium",
        multiple: true,
      },
      {
        name: "isActive",
        type: "boolean",
      },
      {
        name: "user.isActive",
        labelKey: "admin.field.userIsActive",
        type: "boolean",
      },
      {
        name: "suspensionCategory",
        type: "choice",
        enumType: "MemberSuspensionCategory",
      },
      {
        name: "suspensionReason",
        type: "choice",
        enumType: "MemberSuspensionReason",
      },
      {
        name: "suspensionPeriod",
        type: "choice",
        enumType: "MemberSuspensionPeriod",
      },
      {
        name: "suspensionPeriodUntil",
        type: "datetime",
      },
      {
        name: "suspendedAt",
        type: "datetime",
      },
      {
        name: "postCount",
        type: "integer",
      },
      {
        name: "commentCount",
        type: "integer",
      },
      {
        name: "likeCount",
        type: "integer",
      },
      {
        name: "bookmarkCount",
        type: "integer",
      },
      {
        name: "user.lastAuthenticatedAt",
        labelKey: "admin.field.lastAuthenticatedAt",
        type: "datetime",
      },
      {
        name: "user.createdAt",
        labelKey: "admin.field.joinedAt",
        type: "datetime",
      },
      {
        name: "createdAt",
        labelKey: "admin.field.groupJoinedAt",
        type: "datetime",
      },
    ]}
    query={QUERY}
    extraActions={({ obj, refetch }) => (
      <>
        <Can action="suspend" targetType="MEMBER" targetId={obj.id}>
          <SuspendMemberButton
            memberId={obj.id}
            onChange={() => refetch()}
          ></SuspendMemberButton>
        </Can>
        {obj.suspensionCategory && (
          <Can action="suspend" targetType="MEMBER" targetId={obj.id}>
            <UnsuspendMemberButton
              memberId={obj.id}
              onChange={() => refetch()}
            ></UnsuspendMemberButton>
          </Can>
        )}
      </>
    )}
    tabs={[
      {
        path: "",
        labelKey: "admin.resource.comment.plural",
        permission: {
          action: "list",
          targetType: "COMMENT",
        },
        children: ({ obj }) => (
          <CommentListWidget
            baseFilter={{
              group_Overlap: [obj.group.id],
              member_Overlap: [obj.id],
            }}
            createSearchParams={{
              group: obj.group.id,
              mbmer: obj.id,
            }}
            hiddenFields={["group", "member"]}
          />
        ),
      },
      {
        path: "posts",
        labelKey: "admin.resource.post.plural",
        permission: {
          action: "list",
          targetType: "POST",
        },
        children: ({ obj }) => (
          <PostListWidget
            baseFilter={{
              group_Overlap: [obj.group.id],
              member_Overlap: [obj.id],
            }}
            createSearchParams={{ group: obj.group.id, member: obj.id }}
            hiddenFields={["group", "member"]}
          />
        ),
      },
      {
        path: "premiumMemberships",
        labelKey: "admin.common.memberships",
        permission: {
          action: "list",
          targetType: "PREMIUM_MEMBERSHIP",
        },
        children: ({ obj }) => (
          <PremiumMembershipListWidget
            baseFilter={{
              premium_Group_Overlap: [obj.group.id],
              member_Overlap: [obj.id],
            }}
            createSearchParams={{ member: obj.id }}
            hiddenFields={["premium.group", "member"]}
          />
        ),
      },
      {
        path: "application",
        labelKey: "admin.resource.memberEventV2Application.plural",
        children: ({ obj }) => (
          <MemberEventV2ApplicationListWidget
            baseFilter={{
              member_Overlap: [obj.id],
            }}
            defaultSort={[
              {
                type: "CREATED_AT",
                direction: "DESC",
                missing: "LAST",
              },
            ]}
            hideList
            hideCreate
            hideDetail
          />
        ),
      },
      {
        path: "suspended",
        labelKey: "admin.page.audit.tabSuspended",
        permission: {
          action: "list",
          targetType: "AUDIT",
        },
        children: ({ obj }) => (
          <AuditSuspendedListWidget
            baseFilter={{
              targetGroupId_Overlap: [obj.group.id],
              targetId_Overlap: [obj.id],
            }}
            hiddenFields={["targetUser", "status", "targetGroup", "target", "category"]}
          />
        ),
      },
      {
        path: "hidden",
        labelKey: "admin.page.audit.tabHidden",
        permission: {
          action: "list",
          targetType: "AUDIT",
        },
        children: ({ obj }) => (
          <AuditHiddenListWidget
            baseFilter={{
              targetGroupId_Overlap: [obj.group.id],
              targetUserId_Overlap: [obj.user.id],
            }}
            hiddenFields={["targetUser", "targetGroup", "category"]}
          />
        ),
      },
      {
        path: "inactivated",
        labelKey: "admin.page.audit.tabInactivated",
        permission: {
          action: "list",
          targetType: "AUDIT",
        },
        children: ({ obj }) => (
          <AuditInactivatedListWidget
            baseFilter={{
              targetGroupId_Overlap: [obj.group.id],
              targetUserId_Overlap: [obj.user.id],
            }}
            hiddenFields={["targetUser", "targetGroup", "category"]}
          />
        ),
      },
    ]}
    hideUpdate
  />
);

// ==

const QUERY = gql`
  query memberForMemberDetail($id: ID!) {
    member(filter: { id_Overlap: [$id] }) {
      id
      group {
        id
        name
      }
      user {
        id
        email
        phone
        lastAuthenticatedAt
        createdAt
        isActive
      }
      nickname
      profileImage {
        ...DetailImageFields
      }
      coverImage {
        ...DetailImageFields
      }
      artist {
        id
        name
      }
      premiums {
        id
        name
      }
      suspensionCategory
      suspensionReason
      suspensionPeriod
      suspensionPeriodUntil
      suspendedAt
      postCount
      commentCount
      likeCount
      bookmarkCount
      isActive
      ...TrackedFields
    }
  }
  ${DetailPage.fragments.tracked}
  ${DetailPage.fragments.image}
`;
