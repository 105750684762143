import { gql } from "@apollo/client";

import { FormPage } from "../../components/pages";
import { toCamelCase } from "../../utils/helpers";

export const CommentForm = (props) => (
  <FormPage
    resource="comment"
    fields={[
      {
        name: "group",
        type: "object",
        resource: "group",
        disabled: ({ isUpdate }) => isUpdate,
        onChange: ({ resetField }) => resetField("member"),
      },
      {
        name: "member",
        type: "object",
        resource: "member",
        disabled: ({ data, isUpdate }) => !data.group || isUpdate,
        baseFilter: ({ data }) => ({ group_Overlap: [data.group] }),
      },
      {
        name: "targetType",
        type: "choice",
        enumType: "ModelDocumentType",
        onChange: ({ resetField }) => resetField("targetId"),
        filterOptions: () => (options) =>
          options.filter((option) => ["POST", "POLL", "CLIP"].includes(option.value)),
        getter: (obj) => obj.target?.type,
      },
      {
        name: "targetId",
        type: "object",
        resource: ({ data }) =>
          data.targetType ? toCamelCase(data.targetType.toLowerCase()) : null,
        getter: (obj) => obj.target?.id,
      },
      {
        name: "parent",
        type: "object",
        resource: "comment",
        baseFilter: ({ data }) => {
          const { targetId, targetType } = data;
          if (targetId && targetType)
            return {
              targetId_Overlap: [targetId],
              targetType_Overlap: [targetType],
            };
        },
      },
      {
        name: "body",
        rows: 10,
      },
    ]}
    query={QUERY}
    createMutation={CREATE_MUTATION}
    updateMutation={UPDATE_MUTATION}
    {...props}
  />
);

// ==

const FORM_FIELDS_FRAGMENT = gql`
  fragment CommentFormFields on Comment {
    id
    group {
      id
    }
    member {
      id
    }
    body
    target {
      id
      type
    }
  }
`;

const QUERY = gql`
  query commentForCommentForm($id: ID!) {
    comment(filter: { id_Overlap: [$id] }) {
      ...CommentFormFields
    }
  }
  ${FORM_FIELDS_FRAGMENT}
`;

const CREATE_MUTATION = gql`
  mutation createCommentForCommentForm($data: CommentMutationInput!) {
    createComment(data: $data) {
      ok
      errors {
        ...MutationErrorFields
      }
      object {
        ...CommentFormFields
      }
    }
  }
  ${FormPage.fragments.mutationError}
  ${FORM_FIELDS_FRAGMENT}
`;

const UPDATE_MUTATION = gql`
  mutation updateCommentForCommentForm($data: CommentMutationInput!, $id: ID!) {
    updateComment(data: $data, id: $id, partial: true) {
      ok
      errors {
        ...MutationErrorFields
      }
      object {
        ...CommentFormFields
      }
    }
  }
  ${FormPage.fragments.mutationError}
  ${FORM_FIELDS_FRAGMENT}
`;
