import { gql } from "@apollo/client";

import { getValueFromJsonString, toCamelCase } from "../../utils/helpers";
import { ListWidget } from "../list";

export const AuditSuspendedListWidget = ({ baseFilter, ...props }) => (
  <ListWidget
    resource="audit"
    fields={[
      {
        name: "id",
      },
      {
        name: "status",
        type: "boolean",
        labelKey: "admin.page.audit.isSuspended",
        getter: (obj) => Boolean(obj.target.suspendedAt),
      },
      {
        name: "targetGroup",
        type: "object",
        resource: "group",
        filterKey: "targetGroupId_Overlap",
        getter: (obj) => obj.targetGroup,
      },
      {
        name: "targetUser",
        type: "object",
        resource: "user",
        getter: (obj) => obj.targetUser,
      },
      {
        name: "target",
        type: "object",
        resource: ({ obj }) => toCamelCase(obj.target.type.toLowerCase()),
      },
      {
        name: "email",
        type: "email",
        getter: (obj) => getValueFromJsonString("email", obj.memo),
      },
      {
        name: "phone",
        type: "phone",
        getter: (obj) => getValueFromJsonString("phone", obj.memo),
      },
      {
        name: "suspensionCategory",
        type: "choice",
        enumType: "MemberSuspensionCategory",
        getter: (obj) => getValueFromJsonString("suspensionCategory", obj.memo),
      },
      {
        name: "suspensionReason",
        type: "choice",
        enumType: "MemberSuspensionReason",
        getter: (obj) => getValueFromJsonString("suspensionReason", obj.memo),
      },
      {
        name: "suspensionMemo",
        getter: (obj) => getValueFromJsonString("suspensionMemo", obj.memo),
      },
      {
        name: "suspensionPeriod",
        type: "choice",
        enumType: "MemberSuspensionPeriod",
        getter: (obj) => getValueFromJsonString("suspensionPeriod", obj.memo),
      },
      {
        name: "suspensionPeriodUntil",
        type: "datetime",
        getter: (obj) => getValueFromJsonString("suspensionPeriodUntil", obj.memo),
      },
      {
        name: "suspendedAt",
        type: "datetime",
        getter: (obj) => getValueFromJsonString("suspendedAt", obj.memo),
      },
      {
        name: "user",
        type: "object",
        resource: "user",
      },
      { name: "createdAt" },
    ]}
    query={QUERY}
    baseFilter={{
      ...baseFilter,
      targetType_Overlap: ["MEMBER"],
      category_Overlap: ["SUSPENDED"],
    }}
    hideDetail
    {...props}
  />
);

const QUERY = gql`
  query auditsForAuditSuspendedListWidget(
    $filter: AuditFilterInput
    $sort: [AuditSortInput]
    $page: PageInput
  ) {
    audits(filter: $filter, sort: $sort, page: $page) {
      objects {
        id
        user {
          id
          email
          name
        }
        targetGroup {
          id
          name
        }
        targetUser {
          id
          name
        }
        target {
          id
          type
          ... on Member {
            id
            nickname
            suspendedAt
          }
        }
        category
        memo
        diff
        createdAt
      }
      ...ListFields
    }
  }
  ${ListWidget.fragments.list}
`;
