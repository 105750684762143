import { gql } from "@apollo/client";

import { FormPage } from "../../components/pages";

export const BoothNoticeForm = () => (
  <FormPage
    resource="boothNotice"
    fields={[
      {
        type: "fieldset",
        label: "",
        fields: [
          {
            name: "booth",
            type: "object",
            resource: "booth",
            disabled: true,
          },
        ],
      },
      {
        type: "fieldset",
        labelKey: "admin.fieldset.content",
        fields: [
          {
            name: "titleContent",
            labelKey: "admin.field.title",
            type: "translatable",
          },
          {
            name: "bodyContent",
            labelKey: "admin.field.body",
            type: "translatable",
            rows: 5,
          },
        ],
      },
      ...FormPage.fields.canActivate,
    ]}
    query={QUERY}
    createMutation={CREATE_MUTATION}
    updateMutation={UPDATE_MUTATION}
  />
);

// ==

const FORM_FIELDS_FRAGMENT = gql`
  fragment BoothNoticeFormFields on BoothNotice {
    id
    booth {
      id
      title
    }
    titleContent {
      ...TranslatableContentFields
    }
    bodyContent {
      ...TranslatableContentFields
    }
    ...CanActivateFields
  }
  ${FormPage.fragments.canActivate}
  ${FormPage.fragments.translatableContent}
`;

const QUERY = gql`
  query boothNoticeForBoothNoticeForm($id: ID!) {
    boothNotice(filter: { id_Overlap: [$id] }) {
      ...BoothNoticeFormFields
    }
  }
  ${FORM_FIELDS_FRAGMENT}
`;

const CREATE_MUTATION = gql`
  mutation createBoothNoticeForBoothNoticeForm($data: BoothNoticeMutationInput!) {
    createBoothNotice(data: $data) {
      ok
      errors {
        ...MutationErrorFields
      }
      object {
        ...BoothNoticeFormFields
      }
    }
  }
  ${FormPage.fragments.mutationError}
  ${FORM_FIELDS_FRAGMENT}
`;

const UPDATE_MUTATION = gql`
  mutation updateBoothNoticeForBoothNoticeForm(
    $data: BoothNoticeMutationInput!
    $id: ID!
  ) {
    updateBoothNotice(data: $data, id: $id, partial: true) {
      ok
      errors {
        ...MutationErrorFields
      }
      object {
        ...BoothNoticeFormFields
      }
    }
  }
  ${FormPage.fragments.mutationError}
  ${FORM_FIELDS_FRAGMENT}
`;
