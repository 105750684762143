import { gql } from "@apollo/client";

import { ListPage } from "../../components/pages";

export const RoleList = () => (
  <ListPage
    resource="role"
    subtitle="Manage staff roles in community."
    fields={[
      {
        name: "id",
      },
      {
        name: "staff",
        type: "object",
        resource: "staff",
        filterKey: "staff_Overlap",
      },
      {
        name: "staff.user.email",
        labelKey: "admin.field.email",
        type: "email",
      },
      {
        name: "category",
        labelKey: "admin.common.permission",
        type: "choice",
        enumType: "RoleCategory",
        filterKey: "category_Text",
        sortKey: "CATEGORY",
      },
      {
        name: "group",
        type: "object",
        resource: "group",
        filterKey: "group_Overlap",
      },
      {
        name: "company",
        type: "object",
        resource: "company",
        filterKey: "company_Overlap",
      },
      ...ListPage.fields.tracked,
    ]}
    query={QUERY}
    extraRowActions={ListPage.extraRowActions.delete}
  />
);

// ==

const QUERY = gql`
  query rolesForRoleList(
    $filter: RoleFilterInput
    $sort: [RoleSortInput]
    $page: PageInput
  ) {
    roles(filter: $filter, sort: $sort, page: $page) {
      objects {
        id
        staff {
          id
          user {
            id
            email
            name
          }
        }
        category
        group {
          id
          name
        }
        company {
          id
          name
        }
        ...TrackedFields
      }
      ...ListFields
    }
  }
  ${ListPage.fragments.list}
  ${ListPage.fragments.tracked}
`;
