import { gql } from "@apollo/client";

import { ListPage, ListPageWithGroup } from "../../components/pages";

export const EventList = () => (
  <ListPageWithGroup
    hideCreate
    groupFilterKey="group_Overlap"
    resource="event"
    subtitle="Manage events in community."
    fields={[
      {
        name: "id",
      },
      {
        name: "group",
        type: "object",
        resource: "group",
        filterKey: "group_Overlap",
      },
      {
        name: "badge",
        labelKey: "admin.field.badgeStatus",
        type: "choice",
        enumType: "EventBadge",
      },
      {
        name: "isHighlighted",
        type: "boolean",
        filterKey: "isHighlighted_Exact",
      },
      {
        name: "title",
      },
      {
        name: "eventLocationCategory",
        labelKey: "admin.field.category",
        type: "choice",
        resource: "eventLocationCategory",
        enumType: "EventLocationCategory",
        filterKey: "eventLocationCategory_Overlap",
        sortKey: "LOCATION_CATEGORY",
      },
      { name: "eventAt", type: "datetime", labelKey: "admin.field.eventAt" },
      {
        name: "submitCategory",
        type: "choice",
        enumType: "EventSubmitCategory",
        labelKey: "admin.field.submitCategory",
      },
      {
        name: "announceCategory",
        type: "choice",
        enumType: "EventAnnounceCategory",
        labelKey: "admin.field.announceCategory",
      },
      {
        name: "applyStartedAt",
        type: "datetime",
        labelKey: "admin.field.applyStartedAt",
      },
      { name: "applyEndedAt", type: "datetime", labelKey: "admin.field.applyEndedAt" },
      { name: "announcedAt", type: "datetime", labelKey: "admin.field.announcedAt" },
      {
        type: "choice",
        name: "status",
        labelKey: "admin.field.status",
        enumType: "EventStatus",
      },
      ...ListPage.fields.tracked,
    ]}
    query={QUERY}
    extraRowActions={ListPage.extraRowActions.canActivate}
  />
);

// ==

const QUERY = gql`
  query eventsForEventList(
    $filter: EventFilterInput
    $sort: [EventSortInput]
    $page: PageInput
  ) {
    events(filter: $filter, sort: $sort, page: $page) {
      objects {
        id
        group {
          id
          name
        }
        badge
        title
        noticeTitle
        eventAt
        tags {
          id
          name
        }
        announceCategory
        announcedAt
        applyEndedAt
        applyStartedAt
        eventAt
        eventLocationCategory
        id
        status
        isActive
        isHighlighted
        submitCategory
        ...TrackedFields
        ...CanActivateFields
      }
      ...ListFields
    }
  }
  ${ListPage.fragments.list}
  ${ListPage.fragments.tracked}
  ${ListPage.fragments.canActivate}
`;
