import { useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";

import { BarsOutlined, LeftOutlined, RightOutlined } from "@ant-design/icons";
import { Layout as AntdLayout, Button, Drawer, Grid, Menu, theme } from "antd";

import { useApp } from "../../hooks/app";
import { useLayout } from "../../hooks/layout";
import { useAbilities } from "../../hooks/permission";
import { deepEqual } from "../../utils/helpers";
import { Title } from "./Title";

const { useToken } = theme;

export const Sider = ({ fixed = false }) => {
  const [openKeys, setOpenKeys] = useState([]);
  const location = useLocation();
  const breakpoint = Grid.useBreakpoint();
  const { token } = useToken();
  const { siderCollapsed, setSiderCollapsed, mobileSiderOpen, setMobileSiderOpen } =
    useLayout();
  const { menus } = useApp();

  const abilityKeys = useMemo(() => buildAbilityKeys(menus), [menus]);
  const { abilities } = useAbilities(abilityKeys);
  const items = useMemo(
    () => (abilities ? filterMenus(menus, abilityKeys, abilities) : null),
    [menus, abilityKeys, abilities],
  );
  const [selectedKey, defaultOpenKeys] = useMemo(
    () => matchMenus(items, location),
    [items, location],
  );

  useEffect(
    () => setOpenKeys((prev) => [...new Set([...prev, ...defaultOpenKeys])]),
    [defaultOpenKeys],
  );

  if (!items) {
    return null;
  }

  // ==

  const isMobile = typeof breakpoint.lg === "undefined" ? false : !breakpoint.lg;

  const renderMenu = () => (
    <Menu
      mode="inline"
      items={items}
      selectedKeys={selectedKey}
      openKeys={openKeys}
      onOpenChange={(keys) => setOpenKeys(keys)}
      onClick={() => setMobileSiderOpen(false)}
      style={styles.menu}
    />
  );

  return isMobile ? (
    <>
      <Drawer
        open={mobileSiderOpen}
        onClose={() => setMobileSiderOpen(false)}
        placement="left"
        closable={false}
        width={200}
        styles={{
          body: { padding: 0 },
        }}
        maskClosable={true}
      >
        <AntdLayout>
          <AntdLayout.Sider
            style={{
              height: "100vh",
              backgroundColor: token.colorBgContainer,
              borderRight: `1px solid ${token.colorBgElevated}`,
            }}
          >
            <div
              style={{
                ...styles.mobileTitle,
                backgroundColor: token.colorBgElevated,
              }}
            >
              <Title collapsed={false} />
            </div>
            {renderMenu()}
          </AntdLayout.Sider>
        </AntdLayout>
      </Drawer>
      <Button
        style={styles.drawerButton}
        size="large"
        onClick={() => setMobileSiderOpen(true)}
        icon={<BarsOutlined />}
      ></Button>
    </>
  ) : (
    <>
      {fixed && (
        <div
          style={{
            width: siderCollapsed ? "80px" : "200px",
            transition: "all 0.2s",
          }}
        />
      )}

      <AntdLayout.Sider
        style={{
          backgroundColor: token.colorBgContainer,
          borderRight: `1px solid ${token.colorBgElevated}`,
          ...(fixed && {
            position: "fixed",
            top: 0,
            height: "100vh",
            zIndex: 1000,
          }),
        }}
        collapsible
        collapsed={siderCollapsed}
        onCollapse={(collapsed, type) => {
          if (type === "clickTrigger") {
            setSiderCollapsed(collapsed);
          }
        }}
        collapsedWidth={80}
        breakpoint="lg"
        trigger={
          <Button
            type="text"
            style={{
              ...styles.triggerButton,
              backgroundColor: token.colorBgElevated,
            }}
          >
            {siderCollapsed ? (
              <RightOutlined
                style={{
                  color: token.colorPrimary,
                }}
              />
            ) : (
              <LeftOutlined
                style={{
                  color: token.colorPrimary,
                }}
              />
            )}
          </Button>
        }
      >
        <div
          style={{
            ...styles.title,
            width: siderCollapsed ? "80px" : "200px",
            padding: siderCollapsed ? "0" : "0 24px",
            justifyContent: siderCollapsed ? "center" : "flex-start",
            lineHeight: 0,
            backgroundColor: token.colorBgElevated,
          }}
        >
          <Title collapsed={siderCollapsed} />
        </div>

        {renderMenu()}
      </AntdLayout.Sider>
    </>
  );
};

// ==

const buildAbilityKeys = (items) => {
  const abilityKeys = [];

  items?.forEach((item) => {
    if (item.permission) {
      abilityKeys.push(item.permission);
    }
    abilityKeys.push(...buildAbilityKeys(item.children));
  });

  return abilityKeys;
};

const filterMenus = (items, abilityKeys, abilities) => {
  const filteredItems = [];

  items?.forEach((item) => {
    if (item.permission) {
      const index = abilityKeys.findIndex((key) => deepEqual(key, item.permission));
      if (!abilities[index]) {
        return;
      }
    }
    const filteredChildren = filterMenus(item.children, abilityKeys, abilities);
    const filteredItem = {
      ...item,
      children: filteredChildren.length > 0 ? filteredChildren : undefined,
    };
    if (filteredItem.url || filteredChildren.length > 0) {
      filteredItems.push(filteredItem);
    }
  });

  return filteredItems;
};

const matchMenus = (items, location) => {
  const { pathname } = location;

  let selectedKey = null;
  let selectedKeyScore = null;
  let defaultOpenKeys = [];

  const match = (item, keys) => {
    // Special handling for list page
    const url = item.url?.replace(/\/list$/, "");
    if (pathname.startsWith(url)) {
      if (!selectedKeyScore || item.url.length > selectedKeyScore) {
        selectedKey = item.key;
        selectedKeyScore = url.length;
        defaultOpenKeys = keys;
      }
    }

    item.children?.forEach((child) => match(child, [...keys, item.key]));
  };

  items?.forEach((item) => match(item, []));

  return [selectedKey, defaultOpenKeys];
};

// ==

const styles = {
  triggerButton: {
    borderRadius: 0,
    height: "100%",
    width: "100%",
  },
  drawerButton: {
    border: 0,
    position: "fixed",
    boxShadow: "none",
    height: "44px",
    backgroundColor: "transparent",
    zIndex: 1000,
  },
  title: {
    display: "flex",
    alignItems: "center",
    height: "44px",
    fontSize: "14px",
    lineHeight: 0,
  },
  mobileTitle: {
    width: "200px",
    padding: "0 24px",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    height: "44px",
    lineHeight: 0,
  },
  menu: {
    border: "none",
    overflow: "auto",
    lineHeight: 0,
    height: "calc(100% - 48px)",
  },
};
