import { gql } from "@apollo/client";

import { FormPage } from "../../components/pages";

export const NoticeForm = () => (
  <FormPage
    resource="notice"
    fields={[
      {
        type: "fieldset",
        label: "",
        fields: [
          {
            name: "group",
            type: "object",
            resource: "group",
            disabled: ({ isUpdate }) => isUpdate,
          },
          {
            name: "isHighlighted",
            type: "boolean",
          },
          {
            name: "isAd",
            type: "boolean",
          },
          {
            name: "isHiddenFromArtist",
            type: "boolean",
          },
        ],
      },
      {
        type: "fieldset",
        labelKey: "admin.fieldset.content",
        fields: [
          {
            name: "titleContent",
            labelKey: "admin.field.title",
            type: "translatable",
          },
          {
            name: "bodyContent",
            labelKey: "admin.field.body",
            type: "translatable",
            markdown: true,
            rows: 50,
          },
        ],
      },
      ...FormPage.fields.canActivateFirstActivateAt,
    ]}
    query={QUERY}
    createMutation={CREATE_MUTATION}
    updateMutation={UPDATE_MUTATION}
  />
);

// ==

const FORM_FIELDS_FRAGMENT = gql`
  fragment NoticeFormFields on Notice {
    id
    group {
      id
    }
    title
    titleContent {
      ...TranslatableContentFields
    }
    body
    bodyContent {
      ...TranslatableContentFields
    }
    isHighlighted
    isHiddenFromArtist
    isAd
    ...CanActivateFields
  }
  ${FormPage.fragments.canActivate}
  ${FormPage.fragments.translatableContent}
`;

const QUERY = gql`
  query noticeForNoticeForm($id: ID!) {
    notice(filter: { id_Overlap: [$id] }) {
      ...NoticeFormFields
    }
  }
  ${FORM_FIELDS_FRAGMENT}
`;

const CREATE_MUTATION = gql`
  mutation createNoticeForNoticeForm($data: NoticeMutationInput!) {
    createNotice(data: $data) {
      ok
      errors {
        ...MutationErrorFields
      }
      object {
        ...NoticeFormFields
      }
    }
  }
  ${FormPage.fragments.mutationError}
  ${FORM_FIELDS_FRAGMENT}
`;

const UPDATE_MUTATION = gql`
  mutation updateNoticeForNoticeForm($data: NoticeMutationInput!, $id: ID!) {
    updateNotice(data: $data, id: $id, partial: true) {
      ok
      errors {
        ...MutationErrorFields
      }
      object {
        ...NoticeFormFields
      }
    }
  }
  ${FormPage.fragments.mutationError}
  ${FORM_FIELDS_FRAGMENT}
`;
