import { gql } from "@apollo/client";

import { ListPage } from "../../components/pages";

export const PollVoteList = () => (
  <ListPage
    resource="pollVote"
    fields={[
      {
        name: "id",
      },
      {
        name: "option.poll",
        type: "object",
        labelKey: "admin.resource.poll.singular",
        resource: "poll",
        filterKey: "option_Poll_Overlap",
      },
      {
        name: "option",
        type: "object",
        resource: "pollOption",
        filterKey: "option_Overlap",
      },
      {
        name: "member",
        type: "object",
        resource: "member",
        filterKey: "member_Overlap",
      },
      ...ListPage.fields.timestamped,
    ]}
    query={QUERY}
    extraRowActions={ListPage.extraRowActions.delete}
  />
);

// ==

const QUERY = gql`
  query pollVotesForPollVoteList(
    $filter: PollVoteFilterInput
    $sort: [PollVoteSortInput]
    $page: PageInput
  ) {
    pollVotes(filter: $filter, sort: $sort, page: $page) {
      objects {
        id
        option {
          id
          poll {
            id
            title
          }
        }
        member {
          id
          nickname
        }
        ...TimestampedFields
      }
      ...ListFields
    }
  }
  ${ListPage.fragments.list}
  ${ListPage.fragments.timestamped}
`;
