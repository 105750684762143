import { gql } from "@apollo/client";

import { DuplicateGroupThemeButton } from "../../components/actions";
import { DetailPage } from "../../components/pages";

export const GroupThemeDetail = () => (
  <DetailPage
    resource="groupTheme"
    fields={[
      {
        name: "id",
      },
      {
        name: "group",
        type: "object",
        resource: "group",
      },
      {
        name: "name",
      },
      {
        name: "primaryColorLight",
        type: "color",
      },
      {
        name: "primaryColorDark",
        type: "color",
      },
      {
        name: "buttonTextColorModeLight",
        type: "choice",
        enumType: "GroupThemeButtonTextColorMode",
      },
      {
        name: "buttonTextColorModeDark",
        type: "choice",
        enumType: "GroupThemeButtonTextColorMode",
      },
      {
        name: "coverImage",
        type: "imageMeta",
      },
      {
        name: "coverThumbhash",
      },
      {
        name: "coverRatio",
        type: "choice",
        enumType: "GroupThemeCoverRatio",
      },
      {
        name: "logoImage",
        type: "imageMeta",
      },
      {
        name: "logoPosition",
        type: "choice",
        enumType: "GroupThemeLogoPosition",
      },
      {
        name: "previewMedias",
        type: "genericMeta",
      },
      {
        name: "status",
        type: "choice",
        enumType: "GroupThemeStatus",
        filterKey: "status_Overlap",
      },
      ...DetailPage.fields.canActivate,
      {
        name: "activateAt",
        type: "datetime",
      },
      ...DetailPage.fields.tracked,
    ]}
    query={QUERY}
    extraActions={(props) => (
      <>
        {DetailPage.extraActions.canActivate(props)}
        <DuplicateGroupThemeButton groupThemeId={props.obj.id} />
      </>
    )}
  />
);

// ==

const QUERY = gql`
  query groupThemeForGroupThemeDetail($id: ID!) {
    groupTheme(filter: { id_Overlap: [$id] }) {
      id
      group {
        id
        name
      }
      name
      primaryColorLight
      primaryColorDark
      buttonTextColorModeLight
      buttonTextColorModeDark
      coverImage {
        ...DetailImageFields
      }
      coverThumbhash
      coverRatio
      logoImage {
        ...DetailImageFields
      }
      logoPosition
      previewMedias {
        ...DetailMediaFields
      }
      status
      ...TrackedFields
      ...CanActivateFields
    }
  }
  ${DetailPage.fragments.tracked}
  ${DetailPage.fragments.canActivate}
  ${DetailPage.fragments.image}
  ${DetailPage.fragments.media}
`;
