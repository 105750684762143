import type { SortOrder as AntdSortOrder } from "antd/es/table/interface";

import type { SortDirection } from "@/types/list";
import { invert } from "@/utils/helpers";

const AntdDirection = {
  ASC: "ascend",
  DESC: "descend",
} as const;
const InvertedAntdDirection = invert(AntdDirection);

export const toAntdDirection = (direction: SortDirection) => AntdDirection[direction];

export const fromAntdDirection = (direction?: AntdSortOrder) => {
  if (!direction) return "DESC";
  return InvertedAntdDirection[direction];
};
