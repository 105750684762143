import { gql } from "@apollo/client";

import { DuplicateGroupThemeButton } from "../../components/actions";
import { ListWidget } from "../list";

export const GroupThemeListWidget = (props) => (
  <ListWidget
    resource="groupTheme"
    fields={[
      {
        name: "id",
      },
      {
        name: "group",
        type: "object",
        resource: "group",
        filterKey: "group_Overlap",
      },
      {
        name: "name",
        filterKey: "name_Text",
      },
      {
        name: "primaryColorLight",
        type: "color",
      },
      {
        name: "primaryColorDark",
        type: "color",
      },
      {
        name: "buttonTextColorModeLight",
        type: "color",
      },
      {
        name: "buttonTextColorModeDark",
        type: "color",
      },
      {
        name: "coverRatio",
        type: "choice",
        enumType: "GroupThemeCoverRatio",
        filterKey: "coverRatio_Overlap",
      },
      {
        name: "logoPosition",
        type: "choice",
        enumType: "GroupThemeLogoPosition",
        filterKey: "logoPosition_Overlap",
      },
      {
        name: "status",
        type: "choice",
        enumType: "GroupThemeStatus",
        filterKey: "status_Overlap",
      },
      {
        name: "activateAt",
        type: "datetime",
      },
      ...ListWidget.fields.tracked,
    ]}
    query={QUERY}
    extraRowActions={(props) => (
      <>
        <DuplicateGroupThemeButton groupThemeId={props.obj.id} size="small" onlyIcon />
        {ListWidget.extraRowActions.canActivate(props)}
      </>
    )}
    {...props}
  />
);

// ==

const QUERY = gql`
  query groupThemesForGroupThemeListWidget(
    $filter: GroupThemeFilterInput
    $sort: [GroupThemeSortInput]
    $page: PageInput
  ) {
    groupThemes(filter: $filter, sort: $sort, page: $page) {
      objects {
        id
        group {
          id
          name
        }
        name
        primaryColorLight
        primaryColorDark
        buttonTextColorModeLight
        buttonTextColorModeDark
        coverRatio
        logoPosition
        status
        ...TrackedFields
        ...CanActivateFields
      }
      ...ListFields
    }
  }
  ${ListWidget.fragments.list}
  ${ListWidget.fragments.tracked}
  ${ListWidget.fragments.canActivate}
`;
