import { useState } from "react";

import { gql, useMutation } from "@apollo/client";

import { Button, Drawer, Space } from "antd";
import TextArea from "antd/es/input/TextArea";

import { t } from "i18next";

import { useApp } from "../../hooks/app";

export const EventPenaltyButton = ({
  eventId,
  attendanceIds,
  setSelectedRowKeys,
  refetch,
}) => {
  const { message } = useApp();
  const [open, setOpen] = useState(false);
  const [memo, setMemo] = useState("");
  const [penalty, { loading, error }] = useMutation(PENALTY_MUTATION);

  const showDrawer = () => {
    setOpen(true);
  };

  const handleOk = async () => {
    const data = await penalty({
      variables: {
        eventId: eventId,
        attendanceIds: attendanceIds,
        memo: memo,
      },
    });

    const parsed = data?.data?.bulkCreateEventPenalty;
    if (parsed) {
      if (parsed?.ok) {
        refetch();
      } else {
        const errMsg = parsed?.errors[0].messages.join("\n");
        message.error(errMsg);
      }
    }

    setSelectedRowKeys([]);
    message.success(t("admin.message.processSuccess"));
    setMemo("");
    setOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const mutErros = [error];

  mutErros.forEach((err) => {
    if (err) {
      console.error(err);
      message.error(JSON.stringify(err));
    }
  });

  return (
    <>
      <Button
        type="primary"
        danger
        style={styles.customActionButtons}
        onClick={showDrawer}
        loading={loading}
        disabled={attendanceIds.length === 0}
      >
        {t("admin.common.penalty")}
      </Button>
      <Drawer
        title={t("admin.menu.eventPenalty")}
        extra={
          <Space>
            <Button type="primary" onClick={() => handleOk()}>
              {t("admin.common.ok")}
            </Button>
          </Space>
        }
        open={open}
        onClose={handleClose}
      >
        <TextArea rows={4} value={memo} onChange={(e) => setMemo(e.target.value)} />
      </Drawer>
    </>
  );
};

// ==

const PENALTY_MUTATION = gql`
  mutation BulkUpsertEventPenalty(
    $attendanceIds: [Int!]!
    $eventId: ID!
    $memo: String
  ) {
    bulkUpsertEventPenalty(
      attendanceIds: $attendanceIds
      eventId: $eventId
      memo: $memo
    ) {
      ok
      errors {
        code
        title
        messages
      }
    }
  }
`;

const styles = {
  customActionButtons: {
    marginRight: "10px",
  },
};
