import { forwardRef } from "react";
import type { ComponentPropsWithoutRef, ForwardedRef, ReactNode } from "react";

import type { DocumentNode, TypedDocumentNode } from "@apollo/client";

import { Button } from "antd";

import type { Prettify, StrictOmit } from "ts-essentials";

import type { FormRef } from "@/components/form";
import type { GqlMutationData } from "@/gql/types";
import type { FormRequestLike, FormResponseLike } from "@/types/form";

import { MutationForm, type MutationFormProps } from "./MutationForm";

export type MutationDrawerFormProps<
  Mutation extends DocumentNode,
  FormValue extends GqlMutationData<Mutation>,
> = Prettify<
  StrictOmit<
    Extract<MutationFormProps<Mutation, FormValue>, { mode: "drawer" }>,
    "mode"
  > & {
    /** @deprecated use `submitButtonOptions` instead */
    okButtonText?: string;
    /** @deprecated use `submitButtonOptions` instead */
    okButtonLoadingText?: string;
    /** @deprecated use `submitButtonOptions` instead */
    okButtonProps?: ComponentPropsWithoutRef<typeof Button>;
  }
>;

/**
 * A helper component of MutationForm with mode="drawer" as default
 */
const MutationDrawerForm = forwardRef(
  <
    Mutation extends TypedDocumentNode<FormResponseLike, FormRequestLike>,
    FormValue extends GqlMutationData<Mutation>,
  >(
    {
      okButtonText = undefined,
      okButtonLoadingText = undefined,
      okButtonProps = {},
      ...props
    }: MutationDrawerFormProps<Mutation, FormValue>,
    ref: ForwardedRef<FormRef>,
  ) => {
    return (
      <MutationForm
        mode="drawer"
        {...props}
        submitButtonOptions={{
          labelText: okButtonText,
          loadingText: okButtonLoadingText,
          props: okButtonProps,
          ...props.submitButtonOptions,
        }}
        ref={ref}
      />
    );
  },
);

// == for using generic in forwardRef components
const _MutationDrawerForm = MutationDrawerForm as <
  Mutation extends TypedDocumentNode<FormResponseLike, FormRequestLike>,
  FormValue extends GqlMutationData<Mutation>,
>(
  props: MutationDrawerFormProps<Mutation, FormValue> & { ref?: ForwardedRef<FormRef> },
) => ReactNode;

export { _MutationDrawerForm as MutationDrawerForm };
