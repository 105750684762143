import { forwardRef } from "react";

import { gql } from "@apollo/client";

import { Typography } from "antd";

import { ObjectSelect } from "../fields";

export const SelectPollOption = forwardRef((props, ref) => (
  <ObjectSelect
    resource="pollOption"
    query={QUERY}
    optionRender={(pollOption) => (
      <>
        #{pollOption.order} {pollOption.body}
        <Typography.Text type="secondary" style={styles.secondary}>
          {pollOption.poll.title}
        </Typography.Text>
      </>
    )}
    ref={ref}
    {...props}
  />
));

// ==

const QUERY = gql`
  query pollOptionsForSelectPollOption($filter: PollOptionFilterInput) {
    pollOptions(filter: $filter, sort: [{ type: RELEVANCE, direction: DESC }]) {
      objects {
        id
        body
        order
        poll {
          id
          title
        }
      }
    }
  }
`;

// ==

const styles = {
  secondary: {
    marginLeft: 8,
    fontSize: "0.8em",
  },
};
