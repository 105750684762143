import { useFormContext as $useFormContext } from "react-hook-form";

import { BuiltField } from "@/types/field";
import { FormContext } from "@/types/form";

/*
  TODO: refactor useFormContext
  Currently, instead of using useFormContext from react-hook-form as recommended,
  we have been defining the context type in a custom way, as shown above.
  `passiveProps` are used in the field’s resolver, while activeProps are used in after hooks like `onChange`.
  Until now, we’ve been defining these properties individually for each form implementation.
  The plan is to refactor by separating these custom definitions from the form context.
*/
export const useFormContext = $useFormContext as unknown as () => FormContext;

export const useFieldContext = (field: BuiltField, parent: string[]) => {
  const {
    data,
    errors,
    setValue,
    setError,
    clearErrors,
    resetField,
    resetValue,
    reset,
    isUpdate,
  } = useFormContext();
  const passiveProps = {
    field,
    parent,
    data,
    errors,
    isUpdate,
  } as const;
  const activeProps = {
    ...passiveProps,
    setValue,
    setError,
    clearErrors,
    resetField,
    resetValue,
    reset,
  } as const;

  return {
    activeProps,
    passiveProps,
  } as const;
};
