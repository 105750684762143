import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams, useSearchParams } from "react-router-dom";

import { useMutation, useQuery } from "@apollo/client";
import { gql } from "@apollo/client";

import { Button, Card, Drawer, Space } from "antd";

import { MediaUpload } from "../../components/fields";
import { FormPage } from "../../components/pages";
import { Can } from "../../components/permission";
import { useApp } from "../../hooks/app";
import { useAbilities } from "../../hooks/permission";
import { pick } from "../../utils/helpers";
import { TranslationRequestTargetContentMutationForm } from "./";

export const TranslationRequestTargetContentForm = (props) => {
  const { targetContent } = props;
  const isFileInputSource = targetContent.translationRequest.inputSource === "FILE";
  const [update] = useMutation(UPDATE_STATUS_MUTATION);
  const { t } = useTranslation();
  const { message } = useApp();
  const params = useParams();
  const [searchParams] = useSearchParams();
  const fromWhere = searchParams.get("from");
  const { abilities } = useAbilities(permissions);
  const [isAdminStaff] = abilities ?? [false];
  const { refetch } = useQuery(QUERY, {
    variables: { id: params.id },
    notifyOnNetworkStatusChange: true,
  });
  const allowedDataMutationStatuses = ["ASSIGNED", "TRANSLATING", "REJECTED"];
  const updateStatus = (status) => {
    update({
      variables: {
        id: targetContent.id,
        data: {
          progressStatus: status,
        },
      },
      onCompleted: (response) => {
        const { ok, errors } = Object.values(response)[0];
        if (ok) {
          refetch()
            .then(() => message.success(t("admin.message.processSuccess")))
            .catch(() => message.error(t("admin.message.processFailure")));
        } else {
          message.error(
            t("admin.message.processFailure", {
              message: errors[0].messages[0],
            }),
          );
        }
      },
    });
  };
  const [disabled, setDisabled] = useState(true);
  const fields = [
    {
      name: "translationRequest",
      hidden: true,
      forbidden: true,
    },
    {
      name: "plainText",
      type: "text",
      rows: 10,
      disabled: disabled,
      hidden: ({ data }) => {
        return (
          data.translationRequest.inputSource === "FILE" ||
          data.translationRequest.inputSource === undefined
        );
      },
    },
    {
      name: "uploadFiles",
      type: "fileMeta",
      disabled: !isFileInputSource,
      hidden: ({ data }) =>
        data.translationRequest.inputSource === "TEXT" ||
        data.translationRequest.inputSource === undefined,
      normalizer: (value) => {
        if (Array.isArray(value) && value.length > 0) {
          return {
            uploadFiles: value.map((file) => pick(file, ["key", "mime", "size"])),
          };
        } else if (value && Object.keys(value).length > 0) {
          return { uploadFiles: [pick(value, ["key", "mime", "size"])] };
        } else {
          return [];
        }
      },
      render: ({ field, controllerField, setError, clearErrors }) => (
        <MediaUpload
          onUploadBegin={() => setError(field.name, { type: "custom" })}
          onUploadEnd={() => clearErrors(field.name)}
          multiple={field.multiple}
          sync={field.sync}
          accept={".txt,.docx,.doc"}
          {...controllerField}
          disabled={
            isAdminStaff ||
            !["ASSIGNED", "TRANSLATING"].includes(targetContent.progressStatus)
          }
        />
      ),
    },
    {
      name: "progressStatus",
      hidden: true,
      forbidden: true,
    },
  ];
  const changeFormDisableState = (state) => {
    if (!isFileInputSource) {
      setDisabled(state);
    }
    refetch();
  };

  return (
    <Card>
      <TranslationRequestTargetContentMutationForm
        resource="translationRequestTargetContent"
        fields={fields}
        query={QUERY}
        updateMutation={UPDATE_DATA_MUTATION}
        disableSubmit={
          !allowedDataMutationStatuses.includes(targetContent.progressStatus) ||
          isAdminStaff ||
          (isFileInputSource ? fields[2].disabled : fields[1].disabled)
        }
        onAfterSuccess={() => changeFormDisableState(true)}
        colSpan={24}
      />
      <Space
        style={{
          marginTop: 16,
          display: "flex",
          justifyContent: "flex-start",
          flexWrap: "wrap",
        }}
        size="middle"
      >
        {!isAdminStaff && fromWhere !== "request" && (
          <Can action="update" targetType="TRANSLATION_REQUEST_TARGET_CONTENT">
            {targetContent.translationRequest.inputSource !== "FILE" && (
              <Button
                type="primary"
                disabled={
                  !fields[1].disabled ||
                  !["ASSIGNED", "TRANSLATING", "REJECTED"].includes(
                    targetContent.progressStatus,
                  )
                }
                onClick={() => changeFormDisableState(false)}
              >
                {t("admin.component.updateButton.title")}
              </Button>
            )}
            <Button
              type="primary"
              disabled={targetContent.progressStatus !== "OPENED"}
              onClick={() => updateStatus("ASSIGNED")}
            >
              {t("admin.component.assignToMeButton.title")}
            </Button>
            <Button
              type="primary"
              disabled={targetContent.progressStatus !== "TRANSLATING"}
              onClick={() => updateStatus("UNDER_REVIEW")}
            >
              {t("admin.component.requestReviewButton.title")}
            </Button>
            <Can action="review" targetType="TRANSLATION_REQUEST_TARGET_CONTENT">
              <Button
                type="primary"
                disabled={targetContent.progressStatus !== "UNDER_REVIEW"}
                onClick={() => updateStatus("REVIEWED")}
              >
                {t("admin.component.reviewedButton.title")}
              </Button>
            </Can>
          </Can>
        )}
      </Space>
      <Drawer></Drawer>
    </Card>
  );
};

// ==

const permissions = [
  {
    action: "update",
    targetType: "TRANSLATION_REQUEST",
  },
];

// ==

const FORM_FIELDS_FRAGMENT = gql`
  fragment TranslationRequestTargetContentFormFields on TranslationRequestTargetContent {
    translationRequest {
      inputSource
    }
    assignee {
      id
    }
    requestStatus
    progressStatus
    plainText
    uploadFiles {
      key
      mime
      size
      url
      downloadUrl
    }
  }
`;

const QUERY = gql`
  query translationRequestTargetContentForm($id: ID!) {
    translationRequestTargetContent(filter: { id_Overlap: [$id] }) {
      ...TranslationRequestTargetContentFormFields
    }
  }
  ${FORM_FIELDS_FRAGMENT}
`;

const UPDATE_DATA_MUTATION = gql`
  mutation updateTranslationRequestTargetContentDataForm(
    $data: TranslationRequestTargetContentDataMutationInput!
    $id: ID!
  ) {
    updateTranslationRequestTargetContentData(data: $data, id: $id, partial: true) {
      ok
      errors {
        ...MutationErrorFields
      }
      object {
        ...TranslationRequestTargetContentFormFields
      }
    }
  }
  ${FormPage.fragments.mutationError}
  ${FORM_FIELDS_FRAGMENT}
`;

const UPDATE_STATUS_MUTATION = gql`
  mutation updateTranslationRequestTargetContentStatusForm(
    $data: TranslationRequestTargetContentStatusMutationInput!
    $id: ID!
  ) {
    updateTranslationRequestTargetContentStatus(data: $data, id: $id, partial: true) {
      ok
      errors {
        ...MutationErrorFields
      }
      object {
        ...TranslationRequestTargetContentFormFields
      }
    }
  }
  ${FormPage.fragments.mutationError}
  ${FORM_FIELDS_FRAGMENT}
`;
