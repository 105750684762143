import { gql } from "@apollo/client";

import { DetailPage } from "../../components/pages";

export const EventPenaltyDetail = () => (
  <DetailPage
    resource="eventPenalty"
    fields={[
      {
        name: "id",
      },
      {
        name: "event",
        type: "object",
        resource: "event",
        filterKey: "event_Overlap",
      },
      {
        name: "member",
        type: "object",
        resource: "member",
        filterKey: "member_Overlap",
      },
      {
        type: "choice",
        name: "member.status",
        labelKey: "admin.field.memberStatus",
        enumType: "MemberStatus",
      },
      {
        name: "memo",
        type: "string",
        labelKey: "admin.field.memo",
      },
      ...DetailPage.fields.canActivate,
      ...DetailPage.fields.tracked,
    ]}
    query={QUERY}
    extraActions={DetailPage.extraActions.canActivate}
  />
);

// ==

const QUERY = gql`
  query eventPenaltyForEventPenaltyDetail($id: ID!) {
    eventPenalty(filter: { id_Overlap: [$id] }) {
      id
      event {
        id
        title
        group {
          id
          name
        }
      }
      member {
        id
        nickname
        user {
          id
          name
        }
        status
      }
      memo
      ...TrackedFields
      ...CanActivateFields
    }
  }
  ${DetailPage.fragments.tracked}
  ${DetailPage.fragments.canActivate}
`;
