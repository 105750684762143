import { useState } from "react";
import { useTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroll-component";

import { gql, useQuery } from "@apollo/client";

import { SearchOutlined } from "@ant-design/icons";
import { Button, Checkbox, Drawer, Radio, Space, Typography } from "antd";

import config from "../../config";
import { useDrawerState } from "../../hooks/drawer";
import { LIST_FRAGMENT } from "../../utils/gql/fragments";
import { isEmpty } from "../../utils/helpers";
import { DebouncedInput } from "../fields";

const { Text } = Typography;

export const SelectPlaylistAction = ({
  onSelect,
  baseFilter = undefined,
  multiple = true,
  disabled = false,
  disabledValues = undefined,
  okButtonText = undefined,
  okButtonProps = {},
  drawerWidth = config.defaultDrawerWidth,
  children,
  ...props
}) => {
  const { t } = useTranslation();
  const { isOpen, openDrawer, closeDrawer, drawerProps } = useDrawerState();
  const [selected, setSelected] = useState([]);
  const [text, setText] = useState("");

  const { data, fetchMore } = useQuery(QUERY, {
    skip: !isOpen,
    variables: {
      filter: {
        ...baseFilter,
        auto: !isEmpty(text) ? text : undefined,
      },
      sort: [
        {
          type: "CREATED_AT",
          direction: "DESC",
        },
      ],
    },
    fetchPolicy: "network-only",
  });

  // ==

  const { objects: playlists, page } = data?.playlists || {};

  const getDrawerBody = () => {
    const elements = document.querySelectorAll(".ant-drawer-body");
    return elements[elements.length - 1];
  };
  const reset = () => {
    setSelected([]);
    setText("");
  };
  const loadMore = () =>
    fetchMore({
      variables: { page: { after: page.endCursor } },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        return {
          playlists: {
            ...fetchMoreResult.playlists,
            objects: [...prev.playlists.objects, ...fetchMoreResult.playlists.objects],
          },
        };
      },
    });
  const onCheck = (id) =>
    setSelected(multiple ? (selected) => [...selected, id] : [id]);
  const onUncheck = (id) => setSelected((selected) => selected.filter((i) => i !== id));
  const onSubmit = () => {
    onSelect?.(selected);
    reset();
    closeDrawer();
  };
  const onClose = () => {
    reset();
    closeDrawer();
  };

  return (
    <>
      {children?.({ openDrawer, disabled })}
      <Drawer
        title="Select Playlist"
        extra={
          <Space>
            <Button
              type="primary"
              htmlType="submit"
              disabled={selected.length === 0}
              onClick={onSubmit}
              {...okButtonProps}
            >
              {okButtonText ?? t(`admin.common.select`)}
            </Button>
          </Space>
        }
        width={drawerWidth}
        {...drawerProps}
        {...props}
        onClose={onClose}
      >
        <Space direction="vertical" size="large" style={styles.container}>
          <Space direction="vertical" style={styles.container}>
            <DebouncedInput
              prefix={<SearchOutlined />}
              placeholder={t("admin.common.search")}
              value={text}
              onChange={(value) => setText(value)}
            />
          </Space>
          {multiple && (
            <Text>{t("admin.common.selectedCount", { count: selected.length })}</Text>
          )}
          {data && (
            <InfiniteScroll
              dataLength={playlists.length}
              next={loadMore}
              hasMore={page.hasNext}
              scrollableTarget={getDrawerBody()}
            >
              {playlists?.map((playlist) => {
                const disabled = (disabledValues ?? []).includes(playlist.id);
                return (
                  <Space
                    key={playlist.id}
                    align="start"
                    style={{
                      ...styles.row,
                      ...(disabled && styles.disabled),
                    }}
                  >
                    {multiple ? (
                      <Checkbox
                        checked={selected.includes(playlist.id)}
                        disabled={disabled}
                        onChange={(e) =>
                          e.target.checked
                            ? onCheck(playlist.id)
                            : onUncheck(playlist.id)
                        }
                      />
                    ) : (
                      <Radio.Group
                        value={selected.includes(playlist.id)}
                        onChange={(e) => e.target.value && onCheck(playlist.id)}
                      >
                        <Radio value />
                      </Radio.Group>
                    )}
                    <Text>{playlist.name}</Text>
                  </Space>
                );
              })}
            </InfiniteScroll>
          )}
        </Space>
      </Drawer>
    </>
  );
};

// ==

const QUERY = gql`
  query playlistsForPlaylistSelect(
    $filter: PlaylistFilterInput
    $sort: [PlaylistSortInput]
    $page: PageInput
  ) {
    playlists(filter: $filter, sort: $sort, page: $page) {
      objects {
        id
        name
      }
      ...ListFields
    }
  }
  ${LIST_FRAGMENT}
`;

// ==

const styles = {
  container: {
    width: "100%",
  },
  row: {
    width: "100%",
    paddingTop: 4,
    paddingBottom: 4,
  },
  disabled: {
    opacity: 0.3,
  },
};
