import { gql } from "@apollo/client";

import { ListWidget } from "../list";

export const PollVoteListWidget = (props) => (
  <ListWidget
    resource="pollVote"
    fields={[
      {
        name: "id",
      },
      {
        name: "option.poll",
        type: "object",
        labelKey: "admin.resource.poll.singular",
        resource: "poll",
        filterKey: "option_Poll_Overlap",
      },
      {
        name: "option",
        type: "object",
        resource: "pollOption",
        filterKey: "option_Overlap",
      },
      {
        name: "member",
        type: "object",
        resource: "member",
        filterKey: "member_Overlap",
      },
      ...ListWidget.fields.timestamped,
    ]}
    query={QUERY}
    extraRowActions={ListWidget.extraRowActions.delete}
    {...props}
  />
);

// ==

const QUERY = gql`
  query pollVotesForPollVoteListWidget(
    $filter: PollVoteFilterInput
    $sort: [PollVoteSortInput]
    $page: PageInput
  ) {
    pollVotes(filter: $filter, sort: $sort, page: $page) {
      objects {
        id
        option {
          id
          poll {
            id
            title
          }
        }
        member {
          id
          nickname
        }
        ...TimestampedFields
      }
      ...ListFields
    }
  }
  ${ListWidget.fragments.list}
  ${ListWidget.fragments.timestamped}
`;
