import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";

import { gql, useMutation } from "@apollo/client";

import { EditOutlined } from "@ant-design/icons";
import { Breadcrumb, Button, Col, Row } from "antd";

import { ListTable } from "../../components/list";
import { ResourceButton } from "../../components/list/action";
import { FormPage, ListPage } from "../../components/pages";
import { Can } from "../../components/permission";
import { PageHeader } from "../../components/typography";
import { useApp } from "../../hooks/app";
import { useAbilities } from "../../hooks/permission";
import { LIST_FRAGMENT, TRACKED_FRAGMENT } from "../../utils/gql/fragments";

export const TranslationRequestTargetContentList = (_) => {
  const { t } = useTranslation();
  const { resources } = useApp();
  const translationRequestResource = resources.translationRequest;
  const location = useLocation();
  const pathname = location.pathname;
  const isRequestManagementContext = pathname.includes("request-management");
  const queryParam = isRequestManagementContext ? "from=request" : "from=progress";
  const { abilities } = useAbilities(permissions);
  const [isAdminStaff] = abilities ?? [false];
  const fields = [
    {
      name: "translationRequest.id",
      label: "RQ ID",
      fixed: "left",
    },
    {
      name: "id",
      label: "ID",
    },
    {
      name: "translationRequest.group.name",
      labelKey: "admin.resource.group.singular",
      type: "object",
      resource: "group",
      getter: (value) => value.translationRequest.group,
    },
    {
      name: "translationRequest.inputSource",
      labelKey: "admin.field.inputSource",
      type: "choice",
      enumType: "TranslationRequestInputSource",
    },
    {
      name: "translationRequest.title",
      labelKey: "admin.field.title",
    },
    {
      name: "translationRequest.sourceLanguage",
      labelKey: "admin.field.sourceLanguage",
    },
    {
      name: "targetLanguage",
    },
    {
      name: "translationRequest.sourceWordCount",
      labelKey: "admin.field.sourceWordCount",
    },
    // {
    //   name: "targetWordCountAll",
    //   labelKey: "admin.field.targetWordCountAll",
    //   getter: (value) =>
    //     value.translationRequest.targetContents.reduce(
    //       (acc, cur) => acc + cur.targetWordCount,
    //       0,
    //     ),
    // },
    {
      name: "translationRequest.category",
      labelKey: "admin.field.category",
    },
    {
      name: "createdAt",
      type: "datetime",
      format: "YYYY-MM-DD",
    },
    {
      name: "translationRequest.requestor",
      labelKey: "admin.field.requestor",
      type: "object",
      resource: "staff",
      getter: (value) => value.translationRequest.requestor,
    },
    {
      name: "translationRequest.dueAt",
      labelKey: "admin.field.dueAt",
      type: "datetime",
      format: "YYYY-MM-DD",
    },
    {
      name: "assignee",
      type: "object",
      resource: "staff",
      getter: (value) => value.assignee,
    },
    {
      name: "requestStatus",
      type: "choice",
      enumType: "TranslationRequestStatus",
    },
  ];
  if (!isRequestManagementContext) {
    fields.push({
      name: "progressStatus",
      type: "choice",
      enumType: "TranslationProgressStatus",
    });
  }
  const { message } = useApp();
  const [update, { loading }] = useMutation(UPDATE_STATUSES_MUTATION);
  const bulkAssignToMe = (selectedRowKeys, setSelectedRowKeys, refetch) => {
    update({
      variables: {
        data: selectedRowKeys.map((id) => ({
          id,
          progressStatus: "ASSIGNED",
        })),
      },
      onCompleted: (response) => {
        const { ok, errors } = Object.values(response)[0];
        if (ok) {
          setSelectedRowKeys([]);
          refetch()
            .then(() => message.success(t("admin.message.processSuccess")))
            .catch(() => message.error(t("admin.message.processFailure")));
        } else {
          message.error(
            t("admin.message.processFailure", {
              message: errors[0].messages[0],
            }),
          );
        }
      },
    });
  };

  return (
    <>
      <Row justify="space-between">
        <Col>
          <Breadcrumb
            items={[
              { title: <Link to="/">{t("admin.common.home")}</Link> },
              {
                title: (
                  <Link to={translationRequestResource.listUrl}>
                    {translationRequestResource.labelPlural}
                  </Link>
                ),
              },
            ]}
          />
        </Col>
      </Row>
      <PageHeader
        titleKey={
          isRequestManagementContext
            ? "admin.menu.translationRequestManagement"
            : "admin.menu.translationProgressManagement"
        }
      />
      <Row justify="end">
        <Col>
          {isRequestManagementContext && (
            <ResourceButton resource="translationRequest" action="create" />
          )}
        </Col>
      </Row>
      <ListTable
        resource="translationRequestTargetContent"
        hideCreate={true}
        baseFilter={{ isActive_Exact: true }}
        isRowSelectable={!isAdminStaff}
        getCheckboxProps={(record) => ({
          disabled: record.progressStatus !== "OPENED",
        })}
        filterFields={[
          {
            type: "fieldset",
            labelKey: "admin.fieldset.default",
            fields: [
              {
                name: "translationRequest_Group_Exact",
                labelKey: "admin.resource.group.singular",
                type: "object",
                resource: "group",
                control: "select",
                extraProps: {
                  disableUniversalGroup: true,
                },
              },
              {
                name: "requestStatus_Overlap",
                labelKey: "admin.field.requestStatus",
                type: "choice",
                enumType: "TranslationRequestStatus",
                control: "select",
              },
              {
                name: "progressStatus_Overlap",
                labelKey: "admin.field.progressStatus",
                type: "choice",
                enumType: "TranslationProgressStatus",
                control: "select",
                hidden: isRequestManagementContext,
              },
            ],
          },
          {
            type: "fieldset",
            fields: [
              {
                name: "titleNgram_Text",
                labelKey: "admin.field.title",
                type: "text",
              },
              {
                name: "translationRequest_Requestor_Exact",
                labelKey: "admin.field.requestor",
                type: "object",
                resource: "staff",
                control: "select",
                baseFilter: {
                  roleCategories_Overlap: ["TRANSLATION_REQUESTOR"],
                },
              },
              {
                name: "assignee_Exact",
                labelKey: "admin.field.assignee",
                type: "object",
                resource: "staff",
                control: "select",
                baseFilter: {
                  roleCategories_Overlap: [
                    "TRANSLATOR",
                    "TRANSLATION_MANAGER",
                    "TRANSLATION_APPROVER",
                  ],
                },
              },
            ],
          },
          {
            type: "fieldset",
            labelKey: "admin.fieldset.period",
            fields: [
              {
                name: "createdAt_Range",
                labelKey: "admin.field.createdAt",
                type: "dateRange",
                disabledDate: false,
                isoString: true,
              },
              {
                name: "translationRequest_DueAt_Range",
                labelKey: "admin.field.dueAt",
                type: "dateRange",
                disabledDate: false,
                isoString: true,
              },
            ],
          },
        ]}
        fields={fields}
        query={QUERY}
        defaultSort={[
          { type: "RELEVANCE", direction: "DESC" },
          { type: "CREATED_AT", direction: "DESC" },
        ]}
        hideUpdate={true}
        extraActions={({ refetch, selectedRowKeys, setSelectedRowKeys }) => {
          return (
            <>
              {!isAdminStaff && !isRequestManagementContext && (
                <Button
                  type="primary"
                  onClick={() =>
                    bulkAssignToMe(selectedRowKeys, setSelectedRowKeys, refetch)
                  }
                  loading={loading}
                  disabled={selectedRowKeys.length === 0}
                >
                  {t("admin.component.assignToMeButton.title")}
                </Button>
              )}
            </>
          );
        }}
        extraRowActions={[
          ({ obj, resource }) => (
            <Can
              action="update"
              targetType="TRANSLATION_REQUEST_TARGET_CONTENT"
              targetId={obj.id}
            >
              <Link to={`${resource.updateUrlGetter(obj)}?${queryParam}`}>
                <Button icon={<EditOutlined />} size="small" />
              </Link>
            </Can>
          ),
          ListPage.extraRowActions.canActivate,
        ]}
        layout={
          isAdminStaff
            ? {
                filterControl: { span: 0 },
              }
            : {
                filterForm: { order: 0, span: 24 },
                action: { order: 1, span: 24, className: "flex justify-end" },
                table: { order: 2, span: 24 },
                filterControl: { flex: 3, span: 0 },
                paginator: { order: 4, span: 24, className: "flex justify-end" },
              }
        }
      />
    </>
  );
};

// ==

const permissions = [
  {
    action: "update",
    targetType: "TRANSLATION_REQUEST",
  },
];

// ==

const QUERY = gql`
  query translationRequestTargetContents(
    $filter: TranslationRequestTargetContentFilterInput
    $sort: [TranslationRequestTargetContentSortInput]
    $page: PageInput
  ) {
    translationRequestTargetContents(filter: $filter, sort: $sort, page: $page) {
      objects {
        translationRequest {
          id
          group {
            id
            name
          }
          requestor {
            id
            user {
              name
            }
          }
          targetContents {
            targetWordCount
          }
          inputSource
          sourceLanguage
          sourceWordCount
          title
          category
          dueAt
        }
        assignee {
          id
          user {
            name
          }
        }
        id
        targetLanguage
        requestStatus
        progressStatus
        reopenCount
        targetWordCount
        assignedAt
        finalSubmittedAt
        transmissionConfirmedAt
        closedAt
        isActive
        ...TrackedFields
      }
      ...ListFields
    }
  }
  ${TRACKED_FRAGMENT}
  ${LIST_FRAGMENT}
`;

const UPDATE_STATUSES_MUTATION = gql`
  mutation updateTranslationRequestTargetContentStatusesForm(
    $data: [TranslationRequestTargetContentStatusMutationInput]
  ) {
    updateTranslationRequestTargetContentStatuses(data: $data) {
      ok
      errors {
        ...MutationErrorFields
      }
    }
  }
  ${FormPage.fragments.mutationError}
`;
