import { forwardRef, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { gql } from "@apollo/client";

import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Checkbox, Col, Input, Row, Space } from "antd";

export const EventSurveyQuestionEditor = forwardRef(
  ({ value, onChange, disabled = false }, ref) => {
    const { t } = useTranslation();

    const fileQuestions = useMemo(
      () => value.questions?.filter((question) => question.category === "FILE") ?? [],
      [value],
    );
    const textQuestions = useMemo(
      () => value.questions?.filter((question) => question.category === "TEXT") ?? [],
      [value],
    );

    const [isFile, setIsFile] = useState(fileQuestions.length > 0);
    const [isText, setIsText] = useState(textQuestions.length > 0);

    useEffect(() => {
      if (isText && textQuestions.length === 0) {
        onChange({
          ...value,
          questions: [...(value.questions ?? []), { category: "TEXT" }],
        });
      }
      if (!isText && textQuestions.length > 0) {
        onChange({
          ...value,
          questions: value.questions?.filter(
            (question) => question.category !== "TEXT",
          ),
        });
      }
    }, [isText, textQuestions, onChange, value]);

    useEffect(() => {
      if (isFile && fileQuestions.length === 0) {
        onChange({
          ...value,
          questions: [...(value.questions ?? []), { category: "FILE" }],
        });
      }
      if (!isFile && fileQuestions.length > 0) {
        onChange({
          ...value,
          questions: value.questions?.filter(
            (question) => question.category !== "FILE",
          ),
        });
      }
    }, [isFile, fileQuestions, onChange, value]);

    // ==

    const handleAdd = (category) =>
      onChange({
        ...value,
        questions: [...(value.questions ?? []), { category }],
      });

    const handleRemove = (question) => {
      const questions = value.questions ?? [];
      const index = questions?.findIndex((q) => q === question);
      onChange({
        ...value,
        questions: [...questions.slice(0, index), ...questions.slice(index + 1)],
      });
    };

    const handleInputChange = (question, field, e) => {
      const questions = value.questions ?? [];
      const index = questions?.findIndex((q) => q === question);
      onChange({
        ...value,
        questions: [
          ...questions.slice(0, index),
          { ...question, [field]: e.target.value },
          ...questions.slice(index + 1),
        ],
      });
    };

    return (
      <Space direction="vertical" size="large" style={styles.container}>
        <Space>
          {flatFields.map(({ field, labelKey }, i) => (
            <Checkbox
              key={i}
              checked={value[field]}
              disabled={disabled}
              onChange={(e) =>
                onChange({
                  ...value,
                  [field]: e.target.checked,
                })
              }
            >
              {t(labelKey)}
            </Checkbox>
          ))}
        </Space>
        <Row>
          <Col span={4}>
            <Checkbox
              checked={isFile}
              disabled={disabled}
              onChange={(e) => setIsFile(e.target.checked)}
            >
              첨부파일
            </Checkbox>
          </Col>
          <Col span={20}>
            <Space direction="vertical" style={styles.container}>
              {fileQuestions.map((question, index) => (
                <Row key={index} gutter={16} align="middle">
                  <Col flex="auto">
                    <Input
                      placeholder="첨부파일 항목 이름을 입력해주세요. ex) 스트리밍 인증 화면"
                      value={question.title}
                      onChange={(e) => handleInputChange(question, "title", e)}
                    />
                    <Input
                      placeholder="업로드시 유의사항을 입력해주세요. ex) PC 화면 캡쳐 이미지만 인정됩니다. "
                      value={question.description}
                      onChange={(e) => handleInputChange(question, "description", e)}
                    />
                  </Col>
                  <Col flex="none">
                    <Button
                      type="text"
                      icon={<DeleteOutlined />}
                      onClick={() => handleRemove(question)}
                    />
                  </Col>
                </Row>
              ))}
              {isFile && (
                <Button
                  type="link"
                  icon={<PlusOutlined />}
                  onClick={() => handleAdd("FILE")}
                >
                  필드 추가
                </Button>
              )}
            </Space>
          </Col>
        </Row>
        <Row>
          <Col span={4}>
            <Checkbox
              checked={isText}
              disabled={disabled}
              onChange={(e) => setIsText(e.target.checked)}
            >
              자유문항
            </Checkbox>
          </Col>
          <Col span={20}>
            <Space direction="vertical" style={styles.container}>
              {textQuestions.map((question, index) => (
                <Row key={index} gutter={16}>
                  <Col flex="auto">
                    <Input
                      placeholder="자유 문항 제목을 입력해주세요"
                      value={question.title}
                      onChange={(e) => handleInputChange(question, "title", e)}
                    />
                  </Col>
                  <Col flex="none">
                    <Button
                      type="text"
                      icon={<DeleteOutlined />}
                      onClick={() => handleRemove(question)}
                    />
                  </Col>
                </Row>
              ))}
              {isText && (
                <Button
                  type="link"
                  icon={<PlusOutlined />}
                  onClick={() => handleAdd("TEXT")}
                >
                  필드 추가
                </Button>
              )}
            </Space>
          </Col>
        </Row>
      </Space>
    );
  },
);

EventSurveyQuestionEditor.fragments = {
  mediaLayoutSection: gql`
    fragment customEventSurveyForm on EventQuestion {
      id
      title
      description
      category
      placeholder
      isActive
      isRequired
      placeholder
      updatedAt
    }
  `,
};

// ==

const flatFields = [
  {
    field: "hasNameQuestion",
    labelKey: "admin.field.name",
  },
  {
    field: "hasBirthDateQuestion",
    labelKey: "admin.field.birthDate",
  },
  {
    field: "hasPhoneQuestion",
    labelKey: "admin.field.phone",
  },
  {
    field: "hasCountryQuestion",
    labelKey: "admin.field.country",
  },
  {
    field: "hasGenderQuestion",
    labelKey: "admin.field.gender",
  },
];

// ==

const styles = {
  container: {
    width: "100%",
  },
};
