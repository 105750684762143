import { gql } from "@apollo/client";

import { FormPage } from "../../components/pages";

export const SubcontinentForm = () => (
  <FormPage
    resource="subcontinent"
    fields={[
      {
        name: "continent",
        type: "object",
        resource: "continent",
      },
      {
        name: "code",
        helpKey: "admin.form.guide.codeSubcontinent",
      },
      {
        name: "name",
      },
    ]}
    query={QUERY}
    createMutation={CREATE_MUTATION}
    updateMutation={UPDATE_MUTATION}
  />
);

// ==

const FORM_FIELDS_FRAGMENT = gql`
  fragment SubcontinentFormFields on Subcontinent {
    id
    continent {
      id
    }
    code
    name
  }
`;

const QUERY = gql`
  query subcontinentForSubcontinentForm($id: ID!) {
    subcontinent(filter: { id_Overlap: [$id] }) {
      ...SubcontinentFormFields
    }
  }
  ${FORM_FIELDS_FRAGMENT}
`;

const CREATE_MUTATION = gql`
  mutation createSubcontinentForSubcontinentForm($data: SubcontinentMutationInput!) {
    createSubcontinent(data: $data) {
      ok
      errors {
        ...MutationErrorFields
      }
      object {
        ...SubcontinentFormFields
      }
    }
  }
  ${FormPage.fragments.mutationError}
  ${FORM_FIELDS_FRAGMENT}
`;

const UPDATE_MUTATION = gql`
  mutation updateSubcontinentForSubcontinentForm(
    $data: SubcontinentMutationInput!
    $id: ID!
  ) {
    updateSubcontinent(data: $data, id: $id, partial: true) {
      ok
      errors {
        ...MutationErrorFields
      }
      object {
        ...SubcontinentFormFields
      }
    }
  }
  ${FormPage.fragments.mutationError}
  ${FORM_FIELDS_FRAGMENT}
`;
