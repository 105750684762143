import { gql } from "@apollo/client";

import { FormPage } from "../../components/pages";

export const OfflineStoreNoticeForm = () => (
  <FormPage
    resource="offlineStoreNotice"
    fields={[
      {
        type: "fieldset",
        label: "",
        fields: [
          {
            name: "offlineStore",
            type: "object",
            resource: "offlineStore",
            disabled: ({ isUpdate }) => isUpdate,
          },
        ],
      },
      {
        type: "fieldset",
        labelKey: "admin.fieldset.content",
        fields: [
          {
            name: "titleContent",
            labelKey: "admin.field.title",
            type: "translatable",
          },
          {
            name: "bodyContent",
            labelKey: "admin.field.body",
            type: "translatable",
            rows: 5,
          },
        ],
      },
      ...FormPage.fields.canActivate,
    ]}
    query={QUERY}
    createMutation={CREATE_MUTATION}
    updateMutation={UPDATE_MUTATION}
  />
);

// ==

const FORM_FIELDS_FRAGMENT = gql`
  fragment OfflineStoreNoticeFormFields on OfflineStoreNotice {
    id
    offlineStore {
      id
    }
    title
    titleContent {
      ...TranslatableContentFields
    }
    body
    bodyContent {
      ...TranslatableContentFields
    }
    ...CanActivateFields
  }
  ${FormPage.fragments.canActivate}
  ${FormPage.fragments.translatableContent}
`;

const QUERY = gql`
  query offlineStoreNoticeForOfflineStoreNoticeForm($id: ID!) {
    offlineStoreNotice(filter: { id_Overlap: [$id] }) {
      ...OfflineStoreNoticeFormFields
    }
  }
  ${FORM_FIELDS_FRAGMENT}
`;

const CREATE_MUTATION = gql`
  mutation createOfflineStoreNoticeForOfflineStoreNoticeForm(
    $data: OfflineStoreNoticeMutationInput!
  ) {
    createOfflineStoreNotice(data: $data) {
      ok
      errors {
        ...MutationErrorFields
      }
      object {
        ...OfflineStoreNoticeFormFields
      }
    }
  }
  ${FormPage.fragments.mutationError}
  ${FORM_FIELDS_FRAGMENT}
`;

const UPDATE_MUTATION = gql`
  mutation updateOfflineStoreNoticeForOfflineStoreNoticeForm(
    $data: OfflineStoreNoticeMutationInput!
    $id: ID!
  ) {
    updateOfflineStoreNotice(data: $data, id: $id, partial: true) {
      ok
      errors {
        ...MutationErrorFields
      }
      object {
        ...OfflineStoreNoticeFormFields
      }
    }
  }
  ${FormPage.fragments.mutationError}
  ${FORM_FIELDS_FRAGMENT}
`;
