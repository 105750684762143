import { forwardRef } from "react";
import { useTranslation } from "react-i18next";

import { Select } from "antd";

import { booleanToString, stringToBoolean } from "../../utils/helpers";

export const BooleanSelect = forwardRef(
  (
    {
      nullLabel = undefined,
      trueLabel = undefined,
      falseLabel = undefined,
      onChange,
      value,
      ...selectProps
    },
    ref,
  ) => {
    const { t } = useTranslation();
    return (
      <Select
        options={[
          { label: nullLabel ?? "-- " + t("admin.common.select"), value: "" },
          { label: trueLabel ?? t("admin.common.yes"), value: "true" },
          { label: falseLabel ?? t("admin.common.no"), value: "false" },
        ]}
        value={booleanToString(value)}
        onChange={(value) => onChange(stringToBoolean(value))}
        style={styles.container}
        ref={ref}
        {...selectProps}
      />
    );
  },
);

// ==

const styles = {
  container: {
    width: "100%",
  },
};
