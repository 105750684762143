import { gql } from "@apollo/client";

import { DetailPage } from "../../components/pages";
import {
  CommentListWidget, // LikeListWidget,
  PollOptionListWidget,
  PollVoteListWidget,
} from "../../components/widgets";

export const PollDetail = () => (
  <DetailPage
    resource="poll"
    fields={[
      {
        name: "id",
      },
      {
        name: "group",
        type: "object",
        resource: "group",
      },
      {
        name: "title",
      },
      {
        name: "body",
      },
      {
        name: "member",
        labelKey: "admin.field.nickname",
        type: "object",
        resource: "member",
      },
      {
        name: "isOpen",
        type: "boolean",
      },
      {
        name: "closeAt",
        type: "datetime",
      },
      {
        name: "forceClose",
        type: "boolean",
      },
      {
        name: "optionCount",
        type: "integer",
      },
      {
        name: "voteCount",
        type: "integer",
      },
      {
        name: "commentCount",
        type: "integer",
      },
      {
        name: "likeCount",
        type: "integer",
      },
      {
        name: "status",
        type: "choice",
        enumType: "PollStatus",
      },
      ...DetailPage.fields.canActivate,
      ...DetailPage.fields.tracked,
    ]}
    query={QUERY}
    extraActions={DetailPage.extraActions.canActivate}
    tabs={[
      {
        path: "",
        labelKey: "admin.resource.comment.plural",
        permission: {
          action: "list",
          targetType: "COMMENT",
        },
        children: ({ obj }) => (
          <CommentListWidget
            baseFilter={{
              group_Overlap: [obj.group.id],
              targetType_Overlap: ["POLL"],
              targetId_Overlap: [obj.id],
            }}
            createSearchParams={{
              group: obj.group.id,
              targetType: "POLL",
              targetId: obj.id,
            }}
            hiddenFields={["group", "targetType", "target"]}
          />
        ),
      },
      // {
      //   path: "likes",
      //   labelKey: "admin.resource.like.plural",
      //   permission: {
      //     action: "list",
      //     targetType: "LIKE",
      //   },
      //   children: ({ obj }) => (
      //     <LikeListWidget
      //       baseFilter={{
      //         targetType_Overlap: ["POLL"],
      //         targetId_Overlap: [obj.id],
      //       }}
      //       hiddenFields={["targetType", "target"]}
      //     />
      //   ),
      // },
      {
        path: "votes",
        labelKey: "admin.common.votes",
        permission: {
          action: "list",
          targetType: "POLL_VOTE",
        },
        children: ({ obj }) => (
          <PollVoteListWidget
            baseFilter={{
              option_Poll_Overlap: [obj.id],
            }}
            hiddenFields={["option.poll"]}
          />
        ),
      },
      {
        path: "options",
        labelKey: "admin.common.options",
        permission: {
          action: "list",
          targetType: "POLL_OPTION",
        },
        children: ({ obj }) => (
          <PollOptionListWidget
            baseFilter={{
              poll_Overlap: [obj.id],
            }}
            sort={[{ type: "ORDER", direction: "ASC" }]}
            createSearchParams={{ poll: obj.id }}
            hiddenFields={["poll", "order"]}
          />
        ),
      },
    ]}
    hideUpdate
  />
);

// ==

const QUERY = gql`
  query pollForPollDetail($id: ID!) {
    poll(filter: { id_Overlap: [$id] }) {
      id
      group {
        id
        name
      }
      title
      body
      member {
        id
        nickname
      }
      isOpen
      closeAt
      forceClose
      optionCount
      voteCount
      commentCount
      likeCount
      status
      ...TrackedFields
      ...CanActivateFields
    }
  }
  ${DetailPage.fragments.tracked}
  ${DetailPage.fragments.canActivate}
`;
