import { useParams } from "react-router-dom";

import { type DocumentNode, type TypedDocumentNode, useQuery } from "@apollo/client";

import { Col, Row, Space } from "antd";

import type { GqlMutationData } from "@/gql/types";
import { useRevalidateQueryFields } from "@/hooks/apollo";
import { useFormFields } from "@/hooks/fields";
import { useResource } from "@/hooks/resource";
import type { ResourceName } from "@/resources";
import type { Field } from "@/types/field";
import type { FormRequestLike, FormResponseLike } from "@/types/form";
import type { RecordType } from "@/types/primitives";
import { capitalize } from "@/utils/helpers";

import { MutationForm } from "../../components/form";

type Props<
  Q extends DocumentNode,
  C extends TypedDocumentNode<FormResponseLike, FormRequestLike>,
  U extends TypedDocumentNode<FormResponseLike, FormRequestLike>,
  FormValue extends GqlMutationData<C> | GqlMutationData<U>,
> = {
  resource: ResourceName;
  query: Q;
  fields: Field<FormValue>[];
  createMutation: C;
  updateMutation: U;
  onAfterSuccess?: () => void;
  defaultValues?: RecordType;
  disableSubmit?: boolean;
  colSpan?: number;
};

export const TranslationRequestTargetContentMutationForm = <
  Q extends DocumentNode,
  C extends DocumentNode,
  U extends DocumentNode,
  FormValue extends GqlMutationData<C> | GqlMutationData<U>,
>({
  resource: resourceName,
  fields: originalFields,
  query,
  createMutation,
  updateMutation,
  onAfterSuccess = undefined,
  defaultValues: originalDefaultValues = undefined,
  disableSubmit = false,
  colSpan = 16,
}: Props<Q, C, U, FormValue>) => {
  const params = useParams();
  const isUpdate = !!params.id;

  const { resource } = useResource(resourceName);
  const { data } = useQuery(query, {
    skip: !isUpdate,
    variables: { id: params.id },
    fetchPolicy: "no-cache",
  });
  const revalidate = useRevalidateQueryFields(
    resource.typePlural,
    `all${capitalize(resource.typePlural)}`,
  );
  const obj = data ? (Object.values(data)[0] as RecordType) : null;

  const meta = useFormFields<FormValue>({
    resource: resourceName,
    fields: originalFields,
    obj,
    defaultValues: originalDefaultValues,
  });
  const { fields } = meta;

  if (isUpdate && (!obj || !fields)) {
    return null;
  }

  // ==

  const mutation = isUpdate ? updateMutation : createMutation;
  const onSuccess = () => {
    revalidate();
    if (onAfterSuccess) onAfterSuccess();
  };

  return (
    <Space className="flex" direction="vertical" size="middle">
      <Row gutter={24}>
        <Col span={24} lg={colSpan}>
          <MutationForm
            meta={meta}
            mutation={mutation}
            id={params.id}
            submitButtonOptions={{ disabled: disableSubmit }}
            onSuccess={onSuccess}
          />
        </Col>
      </Row>
    </Space>
  );
};
