import { forwardRef } from "react";

import { gql } from "@apollo/client";

import { ObjectSelect } from "../fields";

export const SelectLanguage = forwardRef((props, ref) => (
  <ObjectSelect resource="language" query={QUERY} ref={ref} {...props} />
));

// ==

const QUERY = gql`
  query languagesForSelectLanguage($filter: LanguageFilterInput) {
    languages(filter: $filter, sort: [{ type: RELEVANCE, direction: DESC }]) {
      objects {
        id
        name
      }
    }
  }
`;
