import { forwardRef, useCallback, useContext, useMemo } from "react";

import { FieldsetContext } from "@/components/fields/context";
import { useFormContext } from "@/components/form/context";
import { LanguageTab } from "@/domain/language/components/LanguageTab";
import type { ServerEnum } from "@/hooks/enum";
import type { UploadedFileMeta } from "@/hooks/upload-s3";
import type { TranslatableFilesField } from "@/types/field";
import type { FieldProps } from "@/types/form";
import { castArray, keyBy, mapValues, resolveCallable } from "@/utils/helpers";

import { MediaUpload } from "./MediaUpload";

export const TranslatableFilesEditor = forwardRef<
  any,
  FieldProps<TranslatableFilesField> & {
    // below props will be injected within the `inferFormFieldRender`
    accept: string;
    onUploadBegin?: () => void;
    onUploadEnd?: () => void;
  }
>((props, ref) => {
  const {
    id: _,
    type: __,
    name: ___,
    value,
    onChange,
    multiple = false,
    sync = true,
    disabled: resolvableDisabled,
    ...restProps
  } = props;
  const { data, editingLanguage, setEditingLanguage } = useFormContext();
  const fieldsetContext = useContext(FieldsetContext);

  const valuesByLanguage = useMemo(
    () =>
      mapValues(
        keyBy(value?.values ?? [], (v) => v.language),
        (values) => values.values,
      ),
    [value],
  );

  const handleChange = useCallback(
    (language: string) => (newValue: UploadedFileMeta | UploadedFileMeta[]) => {
      const newValueMap = { ...valuesByLanguage, [language]: castArray(newValue) };
      return onChange?.({
        ...value,
        values: Object.entries(newValueMap).map(([language, values]) => ({
          language,
          values,
        })),
      });
    },
    [valuesByLanguage, onChange, value],
  );

  const disabled = resolveCallable(resolvableDisabled, { data });

  return (
    <LanguageTab
      hideTab={fieldsetContext?.hideLanguageSelect}
      activeKey={editingLanguage}
      onChangeActiveKey={setEditingLanguage}
      render={(language: ServerEnum) => {
        const onChange = handleChange(language.name);
        const values = valuesByLanguage[language.name];
        const value = multiple ? values : values?.[0];

        return (
          <MediaUpload
            value={value}
            disabled={disabled}
            multiple={multiple}
            sync={sync}
            onChange={onChange}
            {...restProps}
            ref={ref}
          />
        );
      }}
    />
  );
});
