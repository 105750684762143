import { forwardRef } from "react";

import { gql } from "@apollo/client";

import { ObjectSelect } from "../fields";

export const SelectHashtag = forwardRef((props, ref) => (
  <ObjectSelect resource="hashtag" query={QUERY} ref={ref} {...props} />
));

// ==

const QUERY = gql`
  query hashtagsForSelectHashtag($filter: HashtagFilterInput) {
    hashtags(filter: $filter, sort: [{ type: RELEVANCE, direction: DESC }]) {
      objects {
        id
        name
      }
    }
  }
`;
