import { gql } from "@apollo/client";

import { ListPage, ListPageWithGroup } from "../../components/pages";

export const OfflineStoreList = () => (
  <ListPageWithGroup
    groupFilterKey="group_Overlap"
    resource="offlineStore"
    subtitleKey="admin.page.offlineStore.subtitle"
    defaultSort={[
      {
        type: "CREATED_AT",
        direction: "DESC",
      },
    ]}
    fields={[
      {
        name: "id",
      },
      {
        name: "group",
        type: "object",
        resource: "group",
        filterKey: "group_Overlap",
      },
      {
        name: "name",
        truncate: true,
      },
      {
        name: "isActive",
        type: "boolean",
      },
      ...ListPage.fields.tracked,
    ]}
    query={QUERY}
    extraRowActions={ListPage.extraRowActions.canActivate}
  />
);

// ==

const QUERY = gql`
  query offlineStoreForOfflineStoreList(
    $filter: OfflineStoreFilterInput
    $sort: [OfflineStoreSortInput]
    $page: PageInput
  ) {
    offlineStores(filter: $filter, sort: $sort, page: $page) {
      objects {
        id
        group {
          id
          name
        }
        name
        isActive
        ...TrackedFields
        ...CanActivateFields
      }
      ...ListFields
    }
  }
  ${ListPage.fragments.list}
  ${ListPage.fragments.tracked}
  ${ListPage.fragments.canActivate}
`;
