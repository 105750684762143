import { useState } from "react";
import { useTranslation } from "react-i18next";

import { useMutation } from "@apollo/client";

import { Button, Modal, Typography } from "antd";

import { useApp } from "../../hooks/app";
import { capitalize } from "../../utils/helpers";

export const HandleSelectedRowKeysButton = ({
  objectId,
  selectedRowKeys,
  setSelectedRowKeys,
  onChange,
  buttonText,
  confirmation,
  confirmMessage,
  mutation,
  icon,
  variablesData,
  size = "middle",
  onlyIcon = false,
  disabled = false,
}) => {
  const { t } = useTranslation();

  const onCompleted = (response) => {
    const { ok, errors } = Object.values(response)[0];
    if (ok) {
      message.success(t("admin.message.processSuccess"));
      onChange?.();
      setSelectedRowKeys([]);
      setIsModalOpen(false);
    } else {
      message.error(
        t("admin.message.processFailure", {
          message: errors[0].messages[0],
        }),
      );
    }
  };

  const variables = variablesData ?? {
    objectId,
    selectedRowKeys,
  };
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [callMutation, { loading }] = useMutation(mutation, {
    variables,
    onCompleted,
  });

  const { message } = useApp();

  const action = buttonText;
  const onClick = () => setIsModalOpen(true);
  const onOk = () => callMutation();
  const onCancel = () => setIsModalOpen(false);

  return (
    <>
      <Button
        type="primary"
        icon={icon}
        onClick={onClick}
        size={size}
        loading={loading}
        disabled={disabled}
      >
        {onlyIcon ? null : capitalize(action)}
      </Button>
      <Modal
        title={confirmation ?? t(`admin.common.confirmation`)}
        open={isModalOpen}
        confirmLoading={loading}
        okText={capitalize(action)}
        okButtonProps={{ icon }}
        cancelText={t("admin.common.cancel")}
        onOk={onOk}
        onCancel={onCancel}
      >
        <Typography.Text>{confirmMessage}</Typography.Text>
      </Modal>
    </>
  );
};
