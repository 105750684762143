import { gql } from "@apollo/client";

import { FormPage } from "../../components/pages";

export const MediaLayoutSectionForm = () => (
  <FormPage
    resource="mediaLayoutSection"
    fields={[
      {
        name: "layout",
        type: "object",
        resource: "mediaLayout",
        disabled: ({ isUpdate }) => isUpdate,
      },
      {
        name: "category",
        type: "choice",
        enumType: "MediaLayoutSectionCategory",
        onChange: ({ value, setValue, resetValue }) => {
          if (value === "BIG_THUMBNAIL") {
            setValue("source", "CUSTOM");
          } else {
            resetValue("source");
          }
        },
      },
      {
        name: "source",
        type: "choice",
        enumType: "MediaLayoutSectionSource",
        disabled: ({ data }) => data.category === "BIG_THUMBNAIL",
      },
      {
        name: "premium",
        type: "object",
        resource: "premium",
        hidden: ({ data }) =>
          !(data.source === "PREMIUM_ONLY" && data.category !== "BIG_THUMBNAIL"),
      },
      {
        name: "playlist",
        type: "object",
        resource: "playlist",
        hidden: ({ data }) =>
          !(data.source === "CUSTOM" && data.category !== "BIG_THUMBNAIL"),
      },

      {
        name: "clip",
        type: "object",
        resource: "clip",
        hidden: ({ data }) =>
          !(data.source === "CUSTOM" && data.category === "BIG_THUMBNAIL"),
      },
      {
        name: "titleContent",
        labelKey: "admin.field.title",
        type: "translatable",
      },
      {
        name: "subtitleContent",
        labelKey: "admin.field.subtitle",
        type: "translatable",
        hidden: ({ data }) => !(data.category === "BIG_THUMBNAIL"),
      },
      {
        name: "order",
        type: "integer",
      },
    ]}
    query={QUERY}
    createMutation={CREATE_MUTATION}
    updateMutation={UPDATE_MUTATION}
  />
);

// ==

const FORM_FIELDS_FRAGMENT = gql`
  fragment MediaLayoutSectionFormFields on MediaLayoutSection {
    id
    layout {
      id
    }
    playlist {
      id
    }
    clip {
      id
    }
    premium {
      id
    }
    category
    source
    title
    titleContent {
      ...TranslatableContentFields
    }
    subtitle
    subtitleContent {
      ...TranslatableContentFields
    }
    order
  }
  ${FormPage.fragments.translatableContent}
`;

const QUERY = gql`
  query mediaLayoutSectionForMediaLayoutSectionForm($id: ID!) {
    mediaLayoutSection(filter: { id_Overlap: [$id] }) {
      ...MediaLayoutSectionFormFields
    }
  }
  ${FORM_FIELDS_FRAGMENT}
`;

const CREATE_MUTATION = gql`
  mutation createMediaLayoutSectionForMediaLayoutSectionForm(
    $data: MediaLayoutSectionMutationInput!
  ) {
    createMediaLayoutSection(data: $data) {
      ok
      errors {
        ...MutationErrorFields
      }
      object {
        ...MediaLayoutSectionFormFields
      }
    }
  }
  ${FormPage.fragments.mutationError}
  ${FORM_FIELDS_FRAGMENT}
`;

const UPDATE_MUTATION = gql`
  mutation updateMediaLayoutSectionForMediaLayoutSectionForm(
    $data: MediaLayoutSectionMutationInput!
    $id: ID!
  ) {
    updateMediaLayoutSection(data: $data, id: $id, partial: true) {
      ok
      errors {
        ...MutationErrorFields
      }
      object {
        ...MediaLayoutSectionFormFields
      }
    }
  }
  ${FormPage.fragments.mutationError}
  ${FORM_FIELDS_FRAGMENT}
`;
