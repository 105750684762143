import { gql } from "@apollo/client";

import { ListPage, ListPageWithGroup } from "../../components/pages";

export const GroupFeatureFlagList = () => (
  <ListPageWithGroup
    groupFilterKey="group_Overlap"
    resource="groupFeatureFlag"
    subtitleKey="admin.page.groupFeatureFlag.subtitle"
    fields={[
      {
        name: "id",
      },
      {
        name: "group",
        type: "object",
        resource: "group",
        filterKey: "group_Overlap",
      },
      {
        name: "category",
        type: "choice",
        enumType: "GroupFeatureFlagCategory",
      },
      ...ListPage.fields.tracked,
    ]}
    query={QUERY}
    defaultSort={[
      {
        type: "CREATED_AT",
        direction: "DESC",
      },
    ]}
    extraRowActions={ListPage.extraRowActions.canActivate}
  />
);

// ==

const QUERY = gql`
  query groupFeatureFlagsForGroupFeatureFlagList(
    $filter: GroupFeatureFlagFilterInput
    $sort: [GroupFeatureFlagSortInput]
    $page: PageInput
  ) {
    groupFeatureFlags(filter: $filter, sort: $sort, page: $page) {
      objects {
        id
        group {
          id
          name
        }
        category
        ...TrackedFields
        ...CanActivateFields
      }
      ...ListFields
    }
  }
  ${ListPage.fragments.list}
  ${ListPage.fragments.tracked}
  ${ListPage.fragments.canActivate}
`;
