import { Fragment } from "react";
import { Link } from "react-router-dom";

import { ExportOutlined } from "@ant-design/icons";
import {
  Checkbox,
  Col,
  ColorPicker,
  Input,
  InputNumber,
  Radio,
  Row,
  Space,
  Switch,
  Tag,
  Typography,
} from "antd";

import dayjs from "dayjs";
import { v4 as uuidv4 } from "uuid";

import { ViewImageAction, ViewVideoAction } from "../../components/actions";
import {
  BooleanField,
  BooleanSelect,
  DateField,
  DatePicker,
  DateRangePicker,
  DateTimeField,
  DateTimePicker,
  EnumCheckbox,
  EnumField,
  EnumRadio,
  EnumSelect,
  HTMLEditor,
  HTMLField,
  MarkdownEditor,
  MarkdownField,
  MediaUpload,
  MultipleInput,
  NumberField,
  PhoneInput,
  RangeInput,
  TimeField,
  TimeRangePicker,
  TranslatableContentEditor,
  TranslatableFilesEditor,
  TranslatableText,
  UpperCaseInput,
} from "../../components/fields";
import { PlayableImage } from "../../components/media";
import { Can } from "../../components/permission";
import { ResolvedText } from "../../components/wrapper";
import { getLocalDateRange } from "../../utils/datetime";
import { isContainerField } from "../../utils/field";
import {
  extractFileName,
  getByPath,
  isArray,
  isEmpty,
  isNumber,
  isString,
  normalizeUrl,
  objectWithPaths,
  pathToObject,
  pick,
  resolveCallable,
  stripDunderKeys,
  truncateString,
} from "../helpers";

const { Text } = Typography;

/*
 TODO: Let's use @/types/field/Field.ts
 * Possible types:
 * - text
 * - phone
 * - email
 * - url
 * - color
 * - integer
 * - float
 * - boolean
 * - date
 * - datetime
 * - choice
 * - object
 * - file
 * - caption
 * - image
 * - translatable
 * - longTimestamp
 *
 * Props:
 * - text: [markdown]
 * - boolean: [nullable]
 * - choice: [enumType!]
 * - object: [resource!, selectComponent]
 * - translatable: [markdown]
 *
 * Form-only props:
 * - *: [placeholder, help, description, disabled]
 * - fieldset: [fields, sider, fieldsColSpan, siderColSpan]
 * - nested: [fields, multiple]
 * - text: [rows]
 * - float: [step]
 * - boolean: [switchLabel]
 * - datetime: [showAllDay]
 * - choice: [multiple, control, direction, filterOptions]
 * - file: [multiple]
 * - fileMeta: [multiple]
 * - genericMeta: [multiple]
 * - image: [multiple]
 * - imageMeta: [multiple]
 * - caption: [multiple]
 * - captionMeta: [multiple]
 * - videoMeta: [multiple]
 * - object: [multiple, baseFilter]
 * - nestedObject: [multiple, baseFilter]
 * - translatable: [rows]
 *
 * List-only props:
 * - *: [filterKey, filterType, sortKey, sortPriority, ...antd.Table.ColumnProps]
 *
 * (List|Detail)-only props:
 * - *: [primary, empty, spaceDirection, spaceSize, spaceWrap, spaceAlign]
 * - text: [translatable]
 * - url: [onlyIcon]
 * - file: [onlyIcon]
 * - fileMeta: [onlyIcon]
 * - genericMeta: [onlyIcon]
 *
 */

export const inferFieldType = () => "text";

export const inferFieldLabel = (field, resource, resources, t) => {
  if (field.label) {
    return field.label;
  }

  if (field.labelKey) {
    return t(field.labelKey);
  }

  if (field.name) {
    const key = `admin.field.${field.name}`;
    if (field.type === "object") {
      return t([key, `admin.resource.${resources[field.resource]?.name}.singular`]);
    } else {
      return t(key);
    }
  }

  return null;
};

export const inferFieldGetter = (field) => {
  switch (field.type) {
    case "composite":
      return (obj) => objectWithPaths(obj, field.names);
    default:
      return (obj) => getByPath(obj, field.name);
  }
};

export const inferFieldPermission = (field, resource, resources, action) => {
  switch (field.type) {
    default:
      return ({ obj, resource }) => ({
        action,
        targetType: resource.typeName,
        targetId: obj?.id,
        field: field.name.split(".")[0],
      });
  }
};

export const inferFieldRender = (field, resource, resources) => {
  switch (field.type) {
    case "text":
      return wrapRenderer(field, resource, ({ value, key }) =>
        field.markdown ? (
          <MarkdownField key={key}>{value}</MarkdownField>
        ) : field.html ? (
          <HTMLField key={key} value={value} />
        ) : field.translatable ? (
          <TranslatableText
            key={key}
            value={value}
            style={{ whiteSpace: "pre-line" }}
          />
        ) : field.truncate ? (
          <Text key={key} style={{ whiteSpace: "pre-line" }}>
            {truncateString(value, field.truncateLength)}
          </Text>
        ) : (
          <Text key={key} style={{ whiteSpace: "pre-line" }}>
            {value}
          </Text>
        ),
      );
    case "integer":
    case "float":
      return wrapRenderer(field, resource, ({ value, key }) =>
        isEmpty(value) ? field.empty : <NumberField key={key} value={value} />,
      );
    case "date":
      // TODO: Make timezone-aware
      return wrapRenderer(field, resource, ({ value, key }) =>
        isEmpty(value) ? field.empty : <DateField key={key} value={value} />,
      );
    case "datetime":
      // TODO: Make timezone-aware
      return wrapRenderer(field, resource, ({ value, key }) =>
        isEmpty(value) ? (
          field.empty
        ) : (
          <DateTimeField format={field.format} key={key} value={value} />
        ),
      );
    case "time":
      return wrapRenderer(field, resource, ({ value, key }) =>
        isEmpty(value) ? field.empty : <TimeField key={key} value={value} />,
      );
    case "boolean":
      return wrapRenderer(field, resource, ({ value, key }) => (
        <BooleanField key={key} value={value} />
      ));
    case "choice":
      return wrapRenderer(field, resource, ({ value, key }) => (
        <EnumField key={key} type={field.enumType} value={value} />
      ));
    case "url":
      return wrapRenderer(field, resource, ({ value, key }) => (
        <Link key={key} to={normalizeUrl(value)} target="_blank">
          {field.onlyIcon ?? `${value} `}
          <ExportOutlined />
        </Link>
      ));
    case "color":
      return wrapRenderer(field, resource, ({ value, key }) => (
        <div
          key={key}
          style={{
            width: 30,
            height: 30,
            backgroundColor: value,
            borderRadius: 4,
          }}
        />
      ));
    case "file":
      return wrapRenderer(field, resource, ({ value, key }) => (
        <Link key={key} to={normalizeUrl(value)} target="_blank">
          {field.onlyIcon ?? `${extractFileName(value)} `}
          <ExportOutlined />
        </Link>
      ));
    case "fileMeta":
      return wrapRenderer(field, resource, ({ value, key }) => (
        <Link key={key} to={value.url} target="_blank">
          {field.onlyIcon ?? `${extractFileName(value.url)} `}
          <ExportOutlined />
        </Link>
      ));
    case "captionMeta":
      return wrapRenderer(field, resource, null, ({ values, key }) => (
        <Space key={key} direction={"vertical"}>
          {values?.map((item, i) => (
            <Link key={i} to={normalizeUrl(item.caption.url)}>
              {item.language.name}
            </Link>
          ))}
        </Space>
      ));
    case "image":
      return wrapRenderer(field, resource, ({ value, key }) => (
        <ViewImageAction key={key} images={[normalizeUrl(value)]} width={value.width}>
          {({ openModal }) => (
            <img
              src={normalizeUrl(value)}
              onClick={openModal}
              style={{ width: "100%", cursor: "pointer" }}
            />
          )}
        </ViewImageAction>
      ));
    case "imageMeta":
      return wrapRenderer(field, resource, ({ value, key }) => (
        <ViewImageAction key={key} images={[value.url]} width={value.width}>
          {({ openModal }) => (
            <img
              src={value.thumbnailUrl ?? value.url}
              onClick={openModal}
              style={{ width: "100%", cursor: "pointer" }}
            />
          )}
        </ViewImageAction>
      ));
    case "videoMeta":
      return wrapRenderer(field, resource, ({ value, key }) => (
        <ViewVideoAction key={key} videos={[value.m3u8Url]} width={value.width}>
          {({ openModal }) => (
            <PlayableImage
              src={value.thumbnailUrl}
              onClick={openModal}
              style={{ width: "100%", cursor: "pointer" }}
            />
          )}
        </ViewVideoAction>
      ));
    case "genericMeta":
      return wrapRenderer(field, resource, ({ value, key }) =>
        value.mime.startsWith("image/") ? (
          <ViewImageAction key={key} images={[value.url]} width={value.width}>
            {({ openModal }) => (
              <img
                src={value.thumbnailUrl ?? value.url}
                onClick={openModal}
                style={{ width: "100%", cursor: "pointer" }}
              />
            )}
          </ViewImageAction>
        ) : value.mime.startsWith("video/") ? (
          <ViewVideoAction key={key} videos={[value.m3u8Url]} width={value.width}>
            {({ openModal }) => (
              <PlayableImage
                src={value.thumbnailUrl}
                onClick={openModal}
                style={{ width: "100%", cursor: "pointer" }}
              />
            )}
          </ViewVideoAction>
        ) : (
          <Link key={key} to={value.url} target="_blank">
            {field.onlyIcon ?? `${extractFileName(value.url)} `}
            <ExportOutlined />
          </Link>
        ),
      );
    case "object":
      return wrapRenderer(field, resource, (props) => {
        try {
          const resource = resources[resolveCallable(field.resource, props)];
          const { key, value } = props;
          return (
            <Can
              key={key}
              action="list"
              targetType={resource.typeName}
              fulfill={
                <Link key={key} to={resource.detailUrlGetter(value)}>
                  {resource.labelGetter(value)}
                </Link>
              }
              reject={resource.labelGetter(value)}
            ></Can>
          );
        } catch (e) {
          const { key, value } = props;
          return <Text key={key}>{`${value?.type}#${value?.id}`}</Text>;
        }
      });
    case "nestedObject":
      return wrapRenderer(field, resource, (props) => {
        const resource = resources[resolveCallable(field.resource, props)];
        const { key, value } = props;
        const nestedValue = resource.keyGetter(value) ?? value;
        return (
          <Can
            key={key}
            action="list"
            targetType={resource.typeName}
            fulfill={
              <Link key={key} to={resource.detailUrlGetter(nestedValue)}>
                {resource.labelGetter(nestedValue)}
              </Link>
            }
            reject={resource.labelGetter(nestedValue)}
          ></Can>
        );
      });
    case "translatable":
      return wrapRenderer(field, resource, ({ value, key }) => {
        return (
          <Space key={key} direction="vertical">
            {value?.values?.map(({ language, value }, i) => (
              <div key={i}>
                <EnumField type="ServiceLanguage" value={language} />
                {field.markdown ? (
                  <MarkdownField>{value}</MarkdownField>
                ) : field.html ? (
                  <HTMLField value={value} />
                ) : (
                  value
                )}
              </div>
            ))}
          </Space>
        );
      });
    case "timeWindow":
      return wrapRenderer(field, resource, ({ value, key }) => {
        const { date, openTime, closeTime } = value;
        return (
          <Row key={key}>
            <Col>
              {date} {openTime} ~ {closeTime}
            </Col>
          </Row>
        );
      });

    case "operatingSchedules":
      return wrapRenderer(field, resource, ({ value, key }) => {
        const { scheduleCategory, date, openTime, closeTime } = value;
        const isSpecificDate = scheduleCategory === "SPECIFIC";
        return (
          <Row key={key}>
            <Col>
              {isSpecificDate && <Tag>{date}</Tag>}
              {scheduleCategory && !isSpecificDate && (
                <EnumField
                  type="OfflineStoreOperatingScheduleCategory"
                  value={value.scheduleCategory}
                />
              )}
            </Col>
            <Col>
              {openTime} ~ {closeTime}
            </Col>
          </Row>
        );
      });
    case "restrictions":
      return wrapRenderer(field, resource, ({ value, key }) => {
        return (
          <Text key={key}>
            {value?.category} {value?.count}
          </Text>
        );
      });
    case "categoryCount":
      return wrapRenderer(field, resource, ({ value, key }) => (
        <div key={key}>
          <EnumField type={field.enumType} value={value.category} />
          <Text>: {value.count}</Text>
        </div>
      ));
    case "googleMapUrl":
      return wrapRenderer(field, resource, ({ value, key }) => {
        return <iframe key={key} src={value} width="100%" loading="lazy"></iframe>;
      });

    case "longTimestamp":
      return wrapRenderer(field, resource, ({ value }) => {
        const nanoTimestamp = BigInt(value);

        const millis = Number(nanoTimestamp / BigInt(1e6));
        const nanoseconds = nanoTimestamp % BigInt(1e9);

        const date = dayjs(millis);

        const dateString = date.format("YYYY-MM-DD HH:mm:ss");

        return `${dateString}.${nanoseconds.toString().padStart(9, "0")}`;
      });
    default:
      return wrapRenderer(field, resource, ({ value, key }) => (
        <Text key={key}>{value}</Text>
      ));
  }
};

export const inferFormFieldGetter = (field, resource, resources) => {
  switch (field.type) {
    case "time":
    case "date":
    case "datetime":
      return (obj) => {
        const value = getByPath(obj, field.name);
        return !isEmpty(value) ? dayjs(value) : null;
      };
    case "nestedObject":
    case "object": {
      return (obj) =>
        field.multiple
          ? getByPath(obj, field.name)?.map(resource.keyGetter)
          : resource.keyGetter(getByPath(obj, field.name));
    }
    case "composite":
      return (obj) => objectWithPaths(obj, field.names);
    default:
      return (obj) => stripDunderKeys(getByPath(obj, field.name));
  }
};

export const inferFormFieldNormalizer = (field, resource) => {
  switch (field.type) {
    case "date":
      return (value) =>
        pathToObject(field.name, value, (v) => (v ? v.format("YYYY-MM-DD") : v));
    case "datetime":
      return (value) =>
        pathToObject(field.name, value, (v) => (v ? v.toISOString() : v));
    case "dateRange": {
      const isoString = field?.isoString ?? false;
      return (value) =>
        pathToObject(field.name, value, (v) =>
          v && v?.length === 2 ? getLocalDateRange(v[0], v[1], isoString) : v,
        );
    }
    case "datetimeRange":
      return (value) =>
        pathToObject(field.name, value, (r) => (r ? r.map((v) => v.toISOString()) : r));
    case "fileMeta":
    case "genericMeta":
    case "imageMeta":
    case "captionMeta":
    case "videoMeta":
      return (value) => pathToObject(field.name, value, normalizeMetaField);
    case "translatable":
      return (value) =>
        pathToObject(field.name, value, (v) => (v ? { values: v.values } : undefined));
    case "translatableFiles": {
      const normalize = (value) =>
        Array.isArray(value.values)
          ? {
              values: value.values.map((item) => ({
                language: item.language,
                values: normalizeMetaField(item.values),
              })),
            }
          : null;
      return (value) =>
        pathToObject(field.name, value, (v) => (v ? normalize(v) : undefined));
    }
    case "nested": {
      const aggregateField = (value) =>
        Object.fromEntries(
          field.fields.flatMap(({ normalizer, name }) =>
            Object.entries(normalizer(name ? getByPath(value, name) : value)),
          ),
        );
      const normalizer = (value) =>
        field.multiple
          ? value
            ? value.map(aggregateField)
            : value
          : aggregateField(value);
      return (value) => pathToObject(field.name, value, normalizer);
    }
    case "nestedObject":
      return (value) =>
        pathToObject(field.name, value, (v) => resource.keyGetter(v) ?? v);
    case "composite":
      return (value) => objectWithPaths(value, field.names);
    default:
      return (value) => pathToObject(field.name, value);
  }
};

export const inferFormFieldRender = (field, resource, resources, t) => {
  switch (field.type) {
    case "text":
      return field.markdown
        ? ({ controllerField }) => <MarkdownEditor {...controllerField} />
        : field.html
        ? ({ controllerField }) => <HTMLEditor {...controllerField} />
        : field.multiple
        ? ({ controllerField }) => <MultipleInput {...controllerField} />
        : field.rows > 1
        ? ({ controllerField }) => (
            <Input.TextArea rows={field.rows} {...controllerField} />
          )
        : field.forceUpperCase
        ? ({ controllerField }) => (
            <UpperCaseInput
              type={field.type}
              {...controllerField}
              {...field.controlProps}
            />
          )
        : ({ controllerField }) => (
            <Input type={field.type} {...controllerField} {...field.controlProps} />
          );

    case "email":
    case "url":
      return ({ controllerField }) => (
        <Input type={field.type} {...controllerField} {...field.controlProps} />
      );

    case "integer":
    case "float":
      return ({ controllerField }) => {
        const { suffix, suffixKey, step } = field;
        const safeStep = isNumber(step) ? step : field.type == "integer" ? 1 : 0.01;
        return (
          <Space>
            <InputNumber step={safeStep} {...controllerField} />
            <ResolvedText value={suffix} valueKey={suffixKey} wrapper={Text} />
          </Space>
        );
      };

    case "boolean":
      return field.nullable
        ? ({ controllerField }) => <BooleanSelect {...controllerField} />
        : ({ controllerField }) => {
            const { value, ...rest } = controllerField;
            return field.control === "checkbox" ? (
              <Checkbox checked={value} {...rest}>
                {field.controlLabel}
              </Checkbox>
            ) : (
              <Space size="small">
                <Switch checked={value} {...rest} />
                {field.controlLabel && <Text>{field.controlLabel}</Text>}
              </Space>
            );
          };

    case "color":
      return ({ controllerField: { ref, onChange, ...otherControllerField } }) => (
        <ColorPicker
          disabledAlpha
          showText
          onChange={(color, hex) => onChange(hex)}
          {...otherControllerField}
        />
      );

    case "date":
      return ({
        controllerField,
        showToday = field?.showToday,
        useHolidays = field?.useHolidays,
      }) => {
        return (
          <DatePicker
            showToday={showToday}
            useHolidays={useHolidays}
            {...controllerField}
          />
        );
      };

    case "dateRange":
      return ({ controllerField, setValue, disabledDate = field?.disabledDate }) => {
        return (
          <DateRangePicker
            setValue={setValue}
            disabledDate={disabledDate}
            {...controllerField}
          />
        );
      };

    case "datetime":
      return ({ controllerField }) => {
        return (
          <DateTimePicker
            showAllDay={field.showAllDay}
            showNullable={field.showNullable}
            nullableText={field.nullableText}
            showHours={field.showHours}
            showMinutes={field.showMinutes}
            showSeconds={field.showSeconds}
            {...controllerField}
          />
        );
      };
    case "datetimeRange":
      return ({ controllerField, setValue }) => (
        <DateRangePicker
          setValue={setValue}
          showTime={{ format: "HH:mm:ss" }}
          format="YYYY-MM-DD HH:mm:ss"
          {...controllerField}
        />
      );

    case "time":
      return ({ controllerField }) => <DatePicker type="time" {...controllerField} />;

    case "timeRange":
      return ({ controllerField, setValue }) => {
        return <TimeRangePicker setValue={setValue} {...controllerField} />;
      };

    case "phone":
      return ({ controllerField: { ref, ...otherControllerField }, setValue }) => (
        <PhoneInput {...otherControllerField} setValue={setValue} />
      );

    case "file":
    case "image":
      return ({ controllerField, setValue }) => (
        <Input
          type="file"
          multiple={field.multiple}
          onChange={({
            target: {
              files: [file],
            },
          }) => {
            setValue(controllerField.name, file, { shouldValidate: false });
          }}
          {...(field.type == "image" && {
            accept: "image/*",
          })}
        />
      );

    case "fileMeta":
    case "genericMeta":
    case "imageMeta":
    case "captionMeta":
    case "videoMeta":
      return ({ controllerField, setError, clearErrors }) => (
        <>
          <MediaUpload
            onUploadBegin={() => setError(field.name, { type: "custom" })}
            onUploadEnd={() => clearErrors(field.name)}
            multiple={field.multiple}
            sync={field.sync}
            {...controllerField}
            {...(field.type == "imageMeta" && {
              accept: "image/*",
            })}
            {...(field.type == "videoMeta" && {
              accept: "video/*",
            })}
            {...(field.type == "captionMeta" && {
              accept: ".vtt",
            })}
          />
        </>
      );

    case "choice":
      // TODO: Implement non-enum select
      return field.enumType
        ? field.control === "select"
          ? ({ controllerField, ...props }) => (
              <EnumSelect
                type={field.enumType}
                multiple={field.multiple}
                filterOptions={field.filterOptions?.(props)}
                {...controllerField}
              />
            )
          : field.multiple
          ? ({ controllerField, ...props }) => (
              <EnumCheckbox
                type={field.enumType}
                multiple={field.multiple}
                filterOptions={field.filterOptions?.(props)}
                direction={field.direction}
                {...controllerField}
              />
            )
          : ({ controllerField, ...props }) => (
              <EnumRadio
                type={field.enumType}
                direction={field.direction}
                filterOptions={field.filterOptions?.(props)}
                {...controllerField}
              />
            )
        : ({ controllerField }) => (
            <Radio.Group {...controllerField}>
              {field.options.map(({ value, label, labelKey }, i) => (
                <Radio key={i} value={value}>
                  {label ?? t(labelKey)}
                </Radio>
              ))}
            </Radio.Group>
          );

    case "object":
      return (props) => {
        const resource = resources[resolveCallable(field.resource, props)];
        const SelectComponent = field.selectComponent || resource?.selectComponent;
        return SelectComponent ? (
          <SelectComponent
            multiple={field.multiple}
            baseFilter={resolveCallable(field.baseFilter, props)}
            {...field.extraProps}
            {...props.controllerField}
          />
        ) : null;
      };
    case "nestedObject":
      return (props) => {
        const resource = resources[resolveCallable(field.resource, props)];
        const SelectComponent = field.selectComponent || resource?.selectComponent;

        props.controllerField.value =
          resource.keyGetter(props.controllerField.value) ??
          props.controllerField.value;
        return SelectComponent ? (
          <SelectComponent
            multiple={field.multiple}
            baseFilter={resolveCallable(field.baseFilter, props)}
            {...props.controllerField}
          />
        ) : null;
      };

    case "translatable":
      return ({ controllerField }) => {
        return (
          <TranslatableContentEditor
            rows={field.rows}
            markdown={field.markdown}
            html={field.html}
            {...controllerField}
          />
        );
      };

    case "translatableFiles":
      return ({ controllerField, setError, clearErrors }) => {
        return (
          <TranslatableFilesEditor
            onUploadBegin={() => setError(field.name, { type: "custom" })}
            onUploadEnd={() => clearErrors(field.name)}
            multiple={field.multiple}
            sync={field.sync}
            {...((field.fileMeta ?? "imageMeta") == "imageMeta" && {
              accept: "image/*",
            })}
            {...(field.fileMeta == "videoMeta" && {
              accept: "video/*",
            })}
            {...(field.fileMeta == "captionMeta" && {
              accept: ".vtt",
            })}
            {...controllerField}
          />
        );
      };

    case "range":
      return ({ controllerField }) => <RangeInput {...controllerField} />;
  }
};

// ==

export const buildField = (originalField, resource, resources, t) => {
  const field = isString(originalField)
    ? { name: originalField }
    : { ...originalField };

  field.id ??= uuidv4();

  field.type ??= inferFieldType(field, resource, resources, t);

  field.label ??= inferFieldLabel(field, resource, resources, t);

  if (!isContainerField(field)) {
    field.getter ??= inferFieldGetter(field, resource, resources, t);

    field.permission ??= inferFieldPermission(field, resource, resources, "read", t);

    field.render ??= inferFieldRender(field, resource, resources, t);
  }

  return field;
};

export const buildFormField = (originalField, anchor, ...args) => {
  const [t, resource, resources, fieldPreProcessor, fieldPostProcessor] = args;

  let field = isString(originalField) ? { name: originalField } : { ...originalField };

  field = fieldPreProcessor(field);

  field.id ??= field.type === "fieldset" ? anchor : uuidv4();

  field.label ??= inferFieldLabel(field, resource, resources, t);

  if (!isContainerField(field)) {
    field.type ??= inferFieldType(field, resource, resources, t);

    field.getter ??= inferFormFieldGetter(field, resource, resources, t);

    field.permission ??= inferFieldPermission(field, resource, resources, "update", t);

    field.normalizer ??= inferFormFieldNormalizer(field, resource, resources, t);

    field.render ??= inferFormFieldRender(field, resource, resources, t);
  }

  if (field.fields) {
    field.fields = field.fields.map((subField, index) =>
      buildFormField(subField, `${anchor}.${index}`, ...args),
    );
  }

  field = fieldPostProcessor(field);
  return field;
};

// ==

// `meta` resolves to antd.Table.ColumnProps for list
// `meta` resolves to the original field definition for detail
const wrapRenderer = (field, resource, renderer, listRenderer) => (meta, obj) => {
  const defaultProps = { obj, meta, field };
  const value = field.getter(obj);
  const values = isArray(value) ? value : !isEmpty(value) ? [value] : [];
  const direction = field.spaceDirection ?? "vertical";
  const size = field.spaceSize ?? 0;
  const wrap = field.spaceWrap ?? true;
  const align = field.spaceAlign ?? "start";

  if (isEmpty(value)) {
    return field.empty;
  }

  listRenderer ??= ({ values, ...props }) =>
    values.map((value, i) => renderer({ value, key: i, ...props }));

  return (
    <Space
      size={size}
      direction={direction}
      wrap={wrap}
      align={align}
      style={{ display: "block" }}
    >
      {field.primary ? (
        <Link to={resource.detailUrlGetter(obj)}>
          {listRenderer({ values, ...defaultProps })}
        </Link>
      ) : (
        listRenderer({ values, ...defaultProps })
      )}
    </Space>
  );
};

const normalizeMetaField = (value) =>
  value
    ? isArray(value)
      ? value.map(normalizeMetaField)
      : pick(value, [
          "key",
          "mime",
          "size",
          "width",
          "height",
          "duration",
          "bitrate",
          "m3u8Key",
          "thumbnailKey",
        ])
    : value;
