import { gql } from "@apollo/client";

import { ActivateButton } from "../../components/actions";
import { DetailPage } from "../../components/pages";
import { Can } from "../../components/permission";

export const EventV2NoticeDetail = () => (
  <DetailPage
    resource="eventV2Notice"
    fields={[
      {
        name: "id",
      },
      {
        name: "bodyContent",
        labelKey: "admin.field.body",
        type: "translatable",
        markdown: true,
      },
      {
        name: "isActive",
        type: "boolean",
      },
      ...DetailPage.fields.canActivate,
      ...DetailPage.fields.tracked,
    ]}
    query={QUERY}
    extraActions={({ obj, refetch, resource }) => {
      return (
        <>
          <Can
            action={obj.isActive ? "inactivate" : "activate"}
            targetType={resource.typeName}
            targetId={obj.id}
          >
            <ActivateButton
              targetType={resource.typeName}
              targetId={obj.id}
              value={obj.isActive}
              onChange={() => refetch()}
            ></ActivateButton>
          </Can>
        </>
      );
    }}
  />
);

// ==

const QUERY = gql`
  query eventV2NoticeForEventV2NoticeDetail($id: ID!) {
    eventV2Notice(filter: { id_Overlap: [$id] }) {
      id
      body
      bodyContent {
        ...TranslatableContentFields
      }
      ...TrackedFields
      ...CanActivateFields
    }
  }
  ${DetailPage.fragments.tracked}
  ${DetailPage.fragments.canActivate}
  ${DetailPage.fragments.translatableContent}
`;
