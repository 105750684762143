import { gql } from "@apollo/client";

import { DetailPage } from "../../components/pages";

export const ScheduleDetail = () => (
  <DetailPage
    resource="schedule"
    fields={[
      {
        name: "id",
      },
      {
        name: "group",
        type: "object",
        resource: "group",
      },
      {
        name: "titleContent",
        labelKey: "admin.field.title",
        type: "translatable",
      },
      {
        name: "bodyContent",
        labelKey: "admin.field.body",
        type: "translatable",
      },
      {
        name: "location",
      },
      {
        name: "artists",
        type: "object",
        resource: "artist",
      },
      {
        name: "category",
        type: "choice",
        enumType: "ScheduleCategory",
      },
      {
        name: "scheduledAt",
        type: "datetime",
      },
      {
        name: "status",
        type: "choice",
        enumType: "ScheduleStatus",
        filterKey: "status_Overlap",
      },
      ...DetailPage.fields.canActivate,
      {
        name: "activateAt",
        type: "datetime",
      },
      ...DetailPage.fields.tracked,
    ]}
    query={QUERY}
    extraActions={DetailPage.extraActions.canActivate}
  />
);

// ==

const QUERY = gql`
  query scheduleForScheduleDetail($id: ID!) {
    schedule(filter: { id_Overlap: [$id] }) {
      id
      group {
        id
        name
      }
      title
      titleContent {
        ...TranslatableContentFields
      }
      body
      bodyContent {
        ...TranslatableContentFields
      }
      location
      artists {
        id
        name
      }
      category
      scheduledAt
      status
      ...TrackedFields
      ...CanActivateFields
    }
  }
  ${DetailPage.fragments.tracked}
  ${DetailPage.fragments.canActivate}
  ${DetailPage.fragments.translatableContent}
`;
