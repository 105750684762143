import { gql } from "@apollo/client";

import { FormPage } from "../../components/pages";

export const AppVersionForm = () => (
  <FormPage
    resource="appVersion"
    fields={[
      {
        name: "revision",
      },
      {
        name: "platform",
        type: "choice",
        enumType: "PlatformCategory",
      },
    ]}
    query={QUERY}
    createMutation={CREATE_MUTATION}
    updateMutation={UPDATE_MUTATION}
  />
);

// ==

const FORM_FIELDS_FRAGMENTS = gql`
  fragment AppVersionFormFields on AppVersion {
    id
    revision
    platform
  }
`;

const QUERY = gql`
  query appVersionForAppVersionForm($id: ID!) {
    appVersion(filter: { id_Overlap: [$id] }) {
      ...AppVersionFormFields
    }
  }
  ${FORM_FIELDS_FRAGMENTS}
`;

const CREATE_MUTATION = gql`
  mutation createAppVersionForAppVersionForm($data: AppVersionMutationInput!) {
    createAppVersion(data: $data) {
      ok
      errors {
        ...MutationErrorFields
      }
      object {
        ...AppVersionFormFields
      }
    }
  }
  ${FormPage.fragments.mutationError}
  ${FORM_FIELDS_FRAGMENTS}
`;

const UPDATE_MUTATION = gql`
  mutation updateAppVersionForAppVersionForm(
    $data: AppVersionMutationInput!
    $id: ID!
  ) {
    updateAppVersion(data: $data, id: $id, partial: true) {
      ok
      errors {
        ...MutationErrorFields
      }
      object {
        ...AppVersionFormFields
      }
    }
  }
  ${FormPage.fragments.mutationError}
  ${FORM_FIELDS_FRAGMENTS}
`;
