import { useRef, useState } from "react";

import { Modal, Typography } from "antd";

import { useTranslate } from "@/hooks/i18n";
import type { BuiltFlatField } from "@/types/field";
import type { FieldConfirmation } from "@/types/field/FieldConfirmation";
import type { RecordType } from "@/types/primitives";
import type { PickRequired } from "@/types/utils";
import { waitForModal } from "@/utils/field";

function shouldFieldBeConfirmed(
  field: BuiltFlatField,
  data: RecordType,
): field is PickRequired<BuiltFlatField, "confirmation"> {
  return Boolean(field.confirmation && field.confirmation.shouldConfirm(data));
}

export function useFieldConfirmations(fields: readonly BuiltFlatField[] | null) {
  const t = useTranslate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState<FieldConfirmation | null>(null);
  const modalResolve = useRef<(value: boolean) => void>();

  const confirmFields = async (data: RecordType) => {
    if (!fields) throw new Error("Fields are not loaded");
    const fieldsNeedingConfirmation = fields.filter((field) =>
      shouldFieldBeConfirmed(field, data),
    );
    for (const field of fieldsNeedingConfirmation) {
      const confirmation = field.confirmation;
      setModalContent(confirmation);
      setIsModalOpen(true);

      const confirmed = await waitForModal(modalResolve);
      setIsModalOpen(false);
      if (!confirmed) return false;
    }
    return true;
  };

  const ConfirmationModal = modalContent && (
    <Modal
      title={t(modalContent.title ?? "admin.common.confirmation")}
      open={isModalOpen}
      onOk={() => modalResolve.current?.(true)}
      onCancel={() => modalResolve.current?.(false)}
      okText={t(modalContent.confirmLabelKey ?? "admin.common.confirm")}
      cancelText={t("admin.common.cancel")}
    >
      <Typography.Text>{t(modalContent.message)}</Typography.Text>
    </Modal>
  );

  return { confirmFields, ConfirmationModal };
}
