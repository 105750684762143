import { forwardRef } from "react";
import { useTranslation } from "react-i18next";

import { TranslatableContentEditor } from "./TranslatableContentEditor";

export const EventV2WinnerNoticeEditor = forwardRef(
  ({ value, onChange, readOnly, disabled }, ref) => {
    const { t } = useTranslation();

    return (
      <TranslatableContentEditor
        ref={ref}
        rows={10}
        markdown
        value={value?.bodyContent || {}}
        readOnly={readOnly}
        disabled={disabled}
        onChange={(newValue) => onChange({ bodyContent: newValue })}
        label={t("admin.field.winnerNotice")}
      />
    );
  },
);
