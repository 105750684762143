import { gql } from "@apollo/client";

import { ListWidget } from "../list";

export const PollListWidget = (props) => (
  <ListWidget
    resource="poll"
    fields={[
      {
        name: "id",
      },
      {
        name: "group",
        type: "object",
        resource: "group",
        filterKey: "group_Overlap",
      },
      {
        name: "title",
        filterKey: "title_Text",
      },
      {
        name: "member",
        labelKey: "admin.field.nickname",
        type: "object",
        resource: "member",
        filterKey: "member_Overlap",
      },
      {
        name: "isOpen",
        type: "boolean",
        filterKey: "isOpen_Exact",
      },
      {
        name: "closeAt",
        type: "datetime",
      },
      {
        name: "optionCount",
        type: "integer",
        filterKey: "optionCount_Range",
        sortKey: "OPTION_COUNT",
      },
      {
        name: "voteCount",
        type: "integer",
        filterKey: "voteCount_Range",
        sortKey: "VoTE_COUNT",
      },
      {
        name: "commentCount",
        type: "integer",
        filterKey: "commentCount_Range",
        sortKey: "COMMENT_COUNT",
      },
      {
        name: "likeCount",
        type: "integer",
        filterKey: "likeCount_Range",
        sortKey: "LIKE_COUNT",
      },
      {
        name: "status",
        type: "choice",
        enumType: "PollStatus",
        filterKey: "status_Overlap",
      },
      ...ListWidget.fields.timestamped,
    ]}
    query={QUERY}
    extraRowActions={ListWidget.extraRowActions.canActivate}
    hideCreate
    hideUpdate
    {...props}
  />
);

// ==

const QUERY = gql`
  query pollsForPollListWidget(
    $filter: PollFilterInput
    $sort: [PollSortInput]
    $page: PageInput
  ) {
    polls(filter: $filter, sort: $sort, page: $page) {
      objects {
        id
        group {
          id
          name
        }
        title
        member {
          id
          nickname
        }
        isOpen
        closeAt
        optionCount
        voteCount
        commentCount
        likeCount
        status
        ...TrackedFields
        ...CanActivateFields
      }
      ...ListFields
    }
  }
  ${ListWidget.fragments.list}
  ${ListWidget.fragments.tracked}
  ${ListWidget.fragments.canActivate}
`;
