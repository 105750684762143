import { forwardRef } from "react";

import { Select, Typography } from "antd";

import { default as nestedTimezones } from "../../data/timezones.json";

export const SelectTimezone = forwardRef(
  ({ value, onChange, placeholder = "-- Timezone", ...props }, ref) => {
    return (
      <Select
        showSearch
        filterOption
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        style={styles.container}
        ref={ref}
        {...props}
      >
        {timezones.map((tz, i) => (
          <Select.Option key={i} value={tz.label}>
            {tz.label}
            <Typography.Text type="secondary" style={styles.secondary}>
              {tz.offset}:00 UTC
            </Typography.Text>
          </Select.Option>
        ))}
      </Select>
    );
  },
);

// ==

const timezones = nestedTimezones.reduce((acc, tz) => {
  return acc.concat(tz.utc.map((label) => ({ offset: tz.offset, label })));
}, []);

// ==

const styles = {
  container: {
    width: "100%",
  },
  secondary: {
    marginLeft: 8,
    fontSize: "0.8em",
  },
};
