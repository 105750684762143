import { useState } from "react";
import { useTranslation } from "react-i18next";

import { gql, useLazyQuery, useMutation } from "@apollo/client";

import { Button, Modal } from "antd";

import { useApp } from "../../hooks/app";
import { LanguageOrderEditor } from "../fields";

export const LanguageOrderEditButton = ({
  onSuccess = undefined,
  onlyIcon = false,
  ...props
}) => {
  const { t } = useTranslation();
  const [fetchLanguages] = useLazyQuery(QUERY, {
    variables: {
      filter: {
        order_Exists: true,
      },
      sort: [
        {
          type: "ORDER",
          direction: "ASC",
        },
      ],
    },
    fetchPolicy: "cache-and-network",
  });
  const { message } = useApp();

  const onCompleted = (response) => {
    const { ok, errors } = Object.values(response)[0];
    if (ok) {
      message.success(t("admin.message.requestSuccess"));
      onSuccess?.();
      onCancel();
    } else {
      message.error(
        t("admin.message.requestFailure", {
          message: errors[0].messages[0],
        }),
      );
    }
  };

  const [languages, setLanguages] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [send, { loading }] = useMutation(UPDATE_MUTATION, {
    variables: {
      languages: languages.map((language) => ({
        id: language.id,
        order: language.order,
      })),
    },
    onCompleted,
  });

  // ==
  const onClick = () => {
    fetchLanguages({
      onCompleted: (response) => {
        setLanguages(response.languages.objects);
        setIsModalOpen(true);
      },
    });
  };

  const onOk = () => send();
  const onCancel = () => {
    setIsModalOpen(false);
    setLanguages([]);
  };

  const updateLanguagesFromEditor = (data) => {
    setLanguages(data);
  };

  return (
    <>
      <Button onClick={onClick} {...props}>
        {t("admin.component.languageOrderEditButton.title")}
      </Button>
      <Modal open={isModalOpen} onOk={onOk} onCancel={onCancel} destroyOnClose={true}>
        <LanguageOrderEditor value={languages} updateData={updateLanguagesFromEditor} />
      </Modal>
    </>
  );
};

const QUERY = gql`
  query languagesForLanguageOrder(
    $filter: LanguageFilterInput
    $sort: [LanguageSortInput]
  ) {
    languages(filter: $filter, sort: $sort) {
      objects {
        id
        code
        name
        nativeName
        order
      }
    }
  }
`;

const UPDATE_MUTATION = gql`
  mutation updateLanguageOrder($languages: [LanguageOrderEditInput]!) {
    updateLanguageOrder(languages: $languages) {
      ok
      errors {
        messages
      }
    }
  }
`;
