import { Divider } from "antd";
import styled from "styled-components";

export const FilterInputContainer = styled.div`
  padding: 7px 8px;
`;

export const FilterDivider = styled(Divider)`
  margin: 0;
`;

export const FilterButtonContainer = styled.div`
  padding: 7px 8px;
  display: flex;
  justify-content: space-between;
`;

export const ObjectFilterContainer = styled.div`
  min-width: 200;
`;
