import { gql } from "@apollo/client";

import { DetailPage } from "../../components/pages";

export const TranslationRequestDetail = () => (
  <DetailPage
    resource="translationRequest"
    fields={[
      {
        name: "group",
        type: "object",
        resource: "group",
      },
      {
        name: "title",
        type: "text",
      },
      {
        name: "sourceLanguage",
        type: "choice",
        enumType: "ServiceLanguage",
        control: "select",
      },
      {
        name: "targetContents",
        type: "choice",
        enumType: "ServiceLanguage",
        getter: (value) => {
          return value.targetContents.map((v) => v.targetLanguage);
        },
      },
      {
        name: "category",
        type: "choice",
        enumType: "TranslationRequestCategory",
      },
      {
        name: "inputSource",
        type: "choice",
        enumType: "TranslationRequestInputSource",
      },
      {
        name: "plainText",
        type: "text",
        rows: 10,
        hidden: ({ data }) =>
          data.inputSource === "FILE" || data.inputSource === undefined,
      },
      {
        name: "uploadFiles",
        type: "fileMeta",
        hidden: ({ data }) =>
          data.inputSource === "TEXT" || data.inputSource === undefined,
        getter: (value) =>
          value.uploadFiles ? { ...value.uploadFiles[0] } : value.uploadFiles,
      },
      {
        name: "additionalRequestComment",
        rows: 5,
        type: "text",
      },
      {
        name: "dueAt",
        type: "date",
      },
      {
        name: "additionalRecipients",
        type: "email",
      },
    ]}
    query={QUERY}
  />
);

// ==

const QUERY = gql`
  query translationRequestForTranslationRequestDetail($id: ID!) {
    translationRequest(filter: { id_Overlap: [$id] }) {
      group {
        id
      }
      requestor {
        id
      }
      category
      inputSource
      title
      sourceLanguage
      dueAt
      additionalRecipients
      additionalRequestComment
      plainText
      uploadFiles {
        key
        mime
        size
        url
      }
      targetContents {
        targetLanguage
      }
      ...TrackedFields
    }
  }
  ${DetailPage.fragments.tracked}
`;
