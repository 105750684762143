import { type ElementType, FC } from "react";
import { useTranslation } from "react-i18next";

import { Button } from "antd";

import { FilterProps } from "@/types/filter/FilterProps";
import { isEmpty } from "@/utils/helpers";

import {
  FilterButtonContainer,
  FilterDivider,
  FilterInputContainer,
  ObjectFilterContainer,
} from "./styled";

interface Props extends FilterProps {
  selectComponent: ElementType;
}

export const ObjectFilter: FC<Props> = ({
  setSelectedKeys,
  selectedKeys,
  confirm,
  clearFilters,
  // ==
  selectComponent: SelectComponent,
}) => {
  const { t } = useTranslation();
  return (
    <ObjectFilterContainer onKeyDown={(e) => e.stopPropagation()}>
      <FilterButtonContainer>
        <Button
          type="link"
          size="small"
          disabled={isEmpty(selectedKeys)}
          onClick={clearFilters}
        >
          {t("admin.common.reset")}
        </Button>
        <Button type="primary" onClick={() => confirm()} size="small">
          {t("admin.common.ok")}
        </Button>
      </FilterButtonContainer>
      <FilterDivider />
      <FilterInputContainer>
        <SelectComponent
          multiple
          value={selectedKeys}
          onChange={(values) => setSelectedKeys(values)}
        />
      </FilterInputContainer>
    </ObjectFilterContainer>
  );
};
