import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { gql, useMutation, useQuery } from "@apollo/client";

import { DownOutlined, SyncOutlined, UserOutlined } from "@ant-design/icons";
import {
  Layout as AntdLayout,
  Avatar,
  Button,
  Dropdown,
  Grid,
  Modal,
  Space,
  Switch,
  Typography,
  theme,
} from "antd";

import dayjs from "dayjs";

import config from "../../../config";
import { useApp } from "../../../hooks/app";
import { useAuthentication } from "../../../hooks/auth";
import { useTimezone } from "../../../hooks/tz";
import {
  AUTHORIZATION_FRAGMENT,
  MUTATION_ERROR_FRAGMENT,
} from "../../../utils/gql/fragments";
import { SelectTimezone } from "../../selects";

const { Text } = Typography;
const { useToken } = theme;

export const Header = ({ fixed = false }) => {
  const { token } = useToken();

  const breakpoint = Grid.useBreakpoint();
  const isMobile = typeof breakpoint.lg === "undefined" ? false : !breakpoint.lg;

  return (
    <AntdLayout.Header
      style={{
        ...styles.container,
        backgroundColor: token.colorBgElevated,
        ...(fixed && {
          position: "fixed",
          top: 0,
          left: 0,
          zIndex: 999,
        }),
      }}
    >
      <Space size="small">
        {!isMobile && <ColorModeSwitch />}
        <TimezoneDropdown isMobile={isMobile} />
        <LanguageDropdown isMobile={isMobile} />
        <ProfileDropdown isMobile={isMobile} />
      </Space>
    </AntdLayout.Header>
  );
};

// ==

const ColorModeSwitch = () => {
  const { colorMode, toggleColorMode } = useApp();

  return (
    <Switch
      checked={colorMode === "dark"}
      onChange={toggleColorMode}
      checkedChildren={<IconSpan>🌛</IconSpan>}
      unCheckedChildren={<IconSpan>🔆</IconSpan>}
    />
  );
};

const TimezoneDropdown = ({ isMobile }) => {
  const { timezone, setTimezone } = useTimezone();
  const [selectedTimezone, setSelectedTimezone] = useState(timezone);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [now, setNow] = useState(dayjs());

  useEffect(() => {
    const intervalId = setInterval(() => setNow(dayjs()), 1000);
    return () => clearInterval(intervalId);
  }, []);

  return (
    <>
      <Dropdown
        menu={{
          items: [
            {
              key: "edit",
              label: <a onClick={() => setIsModalOpen(true)}>Change</a>,
            },
          ],
        }}
      >
        <Button type="text">
          <Space size="small">
            <Text strong>{timezone}</Text>
            {!isMobile ? (
              <Text type="secondary" style={styles.time}>
                {now.tz(timezone).format("YYYY-MM-DD HH:mm:ss")}
              </Text>
            ) : null}
            <DownOutlined />
          </Space>
        </Button>
      </Dropdown>
      <Modal
        title="Change Time Zone"
        open={isModalOpen}
        okText="Change"
        onOk={() => {
          setTimezone(selectedTimezone);
          setIsModalOpen(false);
        }}
        onCancel={() => setIsModalOpen(false)}
      >
        <SelectTimezone
          value={selectedTimezone}
          onChange={(value) => setSelectedTimezone(value)}
        />
      </Modal>
    </>
  );
};

const LanguageDropdown = ({ isMobile }) => {
  const { i18n } = useTranslation();
  const { data } = useQuery(LANGUAGE_QUERY);
  const [updateLanguage] = useMutation(LANGUAGE_MUTATION);

  if (!data) {
    return null;
  }

  // ==

  const getFlagUrl = (code) => {
    const flagCode = { en: "us", ko: "kr" }[code];
    return `https://raw.githubusercontent.com/lipis/flag-icons/main/flags/4x3/${flagCode}.svg`;
  };
  const changeLanguage = ({ code, id }) => {
    i18n.changeLanguage(code);
    dayjs.locale(code);
    updateLanguage({ variables: { language: id } });
  };

  const languages = data.allLanguages
    .filter(({ code }) => config.supportedLanguages.includes(code))
    .map(({ id, code, nativeName }) => ({ id, code, name: nativeName }));
  const language = i18n.resolvedLanguage;
  const languageName = languages.find(({ code }) => code === language)?.name;
  const items = languages.map((language) => ({
    key: language.code,
    onClick: () => changeLanguage(language),
    icon: (
      <span style={{ marginRight: 8 }}>
        <Avatar size={16} src={getFlagUrl(language.code)} />
      </span>
    ),
    label: language.name,
  }));

  return (
    <Dropdown menu={{ items }}>
      <Button type="text">
        <Space>
          <Avatar size={16} src={getFlagUrl(language)} />
          {!isMobile ? languageName : ""}
          <DownOutlined />
        </Space>
      </Button>
    </Dropdown>
  );
};

const ProfileDropdown = ({ isMobile }) => {
  const { user, logout } = useAuthentication();
  const { t } = useTranslation();
  const { colorMode, toggleColorMode } = useApp();

  const items = [];
  if (isMobile) {
    items.push({
      key: "colormode",
      label: `Switch ${colorMode === "dark" ? "Light" : "Dark"}`,
      icon: <SyncOutlined />,
      onClick: () => {
        toggleColorMode();
      },
    });
  }

  items.push({
    key: "logout",
    label: t("admin.common.logout"),
    onClick: logout,
    icon: <UserOutlined />,
    danger: true,
  });

  return (
    <Dropdown
      menu={{
        items: items,
      }}
    >
      <div style={styles.profileDropdownBody}>
        <Space>
          <Avatar icon={<UserOutlined />} />
          <Text strong>{user.name ?? user.email}</Text>
        </Space>
      </div>
    </Dropdown>
  );
};

const IconSpan = ({ children }) => {
  return <span style={{ marginRight: 8 }}>{children}</span>;
};

// ==

const LANGUAGE_QUERY = gql`
  query allLanguagesForHeader {
    allLanguages(filter: { isActiveForService_Exact: true }) {
      id
      code
      nativeName
    }
  }
`;

const LANGUAGE_MUTATION = gql`
  mutation updateMeForHeader($language: ID!) {
    updateMe(data: { serviceLanguage: $language, translationLanguage: $language }) {
      ok
      errors {
        ...MutationErrorFields
      }
      authorization {
        ...AuthorizationFields
      }
    }
  }
  ${MUTATION_ERROR_FRAGMENT}
  ${AUTHORIZATION_FRAGMENT}
`;

// ==

const styles = {
  container: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    padding: "0px 24px",
    height: "44px",
    width: "100%",
  },
  time: {
    fontFamily: "monospace",
    fontSize: ".85em",
  },
  secondary: {
    fontSize: ".9em",
  },
  profileDropdownBody: {
    cursor: "pointer",
    height: "30px",
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    justifyContent: "center",
    alignItems: "center",
  },
};
