import { gql } from "@apollo/client";

import { DuplicateMediaLayoutButton } from "../../components/actions";
import { ListWidget } from "../list";

export const MediaLayoutListWidget = (props) => (
  <ListWidget
    resource="mediaLayout"
    fields={[
      {
        name: "id",
      },
      {
        name: "group",
        type: "object",
        resource: "group",
        filterKey: "group_Overlap",
      },
      {
        name: "name",
        filterKey: "name_Text",
      },
      {
        name: "status",
        type: "choice",
        enumType: "MediaLayoutStatus",
        filterKey: "status_Overlap",
      },
      {
        name: "activateAt",
        type: "datetime",
      },
      ...ListWidget.fields.tracked,
    ]}
    query={QUERY}
    extraRowActions={(props) => (
      <>
        <DuplicateMediaLayoutButton
          mediaLayoutId={props.obj.id}
          size="small"
          onlyIcon
        />
        {ListWidget.extraRowActions.canActivate(props)}
      </>
    )}
    {...props}
  />
);

// ==

const QUERY = gql`
  query mediaLayoutsForMediaLayoutListWidget(
    $filter: MediaLayoutFilterInput
    $sort: [MediaLayoutSortInput]
    $page: PageInput
  ) {
    mediaLayouts(filter: $filter, sort: $sort, page: $page) {
      objects {
        id
        group {
          id
          name
        }
        name
        status
        ...TrackedFields
        ...CanActivateFields
      }
      ...ListFields
    }
  }
  ${ListWidget.fragments.list}
  ${ListWidget.fragments.tracked}
  ${ListWidget.fragments.canActivate}
`;
