import { gql } from "@apollo/client";

import {
  MEDIA_LAYOUT_FRAGMENT,
  MUTATION_ERROR_FRAGMENT,
} from "../../utils/gql/fragments";
import { DuplicateButton } from "./DuplicateButton";

export const DuplicateMediaLayoutButton = ({
  mediaLayoutId,
  size = "middle",
  onlyIcon = false,
}) => {
  return (
    <DuplicateButton
      resourceName="mediaLayout"
      id={mediaLayoutId}
      mutation={MUTATION}
      buttonMessage="admin.component.duplicateThemeButton.message"
      size={size}
      onlyIcon={onlyIcon}
    />
  );
};
// ==

const MUTATION = gql`
  mutation duplicateMediaLayoutForDuplicateMediaLayoutButton($id: ID!) {
    duplicateMediaLayout(id: $id) {
      ok
      errors {
        ...MutationErrorFields
      }
      object {
        ...MediaLayoutFields
      }
    }
  }
  ${MEDIA_LAYOUT_FRAGMENT}
  ${MUTATION_ERROR_FRAGMENT}
`;
