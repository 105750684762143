import { gql } from "@apollo/client";

import { DetailPage } from "../../components/pages";
import {
  CommentListWidget,
  PollListWidget,
  PostListWidget,
  ScheduleListWidget,
} from "../../components/widgets";

export const ArtistDetail = () => (
  <DetailPage
    resource="artist"
    fields={[
      {
        name: "id",
      },
      {
        name: "group",
        type: "object",
        resource: "group",
      },
      {
        name: "user.email",
        labelKey: "admin.field.email",
        type: "email",
      },
      {
        name: "code",
      },
      {
        name: "nameContent",
        labelKey: "admin.field.stageName",
        type: "translatable",
      },
      {
        name: "birthDate",
        type: "date",
      },
      {
        name: "nickname",
      },
      {
        name: "profileImage",
        type: "imageMeta",
      },
      {
        name: "coverImage",
        type: "imageMeta",
      },
      ...DetailPage.fields.canActivate,
      {
        name: "user.createdAt",
        labelKey: "admin.field.joinedAt",
        type: "datetime",
      },
      {
        name: "user.lastAuthenticatedAt",
        labelKey: "admin.field.lastAuthenticatedAt",
        type: "datetime",
      },
      ...DetailPage.fields.tracked,
    ]}
    query={QUERY}
    tabs={[
      {
        path: "",
        labelKey: "admin.resource.comment.plural",
        permission: {
          action: "list",
          targetType: "COMMENT",
        },
        children: ({ obj }) => (
          <CommentListWidget
            baseFilter={{
              group_Overlap: [obj.group.id],
              member_Overlap: [obj.member.id],
            }}
            createSearchParams={{
              group: obj.group.id,
              member: obj.member.id,
            }}
            hiddenFields={["group", "member", "isArtist"]}
          />
        ),
      },
      {
        path: "posts",
        labelKey: "admin.resource.post.plural",
        permission: {
          action: "list",
          targetType: "POST",
        },
        children: ({ obj }) => (
          <PostListWidget
            baseFilter={{
              group_Overlap: [obj.group.id],
              member_Overlap: [obj.member.id],
            }}
            createSearchParams={{
              group: obj.group.id,
              member: obj.member.id,
            }}
            hiddenFields={["group", "member", "isArtist", "hasPremiums", "category"]}
          />
        ),
      },
      {
        path: "polls",
        labelKey: "admin.resource.poll.plural",
        permission: {
          action: "list",
          targetType: "POLL",
        },
        children: ({ obj }) => (
          <PollListWidget
            baseFilter={{
              group_Overlap: [obj.group.id],
              member_Overlap: [obj.member.id],
            }}
            createSearchParams={{
              group: obj.group.id,
              member: obj.member.id,
            }}
            hiddenFields={["group", "member"]}
          />
        ),
      },
      {
        path: "schedules",
        labelKey: "admin.resource.schedule.plural",
        permission: {
          action: "list",
          targetType: "SCHEDULE",
        },
        children: ({ obj }) => (
          <ScheduleListWidget
            baseFilter={{
              artists_Overlap: [obj.id],
            }}
            createSearchParams={{
              group: obj.group.id,
              artists: [obj.id],
            }}
            hiddenFields={["group"]}
          />
        ),
      },
    ]}
  />
);

// ==

const QUERY = gql`
  query artistForArtistDetail($id: ID!) {
    artist(filter: { id_Overlap: [$id] }) {
      id
      group {
        id
        name
      }
      user {
        id
        email
        createdAt
        lastAuthenticatedAt
      }
      member {
        id
      }
      code
      name
      nameContent {
        ...TranslatableContentFields
      }
      birthDate
      nickname
      profileImage {
        ...DetailImageFields
      }
      coverImage {
        ...DetailImageFields
      }
      ...TrackedFields
      ...CanActivateFields
    }
  }
  ${DetailPage.fragments.tracked}
  ${DetailPage.fragments.canActivate}
  ${DetailPage.fragments.image}
  ${DetailPage.fragments.translatableContent}
`;
