import { ApolloClient, InMemoryCache, from } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";

import { createUploadLink } from "apollo-upload-client";

import config from "../../config";
import possibleTypes from "./possibleTypes.json";

const createClient = () => {
  const httpLink = createUploadLink({
    uri: config.graphqlEndpoint,
  });

  const authLink = setContext((_, { headers }) => {
    const token = getLocalStorageValue(config.tokenKey, true);
    const guid = getLocalStorageValue(config.guidKey, true);
    const language = getLocalStorageValue(config.languageKey);
    return {
      headers: {
        ...headers,
        "j-context": config.defaultContext,
        "j-pagination": config.defaultPagination,
        ...(token && { authorization: `Token ${token}` }),
        ...(guid && { "j-guid": guid }),
        ...(language && { "j-language": language }),
      },
    };
  });

  const errorLink = onError(({ graphQLErrors }) => {
    // TODO: Handle networkError
    graphQLErrors?.forEach(({ extensions }) => {
      if (config.resetAuthErrorCodes.includes(extensions?.code as string)) {
        removeLocalStorageValue(config.tokenKey);
        client!.resetStore();
      }
    });
  });

  return new ApolloClient({
    link: from([authLink, errorLink, httpLink]),
    cache: new InMemoryCache({
      possibleTypes,
      typePolicies: {
        Authorization: {
          keyFields: [],
        },
      },
    }),
  });
};

export const getApolloClient = () => {
  if (!client) {
    client = createClient();
  }
  return client;
};

// ==

let client: ReturnType<typeof createClient> | null = null;

const getLocalStorageValue = (key: string, json = false) => {
  const value = window.localStorage.getItem(key);
  return value ? (json ? JSON.parse(value) : value) : undefined;
};

const removeLocalStorageValue = (key: string) => {
  window.localStorage.removeItem(key);
};
