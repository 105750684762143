import { gql } from "@apollo/client";

import { ActivateButton } from "../../components/actions";
import { ShareURLButton } from "../../components/actions";
import { DetailPage } from "../../components/pages";
import { Can } from "../../components/permission";
import config from "../../config";

export const NoticeDetail = () => (
  <DetailPage
    resource="notice"
    fields={[
      {
        name: "id",
      },
      {
        name: "group",
        type: "object",
        resource: "group",
      },
      {
        name: "titleContent",
        labelKey: "admin.field.title",
        type: "translatable",
      },
      {
        name: "bodyContent",
        labelKey: "admin.field.body",
        type: "translatable",
        markdown: true,
      },
      {
        name: "isHighlighted",
        type: "boolean",
      },
      {
        name: "isAd",
        type: "boolean",
      },
      {
        name: "status",
        type: "choice",
        enumType: "NoticeStatus",
      },
      ...DetailPage.fields.canActivate,
      {
        name: "activateAt",
        type: "datetime",
      },
      ...DetailPage.fields.tracked,
    ]}
    query={QUERY}
    extraActions={({ obj, refetch, resource }) => {
      return (
        <>
          <ShareURLButton
            url={`${config.missaEndpoint}/community/${obj.group.code}/notice/${obj.id}`}
          />
          <Can
            action={obj.isActive ? "inactivate" : "activate"}
            targetType={resource.typeName}
            targetId={obj.id}
          >
            <ActivateButton
              targetType={resource.typeName}
              targetId={obj.id}
              value={obj.isActive}
              onChange={() => refetch()}
            ></ActivateButton>
          </Can>
        </>
      );
    }}
  />
);

// ==

const QUERY = gql`
  query noticeForNoticeDetail($id: ID!) {
    notice(filter: { id_Overlap: [$id] }) {
      id
      group {
        id
        name
        code
      }
      title
      titleContent {
        ...TranslatableContentFields
      }
      body
      bodyContent {
        ...TranslatableContentFields
      }
      isHighlighted
      isAd
      status
      ...TrackedFields
      ...CanActivateFields
    }
  }
  ${DetailPage.fragments.tracked}
  ${DetailPage.fragments.canActivate}
  ${DetailPage.fragments.translatableContent}
`;
