import { useMemo } from "react";

import { useApp } from "@/hooks/app";
import { ResourceName } from "@/resources";

/**
 * Get resource info and helpers
 */
export const useResource = (resourceName: ResourceName) => {
  const { resources } = useApp();
  const resource = useMemo(() => resources[resourceName], [resourceName]);
  return useMemo(
    () => ({
      resource,
      hasForm: "formComponent" in resource && Boolean(resource.formComponent),
      hasList: "listComponent" in resource && Boolean(resource.listComponent),
      hasDetail: "detailComponent" in resource && Boolean(resource.detailComponent),
    }),
    [resource],
  );
};
