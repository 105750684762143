import type { DocumentNode, TypedDocumentNode } from "@apollo/client";

import type { Prettify, StrictOmit } from "ts-essentials";

import { MutationDrawerForm, type MutationDrawerFormProps } from "@/components/form";
import { FormPage } from "@/components/pages";
import type { GqlMutationData } from "@/gql/types";
import { useFormFields } from "@/hooks/fields";
import type { ResourceName } from "@/resources";
import type { Field } from "@/types/field";
import type { FormRequestLike, FormResponseLike } from "@/types/form";
import type { RecordType } from "@/types/primitives";

type Props<
  Mutation extends DocumentNode,
  FormValue extends GqlMutationData<Mutation>,
> = Prettify<
  StrictOmit<MutationDrawerFormProps<Mutation, FormValue>, "meta" | "id"> & {
    resource: ResourceName;
    fields: Field<FormValue>[];
    obj: RecordType;
    onChange: (data: FormValue) => void;
  }
>;

/**
 * MutationDrawerForm but using mutation as validation.
 */
export const InlineFormDrawer = <
  Mutation extends TypedDocumentNode<FormResponseLike, FormRequestLike>,
  FormValue extends GqlMutationData<Mutation>,
>({
  resource,
  fields,
  obj,
  onChange,
  ...props
}: Props<Mutation, FormValue>) => {
  const meta = useFormFields<FormValue>({
    resource,
    fields,
    obj,
    forceFieldset: false,
  });

  if (!meta.fields) return null;

  return (
    <MutationDrawerForm
      nested
      meta={meta}
      successMessage={null}
      {...props}
      onSuccessRequest={onChange ?? props.onSuccessRequest}
    />
  );
};

// ==

InlineFormDrawer.fragments = FormPage.fragments;
InlineFormDrawer.fields = FormPage.fields;
