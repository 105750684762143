import { useEffect, useState } from "react";

export type DrawerProps = {
  open: boolean;
  onClose: () => void;
  afterOpenChange: (
    open: boolean,
    context?: {
      /** @deprecated avoid using this pattern */
      setFocus?: (name: string) => void;
    },
  ) => void;
};

export type DrawerState = {
  isOpen: boolean;
  doesRender: boolean;
  openDrawer: () => void;
  closeDrawer: () => void;
  drawerProps: DrawerProps;
};

export const useDrawerState = (): DrawerState => {
  const [isOpen, setIsOpen] = useState(false);
  const [doesRender, setDoesRender] = useState(false);

  useEffect(() => {
    if (isOpen && !doesRender) {
      setDoesRender(true);
    }
  }, [isOpen, doesRender]);

  return {
    isOpen,
    doesRender,
    openDrawer: () => setIsOpen(true),
    closeDrawer: () => setIsOpen(false),
    drawerProps: {
      open: isOpen,
      onClose: () => setIsOpen(false),
      afterOpenChange: (open) => !open && setDoesRender(false),
    },
  };
};
