import { gql } from "@apollo/client";

import { ListWidget } from "../list";

export const SubcontinentListWidget = (props) => (
  <ListWidget
    resource="subcontinent"
    fields={[
      {
        name: "id",
        width: "15%",
      },
      {
        name: "continent",
        type: "object",
        resource: "continent",
        filterKey: "continent_Overlap",
      },
      {
        name: "code",
        filterKey: "code_Exact",
        sortKey: "CODE",
        width: "15%",
      },
      {
        name: "name",
        filterKey: "name_Text",
        sortKey: "NAME",
      },
      {
        name: "countryCount",
        type: "integer",
        filterKey: "countryCount_Range",
        sortKey: "COUNTRY_COUNT",
      },
      ...ListWidget.fields.canActivate,
    ]}
    query={QUERY}
    sort={[
      {
        type: "CODE",
        direction: "ASC",
      },
    ]}
    extraRowActions={ListWidget.extraRowActions.canActivate}
    {...props}
  />
);

// ==

const QUERY = gql`
  query subcontinentsForSubcontinentListWidget(
    $filter: SubcontinentFilterInput
    $sort: [SubcontinentSortInput]
    $page: PageInput
  ) {
    subcontinents(filter: $filter, sort: $sort, page: $page) {
      objects {
        id
        continent {
          id
          name
        }
        code
        name
        countryCount
        ...CanActivateFields
      }
      ...ListFields
    }
  }
  ${ListWidget.fragments.list}
  ${ListWidget.fragments.canActivate}
`;
