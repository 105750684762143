import { useCallback } from "react";

import { type DocumentNode, type TypedDocumentNode, useQuery } from "@apollo/client";

import type { Prettify, StrictOmit } from "ts-essentials";

import { MutationDrawerForm, type MutationDrawerFormProps } from "@/components/form";
import { FormPage } from "@/components/pages";
import type { GqlMutationData } from "@/gql/types";
import { useRevalidateQueryFields } from "@/hooks/apollo";
import { useFormFields } from "@/hooks/fields";
import { useResource } from "@/hooks/resource";
import type { ResourceName } from "@/resources";
import type { Field } from "@/types/field";
import type { FormRequestLike, FormResponseLike } from "@/types/form";
import type { RecordType } from "@/types/primitives";
import { capitalize } from "@/utils/helpers";

type Props<
  Q extends DocumentNode,
  C extends TypedDocumentNode<FormResponseLike, FormRequestLike>,
  U extends TypedDocumentNode<FormResponseLike, FormRequestLike>,
  FormValue extends GqlMutationData<C> | GqlMutationData<U>,
> = Prettify<
  StrictOmit<MutationDrawerFormProps<C | U, FormValue>, "meta" | "mutation"> & {
    resource: ResourceName;
    query: Q;
    createMutation: C;
    updateMutation: U;
    fields: Field<FormValue>[];
  }
>;

/**
 * Create or update document in a drawer
 */
export const FormDrawer = <
  Q extends DocumentNode,
  C extends TypedDocumentNode<FormResponseLike, FormRequestLike>,
  U extends TypedDocumentNode<FormResponseLike, FormRequestLike>,
  FormValue extends GqlMutationData<C> | GqlMutationData<U>,
>({
  id,
  resource: resourceName,
  fields: originalFields,
  query,
  createMutation,
  updateMutation,
  defaultValues: originalDefaultValues,
  onSuccess: originalOnSuccess,
  ...props
}: Props<Q, C, U, FormValue>) => {
  const { resource } = useResource(resourceName);
  const isUpdate = !!id;

  const { data } = useQuery(query, {
    skip: !isUpdate,
    variables: { id },
  });
  const revalidate = useRevalidateQueryFields(
    resource.typePlural,
    `all${capitalize(resource.typePlural)}`,
  );
  const obj = data ? (Object.values(data)[0] as RecordType) : null;

  const meta = useFormFields({
    resource: resourceName,
    fields: originalFields,
    obj,
    forceFieldset: false,
    defaultValues: originalDefaultValues,
  });
  const { fields } = meta;
  const mutation = isUpdate ? updateMutation : createMutation;
  const handleSuccess = useCallback(
    (object: RecordType) => {
      if (!isUpdate) revalidate();
      originalOnSuccess?.(object);
    },
    [isUpdate, originalOnSuccess, revalidate],
  );

  if (isUpdate && (!obj || !fields)) {
    return null;
  }

  return (
    <MutationDrawerForm
      id={id}
      meta={meta}
      mutation={mutation}
      onSuccess={handleSuccess}
      {...props}
    />
  );
};

// ==

FormDrawer.fragments = FormPage.fragments;
FormDrawer.fields = FormPage.fields;
