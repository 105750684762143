import { forwardRef } from "react";

import { gql } from "@apollo/client";

import { Typography } from "antd";

import { ObjectSelect } from "../fields";

export const SelectComment = forwardRef((props, ref) => (
  <ObjectSelect
    resource="comment"
    query={QUERY}
    optionRender={(comment) => (
      <>
        {comment.body}
        <Typography.Text type="secondary" style={styles.secondary}>
          {comment.member.nickname}
        </Typography.Text>
      </>
    )}
    ref={ref}
    {...props}
  />
));

// ==

const QUERY = gql`
  query commentsForSelectComment($filter: CommentFilterInput) {
    comments(filter: $filter, sort: [{ type: RELEVANCE, direction: DESC }]) {
      objects {
        id
        body: bodySnippet
        member {
          id
          nickname
        }
      }
    }
  }
`;

// ==

const styles = {
  secondary: {
    marginLeft: 8,
    fontSize: "0.8em",
  },
};
