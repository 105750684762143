import { forwardRef, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { gql, useQuery } from "@apollo/client";

import { InputNumber, Space, Typography } from "antd";

import { deepEqual } from "../../utils/helpers";

export const EventV2RestrictionEditor = forwardRef(
  ({ value: { restrictions }, onChange, isPremiumOnly, ...props }, ref) => {
    const { Text } = Typography;
    const [restrictionsValue, setRestrictionsValue] = useState(restrictions || []);
    const { t } = useTranslation();
    const { data, loading, error } = useQuery(RESTRICTION_QUERY);

    useEffect(() => {
      setRestrictionsValue(restrictions || []);
    }, [restrictions]);

    useEffect(() => {
      if (restrictionsValue.length === 0) {
        return;
      }
      if (!deepEqual(restrictionsValue, restrictions)) {
        onChange?.({ restrictions: restrictionsValue });
      }
    }, [restrictionsValue, onChange, restrictions]);

    const handleInputChange = (count, category) => {
      const newRestrictions = [...restrictionsValue];
      const index = newRestrictions.findIndex(
        (restriction) => restriction.category === category,
      );
      if (index === -1) {
        newRestrictions.push({ category, count });
      } else {
        newRestrictions[index].count = count;
      }
      setRestrictionsValue(newRestrictions);
      onChange?.({ restrictions: newRestrictions });
    };

    useEffect(() => {
      if (!isPremiumOnly) {
        handleInputChange(null, "AGE");
      }
    }, [isPremiumOnly]);

    if (loading) return <p>{t("Loading...")}</p>;
    if (error)
      return (
        <p>
          {t("Error!")} {error.message}
        </p>
      );
    if (!data || !data.enumValues) return <p>{t("No data found.")}</p>;

    const enums = data.enumValues.flat();

    const generateHelpKey = (category) => {
      const key = "admin.form.guide." + category.toLowerCase() + "Restriction";
      return t(key);
    };

    const generateRestrictionComponents = (enums) => {
      return enums.map((restriction) => {
        const currentRestriction = restrictionsValue.find(
          (r) => r.category === restriction.name,
        );
        const currentValue = currentRestriction ? currentRestriction.count : null;

        if (!isPremiumOnly && restriction.name === "AGE") {
          return null;
        }

        return (
          <Space key={restriction.name}>
            <InputNumber
              value={currentValue}
              onChange={(count) => {
                handleInputChange(count, restriction.name);
              }}
              ref={ref}
              key={restriction.name}
              {...props}
            />
            <Text key={restriction.category}>{generateHelpKey(restriction.name)}</Text>
          </Space>
        );
      });
    };

    return <Space direction="vertical">{generateRestrictionComponents(enums)}</Space>;
  },
);

const RESTRICTION_QUERY = gql`
  query eventV2RestrictionCategories {
    enumValues(names: "EventV2RestrictionCategory") {
      name
      description
    }
  }
`;
