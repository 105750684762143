import { gql } from "@apollo/client";

import { DetailPage } from "../../components/pages";

export const MediaLayoutSectionDetail = () => (
  <DetailPage
    resource="mediaLayoutSection"
    fields={[
      {
        name: "id",
      },
      {
        name: "layout",
        type: "object",
        resource: "mediaLayout",
      },
      {
        name: "playlist",
        type: "object",
        resource: "playlist",
      },
      {
        name: "clip",
        type: "object",
        resource: "clip",
      },
      {
        name: "premium",
        type: "object",
        resource: "premium",
      },
      {
        name: "category",
        type: "choice",
        enumType: "MediaLayoutSectionCategory",
      },
      {
        name: "source",
        type: "choice",
        enumType: "MediaLayoutSectionSource",
      },
      {
        name: "titleContent",
        labelKey: "admin.field.title",
        type: "translatable",
      },
      {
        name: "subtitleContent",
        labelKey: "admin.field.subtitle",
        type: "translatable",
      },
      {
        name: "order",
      },
      ...DetailPage.fields.tracked,
    ]}
    query={QUERY}
    extraActions={DetailPage.extraActions.delete}
  />
);

// ==

const QUERY = gql`
  query mediaLayoutSectionForMediaLayoutSectionDetail($id: ID!) {
    mediaLayoutSection(filter: { id_Overlap: [$id] }) {
      id
      layout {
        id
        name
      }
      playlist {
        id
        name
      }
      clip {
        id
        title
      }
      premium {
        id
        name
      }
      category
      source
      title
      titleContent {
        ...TranslatableContentFields
      }
      subtitle
      subtitleContent {
        ...TranslatableContentFields
      }
      order
      ...TrackedFields
    }
  }
  ${DetailPage.fragments.tracked}
  ${DetailPage.fragments.translatableContent}
`;
