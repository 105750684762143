import { gql } from "@apollo/client";

import { FormPage } from "../../components/pages";

export const EventPostForm = () => (
  <FormPage
    resource="eventPost"
    fields={[
      {
        type: "fieldset",
        labelKey: "admin.fieldset.event",
        fields: [
          {
            name: "event",
            type: "object",
            resource: "event",
            disabled: ({ isUpdate }) => isUpdate,
          },
        ],
      },
      {
        type: "fieldset",
        labelKey: "admin.fieldset.content",
        fields: [
          {
            name: "body",
            labelKey: "admin.field.body",
            markdown: true,
            rows: 10,
          },
        ],
      },
      ...FormPage.fields.canActivate,
    ]}
    query={QUERY}
    createMutation={CREATE_MUTATION}
    updateMutation={UPDATE_MUTATION}
  />
);

// ==

const FORM_FIELDS_FRAGMENT = gql`
  fragment EventPostFormFields on EventPost {
    id
    event {
      id
    }
    body
    ...CanActivateFields
  }
  ${FormPage.fragments.canActivate}
`;

const QUERY = gql`
  query eventPostForEventPostForm($id: ID!) {
    eventPost(filter: { id_Overlap: [$id] }) {
      ...EventPostFormFields
    }
  }
  ${FORM_FIELDS_FRAGMENT}
`;

const CREATE_MUTATION = gql`
  mutation createEventPostForEventPostForm($data: EventPostMutationInput!) {
    createEventPost(data: $data) {
      ok
      errors {
        ...MutationErrorFields
      }
      object {
        ...EventPostFormFields
      }
    }
  }
  ${FormPage.fragments.mutationError}
  ${FORM_FIELDS_FRAGMENT}
`;

const UPDATE_MUTATION = gql`
  mutation updateEventPostForEventPostForm($data: EventPostMutationInput!, $id: ID!) {
    updateEventPost(data: $data, id: $id, partial: true) {
      ok
      errors {
        ...MutationErrorFields
      }
      object {
        ...EventPostFormFields
      }
    }
  }
  ${FormPage.fragments.mutationError}
  ${FORM_FIELDS_FRAGMENT}
`;
