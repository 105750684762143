import { forwardRef } from "react";

import { Input } from "antd";

export const UpperCaseInput = forwardRef(({ onChange, ...props }, ref) => {
  return (
    <Input
      onChange={(e) => onChange?.(e.target.value.toUpperCase())}
      ref={ref}
      {...props}
    />
  );
});
