import React, { forwardRef, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Col, Input, Space } from "antd";

import dayjs from "dayjs";

import { deepEqual } from "../../utils/helpers";
import { DateTimePicker } from "../fields";

export const EventV2WinnerQuestionSetEditor = forwardRef(
  ({ value: { winnerQuestionSet }, onChange, disabled, ...props }, ref) => {
    const { t } = useTranslation();
    const [inputs, $setInputs] = useState(winnerQuestionSet?.questions || []);
    const setInputs = useCallback(
      (inputs) => {
        if (!(inputs.length > 0)) setAnswerableUntil(undefined);
        $setInputs(inputs);
      },
      [$setInputs],
    );
    const [answerableUntil, setAnswerableUntil] = useState(
      winnerQuestionSet?.answerableUntil
        ? dayjs(winnerQuestionSet.answerableUntil)
        : undefined,
    );

    useEffect(() => {
      if (!winnerQuestionSet) {
        return;
      }
      const updatedQuestionSet = {
        ...winnerQuestionSet,
        answerableUntil: answerableUntil?.toISOString(),
        questions: inputs,
      };
      if (!deepEqual(updatedQuestionSet, winnerQuestionSet)) {
        onChange?.({ winnerQuestionSet: updatedQuestionSet });
      }
    }, [inputs, answerableUntil]);

    const handleInputChange = (index, key, e) => {
      const newInputs = [...inputs];
      newInputs[index][key] = e.target.value;
      setInputs(newInputs);
      onChange({
        winnerQuestionSet: {
          ...winnerQuestionSet,
          questions: newInputs,
        },
      });
    };

    const handleRemove = (index) => {
      const newInputs = [...inputs];
      newInputs.splice(index, 1);
      setInputs(newInputs);
      onChange({
        winnerQuestionSet: {
          ...winnerQuestionSet,
          questions: newInputs,
        },
      });
    };

    const handleAdd = () => {
      setInputs([
        ...inputs,
        {
          title: "",
          subtitle: "",
        },
      ]);
    };

    return (
      <Col span={20} style={styles.container}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            paddingBottom: "8px",
          }}
        >
          {t("admin.form.guide.winnerQuestions")}
        </div>
        <Space direction="vertical" size="middle" style={{ width: "100%" }}>
          {inputs.map((input, index) => (
            <div
              key={index}
              style={{ display: "flex", alignItems: "center", width: "100%" }}
            >
              <Space direction="vertical" style={{ flexGrow: 1 }}>
                <Input
                  disabled={disabled}
                  placeholder={t("admin.form.guide.winnerQuestionTitle")}
                  value={input.title}
                  onChange={(e) => handleInputChange(index, "title", e)}
                />
                <Input
                  disabled={disabled}
                  placeholder={t("admin.form.guide.winnerQuestionSubtitle")}
                  value={input.subtitle}
                  onChange={(e) => handleInputChange(index, "subtitle", e)}
                />
              </Space>
              <Button
                type="text"
                icon={<DeleteOutlined />}
                onClick={() => handleRemove(index)}
                style={{ marginLeft: "10px", alignSelf: "center" }}
              />
            </div>
          ))}
          <Button type="link" icon={<PlusOutlined />} onClick={handleAdd}>
            {t("admin.form.guide.addQuestion")}
          </Button>
          {inputs.length > 0 && (
            <DateTimePicker
              title={t("admin.form.guide.winnerQuestionsAnswerableUntil")}
              placeholder="-- Select Date --"
              value={answerableUntil}
              type="secondary"
              style={styles.smallText}
              showSeconds={false}
              onChange={(date) => setAnswerableUntil(date)}
            />
          )}
        </Space>
      </Col>
    );
  },
);

const styles = {
  container: {
    width: "100%",
  },
  smallText: {
    fontSize: "12px",
  },
};
