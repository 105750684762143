import { gql } from "@apollo/client";

import { ListPage } from "../../components/pages";

export const CountryList = () => (
  <ListPage
    resource="country"
    fields={[
      {
        name: "id",
      },
      {
        name: "continent",
        type: "object",
        resource: "continent",
        filterKey: "continent_Overlap",
      },
      {
        name: "subcontinent",
        type: "object",
        resource: "subcontinent",
        filterKey: "subcontinent_Overlap",
      },
      {
        name: "code",
        filterKey: "code_Exact",
        sortKey: "CODE",
        width: "15%",
      },
      {
        name: "name",
        filterKey: "name_Text",
        sortKey: "NAME",
      },
      {
        name: "phonePrefix",
      },
      ...ListPage.fields.canActivate,
    ]}
    query={QUERY}
    defaultSort={[
      {
        type: "CODE",
        direction: "ASC",
      },
    ]}
    extraRowActions={ListPage.extraRowActions.canActivate}
  />
);

// ==

const QUERY = gql`
  query countriesForCountryList(
    $filter: CountryFilterInput
    $sort: [CountrySortInput]
    $page: PageInput
  ) {
    countries(filter: $filter, sort: $sort, page: $page) {
      objects {
        id
        continent {
          id
          name
        }
        subcontinent {
          id
          name
        }
        code
        name
        phonePrefix
        ...CanActivateFields
      }
      ...ListFields
    }
  }
  ${ListPage.fragments.list}
  ${ListPage.fragments.canActivate}
`;
