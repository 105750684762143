import { gql } from "@apollo/client";

import { ListWidget } from "../list";

export const TranslationValueListWidget = (props) => (
  <ListWidget
    resource="translationValue"
    fields={[
      {
        name: "id",
      },
      {
        name: "code",
        type: "object",
        resource: "translationCode",
        filterKey: "code_Overlap",
      },
      {
        name: "language",
        type: "choice",
        enumType: "ServiceLanguage",
        filterKey: "language_Overlap",
      },
      {
        name: "value",
        filterKey: "value_Text",
      },
      {
        name: "status",
        type: "choice",
        enumType: "TranslationStatus",
        filterKey: "status_Overlap",
      },
      ...ListWidget.fields.tracked,
    ]}
    query={QUERY}
    extraRowActions={ListWidget.extraRowActions.delete}
    {...props}
  />
);

// ==

const QUERY = gql`
  query translationValuesForTranslationValueListWidget(
    $filter: TranslationValueFilterInput
    $sort: [TranslationValueSortInput]
    $page: PageInput
  ) {
    translationValues(filter: $filter, sort: $sort, page: $page) {
      objects {
        id
        code {
          id
          code
        }
        language
        value
        status
        ...TrackedFields
      }
      ...ListFields
    }
  }
  ${ListWidget.fragments.list}
  ${ListWidget.fragments.tracked}
`;
