import { gql } from "@apollo/client";

import { DetailPage } from "../../components/pages";
import { CommentListWidget } from "../../components/widgets";

export const ModeratedPostDetail = (props) => (
  <DetailPage
    resource="moderatedPost"
    fields={[
      {
        name: "id",
      },
      {
        name: "group",
        type: "object",
        resource: "group",
      },
      {
        name: "body",
        translatable: true,
      },
      {
        name: "attachments",
        type: "genericMeta",
        spaceDirection: "horizontal",
      },
      {
        name: "member",
        labelKey: "admin.field.nickname",
        type: "object",
        resource: "member",
      },
      {
        name: "category",
        type: "choice",
        enumType: "PostCategory",
      },
      {
        name: "premium",
        labelKey: "admin.field.premiumOnly",
        type: "boolean",
        getter: (obj) => Boolean(obj.premium),
      },
      {
        name: "moderationLabel",
        type: "text",
        getter: (obj) => {
          for (const attachment of obj.attachments || []) {
            if (
              attachment.moderationLabel !== undefined &&
              attachment.moderationLabel !== null &&
              attachment.moderationLabel !== "APPROVED"
            ) {
              return attachment.moderationLabel;
            }
          }
          return "Not Available";
        },
      },
      {
        name: "confidenceLevel",
        type: "integer",
        getter: (obj) => {
          for (const attachment of obj.attachments || []) {
            if (
              attachment.confidenceLevel !== undefined &&
              attachment.confidenceLevel !== null &&
              attachment.moderationLabel !== "APPROVED"
            ) {
              return attachment.confidenceLevel;
            }
          }
          return 0;
        },
      },

      {
        name: "attachmentCount",
        type: "integer",
      },
      {
        name: "commentCount",
        type: "integer",
      },
      {
        name: "likeCount",
        type: "integer",
      },
      {
        name: "bookmarkCount",
        type: "integer",
      },
      {
        name: "reportCount",
        type: "integer",
      },
      {
        name: "status",
        type: "choice",
        enumType: "PostStatus",
      },
      {
        name: "metaStatus",
        type: "choice",
        enumType: "MetaStatus",
      },
      ...DetailPage.fields.canHide,
      ...DetailPage.fields.canActivate,
      ...DetailPage.fields.tracked,
    ]}
    query={QUERY}
    extraActions={(props) => (
      <>
        {DetailPage.extraActions.canActivate(props)}
        {DetailPage.extraActions.canHide(props)}
        {DetailPage.extraActions.canApprove(props)}
      </>
    )}
    tabs={[
      {
        path: "",
        labelKey: "admin.resource.comment.plural",
        permission: {
          action: "list",
          targetType: "COMMENT",
        },
        children: ({ obj }) => (
          <CommentListWidget
            baseFilter={{
              group_Overlap: [obj.group.id],
              targetType_Overlap: ["POST"],
              targetId_Overlap: [obj.id],
            }}
            createSearchParams={{
              group: obj.group.id,
              targetType: "POST",
              targetId: obj.id,
            }}
            hiddenFields={["group", "targetType", "target"]}
          />
        ),
      },
    ]}
    hideUpdate
    {...props}
  />
);

// ==

const QUERY = gql`
  query postForModeratedPostDetail($id: ID!) {
    post(filter: { id_Overlap: [$id] }) {
      id
      group {
        id
        name
      }
      member {
        id
        nickname
        artist {
          id
        }
      }
      premium {
        id
      }
      category
      body
      status
      metaStatus
      attachments {
        ...DetailMediaFields
      }
      attachmentCount
      commentCount
      likeCount
      bookmarkCount
      reportCount
      ...TrackedFields
      ...CanActivateFields
      ...CanHideFields
    }
  }
  ${DetailPage.fragments.tracked}
  ${DetailPage.fragments.canActivate}
  ${DetailPage.fragments.canHide}
  ${DetailPage.fragments.media}
`;
