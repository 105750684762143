import { gql } from "@apollo/client";

import { SyncGroupClipsButton } from "../../components/actions";
import { ListPage, ListPageWithGroup } from "../../components/pages";

export const PlaylistList = () => (
  <ListPageWithGroup
    groupFilterKey="group_Overlap"
    resource="playlist"
    subtitleKey="admin.page.playlist.subtitle"
    fields={[
      {
        name: "id",
      },
      {
        name: "group",
        type: "object",
        resource: "group",
        filterKey: "group_Overlap",
      },
      {
        name: "name",
        filterKey: "name_Text",
      },
      {
        name: "category",
        type: "choice",
        enumType: "PlaylistCategory",
        filterKey: "category_Overlap",
      },
      {
        name: "imageCount",
        type: "integer",
        filterKey: "imageCount_Range",
        sortKey: "IMAGE_COUNT",
      },
      {
        name: "videoCount",
        type: "integer",
        filterKey: "videoCount_Range",
        sortKey: "VIDEO_COUNT",
      },
      ...ListPage.fields.tracked,
    ]}
    query={QUERY}
    extraActions={({ group }) => group && <SyncGroupClipsButton groupId={group} />}
  />
);

// ==

const QUERY = gql`
  query playlistsForPlaylistList(
    $filter: PlaylistFilterInput
    $sort: [PlaylistSortInput]
    $page: PageInput
  ) {
    playlists(filter: $filter, sort: $sort, page: $page) {
      objects {
        id
        group {
          id
          name
        }
        name
        category
        imageCount
        videoCount
        ...TrackedFields
      }
      ...ListFields
    }
  }
  ${ListPage.fragments.list}
  ${ListPage.fragments.tracked}
`;
