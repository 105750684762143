import { gql } from "@apollo/client";

import { DetailPage } from "../../components/pages";

export const GroupMainScreenDetail = () => (
  <DetailPage
    resource="groupMainScreen"
    fields={[
      {
        name: "id",
      },
      {
        name: "defaultProfileImage",
        type: "imageMeta",
      },
      {
        name: "gridImage",
        type: "imageMeta",
      },
      {
        name: "logoImage",
        type: "imageMeta",
      },
      ...DetailPage.fields.canActivate,
      {
        name: "activateAt",
        type: "datetime",
      },
      ...DetailPage.fields.tracked,
    ]}
    query={QUERY}
  />
);

// ==

const QUERY = gql`
  query groupMainScreenForGroupMainScreenDetail($id: ID!) {
    groupMainScreen(filter: { id_Overlap: [$id] }) {
      id
      defaultProfileImage {
        ...DetailImageFields
      }
      gridImage {
        ...DetailImageFields
      }
      logoImage {
        ...DetailImageFields
      }
      ...CanActivateFields
      ...TrackedFields
    }
  }
  ${DetailPage.fragments.canActivate}
  ${DetailPage.fragments.tracked}
  ${DetailPage.fragments.image}
`;
