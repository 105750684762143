import { forwardRef, useState } from "react";

import { TimePicker } from "antd";

import dayjs from "dayjs";

const parseTime = (time) => {
  const [hour, minute] = time.split(":").map(Number); // 문자열을 숫자로 변환
  const period = hour >= 12 ? "pm" : "am";
  const adjustedHour = hour % 12 === 0 ? 12 : hour % 12;
  return `${adjustedHour}:${minute.toString().padStart(2, "0")} ${period}`;
};

export const TimeRangePicker = forwardRef(({ onChange, current, ...props }, ref) => {
  const [value, setValue] = useState(
    props.items.length > 0
      ? [
          dayjs(parseTime(props.items[0]), "hh:mm a"),
          dayjs(parseTime(props.items[1]), "hh:mm a"),
        ]
      : [],
  );

  const handleOnChange = (timeRange) => {
    setValue(timeRange);
    if (timeRange && onChange) {
      onChange(timeRange);
    }
  };
  return (
    <TimePicker.RangePicker
      ref={ref}
      value={value}
      use12Hours
      format="hh:mm a"
      onChange={handleOnChange}
      current={current}
      {...props}
    />
  );
});
