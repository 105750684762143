import { createContext, useState } from "react";

type LayoutContext = {
  siderCollapsed: boolean;
  mobileSiderOpen: boolean;
  setSiderCollapsed: (collapsed: boolean) => void;
  setMobileSiderOpen: (open: boolean) => void;
};

export const LayoutContext = createContext<LayoutContext>({
  siderCollapsed: false,
  mobileSiderOpen: false,
  setSiderCollapsed: () => undefined,
  setMobileSiderOpen: () => undefined,
});

export const LayoutProvider = ({ children, initialSiderCollapsed = false }) => {
  const [siderCollapsed, setSiderCollapsed] = useState(initialSiderCollapsed);
  const [mobileSiderOpen, setMobileSiderOpen] = useState(false);

  return (
    <LayoutContext.Provider
      value={{
        siderCollapsed,
        mobileSiderOpen,
        setSiderCollapsed,
        setMobileSiderOpen,
      }}
    >
      {children}
    </LayoutContext.Provider>
  );
};
