import { Query } from "@/gql/__generated__/graphql";
import { ResourceLike } from "@/types/resource";

import { DrawerSelectClip, DrawerSelectPlaylist } from "./components/drawerSelects";
import {
  SelectArtist,
  SelectComment,
  SelectCompany,
  SelectContinent,
  SelectCountry,
  SelectEvent,
  SelectEventV2,
  SelectEventV2PenaltyCategory,
  SelectExternalPremiumMembership,
  SelectGroup,
  SelectGroupTheme,
  SelectHashtag,
  SelectLanguage,
  SelectMediaLayout,
  SelectMember,
  SelectNotice,
  SelectOfflineStore,
  SelectPoll,
  SelectPollOption,
  SelectPost,
  SelectPremium,
  SelectSchedule,
  SelectStaff,
  SelectSubcontinent,
  SelectTranslationCode,
  SelectUser,
} from "./components/selects";
import { SelectBooth } from "./components/selects/SelectBooth.jsx";
import { SelectVenue } from "./components/selects/SelectVenue.jsx";
import {
  AnnouncementDetail,
  AnnouncementForm,
  AnnouncementList,
} from "./pages/announcement";
import { AppVersionDetail, AppVersionForm, AppVersionList } from "./pages/appVersion";
import { ArtistDetail, ArtistForm, ArtistList } from "./pages/artist";
import { ArtistPostDetail, ArtistPostForm, ArtistPostList } from "./pages/artistPost";
import { AuditDetail, AuditList } from "./pages/audit";
import {
  BannedPhraseDetail,
  BannedPhraseForm,
  BannedPhraseList,
} from "./pages/bannedPhrase";
import { BoothDetail } from "./pages/booth/BoothDetail.jsx";
import { BoothForm } from "./pages/booth/BoothForm.jsx";
import { BoothList } from "./pages/booth/BoothList.jsx";
import { BoothNoticeDetail } from "./pages/boothNotice/BoothNoticeDetail.jsx";
import { BoothNoticeForm } from "./pages/boothNotice/BoothNoticeForm.jsx";
import { BulkEmailDetail, BulkEmailForm, BulkEmailList } from "./pages/bulkEmail";
import { ClipDetail, ClipForm, ClipList } from "./pages/clip";
import { CommentDetail, CommentForm, CommentList } from "./pages/comment";
import { CompanyDetail, CompanyForm, CompanyList } from "./pages/company";
import { ContinentDetail, ContinentForm, ContinentList } from "./pages/continent";
import { CountryDetail, CountryForm, CountryList } from "./pages/country";
import { EventDetail, EventForm, EventList } from "./pages/event";
import {
  EventPenaltyDetail,
  EventPenaltyForm,
  EventPenaltyList,
} from "./pages/eventPenalty";
import { EventPostDetail, EventPostForm, EventPostList } from "./pages/eventPost";
import { EventV2Detail, EventV2Form, EventV2List } from "./pages/eventV2";
import {
  EventV2NoticeDetail,
  EventV2NoticeForm,
  EventV2NoticeList,
} from "./pages/eventV2Notice";
import {
  EventV2PenaltyDetail,
  EventV2PenaltyForm,
  EventV2PenaltyList,
} from "./pages/eventV2Penalty";
import {
  EventV2PenaltyCategoryDetail,
  EventV2PenaltyCategoryForm,
  EventV2PenaltyCategoryList,
} from "./pages/eventV2PenaltyCategory";
import { ExampleDetail, ExampleForm, ExampleList } from "./pages/example";
import {
  ExternalPremiumMembershipDetail,
  ExternalPremiumMembershipForm,
  ExternalPremiumMembershipList,
} from "./pages/externalPremiumMembership";
import { FanPostDetail, FanPostForm, FanPostList } from "./pages/fanPost";
import { GroupDetail, GroupForm, GroupList } from "./pages/group";
import {
  GroupFeatureFlagDetail,
  GroupFeatureFlagForm,
  GroupFeatureFlagList,
} from "./pages/groupFeatureFlag";
import {
  GroupMainScreenDetail,
  GroupMainScreenForm,
  GroupMainScreenList,
} from "./pages/groupMainScreen";
import { GroupRoleDetail, GroupRoleForm, GroupRoleList } from "./pages/groupRole";
import { GroupStaffDetail, GroupStaffForm, GroupStaffList } from "./pages/groupStaff";
import { GroupThemeDetail, GroupThemeForm, GroupThemeList } from "./pages/groupTheme";
import { HashtagDetail, HashtagForm, HashtagList } from "./pages/hashtag";
import { LanguageDetail, LanguageForm, LanguageList } from "./pages/language";
import {
  MediaLayoutDetail,
  MediaLayoutForm,
  MediaLayoutList,
} from "./pages/mediaLayout";
import {
  MediaLayoutSectionDetail,
  MediaLayoutSectionForm,
  MediaLayoutSectionList,
} from "./pages/mediaLayoutSection";
import { MemberDetail, MemberForm, MemberList } from "./pages/member";
import { ModeratedPostDetail, ModeratedPostList } from "./pages/moderatedPost";
import { NoticeDetail, NoticeForm, NoticeList } from "./pages/notice";
import {
  OfflineStoreDetail,
  OfflineStoreForm,
  OfflineStoreList,
} from "./pages/offlineStore";
import {
  OfflineStoreItemDetail,
  OfflineStoreItemForm,
  OfflineStoreItemList,
} from "./pages/offlineStoreItem";
import {
  OfflineStoreNoticeDetail,
  OfflineStoreNoticeForm,
  OfflineStoreNoticeList,
} from "./pages/offlineStoreNotice";
import {
  PersonalInformationCollectionDetail,
  PersonalInformationCollectionForm,
  PersonalInformationCollectionList,
} from "./pages/personalInformationCollection";
import {
  PersonalInformationTermsDetail,
  PersonalInformationTermsForm,
  PersonalInformationTermsList,
} from "./pages/personalInformationTerms";
import { PlaylistDetail, PlaylistForm, PlaylistList } from "./pages/playlist";
import {
  PlaylistEntryDetail,
  PlaylistEntryForm,
  PlaylistEntryList,
} from "./pages/playlistEntry";
import { PollDetail, PollForm, PollList } from "./pages/poll";
import { PollOptionDetail, PollOptionForm, PollOptionList } from "./pages/pollOption";
import { PollVoteDetail, PollVoteList } from "./pages/pollVote";
import { PostDetail, PostForm, PostList } from "./pages/post";
import { PremiumDetail, PremiumForm, PremiumList } from "./pages/premium";
import {
  PremiumMembershipDetail,
  PremiumMembershipForm,
  PremiumMembershipList,
} from "./pages/premiumMembership";
import { PrivacyDetail, PrivacyForm, PrivacyList } from "./pages/privacy";
import {
  ReportedCommentDetail,
  ReportedCommentForm,
  ReportedCommentList,
} from "./pages/reportedComment";
import {
  ReportedPostDetail,
  ReportedPostForm,
  ReportedPostList,
} from "./pages/reportedPost";
import { RoleDetail, RoleForm, RoleList } from "./pages/role";
import { ScheduleDetail, ScheduleForm, ScheduleList } from "./pages/schedule";
import { StaffDetail, StaffForm, StaffList } from "./pages/staff";
import {
  SubcontinentDetail,
  SubcontinentForm,
  SubcontinentList,
} from "./pages/subcontinent";
import { TermDetail, TermForm, TermList } from "./pages/term";
import {
  TranslationCodeDetail,
  TranslationCodeForm,
  TranslationCodeList,
} from "./pages/translationCode";
import {
  TranslationRequestForm,
  TranslationRequestTargetContentDetail,
  TranslationRequestTargetContentDetailForm,
  TranslationRequestTargetContentList,
} from "./pages/translationRequest";
import {
  TranslationValueDetail,
  TranslationValueForm,
  TranslationValueList,
} from "./pages/translationValue";
import {
  TranslationVersionDetail,
  TranslationVersionList,
} from "./pages/translationVersion";
import { UserDetail, UserForm, UserList } from "./pages/user";
import { VenueDetail } from "./pages/venue/VenueDetail.jsx";
import { VenueForm } from "./pages/venue/VenueForm.jsx";
import { VenueList } from "./pages/venue/VenueList.jsx";

const resources = [
  {
    name: "language",
    namePlural: "languages",
    labelField: "name",
    listComponent: LanguageList,
    detailComponent: LanguageDetail,
    formComponent: LanguageForm,
    selectComponent: SelectLanguage,
  },
  {
    name: "continent",
    namePlural: "continents",
    labelField: "name",
    listComponent: ContinentList,
    detailComponent: ContinentDetail,
    formComponent: ContinentForm,
    selectComponent: SelectContinent,
  },
  {
    name: "subcontinent",
    namePlural: "subcontinents",
    labelField: "name",
    listComponent: SubcontinentList,
    detailComponent: SubcontinentDetail,
    formComponent: SubcontinentForm,
    selectComponent: SelectSubcontinent,
  },
  {
    name: "country",
    namePlural: "countries",
    labelField: "name",
    listComponent: CountryList,
    detailComponent: CountryDetail,
    formComponent: CountryForm,
    selectComponent: SelectCountry,
  },
  // ==
  {
    name: "user",
    namePlural: "users",
    labelField: "name",
    listComponent: UserList,
    detailComponent: UserDetail,
    formComponent: UserForm,
    selectComponent: SelectUser,
  },
  {
    name: "staff",
    namePlural: "staffs",
    labelGetter: (obj: Query["staff"]): string =>
      obj?.user?.name ?? obj?.user?.email ?? `Staff #${obj?.id}`,
    listComponent: StaffList,
    detailComponent: StaffDetail,
    formComponent: StaffForm,
    selectComponent: SelectStaff,
  },
  {
    name: "groupStaff",
    type: "staff",
    typePlural: "staffs",
    listComponent: GroupStaffList,
    detailComponent: GroupStaffDetail,
    formComponent: GroupStaffForm,
  },
  {
    name: "groupRole",
    type: "role",
    typePlural: "roles",
    listComponent: GroupRoleList,
    detailComponent: GroupRoleDetail,
    formComponent: GroupRoleForm,
  },
  // ==
  {
    name: "company",
    namePlural: "companies",
    labelField: "name",
    listComponent: CompanyList,
    detailComponent: CompanyDetail,
    formComponent: CompanyForm,
    selectComponent: SelectCompany,
  },
  {
    name: "group",
    namePlural: "groups",
    labelField: "name",
    listComponent: GroupList,
    detailComponent: GroupDetail,
    formComponent: GroupForm,
    selectComponent: SelectGroup,
  },
  {
    name: "groupTheme",
    namePlural: "groupThemes",
    labelField: "name",
    listComponent: GroupThemeList,
    detailComponent: GroupThemeDetail,
    formComponent: GroupThemeForm,
    selectComponent: SelectGroupTheme,
  },
  {
    name: "groupMainScreen",
    namePlural: "groupMainScreens",
    listComponent: GroupMainScreenList,
    detailComponent: GroupMainScreenDetail,
    formComponent: GroupMainScreenForm,
  },
  {
    name: "artist",
    namePlural: "artists",
    labelField: "name",
    listComponent: ArtistList,
    detailComponent: ArtistDetail,
    formComponent: ArtistForm,
    selectComponent: SelectArtist,
  },
  {
    name: "member",
    namePlural: "members",
    labelField: "nickname",
    listComponent: MemberList,
    detailComponent: MemberDetail,
    formComponent: MemberForm,
    selectComponent: SelectMember,
  },
  {
    name: "premium",
    namePlural: "premiums",
    labelField: "name",
    listComponent: PremiumList,
    detailComponent: PremiumDetail,
    formComponent: PremiumForm,
    selectComponent: SelectPremium,
  },
  {
    name: "premiumMembership",
    namePlural: "premiumMemberships",
    listComponent: PremiumMembershipList,
    detailComponent: PremiumMembershipDetail,
    formComponent: PremiumMembershipForm,
  },
  {
    name: "externalPremiumMembership",
    namePlural: "externalPremiumMemberships",
    listComponent: ExternalPremiumMembershipList,
    detailComponent: ExternalPremiumMembershipDetail,
    formComponent: ExternalPremiumMembershipForm,
    selectComponent: SelectExternalPremiumMembership,
  },
  // ==
  {
    name: "post",
    namePlural: "posts",
    detailUrlGetter: (obj: Query["post"]) =>
      obj?.category === "FAN"
        ? `/fan-posts/${obj?.id}`
        : obj?.category === "ARTIST"
        ? `/artist-posts/${obj?.id}`
        : `/posts/${obj?.id}`,
    listComponent: PostList,
    detailComponent: PostDetail,
    formComponent: PostForm,
    selectComponent: SelectPost,
  },
  {
    name: "fanPost",
    type: "post",
    typePlural: "posts",
    listComponent: FanPostList,
    detailComponent: FanPostDetail,
    formComponent: FanPostForm,
  },
  {
    name: "artistPost",
    type: "post",
    typePlural: "posts",
    listComponent: ArtistPostList,
    detailComponent: ArtistPostDetail,
    formComponent: ArtistPostForm,
  },
  {
    name: "poll",
    namePlural: "polls",
    labelField: "title",
    listComponent: PollList,
    detailComponent: PollDetail,
    formComponent: PollForm,
    selectComponent: SelectPoll,
  },
  {
    name: "pollOption",
    namePlural: "pollOptions",
    listComponent: PollOptionList,
    detailComponent: PollOptionDetail,
    formComponent: PollOptionForm,
    selectComponent: SelectPollOption,
  },
  {
    name: "pollVote",
    namePlural: "pollVotes",
    listComponent: PollVoteList,
    detailComponent: PollVoteDetail,
  },
  // ==
  {
    name: "clip",
    namePlural: "clips",
    labelField: "title",
    listComponent: ClipList,
    detailComponent: ClipDetail,
    formComponent: ClipForm,
    selectComponent: DrawerSelectClip,
  },
  {
    name: "playlist",
    namePlural: "playlists",
    labelField: "name",
    listComponent: PlaylistList,
    detailComponent: PlaylistDetail,
    formComponent: PlaylistForm,
    selectComponent: DrawerSelectPlaylist,
  },
  {
    name: "playlistEntry",
    namePlural: "playlistEntries",
    listComponent: PlaylistEntryList,
    detailComponent: PlaylistEntryDetail,
    formComponent: PlaylistEntryForm,
  },
  {
    name: "mediaLayout",
    namePlural: "mediaLayouts",
    labelField: "name",
    listComponent: MediaLayoutList,
    detailComponent: MediaLayoutDetail,
    formComponent: MediaLayoutForm,
    selectComponent: SelectMediaLayout,
  },
  {
    name: "mediaLayoutSection",
    namePlural: "mediaLayoutSections",
    listComponent: MediaLayoutSectionList,
    detailComponent: MediaLayoutSectionDetail,
    formComponent: MediaLayoutSectionForm,
  },
  // ==
  {
    name: "schedule",
    namePlural: "schedules",
    labelField: "title",
    listComponent: ScheduleList,
    detailComponent: ScheduleDetail,
    formComponent: ScheduleForm,
    selectComponent: SelectSchedule,
  },
  // ==
  {
    name: "notice",
    namePlural: "notices",
    labelField: "title",
    listComponent: NoticeList,
    detailComponent: NoticeDetail,
    formComponent: NoticeForm,
    selectComponent: SelectNotice,
  },
  // ==
  {
    name: "comment",
    namePlural: "comments",
    listComponent: CommentList,
    detailComponent: CommentDetail,
    formComponent: CommentForm,
    selectComponent: SelectComment,
  },
  {
    name: "bannedPhrase",
    namePlural: "bannedPhrases",
    listComponent: BannedPhraseList,
    detailComponent: BannedPhraseDetail,
    formComponent: BannedPhraseForm,
  },
  {
    name: "reportedPost",
    type: "post",
    typePlural: "posts",
    listComponent: ReportedPostList,
    detailComponent: ReportedPostDetail,
    formComponent: ReportedPostForm,
  },
  {
    name: "reportedComment",
    type: "comment",
    typePlural: "comments",
    listComponent: ReportedCommentList,
    detailComponent: ReportedCommentDetail,
    formComponent: ReportedCommentForm,
  },
  {
    name: "moderatedPost",
    type: "post",
    typePlural: "posts",
    listComponent: ModeratedPostList,
    detailComponent: ModeratedPostDetail,
  },
  // ==
  {
    name: "announcement",
    namePlural: "announcements",
    listComponent: AnnouncementList,
    detailComponent: AnnouncementDetail,
    formComponent: AnnouncementForm,
  },
  {
    name: "bulkEmail",
    namePlural: "bulkEmails",
    labelField: "title",
    listComponent: BulkEmailList,
    detailComponent: BulkEmailDetail,
    formComponent: BulkEmailForm,
  },
  {
    name: "term",
    type: "agreement",
    typePlural: "agreements",
    labelField: "title",
    listComponent: TermList,
    detailComponent: TermDetail,
    formComponent: TermForm,
  },
  {
    name: "privacy",
    type: "agreement",
    typePlural: "agreements",
    labelField: "title",
    listComponent: PrivacyList,
    detailComponent: PrivacyDetail,
    formComponent: PrivacyForm,
  },
  {
    name: "personalInformationTerms",
    namePlural: "personalInformationTerms",
    type: "agreement",
    typePlural: "agreements",
    labelField: "title",
    listComponent: PersonalInformationTermsList,
    detailComponent: PersonalInformationTermsDetail,
    formComponent: PersonalInformationTermsForm,
  },
  {
    name: "personalInformationCollection",
    type: "agreement",
    typePlural: "agreements",
    labelField: "title",
    listComponent: PersonalInformationCollectionList,
    detailComponent: PersonalInformationCollectionDetail,
    formComponent: PersonalInformationCollectionForm,
  },
  {
    name: "appVersion",
    namePlural: "appVersions",
    labelField: "revision",
    listComponent: AppVersionList,
    detailComponent: AppVersionDetail,
    formComponent: AppVersionForm,
  },
  {
    name: "translationVersion",
    namePlural: "translationVersions",
    labelField: "revision",
    listComponent: TranslationVersionList,
    detailComponent: TranslationVersionDetail,
  },
  // ==
  {
    name: "hashtag",
    namePlural: "hashtags",
    labelField: "name",
    listComponent: HashtagList,
    detailComponent: HashtagDetail,
    formComponent: HashtagForm,
    selectComponent: SelectHashtag,
  },
  // ==
  {
    name: "event",
    namePlural: "events",
    labelField: "title",
    listComponent: EventList,
    detailComponent: EventDetail,
    formComponent: EventForm,
    selectComponent: SelectEvent,
  },
  {
    name: "eventApplication",
    namePlural: "eventApplications",
  },
  {
    name: "eventWinner",
    namePlural: "eventWinners",
  },
  {
    name: "eventAttendance",
    namePlural: "eventAttendances",
  },
  {
    name: "eventPost",
    namePlural: "eventPosts",
    listComponent: EventPostList,
    detailComponent: EventPostDetail,
    formComponent: EventPostForm,
  },
  {
    name: "eventPenalty",
    namePlural: "eventPenalties",
    listComponent: EventPenaltyList,
    detailComponent: EventPenaltyDetail,
    formComponent: EventPenaltyForm,
  },
  // ==
  {
    name: "eventV2",
    namePlural: "eventsV2",
    listComponent: EventV2List,
    detailComponent: EventV2Detail,
    formComponent: EventV2Form,
    selectComponent: SelectEventV2,
    labelGetter: (obj: Query["eventV2"]) => obj?.title ?? `EventV2 #${obj?.id}`,
  },
  {
    name: "eventV2Application",
    namePlural: "eventV2Applications",
  },
  {
    name: "eventV2Notice",
    namePlural: "eventV2Notices",
    listComponent: EventV2NoticeList,
    detailComponent: EventV2NoticeDetail,
    formComponent: EventV2NoticeForm,
  },
  {
    name: "eventV2Penalty",
    namePlural: "eventV2Penalties",
    listComponent: EventV2PenaltyList,
    detailComponent: EventV2PenaltyDetail,
    formComponent: EventV2PenaltyForm,
  },
  {
    name: "eventV2PenaltyCategory",
    namePlural: "eventV2PenaltyCategories",
    listComponent: EventV2PenaltyCategoryList,
    detailComponent: EventV2PenaltyCategoryDetail,
    formComponent: EventV2PenaltyCategoryForm,
    selectComponent: SelectEventV2PenaltyCategory,
    labelGetter: (obj: Query["eventV2PenaltyCategory"]): string =>
      obj?.name ?? `EventV2Penalty#${obj?.id}`,
  },
  {
    name: "eventV2Winner",
    namePlural: "eventV2Winners",
  },
  // ==
  {
    name: "offlineStore",
    namePlural: "offlineStores",
    labelField: "name",
    listComponent: OfflineStoreList,
    detailComponent: OfflineStoreDetail,
    formComponent: OfflineStoreForm,
    selectComponent: SelectOfflineStore,
  },
  {
    name: "offlineStoreItem",
    namePlural: "offlineStoreItems",
    listComponent: OfflineStoreItemList,
    detailComponent: OfflineStoreItemDetail,
    formComponent: OfflineStoreItemForm,
  },
  {
    name: "offlineStoreItemVariant",
    namePlural: "offlineStoreItemVariants",
  },
  {
    name: "offlineStoreNotice",
    namePlural: "offlineStoreNotices",
    listComponent: OfflineStoreNoticeList,
    detailComponent: OfflineStoreNoticeDetail,
    formComponent: OfflineStoreNoticeForm,
  },
  // ==
  {
    name: "venue",
    namePlural: "venues",
    labelField: "title",
    listComponent: VenueList,
    detailComponent: VenueDetail,
    formComponent: VenueForm,
    selectComponent: SelectVenue,
  },
  {
    name: "venueStaff",
    namePlural: "venueStaffs",
  },
  {
    name: "booth",
    namePlural: "booths",
    labelField: "title",
    listComponent: BoothList,
    detailComponent: BoothDetail,
    formComponent: BoothForm,
    selectComponent: SelectBooth,
  },
  {
    name: "boothNotice",
    namePlural: "boothNotices",
    detailComponent: BoothNoticeDetail,
    formComponent: BoothNoticeForm,
  },
  {
    name: "boothWaitListEntry",
    namePlural: "boothWaitList",
  },
  // ==
  {
    name: "role",
    namePlural: "roles",
    listComponent: RoleList,
    detailComponent: RoleDetail,
    formComponent: RoleForm,
  },
  {
    name: "groupFeatureFlag",
    namePlural: "groupFeatureFlags",
    listComponent: GroupFeatureFlagList,
    detailComponent: GroupFeatureFlagDetail,
    formComponent: GroupFeatureFlagForm,
  },
  // ==
  {
    name: "translationCode",
    namePlural: "translationCodes",
    labelField: "code",
    listComponent: TranslationCodeList,
    detailComponent: TranslationCodeDetail,
    formComponent: TranslationCodeForm,
    selectComponent: SelectTranslationCode,
  },
  {
    name: "translationValue",
    namePlural: "translationValues",
    listComponent: TranslationValueList,
    detailComponent: TranslationValueDetail,
    formComponent: TranslationValueForm,
  },
  // ==
  {
    name: "translationRequest",
    namePlural: "translationRequests",
    formComponent: TranslationRequestForm,
  },
  {
    name: "translationRequestTargetContent",
    namePlural: "translationRequestTargetContents",
    listComponent: TranslationRequestTargetContentList,
    detailComponent: TranslationRequestTargetContentDetail,
    formComponent: TranslationRequestTargetContentDetailForm,
  },
  {
    name: "audit",
    namePlural: "audits",
    listComponent: AuditList,
    detailComponent: AuditDetail,
  },
  // ==
  {
    name: "example",
    namePlural: "examples",
    listComponent: ExampleList,
    detailComponent: ExampleDetail,
    formComponent: ExampleForm,
  },
] as const satisfies ResourceLike[];

export type OriginalResources = typeof resources;
export type OriginalResource = OriginalResources[number];
export type ResourceName = OriginalResource["name"];
export type OriginalResourceMap = {
  [R in OriginalResources[number] as R["name"]]: R;
};
export default resources;
