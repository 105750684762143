import { gql } from "@apollo/client";

import { ListWidget } from "../list";

export const PlaylistEntryListWidget = (props) => (
  <ListWidget
    resource="playlistEntry"
    fields={[
      {
        name: "id",
      },
      {
        name: "playlist",
        type: "object",
        resource: "playlist",
        filterKey: "playlist_Overlap",
      },
      {
        name: "clip",
        type: "object",
        resource: "clip",
        filterKey: "clip_Overlap",
      },
      {
        name: "order",
        filterKey: "order_Range",
        sortKey: "ORDER",
      },
      ...ListWidget.fields.tracked,
    ]}
    query={QUERY}
    extraRowActions={ListWidget.extraRowActions.delete}
    hideCreate
    {...props}
  />
);

// ==

const QUERY = gql`
  query playlistEntriesForPlaylistEntryListWidget(
    $filter: PlaylistEntryFilterInput
    $sort: [PlaylistEntrySortInput]
    $page: PageInput
  ) {
    playlistEntries(filter: $filter, sort: $sort, page: $page) {
      objects {
        id
        playlist {
          id
          name
        }
        clip {
          id
          title
        }
        order
        ...TrackedFields
      }
      ...ListFields
    }
  }
  ${ListWidget.fragments.list}
  ${ListWidget.fragments.tracked}
`;
