import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { gql, useMutation, useQuery } from "@apollo/client";

import { SaveOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Divider,
  Radio,
  Row,
  Space,
  Switch,
  Typography,
} from "antd";

import { DndContext, PointerSensor, useSensor, useSensors } from "@dnd-kit/core";
import {
  SortableContext,
  arrayMove,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import dayjs from "dayjs";

import { DragHandle } from "../../components/list";
import { useApp } from "../../hooks/app";

const { Text } = Typography;

export const GroupTabSettingContent = ({ groupId }) => {
  const { t } = useTranslation();
  const [elements, setElements] = useState();
  const { data, refetch } = useQuery(QUERY, {
    variables: { id: groupId },
    fetchPolicy: "network-only",
  });
  const [update, { loading }] = useMutation(MUTATION);
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 10,
      },
    }),
  );
  const { message } = useApp();

  useEffect(() => {
    if (data) {
      setElements(sortElements(data.allGroupElements));
    }
  }, [data]);

  if (!elements) {
    return null;
  }

  // ==

  const onChange = (element) => {
    setElements((prev) => {
      const curr = prev.map((i) => (i.id === element.id ? { ...i, ...element } : i));
      const active = prev.find((i) => i.id === element.id);
      if (active.isActive === element.isActive) {
        return curr;
      }

      const activeIndex = prev.findIndex((i) => i.id === element.id);
      const overIndex = element.isActive
        ? prev.findIndex((i) => !i.isActive)
        : prev.findLastIndex((i) => i.isActive);
      return arrayMove(curr, activeIndex, overIndex);
    });
  };
  const onDragEnd = ({ active, over }) => {
    if (active.id !== over?.id) {
      setElements((prev) => {
        const activeIndex = prev.findIndex((i) => i.id === active.id);
        const overIndex = prev.findIndex((i) => i.id === over?.id);
        return arrayMove(prev, activeIndex, overIndex);
      });
    }
  };
  const onSave = () =>
    update({
      variables: {
        data: elements.map((element, i) => ({
          id: element.id,
          category: element.category,
          tabOrder: i + 1,
          isMemberOnly: element.isMemberOnly,
          isActive: element.isActive,
        })),
      },
      onCompleted: (response) => {
        const { ok } = Object.values(response)[0];
        if (ok) {
          message.success("Successfully saved.");
          refetch();
        }
      },
    });

  const lastUpdatedAt = elements.map((element) => element.lastUpdatedAt).toSorted()[
    elements.length - 1
  ];
  //const topElements = elements.filter((element) => element.category === HOME_CATEGORY);
  const topElements = elements.filter((element) =>
    TOP_CATEGORIES.includes(element.category),
  );
  //const nonTopElements = elements.filter(
  //(element) => element.category !== HOME_CATEGORY,
  //);
  const nonTopElements = elements.filter(
    (element) => !TOP_CATEGORIES.includes(element.category),
  );
  const draggableElements = nonTopElements.filter((element) => element.isActive);
  const bottomElements = nonTopElements.filter((element) => !element.isActive);

  return (
    <div style={styles.container}>
      <Row>
        <Col span={24} lg={12}>
          <Card>
            <ElementHeader />
            {topElements.map((element) => (
              <Element key={element.id} element={element} onChange={onChange} />
            ))}
            <DndContext sensors={sensors} onDragEnd={onDragEnd}>
              <SortableContext
                items={draggableElements.map((i) => i.id)}
                strategy={verticalListSortingStrategy}
              >
                {draggableElements.map((element) => (
                  <Draggable key={element.id} id={element.id}>
                    <Element element={element} onChange={onChange} />
                  </Draggable>
                ))}
              </SortableContext>
            </DndContext>
            {bottomElements.length > 0 && (
              <>
                <div style={styles.padder} />
                {bottomElements.map((element) => (
                  <Element key={element.id} element={element} onChange={onChange} />
                ))}
              </>
            )}
          </Card>
          <Divider />
          <Space size="large">
            <Button
              type="primary"
              htmlType="submit"
              icon={<SaveOutlined />}
              disabled={loading}
              onClick={onSave}
            >
              {loading ? t("admin.common.saving") : t("admin.common.save")}
            </Button>
            {lastUpdatedAt && (
              <Text type="secondary">
                {t("admin.common.lastUpdated", {
                  ago: dayjs(lastUpdatedAt).fromNow(),
                })}
              </Text>
            )}
          </Space>
        </Col>
      </Row>
    </div>
  );
};

// ==

const ElementHeader = () => {
  const { t } = useTranslation();
  return (
    <Row style={{ ...styles.row, ...styles.header }}>
      <Col span={2}></Col>
      <Col span={5}>
        <Text strong>{t("admin.page.groupTabSetting.name")}</Text>
      </Col>
      <Col span={5}>
        <Text strong>{t("admin.page.groupTabSetting.usage")}</Text>
      </Col>
      <Col span={12}>
        <Text strong>{t("admin.page.groupTabSetting.permission")}</Text>
      </Col>
    </Row>
  );
};

const Element = ({ element, onChange }) => {
  const { t } = useTranslation();
  return (
    <Row style={styles.row}>
      <Col span={2}>
        {!TOP_CATEGORIES.includes(element.category) && element.isActive && (
          <DragHandle />
        )}
      </Col>
      <Col span={5}>{element.category}</Col>
      <Col span={5}>
        <Switch
          checked={element.isActive}
          disabled={TOP_CATEGORIES.includes(element.category)}
          onChange={(isActive) => onChange({ ...element, isActive })}
        />
      </Col>
      <Col span={12}>
        {element.category !== HOME_CATEGORY && (
          <Radio.Group
            value={element.isMemberOnly}
            disabled={!element.isActive}
            onChange={(e) => onChange({ ...element, isMemberOnly: e.target.value })}
          >
            <Radio value={false}>{t("admin.page.groupTabSetting.all")}</Radio>
            <Radio value={true}>{t("admin.page.groupTabSetting.memberOnly")}</Radio>
          </Radio.Group>
        )}
      </Col>
    </Row>
  );
};

const Draggable = ({ id, ...props }) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id,
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    cursor: "move",
  };

  return (
    <div ref={setNodeRef} style={style} {...attributes} {...listeners} {...props} />
  );
};

// ==

const HOME_CATEGORY = "HOME";
const ARTIST_CATEGORY = "ARTIST";
const FAN_CATEGORY = "FAN";
const MEDIA_CATEGORY = "MEDIA";
const NOTICE_CATEGORY = "NOTICE";
const EVENT_CATEGORY = "EVENT";
const TOP_CATEGORIES = [
  HOME_CATEGORY,
  ARTIST_CATEGORY,
  FAN_CATEGORY,
  MEDIA_CATEGORY,
  NOTICE_CATEGORY,
  EVENT_CATEGORY,
];

const compute = (element) =>
  element.category === HOME_CATEGORY
    ? -1000
    : element.isActive
    ? element.tabOrder - 100
    : element.tabOrder ?? 0;

const sortElements = (elements) => elements.toSorted((a, b) => compute(a) - compute(b));

// ==

const QUERY = gql`
  query allGroupElementsForGroupTabSettingContent($id: ID!) {
    allGroupElements(filter: { group_Overlap: [$id] }) {
      id
      category
      tabOrder
      isMemberOnly
      isActive
      lastUpdatedAt
    }
  }
`;

const MUTATION = gql`
  mutation bulkUpdateGroupElement($data: [BulkUpdateGroupElementInput]!) {
    bulkUpdateGroupElement(data: $data) {
      ok
    }
  }
`;

// ==

const styles = {
  container: {
    marginTop: 20,
  },
  row: {
    paddingTop: 8,
    paddingBottom: 8,
    borderTop: "1px solid rgba(120, 120, 120, 0.1)",
  },
  header: {
    marginBottom: 4,
    borderTop: "none",
  },
  padder: {
    height: 16,
  },
};
