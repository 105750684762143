import { useState } from "react";
import { useTranslation } from "react-i18next";

import { gql, useQuery } from "@apollo/client";

import {
  BellOutlined,
  ClockCircleOutlined,
  CloseCircleOutlined,
  EllipsisOutlined,
  RedoOutlined,
  SearchOutlined,
  ShopOutlined,
} from "@ant-design/icons";
import {
  Affix,
  Badge,
  Button,
  Calendar,
  Card,
  Col,
  Divider,
  Dropdown,
  Row,
  Space,
  Typography,
} from "antd";

import dayjs from "dayjs";

import { EditScheduleAction } from "../../components/actions";
import {
  DateTimeField,
  DebouncedInput,
  EnumField,
  EnumSelect,
} from "../../components/fields";
import { SelectArtist } from "../../components/selects";
import { useTimezone } from "../../hooks/tz";
import { CAN_ACTIVATE_FRAGMENT, TRACKED_FRAGMENT } from "../../utils/gql/fragments";

const { Text } = Typography;

export const ScheduleCalendarContent = ({ groupId }) => {
  const getFilter = () => ({
    text,
    group_Overlap: [groupId],
    artists_Overlap: artists,
    category_Overlap: categories,
    scheduledAt_Gte: date.startOf("month").toISOString(),
    scheduledAt_Lt: date.add(1, "month").startOf("month").toISOString(),
  });

  const { t } = useTranslation();
  const { timezone } = useTimezone();

  const [date, setDate] = useState(dayjs().tz(timezone));
  const [text, setText] = useState("");
  const [artists, setArtists] = useState([]);
  const [categories, setCategories] = useState([]);
  const { loading, data, refetch } = useQuery(QUERY, {
    variables: {
      filter: getFilter(),
    },
  });

  // ==

  const cellRender = (current, info) =>
    info.type === "date" ? (
      <DateCell schedules={schedulesByDate[current.date()]} />
    ) : info.type === "month" ? null : (
      info.originNode
    );
  const reset = () => {
    setDate(dayjs().tz(timezone));
    setText("");
    setArtists([]);
    setCategories([]);
  };
  const onPanelChange = (value) => setDate(value);

  const schedules = loading ? [] : data?.allSchedules ?? [];
  const schedulesByDate = schedules.reduce((acc, schedule) => {
    const date = dayjs(schedule.scheduledAt).tz(timezone).date();
    if (!acc[date]) {
      acc[date] = [];
    }
    acc[date].push(schedule);
    return acc;
  }, {});
  const isResettable = !!(
    date.format("YYYY-MM-DD") !== dayjs().tz(timezone).format("YYYY-MM-DD") ||
    text ||
    artists.length ||
    categories.length
  );

  return (
    <div style={styles.container}>
      <Divider />
      <Row justify="space-between" style={styles.filterRow}>
        <Col>
          <Space align="start" size="large">
            <DebouncedInput
              prefix={<SearchOutlined />}
              placeholder={t("admin.common.search")}
              value={text}
              onChange={(value) => setText(value)}
              onPressEnter={(value) => setText(value)}
              style={styles.textInput}
            />
            <SelectArtist
              value={artists}
              onChange={(value) => setArtists(value)}
              multiple
              baseFilter={{ group_Overlap: [groupId] }}
              style={styles.selectInput}
            />
            <EnumSelect
              type="ScheduleCategory"
              placeholder={"-- " + t("admin.common.scheduleType")}
              value={categories}
              onChange={(value) => setCategories(value)}
              multiple
              style={styles.selectInput}
            />
            <Button
              type="text"
              icon={<CloseCircleOutlined />}
              disabled={!isResettable}
              onClick={() => reset()}
            ></Button>
            <Button
              type="text"
              icon={<RedoOutlined />}
              disabled={loading}
              loading={loading}
              onClick={() => refetch()}
            ></Button>
          </Space>
        </Col>
        <Col>
          <EditScheduleAction title="Add Schedule" onChange={() => refetch()}>
            {({ openDrawer }) => (
              <Button type="primary" onClick={openDrawer}>
                {t("admin.common.add")}
              </Button>
            )}
          </EditScheduleAction>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={24} lg={17}>
          <Affix offsetTop={68}>
            <Card>
              <Calendar
                value={date}
                cellRender={cellRender}
                onPanelChange={onPanelChange}
              />
            </Card>
          </Affix>
        </Col>
        <Col span={24} lg={7}>
          <Card bodyStyle={styles.schedulesCardBody}>
            {schedules.map((schedule) => (
              <Schedule key={schedule.id} schedule={schedule} />
            ))}
          </Card>
        </Col>
      </Row>
    </div>
  );
};

//

const Schedule = ({ schedule }) => {
  const { t } = useTranslation();
  return (
    <div style={styles.scheduleCard}>
      <Row>
        <Col span={22}>
          <Space direction="vertical" size={4}>
            <EnumField type="ScheduleCategory" value={schedule.category} />
            <Space size="small" align="start">
              <Badge status={badgeColors[schedule.status]} />
              <Text>{schedule.title}</Text>
            </Space>
            <Space size="small">
              <Text type="secondary" style={styles.smallText}>
                <ClockCircleOutlined />
              </Text>
              <DateTimeField
                value={schedule.scheduledAt}
                type="secondary"
                style={styles.smallText}
              />
              {schedule.doesSendPushOnSchedule && <BellOutlined />}
            </Space>
            {schedule.location && (
              <Text type="secondary" style={styles.smallText}>
                <Space size="small">
                  <ShopOutlined />
                  {schedule.location}
                </Space>
              </Text>
            )}
          </Space>
        </Col>
        <Col span={2}>
          <Dropdown
            menu={{
              items: [
                {
                  key: "edit",
                  label: (
                    <EditScheduleAction scheduleId={schedule.id}>
                      {({ openDrawer }) => (
                        <a onClick={openDrawer}>{t("admin.common.edit")}</a>
                      )}
                    </EditScheduleAction>
                  ),
                },
              ],
            }}
            trigger={["click"]}
          >
            <Button type="text" icon={<EllipsisOutlined />} />
          </Dropdown>
        </Col>
      </Row>
    </div>
  );
};

const DateCell = ({ schedules }) => {
  return schedules?.map((schedule) => (
    <Space key={schedule.id} align="start">
      <Badge status={badgeColors[schedule.status]} />
      <Text style={styles.smallText}>{schedule.title}</Text>
    </Space>
  ));
};

// ==

const badgeColors = {
  ACTIVE: "success",
  INACTIVE: "error",
  SCHEDULED: "warning",
};

// ==

const QUERY = gql`
  query allSchedulesForScheduleCalendarContent($filter: ScheduleFilterInput!) {
    allSchedules(filter: $filter, sort: [{ type: SCHEDULED_AT, direction: ASC }]) {
      id
      artists {
        id
        name
      }
      category
      scheduledAt
      title
      body
      location
      status
      doesSendPushOnSchedule
      ...TrackedFields
      ...CanActivateFields
    }
  }
  ${TRACKED_FRAGMENT}
  ${CAN_ACTIVATE_FRAGMENT}
`;

// ==

const styles = {
  container: {},
  filterRow: {
    marginBottom: 24,
  },
  textInput: {
    width: 300,
  },
  selectInput: {
    width: 200,
  },
  schedulesCardBody: {
    padding: 0,
  },
  scheduleCard: {
    padding: 16,
    borderBottom: "1px solid rgba(120, 120, 120, 0.1)",
  },
  smallText: {
    fontSize: "0.9em",
    lineHeight: 1.4,
    display: "block",
  },
};
