import { gql } from "@apollo/client";

import { DetailPage } from "../../components/pages";

export const PollVoteDetail = () => (
  <DetailPage
    resource="pollVote"
    fields={[
      {
        name: "id",
      },
      {
        name: "option",
        type: "object",
        resource: "pollOption",
      },
      {
        name: "member",
        type: "object",
        resource: "member",
      },
      ...DetailPage.fields.timestamped,
    ]}
    query={QUERY}
    extraActions={DetailPage.extraActions.delete}
  />
);

// ==

const QUERY = gql`
  query pollVoteForPollVoteDetail($id: ID!) {
    pollVote(filter: { id_Overlap: [$id] }) {
      id
      option {
        id
      }
      member {
        id
        nickname
      }
      ...TimestampedFields
    }
  }
  ${DetailPage.fragments.timestamped}
`;
