type UnknownTypeField = {
  type?: unknown;
};

export function isContainerField(
  field: UnknownTypeField,
): field is { type: "fieldset" | "subFieldset" } {
  return field.type === "fieldset" || field.type === "subFieldset";
}

export function isCompositeField(
  field: UnknownTypeField,
): field is { type: "composite" } {
  return field.type === "composite";
}
