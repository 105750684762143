import { gql } from "@apollo/client";

import { DetailPage } from "../../components/pages";
import { PollVoteListWidget } from "../../components/widgets";

export const PollOptionDetail = () => (
  <DetailPage
    resource="pollOption"
    fields={[
      {
        name: "id",
      },
      {
        name: "poll",
        type: "object",
        resource: "poll",
      },
      {
        name: "order",
        type: "integer",
      },
      {
        name: "body",
      },
      {
        name: "image",
        type: "imageMeta",
      },
      {
        name: "voteCount",
        type: "integer",
      },
      ...DetailPage.fields.tracked,
    ]}
    query={QUERY}
    extraActions={DetailPage.extraActions.delete}
    tabs={[
      {
        path: "",
        labelKey: "admin.common.votes",
        permission: {
          action: "list",
          targetType: "POLL_VOTE",
        },
        children: ({ obj }) => (
          <PollVoteListWidget
            baseFilter={{
              option_Overlap: [obj.id],
            }}
            hiddenFields={["option.poll", "option"]}
          />
        ),
      },
    ]}
  />
);

// ==

const QUERY = gql`
  query pollOptionForPollOptionDetail($id: ID!) {
    pollOption(filter: { id_Overlap: [$id] }) {
      id
      poll {
        id
        title
      }
      order
      body
      image {
        ...DetailImageFields
      }
      voteCount
      ...TrackedFields
    }
  }
  ${DetailPage.fragments.tracked}
  ${DetailPage.fragments.image}
`;
