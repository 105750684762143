import { gql } from "@apollo/client";

import { FormPage } from "../../components/pages";

export const CompanyForm = () => (
  <FormPage
    resource="company"
    fields={[
      {
        name: "code",
        descriptionKey: "admin.form.guide.code",
      },
      {
        name: "name",
      },
      {
        name: "owner",
        type: "object",
        resource: "staff",
        descriptionKey: "admin.form.guide.owner",
      },
      {
        name: "reservedNicknames",
        multiple: true,
        descriptionKey: "admin.form.guide.reservedNicknames",
      },
    ]}
    query={QUERY}
    createMutation={CREATE_MUTATION}
    updateMutation={UPDATE_MUTATION}
  />
);

// ==

const FORM_FIELDS_FRAGMENT = gql`
  fragment CompanyFormFields on Company {
    id
    code
    name
    reservedNicknames
    owner {
      id
    }
  }
`;

const QUERY = gql`
  query companyForCompanyForm($id: ID!) {
    company(filter: { id_Overlap: [$id] }) {
      ...CompanyFormFields
    }
  }
  ${FORM_FIELDS_FRAGMENT}
`;

const CREATE_MUTATION = gql`
  mutation createCompanyForCompanyForm($data: CompanyMutationInput!) {
    createCompany(data: $data) {
      ok
      errors {
        ...MutationErrorFields
      }
      object {
        ...CompanyFormFields
      }
    }
  }
  ${FormPage.fragments.mutationError}
  ${FORM_FIELDS_FRAGMENT}
`;

const UPDATE_MUTATION = gql`
  mutation updateCompanyForCompanyForm($data: CompanyMutationInput!, $id: ID!) {
    updateCompany(data: $data, id: $id, partial: true) {
      ok
      errors {
        ...MutationErrorFields
      }
      object {
        ...CompanyFormFields
      }
    }
  }
  ${FormPage.fragments.mutationError}
  ${FORM_FIELDS_FRAGMENT}
`;
