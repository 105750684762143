import { gql } from "@apollo/client";

import { FormPage } from "../../components/pages";

export const EventV2NoticeForm = () => (
  <FormPage
    resource="eventV2Notice"
    fields={[
      {
        type: "fieldset",
        label: "",
        fields: [
          {
            name: "eventV2",
            type: "object",
            resource: "eventV2",
            // FIXME: Temporary workaround for eventV2 upgrade
            normalizer: (eventV2) => {
              return { event: eventV2 };
            },
            disabled: ({ isUpdate }) => isUpdate,
          },
        ],
      },
      {
        type: "fieldset",
        labelKey: "admin.fieldset.content",
        fields: [
          {
            name: "bodyContent",
            labelKey: "admin.field.body",
            type: "translatable",
            markdown: true,
            rows: 5,
          },
        ],
      },
      ...FormPage.fields.canActivate,
    ]}
    query={QUERY}
    createMutation={CREATE_MUTATION}
    updateMutation={UPDATE_MUTATION}
  />
);

// ==

const FORM_FIELDS_FRAGMENT = gql`
  fragment EventV2NoticeFormFields on EventV2Notice {
    id
    event {
      id
    }
    body
    bodyContent {
      ...TranslatableContentFields
    }
    ...CanActivateFields
  }
  ${FormPage.fragments.canActivate}
  ${FormPage.fragments.translatableContent}
`;

const QUERY = gql`
  query eventV2NoticeForEventV2NoticeForm($id: ID!) {
    eventV2Notice(filter: { id_Overlap: [$id] }) {
      ...EventV2NoticeFormFields
    }
  }
  ${FORM_FIELDS_FRAGMENT}
`;

const CREATE_MUTATION = gql`
  mutation createEventV2NoticeForEventV2NoticeForm($data: EventV2NoticeMutationInput!) {
    createEventV2Notice(data: $data) {
      ok
      errors {
        ...MutationErrorFields
      }
      object {
        ...EventV2NoticeFormFields
      }
    }
  }
  ${FormPage.fragments.mutationError}
  ${FORM_FIELDS_FRAGMENT}
`;

const UPDATE_MUTATION = gql`
  mutation updateEventV2NoticeForEventV2NoticeForm(
    $data: EventV2NoticeMutationInput!
    $id: ID!
  ) {
    updateEventV2Notice(data: $data, id: $id, partial: true) {
      ok
      errors {
        ...MutationErrorFields
      }
      object {
        ...EventV2NoticeFormFields
      }
    }
  }
  ${FormPage.fragments.mutationError}
  ${FORM_FIELDS_FRAGMENT}
`;
