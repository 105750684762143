import { gql } from "@apollo/client";

import { ListPage } from "../../components/pages";

export const CompanyList = () => (
  <ListPage
    resource="company"
    subtitleKey="admin.page.company.subtitle"
    fields={[
      {
        name: "id",
      },
      {
        name: "code",
        filterKey: "code_Exact",
        sortKey: "CODE",
      },
      {
        name: "name",
        filterKey: "name_Text",
      },
      {
        name: "owner",
        type: "object",
        resource: "staff",
        filterKey: "owner_Overlap",
      },
      {
        name: "groupCount",
        type: "integer",
        filterKey: "groupCount_Range",
        sortKey: "GROUP_COUNT",
      },
      {
        name: "artistCount",
        type: "integer",
        filterKey: "artistCount_Range",
        sortKey: "ARTIST_COUNT",
      },
      ...ListPage.fields.canActivate,
      ...ListPage.fields.tracked,
    ]}
    query={QUERY}
    extraRowActions={ListPage.extraRowActions.canActivate}
  />
);

// ==

const QUERY = gql`
  query companiesForCompanyList(
    $filter: CompanyFilterInput
    $sort: [CompanySortInput]
    $page: PageInput
  ) {
    companies(filter: $filter, sort: $sort, page: $page) {
      objects {
        id
        code
        name
        owner {
          id
          user {
            id
            email
            name
          }
        }
        groupCount
        artistCount
        ...TrackedFields
        ...CanActivateFields
      }
      ...ListFields
    }
  }
  ${ListPage.fragments.list}
  ${ListPage.fragments.tracked}
  ${ListPage.fragments.canActivate}
`;
