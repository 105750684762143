import { Layout as AntdLayout, Grid } from "antd";

import { LayoutProvider } from "../../contexts/layout";
import { Header } from "./Header";
import { Sider } from "./Sider";

export const Layout = ({ children }) => {
  const breakpoint = Grid.useBreakpoint();
  const isSmall = typeof breakpoint.sm === "undefined" ? true : breakpoint.sm;

  return (
    <LayoutProvider>
      <AntdLayout style={{ minHeight: "100vh" }}>
        <Sider fixed />
        <AntdLayout>
          <Header fixed />
          <AntdLayout.Content>
            <div
              style={{
                padding: isSmall ? 24 : 12,
                marginTop: "44px",
              }}
            >
              {children}
            </div>
          </AntdLayout.Content>
        </AntdLayout>
      </AntdLayout>
    </LayoutProvider>
  );
};
