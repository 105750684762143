import { useTranslation } from "react-i18next";
import Markdown from "react-markdown";
import { useParams } from "react-router-dom";

import { gql, useQuery } from "@apollo/client";

import { Card, Col, Descriptions, Divider, Row, Space, Tabs, Typography } from "antd";

import dayjs from "dayjs";

import {
  CAN_ACTIVATE_FRAGMENT,
  TRACKED_FRAGMENT,
  TRANSLATABLE_CONTENT_FRAGMENT,
} from "../../utils/gql/fragments";

const { Title } = Typography;

export const BoothAnnouncementWidget = ({
  query = QUERY,
  tabs = undefined,
  children = undefined,
}) => {
  const { t } = useTranslation();

  const params = useParams();
  const { data: boothData } = useQuery(query, {
    variables: { id: params.id },
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  });

  const data = boothData?.booth;
  const hasContent = tabs?.length > 0 || children;
  const descriptions = [
    {
      key: "1",
      label: t("admin.field.createdAt"),
      children: dayjs(data?.createdAt).format("YYYY-MM-DD HH:mm"),
    },
    {
      key: "2",
      label: t("admin.field.createdBy"),
      children: data?.createdBy?.name || "-",
    },
    {
      key: "3",
      label: t("admin.field.updatedAt"),
      children: dayjs(data?.updatedAt).format("YYYY-MM-DD HH:mm"),
    },
    {
      key: "4",
      label: t("admin.field.updatedBy"),
      children: data?.updatedBy?.name || "-",
    },
  ];

  const languageTabs = data?.announcementContent?.values.map(
    ({ id, language, value }) => ({
      key: language,
      label: language,
      children: (
        <div key={id} style={styles.announcementSection}>
          <Markdown className="markdown">{value}</Markdown>
        </div>
      ),
    }),
  );

  return (
    <Space direction="vertical" size={0} split={<Divider />} style={styles.container}>
      <Row gutter={24}>
        <Col lg={hasContent ? 6 : 24}>
          <Card>
            <Space direction="vertical" size={0}>
              <Title level={2}>{data?.name}</Title>
              <Descriptions
                items={descriptions}
                column={{ xs: 1, sm: 2, md: 3, lg: 3, xl: 4, xxl: 4 }}
                size="small"
              />
              <Divider size="small" />
              <Tabs type="card" items={languageTabs} />
            </Space>
          </Card>
        </Col>
      </Row>
    </Space>
  );
};

// ==

const QUERY = gql`
  query boothForBoothAnnouncementWidget($id: ID!) {
    booth(filter: { id_Overlap: [$id] }) {
      id
      announcementContent {
        ...TranslatableContentFields
      }
      ...TrackedFields
      ...CanActivateFields
    }
  }
  ${TRANSLATABLE_CONTENT_FRAGMENT}
  ${TRACKED_FRAGMENT}
  ${CAN_ACTIVATE_FRAGMENT}
`;

// ==

const styles = {
  container: {
    marginTop: 20,
  },
  announcementSection: {
    marginBottom: 16,
  },
};
