import { gql } from "@apollo/client";

import { ListWidget } from "../list";

export const EventV2NoticeListWidget = ({ filter, ...props }) => (
  <ListWidget
    resource="eventV2Notice"
    filter={{
      ...filter,
    }}
    fields={[
      {
        name: "id",
      },
      {
        name: "body",
        truncate: true,
      },
      {
        name: "isActive",
        type: "boolean",
      },
      ...ListWidget.fields.tracked,
    ]}
    query={QUERY}
    {...props}
  />
);

// ==

const QUERY = gql`
  query eventV2NoticesForEventV2NoticeListWidget(
    $filter: EventV2NoticeFilterInput
    $sort: [EventV2NoticeSortInput]
    $page: PageInput
  ) {
    eventV2Notices(filter: $filter, sort: $sort, page: $page) {
      objects {
        id
        event {
          id
          title
        }
        body
        isActive
        ...TrackedFields
      }
      ...ListFields
    }
  }
  ${ListWidget.fragments.list}
  ${ListWidget.fragments.tracked}
`;
