import { gql } from "@apollo/client";

import { ListPage } from "../../components/pages";

export const TranslationValueList = () => (
  <ListPage
    resource="translationValue"
    fields={[
      {
        name: "id",
      },
      {
        name: "code",
        type: "object",
        resource: "translationCode",
        filterKey: "code_Overlap",
      },
      {
        name: "language",
        type: "choice",
        enumType: "ServiceLanguage",
        filterKey: "language_Overlap",
      },
      {
        name: "value",
        filterKey: "value_Text",
      },
      {
        name: "status",
        type: "choice",
        enumType: "TranslationStatus",
        filterKey: "status_Overlap",
      },
      ...ListPage.fields.tracked,
    ]}
    query={QUERY}
    extraRowActions={ListPage.extraRowActions.delete}
  />
);

// ==

const QUERY = gql`
  query translationValuesForTranslationValueList(
    $filter: TranslationValueFilterInput
    $sort: [TranslationValueSortInput]
    $page: PageInput
  ) {
    translationValues(filter: $filter, sort: $sort, page: $page) {
      objects {
        id
        code {
          id
          code
        }
        language
        value
        status
        ...TrackedFields
      }
      ...ListFields
    }
  }
  ${ListPage.fragments.list}
  ${ListPage.fragments.tracked}
`;
