import { BuiltField } from "@/types/field";
import { CanBeNil } from "@/types/utils";
import { uniq } from "@/utils/helpers";

export function getUniqueEnumTypes(fields: CanBeNil<readonly BuiltField[]>) {
  if (!fields) return [];
  return uniq(
    fields
      .filter((field) => "enumType" in field)
      .map((field) => field.enumType)
      .filter(Boolean),
  );
}
