import React, { useMemo } from "react";

import { Card, Col, Row } from "antd";
import styled from "styled-components";

import { FieldsetContext } from "@/components/fields/context";
import { FieldMessage } from "@/components/form/FieldMessage";
import { Fields } from "@/components/form/Fields";
import { useFieldContext, useFormContext } from "@/components/form/context";
import { ResolvedText } from "@/components/wrapper";
import { LanguageTab } from "@/domain/language/components/LanguageTab";
import type { FieldsetField, ToBuiltField } from "@/types/field";

type Props = {
  field: ToBuiltField<FieldsetField>;
  parent?: string[];
};

const TRANSLATABLE_TYPES = ["translatable", "translatableFiles"];

export const Fieldset: React.FC<Props> = ({ field, parent = [] }) => {
  const { data, editingLanguage, setEditingLanguage } = useFormContext();
  const { passiveProps } = useFieldContext(field, parent);
  const isEveryFieldTranslatable = useMemo(
    () => field.fields.every((field) => TRANSLATABLE_TYPES.includes(`${field.type}`)),
    [field],
  );

  return (
    <FieldsetContext.Provider
      value={{
        hideLanguageSelect: isEveryFieldTranslatable,
      }}
    >
      <Row gutter={24}>
        <Col span={field.fieldsColSpan ?? field.sider ? 12 : 24}>
          <StyledCard
            id={field.id}
            title={
              (field.label || isEveryFieldTranslatable) && (
                <>
                  <div className="title">{field.label}</div>
                  {isEveryFieldTranslatable && (
                    <LanguageTab
                      activeKey={editingLanguage}
                      onChangeActiveKey={setEditingLanguage}
                      render={() => null}
                    />
                  )}
                </>
              )
            }
          >
            <ResolvedText
              wrapper={FieldMessage}
              wrapperProps={{ type: "fieldsetDescription" }}
              value={field.description}
              valueKey={field.descriptionKey}
              args={passiveProps}
            />
            <Fields fields={field.fields} parent={parent} />
          </StyledCard>
        </Col>
        <Col span={field.siderColSpan ?? field.sider ? 12 : 0}>
          {field.sider?.({ data })}
        </Col>
      </Row>
    </FieldsetContext.Provider>
  );
};

const StyledCard = styled(Card)`
  div.title {
    margin: 16px 0;
    &:only-child {
      margin: 0;
    }
  }
`;
