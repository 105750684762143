import { useCallback } from "react";
import { FormProvider, useForm } from "react-hook-form";

import type { DocumentNode } from "@apollo/client";

import { SearchOutlined } from "@ant-design/icons";
import { Button, Card, Descriptions, Divider } from "antd";
import styled from "styled-components";

import type { GqlFilter } from "@/gql/types";
import { useApp } from "@/hooks/app";
import { useFormFields } from "@/hooks/fields";
import { useTranslate } from "@/hooks/i18n";
import type { ResourceName } from "@/resources";
import type { Field } from "@/types/field";
import type { FilterField } from "@/types/filter/FilterField";
import type { RecordType } from "@/types/primitives";
import { stripFilterSuffix } from "@/utils/field";
import { isEmpty } from "@/utils/helpers";

import { Fields } from "../form";

type Props<Q extends DocumentNode, Filter extends GqlFilter<Q>> = {
  query: Q;
  resource: ResourceName;
  fields: FilterField<Filter>[];
  defaultValues?: Partial<Filter>;
  loading?: boolean;
  isResettable?: boolean;
  onSubmit: (data: Filter) => void;
  onReset?: () => void;
};

export const FilterForm = <Q extends DocumentNode, Filter extends GqlFilter<Q>>({
  resource: resourceName,
  fields: originalFields,
  defaultValues = {},
  loading = false,
  isResettable = false,
  onSubmit,
  onReset,
}: Props<Q, Filter>) => {
  const t = useTranslate();
  const { colorMode, resources } = useApp();
  const formContext = useForm({
    defaultValues: defaultValues as RecordType,
  });
  const {
    handleSubmit,
    setValue,
    reset,
    watch,
    formState: { errors, isDirty },
  } = formContext;

  const fieldPreProcessor = useCallback(
    (originalField: Field<Filter>) => {
      const field = { ...originalField };
      field["direction"] = "horizontal"; // set horizontal layout as default
      if (field.name) {
        const candidates = [
          `admin.field.${field.name}`,
          `admin.field.${stripFilterSuffix(field.name)}`,
        ];
        if (field.type === "object") {
          candidates.push(`admin.resource.${resources[field.resource]?.name}.singular`);
        }
        field["labelKey"] ??= candidates; // set default label key
      }
      return field as Field<Filter>;
    },
    [resources],
  );
  const { fields, normalizeData } = useFormFields({
    fields: originalFields,
    resource: resourceName,
    obj: null,
    fieldPreProcessor,
  });
  const handleReset = useCallback(() => {
    reset();
    onReset?.();
  }, [reset, onReset]);

  const data = watch();
  const disabled = loading || !isEmpty(errors) || !isDirty;
  const resetValue = useCallback(
    (fieldName: string) => setValue(fieldName, null),
    [setValue],
  );

  const validateSubmit = useCallback(
    async (data: RecordType) => {
      const normalizedData = normalizeData({ data: data as Filter });

      onSubmit(normalizedData);
    },
    [normalizeData, onSubmit],
  );

  const customFormContext = {
    defaultValues,
    errors,
    data,
    resetValue,
    isUpdate: false,
    editingLanguage: undefined,
    setEditingLanguage: () => {},
  };

  if (!fields) return null;

  return (
    <FormProvider {...formContext} {...customFormContext}>
      <StyledCard size="small" title={t("admin.common.searchFilter")}>
        <StyledDescriptions
          bordered
          column={1}
          style={{ overflowX: "scroll" }}
          labelStyle={{
            backgroundColor: colorMode === "dark" ? "#050505" : "#FAFAFA",
          }}
          items={fields
            .filter((field) => field.type === "fieldset")
            .map((field) => ({
              label: field.label,
              children: (
                <Fields mode="filter" className="subfields" fields={field.fields} />
              ),
            }))}
        />
        <Divider className="m-0" />
        <div className="flex justify-end gap-8 p-16">
          {onReset && (
            <Button type="default" disabled={!isResettable} onClick={handleReset}>
              {t("admin.common.reset")}
            </Button>
          )}
          <Button
            type="primary"
            icon={<SearchOutlined />}
            disabled={disabled}
            onClick={handleSubmit(validateSubmit)}
          >
            {t("admin.common.search")}
          </Button>
        </div>
      </StyledCard>
    </FormProvider>
  );
};

// ==

const StyledCard = styled(Card)`
  .ant-card-body {
    padding: 0;
  }
`;

const StyledDescriptions = styled(Descriptions)`
  .ant-descriptions-view {
    border: none !important;
  }
  .ant-descriptions-item-label {
    text-align: center;
    position: sticky;
    left: 0;
    z-index: 10;
    background: white;
  }
  .ant-descriptions-item-content {
    padding: 11px 16px !important;
    position: relative;
    z-index: 1;
  }
`;
