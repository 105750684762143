import { useTranslation } from "react-i18next";

import { Typography } from "antd";

import { PageWithGroup } from "../../components/pages";
import { GroupTabSettingContent } from "./GroupTabSettingContent";

const { Text } = Typography;

export const GroupTabSetting = () => {
  const { t } = useTranslation();
  return (
    <PageWithGroup
      titleKey="admin.page.groupTabSetting.title"
      subtitleKey="admin.page.groupTabSetting.subtitle"
    >
      {({ group }) =>
        group ? (
          <GroupTabSettingContent groupId={group} />
        ) : (
          <Text type="warning">{t("admin.message.selectGroupFirst")}</Text>
        )
      }
    </PageWithGroup>
  );
};
