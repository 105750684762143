import { forwardRef, useCallback, useEffect, useState } from "react";

import { InputNumber, Space } from "antd";

import type { Merge } from "ts-essentials";

import { useFormContext } from "@/components/form/context";
import { useTranslate } from "@/hooks/i18n";
import type { ControllerFieldProps } from "@/types/form";
import type { CanBeNil } from "@/types/utils";
import { isNullOrUndefined } from "@/utils/helpers";

type Props = Merge<
  Partial<ControllerFieldProps>,
  {
    min: number;
    max: number;
    /** @default false */
    float?: boolean;
    onChange: (value: [number, number] | null) => void;
    value: CanBeNil<[number, number]>;
  }
>;

export const RangeInput = forwardRef<HTMLInputElement, Props>((props, ref) => {
  const {
    status,
    name,
    min = 0,
    max = Number.MAX_VALUE,
    value,
    float = false,
    onChange,
  } = props;
  const t = useTranslate();
  const [lower, setLower] = useState<CanBeNil<number>>(value?.[0]);
  const [upper, setUpper] = useState<CanBeNil<number>>(value?.[1]);
  const form = useFormContext();

  const normalize = useCallback(
    (n: CanBeNil<number>) => {
      if (isNullOrUndefined(n)) return null;
      if (float) return n;
      return Math.floor(n);
    },
    [float],
  );

  useEffect(() => {
    if (lower === null && upper === null) {
      form?.clearErrors(name);
      return onChange(null);
    }
    const [l, r] = ([lower, upper] as const).map(normalize);
    const inputCompleted = l !== null && r !== null && !isNaN(l) && !isNaN(r);
    const valid = inputCompleted && l <= r && l >= min && r <= max;
    if (!inputCompleted) return;
    if (valid) {
      form?.clearErrors(name);
      onChange([l, r]);
      return;
    }
    if (name) {
      form?.setError(name, {
        message: t("admin.component.range.error.invalid_range", { min, max }),
      });
    }
  }, [lower, upper]);

  return (
    <Space>
      <InputNumber
        ref={ref}
        status={status}
        placeholder="Min"
        value={lower}
        min={min}
        max={max}
        onChange={(value) => setLower(value)}
      />
      <div>-</div>
      <InputNumber
        status={status}
        placeholder="Max"
        value={upper}
        min={min}
        max={max}
        onChange={(value) => setUpper(value)}
      />
    </Space>
  );
});
