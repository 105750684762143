import { useMemo } from "react";

import { BuiltField, BuiltFlatField } from "@/types/field";
import { isCompositeField, isContainerField } from "@/utils/field";

export function flattenFields(fields: BuiltField[]): BuiltFlatField[] {
  return fields.flatMap((field) => {
    if (isContainerField(field)) {
      return flattenFields(field.fields);
    }
    return field;
  });
}

export function useFlatFields(builtFields: BuiltField[]) {
  return useMemo(() => {
    const flatFields = flattenFields(builtFields);
    const flatFieldNames = flatFields.flatMap((field) =>
      isCompositeField(field) ? field.names : [field.name],
    );
    return { flatFields, flatFieldNames };
  }, [builtFields]);
}
