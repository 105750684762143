import { gql } from "@apollo/client";

import { ListPage, ListPageWithGroup } from "../../components/pages";

export const PremiumList = () => (
  <ListPageWithGroup
    groupFilterKey="group_Overlap"
    resource="premium"
    subtitleKey="admin.page.premium.subtitle"
    fields={[
      {
        name: "id",
      },
      {
        name: "group",
        type: "object",
        resource: "group",
        filterKey: "group_Overlap",
      },
      {
        name: "name",
        labelKey: "admin.field.name",
      },
      {
        name: "nickname",
        filterKey: "nickname_Exact",
        sortKey: "NICKNAME",
      },
      {
        name: "startAt",
        type: "datetime",
      },
      {
        name: "endAt",
        type: "datetime",
      },
      {
        name: "phase",
        type: "choice",
        enumType: "PremiumPhase",
      },
      {
        name: "membershipCount",
        type: "integer",
        filterKey: "membershipCount_Range",
        sortKey: "MEMBERSHIP_COUNT",
      },
      {
        name: "status",
        type: "choice",
        enumType: "PremiumStatus",
        filterKey: "status_Overlap",
      },
      {
        name: "activateAt",
        type: "datetime",
      },
      ...ListPage.fields.tracked,
    ]}
    query={QUERY}
  />
);

// ==

const QUERY = gql`
  query premiumsForPremiumList(
    $filter: PremiumFilterInput
    $sort: [PremiumSortInput]
    $page: PageInput
  ) {
    premiums(filter: $filter, sort: $sort, page: $page) {
      objects {
        id
        group {
          id
          name
        }
        name
        nickname
        startAt
        endAt
        phase
        membershipCount
        status
        ...TrackedFields
        ...CanActivateFields
      }
      ...ListFields
    }
  }
  ${ListPage.fragments.list}
  ${ListPage.fragments.tracked}
  ${ListPage.fragments.canActivate}
`;
