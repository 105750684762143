import { gql, useMutation } from "@apollo/client";

import { Button } from "antd";

import { t } from "i18next";

import { Can } from "../../components/permission";
import { useApp } from "../../hooks/app";
import { PREMIUM_MEMBERSHIP_FRAGMENT } from "../../utils/gql/fragments";
import { EventSurveyViewButton } from "../actions";
import { DownloadEventWinnerButton } from "../actions/DownloadEventWinnerButton";
import { ListWidget } from "../list";

export const EventWinnerListWidget = (props) => {
  const { message } = useApp();

  const [deleteWinner, { loading: deleteLoading, error: deleteError }] =
    useMutation(DELETE_MUTATION);
  const [announce, { loading: announceLoading, error: announceError }] =
    useMutation(ANNOUNCE_MUTATION);

  const eventId = parseInt(props.event?.id);

  const mutErros = [announceError, deleteError];
  mutErros.forEach((err) => {
    if (err) {
      console.error(err);
      message.error(JSON.stringify(err));
    }
  });

  return (
    <ListWidget
      resource="eventWinner"
      fields={[
        {
          name: "member",
          type: "object",
          resource: "member",
          filterKey: "member_Overlap",
        },
        {
          name: "order",
          type: "string",
          sortKey: "ORDER",
        },
        {
          name: "category",
          type: "choice",
          enumType: "EventWinnerCategory",
          filterKey: "category_Overlap",
        },
        {
          name: "application.premiumMembership.name",
          labelKey: "admin.field.name",
          filterKey: "application_PremiumMembership_Name_Text",
          getter: (obj) =>
            obj.application.premiumMembership?.name || obj.member.user.name || "-",
        },
        {
          name: "application.premiumMembership.identifier",
          labelKey: "admin.field.membershipIdentifier",
          filterKey: "application_PremiumMembership_Identifier_Text",
        },
        {
          name: "application.premiumMembership.birthDate",
          type: "date",
          labelKey: "admin.field.birthDate",
        },
        {
          name: "member.user.phone",
          type: "phone",
          labelKey: "admin.field.phone",
          filterKey: "member_User_Phone_Text",
        },
        {
          name: "member.user.email",
          labelKey: "admin.field.email",
          filterKey: "member_User_Email_Text",
        },
        {
          type: "choice",
          name: "member.status",
          labelKey: "admin.field.memberStatus",
          enumType: "MemberStatus",
        },
        {
          type: "boolean",
          name: "isActive",
          labelKey: "admin.field.isAnnounced",
          filterKey: "isActive_Exact",
        },
        {
          type: "datetime",
          name: "appliedAt",
          labelKey: "admin.field.eventAppliedAt",
          getter: (obj) => obj?.application?.appliedAt,
        },
      ]}
      size={30}
      query={QUERY}
      fetchPolicy="network-only"
      isRowSelectable={true}
      extraRowActions={({ obj }) => {
        if (obj.event.submitCategory === "SURVEY") {
          return (
            <EventSurveyViewButton eventId={obj.event.id} memberId={obj.member.id} />
          );
        }
      }}
      extraActions={({
        filter,
        sort,
        refetch,
        result,
        selectedRowKeys,
        setSelectedRowKeys,
      }) => {
        const winnerIds = selectedRowKeys.map((item) => parseInt(item, 10));
        return (
          <div style={styles.extraActions}>
            <Can action="download" targetType="EVENT_APPLICATION">
              <DownloadEventWinnerButton
                style={styles.customActionButtons}
                count={result?.count}
                filter={filter}
                sort={sort}
                type="text"
                onlyIcon
              />
            </Can>
            <Can action="update" targetType="EVENT">
              <Button
                style={styles.customActionButtons}
                loading={deleteLoading}
                onClick={async () => {
                  const data = await deleteWinner({
                    variables: {
                      eventId: eventId,
                      winnerIds: winnerIds,
                    },
                  });
                  const parsed = data?.data?.deleteEventWinner;
                  if (parsed) {
                    if (parsed?.ok) {
                      const count = parsed?.count || 0;
                      message.success(`${count} items deleted`);
                      refetch();
                    } else {
                      const errMsg = parsed?.errors[0].messages.join("\n");
                      message.error(errMsg);
                    }
                  }

                  setSelectedRowKeys([]);
                }}
                disabled={
                  !winnerIds.length ||
                  result?.objects.length === 0 ||
                  result?.objects.some((item) => item.isActive)
                }
              >
                {t("admin.common.delete")}
              </Button>
              <Button
                type="primary"
                style={styles.customActionButtons}
                loading={announceLoading}
                onClick={async () => {
                  const data = await announce({ variables: { eventId: eventId } });
                  const parsed = data?.data?.announceEventWinner;
                  if (parsed) {
                    if (parsed?.ok) {
                      message.success("당첨자 발표 요청이 처리되었습니다.");
                      refetch();
                    } else {
                      const errMsg = parsed?.errors[0].messages.join("\n");
                      message.error(errMsg);
                    }
                  }

                  setSelectedRowKeys([]);
                }}
                disabled={
                  !(winnerIds.length === 0) ||
                  result?.objects.length === 0 ||
                  result?.objects.some((item) => item.isActive)
                }
              >
                {t("admin.common.announce")}
              </Button>
            </Can>
          </div>
        );
      }}
      {...props}
    />
  );
};

// ==

const QUERY = gql`
  query EventWinnerListWidget(
    $filter: EventWinnerFilterInput
    $sort: [EventWinnerSortInput]
    $page: PageInput
  ) {
    eventWinners(filter: $filter, sort: $sort, page: $page) {
      objects {
        id
        event {
          id
          group {
            id
          }
          submitCategory
        }
        member {
          id
          nickname
          user {
            id
            name
            phone
            email
            isActive
          }
          isActive
          status
        }
        application {
          premiumMembership {
            ...PremiumMembershipFields
          }
          updatedAt
          appliedAt
        }
        order
        category
        ...TrackedFields
        ...CanActivateFields
      }
      ...ListFields
    }
  }

  ${ListWidget.fragments.list}
  ${ListWidget.fragments.tracked}
  ${ListWidget.fragments.canActivate}
  ${PREMIUM_MEMBERSHIP_FRAGMENT}
`;

const DELETE_MUTATION = gql`
  mutation DeleteEventWinner($eventId: ID!, $winnerIds: [Int!]!) {
    deleteEventWinner(eventId: $eventId, winnerIds: $winnerIds) {
      ok
      count
      errors {
        code
        title
        messages
      }
    }
  }
`;
const ANNOUNCE_MUTATION = gql`
  mutation AnnounceEventWinnerMutation($eventId: ID!) {
    announceEventWinner(eventId: $eventId) {
      ok
      errors {
        code
        title
        messages
      }
    }
  }
`;

const styles = {
  extraActions: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  customActionButtons: {
    marginRight: "10px",
  },
};
