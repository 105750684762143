import { Button } from "antd";

import { t } from "i18next";

import { useApp } from "../../hooks/app";

export const ShareURLButton = ({ url }) => {
  const { message } = useApp();

  return (
    <Button
      onClick={() => {
        navigator.clipboard.writeText(url);
        message.success("URL copied to clipboard.");
      }}
      style={styles.customActionButtons}
    >
      Copy URL
    </Button>
  );
};

// ==

const styles = {
  customActionButtons: {
    marginRight: "10px",
  },
};
