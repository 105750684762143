const config = {
  graphqlEndpoint: import.meta.env.VITE_GOD_GRAPHQL_ENDPOINT,
  metaEndpoint: import.meta.env.VITE_GOD_META_ENDPOINT,
  missaEndpoint: import.meta.env.VITE_GOD_MISSA_ENDPOINT,
  sentryDsn: import.meta.env.VITE_GOD_SENTRY_DSN,
  sentryEnvironment: import.meta.env.VITE_GOD_SENTRY_ENVIRONMENT,
  uploadName: "file",
  layoutTitle: "FANS ADMIN",
  defaultPageSize: 20,
  defaultWidgetPageSize: 20,
  defaultSort: [
    {
      type: "CREATED_AT",
      direction: "DESC",
    },
  ],
  tokenKey: "token",
  guidKey: "guid",
  languageKey: "language",
  resetAuthErrorCodes: ["INVALID_TOKEN", "EXPIRED_TOKEN", "INACTIVE_USER"] as string[],
  defaultDrawerWidth: 500,
  defaultContext: "admin",
  defaultPagination: "offset",
  supportedLanguages: ["en", "ko"],
} as const;

export default config;
