import { gql } from "@apollo/client";

import { ListPage, ListPageWithGroup } from "../../components/pages";

export const EventV2NoticeList = () => (
  <ListPageWithGroup
    resource="eventV2Notice"
    subtitleKey="admin.page.notice.subtitle"
    fields={[
      {
        name: "id",
      },
      {
        name: "event",
        type: "object",
        resource: "eventV2",
        filterKey: "eventV2_Overlap",
      },
      {
        name: "body",
        truncate: true,
      },
      {
        name: "activateAt",
        type: "datetime",
      },
      ...ListPage.fields.tracked,
    ]}
    query={QUERY}
    extraRowActions={ListPage.extraRowActions.canActivate}
  />
);

// ==

const QUERY = gql`
  query eventV2NoticesForEventV2NoticeList(
    $filter: EventV2NoticeFilterInput
    $sort: [EventV2NoticeSortInput]
    $page: PageInput
  ) {
    eventV2Notices(filter: $filter, sort: $sort, page: $page) {
      objects {
        id
        body
        event {
          id
          title
        }
        ...TrackedFields
        ...CanActivateFields
      }
      ...ListFields
    }
  }
  ${ListPage.fragments.list}
  ${ListPage.fragments.tracked}
  ${ListPage.fragments.canActivate}
`;
