import { forwardRef } from "react";
import { useTranslation } from "react-i18next";

import { gql, useLazyQuery } from "@apollo/client";

import { EditOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Col, Row, Space } from "antd";

import { v4 as uuidv4 } from "uuid";

import { TRANSLATABLE_CONTENT_FRAGMENT } from "../../utils/gql/fragments";
import { InlineEditMediaLayoutSectionAction } from "../actions";
import { SortableTable } from "../list";

export const MediaLayoutSectionEditor = forwardRef(
  ({ value, onChange, disabled = false, baseFilter = undefined }, ref) => {
    const { t } = useTranslation();
    const [fetchPlaylist] = useLazyQuery(PLAYLIST_QUERY);
    const [fetchClip] = useLazyQuery(CLIP_QUERY);
    const [fetchPremium] = useLazyQuery(PREMIUM_QUERY);

    const normalizeSection = async (section) => {
      const [playlist, clip, premium] = await Promise.all([
        section.playlist
          ? fetchPlaylist({ variables: { id: section.playlist } }).then(
              (response) => response.data.playlist,
            )
          : null,
        section.clip
          ? fetchClip({ variables: { id: section.clip } }).then(
              (response) => response.data.clip,
            )
          : null,
        section.premium
          ? fetchPremium({ variables: { id: section.premium } }).then(
              (response) => response.data.premium,
            )
          : null,
      ]);

      section.playlist = playlist;
      section.clip = clip;
      section.premium = premium;

      return section;
    };

    const handleChange = async (raw, obj) => {
      const section = await normalizeSection(raw);
      const newValue = value.map((item) => {
        if (item.__id === obj.__id) {
          let updatedItem = { ...item, ...section };
          if (updatedItem.source === "PREMIUM_ONLY") {
            updatedItem.playlist = null;
          }
          if (updatedItem.source === "CUSTOM") {
            updatedItem.premium = null;
          }
          return updatedItem;
        }
        return item;
      });
      onChange?.(newValue);
    };

    value?.forEach((item) => {
      item.__id ??= uuidv4();
    });

    return (
      <Space direction="vertical" style={styles.container}>
        <Row justify="space-between" align="top">
          <Col>
            {t("admin.component.mediaLayoutSectionEditor.sectionCount", {
              count: value?.length ?? 0,
            })}
          </Col>
          <Col>
            <InlineEditMediaLayoutSectionAction
              title={t("admin.common.actionTitle", {
                action: t("admin.common.add"),
                name: t("admin.common.section"),
              })}
              okButtonText={t("admin.common.add")}
              baseFilter={baseFilter}
              disabled={disabled}
              onChange={async (raw) =>
                onChange?.([...(value ?? []), await normalizeSection(raw)])
              }
            >
              {({ openDrawer, disabled }) => (
                <Button
                  type="link"
                  icon={<PlusOutlined />}
                  disabled={disabled}
                  onClick={openDrawer}
                >
                  {t("admin.common.actionTitle", {
                    action: t("admin.common.add"),
                    name: t("admin.common.section"),
                  })}
                </Button>
              )}
            </InlineEditMediaLayoutSectionAction>
          </Col>
        </Row>
        <SortableTable
          resource="mediaLayoutSection"
          fields={[
            {
              name: "title",
              // TODO: Consider active language
              render: (_, row) => row.titleContent?.values?.[0]?.value,
            },
            {
              name: "category",
              type: "choice",
              enumType: "MediaLayoutSectionCategory",
            },
            {
              name: "source",
              type: "choice",
              enumType: "MediaLayoutSectionSource",
            },
            {
              name: "playlist",
              type: "object",
              resource: "playlist",
            },
            {
              name: "clip",
              type: "object",
              resource: "clip",
            },
            {
              name: "premium",
              type: "object",
              resource: "premium",
            },
          ]}
          hideDelete={(obj) => obj.isDefault}
          extraActions={({ obj }) => (
            <InlineEditMediaLayoutSectionAction
              title={t("admin.common.actionTitle", {
                action: t("admin.common.edit"),
                name: t("admin.common.section"),
              })}
              obj={obj}
              baseFilter={baseFilter}
              onChange={async (raw) => handleChange(raw, obj)}
            >
              {({ openDrawer }) => (
                <Button icon={<EditOutlined />} size="small" onClick={openDrawer} />
              )}
            </InlineEditMediaLayoutSectionAction>
          )}
          items={value}
          onChange={(items) => onChange?.(items)}
          disabled={disabled}
          pagination={false}
          rowKey="__id"
          size="small"
        />
      </Space>
    );
  },
);

MediaLayoutSectionEditor.fragments = {
  mediaLayoutSection: gql`
    fragment MediaLayoutSectionEditorMediaLayoutSectionFields on MediaLayoutSection {
      playlist {
        id
        name
      }
      clip {
        id
        title
      }
      premium {
        id
        name
      }
      category
      source
      titleContent {
        ...TranslatableContentFields
      }
      subtitleContent {
        ...TranslatableContentFields
      }
      isDefault
    }
    ${TRANSLATABLE_CONTENT_FRAGMENT}
  `,
};

// ==

const PLAYLIST_QUERY = gql`
  query playlistForMediaLayoutSectionEditor($id: ID!) {
    playlist(filter: { id_Overlap: [$id] }) {
      id
      name
    }
  }
`;

const CLIP_QUERY = gql`
  query clipForMediaLayoutSectionEditor($id: ID!) {
    clip(filter: { id_Overlap: [$id] }) {
      id
      title
    }
  }
`;

const PREMIUM_QUERY = gql`
  query premiumForMediaLayoutSectionEditor($id: ID!) {
    premium(filter: { id_Overlap: [$id] }) {
      id
      nickname
    }
  }
`;

// ==

const styles = {
  container: {
    width: "100%",
  },
};
