import { forwardRef, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { gql, useQuery } from "@apollo/client";

import { Space } from "antd";

import { NumberWithCheckboxInput } from "../fields";

export const OfflineStoreItemRestrictionEditor = forwardRef(
  ({ value, onChange, isSingular, ...props }, ref) => {
    const [restrictions, setRestrictions] = useState(value || []);
    const { t } = useTranslation();
    const { data, loading, error } = useQuery(RESTRICTION_QUERY);

    useEffect(() => {
      onChange(restrictions);
    }, [restrictions]);

    if (loading) return <p>{t("Loading...")}</p>;
    if (error)
      return (
        <p>
          {t("Error!")} {error.message}
        </p>
      );
    if (!data || !data.enumValues) return <p>{t("No data found.")}</p>;

    const enums = data.enumValues.flat();

    const trimmedValue = (value) => {
      return value.filter((item) => item.count !== null);
    };

    const handleInputChange = (data) => {
      if (restrictions.length === 0 && data.count !== null) {
        setRestrictions([data]);
        onChange([data]);
        return;
      }

      const index = restrictions.findIndex((item) => item.category === data.category);
      if (index !== -1) {
        const updatedRestrictionsValue = [...restrictions];
        updatedRestrictionsValue[index] = data;
        setRestrictions(trimmedValue(updatedRestrictionsValue));
      } else {
        setRestrictions(trimmedValue([...restrictions, data]));
      }
    };

    const generateRestrictionComponents = (enums) => {
      const trimmedEnums = isSingular
        ? enums.filter((item) => item.name === "USER")
        : enums;
      return trimmedEnums.map((restriction) => {
        return (
          <NumberWithCheckboxInput
            key={restriction.name}
            enumName={restriction.name}
            checkboxText={t(restriction.description)}
            helpKey={t("admin.form.guide.restriction")}
            onChange={(newValue) => {
              handleInputChange(newValue);
            }}
            defaultValue={value?.find((item) => item.category === restriction.name)}
            ref={ref}
            {...props}
          />
        );
      });
    };
    return <Space direction="vertical">{generateRestrictionComponents(enums)}</Space>;
  },
);
// =
const RESTRICTION_QUERY = gql`
  query offlineStoreItemRestrictionCategories {
    enumValues(names: "OfflineStoreItemRestrictionCategory") {
      name
      description
    }
  }
`;
