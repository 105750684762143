import { gql } from "@apollo/client";

import { FormPage } from "../../components/pages";

export const PostForm = (props) => (
  <FormPage
    resource="post"
    fields={[
      {
        type: "fieldset",
        fields: [
          {
            name: "group",
            type: "object",
            resource: "group",
            disabled: ({ isUpdate }) => isUpdate,
            onChange: ({ resetField }) => resetField("member"),
          },
          {
            name: "member",
            type: "object",
            resource: "member",
            disabled: ({ data, isUpdate }) => !data.group || isUpdate,
            baseFilter: ({ data }) => ({ group_Overlap: [data.group] }),
          },
        ],
      },
      {
        type: "fieldset",
        labelKey: "admin.fieldset.content",
        fields: [
          {
            name: "body",
            rows: 10,
          },
          {
            name: "attachments",
            type: "genericMeta",
            multiple: true,
          },
        ],
      },
    ]}
    query={QUERY}
    createMutation={CREATE_MUTATION}
    updateMutation={UPDATE_MUTATION}
    {...props}
  />
);

// ==

const FORM_FIELDS_FRAGMENT = gql`
  fragment PostFormFields on Post {
    id
    group {
      id
    }
    member {
      id
    }
    body
    attachments {
      ...MediaFields
    }
  }
  ${FormPage.fragments.media}
`;

const QUERY = gql`
  query postForPostForm($id: ID!) {
    post(filter: { id_Overlap: [$id] }) {
      ...PostFormFields
    }
  }
  ${FORM_FIELDS_FRAGMENT}
`;

const CREATE_MUTATION = gql`
  mutation createPostForPostForm($data: PostMutationInput!) {
    createPost(data: $data) {
      ok
      errors {
        ...MutationErrorFields
      }
      object {
        ...PostFormFields
      }
    }
  }
  ${FormPage.fragments.mutationError}
  ${FORM_FIELDS_FRAGMENT}
`;

const UPDATE_MUTATION = gql`
  mutation updatePostForPostForm($data: PostMutationInput!, $id: ID!) {
    updatePost(data: $data, id: $id, partial: true) {
      ok
      errors {
        ...MutationErrorFields
      }
      object {
        ...PostFormFields
      }
    }
  }
  ${FormPage.fragments.mutationError}
  ${FORM_FIELDS_FRAGMENT}
`;
