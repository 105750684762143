import { useEffect, useRef } from "react";

export function useRecentObjects<T, K>(
  objects: T[] | null | undefined,
  keyGetter: (obj: T) => K,
) {
  const mapRef = useRef<Map<K, T>>(new Map());

  useEffect(() => {
    if (!objects) return;
    for (const object of objects) {
      mapRef.current.set(keyGetter(object), object);
    }
  }, [objects]);

  function objectByKey(id: K): T | undefined {
    return mapRef.current.get(id);
  }

  return { objectByKey };
}
