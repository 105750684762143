import React, { forwardRef, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Col, Input, Space } from "antd";

import { deepEqual } from "../../utils/helpers";

export const EventV2SurveyQuestionOptionEditor = forwardRef(
  ({ value: { options }, onChange, disabled, hidden, ...props }, ref) => {
    const [inputs, setInputs] = useState(options || []);
    const defaultInputs = {
      value: "",
    };

    useEffect(() => {
      if (inputs.length === 0) {
        return;
      }
      if (!deepEqual(inputs, options)) {
        onChange?.({ options: inputs });
      }
      if (hidden) {
        setInputs([defaultInputs]);
      }
    }, [inputs]);

    const { t } = useTranslation();

    const handleInputChange = (index, e) => {
      const newInputs = [...inputs];
      newInputs[index].value = e.target.value;
      setInputs(newInputs);
      onChange(newInputs);
    };

    const handleRemove = (index) => {
      const newInputs = [...inputs];
      newInputs.splice(index, 1);
      setInputs(newInputs);
      onChange(newInputs);
    };

    const handleAdd = () => {
      setInputs([...inputs, defaultInputs]);
    };

    return (
      !hidden && (
        <Col span={20} style={styles.container}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              width: "100%",
              paddingBottom: "8px",
            }}
          >
            {t("admin.form.guide.surveyQuestionOptions")}
          </div>
          <Space direction="vertical" size="middle" style={{ width: "100%" }}>
            {inputs?.map((input, index) => (
              <div
                key={index}
                style={{ display: "flex", alignItems: "center", width: "100%" }}
              >
                <Space direction="vertical" style={{ flexGrow: 1 }}>
                  <Input
                    disabled={disabled}
                    placeholder={`Option ${index + 1}`}
                    value={input.value}
                    onChange={(e) => handleInputChange(index, e)}
                  />
                </Space>
                <Button
                  disabled={disabled}
                  type="text"
                  icon={<DeleteOutlined />}
                  onClick={() => handleRemove(index)}
                  style={{ marginLeft: "10px", alignSelf: "center" }}
                />
              </div>
            ))}
            <Button
              disabled={disabled}
              type="link"
              icon={<PlusOutlined />}
              onClick={handleAdd}
            >
              add option
            </Button>
          </Space>
        </Col>
      )
    );
  },
);

// ==

const styles = {
  container: {
    width: "100%",
  },
};
