import { gql } from "@apollo/client";

import { FormPage } from "../../components/pages";
import { TRANSLATION_VALUE_FRAGMENT } from "../../utils/gql/fragments";

export const TranslationValueForm = () => (
  <FormPage
    resource="translationValue"
    fields={[
      {
        name: "code",
        type: "object",
        resource: "translationCode",
      },
      {
        name: "language",
        type: "choice",
        enumType: "ServiceLanguage",
      },
      {
        name: "value",
        rows: 10,
      },
    ]}
    query={QUERY}
    createMutation={CREATE_MUTATION}
    updateMutation={UPDATE_MUTATION}
  />
);

// ==

const QUERY = gql`
  query translationValueForTranslationValueForm($id: ID!) {
    translationValue(filter: { id_Overlap: [$id] }) {
      id
      code {
        id
        code
      }
      language
      value
    }
  }
`;

const CREATE_MUTATION = gql`
  mutation createTranslationValueForTranslationValueForm(
    $data: TranslationValueMutationInput!
  ) {
    createTranslationValue(data: $data) {
      ok
      errors {
        ...MutationErrorFields
      }
      object {
        ...TranslationValueFields
      }
    }
  }
  ${FormPage.fragments.mutationError}
  ${TRANSLATION_VALUE_FRAGMENT}
`;

const UPDATE_MUTATION = gql`
  mutation updateTranslationValueForTranslationValueForm(
    $data: TranslationValueMutationInput!
    $id: ID!
  ) {
    updateTranslationValue(data: $data, id: $id, partial: true) {
      ok
      errors {
        ...MutationErrorFields
      }
      object {
        ...TranslationValueFields
      }
    }
  }
  ${FormPage.fragments.mutationError}
  ${TRANSLATION_VALUE_FRAGMENT}
`;
