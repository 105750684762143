import { gql } from "@apollo/client";

import { ListWidget } from "../list";

export const CountryListWidget = (props) => (
  <ListWidget
    resource="country"
    fields={[
      {
        name: "id",
      },
      {
        name: "continent",
        type: "object",
        resource: "continent",
        filterKey: "continent_Overlap",
      },
      {
        name: "subcontinent",
        type: "object",
        resource: "subcontinent",
        filterKey: "subcontinent_Overlap",
      },
      {
        name: "code",
        filterKey: "code_Exact",
        sortKey: "CODE",
        width: "15%",
      },
      {
        name: "name",
        filterKey: "name_Text",
        sortKey: "NAME",
      },
      ...ListWidget.fields.canActivate,
    ]}
    query={QUERY}
    sort={[
      {
        type: "CODE",
        direction: "ASC",
      },
    ]}
    extraRowActions={ListWidget.extraRowActions.canActivate}
    {...props}
  />
);

// ==

const QUERY = gql`
  query countriesForCountryListWidget(
    $filter: CountryFilterInput
    $sort: [CountrySortInput]
    $page: PageInput
  ) {
    countries(filter: $filter, sort: $sort, page: $page) {
      objects {
        id
        continent {
          id
          name
        }
        subcontinent {
          id
          name
        }
        code
        name
        ...CanActivateFields
      }
      ...ListFields
    }
  }
  ${ListWidget.fragments.list}
  ${ListWidget.fragments.canActivate}
`;
