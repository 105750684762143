import { gql } from "@apollo/client";

import { EventPenaltyActivateButton } from "../../components/actions/EventPenaltyActivateButton";
import { ListPage, ListPageWithGroup } from "../../components/pages";
import { Can } from "../../components/permission";

export const EventPenaltyList = () => (
  <ListPageWithGroup
    groupFilterKey="event_Group_Overlap"
    resource="eventPenalty"
    subtitleKey="admin.page.eventPenalty.subtitle"
    fields={[
      {
        name: "id",
      },
      {
        name: "event.group",
        type: "object",
        resource: "group",
        filterKey: "event_Group_Overlap",
      },
      {
        name: "event",
        type: "object",
        resource: "event",
        filterKey: "event_Overlap",
      },
      {
        name: "member",
        type: "object",
        resource: "member",
        filterKey: "member_Overlap",
      },
      {
        type: "choice",
        name: "member.status",
        labelKey: "admin.field.memberStatus",
        enumType: "MemberStatus",
      },
      {
        name: "memo",
        type: "string",
        labelKey: "admin.field.memo",
        truncate: true,
      },
      {
        name: "isActive",
        type: "boolean",
      },
      ...ListPage.fields.tracked,
    ]}
    query={QUERY}
    defaultSort={[
      {
        type: "CREATED_AT",
        direction: "DESC",
      },
    ]}
    size={50}
    isRowSelectable={true}
    getCheckboxProps={(record) => ({
      disabled: record?.member?.status !== "ACTIVE",
    })}
    extraRowActions={ListPage.extraRowActions.canActivate}
    extraActions={({ refetch, selectedRowKeys, setSelectedRowKeys }) => {
      const penaltyIds = selectedRowKeys.map((item) => parseInt(item, 10));
      return (
        <Can action="update" targetType="EVENT_PENALTY">
          <EventPenaltyActivateButton
            isActive={true}
            buttonText={"활성화"}
            penaltyIds={penaltyIds}
            setSelectedRowKeys={setSelectedRowKeys}
            refetch={refetch}
          />
          <EventPenaltyActivateButton
            isActive={false}
            buttonText={"비활성화"}
            penaltyIds={penaltyIds}
            setSelectedRowKeys={setSelectedRowKeys}
            refetch={refetch}
          />
        </Can>
      );
    }}
  />
);

// ==

const QUERY = gql`
  query eventPenaltiesForEventPenaltyList(
    $filter: EventPenaltyFilterInput
    $sort: [EventPenaltySortInput]
    $page: PageInput
  ) {
    eventPenalties(filter: $filter, sort: $sort, page: $page) {
      objects {
        id
        event {
          id
          title
          group {
            id
            name
          }
        }
        member {
          id
          nickname
          user {
            id
            name
          }
          status
        }
        memo
        ...TrackedFields
        ...CanActivateFields
      }
      ...ListFields
    }
  }
  ${ListPage.fragments.list}
  ${ListPage.fragments.tracked}
  ${ListPage.fragments.canActivate}
`;
