import { gql } from "@apollo/client";

import { LanguageOrderEditButton } from "../../components/actions/LanguageOrderEditButton";
import { ListPage } from "../../components/pages";

export const LanguageList = () => (
  <ListPage
    resource="language"
    fields={[
      {
        name: "order",
        sortKey: "ORDER",
      },
      {
        name: "id",
      },
      {
        name: "code",
        sortKey: "CODE",
      },
      {
        name: "name",
        filterKey: "name_Text",
        sortKey: "NAME",
      },
      {
        name: "nativeName",
        filterKey: "nativeName_Text",
      },
      {
        name: "isActiveForService",
        type: "boolean",
        filterKey: "isActiveForService_Exact",
      },
      {
        name: "isActiveForTranslation",
        type: "boolean",
        filterKey: "isActiveForTranslation_Exact",
      },
      {
        name: "isActiveForCaption",
        type: "boolean",
        filterKey: "isActiveForCaption_Exact",
      },
    ]}
    query={QUERY}
    defaultSort={[
      {
        type: "ORDER",
        direction: "ASC",
        missing: "LAST",
      },
      {
        type: "CODE",
        direction: "ASC",
      },
    ]}
    extraActions={({ refetch }) => <LanguageOrderEditButton onSuccess={refetch} />}
  />
);

// ==

const QUERY = gql`
  query languagesForLanguageList(
    $filter: LanguageFilterInput
    $sort: [LanguageSortInput]
    $page: PageInput
  ) {
    languages(filter: $filter, sort: $sort, page: $page) {
      objects {
        id
        code
        name
        nativeName
        order
        isActiveForService
        isActiveForTranslation
        isActiveForCaption
      }
      ...ListFields
    }
  }
  ${ListPage.fragments.list}
`;
