import { forwardRef } from "react";

import { gql } from "@apollo/client";

import { Typography } from "antd";

import { ObjectSelect } from "../fields";

export const SelectCountry = forwardRef((props, ref) => (
  <ObjectSelect
    resource="country"
    query={QUERY}
    optionRender={(country) => (
      <>
        {country.name}
        <Typography.Text type="secondary" style={styles.secondary}>
          {country.continent?.name}
        </Typography.Text>
      </>
    )}
    ref={ref}
    {...props}
  />
));

// ==

const QUERY = gql`
  query countriesForSelectCountry($filter: CountryFilterInput) {
    countries(filter: $filter, sort: [{ type: RELEVANCE, direction: DESC }]) {
      objects {
        id
        name
        continent {
          id
          name
        }
      }
    }
  }
`;

// ==

const styles = {
  secondary: {
    marginLeft: 8,
    fontSize: "0.8em",
  },
};
