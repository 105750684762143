import { gql } from "@apollo/client";

import {
  DownloadExternalPremiumMembershipsButton,
  UploadExternalPremiumMembershipsButton,
} from "../../components/actions";
import { DetailPage } from "../../components/pages";
import { Can } from "../../components/permission";
import {
  ExternalPremiumMembershipListWidget,
  PremiumMembershipListWidget,
} from "../../components/widgets";

export const PremiumDetail = () => (
  <DetailPage
    resource="premium"
    fields={[
      {
        name: "id",
      },
      {
        name: "group",
        type: "object",
        resource: "group",
      },
      {
        name: "nameContent",
        labelKey: "admin.field.name",
        type: "translatable",
      },
      {
        name: "nickname",
      },
      {
        name: "startAt",
        type: "datetime",
      },
      {
        name: "endAt",
        type: "datetime",
      },
      {
        name: "phase",
        type: "choice",
        enumType: "PremiumPhase",
      },
      {
        name: "cardImage",
        type: "imageMeta",
      },
      {
        name: "cardImageHorizontal",
        type: "imageMeta",
      },
      {
        name: "cardImageVertical",
        type: "imageMeta",
      },
      {
        name: "doesProvideCard",
        type: "boolean",
      },
      {
        name: "doesProvidePackage",
        type: "boolean",
      },
      {
        name: "doesProvideContent",
        type: "boolean",
      },
      {
        name: "doesProvideEvent",
        type: "boolean",
      },
      {
        name: "doesProvideTicket",
        type: "boolean",
      },
      {
        name: "membershipCount",
        type: "integer",
      },
      {
        name: "status",
        type: "choice",
        enumType: "PremiumStatus",
      },
      ...DetailPage.fields.canActivate,
      {
        name: "activateAt",
        type: "datetime",
      },
      ...DetailPage.fields.tracked,
    ]}
    query={QUERY}
    extraActions={DetailPage.extraActions.canActivate}
    tabs={[
      {
        path: "",
        labelKey: "admin.common.memberships",
        permission: {
          action: "list",
          targetType: "PREMIUM_MEMBERSHIP",
        },
        children: ({ obj }) => (
          <PremiumMembershipListWidget
            baseFilter={{
              premium_Overlap: [obj.id],
            }}
            createSearchParams={{ premium: obj.id }}
            hiddenFields={["premium.group", "premium"]}
          />
        ),
      },
      {
        path: "externalMembers",
        labelKey: "admin.common.externalMemberships",
        permission: {
          action: "list",
          targetType: "EXTERNAL_PREMIUM_MEMBERSHIP",
        },
        children: ({ obj }) => (
          <ExternalPremiumMembershipListWidget
            baseFilter={{
              premium_Overlap: [obj.id],
            }}
            createSearchParams={{ premium: obj.id }}
            hiddenFields={["premium.group", "premium"]}
            extraActions={({ filter, sort, result }) => (
              <>
                <Can action="download" targetType="EXTERNAL_PREMIUM_MEMBERSHIP">
                  <DownloadExternalPremiumMembershipsButton
                    count={result?.count}
                    filter={filter}
                    sort={sort}
                    type="text"
                    onlyIcon
                  />
                </Can>
                <Can action="upload" targetType="EXTERNAL_PREMIUM_MEMBERSHIP">
                  <UploadExternalPremiumMembershipsButton
                    premiumId={obj.id}
                    type="text"
                    onlyIcon
                  />
                </Can>
              </>
            )}
          />
        ),
      },
    ]}
  />
);

// ==

const QUERY = gql`
  query premiumForPremiumDetail($id: ID!) {
    premium(filter: { id_Overlap: [$id] }) {
      id
      group {
        id
        name
      }
      name
      nameContent {
        ...TranslatableContentFields
      }
      nickname
      startAt
      endAt
      phase
      cardImage {
        ...DetailImageFields
      }
      cardImageHorizontal {
        ...DetailImageFields
      }
      cardImageVertical {
        ...DetailImageFields
      }
      doesProvideCard
      doesProvidePackage
      doesProvideContent
      doesProvideEvent
      doesProvideTicket
      membershipCount
      status
      ...TrackedFields
      ...CanActivateFields
    }
  }
  ${DetailPage.fragments.image}
  ${DetailPage.fragments.tracked}
  ${DetailPage.fragments.canActivate}
  ${DetailPage.fragments.translatableContent}
`;
