import { forwardRef, useState } from "react";

import { DatePicker as AntdDatePicker, Space } from "antd";

import dayjs from "dayjs";

const { RangePicker } = AntdDatePicker;

export const DateRangePicker = forwardRef(($props, ref) => {
  const { onChange, current, disabledDate, ...props } = $props;
  const [value, setValue] = useState(
    props.items?.length > 0
      ? [dayjs(props.items[0]?.date), dayjs(props.items[1]?.date)]
      : [],
  );
  const handleOnChange = (dates) => {
    setValue(dates);
    if (onChange) {
      onChange(dates);
    }
  };
  const defaultDisabledDate = (current) => {
    return current < dayjs().subtract(1, "day") || current > dayjs().add(1, "month");
  };

  return (
    <Space direction="vertical" size={8}>
      <RangePicker
        ref={ref}
        value={value}
        onChange={handleOnChange}
        current={current}
        disabledDate={disabledDate ?? defaultDisabledDate}
        {...props}
      />
    </Space>
  );
});
