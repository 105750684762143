import { gql } from "@apollo/client";

import { FormPage } from "../../components/pages";

export const PlaylistEntryForm = () => (
  <FormPage
    resource="playlistEntry"
    fields={[
      {
        name: "playlist",
        type: "object",
        resource: "playlist",
        disabled: ({ isUpdate }) => isUpdate,
      },
      {
        name: "clip",
        type: "object",
        resource: "clip",
        disabled: ({ isUpdate }) => isUpdate,
      },
      {
        name: "order",
        type: "integer",
      },
    ]}
    query={QUERY}
    createMutation={CREATE_MUTATION}
    updateMutation={UPDATE_MUTATION}
  />
);

// ==

const FORM_FIELDS_FRAGMENT = gql`
  fragment PlaylistEntryFormFields on PlaylistEntry {
    id
    playlist {
      id
    }
    clip {
      id
    }
    order
  }
`;

const QUERY = gql`
  query playlistEntryForPlaylistEntryForm($id: ID!) {
    playlistEntry(filter: { id_Overlap: [$id] }) {
      ...PlaylistEntryFormFields
    }
  }
  ${FORM_FIELDS_FRAGMENT}
`;

const CREATE_MUTATION = gql`
  mutation createPlaylistEntryForPlaylistEntryForm($data: PlaylistEntryMutationInput!) {
    createPlaylistEntry(data: $data) {
      ok
      errors {
        ...MutationErrorFields
      }
      object {
        ...PlaylistEntryFormFields
      }
    }
  }
  ${FormPage.fragments.mutationError}
  ${FORM_FIELDS_FRAGMENT}
`;

const UPDATE_MUTATION = gql`
  mutation updatePlaylistEntryForPlaylistEntryForm(
    $data: PlaylistEntryMutationInput!
    $id: ID!
  ) {
    updatePlaylistEntry(data: $data, id: $id, partial: true) {
      ok
      errors {
        ...MutationErrorFields
      }
      object {
        ...PlaylistEntryFormFields
      }
    }
  }
  ${FormPage.fragments.mutationError}
  ${FORM_FIELDS_FRAGMENT}
`;
