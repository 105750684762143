import { gql } from "@apollo/client";

import { ActivateButton } from "../../components/actions";
import { DetailPage } from "../../components/pages";
import { Can } from "../../components/permission";
import { BoothAnnouncementWidget } from "../../components/widgets/BoothAnnouncementWidget.jsx";
import { BoothNoticeListWidget } from "../../components/widgets/BoothNoticeListWidget.jsx";
import { BoothWaitListListWidget } from "../../components/widgets/BoothWaitListListWidget.jsx";

export const BoothDetail = () => (
  <DetailPage
    resource="booth"
    fields={[
      {
        name: "id",
      },
      {
        name: "group",
        type: "object",
        resource: "group",
        getter: ({ venue }) => venue.group,
      },
      {
        name: "venue",
        type: "object",
        resource: "venue",
      },
      {
        name: "titleContent",
        labelKey: "admin.field.boothTitle",
        type: "translatable",
      },
      {
        name: "operationStatus",
        type: "choice",
        enumType: "BoothOperationStatus",
      },
      {
        name: "waitStatus",
        type: "choice",
        enumType: "BoothWaitStatus",
      },
      {
        name: "exposedStatus",
        type: "choice",
        enumType: "BoothExposedStatus",
      },
      {
        name: "operatingHours",
        type: "timeWindow",
      },
      {
        name: "locationContent",
        labelKey: "admin.field.location",
        type: "translatable",
      },
      {
        name: "premium",
        labelKey: "admin.field.premiumOnly",
        type: "boolean",
        getter: (obj) => Boolean(obj.premium),
        filterKey: "premium_Exists",
      },
      {
        name: "longitude",
        labelKey: "admin.field.longitude",
      },
      {
        name: "latitude",
        labelKey: "admin.field.latitude",
      },
      {
        name: "radius",
        getter: ({ radius }) => `${radius}km`,
      },
      {
        name: "groupSize",
        type: "integer",
      },
      {
        name: "displayWaitListNumber",
        labelKey: "admin.field.displayWaitListNumber",
        type: "boolean",
      },
      {
        name: "averageWaitingMinutes",
        type: "integer",
      },
      {
        name: "dailyWaitListLimit",
        type: "integer",
      },
      ...DetailPage.fields.canActivate,
      ...DetailPage.fields.tracked,
    ]}
    query={QUERY}
    extraActions={({ obj, refetch, resource }) => {
      return (
        <>
          <Can
            action={obj.isActive ? "inactivate" : "activate"}
            targetType={resource.typeName}
            targetId={obj.id}
          >
            <ActivateButton
              targetType={resource.typeName}
              targetId={obj.id}
              value={obj.isActive}
              onChange={() => refetch()}
            ></ActivateButton>
          </Can>
        </>
      );
    }}
    tabs={[
      {
        path: "",
        labelKey: "admin.resource.boothWaitList.plural",
        permission: {
          action: "list",
          targetType: "BOOTH_WAIT_LIST_ENTRY",
        },
        children: ({ obj }) => (
          <BoothWaitListListWidget
            booth={obj}
            baseFilter={{ booth_Overlap: obj.id }}
            hiddenFields={["booth"]}
          />
        ),
      },
      {
        path: "announce",
        labelKey: "admin.field.boothAnnouncement",
        permission: {
          action: "read",
          targetType: "BOOTH",
        },
        children: ({ obj }) => (
          <BoothAnnouncementWidget baseFilter={{ booth_Overlap: obj.id }} />
        ),
      },
      {
        path: "notices",
        labelKey: "admin.resource.notice.plural",
        permission: {
          action: "list",
          targetType: "BOOTH_NOTICE",
        },
        children: ({ obj }) => (
          <BoothNoticeListWidget
            baseFilter={{ booth_Overlap: obj.id }}
            createSearchParams={{ booth: obj.id }}
            hiddenFields={["booth"]}
          />
        ),
      },
    ]}
  />
);

// ==

const QUERY = gql`
  query boothForBoothDetail($id: ID!) {
    booth(filter: { id_Overlap: [$id] }) {
      id
      venue {
        id
        title
        group {
          id
          name
        }
      }
      operationStatus
      waitStatus
      exposedStatus
      premium {
        id
        name
      }
      operatingHours {
        date
        closeTime
        openTime
      }
      titleContent {
        ...TranslatableContentFields
      }
      locationContent {
        ...TranslatableContentFields
      }
      ...TrackedFields
      latitude
      longitude
      radius
      groupSize
      averageWaitingMinutes
      displayWaitListNumber
      dailyWaitListLimit
      ...CanActivateFields
    }
  }
  ${DetailPage.fragments.canActivate}
  ${DetailPage.fragments.tracked}
  ${DetailPage.fragments.translatableContent}
`;
