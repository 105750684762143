import type { FC, PropsWithChildren } from "react";

import { Typography } from "antd";
import type { BaseType } from "antd/es/typography/Base";
import styled from "styled-components";

const { Text } = Typography;

type FieldMessageType =
  | "fieldsetDescription"
  | "fieldDescription"
  | "fieldHelp"
  | "fieldError";

type Props = PropsWithChildren<{
  type: FieldMessageType;
}>;

const TextType: Partial<Record<FieldMessageType, BaseType>> = {
  fieldError: "danger",
};

export const FieldMessage: FC<Props> = ({ type, children }) => (
  <FieldMessageContainer className={type}>
    <Text type={TextType[type] ?? "secondary"}>{children}</Text>
  </FieldMessageContainer>
);

const FieldMessageContainer = styled.div`
  margin-bottom: 8px;
  &.fieldsetDescription {
    margin-bottom: 18px;
  }
  &.fieldError {
    margin-top: 6px;
    margin-bottom: 0;
  }

  white-space: pre-line;
  span.ant-typography {
    display: inline-block;
    line-height: 1.4;
  }
`;
