import { gql } from "@apollo/client";

import { ListWidget } from "../list";

export const BoothListWidget = (props) => (
  <ListWidget
    resource="booth"
    fields={[
      { name: "id" },
      {
        name: "venue",
        type: "object",
        resource: "venue",
        filterKey: "venue_Overlap",
      },
      {
        name: "title",
        truncate: true,
      },
      {
        name: "premium",
        labelKey: "admin.field.premiumOnly",
        type: "boolean",
        getter: (obj) => Boolean(obj.premium),
        filterKey: "premium_Exists",
      },
      {
        name: "operationStatus",
        type: "choice",
        enumType: "BoothOperationStatus",
      },
      {
        name: "waitStatus",
        type: "choice",
        enumType: "BoothWaitStatus",
      },
      {
        name: "waitListCount",
        type: "integer",
      },
      ...ListWidget.fields.tracked,
      ...ListWidget.fields.canActivate,
    ]}
    query={QUERY}
    extraRowActions={ListWidget.extraRowActions.canActivate}
    {...props}
  />
);

// ==

const QUERY = gql`
  query boothsForBoothListWidget(
    $filter: BoothFilterInput
    $sort: [BoothSortInput]
    $page: PageInput
  ) {
    booths(filter: $filter, sort: $sort, page: $page) {
      objects {
        id
        title
        venue {
          id
          title
          group {
            id
            name
          }
        }
        premium {
          id
        }
        operationStatus
        waitStatus
        waitListCount
        ...TrackedFields
        ...CanActivateFields
      }
      ...ListFields
    }
  }
  ${ListWidget.fragments.list}
  ${ListWidget.fragments.tracked}
  ${ListWidget.fragments.canActivate}
`;
