import { gql } from "@apollo/client";

import { DuplicateGroupThemeButton } from "../../components/actions";
import { ListPage, ListPageWithGroup } from "../../components/pages";

export const GroupThemeList = () => (
  <ListPageWithGroup
    groupFilterKey="group_Overlap"
    resource="groupTheme"
    subtitleKey="admin.page.groupTheme.subtitle"
    fields={[
      {
        name: "id",
      },
      {
        name: "group",
        type: "object",
        resource: "group",
        filterKey: "group_Overlap",
      },
      {
        name: "name",
        filterKey: "name_Text",
      },
      {
        name: "primaryColorLight",
        type: "color",
      },
      {
        name: "primaryColorDark",
        type: "color",
      },
      {
        name: "buttonTextColorModeLight",
        type: "choice",
        enumType: "GroupThemeButtonTextColorMode",
      },
      {
        name: "buttonTextColorModeDark",
        type: "choice",
        enumType: "GroupThemeButtonTextColorMode",
      },
      {
        name: "coverRatio",
        type: "choice",
        enumType: "GroupThemeCoverRatio",
      },
      {
        name: "logoPosition",
        type: "choice",
        enumType: "GroupThemeLogoPosition",
      },
      {
        name: "status",
        type: "choice",
        enumType: "GroupThemeStatus",
        filterKey: "status_Overlap",
      },
      {
        name: "activateAt",
        type: "datetime",
      },
      ...ListPage.fields.tracked,
    ]}
    query={QUERY}
    extraRowActions={(props) => (
      <>
        <DuplicateGroupThemeButton groupThemeId={props.obj.id} size="small" onlyIcon />
        {ListPage.extraRowActions.canActivate(props)}
      </>
    )}
  />
);

// ==

const QUERY = gql`
  query groupThemesForGroupThemeList(
    $filter: GroupThemeFilterInput
    $sort: [GroupThemeSortInput]
    $page: PageInput
  ) {
    groupThemes(filter: $filter, sort: $sort, page: $page) {
      objects {
        id
        group {
          id
          name
        }
        name
        primaryColorLight
        primaryColorDark
        buttonTextColorModeLight
        buttonTextColorModeDark
        coverRatio
        logoPosition
        status
        ...TrackedFields
        ...CanActivateFields
      }
      ...ListFields
    }
  }
  ${ListPage.fragments.list}
  ${ListPage.fragments.tracked}
  ${ListPage.fragments.canActivate}
`;
