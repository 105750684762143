import { gql } from "@apollo/client";

import { DownloadMembersButton } from "../actions";
import { ListWidget } from "../list";
import { Can } from "../permission";

export const MemberListWidget = ({ filter, ...props }) => (
  <ListWidget
    resource="member"
    filter={{
      ...filter,
      artist_Exists: false,
    }}
    fields={[
      {
        name: "id",
      },
      {
        name: "group",
        type: "object",
        resource: "group",
        filterKey: "group_Overlap",
      },
      {
        name: "user.email",
        labelKey: "admin.field.email",
        type: "email",
        filterKey: "user_Email_Prefix",
      },
      {
        name: "user.phone",
        labelKey: "admin.field.phone",
        type: "phone",
      },
      {
        name: "nickname",
        filterKey: "nickname_Exact",
        sortKey: "NICKNAME",
      },
      {
        name: "hasPremiums",
        type: "boolean",
        getter: (obj) => Boolean(obj.premiums?.length),
        filterKey: "premiums_Exists",
      },
      {
        name: "status",
        type: "choice",
        enumType: "MemberStatus",
        filterKey: "status_Overlap",
      },
      {
        name: "user.lastAuthenticatedAt",
        labelKey: "admin.field.lastAuthenticatedAt",
        type: "datetime",
      },
      {
        name: "createdAt",
        labelKey: "admin.field.joinedAt",
        type: "datetime",
        sortKey: "CREATED_AT",
      },
    ]}
    query={QUERY}
    extraActions={({ filter, sort, result }) => (
      <Can action="download" targetType="MEMBER">
        <DownloadMembersButton
          count={result?.count}
          filter={filter}
          sort={sort}
          type="text"
          onlyIcon
        />
      </Can>
    )}
    hideCreate
    hideUpdate
    {...props}
  />
);

// ==

const QUERY = gql`
  query membersForMemberListWidget(
    $filter: MemberFilterInput
    $sort: [MemberSortInput]
    $page: PageInput
  ) {
    members(filter: $filter, sort: $sort, page: $page) {
      objects {
        id
        group {
          id
          name
        }
        user {
          id
          email
          phone
          lastAuthenticatedAt
        }
        nickname
        premiums {
          id
        }
        status
        ...TrackedFields
      }
      ...ListFields
    }
  }
  ${ListWidget.fragments.list}
  ${ListWidget.fragments.tracked}
`;
