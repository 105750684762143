import { useState } from "react";
import { Trans, useTranslation } from "react-i18next";

import { useMutation } from "@apollo/client";

import { DownloadOutlined } from "@ant-design/icons";
import { Button, Modal, Space, Typography } from "antd";

import { useApp } from "../../hooks/app";
import { useAuthentication } from "../../hooks/auth";
import { formatNumber } from "../../utils/helpers";

const { Text } = Typography;

export const DownloadButton = ({
  mutation,
  count,
  filter,
  sort,
  id = undefined,
  onSuccess = undefined,
  onlyIcon = false,
  ...props
}) => {
  const { t } = useTranslation();

  const onCompleted = (response) => {
    const { ok, errors } = Object.values(response)[0];
    if (ok) {
      message.success(t("admin.message.requestSuccess"));
      onSuccess?.();
      onCancel();
    } else {
      message.error(
        t("admin.message.requestFailure", {
          message: errors[0].messages[0],
        }),
      );
    }
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [send, { loading }] = useMutation(mutation, {
    variables: {
      filter: JSON.stringify(filter),
      sort: JSON.stringify(sort),
      id: id,
    },
    onCompleted,
  });
  const { message } = useApp();
  const {
    user: { email },
  } = useAuthentication();

  // ==

  const onClick = () => setIsModalOpen(true);
  const onOk = () => send();
  const onCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Button
        icon={<DownloadOutlined />}
        disabled={count?.value === 0}
        onClick={onClick}
        {...props}
      >
        {onlyIcon ? null : t("admin.common.download")}
      </Button>
      <Modal
        title={t("admin.component.downloadButton.title")}
        open={isModalOpen}
        confirmLoading={loading}
        okText={t("admin.common.download")}
        okButtonProps={{
          icon: <DownloadOutlined />,
        }}
        cancelText={t("admin.common.cancel")}
        onOk={onOk}
        onCancel={onCancel}
      >
        <>
          <Space direction="vertical">
            <Text>{t("admin.component.downloadButton.message")}</Text>
            <ul style={styles.ul}>
              <li>
                <Trans i18nKey="admin.component.downloadButton.messageItem1">
                  Your email address is <strong>{{ email }}</strong>
                </Trans>
              </li>
              {count && (
                <li>
                  {t(
                    count.relation === "GREATER_THAN_OR_EQUAL"
                      ? "admin.component.downloadButton.messageItem2GreaterThanOrEqual"
                      : "admin.component.downloadButton.messageItem2Exact",
                    {
                      count: formatNumber(count.value),
                    },
                  )}
                </li>
              )}
            </ul>
          </Space>
        </>
      </Modal>
    </>
  );
};

// ==

const styles = {
  ul: {
    margin: 0,
  },
};
