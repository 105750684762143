import { gql } from "@apollo/client";

import { ListPage } from "../../components/pages";

export const PollOptionList = () => (
  <ListPage
    resource="pollOption"
    fields={[
      {
        name: "id",
      },
      {
        name: "poll",
        type: "object",
        resource: "poll",
        filterKey: "poll_Overlap",
      },
      {
        name: "order",
        type: "integer",
        filterKey: "order_Range",
        sortKey: "ORDER",
      },
      {
        name: "body",
        filterKey: "body_Text",
      },
      {
        name: "image",
        type: "imageMeta",
      },
      {
        name: "voteCount",
        type: "integer",
        filterKey: "voteCount_Range",
        sortKey: "VoTE_COUNT",
      },
      ...ListPage.fields.tracked,
    ]}
    query={QUERY}
    extraRowActions={ListPage.extraRowActions.delete}
  />
);

// ==

const QUERY = gql`
  query pollOptionsForPollOptionList(
    $filter: PollOptionFilterInput
    $sort: [PollOptionSortInput]
    $page: PageInput
  ) {
    pollOptions(filter: $filter, sort: $sort, page: $page) {
      objects {
        id
        poll {
          id
          title
        }
        order
        body: bodySnippet
        image {
          ...ListImageFields
        }
        voteCount
        ...TrackedFields
      }
      ...ListFields
    }
  }
  ${ListPage.fragments.list}
  ${ListPage.fragments.tracked}
  ${ListPage.fragments.image}
`;
