import { forwardRef } from "react";
import { useTranslation } from "react-i18next";

import { gql, useLazyQuery } from "@apollo/client";

import { PlusOutlined } from "@ant-design/icons";
import { Button, Col, Row, Space } from "antd";

import { v4 as uuidv4 } from "uuid";

import { LIST_FRAGMENT } from "../../utils/gql/fragments";
import { SelectObjectAction } from "../actions";
import { SortableTable } from "../list";
import { ClipThumbnail } from "../media";

export const PlaylistEntryEditor = forwardRef(
  ({ value, onChange, disabled = false, baseFilter = undefined }, ref) => {
    const { t } = useTranslation();
    const [fetch] = useLazyQuery(QUERY);

    const onSelect = (ids) =>
      fetch({
        variables: { filter: { id_Overlap: ids } },
        onCompleted: (response) => {
          const clips = Object.values(response)[0];
          const entries = ids.map((id) => ({
            clip: clips.find((clip) => clip.id === id),
          }));
          onChange?.([...(value ?? []), ...entries]);
        },
      });

    value?.forEach((item) => {
      item.__id ??= uuidv4();
    });

    return (
      <Space direction="vertical" style={styles.container}>
        <Row justify="space-between" align="top">
          <Col>Total {value?.length ?? 0} clips</Col>
          <Col>
            <SelectObjectAction
              resource={"clip"}
              query={CLIP_QUERY}
              fields={[
                {
                  name: "thumbnail",
                  type: "image",
                  render: (_, entry) => <ClipThumbnail clip={entry} />,
                },
                {
                  name: "title",
                  type: "text",
                },
              ]}
              onSelect={onSelect}
              baseFilter={baseFilter}
              disabled={disabled}
              disabledValues={value?.map((entry) => entry.clip.id)}
              okButtonText={t("admin.common.add")}
            >
              {({ openDrawer, disabled }) => (
                <Button
                  type="link"
                  icon={<PlusOutlined />}
                  disabled={disabled}
                  onClick={openDrawer}
                >
                  {t("admin.common.actionTitle", {
                    action: t("admin.common.add"),
                    name: t("admin.resource.clip.singular"),
                  })}
                </Button>
              )}
            </SelectObjectAction>
          </Col>
        </Row>
        <SortableTable
          resource="playlistEntry"
          fields={[
            {
              name: "clip.id",
              labelKey: "admin.field.id",
              width: 0,
            },
            {
              name: "thumbnail",
              render: (_, entry) => <ClipThumbnail clip={entry.clip} />,
            },
            {
              name: "clip.title",
              labelKey: "admin.field.title",
            },
          ]}
          items={value}
          onChange={(items) => onChange?.(items)}
          disabled={disabled}
          pagination={false}
          rowKey="__id"
          size="small"
        />
      </Space>
    );
  },
);

PlaylistEntryEditor.fragments = {
  clip: gql`
    fragment PlaylistEntryEditorClipFields on Clip {
      category
      title
      ...ClipThumbnailFields
    }
    ${ClipThumbnail.fragments.clip}
  `,
};

// ==

const QUERY = gql`
  query allClipsForPlaylistEntryEditor($filter: ClipFilterInput!) {
    allClips(filter: $filter) {
      id
      ...PlaylistEntryEditorClipFields
    }
  }
  ${PlaylistEntryEditor.fragments.clip}
`;

const CLIP_QUERY = gql`
  query clipsForPlaylistEntry(
    $filter: ClipFilterInput
    $sort: [ClipSortInput]
    $page: PageInput
  ) {
    clips(filter: $filter, sort: $sort, page: $page) {
      objects {
        id
        title
        ...ClipThumbnailFields
      }
      ...ListFields
    }
  }
  ${LIST_FRAGMENT}
  ${ClipThumbnail.fragments.clip}
`;

// ==

const styles = {
  container: {
    width: "100%",
  },
};
