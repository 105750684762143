import { forwardRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { gql } from "@apollo/client";

import { Spin } from "antd";

import {
  BoldItalicUnderlineToggles,
  DiffSourceToggleWrapper,
  InsertImage,
  MDXEditor,
  UndoRedo,
  diffSourcePlugin,
  headingsPlugin,
  imagePlugin,
  linkDialogPlugin,
  linkPlugin,
  listsPlugin,
  quotePlugin,
  thematicBreakPlugin,
  toolbarPlugin,
} from "@mdxeditor/editor";
import "@mdxeditor/editor/style.css";

import { useApp } from "../../hooks/app";
import "../../styles/MarkdownEditor.css";
import { getApolloClient } from "../../utils/gql";
import { stripDunderKeys, stripEmptyValues } from "../../utils/helpers";

export const MarkdownEditor = forwardRef(({ value, onChange, ...props }, ref) => {
  const { t } = useTranslation();
  const [oldValue] = useState(value);
  const [loading, setLoading] = useState(false);
  const { message, colorMode } = useApp();

  return (
    <>
      <MDXEditor
        className={colorMode === "dark" ? "dark-theme" : "light-theme"}
        markdown={value ?? ""}
        onChange={(e) => {
          const refined = e.replaceAll("\n\n\n\n", "\n\n&#x20;&#x20;\n\n");
          onChange(refined);
        }}
        contentEditableClassName="markdown-editor"
        plugins={[
          linkPlugin(),
          linkDialogPlugin(),
          imagePlugin({
            disableImageResize: true,
            imageUploadHandler: async (file) => {
              setLoading(true);
              const url = await imageUploadHandler({ file, t, onError: message.error });
              setLoading(false);
              return url;
            },
          }),
          diffSourcePlugin({ diffMarkdown: oldValue, viewMode: "rich-text" }),
          toolbarPlugin({
            toolbarContents: () => (
              <>
                <DiffSourceToggleWrapper>
                  <UndoRedo />
                  <BoldItalicUnderlineToggles />
                  <InsertImage />
                </DiffSourceToggleWrapper>
              </>
            ),
          }),
          // Below are the plugins that are not included in the toolbar
          headingsPlugin(),
          quotePlugin(),
          listsPlugin(),
          thematicBreakPlugin(),
        ]}
        ref={ref}
        {...props}
      />
      <Spin spinning={loading} fullscreen />
    </>
  );
});

// ==

const imageUploadHandler = async ({ file, onError, t }) => {
  const client = getApolloClient();

  // Generate signed URL for uploading
  const {
    data: {
      uploadUrl: { signedUrl, key },
    },
  } = await client.query({
    query: UPLOAD_URL_QUERY,
    variables: { name: file.name },
    fetchPolicy: "no-cache",
  });

  if (!signedUrl) {
    onError(t("admin.message.mediaUploadFailureSignedUrl"));
    return;
  }

  // Upload to S3
  const { ok } = await fetch(signedUrl, {
    method: "PUT",
    body: file,
  });

  if (!ok) {
    onError(t("admin.message.mediaUploadFailureUpload"));
    return;
  }

  // Retrieve meta data
  const {
    data: { uploadMeta },
  } = await client.query({
    query: UPLOAD_META_QUERY,
    variables: { key },
    fetchPolicy: "no-cache",
  });

  if (!uploadMeta) {
    onError(t("admin.message.mediaUploadFailureMeta"));
    return;
  }

  const meta = stripEmptyValues(stripDunderKeys(uploadMeta));

  return meta.url;
};

// ==

const UPLOAD_URL_QUERY = gql`
  query uploadUrl($name: String!) {
    uploadUrl(name: $name) {
      signedUrl
      key
    }
  }
`;

const UPLOAD_META_QUERY = gql`
  query uploadMetaForMarkdown($key: String!) {
    uploadMeta(key: $key) {
      key
      mime
      size
      width
      height
      duration
      bitrate
      m3u8Key
      thumbnailKey
      # ==
      url
    }
  }
`;
