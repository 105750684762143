import { useTranslation } from "react-i18next";

import { Button, Result } from "antd";

export const NotFound = () => {
  const { t } = useTranslation();
  return (
    <Result
      status="404"
      title="404"
      subTitle={t("admin.page.error.notFound.")}
      extra={<Button type="primary">{t("admin.page.error.goToHome")}</Button>}
    />
  );
};
