import { gql } from "@apollo/client";

import { DetailPage } from "../../components/pages";

export const GroupRoleDetail = () => (
  <DetailPage
    resource="groupRole"
    fields={[
      {
        name: "id",
      },
      {
        name: "staff",
        type: "object",
        resource: "staff",
      },
      {
        name: "staff.user.email",
        labelKey: "admin.field.email",
        type: "email",
      },
      {
        name: "category",
        type: "choice",
        enumType: "RoleCategory",
      },
      {
        name: "group",
        type: "object",
        resource: "group",
      },
      ...DetailPage.fields.tracked,
    ]}
    query={QUERY}
  />
);

// ==

const QUERY = gql`
  query roleForGroupRoleDetail($id: ID!) {
    role(filter: { id_Overlap: [$id] }) {
      id
      staff {
        id
        user {
          id
          email
          name
        }
      }
      category
      group {
        id
        name
      }
      ...TrackedFields
    }
  }
  ${DetailPage.fragments.tracked}
`;
