import { gql } from "@apollo/client";

import { ListPage } from "../../components/pages";

export const SubcontinentList = () => (
  <ListPage
    resource="subcontinent"
    fields={[
      {
        name: "id",
        width: "15%",
      },
      {
        name: "continent",
        type: "object",
        resource: "continent",
        filterKey: "continent_Overlap",
      },
      {
        name: "code",
        filterKey: "code_Exact",
        sortKey: "CODE",
        width: "15%",
      },
      {
        name: "name",
        filterKey: "name_Text",
        sortKey: "NAME",
      },
      {
        name: "countryCount",
        type: "integer",
        filterKey: "countryCount_Range",
        sortKey: "COUNTRY_COUNT",
      },
      ...ListPage.fields.canActivate,
    ]}
    query={QUERY}
    defaultSort={[
      {
        type: "CODE",
        direction: "ASC",
      },
    ]}
    extraRowActions={ListPage.extraRowActions.canActivate}
  />
);

// ==

const QUERY = gql`
  query subcontinentsForSubcontinentList(
    $filter: SubcontinentFilterInput
    $sort: [SubcontinentSortInput]
    $page: PageInput
  ) {
    subcontinents(filter: $filter, sort: $sort, page: $page) {
      objects {
        id
        continent {
          id
          name
        }
        code
        name
        countryCount
        ...CanActivateFields
      }
      ...ListFields
    }
  }
  ${ListPage.fragments.list}
  ${ListPage.fragments.canActivate}
`;
