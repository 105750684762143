import { BrowserRouter, Outlet, Route, Routes } from "react-router-dom";

import { ApolloProvider } from "@apollo/client";

import { AnonymousRequired, AuthenticationRequired } from "./components/auth";
import { Layout } from "./components/layout";
import { God } from "./contexts/app";
import { AuthenticationProvider } from "./contexts/auth";
import { TimezoneProvider } from "./contexts/tz";
import { useApp } from "./hooks/app";
import menus from "./menus";
import { Login } from "./pages/auth";
import { Dashboard } from "./pages/dashboard";
import { NotFound } from "./pages/error";
import { GroupHomeSetting } from "./pages/groupHomeSetting";
import { GroupTabSetting } from "./pages/groupTabSetting";
import { ScheduleCalendar } from "./pages/scheduleCalendar";
import { TranslationEditor } from "./pages/translationEditor";
import { TranslationRequestTargetContentList } from "./pages/translationRequest";
import resources from "./resources";
import "./styles/App.css";
import { getApolloClient } from "./utils/gql";

const AppRoutes = () => {
  const { resources } = useApp();
  return (
    <Routes>
      <Route
        element={
          <AuthenticationRequired>
            <Layout>
              <Outlet />
            </Layout>
          </AuthenticationRequired>
        }
      >
        <Route path="/" element={<Dashboard />} />
        <Route path="/group-home-setting" element={<GroupHomeSetting />} />
        <Route path="/group-tab-setting" element={<GroupTabSetting />} />
        <Route path="/schedule-calendar" element={<ScheduleCalendar />} />
        <Route path="/translation-editor" element={<TranslationEditor />} />
        <Route
          path="/translation-request-management/list"
          element={<TranslationRequestTargetContentList />}
        />
        <Route
          path="/translation-progress-management/list"
          element={<TranslationRequestTargetContentList />}
        />

        {Object.values(resources).map((resource, i) => (
          <Route key={i} path={resource.baseUrl}>
            {resource.listComponent && (
              <Route path="list/*" element={<resource.listComponent />} />
            )}
            {resource.detailComponent && (
              <Route path=":id/*" element={<resource.detailComponent />} />
            )}
            {resource.formComponent && (
              <>
                <Route path="add" element={<resource.formComponent />} />
                <Route path=":id/edit" element={<resource.formComponent />} />
              </>
            )}
          </Route>
        ))}

        <Route path="*" element={<NotFound />} />
      </Route>
      <Route
        element={
          <AnonymousRequired>
            <Outlet />
          </AnonymousRequired>
        }
      >
        <Route path="/login" element={<Login />} />
      </Route>
    </Routes>
  );
};

const App = () => {
  const client = getApolloClient();
  return (
    <ApolloProvider client={client}>
      <BrowserRouter>
        <God resources={resources} menus={menus}>
          <TimezoneProvider>
            <AuthenticationProvider>
              <AppRoutes />
            </AuthenticationProvider>
          </TimezoneProvider>
        </God>
      </BrowserRouter>
    </ApolloProvider>
  );
};

export default App;
