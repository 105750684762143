import { gql } from "@apollo/client";

import {
  EventV2RestrictionEditor,
  EventV2SurveyQuestionEditor,
  EventV2WinnerNoticeEditor,
  EventV2WinnerQuestionSetEditor,
} from "../../components/fields";
import { FormPage } from "../../components/pages";

export const EventV2Form = () => (
  <FormPage
    resource="eventV2"
    fields={[
      {
        type: "fieldset",
        labelKey: "admin.fieldset.default",
        fields: [
          {
            name: "group",
            type: "object",
            resource: "group",
            disabled: ({ isUpdate }) => isUpdate,
          },
          {
            name: "category",
            type: "choice",
            direction: "horizontal",
            enumType: "EventV2Category",
            disabled: ({ data, isUpdate }) => {
              return (
                isUpdate &&
                (data?.applicationOpenAt < new Date() ||
                  data?.applicationCloseAt < new Date())
              );
            },
            onChange: ({ setValue }) => {
              setValue("drawCategory", null);
              setValue("submissionCategory", null);
              setValue("externalUrl", null);
              setValue("location", null);
              setValue("eventAt", null);
            },
          },
          {
            name: "drawCategory",
            type: "choice",
            direction: "horizontal",
            enumType: "EventV2DrawCategory",
            hidden: ({ data }) => data.category?.includes("EXTERNAL"),
            readOnly: ({ data }) => data.category?.includes("EXTERNAL"),
            disabled: ({ data }) => {
              return data?.isWinnerSelected;
            },
            onChange: ({ value, setValue }) => {
              if (value === "ORDER") {
                setValue("submissionCategory", "BUTTON");
              } else if (value === "RANDOM") {
                setValue("submissionCategory", "SURVEY");
              }
            },
          },
          {
            name: "location",
            hidden: ({ data }) => data.category?.includes("ONLINE"),
            readOnly: ({ data }) => data.category?.includes("ONLINE"),
          },
          {
            name: "eventAt",
            type: "datetime",
            showSeconds: false,
            hidden: ({ data }) => data.category?.includes("ONLINE"),
            readOnly: ({ data }) => data.category?.includes("ONLINE"),
          },
          {
            name: "titleContent",
            labelKey: "admin.field.title",
            type: "translatable",
          },
          {
            name: "bodyContent",
            labelKey: "admin.field.body",
            type: "translatable",
            markdown: true,
            rows: 50,
          },
          {
            name: "isHighlighted",
            labelKey: "admin.field.isHighlighted",
            type: "boolean",
          },
          {
            name: "isWinnerSelected",
            hidden: true,
          },
        ],
      },
      {
        type: "fieldset",
        labelKey: "admin.fieldset.winnerSettings",
        hidden: ({ data }) => !data.category?.includes("ONLINE"),
        fields: [
          {
            name: "winnerNotice",
            label: "",
            // FIXME: Create translatableObject type for future use
            readOnly: ({ data }) => !data.category?.includes("ONLINE"),
            normalizer: (winnerNotice) => {
              return { winnerNotice };
            },
            render: ({ controllerField }) => {
              return <EventV2WinnerNoticeEditor {...controllerField} />;
            },
          },
          {
            name: "winnerQuestionSet",
            label: "",
            readOnly: ({ data }) => !data.category?.includes("ONLINE"),
            getter: ({ winnerQuestionSet }) => ({
              winnerQuestionSet,
            }),
            normalizer: ({ winnerQuestionSet }) => {
              const merged = {
                ...winnerQuestionSet,
                questions: Array.isArray(winnerQuestionSet?.questions)
                  ? winnerQuestionSet?.questions.filter(
                      (question) => question.title.trim() !== "",
                    )
                  : winnerQuestionSet?.questions,
              };
              const hasQuestions = merged.questions?.length >= 1;
              return { winnerQuestionSet: hasQuestions ? merged : null };
            },
            render: ({ controllerField }) => (
              <EventV2WinnerQuestionSetEditor {...controllerField} />
            ),
          },
        ],
      },
      {
        type: "fieldset",
        labelKey: "admin.fieldset.applicationSettings",
        fields: [
          {
            name: "applicationOpenAt",
            labelKey: "admin.field.applicationOpenAt",
            type: "datetime",
            showSeconds: false,
            disabled: ({ data, isUpdate }) => {
              return (
                isUpdate &&
                (data?.applicationOpenAt < new Date() ||
                  data?.applicationCloseAt < new Date())
              );
            },
          },
          {
            name: "applicationCloseAt",
            labelKey: "admin.field.applicationCloseAt",
            type: "datetime",
            showSeconds: false,
            disabled: ({ data, isUpdate }) => {
              return isUpdate && data?.isWinnerSelected;
            },
          },
          {
            name: "submissionCategory",
            labelKey: "admin.field.submissionCategory",
            type: "choice",
            enumType: "EventV2SubmissionCategory",
            direction: "horizontal",
            disabled: ({ data, isUpdate }) => {
              return (
                isUpdate &&
                (data?.applicationOpenAt < new Date() ||
                  data?.applicationCloseAt < new Date())
              );
            },
            hidden: ({ data }) => data.category?.includes("EXTERNAL"),
          },
          {
            name: "externalUrl",
            labelKey: "admin.field.externalUrl",
            type: "text",
            hidden: ({ data }) => !data.category?.includes("EXTERNAL"),
          },
        ],
      },
      {
        type: "fieldset",
        labelKey: "admin.fieldset.surveySettings",
        hidden: ({ data }) => !data.submissionCategory?.includes("SURVEY"),
        readOnly: ({ data }) => !data.submissionCategory?.includes("SURVEY"),
        fields: [
          {
            name: "surveyQuestions",
            label: "",
            disabled: ({ data, isUpdate }) => {
              return isUpdate && data?.applicationOpenAt < new Date();
            },
            getter: ({ surveyQuestions }) => ({
              surveyQuestions,
            }),
            normalizer: ({ surveyQuestions }) => ({
              surveyQuestions,
            }),
            render: ({ controllerField }) => (
              <EventV2SurveyQuestionEditor {...controllerField} />
            ),
          },
        ],
      },
      {
        type: "fieldset",
        labelKey: "admin.fieldset.restrictionsSettings",
        hidden: ({ data }) => data.category?.includes("EXTERNAL"),
        fields: [
          {
            name: "isPremiumOnly",
            labelKey: "admin.field.isPremiumOnly",
            type: "boolean",
            disabled: ({ data, isUpdate }) => {
              return data?.applicationOpenAt < new Date() && isUpdate;
            },
          },
          {
            name: "restrictions",
            label: "",
            getter: ({ restrictions }) => ({
              restrictions,
            }),
            disabled: ({ data, isUpdate }) => {
              return data?.applicationOpenAt < new Date() && isUpdate;
            },
            normalizer: ({ restrictions }) => ({
              restrictions:
                restrictions?.filter(
                  (restriction) =>
                    restriction.count !== null && restriction.count !== 0,
                ) || undefined,
            }),
            render: ({ controllerField, data }) => (
              <EventV2RestrictionEditor
                {...controllerField}
                isPremiumOnly={data.isPremiumOnly}
              />
            ),
          },
          {
            name: "winnerCount",
            type: "integer",
          },
          {
            name: "waitlistCount",
            type: "integer",
            hidden: ({ data }) => !data.category?.includes("OFFLINE"),
          },
        ],
      },
      ...FormPage.fields.canActivate,
    ]}
    query={QUERY}
    createMutation={CREATE_MUTATION}
    updateMutation={UPDATE_MUTATION}
  />
);

// ==

const FORM_FIELDS_FRAGMENT = gql`
  fragment EventV2FormFields on EventV2 {
    id
    group {
      id
    }
    titleContent {
      ...TranslatableContentFields
    }
    bodyContent {
      ...TranslatableContentFields
    }

    category
    externalUrl
    drawCategory
    submissionCategory
    applicationOpenAt
    applicationCloseAt
    eventAt
    location
    winnerCount
    waitlistCount
    isHighlighted
    isPremiumOnly
    isWinnerAnnounced
    isWinnerSelected

    winnerNotice {
      bodyContent {
        ...TranslatableContentFields
      }
    }
    winnerQuestionSet {
      answerableUntil
      questions {
        id
        title
        subtitle
      }
    }
    surveyQuestions {
      id
      category
      title
      subtitle
      isRequired
      options {
        id
        value
      }
    }
    restrictions {
      id
      category
      count
    }

    ...CanActivateFields
  }
  ${FormPage.fragments.canActivate}
  ${FormPage.fragments.translatableContent}
`;

const QUERY = gql`
  query eventV2ForEventV2Form($id: ID!) {
    eventV2(filter: { id_Overlap: [$id] }) {
      ...EventV2FormFields
    }
  }
  ${FORM_FIELDS_FRAGMENT}
`;

const CREATE_MUTATION = gql`
  mutation createEventV2ForEventV2Form($data: EventV2MutationInput!) {
    createEventV2(data: $data) {
      ok
      errors {
        ...MutationErrorFields
      }
      object {
        ...EventV2FormFields
      }
    }
  }
  ${FormPage.fragments.mutationError}
  ${FORM_FIELDS_FRAGMENT}
`;

const UPDATE_MUTATION = gql`
  mutation updateEventForEventForm($data: EventV2MutationInput!, $id: ID!) {
    updateEventV2(data: $data, id: $id, partial: true) {
      ok
      errors {
        ...MutationErrorFields
      }
      object {
        ...EventV2FormFields
      }
    }
  }
  ${FormPage.fragments.mutationError}
  ${FORM_FIELDS_FRAGMENT}
`;
