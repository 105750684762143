import { gql } from "@apollo/client";

import { SyncClipButton } from "../../components/actions";
import { DetailPage } from "../../components/pages";
import { CommentListWidget, PlaylistEntryListWidget } from "../../components/widgets";

export const ClipDetail = () => (
  <DetailPage
    resource="clip"
    fields={[
      {
        name: "id",
      },
      {
        name: "group",
        type: "object",
        resource: "group",
      },
      {
        name: "category",
        type: "choice",
        enumType: "ClipCategory",
      },
      {
        name: "titleContent",
        labelKey: "admin.field.title",
        type: "translatable",
      },
      {
        name: "bodyContent",
        labelKey: "admin.field.body",
        type: "translatable",
      },
      {
        name: "thumbnail",
        type: "imageMeta",
      },
      {
        name: "images",
        type: "imageMeta",
        spaceDirection: "horizontal",
      },
      {
        name: "video",
        type: "videoMeta",
      },
      {
        name: "captions",
        type: "captionMeta",
      },
      {
        name: "duration",
        type: "integer",
      },
      {
        name: "youtubeClipUrl",
        type: "url",
        onlyIcon: true,
      },
      {
        name: "isPremiumOnly",
        labelKey: "admin.field.premiumOnly",
        type: "boolean",
      },
      {
        name: "member",
        type: "object",
        resource: "member",
      },
      {
        name: "commentCount",
        type: "integer",
      },
      {
        name: "likeCount",
        type: "integer",
      },
      {
        name: "bookmarkCount",
        type: "integer",
      },
      {
        name: "status",
        type: "choice",
        enumType: "ClipStatus",
      },
      ...DetailPage.fields.canActivate,
      {
        name: "activateAt",
        type: "datetime",
      },
      ...DetailPage.fields.tracked,
    ]}
    query={QUERY}
    extraActions={(props) => (
      <>
        {DetailPage.extraActions.canActivate(props)}
        {props.obj?.category === "YOUTUBE" && <SyncClipButton clipId={props.obj.id} />}
      </>
    )}
    tabs={[
      {
        path: "",
        labelKey: "admin.resource.comment.plural",
        permission: {
          action: "list",
          targetType: "COMMENT",
        },
        children: ({ obj }) => (
          <CommentListWidget
            baseFilter={{
              group_Overlap: [obj.group.id],
              targetType_Overlap: ["CLIP"],
              targetId_Overlap: [obj.id],
            }}
            createSearchParams={{
              group: obj.group.id,
              targetType: "CLIP",
              targetId: obj.id,
            }}
            hiddenFields={["group", "targetType", "target"]}
          />
        ),
      },
      {
        path: "playlists",
        labelKey: "admin.resource.playlist.plural",
        permission: {
          action: "list",
          targetType: "PLAYLIST_ENTRY",
        },
        children: ({ obj }) => (
          <PlaylistEntryListWidget
            baseFilter={{
              clip_Overlap: [obj.id],
            }}
            createSearchParams={{ clip: obj.id }}
            hiddenFields={["clip"]}
          />
        ),
      },
    ]}
  />
);

// ==

const QUERY = gql`
  query clipForClipDetail($id: ID!) {
    clip(filter: { id_Overlap: [$id] }) {
      id
      group {
        id
        name
      }
      member {
        id
        nickname
      }
      category
      title
      titleContent {
        ...TranslatableContentFields
      }
      body
      bodyContent {
        ...TranslatableContentFields
      }
      thumbnail {
        ...DetailImageFields
      }
      youtubeClipUrl
      duration
      images {
        ...DetailImageFields
      }
      video {
        ...DetailVideoFields
      }
      captions {
        ...DetailClipCaptionFields
      }
      commentCount
      likeCount
      bookmarkCount
      isPremiumOnly
      status
      ...TrackedFields
      ...CanActivateFields
    }
  }
  ${DetailPage.fragments.tracked}
  ${DetailPage.fragments.canActivate}
  ${DetailPage.fragments.image}
  ${DetailPage.fragments.video}
  ${DetailPage.fragments.clipCaption}
  ${DetailPage.fragments.translatableContent}
`;
