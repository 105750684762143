import { gql } from "@apollo/client";

import { DateAndHourWithTZEditor } from "../../components/fields/DateAndHourWithTZEditor";
import { FormPage } from "../../components/pages";

export const BoothForm = () => (
  <FormPage
    resource="booth"
    fields={[
      {
        type: "fieldset",
        labelKey: "admin.fieldset.basic",
        fields: [
          {
            name: "group",
            type: "object",
            resource: "group",
            getter: (obj) => obj?.venue?.group.id,
            onChange: ({ resetField }) => resetField("staffs", "venue", "premium"),
            disabled: ({ isUpdate }) => isUpdate,
            readOnly: true,
          },
          {
            name: "venue",
            type: "object",
            resource: "venue",
            baseFilter: ({ data }) => ({ group_Overlap: [data.group] }),
            disabled: ({ isUpdate }) => isUpdate,
          },
          {
            name: "titleContent",
            labelKey: "admin.field.boothTitle",
            type: "translatable",
          },
          {
            name: "operatingHours",
            getter: ({ operatingHours }) => ({
              dateAndHours: operatingHours,
            }),
            normalizer: ({ dateAndHours }) => ({
              operatingHours: dateAndHours,
            }),
            render: ({ controllerField, isUpdate }) => (
              <DateAndHourWithTZEditor disabled={isUpdate} {...controllerField} />
            ),
            disabled: ({ isUpdate, data: { operationStatus } }) =>
              isUpdate && operationStatus === "OPENED",
          },
          {
            name: "locationContent",
            labelKey: "admin.field.location",
            type: "translatable",
          },
          {
            name: "premium",
            type: "object",
            resource: "premium",
            baseFilter: ({ data }) => ({ group_Overlap: [data.group] }),
          },
          {
            name: "staffs",
            type: "object",
            resource: "staff",
            multiple: true,
            baseFilter: ({ data }) => ({
              permittedGroups_Overlap: [data.group],
              roleCategories_Overlap: ["GROUP_BOOTH_MANAGER"],
            }),
            getter: ({ staffs }) => staffs?.map((s) => s.staff.id),
            normalizer: (values) => ({
              staffs: (values ?? []).map((v) => ({ staff: v })),
            }),
          },
          {
            name: "announcementContent",
            labelKey: "admin.field.boothAnnouncement",
            type: "translatable",
            markdown: true,
          },
          {
            name: "operationStatus",
            hidden: true,
            readOnly: true,
          },
        ],
      },
      {
        type: "fieldset",
        labelKey: "admin.fieldset.coordinate",
        descriptionKey: "admin.form.guide.coordinate",
        fields: [
          {
            name: "latitude",
            type: "float",
          },
          {
            name: "longitude",
            type: "float",
          },
          {
            name: "radius",
            type: "float",
            suffixKey: "admin.form.label.kilometer",
          },
          // FIXME
          // {
          //   name: "googleMapUrl",
          //   label: "",
          //   getter: ({ googleMapUrl }) => ({
          //     googleMapUrl,
          //   }),
          //   normalizer: ({ googleMapUrl }) => ({
          //     googleMapUrl,
          //   }),
          //   render: ({ controllerField }) => (
          //     <GoogleMapUrlEditor {...controllerField} />
          //   ),
          // },
        ],
      },
      {
        type: "fieldset",
        labelKey: "admin.fieldset.waitList",
        fields: [
          {
            name: "dailyWaitListLimit",
            descriptionKey: "admin.form.guide.dailyWaitListLimit",
            type: "integer",
            suffixKey: "admin.form.label.people",
          },
          {
            name: "groupSize",
            descriptionKey: "admin.form.guide.waitListGroupSize",
            type: "integer",
            suffixKey: "admin.form.label.peoplePerGroup",
            disabled: ({ isUpdate }) => isUpdate,
          },
          {
            name: "displayWaitListNumber",
            descriptionKey: "admin.form.guide.displayWaitListNumber",
            type: "boolean",
          },
          {
            name: "averageWaitingMinutes",
            descriptionKey: "admin.form.guide.averageWaitingMinutes",
            type: "integer",
            suffixKey: "admin.form.label.minutesPerGroup",
          },
          {
            name: "noShowTimeLimit",
            descriptionKey: "admin.form.guide.noShowTimeLimit",
            type: "integer",
            disabled: ({ isUpdate }) => isUpdate,
            suffixKey: "admin.form.label.minutes",
          },
          {
            name: "waitStatus",
            type: "choice",
            enumType: "BoothWaitStatus",
            hidden: ({ isUpdate }) => !isUpdate,
          },
        ],
      },
      ...FormPage.fields.canActivate,
    ]}
    query={QUERY}
    createMutation={CREATE_MUTATION}
    updateMutation={UPDATE_MUTATION}
  />
);

// ==

const FORM_FIELDS_FRAGMENT = gql`
  fragment BoothFormFields on Booth {
    id
    venue {
      id
      group {
        id
        code
      }
    }
    titleContent {
      ...TranslatableContentFields
    }
    announcementContent {
      ...TranslatableContentFields
    }
    locationContent {
      ...TranslatableContentFields
    }
    premium {
      id
    }
    operatingHours {
      date
      openTime
      closeTime
      timeZone
    }
    staffs {
      id
      staff {
        id
        user {
          name
        }
      }
    }
    latitude
    longitude
    radius
    groupSize
    operationStatus
    waitStatus
    displayWaitListNumber
    dailyWaitListLimit
    averageWaitingMinutes
    noShowTimeLimit
    ...CanActivateFields
  }
  ${FormPage.fragments.canActivate}
  ${FormPage.fragments.translatableContent}
`;

const QUERY = gql`
  query BoothForBoothForm($id: ID!) {
    booth(filter: { id_Overlap: [$id] }) {
      ...BoothFormFields
    }
  }
  ${FORM_FIELDS_FRAGMENT}
`;

const CREATE_MUTATION = gql`
  mutation createBoothForBoothForm($data: BoothMutationInput!) {
    createBooth(data: $data) {
      ok
      errors {
        ...MutationErrorFields
      }
      object {
        ...BoothFormFields
      }
    }
  }
  ${FormPage.fragments.mutationError}
  ${FORM_FIELDS_FRAGMENT}
`;

const UPDATE_MUTATION = gql`
  mutation updateBoothForBoothForm($data: BoothMutationInput!, $id: ID!) {
    updateBooth(data: $data, id: $id, partial: true) {
      ok
      errors {
        ...MutationErrorFields
      }
      object {
        ...BoothFormFields
      }
    }
  }
  ${FormPage.fragments.mutationError}
  ${FORM_FIELDS_FRAGMENT}
`;
