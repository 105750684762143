import { FC } from "react";

import { useTranslate } from "@/hooks/i18n";
import { formatNumber } from "@/utils/helpers";

type ListCountRelation = "EQUAL" | "GREATER_THAN_OR_EQUAL";

type ListCount = {
  relation: ListCountRelation;
  value: number;
};

type Props = {
  count?: ListCount;
  labelPlural: string;
  labelSingular: string;
};

export const ListCountInfo: FC<Props> = (props) => {
  const t = useTranslate();
  if (!props.count) return null;
  const { count, labelPlural, labelSingular } = props;
  const { relation, value } = count;

  return (
    <>
      {t(
        (relation === "GREATER_THAN_OR_EQUAL"
          ? "admin.component.listTable.resultCountGreaterThanOrEqual"
          : "admin.component.listTable.resultCountExact") as string,
        {
          count: formatNumber(value),
          name: value > 1 ? labelPlural.toLowerCase() : labelSingular.toLowerCase(),
        },
      )}
    </>
  );
};
