import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";

import { Progress, Switch } from "antd";
import TextArea from "antd/es/input/TextArea";

import htmlBeautify from "html-beautify";
import ImageResize from "quill-image-resize";
import { useDebouncedCallback } from "use-debounce";

import { useS3Upload } from "@/hooks/upload-s3";
import { useResizeObserver } from "@/hooks/utils/useResizeObserver";

Quill.register("modules/ImageResize", ImageResize);

const formats = [
  "header",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "float",
  "height",
  "width",
];

export const HTMLEditor = forwardRef(({ value, onChange }, ref) => {
  const prevValue = useRef<string>(value);
  const [editorHtml, setEditorHtml] = useState(value);
  const [htmlText, setHtmlText] = useState("");
  const [editorMode, setEditorMode] = useState(true);
  const quillRef = useRef<ReactQuill | null>(null);

  useImperativeHandle(ref, () => ({
    focus: () => quillRef.current?.focus(),
  }));

  const moveCursorToElement = useCallback(() => {
    const range = quillRef?.current?.getEditor().getSelection(true);
    if (range?.index) {
      const bounds = quillRef?.current?.getEditor().getBounds(range?.index);
      bounds ? window.scrollTo(0, bounds.bottom) : null;
    }
  }, []);

  useResizeObserver(quillRef.current?.editor?.root, moveCursorToElement);

  const debouncedOnChange = useDebouncedCallback(onChange, 1000);

  useEffect(() => {
    if (prevValue.current !== value) {
      setEditorHtml(value);
      setHtmlText(value);
      prevValue.current = value;
    }
  }, [value]);

  const handleChange = useCallback(
    (html) => {
      setEditorHtml(html);
      debouncedOnChange(html);
    },
    [setEditorHtml, debouncedOnChange],
  );

  const toggleEditorSwitch = () => {
    setEditorMode(!editorMode);
    editorMode ? setHtmlText(htmlBeautify(editorHtml)) : setEditorHtml(htmlText);
  };

  const handleHTMLEditorChange = useCallback(
    (value) => {
      setHtmlText(value);
      debouncedOnChange(value);
    },
    [setHtmlText, debouncedOnChange],
  );

  const s3Upload = useS3Upload({ sync: true });
  const [uploadProgress, setUploadProgress] = useState(0);

  const imageHandler = useCallback(() => {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();
    input.onchange = async () => {
      if (input.files && input.files[0]) {
        setUploadProgress(0);
        try {
          const result = await s3Upload({
            file: input.files[0],
            onProgress: (progress) => {
              setUploadProgress(progress.percent ?? 0);
            },
          });
          if (!quillRef.current) throw Error("Quill editor is not initialized");
          const editor = quillRef.current.getEditor();
          const range = quillRef.current.selection;
          setUploadProgress(100);
          editor.insertEmbed(range?.index ?? 0, "image", result.url);
        } catch (error) {
          alert("Upload failed. Please try again.");
        } finally {
          setUploadProgress(0);
        }
      }
    };
  }, [setUploadProgress, s3Upload, quillRef]);

  const modules = useMemo(() => {
    return {
      toolbar: {
        container: [
          [{ header: [1, 2, false] }],
          ["bold", "italic", "underline", "strike", "blockquote"],
          [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }],
          ["link", "image"],
          ["clean"],
        ],
        handlers: {
          image: imageHandler,
        },
      },
      ImageResize: {
        parchment: Quill.import("parchment"),
      },
      clipboard: {
        matchVisual: false,
      },
    };
  }, [imageHandler]);

  return (
    <>
      <Switch
        style={{ marginBottom: 10 }}
        checkedChildren="HTML"
        unCheckedChildren="Editor"
        onChange={toggleEditorSwitch}
      />
      {editorMode ? (
        <div>
          {uploadProgress > 0 && <Progress percent={uploadProgress} />}
          <ReactQuill
            ref={quillRef}
            theme="snow"
            modules={modules}
            formats={formats}
            value={editorHtml}
            onChange={handleChange}
            scrollingContainer={"html"}
          />
        </div>
      ) : (
        <TextArea
          rows={20}
          value={htmlText}
          onChange={(e) => handleHTMLEditorChange(e.target.value)}
        />
      )}
    </>
  );
});
