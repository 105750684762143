import { gql } from "@apollo/client";

import { DetailPage } from "../../components/pages";
import {
  AuditHiddenListWidget,
  AuditSuspendedListWidget,
  CommentListWidget,
  MemberListWidget,
  PostListWidget,
} from "../../components/widgets";
import { AuditInactivatedListWidget } from "../../components/widgets/AuditInactivatedListWidget.jsx";

export const UserDetail = () => (
  <DetailPage
    resource="user"
    fields={[
      {
        name: "id",
      },
      {
        name: "email",
        type: "email",
      },
      {
        name: "phone",
        type: "phone",
      },
      {
        name: "name",
      },
      {
        name: "birthDate",
        type: "date",
      },
      {
        name: "country",
        type: "object",
        resource: "country",
      },
      {
        name: "serviceLanguage",
        type: "object",
        resource: "language",
      },
      {
        name: "translationLanguage",
        type: "object",
        resource: "language",
      },
      {
        name: "staff",
        type: "object",
        resource: "staff",
      },
      {
        name: "artists",
        type: "object",
        resource: "artist",
      },
      {
        name: "doesAgreeMarketing",
        type: "boolean",
        filterKey: "doesAgreeMarketing_Exact",
      },
      {
        name: "doesAgreeTerms",
        type: "boolean",
        filterKey: "doesAgreeTerms_Exact",
      },
      {
        name: "memberCount",
        type: "integer",
      },
      {
        name: "status",
        type: "choice",
        enumType: "UserStatus",
      },
      {
        name: "withdrawalCategory",
        type: "choice",
        enumType: "UserWithdrawalCategory",
      },
      {
        name: "withdrawalReason",
        type: "choice",
        enumType: "UserWithdrawalReason",
      },
      {
        name: "withdrawalRequestedAt",
        type: "datetime",
      },
      {
        name: "lastAuthenticatedAt",
        type: "datetime",
      },
      {
        name: "createdAt",
        labelKey: "admin.field.joinedAt",
        type: "datetime",
      },
    ]}
    query={QUERY}
    extraActions={DetailPage.extraActions.canActivate}
    tabs={[
      {
        path: "",
        labelKey: "admin.resource.member.plural",
        permission: {
          action: "list",
          targetType: "MEMBER",
        },
        children: ({ obj }) => (
          <MemberListWidget
            baseFilter={{
              user_Overlap: [obj.id],
            }}
            createSearchParams={{ user: obj.id }}
            hiddenFields={["user"]}
          />
        ),
      },
      {
        path: "posts",
        labelKey: "admin.resource.post.plural",
        permission: {
          action: "list",
          targetType: "POST",
        },
        children: ({ obj }) => (
          <PostListWidget
            baseFilter={{
              member_User_Overlap: [obj.id],
            }}
            hideCreate
          />
        ),
      },
      {
        path: "comments",
        labelKey: "admin.resource.comment.plural",
        permission: {
          action: "list",
          targetType: "COMMENT",
        },
        children: ({ obj }) => (
          <CommentListWidget
            baseFilter={{
              member_User_Overlap: [obj.id],
            }}
            hideCreate
          />
        ),
      },
      {
        path: "suspended",
        labelKey: "admin.page.audit.tabSuspended",
        permission: {
          action: "list",
          targetType: "AUDIT",
        },
        children: ({ obj }) => (
          <AuditSuspendedListWidget
            baseFilter={{
              targetUserId_Overlap: [obj.id],
              category_Overlap: ["SUSPENDED"],
            }}
            hiddenFields={["category"]}
          />
        ),
      },
      {
        path: "hidden",
        labelKey: "admin.page.audit.tabHidden",
        permission: {
          action: "list",
          targetType: "AUDIT",
        },
        children: ({ obj }) => (
          <AuditHiddenListWidget
            baseFilter={{
              targetUserId_Overlap: [obj.id],
              category_Overlap: ["HIDDEN"],
            }}
            hiddenFields={["category", "targetUser"]}
          />
        ),
      },
      {
        path: "inactivated",
        labelKey: "admin.page.audit.tabInactivated",
        permission: {
          action: "list",
          targetType: "AUDIT",
        },
        children: ({ obj }) => (
          <AuditInactivatedListWidget
            baseFilter={{
              targetUserId_Overlap: [obj.id],
            }}
            hiddenFields={["category", "targetUser"]}
          />
        ),
      },
    ]}
  />
);

// ==

const QUERY = gql`
  query userForUserDetail($id: ID!) {
    user(filter: { id_Overlap: [$id] }) {
      id
      email
      phone
      name
      birthDate
      country {
        id
        name
      }
      serviceLanguage {
        id
        name
      }
      translationLanguage {
        id
        name
      }
      staff {
        id
        user {
          id
          email
          name
        }
      }
      artists {
        id
        name
      }
      doesAgreeMarketing
      doesAgreeTerms
      memberCount
      status
      withdrawalCategory
      withdrawalReason
      withdrawalRequestedAt
      lastAuthenticatedAt
      ...TrackedFields
      ...CanActivateFields
    }
  }
  ${DetailPage.fragments.tracked}
  ${DetailPage.fragments.canActivate}
`;
