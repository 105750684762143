import { useState } from "react";

import { gql, useLazyQuery } from "@apollo/client";

import { FileSearchOutlined } from "@ant-design/icons";
import { Button, Drawer, Tabs, Typography } from "antd";

import { t } from "i18next";

import { useApp } from "../../hooks/app";
import { DETAIL_MEDIA_FRAGMENT } from "../../utils/gql/fragments";

const { Text } = Typography;

export const EventV2ApplicationViewButton = ({
  applicationId,
  hideWinners = false,
}) => {
  const { message } = useApp();
  const [open, setOpen] = useState(false);

  const [fetchAnswer, { data, loading, error }] = useLazyQuery(QUERY, {
    variables: {
      applicationId: applicationId,
    },
  });

  const showDrawer = async () => {
    setOpen(true);
    await fetchAnswer();
  };

  const handleClose = () => {
    setOpen(false);
  };

  if (error) {
    console.error(error);
    message.error(JSON.stringify(error));
  }

  const surveyAnswers = data?.eventV2SurveyQuestionAnswers?.objects || [];
  const winnerAnswers = data?.eventV2WinnerQuestionAnswers?.objects || [];

  const items = [
    {
      key: "1",
      label: t("admin.field.surveyQuestionAnswer"),
      children: (
        <>
          {surveyAnswers.length > 0 ? (
            surveyAnswers.map((surveyAnswer) => (
              <div key={surveyAnswer.id} style={styles.answer}>
                <strong style={styles.title}>{surveyAnswer.question.title}</strong>
                <div>{mapSurveyAnswer(surveyAnswer)}</div>
              </div>
            ))
          ) : (
            <Text>{t("admin.form.guide.noAnswersFound")}</Text>
          )}
        </>
      ),
    },
    !hideWinners && {
      key: "2",
      label: t("admin.field.winnerQuestionAnswer"),
      children: (
        <>
          {winnerAnswers.length > 0 ? (
            winnerAnswers.map((winnerAnswer) => (
              <div key={winnerAnswer.id} style={styles.answer}>
                <strong style={styles.title}>{winnerAnswer.question.title}</strong>
                <Text>{winnerAnswer.textInput}</Text>
              </div>
            ))
          ) : (
            <Text>{t("admin.form.guide.noAnswersFound")}</Text>
          )}
        </>
      ),
    },
  ];

  return (
    <>
      <Button size="small" type="default" onClick={showDrawer} loading={loading}>
        <FileSearchOutlined />
      </Button>
      <Drawer title={t("admin.common.survey")} open={open} onClose={handleClose}>
        <Tabs defaultActiveKey="1" items={items} />
      </Drawer>
    </>
  );
};

// ==

const QUERY = gql`
  query EventV2AnswersForEventV2SurveyApplicationButton($applicationId: ID!) {
    eventV2SurveyQuestionAnswers(filter: { application_Overlap: [$applicationId] }) {
      objects {
        id
        question {
          id
          title
          category
        }
        options {
          id
          value
        }
        file {
          ...DetailMediaFields
        }
        textInput
      }
    }
    eventV2WinnerQuestionAnswers(
      filter: { winner_Application_Overlap: [$applicationId] }
    ) {
      objects {
        id
        question {
          id
          title
        }
        textInput
      }
    }
  }
  ${DETAIL_MEDIA_FRAGMENT}
`;

const styles = {
  title: {
    display: "block",
    marginTop: 0,
  },
  description: {
    display: "block",
    marginTop: "12px",
    marginBottom: "12px",
  },
  image: {
    display: "block",
    width: "100%",
    marginBottom: "22px",
  },
  answer: {
    display: "block",
    marginBottom: "22px",
  },
};

const mapSurveyAnswer = (surveyAnswer) => {
  const { category } = surveyAnswer.question;
  switch (category) {
    case "SHORT_TEXT":
    case "LONG_TEXT":
    case "DATE":
    case "PHONE":
    case "COUNTRY":
      if (!surveyAnswer.textInput) {
        return <Text>-</Text>;
      }
      return <Text>{surveyAnswer.textInput}</Text>;
    case "SINGLE_CHOICE":
    case "MULTIPLE_CHOICE":
      if (surveyAnswer.options.length === 0) {
        return <Text>-</Text>;
      }
      return (
        <Text>{surveyAnswer.options.map((option) => option.value).join(", ")}</Text>
      );
    case "FILE":
      if (!surveyAnswer.file?.url) {
        return <Text>-</Text>;
      }
      return (
        <img src={surveyAnswer.file.url} alt="Uploaded file" style={styles.image} />
      );
    default:
      return <Text>Unknown surveyAnswer type</Text>;
  }
};
