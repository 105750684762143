import { FC, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { Button, DatePicker, Space } from "antd";

import dayjs, { type Dayjs } from "dayjs";

import { FilterProps } from "@/types/filter/FilterProps";
import { isEmpty } from "@/utils/helpers";

import { FilterButtonContainer, FilterDivider, FilterInputContainer } from "./styled";

const { RangePicker } = DatePicker;

type RangeValueType = [Dayjs | null, Dayjs | null];
type Props = FilterProps & {
  /** @default "datetime" */
  mode?: "date" | "datetime";
};

export const DatetimeFilter: FC<Props> = ({
  mode = "datetime",
  setSelectedKeys,
  selectedKeys,
  confirm,
  clearFilters,
}) => {
  const { t } = useTranslation();

  const value = useMemo(
    () =>
      selectedKeys.map((value) => {
        if (!value) return null;
        return dayjs(value as string);
      }) as RangeValueType,
    [selectedKeys],
  );

  const showTime = mode === "datetime" ? { format: "HH:mm:ss" } : false;
  const format = mode === "date" ? "YYYY-MM-DD" : "YYYY-MM-DD HH:mm:ss";
  const normalize = useCallback(
    (day: Dayjs) => {
      if (mode === "date") return day.format("YYYY-MM-DD");
      return day.toISOString();
    },
    [mode],
  );
  const onChange = useCallback(
    (value: RangeValueType | null) => {
      if (!value) return setSelectedKeys([]);
      const [start, end] = value;
      if (!start || !end) return setSelectedKeys([]);
      setSelectedKeys([start, end].map(normalize));
    },
    [setSelectedKeys],
  );

  return (
    <div onKeyDown={(e) => e.stopPropagation()}>
      <FilterInputContainer>
        <Space>
          <RangePicker
            showTime={showTime}
            format={format}
            value={value}
            onChange={onChange}
          />
        </Space>
      </FilterInputContainer>
      <FilterDivider />
      <FilterButtonContainer>
        <Button
          type="link"
          size="small"
          disabled={isEmpty(selectedKeys)}
          onClick={clearFilters}
        >
          {t("admin.common.reset")}
        </Button>
        <Button type="primary" onClick={() => confirm()} size="small">
          {t("admin.common.ok")}
        </Button>
        t
      </FilterButtonContainer>
    </div>
  );
};
