import { gql } from "@apollo/client";

import { ListPage } from "../../components/pages";

export const PrivacyList = () => (
  <ListPage
    resource="privacy"
    baseFilter={{
      category_Overlap: ["PRIVACY"],
    }}
    fields={[
      {
        name: "id",
      },
      {
        name: "title",
      },
      {
        name: "appliedOn",
        type: "date",
        sortKey: "APPLIED_ON",
      },
      {
        name: "status",
        type: "choice",
        enumType: "AgreementStatus",
        filterKey: "status_Overlap",
      },
      ...ListPage.fields.tracked,
    ]}
    query={QUERY}
    extraRowActions={ListPage.extraRowActions.canActivate}
  />
);

// ==

const QUERY = gql`
  query agreementsForPrivacyList(
    $filter: AgreementFilterInput
    $sort: [AgreementSortInput]
    $page: PageInput
  ) {
    agreements(filter: $filter, sort: $sort, page: $page) {
      objects {
        id
        title
        appliedOn
        status
        ...TrackedFields
        ...CanActivateFields
      }
      ...ListFields
    }
  }
  ${ListPage.fragments.list}
  ${ListPage.fragments.tracked}
  ${ListPage.fragments.canActivate}
`;
