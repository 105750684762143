import React, { PropsWithChildren, createContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { App as AntdApp, ConfigProvider, theme } from "antd";
import type { useAppProps as AntdContext } from "antd/es/app/context";

import type { Prettify } from "ts-essentials";

import { useLocalStorage } from "@/hooks/localStorage";
import type { OriginalMenus } from "@/menus";
import { ResourceName } from "@/resources";
import { BuiltMenu, Menu } from "@/types/menu";
import type { BuiltResource, BuiltResourceMap } from "@/types/resource";
import type { ResourceLike } from "@/types/resource";
import type { TranslateFn } from "@/types/utils";
import { camelToHyphenCase, camelToSnakeCase } from "@/utils/helpers";

type CustomAppContext = {
  resources: BuiltResourceMap;
  menus: BuiltMenu[];
  // ==
  toggleColorMode: () => void;
  colorMode: "light" | "dark";
};

type AppContext = Prettify<
  // prettier-ignore
  & CustomAppContext
  & AntdContext
>;

export const AppContext = createContext<AppContext>({} as AppContext);

export const AppProvider = ({
  resources: originalResources,
  menus: originalMenus,
  ...props
}: PropsWithChildren<{ resources: ResourceLike[]; menus: OriginalMenus }>) => {
  const isSystemPreferenceDark = window?.matchMedia("(prefers-color-scheme: dark)")
    .matches;
  const systemPreference = isSystemPreferenceDark ? "dark" : "light";

  const [colorMode, setColorMode] = useLocalStorage("colorMode", systemPreference);
  const { t } = useTranslation();

  const resources = useMemo(
    () =>
      Object.fromEntries(
        originalResources.map((resource) => [
          resource.name,
          inferResource(resource, t),
        ]),
      ),
    [originalResources, t],
  ) as BuiltResourceMap;
  const menus = useMemo(
    () => originalMenus.map((menu) => inferMenu(menu, resources, t)),
    [originalMenus, resources, t],
  );

  // ==

  const toggleColorMode = () => {
    if (colorMode === "light") {
      setColorMode("dark");
    } else {
      setColorMode("light");
    }
  };

  return (
    <ConfigProvider
      theme={{
        algorithm: colorMode === "light" ? theme.defaultAlgorithm : theme.darkAlgorithm,
        components: {
          Layout: {
            headerHeight: 44,
            algorithm: true,
          },
        },
      }}
    >
      <AntdApp>
        <AntdAppWrapper
          value={{
            resources,
            menus,
            // ==
            toggleColorMode,
            colorMode,
          }}
          {...props}
        />
      </AntdApp>
    </ConfigProvider>
  );
};

export { AppProvider as God };

// ==

const AntdAppWrapper: React.FC<{ value: CustomAppContext }> = ({ value, ...props }) => {
  const antdContext = AntdApp.useApp();

  return <AppContext.Provider value={{ ...antdContext, ...value }} {...props} />;
};

// ==

const inferResource = (originalResource: ResourceLike, t: TranslateFn) => {
  const resource = { ...originalResource };

  resource.namePlural ??= resource.name + "s";
  resource.type ??= resource.name;
  resource.typePlural ??= resource.namePlural;
  resource.label ??= t(`admin.resource.${resource.name}.singular`);
  resource.labelPlural ??= t(`admin.resource.${resource.name}.plural`);
  resource.typeName ??= camelToSnakeCase(resource.type).toUpperCase();

  const keyField = resource.keyField ?? "id";
  const keyGetter = resource.keyGetter ?? ((obj) => obj?.[keyField] as string);
  resource.keyField = keyField;
  resource.keyGetter = keyGetter;
  resource.labelField ??= undefined;
  resource.labelGetter ??= (obj) =>
    resource.labelField
      ? obj?.[resource.labelField] ?? `${resource.label} #${obj.id}`
      : `${resource.label} #${obj.id}`;

  resource.baseUrl ??= ["/", camelToHyphenCase(resource.namePlural)].join("");
  resource.listUrl ??= [resource.baseUrl, "list"].join("/");
  resource.createUrl ??= [resource.baseUrl, "add"].join("/");
  resource.detailUrlGetter ??= (obj) => [resource.baseUrl, keyGetter(obj)].join("/");
  resource.updateUrlGetter ??= (obj) =>
    [resource.baseUrl, keyGetter(obj), "edit"].join("/");

  resource.listComponent ??= undefined;
  resource.detailComponent ??= undefined;
  resource.formComponent ??= undefined;
  resource.selectComponent ??= undefined;

  return resource as BuiltResource;
};

const inferMenu = (originalMenu: Menu, resources: BuiltResourceMap, t: TranslateFn) => {
  const { labelKey, ...menu } = { ...originalMenu } as any;

  const resourceName = menu.resource as ResourceName;
  if (resourceName) {
    const resource = resources[resourceName];
    menu.key ??= resource.name;
    menu.label ??= labelKey ? t(labelKey) : resource.labelPlural;
    menu.url ??= resource.listUrl;
    menu.permission ??= {
      action: "list",
      targetType: resource.typeName,
    };
  }

  if (!menu.label) {
    menu.label = t(labelKey ?? `admin.menu.${menu.key}`);
  }

  if (menu.url) {
    menu.label = <Link to={menu.url}>{menu.label}</Link>;
  }

  if (menu.children) {
    menu.children = menu.children.map((child: Menu) => inferMenu(child, resources, t));
  }

  return menu as BuiltMenu;
};
