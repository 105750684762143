import type { ForwardedRef, ReactNode } from "react";
import {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";

import type { DocumentNode, TypedDocumentNode } from "@apollo/client";
import { useMutation } from "@apollo/client";

import type { StrictOmit } from "ts-essentials";

import { Form, FormProps, FormRef } from "@/components/form/Form";
import type { GqlMutationData } from "@/gql/types";
import { useApp } from "@/hooks/app";
import { useTranslate } from "@/hooks/i18n";
import type { FormRequestLike, FormResponseLike, MutationError } from "@/types/form";
import type { RecordType } from "@/types/primitives";

export type MutationFormProps<
  Mutation extends DocumentNode,
  FormValue extends GqlMutationData<Mutation>,
> = {
  /** update mode if record id exists */
  id?: string;
  mutation: Mutation;
  /** return form data when mutation succeed */
  onSuccessRequest?: (object: FormValue) => void;
  /** return server response when mutation succeed */
  onSuccess?: (object: RecordType) => void;
  /** @default t("admin.message.saveSuccess") */
  successMessage?: string | null;
  updateOnMutate?: (cache: any, response: any) => void;
} & StrictOmit<FormProps<FormValue>, "onSubmit">;

const MutationForm = forwardRef(
  <
    Mutation extends TypedDocumentNode<FormResponseLike, FormRequestLike>,
    FormValue extends GqlMutationData<Mutation>,
  >(
    {
      id,
      meta,
      mutation,
      successMessage: $successMessage,
      updateOnMutate,
      onSuccessRequest,
      onSuccess,
      ...formProps
    }: MutationFormProps<Mutation, FormValue>,
    ref: ForwardedRef<FormRef>,
  ) => {
    const { message } = useApp();
    const formRef = useRef<FormRef | null>(null);
    const t = useTranslate();
    const [mutate, { loading }] = useMutation(mutation);
    const [nonFieldError, setNonFieldError] = useState<ReactNode>();
    const successMessage = useMemo(() => {
      if ($successMessage === null) return null;
      return $successMessage || t("admin.message.saveSuccess");
    }, [t, $successMessage]);

    useImperativeHandle(ref, () => formRef.current!);

    const { flatFieldNames: fieldNames } = meta;

    const handleError = useCallback(
      (error: MutationError) => {
        if (
          !formRef.current ||
          error.code == "nonFieldErrors" ||
          !fieldNames.includes(error.code) ||
          !fieldNames.includes(error.code.split(".")[0])
        ) {
          return setNonFieldError(error.messages.join("\n"));
        }
        formRef.current.setError(error.code, {
          type: "server",
          message: error.messages.join("\n"),
        });
      },
      [setNonFieldError, fieldNames],
    );

    const handleMutate = (normalizedData: FormValue) => {
      mutate({
        variables: { data: normalizedData, id },
        onCompleted: (response) => {
          const { ok, errors, object } = Object.values(response)[0];

          if (ok) {
            onSuccessRequest?.(normalizedData);
            onSuccess?.(object);
            if (successMessage) message.success(successMessage).then();
            return;
          }

          setNonFieldError(undefined);
          errors.forEach((error) => handleError(error));
        },
        update: updateOnMutate,
      }).then();
    };

    return (
      <Form
        {...formProps}
        ref={formRef}
        meta={meta}
        isUpdate={Boolean(id)}
        loading={loading}
        nonFieldError={nonFieldError}
        onSubmit={handleMutate}
      />
    );
  },
);

// == for using generic in forwardRef components
const _MutationForm = MutationForm as <
  Mutation extends TypedDocumentNode<FormResponseLike, FormRequestLike>,
  FormValue extends GqlMutationData<Mutation>,
>(
  props: MutationFormProps<Mutation, FormValue> & { ref?: ForwardedRef<FormRef> },
) => ReactNode;

export { _MutationForm as MutationForm };
