import { useCallback, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";

import config from "@/config";
import type { FilterInput, PageInput, SortInput } from "@/types/list";
import { deepEqual, isEmptyArray, isEmptyObject } from "@/utils/helpers";

type UseFilterStateOptions = {
  /** @default "state" */
  mode?: "search-params" | "state";
  defaultValue?: Partial<FilterState>;
};

type FilterState = {
  filter: FilterInput;
  /** @default [{ type: "CREATED_AT", direction: "DESC" }], */
  sort: SortInput;
  /** @default { first: config.defaultPageSize } */
  page: PageInput;
};

export type SetFilterState = (newState: Partial<FilterState>) => void;

type UseFilterStateImplResult = {
  filter: FilterInput;
  sort: SortInput;
  page: PageInput;
  set: SetFilterState;
};

type UseFilterStateImpl = (defaultValue: FilterState) => UseFilterStateImplResult;
type UseFilterState = (options?: UseFilterStateOptions) => UseFilterStateImplResult & {
  // helpers
  reset: () => void;
  isResettable: boolean;
  pageSize: number;
  setPage: (page: PageInput) => void;
  setFilter: (filter: FilterInput) => void;
  setSort: (sort: SortInput) => void;
};

export const useFilterState: UseFilterState = (options = {}) => {
  const { mode = "state" } = options;
  // make defaultValue memoized with system default values
  const defaultValue = useMemo(() => {
    const { defaultValue } = options;
    const filter = defaultValue?.filter ?? {};
    const sort = defaultValue?.sort ?? config.defaultSort;
    const page = defaultValue?.page ?? { first: config.defaultPageSize };
    return { filter, sort, page } as const;
  }, [options.defaultValue]);

  const useFilter =
    mode === "search-params" ? useFilterStateWithSearchParams : useFilterStateWithState;
  const { filter, sort, page, set } = useFilter(defaultValue);

  // helpers
  const reset = useCallback(() => set(defaultValue), [set, defaultValue]);
  const isResettable = useMemo(
    () =>
      !deepEqual(filter, defaultValue.filter) || !deepEqual(sort, defaultValue.sort),
    [filter, sort, defaultValue],
  );
  const setPage = useCallback((page: PageInput) => set({ page }), [set]);
  const setFilter = useCallback((filter: FilterInput) => set({ filter }), [set]);
  const setSort = useCallback((sort: SortInput) => set({ sort }), [set]);
  const pageSize = useMemo(
    () => page.first || page.last || defaultValue.page.first!,
    [page, defaultValue],
  );

  return {
    filter,
    sort,
    page,
    set,
    reset,
    isResettable,
    pageSize,
    setPage,
    setFilter,
    setSort,
  };
};

const useFilterStateWithSearchParams: UseFilterStateImpl = (defaultValue) => {
  const [searchParams, setSearchParams] = useSearchParams();

  // parse search params first to memoize
  const filterParam = searchParams.get("filter");
  const sortParam = searchParams.get("sort");
  const pageParam = searchParams.get("page");

  const filter = useMemo(() => {
    return filterParam ? (JSON.parse(filterParam) as FilterInput) : defaultValue.filter;
  }, [filterParam, defaultValue]);

  const sort = useMemo(() => {
    return sortParam ? (JSON.parse(sortParam) as SortInput) : defaultValue.sort;
  }, [sortParam, defaultValue]);

  const page = useMemo(() => {
    return pageParam ? (JSON.parse(pageParam) as PageInput) : defaultValue.page;
  }, [pageParam, defaultValue]);

  const set: SetFilterState = useCallback(
    ({ filter: newFilter, sort: newSort, page: newPage }) => {
      const params = Object.fromEntries(searchParams.entries());

      if (newFilter) {
        if (isEmptyObject(newFilter)) delete params.filter;
        else params.filter = JSON.stringify(newFilter);
      }
      if (newSort) {
        if (isEmptyArray(newSort)) delete params.sort;
        else params.sort = JSON.stringify(newSort);
      }
      if (newPage) {
        if (isEmptyObject(newPage)) delete params.page;
        else params.page = JSON.stringify(newPage);
      }

      setSearchParams(params);
    },
    [searchParams],
  );

  return { filter, sort, page, set } as const;
};

const useFilterStateWithState: UseFilterStateImpl = (defaultValue) => {
  const [filter, setFilter] = useState<FilterInput>(defaultValue.filter);
  const [sort, setSort] = useState<SortInput>(defaultValue.sort);
  const [page, setPage] = useState<PageInput>(defaultValue.page);

  const set: SetFilterState = useCallback(
    ({ filter: newFilter, sort: newSort, page: newPage }) => {
      if (newFilter) setFilter(newFilter);
      if (newSort) setSort(newSort);
      if (newPage) setPage(newPage);
    },
    [setFilter, setSort, setPage],
  );

  return { filter, sort, page, set } as const;
};
