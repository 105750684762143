import { gql } from "@apollo/client";

import { ListWidget } from "../list";

export const ScheduleListWidget = (props) => (
  <ListWidget
    resource="schedule"
    fields={[
      {
        name: "id",
      },
      {
        name: "group",
        type: "object",
        resource: "group",
        filterKey: "group_Overlap",
      },
      {
        name: "title",
        filterKey: "title_Text",
      },
      {
        name: "artists",
        type: "object",
        resource: "artist",
        filterKey: "artists_Overlap",
      },
      {
        name: "category",
        type: "choice",
        enumType: "ScheduleCategory",
        filterKey: "category_Overlap",
      },
      {
        name: "scheduledAt",
        type: "datetime",
        filterKey: "scheduledAt_Range",
        sortKey: "SCHEDULED_AT",
      },
      {
        name: "status",
        type: "choice",
        enumType: "ScheduleStatus",
        filterKey: "status_Overlap",
      },
      {
        name: "activateAt",
        type: "datetime",
      },
      ...ListWidget.fields.tracked,
    ]}
    query={QUERY}
    extraRowActions={ListWidget.extraRowActions.canActivate}
    {...props}
  />
);

// ==

const QUERY = gql`
  query schedulesForScheduleListWidget(
    $filter: ScheduleFilterInput
    $sort: [ScheduleSortInput]
    $page: PageInput
  ) {
    schedules(filter: $filter, sort: $sort, page: $page) {
      objects {
        id
        group {
          id
          name
        }
        title
        artists {
          id
          name
        }
        category
        scheduledAt
        status
        ...TrackedFields
        ...CanActivateFields
      }
      ...ListFields
    }
  }
  ${ListWidget.fragments.list}
  ${ListWidget.fragments.tracked}
  ${ListWidget.fragments.canActivate}
`;
