import { gql } from "@apollo/client";

import { MUTATION_ERROR_FRAGMENT } from "../../utils/gql/fragments";
import { DownloadButton } from "./DownloadButton";

export const DownloadEventApplicationButton = (props) => (
  <DownloadButton mutation={MUTATION} {...props} />
);

// ==

const MUTATION = gql`
  mutation SendEventApplicationCsv($filter: JSONString, $sort: JSONString) {
    sendEventApplicationCsv(filter: $filter, sort: $sort) {
      ok
      errors {
        ...MutationErrorFields
      }
    }
  }
  ${MUTATION_ERROR_FRAGMENT}
`;
