import { useTranslation } from "react-i18next";
import { Link, Route, Routes, useLocation, useNavigate } from "react-router-dom";

import { Breadcrumb, Col, Row, Space, Tabs } from "antd";

import { ListTable } from "../../components/list";
import { PageHeader } from "../../components/typography";
import { useApp } from "../../hooks/app";

export const ListPage = ({
  resource: resourceName,
  baseFilter = undefined,
  globalSearchKey: globalSearchKey,
  outlet = undefined,
  title = undefined,
  titleKey = undefined,
  subtitle = undefined,
  subtitleKey = undefined,
  tabs = undefined,
  ...tableProps
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { resources } = useApp();
  const { t } = useTranslation();

  const resource = resources[resourceName];
  const tabKey = location.pathname;

  return (
    <Space direction="vertical" size="middle" style={styles.container}>
      <Row justify="space-between">
        <Col>
          <Breadcrumb
            items={[
              { title: <Link to="/">{t("admin.common.home")}</Link> },
              { title: <Link to={resource.listUrl}>{resource.labelPlural}</Link> },
            ]}
          />
        </Col>
        {outlet && <Col>{outlet}</Col>}
      </Row>
      <PageHeader
        title={title ?? resource.labelPlural}
        titleKey={titleKey}
        subtitle={subtitle}
        subtitleKey={subtitleKey}
      />

      {tabs ? (
        <>
          <div>
            <Tabs
              activeKey={tabKey}
              items={tabs.map(({ path, label, labelKey }) => ({
                key: [resource.listUrl, ...(path ? [path] : [])].join("/"),
                label: label ?? (labelKey ? t(labelKey) : null),
              }))}
              onChange={(key) => navigate(key)}
            />
            <Routes>
              {tabs.map(
                ({ path, tableProps: { baseFilter: tabBaseFilter, ...props } }) => (
                  <Route
                    key={path}
                    path={path}
                    element={
                      <ListTable
                        resource={resourceName}
                        baseFilter={{
                          ...baseFilter,
                          ...tabBaseFilter,
                        }}
                        globalSearchKey={globalSearchKey}
                        {...props}
                      />
                    }
                  />
                ),
              )}
            </Routes>
          </div>
        </>
      ) : (
        <ListTable
          resource={resourceName}
          baseFilter={baseFilter}
          globalSearchKey={globalSearchKey}
          {...tableProps}
        />
      )}
    </Space>
  );
};

// ==

ListPage.fragments = ListTable.fragments;
ListPage.fields = ListTable.fields;
ListPage.extraRowActions = ListTable.extraRowActions;

// ==

const styles = {
  container: {
    display: "flex",
  },
};
