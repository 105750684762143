import { useTranslation } from "react-i18next";

import { gql } from "@apollo/client";

import { Radio } from "antd";

import config from "../../config";
import { useDrawerState } from "../../hooks/drawer";
import { InlineFormDrawer } from "../drawers";
import {
  OfflineStoreItemRestrictionEditor,
  OfflineStoreItemVariantEditor,
} from "../fields";
import { EnumRadio } from "../fields";

export const CreateOfflineItemAction = ({
  onChange,
  disabled = false,
  drawerWidth = config.defaultDrawerWidth,
  children,
  ...props
}) => {
  const { t } = useTranslation();
  const { doesRender, openDrawer, closeDrawer, drawerProps } = useDrawerState();
  const onSuccess = (value) => {
    const originalValue = props.obj ?? {};
    onChange?.({ ...originalValue, ...value });
    closeDrawer();
  };

  return (
    <>
      {children?.({ openDrawer, disabled })}
      {doesRender && (
        <InlineFormDrawer
          drawerWidth={drawerWidth}
          resource="offlineStoreItem"
          fields={[
            {
              name: "nameContent",
              labelKey: "admin.field.name",
              type: "translatable",
            },
            {
              name: "isSingular",
              readOnly: true,
              render: ({ controllerField }) => (
                <Radio.Group {...controllerField}>
                  <Radio value={true}>{t("admin.form.label.single")}</Radio>
                  <Radio value={false}>{t("admin.form.label.multiple")}</Radio>
                </Radio.Group>
              ),
            },
            {
              name: "availability",
              hidden: ({ data }) => !data.isSingular,
              render: ({ controllerField }) => (
                <EnumRadio
                  {...controllerField}
                  type="OfflineStoreItemVariantAvailability"
                  direction="vertical"
                />
              ),
            },
            {
              name: "variants",
              labelKey: "admin.field.variants",
              hidden: ({ data }) => data.isSingular,
              render: ({ controllerField }) => (
                <OfflineStoreItemVariantEditor {...controllerField} />
              ),
            },
            {
              name: "price",
              type: "integer",
            },
            {
              name: "image",
              type: "imageMeta",
            },
            {
              name: "restrictions",
              labelKey: "admin.field.restrictions",
              render: ({ controllerField, data }) => (
                <OfflineStoreItemRestrictionEditor
                  isSingular={data.isSingular}
                  {...controllerField}
                />
              ),
            },
            {
              name: "detailUrl",
            },
            ...InlineFormDrawer.fields.canActivate,
          ]}
          mutation={MUTATION}
          successMessage={t("admin.message.processSuccess")}
          onChange={onSuccess}
          drawerProps={drawerProps}
          {...props}
        />
      )}
    </>
  );
};

// ==

const MUTATION = gql`
  mutation validateOfflineStoreItemForCreateOfflineStoreItemAction(
    $data: OfflineStoreItemMutationInput!
  ) {
    validateOfflineStoreItem(data: $data) {
      ok
      errors {
        ...MutationErrorFields
      }
    }
  }
  ${InlineFormDrawer.fragments.mutationError}
`;
