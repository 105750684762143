import { gql } from "@apollo/client";

import { ListWidget } from "../list";

export const OfflineStoreNoticeListWidget = ({ filter, ...props }) => (
  <ListWidget
    resource="offlineStoreNotice"
    filter={{
      ...filter,
    }}
    fields={[
      {
        name: "id",
      },
      {
        name: "title",
        truncate: true,
      },
      {
        name: "body",
        truncate: true,
      },
      {
        name: "offlineStore",
        type: "object",
        resource: "offlineStore",
      },
      {
        name: "isActive",
        type: "boolean",
      },
      ...ListWidget.fields.tracked,
    ]}
    query={QUERY}
    extraRowActions={ListWidget.extraRowActions.canActivate}
    {...props}
  />
);

// ==

const QUERY = gql`
  query offlineStoreNoticesForOfflineStoreNoticeListWidget(
    $filter: OfflineStoreNoticeFilterInput
    $sort: [OfflineStoreNoticeSortInput]
    $page: PageInput
  ) {
    offlineStoreNotices(filter: $filter, sort: $sort, page: $page) {
      objects {
        id
        offlineStore {
          id
          name
        }
        title
        body
        isActive
        ...TrackedFields
        ...CanActivateFields
      }
      ...ListFields
    }
  }
  ${ListWidget.fragments.list}
  ${ListWidget.fragments.tracked}
  ${ListWidget.fragments.canActivate}
`;
