import { gql } from "@apollo/client";

import { ListPage, ListPageWithGroup } from "../../components/pages";

export const GroupStaffList = () => (
  <ListPageWithGroup
    groupFilterKey="permittedGroups_Overlap"
    resource="groupStaff"
    subtitleKey="admin.page.groupStaff.subtitle"
    fields={[
      {
        name: "id",
      },
      {
        name: "permittedGroups",
        labelKey: "admin.resource.group.plural",
        type: "object",
        resource: "group",
        multiple: true,
        filterKey: "permittedGroups_Overlap",
      },
      {
        name: "user.email",
        labelKey: "admin.field.email",
        type: "email",
        filterKey: "user_Email_Prefix",
      },
      {
        name: "phone",
        labelKey: "admin.field.phone",
        type: "phone",
      },
      {
        name: "user.name",
        labelKey: "admin.field.name",
      },
      {
        name: "roleCategories",
        labelKey: "admin.resource.role.plural",
        type: "choice",
        enumType: "RoleCategory",
        multiple: true,
        filterKey: "roleCategories_Overlap",
      },
      ...ListPage.fields.canActivate,
      {
        name: "user.lastAuthenticatedAt",
        labelKey: "admin.field.lastAuthenticatedAt",
        type: "datetime",
      },
      {
        name: "user.createdAt",
        labelKey: "admin.field.joinedAt",
        type: "datetime",
      },
    ]}
    query={QUERY}
    extraRowActions={ListPage.extraRowActions.canActivate}
  />
);

// ==

const QUERY = gql`
  query staffsForGroupStaffList(
    $filter: StaffFilterInput
    $sort: [StaffSortInput]
    $page: PageInput
  ) {
    staffs(filter: $filter, sort: $sort, page: $page) {
      objects {
        id
        phone
        user {
          id
          email
          name
          lastAuthenticatedAt
          createdAt
        }
        roleCategories
        permittedGroups {
          id
          name
        }
        ...TrackedFields
        ...CanActivateFields
      }
      ...ListFields
    }
  }
  ${ListPage.fragments.list}
  ${ListPage.fragments.tracked}
  ${ListPage.fragments.canActivate}
`;
