import React, { forwardRef, useState } from "react";

import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Col, Input, Space } from "antd";

import { EnumRadio } from "../fields";

export const OfflineStoreItemVariantEditor = forwardRef(
  ({ value, onChange, options, disabled, ...props }, ref) => {
    const [inputs, setInputs] = useState(value || [{ label: "" }]); // FIXME: default label shouldn't be ""

    const handleInputChange = (index, e) => {
      const newInputs = [...inputs];
      newInputs[index].label = e.target.value;
      setInputs(newInputs);
      onChange(newInputs);
    };

    const handleRemove = (index) => {
      const newInputs = [...inputs];
      newInputs.splice(index, 1);
      setInputs(newInputs);
      onChange(newInputs);
    };

    const handleAdd = () => {
      setInputs([...inputs, { label: "" }]);
    };

    // FIXME: change EnumRadio direction after translation is applied
    return (
      <Col span={20} style={styles.container}>
        <Space direction="vertical" size="middle" style={{ width: "100%" }}>
          {inputs.map((input, index) => (
            <Space key={index} style={{ width: "100%" }}>
              <Input
                disabled={disabled}
                placeholder="placeholder"
                value={input.label}
                onChange={(e) => handleInputChange(index, e)}
                style={{ flexGrow: 1 }}
              />
              <EnumRadio
                type="OfflineStoreItemVariantAvailability"
                direction="vertical"
                value={input.availability}
                onChange={(value) => {
                  const newInputs = [...inputs];
                  newInputs[index].availability = value;
                  setInputs(newInputs);
                  onChange(newInputs);
                }}
                disabled={disabled}
              />
              <Button
                type="text"
                icon={<DeleteOutlined />}
                onClick={() => handleRemove(index)}
              />
            </Space>
          ))}
          <Button type="link" icon={<PlusOutlined />} onClick={handleAdd}>
            add option
          </Button>
        </Space>
      </Col>
    );
  },
);

// ==

const styles = {
  container: {
    width: "100%",
  },
};
