import { gql } from "@apollo/client";

import { FormPage } from "../../components/pages";
import { useAuthentication } from "../../hooks/auth";

export const RoleForm = () => {
  const { user } = useAuthentication();

  const isSuper = user.staff.isSuper;
  const isAdmin = user.staff.roleCategories.includes("ADMIN");
  const isGroupOwner = user.staff.roleCategories.includes("GROUP_OWNER");
  const isCompanyOwner = user.staff.roleCategories.includes("COMPANY_OWNER");

  const hasGroupPermission = isGroupOwner || isCompanyOwner || isAdmin || isSuper;
  const hasCompanyPermission = isCompanyOwner || isAdmin || isSuper;

  return (
    <FormPage
      resource="role"
      fields={[
        {
          name: "staff",
          type: "object",
          resource: "staff",
          disabled: ({ isUpdate }) => isUpdate,
        },
        {
          name: "category",
          type: "choice",
          enumType: "RoleCategory",
          filterOptions: () => (options) =>
            options.filter(
              (option) =>
                (option.value.startsWith("GROUP_") &&
                  option.value != "GROUP_OWNER" &&
                  hasGroupPermission) ||
                ((option.value == "GROUP_OWNER" ||
                  (option.value.startsWith("COMPANY_") &&
                    option.value != "COMPANY_OWNER")) &&
                  hasCompanyPermission) ||
                (option.value != "ADMIN" && isAdmin) ||
                isSuper,
            ),
          onChange: ({ value, resetValue }) => {
            if (!value?.startsWith("GROUP_")) {
              resetValue("group");
            }
            if (!value?.startsWith("COMPANY_")) {
              resetValue("company");
            }
          },
        },
        {
          name: "group",
          type: "object",
          resource: "group",
          hidden: ({ data }) => !data.category?.startsWith("GROUP_"),
        },
        {
          name: "company",
          type: "object",
          resource: "company",
          hidden: ({ data }) => !data.category?.startsWith("COMPANY_"),
        },
      ]}
      query={QUERY}
      createMutation={CREATE_MUTATION}
      updateMutation={UPDATE_MUTATION}
    />
  );
};

// ==

const FORM_FIELDS_FRAGMENT = gql`
  fragment RoleFormFields on Role {
    id
    staff {
      id
    }
    category
    group {
      id
    }
    company {
      id
    }
  }
`;

const QUERY = gql`
  query roleForRoleForm($id: ID!) {
    role(filter: { id_Overlap: [$id] }) {
      ...RoleFormFields
    }
  }
  ${FORM_FIELDS_FRAGMENT}
`;

const CREATE_MUTATION = gql`
  mutation createRoleForRoleForm($data: RoleMutationInput!) {
    createRole(data: $data) {
      ok
      errors {
        ...MutationErrorFields
      }
      object {
        ...RoleFormFields
      }
    }
  }
  ${FormPage.fragments.mutationError}
  ${FORM_FIELDS_FRAGMENT}
`;

const UPDATE_MUTATION = gql`
  mutation updateRoleForRoleForm($data: RoleMutationInput!, $id: ID!) {
    updateRole(data: $data, id: $id, partial: true) {
      ok
      errors {
        ...MutationErrorFields
      }
      object {
        ...RoleFormFields
      }
    }
  }
  ${FormPage.fragments.mutationError}
  ${FORM_FIELDS_FRAGMENT}
`;
