import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { Typography } from "antd";

export const BooleanField = ({
  value,
  trueType = "success",
  falseType = "danger",
  trueIcon = <CheckOutlined />,
  falseIcon = <CloseOutlined />,
  ...rest
}) => {
  return (
    <Typography.Text type={value ? trueType : falseType} {...rest}>
      {value ? <span>{trueIcon}</span> : <span>{falseIcon}</span>}
    </Typography.Text>
  );
};
