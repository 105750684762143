import { gql } from "@apollo/client";

import { FormPage } from "../../components/pages";

export const MemberForm = () => (
  <FormPage
    resource="member"
    fields={[
      {
        name: "group",
        type: "object",
        resource: "group",
        disabled: ({ isUpdate }) => isUpdate,
      },
      {
        name: "user",
        type: "object",
        resource: "user",
        disabled: ({ isUpdate }) => isUpdate,
      },
      {
        name: "nickname",
        descriptionKey: "admin.form.guide.nickname",
      },
      {
        name: "profileImage",
        type: "imageMeta",
      },
      {
        name: "coverImage",
        type: "imageMeta",
      },
    ]}
    query={QUERY}
    createMutation={CREATE_MUTATION}
    updateMutation={UPDATE_MUTATION}
  />
);

// ==

const FORM_FIELDS_FRAGMENT = gql`
  fragment MemberFormFields on Member {
    id
    group {
      id
    }
    user {
      id
    }
    nickname
    profileImage {
      ...ImageFields
    }
    coverImage {
      ...ImageFields
    }
  }
  ${FormPage.fragments.image}
`;

const QUERY = gql`
  query memberForMemberForm($id: ID!) {
    member(filter: { id_Overlap: [$id] }) {
      ...MemberFormFields
    }
  }
  ${FORM_FIELDS_FRAGMENT}
`;

const CREATE_MUTATION = gql`
  mutation createMemberForMemberForm($data: MemberMutationInput!) {
    createMember(data: $data) {
      ok
      errors {
        ...MutationErrorFields
      }
      object {
        ...MemberFormFields
      }
    }
  }
  ${FormPage.fragments.mutationError}
  ${FORM_FIELDS_FRAGMENT}
`;

const UPDATE_MUTATION = gql`
  mutation updateMemberForMemberForm($data: MemberMutationInput!, $id: ID!) {
    updateMember(data: $data, id: $id, partial: true) {
      ok
      errors {
        ...MutationErrorFields
      }
      object {
        ...MemberFormFields
      }
    }
  }
  ${FormPage.fragments.mutationError}
  ${FORM_FIELDS_FRAGMENT}
`;
