import { forwardRef } from "react";

import { gql } from "@apollo/client";

import { ObjectSelect } from "../fields";

export const SelectEventV2 = forwardRef((props, ref) => (
  <ObjectSelect resource="eventV2" query={QUERY} ref={ref} {...props} />
));

// ==

const QUERY = gql`
  query eventsV2ForSelectEventV2($filter: EventV2FilterInput) {
    eventsV2(filter: $filter, sort: [{ type: RELEVANCE, direction: DESC }]) {
      objects {
        id
        title
      }
    }
  }
`;
