import { useState } from "react";
import { useTranslation } from "react-i18next";

import { Modal, Space } from "antd";

import { HlsPlayer } from "../media";

export const ViewVideoAction = ({
  videos,
  disabled = false,
  children,
  width = 520,
  ...props
}) => {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => setIsModalOpen(true);
  const onCancel = () => setIsModalOpen(false);

  return (
    <>
      {children?.({ openModal, disabled })}
      <Modal
        title={t(`admin.component.viewVideoAction.title`)}
        open={isModalOpen}
        footer={null}
        width={Math.min(width, maxWidth)}
        onOk={onCancel}
        onCancel={onCancel}
        centered
        {...props}
      >
        <Space direction="vertical">
          {videos.map((video, i) => (
            <HlsPlayer key={i} src={video} style={{ width: "100%" }} />
          ))}
        </Space>
      </Modal>
    </>
  );
};

// ==

const maxWidth = 1000;
