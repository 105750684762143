import { useTranslation } from "react-i18next";

import { gql } from "@apollo/client";

import { CheckOutlined } from "@ant-design/icons";

import { MUTATION_ERROR_FRAGMENT } from "../../utils/gql/fragments";
import { HandleSelectedRowKeysButton } from "./HandleSelectedRowKeysButton.jsx";

export const BoothWaitListBulkEnterButton = ({
  boothId,
  selectedRowKeys,
  setSelectedRowKeys,
  onChange,
  size = "middle",
  onlyIcon = false,
  disabled = false,
}) => {
  const { t } = useTranslation();

  return (
    <HandleSelectedRowKeysButton
      objectId={boothId}
      selectedRowKeys={selectedRowKeys}
      setSelectedRowKeys={setSelectedRowKeys}
      onChange={onChange}
      buttonText={t("admin.component.boothWaitListButton.enter")}
      confirmation={t("admin.component.boothWaitListButton.enterConfirmation", {
        count: selectedRowKeys.length ?? 0,
      })}
      confirmMessage={t("admin.component.boothWaitListButton.enterConfirmMessage")}
      icon={<CheckOutlined />}
      mutation={MUTATION}
      size={size}
      onlyIcon={onlyIcon}
      disabled={disabled}
    />
  );
};

// ==

const MUTATION = gql`
  mutation boothWaitListBulkEnterForBoothWaitListBulkEnterButton(
    $objectId: ID!
    $selectedRowKeys: [ID!]!
  ) {
    boothWaitListBulkEnter(boothId: $objectId, boothWaitListIds: $selectedRowKeys) {
      ok
      errors {
        ...MutationErrorFields
      }
    }
  }
  ${MUTATION_ERROR_FRAGMENT}
`;
