import { forwardRef } from "react";

import { gql } from "@apollo/client";

import { ObjectSelect } from "../fields";

export const SelectBooth = forwardRef((props, ref) => (
  <ObjectSelect
    resource="booth"
    query={QUERY}
    optionRender={(booth) => <>{booth.title}</>}
    ref={ref}
    {...props}
  />
));

// ==

const QUERY = gql`
  query boothsForBoothSelect($filter: BoothFilterInput) {
    booths(filter: $filter, sort: [{ type: RELEVANCE, direction: DESC }]) {
      objects {
        id
        title
      }
    }
  }
`;
