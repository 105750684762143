import { gql } from "@apollo/client";

import { ListWidget } from "../list";

export const OfflineStoreItemListWidget = ({ filter, ...props }) => (
  <ListWidget
    resource="offlineStoreItem"
    filter={{
      ...filter,
    }}
    fields={[
      {
        name: "id",
      },
      {
        name: "image",
        type: "imageMeta",
      },
      {
        name: "nameContent",
        labelKey: "admin.field.name",
        type: "translatable",
      },
      {
        name: "variantCount",
        type: "integer",
      },
      {
        name: "price",
        type: "integer",
      },
      {
        name: "availability",
        enumType: "OfflineStoreItemAvailability",
        filterKey: "availability_Overlap",
        type: "choice",
      },
      {
        type: "boolean",
        name: "isActive",
        filterKey: "isActive_Exact",
      },
      ...ListWidget.fields.tracked,
    ]}
    query={QUERY}
    defaultSort={[
      {
        type: "AVAILABILITY",
        direction: "ASC",
      },
      {
        type: "ORDER",
        direction: "ASC",
      },
    ]}
    {...props}
  />
);

// ==

const QUERY = gql`
  query offlineStoreItemsForOfflineStoreItemListWidget(
    $filter: OfflineStoreItemFilterInput
    $sort: [OfflineStoreItemSortInput]
    $page: PageInput
  ) {
    offlineStoreItems(filter: $filter, sort: $sort, page: $page) {
      objects {
        id
        image {
          ...ListImageFields
        }
        nameContent {
          ...TranslatableContentFields
        }
        isActive
        variantCount
        price
        availability
        ...TrackedFields
      }
      ...ListFields
    }
  }
  ${ListWidget.fragments.translatableContent}
  ${ListWidget.fragments.image}
  ${ListWidget.fragments.list}
  ${ListWidget.fragments.tracked}
`;
