import { gql } from "@apollo/client";

import { DetailPage } from "../../components/pages";
import {
  GroupThemeListWidget,
  HashtagListWidget,
  MediaLayoutListWidget,
  MemberListWidget,
  NoticeListWidget,
  PollListWidget,
  PostListWidget,
  PremiumListWidget,
  ScheduleListWidget,
} from "../../components/widgets";

export const GroupDetail = () => (
  <DetailPage
    resource="group"
    fields={[
      {
        name: "id",
      },
      {
        name: "company",
        type: "object",
        resource: "company",
      },
      {
        name: "code",
      },
      {
        name: "name",
      },
      {
        name: "debutDate",
        type: "date",
      },
      {
        name: "facebookUrl",
        type: "url",
      },
      {
        name: "instagramUrl",
        type: "url",
      },
      {
        name: "snapchatUrl",
        type: "url",
      },
      {
        name: "tiktokUrl",
        type: "url",
      },
      {
        name: "twitterUrl",
        type: "url",
      },
      {
        name: "weiboUrl",
        type: "url",
      },
      {
        name: "youtubeUrl",
        type: "url",
      },
      {
        name: "activeTheme",
        type: "object",
        resource: "groupTheme",
      },
      {
        name: "activePremium",
        type: "object",
        resource: "premium",
      },
      {
        name: "owner",
        type: "object",
        resource: "staff",
      },
      {
        name: "artistCount",
        type: "integer",
      },
      {
        name: "memberCount",
        type: "integer",
      },
      {
        name: "postCount",
        type: "integer",
      },
      {
        name: "pollCount",
        type: "integer",
      },
      {
        name: "clipCount",
        type: "integer",
      },
      {
        name: "scheduleCount",
        type: "integer",
      },
      {
        name: "noticeCount",
        type: "integer",
      },
      {
        name: "eventCount",
        type: "integer",
      },
      {
        name: "commentCount",
        type: "integer",
      },
      {
        name: "status",
        type: "choice",
        enumType: "GroupStatus",
        filterKey: "status_Overlap",
      },
      ...DetailPage.fields.canActivate,
      {
        name: "activateAt",
        type: "datetime",
      },
      ...DetailPage.fields.tracked,
    ]}
    query={QUERY}
    extraActions={DetailPage.extraActions.canActivate}
    tabs={[
      {
        path: "",
        labelKey: "admin.resource.member.plural",
        permission: {
          action: "list",
          targetType: "MEMBER",
        },
        children: ({ obj }) => (
          <MemberListWidget
            baseFilter={{
              group_Overlap: [obj.id],
            }}
            createSearchParams={{ group: obj.id }}
            hiddenFields={["group"]}
          />
        ),
      },
      {
        path: "themes",
        labelKey: "admin.resource.groupTheme.plural",
        permission: {
          action: "list",
          targetType: "GROUP_THEME",
        },
        children: ({ obj }) => (
          <GroupThemeListWidget
            baseFilter={{
              group_Overlap: [obj.id],
            }}
            createSearchParams={{ group: obj.id }}
            hiddenFields={["group"]}
          />
        ),
      },
      {
        path: "premiums",
        labelKey: "admin.resource.premium.plural",
        permission: {
          action: "list",
          targetType: "PREMIUM",
        },
        children: ({ obj }) => (
          <PremiumListWidget
            baseFilter={{
              group_Overlap: [obj.id],
            }}
            createSearchParams={{ group: obj.id }}
            hiddenFields={["group"]}
          />
        ),
      },
      {
        path: "schedules",
        labelKey: "admin.resource.schedule.plural",
        permission: {
          action: "list",
          targetType: "SCHEDULE",
        },
        children: ({ obj }) => (
          <ScheduleListWidget
            baseFilter={{
              group_Overlap: [obj.id],
            }}
            createSearchParams={{ group: obj.id }}
            hiddenFields={["group"]}
          />
        ),
      },
      {
        path: "notices",
        labelKey: "admin.resource.notice.plural",
        permission: {
          action: "list",
          targetType: "NOTICE",
        },
        children: ({ obj }) => (
          <NoticeListWidget
            baseFilter={{
              group_Overlap: [obj.id],
            }}
            createSearchParams={{ group: obj.id }}
            hiddenFields={["group"]}
          />
        ),
      },
      {
        path: "mediaLayouts",
        labelKey: "admin.resource.mediaLayout.plural",
        permission: {
          action: "list",
          targetType: "MEDIA_LAYOUT",
        },
        children: ({ obj }) => (
          <MediaLayoutListWidget
            baseFilter={{
              group_Overlap: [obj.id],
            }}
            createSearchParams={{ group: obj.id }}
            hiddenFields={["group"]}
          />
        ),
      },
      {
        path: "posts",
        labelKey: "admin.resource.post.plural",
        permission: {
          action: "list",
          targetType: "POST",
        },
        children: ({ obj }) => (
          <PostListWidget
            baseFilter={{
              group_Overlap: [obj.id],
            }}
            createSearchParams={{ group: obj.id }}
            hiddenFields={["group"]}
          />
        ),
      },
      {
        path: "polls",
        labelKey: "admin.resource.poll.plural",
        permission: {
          action: "list",
          targetType: "POLL",
        },
        children: ({ obj }) => (
          <PollListWidget
            baseFilter={{
              group_Overlap: [obj.id],
            }}
            createSearchParams={{ group: obj.id }}
            hiddenFields={["group"]}
          />
        ),
      },
      {
        path: "hashtags",
        labelKey: "admin.resource.hashtag.plural",
        permission: {
          action: "list",
          targetType: "HASHTAG",
        },
        children: ({ obj }) => (
          <HashtagListWidget
            baseFilter={{
              group_Overlap: [obj.id],
            }}
            createSearchParams={{ group: obj.id }}
            hiddenFields={["group"]}
          />
        ),
      },
    ]}
  />
);

// ==

const QUERY = gql`
  query groupForGroupDetail($id: ID!) {
    group(filter: { id_Overlap: [$id] }) {
      id
      company {
        id
        name
      }
      code
      name
      debutDate
      facebookUrl
      instagramUrl
      snapchatUrl
      tiktokUrl
      twitterUrl
      weiboUrl
      youtubeUrl
      activeTheme {
        id
        name
      }
      activePremium {
        id
        name
      }
      owner {
        id
        user {
          id
          email
          name
        }
      }
      artistCount
      memberCount
      postCount
      pollCount
      clipCount
      scheduleCount
      noticeCount
      eventCount
      commentCount
      status
      ...TrackedFields
      ...CanActivateFields
    }
  }
  ${DetailPage.fragments.tracked}
  ${DetailPage.fragments.canActivate}
`;
