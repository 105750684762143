import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { useMutation } from "@apollo/client";

import { CopyOutlined } from "@ant-design/icons";
import { Button, Modal, Typography } from "antd";

import { useApp } from "../../hooks/app";

export const DuplicateButton = ({
  resourceName,
  id,
  mutation,
  buttonMessage,
  size = "middle",
  onlyIcon = false,
}) => {
  const { t } = useTranslation();

  const variables = {
    id,
  };
  const onCompleted = (response) => {
    const { ok, errors, object } = Object.values(response)[0];
    if (ok) {
      message.success(t("admin.message.processSuccess"));
      navigate(resources[resourceName].updateUrlGetter(object));
      setIsModalOpen(false);
    } else {
      message.error(
        t("admin.message.processFailure", {
          message: errors[0].messages[0],
        }),
      );
    }
  };

  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [duplicate, { loading }] = useMutation(mutation, {
    variables,
    onCompleted,
  });
  const { message, resources } = useApp();

  const onClick = () => setIsModalOpen(true);
  const onOk = () => duplicate();
  const onCancel = () => setIsModalOpen(false);

  return (
    <>
      <Button icon={<CopyOutlined />} onClick={onClick} size={size}>
        {onlyIcon ? null : t("admin.common.duplicate")}
      </Button>
      <Modal
        title={t(`admin.common.confirmation`)}
        open={isModalOpen}
        confirmLoading={loading}
        okText={t("admin.common.duplicate")}
        okButtonProps={{
          icon: <CopyOutlined />,
        }}
        cancelText={t("admin.common.cancel")}
        onOk={onOk}
        onCancel={onCancel}
      >
        <Typography.Text>{t(buttonMessage)}</Typography.Text>
      </Modal>
    </>
  );
};
