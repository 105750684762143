import { gql } from "@apollo/client";

import { DetailPage } from "../../components/pages";

export const TranslationVersionDetail = () => (
  <DetailPage
    resource="translationVersion"
    fields={[
      {
        name: "id",
      },
      {
        name: "revision",
      },
      {
        name: "hashedValue",
      },
      ...DetailPage.fields.tracked,
    ]}
    query={QUERY}
  />
);

// ==

const QUERY = gql`
  query translationVersionForTranslationVersionDetail($id: ID!) {
    translationVersion(filter: { id_Overlap: [$id] }) {
      id
      revision
      hashedValue
      ...TrackedFields
    }
  }
  ${DetailPage.fragments.tracked}
`;
