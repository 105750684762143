import { gql } from "@apollo/client";

import { ListPage } from "../../components/pages";

export const ExampleList = () => (
  <ListPage
    resource="example"
    fields={[
      {
        name: "id",
        filterKey: "id_Overlap",
      },
      {
        name: "slug",
        sortKey: "SLUG",
        filterKey: "slug_Exact",
      },
      {
        name: "char",
        sortKey: "CHAR",
        filterKey: "char_Text",
      },
      {
        name: "longText",
        filterKey: "longText_Text",
      },
      {
        name: "integer",
        type: "integer",
        filterKey: "integer_Range",
        sortKey: "INTEGER",
      },
      {
        name: "decimal",
        type: "float",
        filterKey: "decimal_Range",
        sortKey: "DECIMAL",
      },
      {
        name: "email",
        type: "email",
        filterKey: "email_Text",
        sortKey: "EMAIL",
      },
      {
        name: "url",
        type: "url",
        filterKey: "url_Text",
        sortKey: "URL",
      },
      {
        name: "array",
        filterKey: "array_Text",
        render: (_, { array }) => array?.join(","),
      },
      {
        name: "date",
        type: "date",
        filterKey: "date_Exact",
        sortKey: "DATE",
      },
      {
        name: "datetime",
        type: "datetime",
        filterKey: "datetime_Exact",
        sortKey: "DATETIME",
      },
      {
        name: "phone",
        type: "phone",
        filterKey: "phone_Exact",
        sortKey: "PHONE",
      },
      {
        name: "boolean",
        type: "boolean",
        filterKey: "boolean_Exact",
        sortKey: "BOOLEAN",
      },
      {
        name: "nullBoolean",
        type: "boolean",
        nullable: true,
        filterKey: "nullBoolean_Overlap",
        sortKey: "NULL_BOOLEAN",
      },
      {
        name: "textChoice",
        type: "choice",
        enumType: "ExampleTextChoice",
        filterKey: "textChoice_Overlap",
        sortKey: "TEXT_CHOICE",
      },
      {
        name: "integerChoice",
        type: "choice",
        enumType: "ExampleIntegerChoice",
        filterKey: "integerChoice_Overlap",
        sortKey: "INTEGER_CHOICE",
      },
      {
        name: "integerChoices",
        type: "choice",
        enumType: "ExampleIntegerChoice",
        filterKey: "integerChoices_Overlap",
        multiple: true,
      },
      {
        name: "countryOne",
        type: "object",
        resource: "country",
        filterKey: "countryOne_Overlap",
      },
      {
        name: "countryForeign",
        type: "object",
        resource: "country",
        filterKey: "countryForeign_Overlap",
      },
      {
        name: "countriesMany",
        type: "object",
        resource: "country",
        filterKey: "countriesMany_Overlap",
      },
      // ==
      {
        name: "status",
        type: "choice",
        enumType: "ExampleStatus",
        filterKey: "status_Overlap",
        sortKey: "STATUS",
      },
      {
        name: "metaStatus",
        type: "choice",
        enumType: "MetaStatus",
        filterKey: "metaStatus_Overlap",
        sortKey: "META_STATUS",
      },
      ...ListPage.fields.canActivate,
      ...ListPage.fields.tracked,
    ]}
    query={QUERY}
    extraRowActions={ListPage.extraRowActions.canActivate}
  />
);

// ==

const QUERY = gql`
  query examplesForExampleList(
    $filter: ExampleFilterInput
    $sort: [ExampleSortInput]
    $page: PageInput
  ) {
    examples(filter: $filter, sort: $sort, page: $page) {
      objects {
        id
        slug
        char
        longText
        integer
        decimal
        email
        url
        array
        date
        datetime
        phone
        boolean
        nullBoolean
        textChoice
        integerChoice
        integerChoices
        countryOne {
          id
          name
        }
        countryForeign {
          id
          name
        }
        countriesMany {
          id
          name
        }
        status
        metaStatus
        ...TrackedFields
        ...CanActivateFields
      }
      ...ListFields
    }
  }
  ${ListPage.fragments.list}
  ${ListPage.fragments.tracked}
  ${ListPage.fragments.canActivate}
`;
