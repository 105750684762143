import { useTranslation } from "react-i18next";

import { gql, useMutation } from "@apollo/client";

import { Button } from "antd";

import { useRevalidateQueryFields } from "../../hooks/apollo/index";
import { useApp } from "../../hooks/app";
import { MUTATION_ERROR_FRAGMENT } from "../../utils/gql/fragments";

export const SelectWinnersButton = ({ eventId, category, disabled }) => {
  const [mutate, { data, loading }] = useMutation(MUTATION);
  const revalidate = useRevalidateQueryFields("eventV2Winners");
  const { t } = useTranslation();
  const { message } = useApp();

  const handleButtonClick = async () => {
    const { data } = await mutate({
      variables: { id: eventId },
    });

    if (data) {
      const { selectWinners } = data;
      if (selectWinners.ok) {
        message.success(t("admin.message.processSuccess"));
        revalidate();
      } else {
        const errMsg = selectWinners.errors[0].messages.join("\n");
        message.error(errMsg);
      }
    }
  };

  const capitalizeFirstLetter = (str) => {
    if (typeof str !== "string" || str.length === 0) return str;
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  };

  const generateButtonKey = (category) => {
    const key =
      "admin.component.selectWinnersBy" +
      capitalizeFirstLetter(category) +
      "Button.title";
    return t(key);
  };

  return (
    <Button loading={loading} disabled={disabled} onClick={handleButtonClick}>
      {generateButtonKey(category)}
    </Button>
  );
};

const MUTATION = gql`
  mutation selectWinnersForSelectWinnerButton($id: ID!) {
    selectWinners(id: $id) {
      ok
      errors {
        ...MutationErrorFields
      }
    }
  }
  ${MUTATION_ERROR_FRAGMENT}
`;
