import { gql } from "@apollo/client";

import { ListWidget } from "../list";

export const StaffListWidget = (props) => (
  <ListWidget
    resource="staff"
    fields={[
      {
        name: "id",
      },
      {
        name: "user.email",
        labelKey: "admin.field.email",
        type: "email",
        filterKey: "user_Email_Prefix",
      },
      {
        name: "phone",
        labelKey: "admin.field.phone",
        type: "phone",
      },
      {
        name: "user.name",
        labelKey: "admin.field.name",
      },
      {
        name: "roleCategories",
        labelKey: "admin.resource.role.plural",
        type: "choice",
        enumType: "RoleCategory",
        multiple: true,
        filterKey: "roleCategories_Overlap",
      },
      {
        name: "permittedGroups",
        labelKey: "admin.resource.group.plural",
        type: "object",
        resource: "group",
        multiple: true,
        filterKey: "permittedGroups_Overlap",
      },
      {
        name: "permittedCompanies",
        labelKey: "admin.resource.company.plural",
        type: "object",
        resource: "company",
        multiple: true,
        filterKey: "permittedCompanies_Overlap",
      },
      ...ListWidget.fields.canActivate,
      ...ListWidget.fields.tracked,
    ]}
    query={QUERY}
    extraRowActions={ListWidget.extraRowActions.canActivate}
    {...props}
  />
);

// ==

const QUERY = gql`
  query staffsForStaffListWidget(
    $filter: StaffFilterInput
    $sort: [StaffSortInput]
    $page: PageInput
  ) {
    staffs(filter: $filter, sort: $sort, page: $page) {
      objects {
        id
        phone
        user {
          id
          email
          name
        }
        roleCategories
        permittedGroups {
          id
          name
        }
        permittedCompanies {
          id
          name
        }
        ...TrackedFields
        ...CanActivateFields
      }
      ...ListFields
    }
  }
  ${ListWidget.fragments.list}
  ${ListWidget.fragments.tracked}
  ${ListWidget.fragments.canActivate}
`;
