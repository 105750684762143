import type { ComponentPropsWithoutRef, ComponentType, ReactNode } from "react";

import { SaveOutlined } from "@ant-design/icons";
import { Button } from "antd";

import { Prettify } from "ts-essentials";

import { useTranslate } from "@/hooks/i18n";
import { RecordType } from "@/types/primitives";

export type SubmitButtonOptions = {
  disabled?: boolean;
  labelText?: string;
  loadingText?: string;
  /** @default <SaveOutlined /> */
  icon?: ReactNode;
  props?: ComponentPropsWithoutRef<typeof Button>;
};

export type FormActionableProps<Data extends RecordType> = {
  getFormData: () => Data;
};

export type FormActionsOptions<Data extends RecordType> = {
  submitButtonOptions?: SubmitButtonOptions;
  extraActions?: ComponentType<FormActionableProps<Data>>[];
};

type Props<Data extends RecordType> = Prettify<
  // prettier-ignore
  {
    nested: boolean;
    formId: string;
    loading: boolean;
    onSubmit: () => Promise<void>;
  }
  & FormActionsOptions<Data>
  & FormActionableProps<Data>
>;

export const FormActions = <Data extends RecordType>({
  nested,
  formId,
  loading,
  submitButtonOptions,
  extraActions,
  getFormData,
  onSubmit,
}: Props<Data>) => {
  const t = useTranslate();
  const {
    loadingText = t("admin.common.saving"),
    labelText = t("admin.common.save"),
    icon = <SaveOutlined />,
  } = submitButtonOptions ?? {};
  const submitProps = nested
    ? ({ onClick: onSubmit } as const)
    : ({ htmlType: "submit", form: formId } as const);

  return (
    <>
      <Button
        type="primary"
        icon={icon}
        {...submitButtonOptions?.props}
        {...submitProps}
        disabled={submitButtonOptions?.disabled || loading}
      >
        {loading ? loadingText : labelText}
      </Button>
      {extraActions?.map((Action, index) => (
        <Action key={index} getFormData={getFormData} />
      ))}
    </>
  );
};
