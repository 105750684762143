import { gql } from "@apollo/client";

import { ListPage } from "../../components/pages";
import { getValueFromJsonString, toCamelCase } from "../../utils/helpers";

export const AuditList = () => (
  <ListPage
    groupFilterKey="targetGroupId_Overlap"
    resource="audit"
    tabs={[
      {
        path: "",
        labelKey: "admin.page.audit.tabAll",

        tableProps: {
          query: ALL_QUERY,
          fields: [
            {
              name: "id",
            },
            {
              name: "user",
              type: "object",
              resource: "user",
              filterKey: "userId_Overlap",
            },
            {
              name: "group",
              type: "object",
              resource: "group",
              filterKey: "targetGroupId_Overlap",
              getter: (obj) => obj.targetGroup,
            },
            {
              name: "target",
              type: "object",
              resource: ({ obj }) =>
                toCamelCase(obj.target?.type.toLowerCase() ?? "unknown"),
            },
            {
              name: "category",
              type: "choice",
              enumType: "AuditCategory",
              filterKey: "category_Overlap",
            },
            {
              name: "createdAt",
              type: "datetime",
              sortKey: "CREATED_AT",
              filterKey: "createdAt_Range",
            },
          ],
        },
      },
      {
        path: "suspended",
        labelKey: "admin.page.audit.tabSuspended",
        tableProps: {
          query: SUSPENDED_QUERY,
          baseFilter: {
            targetType_Overlap: ["MEMBER"],
            category_Overlap: ["SUSPENDED"],
          },
          hideDetail: true,
          fields: [
            {
              name: "id",
            },
            {
              name: "status",
              type: "boolean",
              labelKey: "admin.page.audit.isSuspended",
              getter: (obj) => Boolean(obj.target?.suspendedAt),
            },
            {
              name: "targetUser",
              type: "object",
              resource: "user",
              filterKey: "targetUserId_Overlap",
              getter: (obj) => obj.targetUser,
            },
            {
              name: "group",
              type: "object",
              resource: "group",
              filterKey: "targetGroupId_Overlap",
              getter: (obj) => obj.targetGroup,
            },
            {
              name: "target",
              type: "object",
              resource: ({ obj }) => toCamelCase(obj.target.type.toLowerCase()),
            },
            {
              name: "email",
              type: "email",
              getter: (obj) => getValueFromJsonString("email", obj.memo),
            },
            {
              name: "phone",
              type: "phone",
              getter: (obj) => getValueFromJsonString("phone", obj.memo),
            },
            {
              name: "suspensionCategory",
              type: "choice",
              enumType: "MemberSuspensionCategory",
              getter: (obj) => getValueFromJsonString("suspensionCategory", obj.memo),
            },
            {
              name: "suspensionReason",
              type: "choice",
              enumType: "MemberSuspensionReason",
              getter: (obj) => getValueFromJsonString("suspensionReason", obj.memo),
            },
            {
              name: "suspensionMemo",
              getter: (obj) => getValueFromJsonString("suspensionMemo", obj.memo),
            },
            {
              name: "suspensionPeriod",
              type: "choice",
              enumType: "MemberSuspensionPeriod",
              getter: (obj) => getValueFromJsonString("suspensionPeriod", obj.memo),
            },
            {
              name: "suspensionPeriodUntil",
              type: "datetime",
              getter: (obj) =>
                getValueFromJsonString("suspensionPeriodUntil", obj.memo),
            },
            {
              name: "suspendedAt",
              type: "datetime",
              getter: (obj) => getValueFromJsonString("suspendedAt", obj.memo),
            },
            {
              name: "user",
              type: "object",
              resource: "user",
              filterKey: "userId_Overlap",
            },
            {
              name: "createdAt",
              type: "datetime",
              sortKey: "CREATED_AT",
              filterKey: "createdAt_Range",
            },
          ],
        },
      },
      {
        path: "hidden",
        labelKey: "admin.page.audit.tabHidden",
        tableProps: {
          query: HIDDEN_QUERY,
          baseFilter: {
            category_Overlap: ["HIDDEN"],
            context_Overlap: ["ADMIN"],
          },
          hideDetail: true,
          fields: [
            {
              name: "id",
            },
            {
              name: "status",
              type: "boolean",
              labelKey: "admin.page.audit.isHidden",
              getter: (obj) => obj.target?.isHidden,
            },
            {
              name: "targetUser",
              type: "object",
              resource: "user",
              filterKey: "targetUserId_Overlap",
              getter: (obj) => obj.targetUser,
            },
            {
              name: "group",
              type: "object",
              resource: "group",
              filterKey: "targetGroupId_Overlap",
              getter: (obj) => obj.targetGroup,
            },
            {
              name: "target",
              type: "object",
              resource: ({ obj }) => toCamelCase(obj.target?.type.toLowerCase()),
            },
            {
              name: "user",
              type: "object",
              resource: "user",
              filterKey: "userId_Overlap",
            },
            {
              name: "createdAt",
            },
          ],
        },
      },
      {
        path: "inactivated",
        labelKey: "admin.page.audit.tabInactivated",
        tableProps: {
          query: INACTIVATED_QUERY,
          baseFilter: {
            category_Overlap: ["INACTIVATED"],
            context_Overlap: ["ADMIN"],
          },
          hideDetail: true,
          fields: [
            {
              name: "id",
            },
            {
              name: "status",
              type: "boolean",
              labelKey: "admin.page.audit.isActive",
              getter: (obj) => !obj.target?.isActive,
            },
            {
              name: "targetUser",
              type: "object",
              resource: "user",
              filterKey: "targetUserId_Overlap",
              getter: (obj) => obj.targetUser,
            },
            {
              name: "group",
              type: "object",
              resource: "group",
              filterKey: "targetGroupId_Overlap",
              getter: (obj) => obj.targetGroup,
            },
            {
              name: "target",
              type: "object",
              resource: ({ obj }) => toCamelCase(obj.target.type.toLowerCase()),
            },
            {
              name: "user",
              type: "object",
              resource: "user",
              filterKey: "userId_Overlap",
            },
            {
              name: "createdAt",
              type: "datetime",
              sortKey: "CREATED_AT",
              filterKey: "createdAt_Range",
            },
          ],
        },
      },
    ]}
  />
);

// ==

const ALL_QUERY = gql`
  query auditsForAuditList(
    $filter: AuditFilterInput
    $sort: [AuditSortInput]
    $page: PageInput
  ) {
    audits(filter: $filter, sort: $sort, page: $page) {
      objects {
        id
        user {
          id
          name
        }
        targetGroup {
          id
          name
        }
        targetUser {
          id
          name
        }
        target {
          id
          type
        }
        category
        memo
        diff
        createdAt
      }
      ...ListFields
    }
  }
  ${ListPage.fragments.list}
`;

const SUSPENDED_QUERY = gql`
  query auditsForAuditSuspendedList(
    $filter: AuditFilterInput
    $sort: [AuditSortInput]
    $page: PageInput
  ) {
    audits(filter: $filter, sort: $sort, page: $page) {
      objects {
        id
        user {
          id
          name
        }
        targetUser {
          id
          name
        }
        targetGroup {
          id
          name
        }
        target {
          id
          type
          ... on Member {
            id
            nickname
            suspendedAt
          }
        }
        category
        memo
        diff
        createdAt
      }
      ...ListFields
    }
  }
  ${ListPage.fragments.list}
`;

const HIDDEN_QUERY = gql`
  query auditsForAuditHiddenList(
    $filter: AuditFilterInput
    $sort: [AuditSortInput]
    $page: PageInput
  ) {
    audits(filter: $filter, sort: $sort, page: $page) {
      objects {
        id
        user {
          id
          name
        }
        targetUser {
          id
          name
        }
        targetGroup {
          id
          name
        }
        target {
          id
          type
          ... on CanHide {
            isHidden
          }
        }
        category
        memo
        diff
        createdAt
      }
      ...ListFields
    }
  }
  ${ListPage.fragments.list}
`;

const INACTIVATED_QUERY = gql`
  query auditsForAuditInactivatedList(
    $filter: AuditFilterInput
    $sort: [AuditSortInput]
    $page: PageInput
  ) {
    audits(filter: $filter, sort: $sort, page: $page) {
      objects {
        id
        user {
          id
          name
        }
        targetUser {
          id
          name
        }
        targetGroup {
          id
          name
        }
        target {
          id
          type
          ... on CanActivate {
            isActive
          }
        }
        category
        memo
        diff
        createdAt
      }
      ...ListFields
    }
  }
  ${ListPage.fragments.list}
`;
