import { gql } from "@apollo/client";

import { FormPage } from "../../components/pages";

export const PrivacyForm = () => (
  <FormPage
    resource="privacy"
    defaultValues={{
      category: "PRIVACY",
    }}
    fields={[
      {
        type: "fieldset",
        fields: [
          {
            name: "revision",
          },
          {
            name: "category",
            type: "choice",
            enumType: "AgreementCategory",
            hidden: true,
          },
          {
            name: "titleContent",
            labelKey: "admin.field.title",
            type: "translatable",
          },
          {
            name: "bodyContent",
            labelKey: "admin.field.body",
            type: "translatable",
            rows: 50,
            html: true,
          },
          {
            name: "appliedOn",
            type: "date",
          },
        ],
      },
      ...FormPage.fields.canActivate,
    ]}
    query={QUERY}
    createMutation={CREATE_MUTATION}
    updateMutation={UPDATE_MUTATION}
  />
);

// ==

const FORM_FIELDS_FRAGMENTS = gql`
  fragment PrivacyFormFields on Agreement {
    id
    revision
    title
    titleContent {
      ...TranslatableContentFields
    }
    body
    bodyContent {
      ...TranslatableContentFields
    }
    appliedOn
  }
  ${FormPage.fragments.translatableContent}
`;

const QUERY = gql`
  query privacyForPrivacyForm($id: ID!) {
    agreement(filter: { id_Overlap: [$id] }) {
      ...PrivacyFormFields
      ...CanActivateFields
    }
  }
  ${FORM_FIELDS_FRAGMENTS}
  ${FormPage.fragments.canActivate}
`;

const CREATE_MUTATION = gql`
  mutation createPrivacyForPrivacyForm($data: AgreementMutationInput!) {
    createAgreement(data: $data) {
      ok
      errors {
        ...MutationErrorFields
      }
      object {
        ...PrivacyFormFields
      }
    }
  }
  ${FormPage.fragments.mutationError}
  ${FORM_FIELDS_FRAGMENTS}
`;

const UPDATE_MUTATION = gql`
  mutation updatePrivacyForPrivacyForm($id: ID!, $data: AgreementMutationInput!) {
    updateAgreement(id: $id, data: $data, partial: true) {
      ok
      errors {
        ...MutationErrorFields
      }
      object {
        ...PrivacyFormFields
      }
    }
  }
  ${FormPage.fragments.mutationError}
  ${FORM_FIELDS_FRAGMENTS}
`;
