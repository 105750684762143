import { gql } from "@apollo/client";

import { SyncPlaylistButton } from "../../components/actions";
import { DetailPage } from "../../components/pages";
import { PlaylistEntryListWidget } from "../../components/widgets";

export const PlaylistDetail = () => (
  <DetailPage
    resource="playlist"
    fields={[
      {
        name: "id",
      },
      {
        name: "group",
        type: "object",
        resource: "group",
      },
      {
        name: "name",
      },
      {
        name: "category",
        type: "choice",
        enumType: "PlaylistCategory",
      },
      {
        name: "youtubePlaylistUrl",
        type: "url",
      },
      {
        name: "imageCount",
        type: "integer",
      },
      {
        name: "videoCount",
        type: "integer",
      },
      ...DetailPage.fields.tracked,
    ]}
    query={QUERY}
    extraActions={(props) => (
      <>
        {props.obj?.category === "YOUTUBE" && (
          <SyncPlaylistButton playlistId={props.obj.id} />
        )}
      </>
    )}
    tabs={[
      {
        path: "",
        labelKey: "admin.resource.clip.plural",
        permission: {
          action: "list",
          targetType: "PLAYLIST_ENTRY",
        },
        children: ({ obj }) => (
          <PlaylistEntryListWidget
            baseFilter={{
              playlist_Overlap: [obj.id],
            }}
            defaultSort={[{ type: "ORDER", direction: "ASC" }]}
            createSearchParams={{ playlist: obj.id }}
            hiddenFields={["playlist"]}
          />
        ),
      },
    ]}
  />
);

// ==

const QUERY = gql`
  query playlistForPlaylistDetail($id: ID!) {
    playlist(filter: { id_Overlap: [$id] }) {
      id
      group {
        id
        name
      }
      name
      category
      youtubePlaylistUrl
      imageCount
      videoCount
      ...TrackedFields
    }
  }
  ${DetailPage.fragments.tracked}
`;
