import { gql } from "@apollo/client";

import { DuplicateGroupMainScreenButton } from "../../components/actions";
import { ListPage, ListPageWithGroup } from "../../components/pages";

export const GroupMainScreenList = () => (
  <ListPageWithGroup
    groupFilterKey="group_Overlap"
    resource="groupMainScreen"
    subtitleKey="admin.page.mainScreen.subtitle"
    fields={[
      {
        name: "id",
      },
      {
        name: "group",
        type: "object",
        resource: "group",
        filterKey: "group_Overlap",
      },
      {
        name: "name",
      },
      {
        name: "gridImage",
        type: "imageMeta",
      },
      {
        name: "status",
        type: "choice",
        enumType: "GroupMainScreenStatus",
        filterKey: "status_Overlap",
      },
      ...ListPage.fields.tracked,
    ]}
    query={QUERY}
    defaultSort={[
      {
        type: "CREATED_AT",
        direction: "DESC",
      },
    ]}
    extraRowActions={(props) => (
      <>
        <DuplicateGroupMainScreenButton
          groupMainScreenId={props.obj.id}
          size="small"
          onlyIcon
        />
        {ListPage.extraRowActions.canActivate(props)}
      </>
    )}
  />
);

// ==

const QUERY = gql`
  query groupMainScreensForgroupMainScreenList(
    $filter: GroupMainScreenFilterInput
    $sort: [GroupMainScreenSortInput]
    $page: PageInput
  ) {
    groupMainScreens(filter: $filter, sort: $sort, page: $page) {
      objects {
        id
        name
        group {
          id
          name
        }
        gridImage {
          url
          thumbnailUrl(width: 200, height: 200)
        }
        status
        ...CanActivateFields
        ...TrackedFields
      }
      ...ListFields
    }
  }
  ${ListPage.fragments.list}
  ${ListPage.fragments.canActivate}
  ${ListPage.fragments.tracked}
`;
