import { gql } from "@apollo/client";

import { ListPage, ListPageWithGroup } from "../../components/pages";

export const OfflineStoreItemList = () => (
  <ListPageWithGroup
    groupFilterKey="offlineStore_Group_Overlap"
    resource="offlineStoreItem"
    subtitleKey="admin.page.offlineStoreItem.subtitle"
    defaultSort={[
      {
        type: "CREATED_AT",
        direction: "DESC",
      },
    ]}
    fields={[
      {
        name: "id",
      },
      {
        name: "offlineStore.group",
        type: "object",
        resource: "group",
        filterKey: "offlineStore_Group_Overlap",
      },
      {
        name: "offlineStore",
        type: "object",
        resource: "offlineStore",
        filterKey: "offlineStore_Overlap",
      },
      {
        name: "name",
        truncate: true,
      },
      {
        name: "variantCount",
        type: "integer",
      },
      ...ListPage.fields.tracked,
    ]}
    query={QUERY}
    extraRowActions={ListPage.extraRowActions.canActivate}
  />
);

// ==

const QUERY = gql`
  query offlineStoreItemForOfflineStoreItemList(
    $filter: OfflineStoreItemFilterInput
    $sort: [OfflineStoreItemSortInput]
    $page: PageInput
  ) {
    offlineStoreItems(filter: $filter, sort: $sort, page: $page) {
      objects {
        id
        offlineStore {
          id
          name
          group {
            id
            name
          }
        }
        name
        variantCount
        ...TrackedFields
        ...CanActivateFields
      }
      ...ListFields
    }
  }
  ${ListPage.fragments.list}
  ${ListPage.fragments.tracked}
  ${ListPage.fragments.canActivate}
`;
