import { useUniversalGroup } from "../../hooks/universal";
import { SelectGroup } from "../selects";
import { ListPage } from "./ListPage";

export const ListPageWithGroup = ({
  groupFilterKey,
  baseFilter = undefined,
  extraActions = undefined,
  ...props
}) => {
  const { group, setGroup, hasMultipleGroups } = useUniversalGroup();

  // ==

  const handleGroupChange = (value) => setGroup(value);

  return (
    <ListPage
      {...(hasMultipleGroups
        ? {
            baseFilter: group
              ? { [groupFilterKey]: [group], ...baseFilter }
              : baseFilter,
            extraActions: (props) => extraActions?.({ ...props, group }),
            outlet: (
              <SelectGroup
                value={group}
                onChange={handleGroupChange}
                style={styles.select}
                disableUniversalGroup
              />
            ),
          }
        : {
            baseFilter,
            extraActions,
          })}
      {...props}
    />
  );
};

//

const styles = {
  select: {
    width: 200,
  },
};
