import { useTranslation } from "react-i18next";

import { gql } from "@apollo/client";

import { AlertOutlined } from "@ant-design/icons";
import { Button } from "antd";

import dayjs from "dayjs";

import { useDrawerState } from "../../hooks/drawer";
import { MEMBER_SUSPENSION_FRAGMENT } from "../../utils/gql/fragments";
import { FormDrawer } from "../drawers";

export const SuspendMemberButton = ({
  memberId,
  onChange,
  size = "middle",
  onlyIcon = false,
}) => {
  const { t } = useTranslation();
  const { doesRender, openDrawer, closeDrawer, drawerProps } = useDrawerState();

  const onSuccess = () => {
    onChange?.();
    closeDrawer();
  };

  return (
    <>
      <Button
        danger
        ghost
        type="primary"
        icon={<AlertOutlined />}
        onClick={openDrawer}
        size={size}
      >
        {onlyIcon ? null : "Suspend"}
      </Button>
      {doesRender && (
        <FormDrawer
          title={t("admin.common.actionTitle", {
            action: t("admin.common.suspend"),
            name: t("admin.resource.member.singular"),
          })}
          resource="member"
          query={QUERY}
          updateMutation={MUTATION}
          fields={[
            {
              name: "suspensionCategory",
              type: "choice",
              enumType: "MemberSuspensionCategory",
              descriptionKey: "admin.form.guide.suspensionCategory",
            },
            {
              name: "suspensionReason",
              type: "choice",
              enumType: "MemberSuspensionReason",
            },
            {
              name: "suspensionPeriod",
              type: "choice",
              enumType: "MemberSuspensionPeriod",
              onChange: ({ value, setValue }) => {
                const now = dayjs();
                switch (value) {
                  case "FOREVER":
                    setValue("suspensionPeriodUntil", null);
                    break;
                  case "ONE_DAY":
                  case "THREE_DAYS":
                  case "ONE_WEEK":
                  case "ONE_MONTH":
                    setValue(
                      "suspensionPeriodUntil",
                      now.add(
                        {
                          ONE_DAY: 1,
                          THREE_DAYS: 3,
                          ONE_WEEK: 7,
                          ONE_MONTH: 30,
                        }[value],
                        "day",
                      ),
                    );
                    break;
                }
              },
            },
            {
              name: "suspensionPeriodUntil",
              type: "datetime",
              showHours: false,
              disabled: ({ data }) => data.suspensionPeriod !== "CUSTOM",
            },
            {
              name: "doesInactivateContents",
              type: "boolean",
            },
            {
              name: "suspensionMemo",
              rows: 3,
              placeholder: "Memo",
            },
          ]}
          id={memberId}
          successMessage={t("admin.message.processSuccess")}
          okButtonText={t("admin.common.suspend")}
          okButtonLoadingText={t("admin.common.suspending")}
          okButtonProps={{
            danger: true,
            icon: <AlertOutlined />,
          }}
          onSuccess={onSuccess}
          drawerProps={drawerProps}
        />
      )}
    </>
  );
};

// ==

const QUERY = gql`
  query memberForSuspendMemberButton($id: ID!) {
    member(filter: { id_Overlap: [$id] }) {
      ...MemberSuspensionFields
    }
  }
  ${MEMBER_SUSPENSION_FRAGMENT}
`;

const MUTATION = gql`
  mutation suspendMemberForSuspendMemberButton(
    $data: SuspendMemberMutationInput!
    $id: ID!
  ) {
    suspendMember(data: $data, id: $id) {
      ok
      errors {
        ...MutationErrorFields
      }
      object {
        ...MemberSuspensionFields
      }
    }
  }
  ${MEMBER_SUSPENSION_FRAGMENT}
  ${FormDrawer.fragments.mutationError}
`;
