import { forwardRef } from "react";

import { gql } from "@apollo/client";

import { Typography } from "antd";

import { ObjectSelect } from "../fields";

export const SelectClip = forwardRef((props, ref) => (
  <ObjectSelect resource="clip" query={QUERY} ref={ref} {...props} />
));

// ==

const QUERY = gql`
  query clipsForSelectClip($filter: ClipFilterInput) {
    clips(filter: $filter, sort: [{ type: RELEVANCE, direction: DESC }]) {
      objects {
        id
        title
      }
    }
  }
`;
