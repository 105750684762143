import { useState } from "react";
import { useTranslation } from "react-i18next";

import { gql, useMutation } from "@apollo/client";

import { PoweroffOutlined } from "@ant-design/icons";
import { Button, Modal, Typography } from "antd";

import { useApp } from "../../hooks/app";
import { MUTATION_ERROR_FRAGMENT } from "../../utils/gql/fragments";
import { capitalize } from "../../utils/helpers";

export const ActivateButton = ({
  targetType,
  targetId,
  value,
  onChange,
  size = "middle",
  onlyIcon = false,
}) => {
  const { t } = useTranslation();

  const variables = {
    type: targetType,
    id: targetId,
    data: {},
  };
  const onCompleted = (response) => {
    const { ok, errors, object } = Object.values(response)[0];
    if (ok) {
      message.success(t("admin.message.processSuccess"));
      onChange?.(object);
      setIsModalOpen(false);
    } else {
      message.error(
        t("admin.message.processFailure", {
          message: errors[0].messages[0],
        }),
      );
    }
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [activate, { loading: loadingForActivate }] = useMutation(ACTIVATE_MUTATION, {
    variables,
    onCompleted,
  });
  const [inactivate, { loading: loadingForInactivate }] = useMutation(
    INACTIVATE_MUTATION,
    { variables, onCompleted },
  );
  const { message } = useApp();

  const loading = loadingForActivate || loadingForInactivate;
  const action = value
    ? t("admin.component.activateButton.inactivate")
    : t("admin.component.activateButton.activate");
  const onClick = () => setIsModalOpen(true);
  const onOk = () => (value ? inactivate() : activate());
  const onCancel = () => setIsModalOpen(false);

  return (
    <>
      <Button
        type={value ? "default" : "primary"}
        ghost
        danger={!!value}
        icon={<PoweroffOutlined />}
        onClick={onClick}
        size={size}
      >
        {onlyIcon ? null : capitalize(action)}
      </Button>
      <Modal
        title={t(`admin.common.confirmation`)}
        open={isModalOpen}
        confirmLoading={loading}
        okText={capitalize(action)}
        okButtonProps={{
          icon: <PoweroffOutlined />,
          danger: !!value,
        }}
        cancelText={t("admin.common.cancel")}
        onOk={onOk}
        onCancel={onCancel}
      >
        <Typography.Text>
          {value
            ? t("admin.component.activateButton.inactivateMessage")
            : t("admin.component.activateButton.activateMessage")}
        </Typography.Text>
      </Modal>
    </>
  );
};

// ==

const ACTIVATE_MUTATION = gql`
  mutation activateForActivateButton(
    $type: ModelDocumentType!
    $id: ID!
    $data: ActivateMutationInput!
  ) {
    activate(type: $type, id: $id, data: $data) {
      ok
      errors {
        ...MutationErrorFields
      }
      object {
        isActive
      }
    }
  }
  ${MUTATION_ERROR_FRAGMENT}
`;

const INACTIVATE_MUTATION = gql`
  mutation inactivateForActivateButton(
    $type: ModelDocumentType!
    $id: ID!
    $data: InactivateMutationInput!
  ) {
    inactivate(type: $type, id: $id, data: $data) {
      ok
      errors {
        ...MutationErrorFields
      }
      object {
        isActive
      }
    }
  }
  ${MUTATION_ERROR_FRAGMENT}
`;
