import { gql } from "@apollo/client";

import { DetailPage } from "../../components/pages";

export const EventV2PenaltyCategoryDetail = () => (
  <DetailPage
    resource="eventV2PenaltyCategory"
    fields={[
      {
        name: "id",
      },
      {
        name: "nameContent",
        labelKey: "admin.field.title",
        type: "translatable",
        render: ({ obj }) => obj?.name,
      },
      ...DetailPage.fields.tracked,
    ]}
    query={QUERY}
  />
);

// ==

const QUERY = gql`
  query eventV2PenaltyCategoryForEventV2PenaltyCategoryDetail($id: ID!) {
    eventV2PenaltyCategory(filter: { id_Overlap: [$id] }) {
      id
      name
      nameContent {
        ...TranslatableContentFields
      }
      ...TrackedFields
    }
  }
  ${DetailPage.fragments.tracked}
  ${DetailPage.fragments.translatableContent}
`;
