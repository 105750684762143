import { gql } from "@apollo/client";

import { ListPage } from "../../components/pages";

export const TranslationVersionList = () => (
  <ListPage
    resource="translationVersion"
    fields={[
      {
        name: "id",
      },
      {
        name: "revision",
      },
      {
        name: "hashedValue",
      },
      ...ListPage.fields.tracked,
    ]}
    query={QUERY}
  />
);

// ==

const QUERY = gql`
  query translationVersionsForTranslationVersionList(
    $filter: TranslationVersionFilterInput
    $sort: [TranslationVersionSortInput]
    $page: PageInput
  ) {
    translationVersions(filter: $filter, sort: $sort, page: $page) {
      objects {
        id
        revision
        hashedValue
        ...TrackedFields
      }
      ...ListFields
    }
  }
  ${ListPage.fragments.list}
  ${ListPage.fragments.tracked}
`;
