import { gql } from "@apollo/client";

import { WithdrawUserButton } from "../../components/actions";
import { ListPage } from "../../components/pages";

export const UserList = () => (
  <ListPage
    resource="user"
    subtitleKey="admin.page.user.subtitle"
    globalSearchKey="compositeAdmin_Search"
    tabs={[
      {
        path: "",
        labelKey: "admin.page.user.tabAll",
        tableProps: {
          query: ALL_QUERY,
          fields: [
            {
              name: "id",
            },
            {
              name: "email",
              type: "email",
              filterKey: "email_Wildcard",
            },
            {
              name: "phone",
              type: "phone",
              filterKey: "searchPhone_Text",
            },
            {
              name: "name",
              filterKey: "searchName_Text",
            },
            {
              name: "birthDate",
              type: "date",
              sortKey: "BIRTH_DATE",
            },
            {
              name: "country",
              type: "object",
              resource: "country",
              filterKey: "country_Overlap",
            },
            {
              name: "isStaff",
              type: "boolean",
              filterKey: "isStaff_Exact",
            },
            {
              name: "isArtist",
              type: "boolean",
              filterKey: "isArtist_Exact",
            },
            {
              name: "memberCount",
              type: "integer",
              filterKey: "memberCount_Range",
              sortKey: "MEMBER_COUNT",
            },
            {
              name: "status",
              type: "choice",
              enumType: "UserStatus",
              filterKey: "status_Overlap",
            },
            {
              name: "lastAuthenticatedAt",
              type: "datetime",
              sortKey: "LAST_AUTHENTICATED_AT",
            },
            {
              name: "createdAt",
              labelKey: "admin.field.joinedAt",
              type: "datetime",
              sortKey: "CREATED_AT",
            },
          ],
          baseFilter: {
            status_Overlap: ["ACTIVE"],
          },
          extraRowActions: ({ obj, refetch, resource }) => (
            <>
              <WithdrawUserButton
                userId={obj?.id}
                onChange={() => refetch()}
                value={obj?.withdrawalCategory === "WITHDRAWN"}
                size="small"
                type="text"
                onlyIcon
              />
              {ListPage.extraRowActions.canActivate({ obj, refetch, resource })}
            </>
          ),
        },
      },
      {
        path: "withdrawn",
        labelKey: "admin.page.user.tabWithdrawn",
        tableProps: {
          query: WITHDRAWN_QUERY,
          fields: [
            {
              name: "id",
            },
            {
              name: "email",
              type: "email",
              filterKey: "email_Exact",
            },
            {
              name: "phone",
              type: "phone",
              filterKey: "phone_Exact",
            },
            {
              name: "name",
              filterKey: "name_Text",
            },
            {
              name: "isStaff",
              type: "boolean",
              filterKey: "isStaff_Exact",
            },
            {
              name: "isArtist",
              type: "boolean",
              filterKey: "isArtist_Exact",
            },
            {
              name: "withdrawalCategory",
              type: "choice",
              enumType: "UserWithdrawalCategory",
              filterKey: "withdrawalCategory_Overlap",
            },
            {
              name: "withdrawalReason",
              type: "choice",
              enumType: "UserWithdrawalReason",
              filterKey: "withdrawalReason_Overlap",
            },
            {
              name: "withdrawalRequestedAt",
              type: "datetime",
            },
            {
              name: "status",
              type: "choice",
              enumType: "UserStatus",
              filterKey: "status_Overlap",
            },
          ],
          hideCreate: true,
          baseFilter: {
            status_Overlap: ["WITHDRAWAL_REQUESTED", "WITHDRAWN"],
          },
        },
      },
      {
        path: "inactivated",
        labelKey: "admin.page.user.tabInactivated",
        tableProps: {
          query: INACTIVATED_QUERY,
          fields: [
            {
              name: "id",
            },
            {
              name: "email",
              type: "email",
              filterKey: "email_Exact",
            },
            {
              name: "phone",
              type: "phone",
              filterKey: "phone_Exact",
            },
            {
              name: "name",
              filterKey: "name_Text",
            },
            {
              name: "isStaff",
              type: "boolean",
              filterKey: "isStaff_Exact",
            },
            {
              name: "isArtist",
              type: "boolean",
              filterKey: "isArtist_Exact",
            },
            {
              name: "activationMemo",
            },
            {
              name: "activationChangedAt",
              type: "datetime",
            },
            {
              name: "activationChangedBy",
              type: "object",
              resource: "user",
            },
          ],
          hideCreate: true,
          baseFilter: {
            status_Overlap: ["INACTIVATED"],
          },
        },
      },
    ]}
  />
);

// ==

const ALL_QUERY = gql`
  query usersForUserListAll(
    $filter: UserFilterInput
    $sort: [UserSortInput]
    $page: PageInput
  ) {
    users(filter: $filter, sort: $sort, page: $page) {
      objects {
        id
        email
        phone
        name
        birthDate
        country {
          id
          name
        }
        isStaff
        isArtist
        memberCount
        status
        lastAuthenticatedAt
        withdrawalCategory
        ...TrackedFields
        ...CanActivateFields
      }
      ...ListFields
    }
  }
  ${ListPage.fragments.list}
  ${ListPage.fragments.tracked}
  ${ListPage.fragments.canActivate}
`;

const WITHDRAWN_QUERY = gql`
  query usersForUserListWithdrawn(
    $filter: UserFilterInput
    $sort: [UserSortInput]
    $page: PageInput
  ) {
    users(filter: $filter, sort: $sort, page: $page) {
      objects {
        id
        email
        phone
        name
        isStaff
        isArtist
        withdrawalCategory
        withdrawalReason
        withdrawalRequestedAt
        status
      }
      ...ListFields
    }
  }
  ${ListPage.fragments.list}
`;

const INACTIVATED_QUERY = gql`
  query usersForUserListInactive(
    $filter: UserFilterInput
    $sort: [UserSortInput]
    $page: PageInput
  ) {
    users(filter: $filter, sort: $sort, page: $page) {
      objects {
        id
        email
        phone
        name
        isStaff
        isArtist
        activationMemo
        activationChangedAt
        activationChangedBy {
          id
          email
          name
        }
        ...CanActivateFields
      }
      ...ListFields
    }
  }
  ${ListPage.fragments.list}
  ${ListPage.fragments.canActivate}
`;
