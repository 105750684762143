import { gql } from "@apollo/client";

import { DetailPage } from "../../components/pages";

export const GroupFeatureFlagDetail = () => (
  <DetailPage
    resource="groupFeatureFlag"
    fields={[
      {
        name: "id",
      },
      {
        name: "group",
        type: "object",
        resource: "group",
      },
      {
        name: "category",
        type: "choice",
        enumType: "GroupFeatureFlagCategory",
      },
      ...DetailPage.fields.canActivate,
      ...DetailPage.fields.tracked,
    ]}
    query={QUERY}
  />
);

// ==

const QUERY = gql`
  query groupFeatureFlagForGroupFeatureFlagDetail($id: ID!) {
    groupFeatureFlag(filter: { id_Overlap: [$id] }) {
      id
      group {
        id
        name
      }
      category
      ...TrackedFields
      ...CanActivateFields
    }
  }
  ${DetailPage.fragments.tracked}
  ${DetailPage.fragments.canActivate}
`;
