import React from "react";

import { gql } from "@apollo/client";

import { ListWidget } from "../list";

export const EventV2WinnerQuestionAnswerListWidget = ({ object, filter, ...props }) => {
  const dynamicFields = (object?.winnerQuestionSet?.questions ?? []).map(
    (question, index) => ({
      name: `winnerQuestionSet.questions[${index}].title`,
      label: question.title || "-",
      type: "text",
      getter: (obj) => {
        return obj.answers[index]?.textInput || "-";
      },
    }),
  );

  const staticFields = [
    {
      name: "order",
      type: "integer",
    },
    {
      name: "state",
      labelKey: "admin.field.applicationState",
      type: "choice",
      enumType: "EventV2WinnerState",
    },
    {
      name: "member",
      type: "object",
      resource: "member",
      filterKey: "member_Overlap",
    },
    {
      name: "application.name",
      labelKey: "admin.field.name",
      getter: (obj) => obj.application.name || obj.member.user.name || "-",
    },
    {
      name: "application.birthDate",
      labelKey: "admin.field.birthDate",
    },
    {
      name: "application.premiumMembershipIdentifier",
      labelKey: "admin.field.membershipIdentifier",
      getter: (obj) => obj.application.premiumMembershipIdentifier || "-",
    },
    {
      name: "application.phone",
      labelKey: "admin.field.phone",
    },
    {
      name: "application.memberPenaltyCount",
      labelKey: "admin.field.penaltyCount",
      type: "integer",
    },
    {
      name: "member.isActive",
      labelKey: "admin.field.memberIsActive",
      type: "boolean",
    },
    {
      name: "application.createdAt",
      labelKey: "admin.field.appliedAt",
      type: "datetime",
    },
  ];

  const fields = [...staticFields, ...dynamicFields];

  return (
    <ListWidget
      resource="eventV2Winner"
      fields={fields}
      query={QUERY}
      filter={filter}
      {...props}
    />
  );
};

// ==

const QUERY = gql`
  query eventV2WinnersForEventV2QuestionAnswerListWidget(
    $filter: EventV2WinnerFilterInput
    $sort: [EventV2WinnerSortInput]
    $page: PageInput
  ) {
    eventV2Winners(filter: $filter, sort: $sort, page: $page) {
      objects {
        id
        member {
          id
          nickname
          penaltyCount
          isActive
          user {
            id
            name
            phone
            email
          }
        }
        event {
          id
          submissionCategory
        }
        application {
          id
          name
          phone
          birthDate
          premiumMembershipIdentifier
          memberPenaltyCount
          createdAt
        }
        answers {
          id
          textInput
          question {
            id
            title
            subtitle
          }
        }
        order
        state
        attendanceState
      }
      ...ListFields
    }
  }
  ${ListWidget.fragments.list}
`;
