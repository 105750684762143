import { FC, ReactNode, useMemo } from "react";
import { Link } from "react-router-dom";

import { OrderedListOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { Button, Space } from "antd";

import { Can } from "@/components/permission";
import { useTranslate } from "@/hooks/i18n";
import { useResource } from "@/hooks/resource";
import { ResourceName } from "@/resources";
import { RecordType } from "@/types/primitives";
import {
  getRelativeUrl,
  mapValues,
  urlQueryParse,
  urlQueryStringify,
} from "@/utils/helpers";

/** use `primary` at main list, `text`(icon) at widget */
export type ResourceButtonType = "primary" | "text";

export type ResourceButtonOption = {
  /** @default "primary" */
  type?: ResourceButtonType;
  /** override "Add" label */
  labelKey?: string;
  /** override resource.{action}Url */
  overrideUrl?: string;
  /** append some query params to url (for form defaultValues) */
  additionalSearchParams?: RecordType;
};

type Props = {
  resource: ResourceName;
  action: "create" | "list";
} & ResourceButtonOption;

export const ResourceButton: FC<Props> = ({
  resource: resourceName,
  action,
  type = "primary",
  labelKey,
  overrideUrl,
  additionalSearchParams,
}) => {
  const t = useTranslate();
  const r = useResource(resourceName);

  const meta = useMemo(() => {
    let url: string;
    let icon: ReactNode;
    let label = labelKey ? t(labelKey) : undefined;
    const { resource, hasForm, hasList } = r;
    const primary = type === "primary";

    switch (action) {
      case "create":
        if (!hasForm) return;
        url = overrideUrl ?? resource.createUrl;
        icon = <PlusCircleOutlined />;
        if (primary) label ??= t("admin.common.add");
        break;
      case "list":
        if (!hasList) return;
        url = overrideUrl ?? resource.listUrl;
        icon = <OrderedListOutlined />;
        if (primary) label ??= t("admin.common.list");
        break;
    }
    if (!url) return;
    if (additionalSearchParams) {
      const currentUrl = getRelativeUrl();
      const search = urlQueryStringify({
        ...urlQueryParse(window.location.search, { ignoreQueryPrefix: true }),
        ...mapValues(additionalSearchParams, (v) => JSON.stringify(v)),
        nextUrl: currentUrl,
      });
      url += `?${search}`;
    }
    return [url, label, icon] as const;
  }, [overrideUrl, additionalSearchParams, action, r, t]);

  if (!meta) return null;
  const [url, label, icon] = meta;

  return (
    <Can action={action} targetType={r.resource.typeName}>
      <Space size="middle">
        <Link to={url}>
          <Button type={type} icon={icon}>
            {label}
          </Button>
        </Link>
      </Space>
    </Can>
  );
};
