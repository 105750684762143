import { gql } from "@apollo/client";

import { FormPage } from "../../components/pages";

export const StaffForm = () => (
  <FormPage
    resource="staff"
    fields={[
      {
        name: "user",
        type: "nested",
        fields: [
          {
            name: "email",
            type: "email",
          },
          {
            name: "name",
          },
          {
            name: "country",
            type: "nestedObject",
            resource: "country",
          },
          {
            name: "serviceLanguage",
            type: "nestedObject",
            resource: "language",
            baseFilter: {
              isActiveForService_Exact: true,
            },
          },
          {
            name: "translationLanguage",
            type: "nestedObject",
            resource: "language",
            baseFilter: {
              isActiveForTranslation_Exact: true,
            },
          },
        ],
      },
      {
        name: "phone",
        type: "phone",
      },
    ]}
    query={QUERY}
    createMutation={CREATE_MUTATION}
    updateMutation={UPDATE_MUTATION}
  />
);

// ==

const FORM_FIELDS_FRAGMENT = gql`
  fragment StaffFormFields on Staff {
    id
    phone
    user {
      id
      email
      name
      country {
        id
      }
      serviceLanguage {
        id
      }
      translationLanguage {
        id
      }
    }
  }
`;

const QUERY = gql`
  query staffForStaffForm($id: ID!) {
    staff(filter: { id_Overlap: [$id] }) {
      ...StaffFormFields
    }
  }
  ${FORM_FIELDS_FRAGMENT}
`;

const CREATE_MUTATION = gql`
  mutation createStaffForStaffForm($data: StaffMutationInput!) {
    createStaff(data: $data) {
      ok
      errors {
        ...MutationErrorFields
      }
      object {
        ...StaffFormFields
      }
    }
  }
  ${FormPage.fragments.mutationError}
  ${FORM_FIELDS_FRAGMENT}
`;

const UPDATE_MUTATION = gql`
  mutation updateStaffForStaffForm($data: StaffMutationInput!, $id: ID!) {
    updateStaff(data: $data, id: $id, partial: true) {
      ok
      errors {
        ...MutationErrorFields
      }
      object {
        ...StaffFormFields
      }
    }
  }
  ${FormPage.fragments.mutationError}
  ${FORM_FIELDS_FRAGMENT}
`;
