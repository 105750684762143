import { useMemo } from "react";

import type { Field } from "@/types/field";
import { RecordType } from "@/types/primitives";

export function useForceFieldset<T extends RecordType>(
  fields: Field<T>[],
  forceFieldset?: boolean,
): Field<T>[] {
  return useMemo(
    () =>
      fields
        ? !forceFieldset || fields.some((field) => field.type === "fieldset")
          ? fields
          : [
              {
                type: "fieldset",
                label: "",
                fields: fields,
              },
            ]
        : [],
    [fields, forceFieldset],
  );
}
