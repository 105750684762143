import { gql } from "@apollo/client";

import { MUTATION_ERROR_FRAGMENT } from "../../utils/gql/fragments";
import { DownloadButton } from "./DownloadButton";

export const DownloadEventV2AttendanceButton = (props) => (
  <DownloadButton mutation={MUTATION} {...props} />
);

// ==

const MUTATION = gql`
  mutation SendEventV2AttendanceCsv($filter: JSONString, $sort: JSONString, $id: ID!) {
    sendEventV2AttendanceCsv(filter: $filter, sort: $sort, id: $id) {
      ok
      errors {
        ...MutationErrorFields
      }
    }
  }
  ${MUTATION_ERROR_FRAGMENT}
`;
