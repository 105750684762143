import { forwardRef, useMemo } from "react";

import { Radio, Space } from "antd";

import { useEnum } from "../../hooks/enum";

export const EnumRadio = forwardRef(
  (
    {
      type,
      filterOptions = (options) => options,
      onChange,
      direction = "vertical",
      size = 4,
      ...props
    },
    ref,
  ) => {
    const { values } = useEnum(type);
    const options = useMemo(
      () =>
        values?.map(({ name, description }) => ({
          value: name,
          label: description,
        })),
      [values],
    );

    return (
      <Radio.Group
        style={styles.container}
        ref={ref}
        onChange={(e) => onChange?.(e.target.value)}
        {...props}
      >
        <Space direction={direction} size={size}>
          {(options ? filterOptions(options) : undefined)?.map(({ value, label }) => (
            <Radio key={value} value={value}>
              {label}
            </Radio>
          ))}
        </Space>
      </Radio.Group>
    );
  },
);

// ==

const styles = {
  container: {
    width: "100%",
  },
};
