import { gql } from "@apollo/client";

import { FormPage } from "../../components/pages";

export const EventPenaltyForm = () => (
  <FormPage
    resource="eventPenalty"
    fields={[
      {
        type: "fieldset",
        labelKey: "admin.fieldset.event",
        fields: [
          {
            name: "event",
            type: "object",
            resource: "event",
            disabled: ({ isUpdate }) => isUpdate,
          },
          {
            name: "member",
            type: "object",
            resource: "member",
            filterKey: "member_Overlap",
            disabled: ({ isUpdate }) => isUpdate,
          },
        ],
      },
      {
        type: "fieldset",
        labelKey: "admin.fieldset.memo",
        fields: [
          {
            name: "memo",
            labelKey: "admin.field.memo",
            rows: 10,
          },
        ],
      },
      ...FormPage.fields.canActivate,
    ]}
    query={QUERY}
    createMutation={CREATE_MUTATION}
    updateMutation={UPDATE_MUTATION}
  />
);

// ==

const FORM_FIELDS_FRAGMENT = gql`
  fragment EventPenaltyFormFields on EventPenalty {
    id
    event {
      id
    }
    member {
      id
    }
    memo
    ...CanActivateFields
  }
  ${FormPage.fragments.canActivate}
`;

const QUERY = gql`
  query eventPenaltyForEventPenaltyForm($id: ID!) {
    eventPenalty(filter: { id_Overlap: [$id] }) {
      ...EventPenaltyFormFields
    }
  }
  ${FORM_FIELDS_FRAGMENT}
`;

const CREATE_MUTATION = gql`
  mutation createEventPenaltyForEventPenaltyForm($data: EventPenaltyMutationInput!) {
    createEventPenalty(data: $data) {
      ok
      errors {
        ...MutationErrorFields
      }
      object {
        ...EventPenaltyFormFields
      }
    }
  }
  ${FormPage.fragments.mutationError}
  ${FORM_FIELDS_FRAGMENT}
`;

const UPDATE_MUTATION = gql`
  mutation updateEventPenaltyForEventPenaltyForm(
    $data: EventPenaltyMutationInput!
    $id: ID!
  ) {
    updateEventPenalty(data: $data, id: $id, partial: true) {
      ok
      errors {
        ...MutationErrorFields
      }
      object {
        ...EventPenaltyFormFields
      }
    }
  }
  ${FormPage.fragments.mutationError}
  ${FORM_FIELDS_FRAGMENT}
`;
