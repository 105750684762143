import { useTranslation } from "react-i18next";

import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { Button, Space, Typography } from "antd";

import { formatNumber } from "../../utils/helpers";

export const Paginator = ({ page, count, onPrev, onNext }) => {
  const { t } = useTranslation();
  return (
    <Space size="middle" align="center">
      <span>
        <Typography.Text>
          {t(
            count.relation === "GREATER_THAN_OR_EQUAL"
              ? "admin.component.paginator.resultCountGreaterThanOrEqual"
              : "admin.component.paginator.resultCountExact",
            {
              start: formatNumber(page.startIndex + 1),
              end: formatNumber(Math.min(page.endIndex, count.value)),
              total: formatNumber(count.value),
            },
          )}
        </Typography.Text>
      </span>
      <Space>
        <Button
          type="text"
          icon={<LeftOutlined />}
          disabled={!page.hasPrevious}
          onClick={onPrev}
        />
        <Button
          type="text"
          icon={<RightOutlined />}
          disabled={!page.hasNext}
          onClick={onNext}
        />
      </Space>
    </Space>
  );
};
