import { createContext } from "react";

import { gql, useApolloClient, useQuery } from "@apollo/client";

import { v4 as uuidv4 } from "uuid";

import config from "@/config";
import { useLocalStorage } from "@/hooks/localStorage";
import { clearAllAbilities } from "@/hooks/permission";
import type { User } from "@/types/user";
import { AUTHORIZATION_FRAGMENT } from "@/utils/gql/fragments";

export type AuthenticationContext = {
  token: string | null;
  setToken: (token: string) => void;
  logout: () => Promise<void>;
  guid: string;
  // ==
  isAuthenticated: boolean;
  user: User;
};
export const AuthenticationContext = createContext({} as AuthenticationContext);

export const AuthenticationProvider = ({ children }) => {
  const [token, setStorageToken, removeStorageToken] = useLocalStorage(config.tokenKey);
  const [guid] = useLocalStorage(config.guidKey, () => uuidv4());
  const { data, refetch } = useQuery<{
    authorization: { isAuthenticated: boolean; user: User };
  }>(QUERY);
  const client = useApolloClient();

  const setToken = (token: string) => {
    localStorage.removeItem("group"); // should be revalidated on useUniversalGroup
    setStorageToken(token);
  };
  const logout = async () => {
    removeStorageToken();
    clearAllAbilities();
    await client.clearStore();
    await refetch();
  };

  if (!data) return null;
  const { isAuthenticated, user } = data.authorization;

  return (
    <AuthenticationContext.Provider
      value={{
        token,
        setToken,
        logout,
        guid,
        // ==
        isAuthenticated,
        user,
      }}
    >
      {children}
    </AuthenticationContext.Provider>
  );
};

// ==

const QUERY = gql`
  query authorizationForAuthContext {
    authorization {
      ...AuthorizationFields
    }
  }
  ${AUTHORIZATION_FRAGMENT}
`;
