import { forwardRef, useEffect, useState } from "react";

import { Checkbox, InputNumber, Space } from "antd";

import { deepEqual } from "../../utils/helpers";

export const NumberWithCheckboxInput = forwardRef(
  ({ enumName, checkboxText, helpKey, defaultValue, onChange, ...props }, ref) => {
    const [checkedValue, setCheckedValue] = useState(defaultValue || false);
    const [inputValue, setInputValue] = useState(defaultValue?.count || null);

    useEffect(() => {
      if (!checkedValue) {
        setInputValue(null);
      }
      const newValue = { category: enumName, count: inputValue };
      if (!deepEqual(newValue, defaultValue)) {
        onChange?.({ ...newValue });
      }
    }, [defaultValue, checkedValue, inputValue]);

    return (
      <Space>
        <Checkbox
          checked={checkedValue}
          onChange={() => {
            setCheckedValue((prev) => !prev);
          }}
        >
          {checkboxText}
        </Checkbox>
        <InputNumber
          defaultValue={inputValue}
          onChange={(count) => {
            setInputValue(count);
          }}
          disabled={!checkedValue}
          ref={ref}
          {...props}
        ></InputNumber>
        <span style={{ color: "white" }}>{helpKey}</span>
      </Space>
    );
  },
);
