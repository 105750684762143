import { useTranslation } from "react-i18next";

import { gql } from "@apollo/client";

import { useDrawerState } from "../../hooks/drawer";
import { SCHEDULE_FRAGMENT } from "../../utils/gql/fragments";
import { FormDrawer } from "../drawers";

export const EditScheduleAction = ({
  scheduleId,
  onChange,
  disabled = false,
  children,
  ...props
}) => {
  const { t } = useTranslation();
  const { doesRender, openDrawer, closeDrawer, drawerProps } = useDrawerState();

  // ==

  const onSuccess = (value) => {
    onChange?.(value);
    closeDrawer();
  };

  return (
    <>
      {children?.({ openDrawer, disabled })}
      {doesRender && (
        <FormDrawer
          title={t("admin.common.actionTitle", {
            action: scheduleId ? t("admin.common.edit") : t("admin.common.add"),
            name: t("admin.resource.schedule.singular"),
          })}
          resource="schedule"
          fields={[
            {
              name: "group",
              type: "object",
              resource: "group",
              onChange: ({ resetField }) => resetField("artists"),
              disabled: ({ isUpdate }) => isUpdate,
            },
            {
              name: "artists",
              type: "object",
              resource: "artist",
              multiple: true,
              disabled: ({ data }) => !data.group,
              baseFilter: ({ data }) => ({ group_Overlap: [data.group] }),
            },
            {
              name: "category",
              type: "choice",
              enumType: "ScheduleCategory",
            },
            {
              name: "scheduledAt",
              type: "datetime",
              showAllDay: true,
            },
            {
              name: "titleContent",
              labelKey: "admin.field.title",
              type: "translatable",
            },
            {
              name: "bodyContent",
              labelKey: "admin.field.body",
              type: "translatable",
              rows: 10,
            },
            {
              name: "location",
            },
            ...FormDrawer.fields.canActivate,
            {
              name: "doesSendPushOnSchedule",
              label: "",
              type: "boolean",
              control: "checkbox",
              controlLabel: t("admin.form.label.doesSendPushOnSchedule"),
            },
          ]}
          id={scheduleId}
          query={QUERY}
          createMutation={CREATE_MUTATION}
          updateMutation={UPDATE_MUTATION}
          onSuccess={onSuccess}
          drawerProps={drawerProps}
          {...props}
        />
      )}
    </>
  );
};

// ==

const QUERY = gql`
  query scheduleForEditScheduleAction($id: ID!) {
    schedule(filter: { id_Overlap: [$id] }) {
      id
      group {
        id
      }
      artists {
        id
      }
      category
      scheduledAt
      title
      titleContent {
        ...TranslatableContentFields
      }
      body
      bodyContent {
        ...TranslatableContentFields
      }
      location
      doesSendPushOnSchedule
      ...CanActivateFields
    }
  }
  ${FormDrawer.fragments.canActivate}
  ${FormDrawer.fragments.translatableContent}
`;

const CREATE_MUTATION = gql`
  mutation createScheduleForEditScheduleAction($data: ScheduleMutationInput!) {
    createSchedule(data: $data) {
      ok
      errors {
        ...MutationErrorFields
      }
      object {
        ...ScheduleFields
      }
    }
  }
  ${FormDrawer.fragments.mutationError}
  ${SCHEDULE_FRAGMENT}
`;

const UPDATE_MUTATION = gql`
  mutation updateScheduleForEditScheduleAction(
    $data: ScheduleMutationInput!
    $id: ID!
  ) {
    updateSchedule(data: $data, id: $id, partial: true) {
      ok
      errors {
        ...MutationErrorFields
      }
      object {
        ...ScheduleFields
      }
    }
  }
  ${FormDrawer.fragments.mutationError}
  ${SCHEDULE_FRAGMENT}
`;
