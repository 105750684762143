import React, { useMemo } from "react";

import { DeleteOutlined } from "@ant-design/icons";
import { Button, Space, Table } from "antd";

import { DndContext } from "@dnd-kit/core";
import {
  SortableContext,
  arrayMove,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

import { useFields } from "../../hooks/fields";
import { resolveCallable } from "../../utils/helpers";
import { DragHandle } from "./DragHandle";

export const SortableTable = ({
  resource: resourceName,
  fields: originalFields,
  items = [],
  onChange,
  rowKey = "id",
  disabled = false,
  extraActions = undefined,
  hideDelete = false,
  ...props
}) => {
  const { fields } = useFields({
    resource: resourceName,
    fields: originalFields,
  });
  const columns = useMemo(
    () =>
      fields
        ? fields.map((field) => ({
            dataIndex: field.name,
            title: field.label,
            render: field.render,
          }))
        : null,
    [fields],
  );
  const antdColumns = useMemo(
    () =>
      columns
        ? disabled
          ? columns
          : [
              { key: "handle", width: "1%" },
              ...columns,
              {
                key: "actions",
                width: "1%",
                render: (_, row) => (
                  <Space size="small">
                    {extraActions?.({ obj: row })}
                    {!resolveCallable(hideDelete, row) && (
                      <Button
                        danger
                        icon={<DeleteOutlined />}
                        size="small"
                        onClick={() => onChange?.(items.filter((i) => i !== row))}
                      />
                    )}
                  </Space>
                ),
              },
            ]
        : null,
    [columns, disabled, items, onChange, extraActions, hideDelete],
  );

  if (!antdColumns) {
    return null;
  }

  // ==

  const onDragEnd = ({ active, over }) => {
    if (active.id !== over?.id) {
      const activeIndex = items.findIndex((i) => i[rowKey] === active.id);
      const overIndex = items.findIndex((i) => i[rowKey] === over?.id);
      return onChange?.(arrayMove(items, activeIndex, overIndex));
    }
  };

  return (
    <DndContext onDragEnd={onDragEnd}>
      <SortableContext
        items={items.map((i) => i[rowKey])}
        strategy={verticalListSortingStrategy}
      >
        <Table
          components={{
            body: {
              row: SortableRow,
            },
          }}
          rowKey={rowKey}
          columns={antdColumns}
          dataSource={items}
          {...props}
        />
      </SortableContext>
    </DndContext>
  );
};

// ==

const SortableRow = ({ children, ...props }) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: props["data-row-key"],
  });
  const isEmptyRow = !Array.isArray(children);
  const style = {
    ...props.style,
    transform: CSS.Transform.toString(
      transform && {
        ...transform,
        scaleY: 1,
      },
    ),
    transition,
    ...(isDragging && !isEmptyRow
      ? {
          position: "relative",
          zIndex: 999,
        }
      : {}),
  };

  return (
    <tr {...props} ref={setNodeRef} style={style} {...attributes}>
      {React.Children.map(children, (child) => {
        if (child.key === "handle") {
          return React.cloneElement(child, {
            children: (
              <DragHandle
                ref={setActivatorNodeRef}
                style={{
                  touchAction: "none",
                  cursor: "move",
                }}
                {...listeners}
              />
            ),
          });
        }
        return child;
      })}
    </tr>
  );
};
