import { gql } from "@apollo/client";

import { UploadPreviousFansClipsButton } from "../../components/actions";
import { ListPage, ListPageWithGroup } from "../../components/pages";

export const ClipList = () => (
  <ListPageWithGroup
    groupFilterKey="group_Overlap"
    resource="clip"
    subtitleKey="admin.page.clip.subtitle"
    fields={[
      {
        name: "id",
      },
      {
        name: "group",
        type: "object",
        resource: "group",
        filterKey: "group_Overlap",
      },
      {
        name: "thumbnail",
        type: "imageMeta",
      },
      {
        name: "title",
      },
      {
        name: "category",
        type: "choice",
        enumType: "ClipCategory",
        filterKey: "category_Overlap",
      },
      {
        name: "isPremiumOnly",
        labelKey: "admin.field.premiumOnly",
        type: "boolean",
      },
      {
        name: "commentCount",
        type: "integer",
        filterKey: "commentCount_Range",
        sortKey: "COMMENT_COUNT",
      },
      {
        name: "likeCount",
        type: "integer",
        filterKey: "likeCount_Range",
        sortKey: "LIKE_COUNT",
      },
      {
        name: "status",
        type: "choice",
        enumType: "ClipStatus",
        filterKey: "status_Overlap",
      },
      {
        name: "activateAt",
        type: "datetime",
      },
      ...ListPage.fields.tracked,
    ]}
    query={QUERY}
    extraRowActions={ListPage.extraRowActions.canActivate}
    extraActions={() => <UploadPreviousFansClipsButton type="text" onlyIcon />}
  />
);

// ==

const QUERY = gql`
  query clipsForClipList(
    $filter: ClipFilterInput
    $sort: [ClipSortInput]
    $page: PageInput
  ) {
    clips(filter: $filter, sort: $sort, page: $page) {
      objects {
        id
        group {
          id
          name
        }
        thumbnail {
          ...ListImageFields
        }
        title
        category
        commentCount
        likeCount
        isPremiumOnly
        status
        ...TrackedFields
        ...CanActivateFields
      }
      ...ListFields
    }
  }
  ${ListPage.fragments.list}
  ${ListPage.fragments.tracked}
  ${ListPage.fragments.canActivate}
  ${ListPage.fragments.image}
`;
