import { gql, useMutation } from "@apollo/client";

import { Button, Tooltip } from "antd";

import dayjs from "dayjs";
import { t } from "i18next";

import { Can } from "../../components/permission";
import { useApp } from "../../hooks/app";
import { PREMIUM_MEMBERSHIP_FRAGMENT } from "../../utils/gql/fragments";
import { EventSurveyViewButton } from "../actions";
import { DownloadEventApplicationButton } from "../actions/DownloadEventApplicationButton";
import { ListWidget } from "../list";

export const EventApplicationListWidget = ({ event, ...props }) => {
  const { message } = useApp();
  const [mutate, { data, loading, error }] = useMutation(MUTATION);

  const eventId = parseInt(event?.id);

  const parsed = data?.initializeEventWinner;
  if (parsed) {
    if (parsed?.ok) {
      message.success("당첨자 선정 요청이 처리되었습니다.");
    } else {
      const errMsg = parsed?.errors[0].messages.join("\n");
      message.error(errMsg);
    }
  }
  if (error) {
    console.error(error);
    message.error(JSON.stringify(error));
  }

  return (
    <ListWidget
      resource="eventApplication"
      fields={[
        {
          name: "member",
          type: "object",
          resource: "member",
          filterKey: "member_Overlap",
        },
        {
          name: "premiumMembership.name",
          labelKey: "admin.field.name",
          filterKey: "premiumMembership_Name_Text",
          getter: (obj) => obj.premiumMembership?.name || obj.member.user.name || "-",
        },
        {
          name: "premiumMembership.identifier",
          labelKey: "admin.field.membershipIdentifier",
          filterKey: "premiumMembership_Identifier_Text",
        },
        {
          name: "premiumMembership.birthDate",
          type: "date",
          labelKey: "admin.field.birthDate",
        },
        {
          name: "member.user.phone",
          type: "phone",
          labelKey: "admin.field.phone",
          filterKey: "member_User_Phone_Text",
        },
        {
          name: "member.user.email",
          labelKey: "admin.field.email",
          filterKey: "member_User_Email_Text",
        },
        {
          type: "boolean",
          name: "isActive",
          labelKey: "admin.field.isCancelApplication",
          filterKey: "isActive_Exact",
        },
        {
          type: "choice",
          name: "member.status",
          labelKey: "admin.field.memberStatus",
          enumType: "MemberStatus",
        },
        {
          name: "isExcepted",
          type: "boolean",
          labelKey: "admin.field.isExcepted",
        },
        {
          labelKey: "admin.field.eventAppliedAt",
          type: "datetime",
          name: "appliedAt",
        },
      ]}
      size={30}
      query={QUERY}
      extraRowActions={({ obj }) => {
        if (obj.event.submitCategory === "SURVEY") {
          return (
            <EventSurveyViewButton eventId={obj.event.id} memberId={obj.member.id} />
          );
        }
      }}
      extraActions={({ filter, sort, result }) => {
        const isEndedEvent = dayjs(event.applyEndedAt) < dayjs();
        return (
          <div style={styles.extraActions}>
            <Can action="download" targetType="EVENT_APPLICATION">
              <DownloadEventApplicationButton
                style={styles.customActionButtons}
                count={result?.count}
                filter={filter}
                sort={sort}
                type="text"
                onlyIcon
              />
            </Can>
            <Tooltip
              title={
                isEndedEvent ? "" : t("admin.form.guide.notYetEndedEventApplication")
              }
              placement="rightBottom"
              trigger="hover"
              defaultOpen
            >
              <Button
                style={styles.customActionButtons}
                loading={loading}
                onClick={() => {
                  mutate({ variables: { eventId: eventId } });
                }}
                disabled={result?.objects.length === 0 || !isEndedEvent}
              >
                {t("admin.common.draw")}
              </Button>
            </Tooltip>
          </div>
        );
      }}
      {...props}
    />
  );
};

// ==

const QUERY = gql`
  query EventApplicationListWidget(
    $filter: EventApplicationFilterInput
    $sort: [EventApplicationSortInput]
    $page: PageInput
  ) {
    eventApplications(filter: $filter, sort: $sort, page: $page) {
      objects {
        id
        event {
          id
          group {
            id
          }
          submitCategory
        }
        member {
          id
          nickname
          user {
            id
            name
            phone
            email
            isActive
          }
          isActive
          status
        }
        premiumMembership {
          ...PremiumMembershipFields
        }
        isExcepted
        appliedAt
        activationMemo
        ...TrackedFields
        ...CanActivateFields
      }
      ...ListFields
    }
  }
  ${ListWidget.fragments.list}
  ${ListWidget.fragments.tracked}
  ${ListWidget.fragments.canActivate}
  ${PREMIUM_MEMBERSHIP_FRAGMENT}
`;

const MUTATION = gql`
  mutation InitializeEventWinner($eventId: ID!) {
    initializeEventWinner(eventId: $eventId) {
      ok
      winnerIds
      errors {
        code
        title
        messages
      }
    }
  }
`;

// ==

const styles = {
  extraActions: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  customActionButtons: {
    marginRight: "10px",
  },
};
