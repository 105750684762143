import { useState } from "react";
import { useTranslation } from "react-i18next";

import { gql, useMutation } from "@apollo/client";

import { GlobalOutlined } from "@ant-design/icons";
import { Button, Space, Typography } from "antd";

const { Text } = Typography;

export const TranslatableText = ({ value: originalValue, ...props }) => {
  const { t } = useTranslation();
  const [value, setValue] = useState(originalValue);
  const [translate, { loading }] = useMutation(TRANSLATION_MUTATION);

  // ==

  const onClick = () =>
    translate({
      variables: {
        text: value,
      },
    }).then((response) => {
      setValue(response.data.translate.translatedText);
    });

  const translated = value !== originalValue;

  return (
    <Space direction="vertical">
      <Text {...props}>{value}</Text>
      <Button size="small" disabled={loading || translated} onClick={onClick}>
        <GlobalOutlined />{" "}
        {translated
          ? t("admin.component.translatableText.translated")
          : t("admin.common.translate")}
      </Button>
    </Space>
  );
};

const TRANSLATION_MUTATION = gql`
  mutation translateForTranslatableText($text: String!) {
    translate(text: $text) {
      ok
      translatedText
    }
  }
`;
