import { gql } from "@apollo/client";

import { ActivateButton } from "../../components/actions";
import { DetailPage } from "../../components/pages";
import { Can } from "../../components/permission";
import {
  OfflineStoreDescriptionWidget,
  OfflineStoreItemListWidget,
  OfflineStoreNoticeListWidget,
} from "../../components/widgets";

export const OfflineStoreDetail = () => (
  <DetailPage
    resource="offlineStore"
    fields={[
      {
        name: "id",
      },
      {
        name: "group",
        type: "object",
        resource: "group",
      },
      {
        name: "nameContent",
        labelKey: "admin.field.name",
        type: "translatable",
      },
      {
        name: "startDate",
        type: "startDate",
      },
      {
        name: "endDate",
        type: "endDate",
      },
      {
        name: "operatingSchedules",
        type: "operatingSchedules",
      },
      {
        name: "location",
      },
      {
        name: "googleMapUrl",
        type: "googleMapUrl",
      },
      {
        name: "bannerImage",
        type: "imageMeta",
        spaceDirection: "horizontal",
      },
      ...DetailPage.fields.canActivate,
      {
        name: "activateAt",
        type: "datetime",
      },
      ...DetailPage.fields.tracked,
    ]}
    query={QUERY}
    extraActions={({ obj, refetch, resource }) => {
      return (
        <>
          <Can
            action={obj.isActive ? "inactivate" : "activate"}
            targetType={resource.typeName}
            targetId={obj.id}
          >
            <ActivateButton
              targetType={resource.typeName}
              targetId={obj.id}
              value={obj.isActive}
              onChange={() => refetch()}
            ></ActivateButton>
          </Can>
        </>
      );
    }}
    tabs={[
      {
        path: "",
        labelKey: "admin.resource.offlineStoreItem.plural",
        permission: {
          action: "list",
          targetType: "OFFLINE_STORE_ITEM",
        },
        children: ({ obj }) => (
          <OfflineStoreItemListWidget
            baseFilter={{
              offlineStore_Overlap: [obj.id],
            }}
            createSearchParams={{ offlineStore: obj.id }}
            hiddenFields={["offlineStore"]}
          />
        ),
      },
      {
        path: "notice",
        labelKey: "admin.resource.offlineStoreNotice.plural",
        permission: {
          action: "list",
          targetType: "OFFLINE_STORE_NOTICE",
        },
        children: ({ obj }) => (
          <OfflineStoreNoticeListWidget
            baseFilter={{
              offlineStore_Overlap: [obj.id],
            }}
            createSearchParams={{ offlineStore: obj.id }}
            hiddenFields={["offlineStore"]}
          />
        ),
      },
      {
        path: "description",
        labelKey: "admin.resource.offlineStore.plural",
        children: ({ obj }) => (
          <OfflineStoreDescriptionWidget
            createSearchParams={{ offlineStore: obj.id }}
          />
        ),
      },
    ]}
  />
);

// ==

const QUERY = gql`
  query offlineStoreForOfflineStoreDetail($id: ID!) {
    offlineStore(filter: { id_Overlap: [$id] }) {
      id
      group {
        id
        name
        code
      }
      name
      nameContent {
        ...TranslatableContentFields
      }
      description
      descriptionContent {
        ...TranslatableContentFields
      }
      bannerImage {
        ...DetailImageFields
      }
      startDate
      endDate
      operatingHours {
        date
        openTime
        closeTime
      }
      operatingSchedules {
        scheduleCategory
        date
        openTime
        closeTime
      }
      location
      googleMapUrl
      ...TrackedFields
      ...CanActivateFields
    }
  }
  ${DetailPage.fragments.canActivate}
  ${DetailPage.fragments.image}
  ${DetailPage.fragments.tracked}
  ${DetailPage.fragments.translatableContent}
`;
