import { forwardRef } from "react";

import { gql } from "@apollo/client";

import { ObjectSelect } from "../fields";

export const SelectNotice = forwardRef((props, ref) => (
  <ObjectSelect resource="notice" query={QUERY} ref={ref} {...props} />
));

// ==

const QUERY = gql`
  query noticesForSelectNotice($filter: NoticeFilterInput) {
    notices(filter: $filter, sort: [{ type: RELEVANCE, direction: DESC }]) {
      objects {
        id
        title
      }
    }
  }
`;
