import { forwardRef } from "react";

import { gql } from "@apollo/client";

import { Typography } from "antd";

import { ObjectSelect } from "../fields";

export const SelectStaff = forwardRef((props, ref) => {
  const { baseFilter, ...restProps } = props;
  return (
    <ObjectSelect
      resource="staff"
      query={QUERY}
      optionRender={(staff) => (
        <>
          {staff.user.email}
          <Typography.Text type="secondary" style={styles.secondary}>
            {staff.user.name}
          </Typography.Text>
        </>
      )}
      ref={ref}
      baseFilter={
        baseFilter
        // baseFilter || {
        //   roleCategories_DoesNotOverlap: [
        //     "TRANSLATOR",
        //     "TRANSLATION_MANAGER",
        //     "TRANSLATION_APPROVER",
        //     "TRANSLATION_REQUESTOR",
        //   ],
        // }
      }
      {...restProps}
    />
  );
});

// ==

const QUERY = gql`
  query staffsForSelectStaff($filter: StaffFilterInput) {
    staffs(filter: $filter, sort: [{ type: RELEVANCE, direction: DESC }]) {
      objects {
        id
        user {
          id
          email
          name
        }
      }
    }
  }
`;

// ==

const styles = {
  secondary: {
    marginLeft: 8,
    fontSize: "0.8em",
  },
};
