import { gql } from "@apollo/client";

import { DetailPage } from "../../components/pages";

export const PremiumMembershipDetail = () => (
  <DetailPage
    resource="premiumMembership"
    fields={[
      {
        name: "id",
      },
      {
        name: "premium.group",
        labelKey: "admin.resource.group.singular",
        type: "object",
        resource: "group",
      },
      {
        name: "premium",
        type: "object",
        resource: "premium",
      },
      {
        name: "member",
        type: "object",
        resource: "member",
      },
      {
        name: "identifier",
      },
      ...DetailPage.fields.tracked,
    ]}
    query={QUERY}
    extraActions={DetailPage.extraActions.delete}
  />
);

// ==

const QUERY = gql`
  query premiumMembershipForPremiumMembershipDetail($id: ID!) {
    premiumMembership(filter: { id_Overlap: [$id] }) {
      id
      premium {
        id
        name
        group {
          id
          name
        }
      }
      member {
        id
        nickname
      }
      identifier
      ...TrackedFields
    }
  }
  ${DetailPage.fragments.tracked}
`;
