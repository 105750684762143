import { gql, useMutation } from "@apollo/client";

import { Button } from "antd";

import { t } from "i18next";

import { useApp } from "../../hooks/app";

export const EventAttendanceButton = ({
  eventId,
  attendanceType,
  attendanceIds,
  attendanceCategory,
  setSelectedRowKeys,
  buttonText,
  refetch,
}) => {
  const [attend, { loading, error }] = useMutation(MUTATION);
  const { message } = useApp();

  const mutErros = [error];

  mutErros.forEach((err) => {
    if (err) {
      console.error(err);
      message.error(JSON.stringify(err));
    }
  });

  return (
    <Button
      style={styles.customActionButtons}
      onClick={async () => {
        const refetchData = await refetch();
        const result = refetchData?.data?.eventAttendances;

        const confirmMsgMapper = {
          ATTEND: "이미 출석한 회원이 선택되어 있습니다. 출석 취소하시겠습니까?",
          ENTER: "이미 입장한 회원이 선택되어 있습니다. 입장 취소하시겠습니까?",
        };

        let hasChecked = false;
        if (attendanceType === "ATTEND") {
          hasChecked = result?.objects.some((item) => {
            return (
              attendanceIds.includes(parseInt(item.id)) && item.isAttended === true
            );
          });
        } else if (attendanceType === "ENTER") {
          hasChecked = result?.objects.some((item) => {
            return attendanceIds.includes(parseInt(item.id)) && item.isEntered === true;
          });
        }

        if (hasChecked) {
          const confirmMsg =
            confirmMsgMapper[attendanceType] ||
            "취소 처리 대상자가 선택되어 있습니다. 취소하시겠습니까?";
          if (!confirm(confirmMsg)) {
            return;
          }
        }

        const data = await attend({
          variables: {
            eventId: eventId,
            attendanceCategory: attendanceCategory,
            attendanceIds: attendanceIds,
          },
        });
        const parsed = data?.data?.attendEvent;
        if (parsed) {
          if (parsed?.ok) {
            message.success(t("admin.message.processSuccess"));
          } else {
            const errMsg = parsed?.errors[0].messages.join("\n");
            message.error(errMsg);
          }
        }

        setTimeout(async () => {
          await refetch();
        }, 400);
        setSelectedRowKeys([]);
      }}
      loading={loading}
      disabled={attendanceIds.length === 0}
    >
      {buttonText}
    </Button>
  );
};

// ==

const MUTATION = gql`
  mutation AttendEvent(
    $eventId: ID!
    $attendanceCategory: EventAttendanceCategory!
    $attendanceIds: [Int!]!
  ) {
    attendEvent(
      eventId: $eventId
      attendanceCategory: $attendanceCategory
      attendanceIds: $attendanceIds
    ) {
      ok
      count
      errors {
        code
        title
        messages
      }
    }
  }
`;

const styles = {
  customActionButtons: {
    marginRight: "10px",
  },
};
