import { Link } from "react-router-dom";

import { Space, Typography, theme } from "antd";

import Logo from "../../assets/app-icon.png";
import config from "../../config";

const { useToken } = theme;

export const Title = ({ collapsed }) => {
  const { token } = useToken();

  return (
    <Link to="/">
      <Space align="center">
        <div
          style={{
            ...styles.icon,
            color: token.colorPrimary,
          }}
        >
          {defaultIcon}
        </div>

        {!collapsed && (
          <Typography.Title style={styles.title}>{config.layoutTitle}</Typography.Title>
        )}
      </Space>
    </Link>
  );
};

// ==

const styles = {
  icon: {
    width: 24,
    height: 24,
  },
  title: {
    fontSize: "inherit",
    margin: 0,
    lineHeight: 0,
    fontWeight: "900",
  },
};

//

const defaultIcon = (
  <img src={Logo} alt="Logo" style={{ width: "100%", height: "100%" }} />
);
