import React, { forwardRef, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Col, Input, Row, Space, Switch } from "antd";

import { deepEqual } from "../../utils/helpers";
import { EnumSelect, EventV2SurveyQuestionOptionEditor } from "../fields";

export const EventV2SurveyQuestionEditor = forwardRef(
  ({ value: { surveyQuestions }, onChange, disabled, ...props }, ref) => {
    const [inputs, setInputs] = useState(surveyQuestions || []);
    const defaultInputs = {
      category: undefined,
      title: "",
      subtitle: "",
      options: [],
      isRequired: false,
    };

    useEffect(() => {
      if (inputs.length === 0) {
        return;
      }
      if (!deepEqual(inputs, surveyQuestions)) {
        onChange?.({ surveyQuestions: inputs });
      }
    }, [inputs, onChange]);

    const { t } = useTranslation();

    const handleInputChange = (index, key, e) => {
      const newInputs = [...inputs];
      if (["title", "subtitle"].includes(key)) {
        newInputs[index][key] = e.target.value;
      } else {
        newInputs[index][key] = e;
      }
      setInputs(newInputs);
      onChange(newInputs);
    };

    const handleRemove = (index) => {
      const newInputs = [...inputs];
      newInputs.splice(index, 1);
      setInputs(newInputs);
      onChange(newInputs);
    };

    const handleAdd = () => {
      setInputs([...inputs, defaultInputs]);
    };

    return (
      <Col span={20} style={styles.container}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            paddingBottom: "8px",
          }}
        >
          {t("admin.form.guide.surveyQuestions")}
        </div>
        <Space direction="vertical" size="middle" style={{ width: "100%" }}>
          {inputs.map((input, index) => (
            <Row key={index} gutter={[16, 16]} style={{ width: "100%" }}>
              <Col span={6}>
                <EnumSelect
                  disabled={disabled}
                  type="EventV2SurveyQuestionCategory"
                  placeholder={"-- " + t("admin.form.guide.surveyQuestionCategory")}
                  value={input.category}
                  onChange={(e) => handleInputChange(index, "category", e)}
                />
                <Col span={24}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "left",
                      width: "100%",
                      paddingBottom: "8px",
                    }}
                  >
                    {t("admin.form.guide.surveyQuestionRequired")}
                  </div>
                  <Switch
                    checked={input.isRequired}
                    disabled={disabled}
                    placeholder={t("admin.form.guide.surveyQuestionRequired")}
                    onChange={(e) => handleInputChange(index, "isRequired", e)}
                  />
                </Col>
              </Col>
              <Col span={18}>
                <Row gutter={[10, 10]} style={{ width: "100%" }}>
                  <Col span={22}>
                    <Input
                      disabled={disabled}
                      placeholder={t("admin.form.guide.surveyQuestionTitle")}
                      value={input.title}
                      onChange={(e) => handleInputChange(index, "title", e)}
                    />
                  </Col>
                  <Col span={22}>
                    <Input
                      disabled={disabled}
                      placeholder={t("admin.form.guide.surveyQuestionSubtitle")}
                      value={input.subtitle}
                      onChange={(e) => handleInputChange(index, "subtitle", e)}
                    />
                  </Col>
                  <Button
                    disabled={disabled}
                    type="text"
                    icon={<DeleteOutlined />}
                    onClick={() => handleRemove(index)}
                  />
                  <Col span={24}>
                    <EventV2SurveyQuestionOptionEditor
                      value={input}
                      disabled={disabled}
                      hidden={
                        input.category !== "MULTIPLE_CHOICE" &&
                        input.category !== "SINGLE_CHOICE"
                      }
                      items={input.options}
                      onChange={(e) => handleInputChange(index, "options", e)}
                      style={{ width: "100%" }}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          ))}
          <Button
            disabled={disabled}
            type="link"
            icon={<PlusOutlined />}
            onClick={handleAdd}
          >
            add survey question
          </Button>
        </Space>
      </Col>
    );
  },
);

// ==

const styles = {
  container: {
    width: "100%",
  },
  selectInput: {
    width: 400,
  },
};
