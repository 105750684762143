import { useTranslation } from "react-i18next";

import { ExportOutlined } from "@ant-design/icons";
import { Card, Col, List, Row, Typography, theme } from "antd";

export const TranslationRequestTargetContentRevisions = (props) => {
  const { t } = useTranslation();
  const { Title, Paragraph } = Typography;
  const { targetContent } = props;
  const revisions = [];
  const inputSource = targetContent.translationRequest.inputSource;
  const sourceData =
    inputSource === "FILE" ? (
      <a
        href={
          targetContent.translationRequest.uploadFiles[0].downloadUrl ??
          targetContent.translationRequest.uploadFiles[0].url
        }
        rel="noopener noreferrer"
      >
        <ExportOutlined />
      </a>
    ) : (
      targetContent.translationRequest.plainText
    );

  if (targetContent.translationRequest.additionalRequestComment) {
    revisions.push({
      author: targetContent.translationRequest.requestor.user.name,
      date: targetContent.translationRequest.createdAt,
      memo: targetContent.translationRequest.additionalRequestComment,
    });
  }
  targetContent.revisions.forEach((revision) => {
    if (revision.progressStatus === "REJECTED") {
      // This is a reject message before close the translation request
      revisions.push({
        author: revision.author.user.name, // TODO: change name to role
        date: revision.createdAt,
        memo: revision.memo,
      });
    } else if (revision.progressStatus === "TRANSLATING" && revision.memo) {
      // This is a reopen message before confirming the transmission
      revisions.push({
        author: revision.author.user.name, // TODO: change name to role
        date: revision.createdAt,
        memo: revision.memo,
      });
    }
  });

  const { useToken } = theme;
  const { token } = useToken();
  const typographStyle = {
    height: "220px",
    width: "100%",
    whiteSpace: "pre-wrap",
    display: "block",
    overflowY: "auto",
    padding: "8px",
    border: `1px solid ${token.colorBorder}`,
    borderRadius: "4px",
    backgroundColor: token.colorBgTextActive,
    color: token.colorText,
  };

  return (
    <Card>
      <Row style={{ borderBottom: "1px solid #f0f0f0" }}>
        <Col span={24}>
          <Title level={4}>{t("admin.resource.translationRequest.singular")}</Title>
          <Typography style={{ marginBottom: "10px" }}>
            {inputSource === "FILE" ? (
              sourceData
            ) : (
              <Paragraph style={typographStyle}>{sourceData}</Paragraph>
            )}
          </Typography>
        </Col>
      </Row>
      <Row style={{ display: "block" }}>
        <Col>
          <List
            itemLayout="horizontal"
            dataSource={revisions}
            renderItem={(revision, _) => (
              <List.Item>
                <List.Item.Meta
                  avatar={revision.author}
                  title={
                    <div style={{ fontWeight: "normal" }}>
                      {revision.date ? new Date(revision.date).toLocaleString() : ""}
                    </div>
                  }
                  description={
                    <div style={{ fontWeight: "bolder", color: "#555" }}>
                      {revision.memo}
                    </div>
                  }
                />
              </List.Item>
            )}
          />
        </Col>
      </Row>
    </Card>
  );
};
