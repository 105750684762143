import { forwardRef, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Checkbox, Space } from "antd";

import dayjs from "dayjs";

import { deepEqual } from "../../utils/helpers";
import { DateRangePicker } from "./DateRangePicker";
import { TimeRangePicker } from "./TimeRangePicker";

export const OfflineStoreOperatingScheduleEditor = forwardRef(
  (
    { status, onChange, value: { operatingSchedules, startDate, endDate }, ...props },
    ref,
  ) => {
    const { t } = useTranslation();
    const [operatingSchedulesValue, setOperatingSchedulesValue] = useState(
      operatingSchedules || [],
    );
    const [dateRange, setDateRange] = useState(
      startDate && endDate ? [{ date: startDate }, { date: endDate }] : [],
    );

    const handleDateChange = (dateRange) => {
      setDateRange(
        dateRange?.map((date) => ({ date: date.format("YYYY-MM-DD") })) ?? [],
      );
      setOperatingSchedulesValue([]);
    };

    const findSchedule = (scheduleCategory, date) =>
      operatingSchedulesValue.find(
        (entry) =>
          entry.scheduleCategory === scheduleCategory && (!date || entry.date === date),
      );
    const everydaySchedule = findSchedule("EVERYDAY");
    const weekdaySchedule = findSchedule("WEEKDAY");
    const weekendSchedule = findSchedule("WEEKEND");
    const getScheduleByDate = (date) => {
      const specificSchedule = findSchedule("SPECIFIC", date);
      return (
        specificSchedule ||
        weekendSchedule ||
        weekdaySchedule ||
        everydaySchedule ||
        null
      );
    };

    useEffect(() => {
      const [newStartDate, newEndDate] = dateRange.map(({ date }) => date);
      if (
        !deepEqual(operatingSchedulesValue, operatingSchedules) ||
        newStartDate !== startDate ||
        newEndDate !== endDate
      ) {
        onChange?.({
          operatingSchedules: operatingSchedulesValue,
          startDate: newStartDate,
          endDate: newEndDate,
        });
      }
    }, [
      onChange,
      operatingSchedulesValue,
      operatingSchedules,
      startDate,
      endDate,
      dateRange,
    ]);

    const generateTimeComponent = (label, schedule, onChange) => {
      const value = schedule
        ? [schedule.openTime, schedule.closeTime].filter(Boolean)
        : [];
      return (
        <div
          key={label}
          style={{ display: "flex", alignItems: "center", marginBottom: "10px" }}
        >
          <p style={{ marginRight: "20px" }}>{label}</p>
          <TimeRangePicker
            status={status}
            onChange={onChange}
            items={value}
            minuteStep={5}
          />
        </div>
      );
    };

    const handleTimeChange = (scheduleCategory, date) => (timeRange) => {
      const [openTime, closeTime] = timeRange.map((time) =>
        dayjs(time).format("HH:mm"),
      );
      const newEntry = {
        scheduleCategory,
        date: date,
        openTime: openTime,
        closeTime: closeTime,
      };

      setOperatingSchedulesValue((prevSchedules) => {
        const newSchedules = [...prevSchedules];
        const existingEntry = date
          ? newSchedules.find(
              (entry) =>
                entry.scheduleCategory === scheduleCategory && entry.date === date,
            )
          : newSchedules.find((entry) => entry.scheduleCategory === scheduleCategory);
        if (existingEntry) {
          existingEntry.openTime = openTime;
          existingEntry.closeTime = closeTime;
        } else {
          newSchedules.push(newEntry);
        }
        return newSchedules;
      });
    };

    const generateTimeComponents = () => {
      if (everydaySchedule || weekendSchedule || weekdaySchedule) return null;
      if (dateRange.length !== 2) return null;
      const [startDay, endDay] = dateRange.map(({ date }) => dayjs(date));
      const days = endDay.diff(startDay, "day") + 1;

      return Array.from({ length: days }, (_, i) => {
        const day = startDay.add(i, "day");
        const label = day.format("MMMM D, YYYY");
        const date = day.format("YYYY-MM-DD");
        const schedule = getScheduleByDate(date);
        return generateTimeComponent(
          label,
          schedule,
          handleTimeChange("SPECIFIC", date),
        );
      });
    };

    const toggle = (scheduleCategory, ...categories) => {
      if (findSchedule(scheduleCategory)) {
        setOperatingSchedulesValue([]);
      } else {
        setOperatingSchedulesValue(
          [scheduleCategory, ...categories].map((c) => ({ scheduleCategory: c })),
        );
      }
    };

    return (
      <Space direction="vertical" size={8} ref={ref}>
        <DateRangePicker
          onChange={handleDateChange}
          items={dateRange}
          disabledDate={() => false}
          status={status}
          {...props}
        />
        <Space direction="horizontal">
          <Checkbox checked={!!everydaySchedule} onChange={() => toggle("EVERYDAY")}>
            {t("admin.component.dateTimePicker.everyDay")}
          </Checkbox>
          <Checkbox
            checked={!!(weekendSchedule || weekdaySchedule)}
            onChange={() => toggle("WEEKDAY", "WEEKEND")}
          >
            {`${t("admin.component.dateTimePicker.weekEnd")}, ${t(
              "admin.component.dateTimePicker.weekDay",
            )}`}
          </Checkbox>
        </Space>
        {everydaySchedule
          ? generateTimeComponent(
              t("admin.component.dateTimePicker.everyDay"),
              everydaySchedule,
              handleTimeChange("EVERYDAY"),
            )
          : null}
        {weekdaySchedule
          ? generateTimeComponent(
              t("admin.component.dateTimePicker.weekDay"),
              weekdaySchedule,
              handleTimeChange("WEEKDAY"),
            )
          : null}
        {weekendSchedule
          ? generateTimeComponent(
              t("admin.component.dateTimePicker.weekEnd"),
              weekendSchedule,
              handleTimeChange("WEEKEND"),
            )
          : null}
        {generateTimeComponents()}
      </Space>
    );
  },
);
