import { forwardRef, useImperativeHandle, useRef } from "react";

import { default as ReactPhoneInput } from "react-phone-number-input/min";
import "react-phone-number-input/style.css";

// import { CloseCircleFilled } from "@ant-design/icons";
import { Input } from "antd";

export const PhoneInput = ({ setValue, ...props }) => {
  return (
    <ReactPhoneInput
      {...props}
      international
      inputComponent={PhoneInputComponent}
      onChange={(value) => {
        if (value === undefined || value === "") {
          setValue(props.name, null);
        } else {
          setValue(props.name, value);
        }
      }}
    />
  );
};

// ==

const PhoneInputComponent = forwardRef(({ ...props }, ref) => {
  const inputRef = useRef(null);

  useImperativeHandle(
    ref,
    () => {
      return inputRef.current.input;
    },
    [],
  );

  return (
    <Input
      {...props}
      ref={inputRef}
      // allowClear={{
      //   clearIcon: <CloseCircleFilled onClick={() => props?.resetValue()} />,
      // }}
    />
  );
});
