import { gql } from "@apollo/client";

import { toCamelCase } from "../../utils/helpers";
import { ListWidget } from "../list/ListWidget";

export const AuditListWidget = (props) => (
  <ListWidget
    resource="audit"
    fields={[
      {
        name: "id",
      },
      {
        name: "group",
        type: "object",
        resource: "group",
        filterKey: "targetGroupId_Overlap",
      },
      {
        name: "user",
        type: "object",
        resource: "user",
        filterKey: "userId_Overlap",
      },
      {
        name: "target",
        type: "object",
        resource: ({ obj }) => toCamelCase(obj.target.type.toLowerCase()),
      },
      {
        name: "category",
        type: "choice",
        enumType: "AuditCategory",
        filterKey: "category_Overlap",
      },
      {
        name: "memo",
        resource: "memo",
      },
      {
        name: "createdAt",
      },
    ]}
    query={QUERY}
    {...props}
  />
);

const QUERY = gql`
  query auditsForAuditListWidget(
    $filter: AuditFilterInput
    $sort: [AuditSortInput]
    $page: PageInput
  ) {
    audits(filter: $filter, sort: $sort, page: $page) {
      objects {
        id
        user {
          id
          name
        }
        targetGroup {
          id
          name
        }
        target {
          id
          type
          ... on Member {
            id
            nickname
          }
        }
        category
        memo
        diff
        createdAt
      }
      ...ListFields
    }
  }
  ${ListWidget.fragments.list}
`;
