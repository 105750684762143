import { useState } from "react";
import { useTranslation } from "react-i18next";

import { gql, useMutation } from "@apollo/client";

import { useDrawerState } from "../../hooks/drawer";
import { TRANSLATION_VALUE_FRAGMENT } from "../../utils/gql/fragments";
import { FormDrawer } from "../drawers";

export const EditTranslationValueAction = ({
  translationValueId,
  defaultValues = undefined,
  onChange,
  disabled = false,
  children,
  referenceValues = undefined,
  afterOpenChange: originalAfterOpenChange = undefined,
  ...props
}) => {
  const { t } = useTranslation();
  const {
    doesRender,
    openDrawer,
    closeDrawer,
    drawerProps: { afterOpenChange: drawerAfterOpenChange, ...drawerProps },
  } = useDrawerState();
  const [help, setHelp] = useState();
  const [translate] = useMutation(TRANSLATION_MUTATION);

  // ==

  const onSuccess = (value) => {
    onChange?.(value);
    closeDrawer();
  };
  const afterOpenChange = (open, ...args) => {
    originalAfterOpenChange?.(open, ...args);
    drawerAfterOpenChange?.(open, ...args);

    if (referenceValues?.length > 0 && defaultValues?.language) {
      translate({
        variables: {
          text: referenceValues[0],
          language: {
            ENGLISH: "en",
            KOREAN: "ko",
            JAPANESE: "ja",
            CHINESE: "zh-CN",
          }[defaultValues.language],
        },
      }).then((response) => {
        setHelp(response.data.translate.translatedText);
      });
    }
  };

  return (
    <>
      {children?.({ openDrawer, disabled })}
      {doesRender && (
        <FormDrawer
          title={t("admin.common.actionTitle", {
            action: translationValueId ? t("admin.common.edit") : t("admin.common.add"),
            name: t("admin.resource.translationValue.singular"),
          })}
          resource="translationValue"
          fields={[
            {
              name: "code",
              type: "object",
              resource: "translationCode",
              disabled: true,
            },
            {
              name: "language",
              type: "choice",
              enumType: "ServiceLanguage",
              disabled: true,
              control: "select",
            },
            {
              name: "isReadyForReview",
              type: "boolean",
            },
            {
              name: "value",
              rows: 10,
              help: help,
            },
          ]}
          id={translationValueId}
          query={QUERY}
          createMutation={CREATE_MUTATION}
          updateMutation={UPDATE_MUTATION}
          onSuccess={onSuccess}
          defaultValues={defaultValues}
          drawerProps={{ ...drawerProps, afterOpenChange }}
          {...props}
        />
      )}
    </>
  );
};

// ==

const QUERY = gql`
  query translationValueForEditTranslationValueAction($id: ID!) {
    translationValue(filter: { id_Overlap: [$id] }) {
      id
      code {
        id
      }
      language
      value
    }
  }
`;

const CREATE_MUTATION = gql`
  mutation createTranslationValueForEditTranslationValueAction(
    $data: TranslationValueMutationInput!
  ) {
    createTranslationValue(data: $data) {
      ok
      errors {
        ...MutationErrorFields
      }
      object {
        ...TranslationValueFields
      }
    }
  }
  ${FormDrawer.fragments.mutationError}
  ${TRANSLATION_VALUE_FRAGMENT}
`;

const UPDATE_MUTATION = gql`
  mutation updateTranslationValueForEditTranslationValueAction(
    $data: TranslationValueMutationInput!
    $id: ID!
  ) {
    updateTranslationValue(data: $data, id: $id, partial: true) {
      ok
      errors {
        ...MutationErrorFields
      }
      object {
        ...TranslationValueFields
      }
    }
  }
  ${FormDrawer.fragments.mutationError}
  ${TRANSLATION_VALUE_FRAGMENT}
`;

const TRANSLATION_MUTATION = gql`
  mutation translateForEditTranslationValueAction($text: String!, $language: String) {
    translate(text: $text, language: $language) {
      ok
      translatedText
    }
  }
`;
