import { FC } from "react";
import { useTranslation } from "react-i18next";

import { Button, InputNumber, Space } from "antd";

import {
  FilterButtonContainer,
  FilterDivider,
  FilterInputContainer,
} from "@/components/filter/styled";
import { FilterProps } from "@/types/filter/FilterProps";
import { isEmpty } from "@/utils/helpers";

export const RangeFilter: FC<FilterProps> = ({
  setSelectedKeys,
  selectedKeys,
  confirm,
  clearFilters,
}) => {
  const { t } = useTranslation();

  const min = selectedKeys?.[0];
  const max = selectedKeys?.[1];

  return (
    <div onKeyDown={(e) => e.stopPropagation()}>
      <FilterInputContainer style={styles.filterInputContainer}>
        <Space>
          <InputNumber
            placeholder="Min"
            value={min}
            onChange={(value) => setSelectedKeys([value, max])}
          />
          <InputNumber
            placeholder="Max"
            value={max}
            onChange={(value) => setSelectedKeys([min, value])}
          />
        </Space>
      </FilterInputContainer>
      <FilterDivider />
      <FilterButtonContainer>
        <Button
          type="link"
          size="small"
          disabled={isEmpty(selectedKeys)}
          onClick={clearFilters}
        >
          {t("admin.common.reset")}
        </Button>
        <Button type="primary" onClick={() => confirm()} size="small">
          {t("admin.common.ok")}
        </Button>
      </FilterButtonContainer>
    </div>
  );
};

const styles = {
  container: {
    display: "flex",
  },
  filterInputContainer: {
    padding: "7px 8px",
  },
  filterDivider: {
    margin: 0,
  },
  filterButtonContainer: {
    padding: "7px 8px",
    display: "flex",
    justifyContent: "space-between",
  },
  objectFilterContainer: {
    minWidth: 200,
  },
};
