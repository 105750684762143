import { forwardRef, useEffect, useState } from "react";

import { Space } from "antd";

import dayjs from "dayjs";

import { useTimezone } from "../../hooks/tz";
import { deepEqual } from "../../utils/helpers";
import { DateRangePicker } from "./DateRangePicker";
import { TimeRangePicker } from "./TimeRangePicker";

export const DateAndHourWithTZEditor = forwardRef(
  ({ onChange, value: { dateAndHours }, disabled = false, ...props }, ref) => {
    const [dateAndHoursValue, setDateAndHoursValue] = useState(dateAndHours || []);
    const { timezone } = useTimezone();
    const [dateRange, setDateRange] = useState(
      dateAndHours?.length
        ? [
            dayjs(dateAndHours[0].date),
            dayjs(dateAndHours[dateAndHours.length - 1].date),
          ]
        : [],
    );
    const handleDateChange = (dateRange) => {
      setDateRange(dateRange);
      setDateAndHoursValue([]);
    };

    useEffect(() => {
      if (dateAndHoursValue.length === 0) {
        return;
      }
      if (!deepEqual(dateAndHoursValue, dateAndHours)) {
        onChange?.({ dateAndHours: dateAndHoursValue });
      }
    }, [dateAndHoursValue, onChange, dateAndHours]);

    const generateTimeComponents = () => {
      if (!dateRange || dateRange.length !== 2) {
        return null;
      }
      const startDate = dayjs(dateRange[0]);
      const endDate = dayjs(dateRange[1]);
      const days = endDate.diff(startDate, "day") + 1;
      const timeComponents = [];

      for (let i = 0; i < days; i++) {
        const date = startDate.add(i, "day");
        const hoursObj = dateAndHoursValue.find(
          (entry) => entry.date === date.format("YYYY-MM-DD"),
        );
        const times = hoursObj ? [hoursObj.openTime, hoursObj.closeTime] : [];
        timeComponents.push(
          <div
            key={date.toString()}
            style={{ display: "flex", alignItems: "center", marginBottom: "10px" }}
          >
            <p style={{ marginRight: "20px" }}>{date.format("MMMM D, YYYY")}</p>
            <TimeRangePicker
              onChange={(timeRange) =>
                handleTimeChange(
                  dayjs(date).format("YYYY-MM-DD"),
                  dayjs(timeRange[0]).format("HH:mm"),
                  dayjs(timeRange[1]).format("HH:mm"),
                )
              }
              disabled={disabled}
              items={times}
              minuteStep={5}
            />
          </div>,
        );
      }
      return timeComponents;
    };

    const handleTimeChange = (date, openTime, closeTime) => {
      const newEntry = {
        date: date,
        openTime: openTime,
        closeTime: closeTime,
        timeZone: timezone,
      };

      setDateAndHoursValue((prevHours) => {
        const newHours = [...prevHours];
        const existingEntry = newHours.find((entry) => entry.date === date);
        if (existingEntry) {
          existingEntry.openTime = openTime;
          existingEntry.closeTime = closeTime;
          existingEntry.timeZone = timezone;
        } else {
          newHours.push(newEntry);
        }
        return newHours;
      });
    };

    return (
      <Space direction="vertical" size={8} ref={ref}>
        <DateRangePicker
          disabled={disabled}
          onChange={handleDateChange}
          items={dateAndHoursValue}
          {...props}
        />
        {generateTimeComponents()}
      </Space>
    );
  },
);
