import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { gql, useMutation, useQuery } from "@apollo/client";

import { SaveOutlined } from "@ant-design/icons";
import { Button, Card, Col, Divider, Row, Space, Switch, Typography } from "antd";

import { DndContext, PointerSensor, useSensor, useSensors } from "@dnd-kit/core";
import {
  SortableContext,
  arrayMove,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import dayjs from "dayjs";

import { DragHandle } from "../../components/list";
import { useApp } from "../../hooks/app";

const { Text } = Typography;

export const GroupHomeSettingContent = ({ groupId }) => {
  const { t } = useTranslation();
  const [elements, setElements] = useState();
  const { data, refetch } = useQuery(QUERY, {
    variables: { id: groupId },
    fetchPolicy: "network-only",
  });
  const [update, { loading }] = useMutation(MUTATION);
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 10,
      },
    }),
  );
  const { message } = useApp();

  useEffect(() => {
    if (data) {
      setElements(sortElements(data.allGroupElements));
    }
  }, [data]);

  if (!elements) {
    return null;
  }

  // ==

  const onDragEnd = ({ active, over }) => {
    if (active.id !== over?.id) {
      setElements((prev) => {
        const activeIndex = prev.findIndex((i) => i.id === active.id);
        const overIndex = prev.findIndex((i) => i.id === over?.id);
        return arrayMove(prev, activeIndex, overIndex);
      });
    }
  };
  const onSave = () =>
    update({
      variables: {
        data: elements.map((element, i) => ({
          id: element.id,
          category: element.category,
          homeOrder: i + 1,
        })),
      },
      onCompleted: (response) => {
        const { ok } = Object.values(response)[0];
        if (ok) {
          message.success("Successfully saved.");
          refetch();
        }
      },
    });

  const lastUpdatedAt = elements.map((element) => element.lastUpdatedAt).toSorted()[
    elements.length - 1
  ];

  return (
    <div style={styles.container}>
      <Row>
        <Col span={24} lg={12}>
          <Space direction="vertical" size="large">
            <Card>
              <Space direction="vertical">
                <Space size="large">
                  <Text>Automatic Update</Text>
                  <Switch disabled />
                </Space>
                <Text type="secondary">
                  {t("admin.page.groupHomeSetting.automaticDescription")}
                </Text>
                <Text type="warning">
                  {t("admin.page.groupHomeSetting.automaticNotSupported")}
                </Text>
              </Space>
            </Card>
            <Card>
              <Space direction="vertical">
                <Text>{t("admin.page.groupHomeSetting.orderHomeLayoutLabel")}</Text>
                <Text type="secondary">
                  {t("admin.page.groupHomeSetting.orderHomeLayoutDescription")}
                </Text>
                <div>
                  <DndContext sensors={sensors} onDragEnd={onDragEnd}>
                    <SortableContext
                      items={elements.map((i) => i.id)}
                      strategy={verticalListSortingStrategy}
                    >
                      {elements.map((element) => (
                        <Draggable key={element.id} id={element.id}>
                          <Space size="large" style={styles.row}>
                            <DragHandle />
                            <Text>{element.category}</Text>
                          </Space>
                        </Draggable>
                      ))}
                    </SortableContext>
                  </DndContext>
                </div>
              </Space>
            </Card>
          </Space>
          <Divider />
          <Space size="large">
            <Button
              type="primary"
              htmlType="submit"
              icon={<SaveOutlined />}
              disabled={loading}
              onClick={onSave}
            >
              {loading ? t("admin.common.saving") : t("admin.common.save")}
            </Button>
            {lastUpdatedAt && (
              <Text type="secondary">
                {t("admin.common.lastUpdated", {
                  ago: dayjs(lastUpdatedAt).fromNow(),
                })}
              </Text>
            )}
          </Space>
        </Col>
      </Row>
    </div>
  );
};

// =

const Draggable = ({ id, ...props }) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id,
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    cursor: "move",
  };

  return (
    <div ref={setNodeRef} style={style} {...attributes} {...listeners} {...props} />
  );
};

// ==

const compute = (element) => element.homeOrder ?? 0;

const sortElements = (elements) => elements.toSorted((a, b) => compute(a) - compute(b));

// ==

const QUERY = gql`
  query allGroupElementsForGroupHomeSettingContent($id: ID!) {
    allGroupElements(
      filter: {
        group_Overlap: [$id]
        category_Not_Overlap: [HOME]
        isActive_Exact: true
      }
    ) {
      id
      category
      homeOrder
      lastUpdatedAt
    }
  }
`;

const MUTATION = gql`
  mutation bulkUpdateGroupElement($data: [BulkUpdateGroupElementInput]!) {
    bulkUpdateGroupElement(data: $data) {
      ok
    }
  }
`;

// ==

const styles = {
  container: {
    marginTop: 20,
  },
  row: {
    paddingTop: 4,
    paddingBottom: 4,
  },
};
