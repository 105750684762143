import { gql } from "@apollo/client";

import { DetailPage } from "../../components/pages";
import { TranslationValueListWidget } from "../../components/widgets";

export const TranslationCodeDetail = () => (
  <DetailPage
    resource="translationCode"
    fields={[
      {
        name: "id",
      },
      {
        name: "code",
      },
      {
        name: "context",
        type: "choice",
        enumType: "TranslationContext",
      },
      {
        name: "images",
        type: "imageMeta",
        spaceDirection: "horizontal",
      },
      {
        name: "memo",
      },
      {
        name: "lastValueUpdatedAt",
        type: "datetime",
      },
      ...DetailPage.fields.tracked,
    ]}
    query={QUERY}
    extraActions={DetailPage.extraActions.delete}
    tabs={[
      {
        path: "",
        labelKey: "admin.resource.translationValue.plural",
        permission: {
          action: "list",
          targetType: "TRANSLATION_VALUE",
        },
        children: ({ obj }) => (
          <TranslationValueListWidget
            baseFilter={{
              code_Overlap: [obj.id],
            }}
            createSearchParams={{ code: obj.id }}
            hiddenFields={["code"]}
          />
        ),
      },
    ]}
  />
);

// ==

const QUERY = gql`
  query translationCodeForTranslationCodeDetail($id: ID!) {
    translationCode(filter: { id_Overlap: [$id] }) {
      id
      code
      context
      images {
        ...DetailImageFields
      }
      memo
      lastValueUpdatedAt
      ...TrackedFields
    }
  }
  ${DetailPage.fragments.tracked}
  ${DetailPage.fragments.image}
`;
