import { gql } from "@apollo/client";

import { ListWidget } from "../list";

export const NoticeListWidget = (props) => (
  <ListWidget
    resource="notice"
    fields={[
      {
        name: "id",
      },
      {
        name: "group",
        type: "object",
        resource: "group",
        filterKey: "group_Overlap",
      },
      {
        name: "title",
      },
      {
        name: "isHighlighted",
        type: "boolean",
        filterKey: "isHighlighted_Exact",
      },
      {
        name: "isAd",
        type: "boolean",
        filterKey: "isAd_Exact",
      },
      {
        name: "status",
        type: "choice",
        enumType: "NoticeStatus",
      },
      {
        name: "activateAt",
        type: "datetime",
      },
      ...ListWidget.fields.tracked,
    ]}
    query={QUERY}
    extraRowActions={ListWidget.extraRowActions.canActivate}
    {...props}
  />
);

// ==

const QUERY = gql`
  query noticesForNoticeListWidget(
    $filter: NoticeFilterInput
    $sort: [NoticeSortInput]
    $page: PageInput
  ) {
    notices(filter: $filter, sort: $sort, page: $page) {
      objects {
        id
        group {
          id
          name
        }
        title
        isHighlighted
        isAd
        status
        ...TrackedFields
        ...CanActivateFields
      }
      ...ListFields
    }
  }
  ${ListWidget.fragments.list}
  ${ListWidget.fragments.tracked}
  ${ListWidget.fragments.canActivate}
`;
