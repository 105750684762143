import { gql } from "@apollo/client";

import { DetailPage } from "../../components/pages";

export const ExampleDetail = () => (
  <DetailPage
    resource="example"
    fields={[
      {
        name: "id",
      },
      {
        name: "slug",
      },
      {
        name: "char",
      },
      {
        name: "longText",
      },
      {
        name: "integer",
        type: "integer",
      },
      {
        name: "decimal",
        type: "float",
      },
      {
        name: "email",
        type: "email",
      },
      {
        name: "url",
        type: "url",
      },
      {
        name: "array",
        help: "Comma-separated",
        getter: (obj) => obj.array?.join(","),
      },
      {
        name: "date",
        type: "date",
      },
      {
        name: "datetime",
        type: "datetime",
      },
      {
        name: "phone",
        type: "phone",
      },
      {
        name: "boolean",
        type: "boolean",
      },
      {
        name: "nullBoolean",
        type: "boolean",
        nullable: true,
      },
      {
        name: "fileMeta",
        type: "fileMeta",
      },
      {
        name: "imageMeta",
        type: "imageMeta",
      },
      {
        name: "videoMeta",
        type: "videoMeta",
      },
      {
        name: "genericMeta",
        type: "genericMeta",
      },
      {
        name: "genericMetas",
        type: "genericMeta",
      },
      {
        name: "textChoice",
        type: "choice",
        enumType: "ExampleTextChoice",
      },
      {
        name: "integerChoice",
        type: "choice",
        enumType: "ExampleIntegerChoice",
      },
      {
        name: "integerChoices",
        type: "choice",
        enumType: "ExampleIntegerChoice",
        multiple: true,
      },
      {
        name: "countryOne",
        type: "object",
        resource: "country",
      },
      {
        name: "countryForeign",
        type: "object",
        resource: "country",
      },
      {
        name: "countriesMany",
        type: "object",
        resource: "country",
      },
      // ==
      {
        name: "status",
        type: "choice",
        enumType: "ExampleStatus",
      },
      {
        name: "metaStatus",
        type: "choice",
        enumType: "MetaStatus",
      },
      ...DetailPage.fields.canActivate,
      ...DetailPage.fields.tracked,
    ]}
    query={QUERY}
    extraActions={DetailPage.extraActions.canActivate}
  />
);

// ==

const QUERY = gql`
  query exampleForExampleDetail($id: ID!) {
    example(filter: { id_Overlap: [$id] }) {
      id
      slug
      char
      longText
      integer
      decimal
      email
      url
      array
      date
      datetime
      phone
      boolean
      nullBoolean
      fileMeta {
        ...DetailFileFields
      }
      imageMeta {
        ...DetailImageFields
      }
      videoMeta {
        ...DetailVideoFields
      }
      genericMeta {
        ...DetailMediaFields
      }
      genericMetas {
        ...DetailMediaFields
      }
      textChoice
      integerChoice
      integerChoices
      countryOne {
        id
        name
      }
      countryForeign {
        id
        name
      }
      countriesMany {
        id
        name
      }
      status
      metaStatus
      ...TrackedFields
      ...CanActivateFields
    }
  }
  ${DetailPage.fragments.tracked}
  ${DetailPage.fragments.canActivate}
  ${DetailPage.fragments.file}
  ${DetailPage.fragments.image}
  ${DetailPage.fragments.video}
  ${DetailPage.fragments.media}
`;
