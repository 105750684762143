import { useTranslation } from "react-i18next";

import { gql } from "@apollo/client";

import { DeleteOutlined } from "@ant-design/icons";
import { Button } from "antd";

import { useDrawerState } from "../../hooks/drawer";
import { capitalize } from "../../utils/helpers";
import { FormDrawer } from "../drawers";

export const WithdrawUserButton = ({
  userId,
  value,
  onChange,
  size = "middle",
  onlyIcon = false,
}) => {
  const { t } = useTranslation();
  const { doesRender, openDrawer, closeDrawer, drawerProps } = useDrawerState();

  const onSuccess = () => {
    onChange?.();
    closeDrawer();
  };

  const action = t("admin.component.withdrawUserButton.withdraw");

  return (
    <>
      <Button
        type={value ? "primary" : "default"}
        ghost
        disabled={value}
        danger={!value}
        icon={<DeleteOutlined />}
        onClick={openDrawer}
        size={size}
      >
        {onlyIcon ? null : capitalize(action)}
      </Button>
      {doesRender && (
        <FormDrawer
          title={t("admin.common.actionTitle", {
            action: t("admin.common.withdraw"),
            name: t("admin.resource.user.singular"),
          })}
          defaultValues={{
            withdrawalCategory: "WITHDRAWN",
          }}
          resource="user"
          query={QUERY}
          updateMutation={WITHDRAW_USER_MUTATION}
          fields={[
            {
              name: "withdrawalCategory",
              type: "choice",
              enumType: "UserWithdrawalCategory",
              hidden: true,
            },
            {
              name: "withdrawalReason",
              type: "choice",
              enumType: "UserWithdrawalReason",
            },
          ]}
          id={userId}
          successMessage={t("admin.message.processSuccess")}
          okButtonText={t("admin.common.withdraw")}
          okButtonLoadingText={t("admin.common.withdrawing")}
          okButtonProps={{
            danger: true,
            icon: <DeleteOutlined />,
          }}
          onSuccess={onSuccess}
          drawerProps={drawerProps}
        />
      )}
    </>
  );
};

// ==

const QUERY = gql`
  query withdrawalCategoryForWithdrawUserButton($id: ID!) {
    user(filter: { id_Overlap: [$id] }) {
      id
      withdrawalCategory
      withdrawalReason
    }
  }
`;

const WITHDRAW_USER_MUTATION = gql`
  mutation withdrawUserForWithdrawUserButton(
    $data: WithdrawUserMutationInput!
    $id: ID!
  ) {
    withdrawUser(data: $data, id: $id) {
      ok
      errors {
        ...MutationErrorFields
      }
    }
  }
  ${FormDrawer.fragments.mutationError}
`;
