import { Menu } from "@/types/menu";

const menus = [
  {
    key: "dashboard",
    url: "/",
    permission: {
      action: "view/admin/dashboard",
    },
  },
  {
    labelKey: "admin.menu.mainScreen",
    resource: "groupMainScreen",
    permission: {
      action: "view/admin/main-screen",
    },
  },
  {
    labelKey: "admin.menu.company",
    resource: "company",
  },
  {
    key: "_community",
    children: [
      {
        labelKey: "admin.menu.group",
        resource: "group",
      },
      {
        labelKey: "admin.menu.groupTheme",
        resource: "groupTheme",
      },
      {
        labelKey: "admin.menu.premium",
        resource: "premium",
      },
      {
        key: "_member",
        children: [
          {
            labelKey: "admin.menu.member",
            resource: "member",
          },
          {
            labelKey: "admin.menu.artist",
            resource: "artist",
          },
          {
            labelKey: "admin.menu.groupStaff",
            resource: "groupStaff",
          },
        ],
      },
      {
        key: "_schedule",
        children: [
          {
            key: "scheduleCalendar",
            url: "/schedule-calendar",
            permission: {
              action: "view/admin/schedule-calendar",
            },
          },
          {
            labelKey: "admin.menu.schedule",
            resource: "schedule",
          },
        ],
      },
    ],
  },
  {
    key: "_content",
    children: [
      {
        key: "groupTabSetting",
        url: "/group-tab-setting",
        permission: {
          action: "view/admin/group-tab-setting",
        },
      },
      {
        key: "groupHomeSetting",
        url: "/group-home-setting",
        permission: {
          action: "view/admin/group-home-setting",
        },
      },
      {
        key: "_fan",
        children: [
          {
            labelKey: "admin.menu.fanPost",
            resource: "fanPost",
          },
          {
            labelKey: "admin.menu.hashtag",
            resource: "hashtag",
          },
        ],
      },
      {
        key: "_artist",
        permission: {
          action: "view/admin/content-artist",
        },
        children: [
          {
            labelKey: "admin.menu.artistPost",
            resource: "artistPost",
          },
          {
            labelKey: "admin.menu.poll",
            resource: "poll",
          },
        ],
      },
      {
        labelKey: "admin.menu.comment",
        resource: "comment",
      },
      {
        key: "_media",
        children: [
          {
            labelKey: "admin.menu.mediaLayout",
            resource: "mediaLayout",
          },
          {
            labelKey: "admin.menu.playlist",
            resource: "playlist",
          },
          {
            labelKey: "admin.menu.clip",
            resource: "clip",
          },
        ],
      },
      {
        labelKey: "admin.menu.notice",
        resource: "notice",
      },
      {
        key: "_event",
        permission: {
          action: "view/admin/event",
        },
        children: [
          {
            labelKey: "admin.menu.event",
            resource: "event",
          },
          {
            labelKey: "admin.menu.eventPenalty",
            resource: "eventPenalty",
          },
        ],
      },
      {
        key: "_eventV2",
        permission: {
          action: "view/admin/eventV2",
        },
        children: [
          {
            labelKey: "admin.menu.eventV2",
            resource: "eventV2",
          },
          {
            labelKey: "admin.menu.eventV2Penalty",
            resource: "eventV2Penalty",
          },
          {
            labelKey: "admin.menu.offlineStore",
            resource: "offlineStore",
            permission: {
              action: "view/admin/eventV2/offlineStore",
            },
          },
          {
            labelKey: "admin.menu.offlineStoreItem",
            resource: "offlineStoreItem",
            permission: {
              action: "view/admin/eventV2/offlineStoreItem",
            },
          },
          {
            key: "_venue",
            permission: {
              action: "view/admin/_venue",
            },
            children: [
              {
                labelKey: "admin.menu.venue",
                resource: "venue",
                permission: {
                  action: "view/admin/venues",
                },
              },
              {
                labelKey: "admin.menu.booth",
                resource: "booth",
                permission: {
                  action: "view/admin/booths",
                },
              },
            ],
          },
        ],
      },
    ],
  },
  {
    key: "_account",
    permission: {
      action: "view/admin/account",
    },
    children: [
      {
        resource: "user",
        labelKey: "admin.menu.user",
      },
      {
        key: "_staff",
        children: [
          {
            resource: "staff",
            labelKey: "admin.menu.staff",
          },
          {
            resource: "role",
            labelKey: "admin.menu.role",
          },
        ],
      },
    ],
  },
  {
    key: "_audit",
    permission: {
      action: "view/admin/audit",
    },
    children: [
      {
        resource: "audit",
        labelKey: "admin.menu.audit",
      },
    ],
  },
  {
    key: "_moderation",
    permission: {
      action: "view/admin/moderation",
    },
    children: [
      {
        key: "_report",
        children: [
          {
            labelKey: "admin.menu.reportedPost",
            resource: "reportedPost",
          },
          {
            labelKey: "admin.menu.reportedComment",
            resource: "reportedComment",
          },
        ],
      },
      {
        key: "_moderated",
        children: [
          {
            labelKey: "admin.menu.moderatedPost",
            resource: "moderatedPost",
          },
          // {
          //   labelKey: "admin.menu.moderatedComment",
          //   resource: "moderatedComment",
          // },
        ],
      },
      {
        labelKey: "admin.menu.bannedPhrase",
        resource: "bannedPhrase",
      },
    ],
  },
  {
    key: "_translation",
    children: [
      {
        key: "translationEditor",
        url: "/translation-editor",
        permission: {
          action: "view/admin/translation-editor",
        },
      },
    ],
  },
  {
    key: "_translation_request",
    children: [
      {
        key: "translationRequestManagement",
        url: "/translation-request-management/list",
        permission: {
          action: "view/admin/translation-request-management",
        },
      },
      {
        key: "translationProgressManagement",
        url: "/translation-progress-management/list",
        permission: {
          action: "view/admin/translation-progress-management",
        },
      },
    ],
  },
  {
    key: "_administration",
    permission: {
      action: "view/admin/administration",
    },
    children: [
      {
        labelKey: "admin.menu.announcement",
        resource: "announcement",
      },
      {
        labelKey: "admin.menu.term",
        resource: "term",
      },
      {
        labelKey: "admin.menu.privacy",
        resource: "privacy",
      },
      {
        labelKey: "admin.menu.personalInformationTerms",
        resource: "personalInformationTerms",
      },
      {
        labelKey: "admin.menu.personalInformationCollection",
        resource: "personalInformationCollection",
      },
      {
        labelKey: "admin.menu.appVersion",
        resource: "appVersion",
      },
      {
        labelKey: "admin.menu.translationVersion",
        resource: "translationVersion",
      },
      {
        key: "_meta",
        children: [
          {
            labelKey: "admin.menu.language",
            resource: "language",
          },
          {
            labelKey: "admin.menu.continent",
            resource: "continent",
          },
          {
            labelKey: "admin.menu.subcontinent",
            resource: "subcontinent",
          },
          {
            labelKey: "admin.menu.country",
            resource: "country",
          },
        ],
      },
    ],
  },
  {
    key: "_dev",
    permission: {
      action: "view/admin/dev",
    },
    children: [
      {
        resource: "premiumMembership",
      },
      {
        resource: "externalPremiumMembership",
      },
      {
        resource: "bulkEmail",
      },
      {
        resource: "post",
      },
      {
        resource: "pollOption",
      },
      {
        resource: "pollVote",
      },
      {
        resource: "playlistEntry",
      },
      {
        resource: "mediaLayoutSection",
      },
      {
        resource: "translationCode",
      },
      {
        resource: "translationValue",
      },
      {
        resource: "groupFeatureFlag",
      },
      {
        resource: "eventPost",
      },
      {
        resource: "eventV2PenaltyCategory",
      },
    ],
  },
] as const satisfies Menu[];

export type OriginalMenus = typeof menus;

export default menus;
