import { useState } from "react";
import { useTranslation } from "react-i18next";

import { gql, useMutation } from "@apollo/client";

import { UploadOutlined } from "@ant-design/icons";
import { Button, Divider, Modal, Space, Typography, Upload } from "antd";

import { useApp } from "../../hooks/app";
import { MUTATION_ERROR_FRAGMENT } from "../../utils/gql/fragments";

const { Text } = Typography;

export const UploadBannedPhrasesButton = ({
  onSuccess = undefined,
  onlyIcon = false,
  ...props
}) => {
  const { t } = useTranslation();

  const onCompleted = (response) => {
    const { ok, errors } = Object.values(response)[0];
    if (ok) {
      message.success(t("admin.message.requestSuccess"));
      onSuccess?.();
      onCancel();
    } else {
      message.error(
        t("admin.message.requestFailure", {
          message: errors[0].messages[0],
        }),
      );
    }
  };

  const [fileList, setFileList] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [upload, { loading }] = useMutation(MUTATION, {
    variables: {
      file: fileList[0],
    },
    onCompleted,
  });
  const { message } = useApp();

  // ==

  const onClick = () => setIsModalOpen(true);
  const onOk = () => upload();
  const onCancel = () => {
    setFileList([]);
    setIsModalOpen(false);
  };
  const beforeUpload = (file) => {
    setFileList([file]);
    return false;
  };
  const onRemove = (file) => {
    const index = fileList.indexOf(file);
    const newFileList = fileList.slice();
    newFileList.splice(index, 1);
    setFileList(newFileList);
  };

  const disabled = loading || fileList.length === 0;

  return (
    <>
      <Button icon={<UploadOutlined />} onClick={onClick} {...props}>
        {onlyIcon ? null : t("admin.common.upload")}
      </Button>
      <Modal
        title={t("admin.common.upload")}
        open={isModalOpen}
        confirmLoading={loading}
        okText={t("admin.common.upload")}
        okButtonProps={{
          icon: <UploadOutlined />,
          disabled,
        }}
        cancelText={t("admin.common.cancel")}
        onOk={onOk}
        onCancel={onCancel}
      >
        <>
          <Space direction="vertical">
            <Text style={styles.message}>
              {t("admin.component.uploadBannedPhraseButton.message")}
            </Text>
          </Space>
          <Divider />
          <Space direction="vertical" size="large">
            <Upload
              fileList={fileList}
              beforeUpload={beforeUpload}
              onRemove={onRemove}
              accept="text/csv"
            >
              <Button icon={<UploadOutlined />}>
                {t("admin.component.uploadBannedPhraseButton.selectFile")}
              </Button>
            </Upload>
          </Space>
        </>
      </Modal>
    </>
  );
};

// ==

const MUTATION = gql`
  mutation uploadBanedPhrasesForUploadBannedPhrasesButton($file: Upload!) {
    uploadBannedPhrases(file: $file) {
      ok
      errors {
        ...MutationErrorFields
      }
    }
  }
  ${MUTATION_ERROR_FRAGMENT}
`;

// ==

const styles = {
  message: {
    whiteSpace: "pre-line",
  },
};
