import { getByPath } from "@clickbar/dot-diver";
import type { GetPathValue, Path, SearchableObject } from "@clickbar/dot-diver";
import camelCase from "camelcase";
import dayjs, { type Dayjs } from "dayjs";
import { isNil } from "es-toolkit";
import { without } from "es-toolkit/array";
import { castArray, isArray, zipObjectDeep } from "es-toolkit/compat";
import { isFunction, isNull, isUndefined } from "es-toolkit/predicate";
import { pipe } from "funcom";
import type { ArrayOrSingle, Primitive } from "ts-essentials";

import type { EmptyObject, RecordType } from "@/types/primitives";
import type { NoNil, PathToObject } from "@/types/utils";

import config from "../config";

// Checker

export {
  isBoolean,
  isString,
  isFunction,
  isUndefined,
  isNull,
  isNil as isNullOrUndefined,
} from "es-toolkit/predicate";
export { isArray, isNumber } from "es-toolkit/compat";
export const isObject = (val: any): val is Record<string, any> =>
  typeof val === "object" && !Array.isArray(val) && val !== null;
export const isDefined = (val: any) => typeof val !== "undefined";
export const isEmptyString = (val: any) => val === "";
export const isEmptyArray = (val: any): val is [] => isArray(val) && !val.length;
export const isEmptyObject = (val: any): val is EmptyObject =>
  isObject(val) && Object.keys(val).length === 0;
export const isEmpty = (val: any) =>
  isUndefined(val) ||
  isNull(val) ||
  isEmptyString(val) ||
  isEmptyArray(val) ||
  isEmptyObject(val);

// Format

export const formatNumber = (value: number | bigint | Intl.StringNumericLiteral) =>
  new Intl.NumberFormat().format(value);
export const formatDuration = (value: number) => {
  const hours = Math.floor(value / 3600);
  const minutes = Math.floor((value % 3600) / 60);
  const seconds = Math.floor(value % 60);

  const formattedMinutes = String(minutes).padStart(2, "0");
  const formattedSeconds = String(seconds).padStart(2, "0");

  if (hours > 0) {
    const formattedHours = String(hours).padStart(2, "0");
    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
  } else {
    return `${formattedMinutes}:${formattedSeconds}`;
  }
};

// Case Conversion

export const toCamelCase = camelCase;
export const camelToHyphenCase = (val: string) =>
  val.replace(/[A-Z]/g, (letter) => `-${letter.toLowerCase()}`);
export const camelToSnakeCase = (val: string) =>
  val.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);
export const snakeToTitleCase = (val: string) =>
  val
    .split(/_+/)
    .map((word) => word[0].toUpperCase() + word.slice(1).toLowerCase())
    .join("");
export const camelToTitleCase = (val: string) =>
  snakeToTitleCase(camelToSnakeCase(val));
export const snakeToHumanCase = (val: string) =>
  val
    .split(/_+/)
    .map((word) => word[0].toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");
export const camelToHumanCase = (val: string) =>
  snakeToHumanCase(camelToSnakeCase(val));
export const capitalize = (value?: string | null) =>
  !value || typeof value !== "string"
    ? ""
    : value.charAt(0).toUpperCase() + value.slice(1);

// Comparision

export { isEqual as deepEqual } from "es-toolkit";
export const arrayEquals = (arr1: Array<any>, arr2: Array<any>) =>
  arr1.length == arr2.length && arr1.every((e, i) => e == arr2[i]);

// Conversion

export const stringToBoolean = (val: string) =>
  val === "true" ? true : val === "false" ? false : null;
export const booleanToString = (val: boolean): "true" | "false" | "" =>
  val === true ? "true" : val === false ? "false" : "";

// Url

export { stringify as urlQueryStringify, parse as urlQueryParse } from "qs";
export const normalizeUrl = (url: URL | string) => {
  return url ? new URL(url, config.graphqlEndpoint).toString() : null;
};
export const getRelativeUrl = (url: URL | string | undefined = undefined) => {
  const u = new URL(url ?? window.location.href, window.location.origin);
  return [u.pathname, u.search].join("");
};
export const getSearchParam = (key: string, url = undefined) =>
  new URL(url ?? window.location.href, window.location.origin).searchParams.get(key);
export const addSearchParam = (
  key: string,
  value: string,
  url: URL | string | undefined = undefined,
) => {
  const u = new URL(url ?? window.location.href, window.location.origin);
  u.searchParams.append(key, value);
  return u.href;
};
export const extractFileName = (url: string) => {
  const value = url.split("/").pop() ?? "";
  return decodeURIComponent(value);
};

// String

export const truncateString = (
  str: string,
  length: number = 50,
  ending: string = "...",
) => (str.length > length ? str.substring(0, length - ending.length) + ending : str);

// Array

export {
  // prettier-ignore
  groupBy,
  keyBy,
  mapValues,
  omit,
  uniq,
  zip,
  zipObject,
  unzip,
} from "es-toolkit";
export { clsx } from "clsx";
export { castArray, compact, zipObjectDeep } from "es-toolkit/compat";
export { without } from "es-toolkit/array";
/** Removes null or undefined values (null, undefined) from an array. */
export function withoutNil<T>(arr: readonly T[]) {
  return without(arr, null, undefined) as Array<NoNil<T>>;
}
/** Casts value as an array if it's not one without nil elements */
export const arrayify = pipe(castArray, withoutNil);

// Object

export {
  // prettier-ignore
  cloneDeep as deepCopy,
  invert,
  merge,
  pick,
  pickBy,
} from "es-toolkit";
export { getByPath } from "@clickbar/dot-diver";
export type {
  Path,
  SearchableObject,
  GetPathValue,
  PathValue,
} from "@clickbar/dot-diver";

/**
 * Return object with paths
 * @example objectWithPaths({ a: { b: 1, e: 4 }, c: 2, d: 3 }, "a.b") => { a: { b: 1 }}
 * @example objectWithPaths({ a: { b: 1, e: 4 }, c: 2, d: 3 }, "c") => { c: 2 }
 * @example objectWithPaths({ a: { b: 1, e: 4 }, c: 2, d: 3 }, ["a", "d"]) => { a: { b: 1, e: 4 }, d: 3 }
 * @example objectWithPaths({ a: { b: 1, e: 4 }, c: 2, d: 3 }, ["a.e", "d"]) => { a: { e: 4 }, d: 3 }
 */
export function objectWithPaths<
  T extends SearchableObject,
  P extends ArrayOrSingle<Path<T, P>>,
>(object: T, pathOrPaths: P, normalize: (value: any) => Primitive = (v) => v) {
  const paths = castArray(pathOrPaths);
  return zipObjectDeep(
    paths,
    paths.map((p) => getByPath<RecordType, string>(object, p as string)).map(normalize),
  );
}
/**
 * Set object with paths
 * @example pathToObject("a.b.c", 1) => { a: { b: { c: 1 }}}
 */
export function pathToObject<T, P extends string>(
  path: P,
  value: any,
  normalize: (value: any) => T = (v) => v as T,
): PathToObject<P, T> {
  return zipObjectDeep([path], [normalize(value)]) as PathToObject<P, T>;
}
export const stripDunderKeys = <T>(obj: T): T => {
  if (isArray(obj)) {
    return obj.map((val) => stripDunderKeys(val)) as unknown as T;
  } else if (isObject(obj)) {
    const result = {} as any;
    for (const key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key) && !key.startsWith("__")) {
        result[key] = stripDunderKeys((obj as any)[key]);
      }
    }
    return result as T;
  }
  return obj;
};
export const stripEmptyValues = <T>(obj: T): T => {
  if (isArray(obj)) {
    return obj.map((val) => stripEmptyValues(val)) as unknown as T;
  } else if (isObject(obj)) {
    const newObj = {} as any;
    for (const key in obj) {
      if (!isEmpty(obj[key])) {
        newObj[key] = stripEmptyValues(obj[key]);
      }
    }
    return newObj as T;
  }
  return obj;
};
export const getSafeValue = <
  T extends SearchableObject,
  P extends Path<T, P, { depth: 5 }> & string,
  F,
>(
  object: T,
  path: P,
  fallback: F,
): NoNil<GetPathValue<T, P>> | F => {
  const value = getByPath(object, path) as GetPathValue<T, P>;
  if (isNil(value)) return fallback as any;
  return value as any;
};
export const getValueFromJsonString = (key: string, targetStr: string) => {
  if (!targetStr) {
    return undefined;
  }
  const targetObject = JSON.parse(targetStr);
  const camelObject: any = {};
  for (const prop in targetObject) {
    if (Object.prototype.hasOwnProperty.call(targetObject, prop)) {
      camelObject[toCamelCase(prop)] = targetObject[prop];
    }
  }
  const result = camelObject[key];
  return result === "None" ? undefined : result;
};

// Function

export { debounce } from "es-toolkit";
export { pipe, pipeAsync } from "funcom";
export function resolveCallable<V>(
  callable: V | ((...args: any[]) => V),
  ...args: any[]
): V;
export function resolveCallable(callable: undefined, ...args: any[]): undefined;
export function resolveCallable(callable: any, ...args: any[]): any {
  return isFunction(callable) ? callable(...args) : callable;
}

// Datetime
export function toDay(date?: string | null) {
  return date ? dayjs(date) : null;
}
export function fromDay(date?: Dayjs | null) {
  return date ? date.toISOString() : null;
}

// Debug

let idCounter = 0;
const objectIds = new WeakMap();

export function getObjectId(obj) {
  if (isNil(obj)) return null;
  if (!objectIds.has(obj)) {
    objectIds.set(obj, ++idCounter);
  }
  return objectIds.get(obj);
}
