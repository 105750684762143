import { forwardRef } from "react";

import { gql } from "@apollo/client";

import { Typography } from "antd";

import { ObjectSelect } from "../fields";

export const SelectPoll = forwardRef((props, ref) => (
  <ObjectSelect
    resource="poll"
    query={QUERY}
    optionRender={(poll) => (
      <>
        {poll.title}
        <Typography.Text type="secondary" style={styles.secondary}>
          {poll.member.nickname}
        </Typography.Text>
      </>
    )}
    ref={ref}
    {...props}
  />
));

// ==

const QUERY = gql`
  query pollsForSelectPoll($filter: PollFilterInput) {
    polls(filter: $filter, sort: [{ type: RELEVANCE, direction: DESC }]) {
      objects {
        id
        title
        member {
          id
          nickname
        }
      }
    }
  }
`;

// ==

const styles = {
  secondary: {
    marginLeft: 8,
    fontSize: "0.8em",
  },
};
