import { useState } from "react";

import { Space } from "antd";

import { SortableTable } from "../../components/list";

export const LanguageOrderEditor = ({ value, updateData }) => {
  const [languages, setLanguages] = useState(value || []);

  const onChange = (e) => {
    let newLanguages = [];
    if (e.length === 0) {
      window.alert("Please select at least one language");
      return;
    }
    for (const [_, language] of value.entries()) {
      const idx = e.findIndex((item) => item.name === language.name);
      const newLanguage = {
        ...language,
        order: idx >= 0 ? idx + 1 : null,
      };
      newLanguages.push(newLanguage);
    }
    setLanguages(newLanguages.sort((a, b) => a.order - b.order));
    updateData(newLanguages);
  };

  return (
    <Space direction="vertical" style={styles.container}>
      <SortableTable
        resource="language"
        fields={[
          {
            name: "order",
          },
          {
            name: "code",
          },
          {
            name: "name",
          },
          {
            name: "nativeName",
          },
        ]}
        items={languages.filter((l) => l.order !== null)}
        onChange={(items) => onChange?.(items)}
        pagination={false}
        rowKey="id"
        size="small"
      />
    </Space>
  );
};

// ==

const styles = {
  container: {
    width: "100%",
  },
};
