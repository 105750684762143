import { gql } from "@apollo/client";

import { ListPage, ListPageWithGroup } from "../../components/pages";

export const HashtagList = () => (
  <ListPageWithGroup
    groupFilterKey="group_Overlap"
    resource="hashtag"
    subtitleKey="admin.page.hashtag.subtitle"
    tabs={[
      {
        path: "",
        labelKey: "admin.page.hashtag.tabAll",
        tableProps: {
          query: QUERY,
          fields: [
            {
              name: "id",
            },
            {
              name: "group",
              type: "object",
              resource: "group",
              filterKey: "group_Overlap",
            },
            {
              name: "name",
              filterKey: "name_Exact",
            },
            {
              name: "isHighlighted",
              type: "boolean",
            },
          ],
        },
      },
      {
        path: "highlighted",
        labelKey: "admin.page.hashtag.tabHighlighted",
        tableProps: {
          query: QUERY,
          baseFilter: {
            isHighlighted_Exact: true,
          },
          fields: [
            {
              name: "id",
            },
            {
              name: "group",
              type: "object",
              resource: "group",
              filterKey: "group_Overlap",
            },
            {
              name: "name",
              filterKey: "name_Text",
              sortKey: "NAME",
            },
            {
              name: "isHighlighted",
              type: "boolean",
            },
          ],
        },
      },
    ]}
    query={QUERY}
    extraRowActions={ListPage.extraRowActions.canActivate}
    hideCreate
  />
);

// ==

const QUERY = gql`
  query hashtagsForHashtagList(
    $filter: HashtagFilterInput
    $sort: [HashtagSortInput]
    $page: PageInput
  ) {
    hashtags(filter: $filter, sort: $sort, page: $page) {
      objects {
        id
        group {
          id
          name
        }
        name
        isHighlighted
        ...TrackedFields
        ...CanActivateFields
      }
      ...ListFields
    }
  }
  ${ListPage.fragments.list}
  ${ListPage.fragments.tracked}
  ${ListPage.fragments.canActivate}
`;
