import { forwardRef } from "react";

import { gql } from "@apollo/client";

import { ObjectSelect } from "../fields";

export const SelectEventV2PenaltyCategory = forwardRef((props, ref) => {
  return (
    <ObjectSelect
      resource="eventV2PenaltyCategory"
      query={QUERY}
      optionRender={(penaltyCategory) => <>{penaltyCategory.name}</>}
      ref={ref}
      {...props}
    />
  );
});

// ==

const QUERY = gql`
  query eventV2PenaltyCategoriesForSelectEventV2PenaltyCategory(
    $filter: EventV2PenaltyCategoryFilterInput
  ) {
    eventV2PenaltyCategories(
      filter: $filter
      sort: [{ type: RELEVANCE, direction: DESC }]
    ) {
      objects {
        id
        name
      }
    }
  }
`;
