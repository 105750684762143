import { gql } from "@apollo/client";

import { ListWidget } from "../list";

export const UserListWidget = (props) => (
  <ListWidget
    resource="user"
    fields={[
      {
        name: "id",
      },
      {
        name: "email",
        type: "email",
        filterKey: "email_Exact",
      },
      {
        name: "phone",
        type: "phone",
        filterKey: "phone_Exact",
      },
      {
        name: "name",
        filterKey: "name_Text",
      },
      {
        name: "birthDate",
        type: "date",
        sortKey: "BIRTH_DATE",
      },
      {
        name: "country",
        type: "object",
        resource: "country",
        filterKey: "country_Overlap",
      },
      {
        name: "isStaff",
        type: "boolean",
        filterKey: "isStaff_Exact",
      },
      {
        name: "isArtist",
        type: "boolean",
        filterKey: "isArtist_Exact",
      },
      {
        name: "memberCount",
        type: "integer",
        filterKey: "memberCount_Range",
        sortKey: "MEMBER_COUNT",
      },
      {
        name: "status",
        type: "choice",
        enumType: "UserStatus",
        filterKey: "status_Overlap",
      },
      {
        name: "lastAuthenticatedAt",
        labelKey: "admin.field.lastAuthenticatedAt",
        type: "datetime",
        sortKey: "LAST_AUTHENTICATED_AT",
      },
      {
        name: "createdAt",
        labelKey: "admin.field.joinedAt",
        type: "datetime",
        sortKey: "CREATED_AT",
      },
    ]}
    query={QUERY}
    extraRowActions={ListWidget.extraRowActions.canActivate}
    {...props}
  />
);

// ==

const QUERY = gql`
  query usersForUserListWidget(
    $filter: UserFilterInput
    $sort: [UserSortInput]
    $page: PageInput
  ) {
    users(filter: $filter, sort: $sort, page: $page) {
      objects {
        id
        email
        phone
        name
        birthDate
        country {
          id
          name
        }
        isStaff
        isArtist
        memberCount
        status
        lastAuthenticatedAt
        ...TrackedFields
        ...CanActivateFields
      }
      ...ListFields
    }
  }
  ${ListWidget.fragments.list}
  ${ListWidget.fragments.tracked}
  ${ListWidget.fragments.canActivate}
`;
