import { ComponentProps } from "react";

import config from "@/config";

import { ListTable } from "./ListTable";

export const ListWidget = (props: ComponentProps<typeof ListTable>) => {
  return (
    <ListTable
      size={config.defaultWidgetPageSize}
      globalSearch={false}
      hideList={false}
      resourceActionButtonType="text"
      resourceCreateOptions={{
        // `createUrl` and `createSearchParams` are deprecated, but downward compatibility is kept for now
        overrideUrl: props.createUrl,
        additionalSearchParams: props.createSearchParams,
        ...props.resourceCreateOptions,
      }}
      layout={{
        count: { span: 0 },
        filterControl: { order: 1, className: "flex justify-start reverse" },
        action: { order: 2, flex: 1 },
        filterForm: { order: 4, span: 24 },
        table: { order: 5, span: 24 },
        paginator: { order: 3, className: "flex justify-end" },
      }}
      {...props}
    />
  );
};

// ==

ListWidget.fragments = ListTable.fragments;
ListWidget.fields = ListTable.fields;
ListWidget.extraRowActions = ListTable.extraRowActions;
