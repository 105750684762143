import { gql } from "@apollo/client";

import { ListPage, ListPageWithGroup } from "../../components/pages";

export const VenueList = () => (
  <ListPageWithGroup
    groupFilterKey="group_Overlap"
    resource="venue"
    fields={[
      { name: "id" },
      {
        name: "group",
        type: "object",
        resource: "group",
        filterKey: "group_Overlap",
      },
      {
        name: "title",
        truncate: true,
      },
      {
        name: "boothCount",
        type: "integer",
      },
      ...ListPage.fields.tracked,
      ...ListPage.fields.canActivate,
    ]}
    query={QUERY}
    extraRowActions={ListPage.extraRowActions.canActivate}
  />
);

// ==

const QUERY = gql`
  query venuesForVenueList(
    $filter: VenueFilterInput
    $sort: [VenueSortInput]
    $page: PageInput
  ) {
    venues(filter: $filter, sort: $sort, page: $page) {
      objects {
        id
        title
        group {
          id
          name
        }
        boothCount
        ...TrackedFields
        ...CanActivateFields
      }
      ...ListFields
    }
  }
  ${ListPage.fragments.list}
  ${ListPage.fragments.tracked}
  ${ListPage.fragments.canActivate}
`;
