import { forwardRef } from "react";

import { gql } from "@apollo/client";

import { Typography } from "antd";

import { ObjectSelect } from "../fields";

export const SelectCompany = forwardRef((props, ref) => (
  <ObjectSelect
    resource="company"
    query={QUERY}
    optionRender={(company) => (
      <>
        {company.name}
        <Typography.Text type="secondary" style={styles.secondary}>
          {company.code}
        </Typography.Text>
      </>
    )}
    ref={ref}
    {...props}
  />
));

// ==

const QUERY = gql`
  query companiesForSelectCompany($filter: CompanyFilterInput) {
    companies(filter: $filter, sort: [{ type: RELEVANCE, direction: DESC }]) {
      objects {
        id
        code
        name
      }
    }
  }
`;

// ==

const styles = {
  secondary: {
    marginLeft: 8,
    fontSize: "0.8em",
  },
};
