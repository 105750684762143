import { forwardRef } from "react";

import { gql } from "@apollo/client";

import { ObjectSelect } from "../fields";

export const SelectVenue = forwardRef((props, ref) => (
  <ObjectSelect
    resource="venue"
    query={QUERY}
    optionRender={(venue) => <>{venue.title}</>}
    ref={ref}
    {...props}
  />
));

// ==

const QUERY = gql`
  query venuesForVenueSelect($filter: VenueFilterInput) {
    venues(filter: $filter, sort: [{ type: RELEVANCE, direction: DESC }]) {
      objects {
        id
        title
      }
    }
  }
`;
