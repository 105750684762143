import { useTranslation } from "react-i18next";

import { gql, useLazyQuery } from "@apollo/client";

import {
  PlaylistEntryEditor,
  YoutubePlaylistVideoViewer,
} from "../../components/fields";
import { FormPage } from "../../components/pages";
import { useApp } from "../../hooks/app";

export const PlaylistForm = () => {
  const { t } = useTranslation();
  const { message } = useApp();
  const [fetchYoutubePlaylistMeta, { loading }] = useLazyQuery(
    YOUTUBE_PLAYLIST_META_QUERY,
  );

  return (
    <FormPage
      resource="playlist"
      fields={[
        {
          type: "fieldset",
          fields: [
            {
              name: "group",
              type: "object",
              resource: "group",
              disabled: ({ isUpdate }) => isUpdate,
              onChange: ({ resetField }) => resetField("member"),
            },
            {
              name: "category",
              type: "choice",
              enumType: "PlaylistCategory",
              disabled: ({ isUpdate }) => isUpdate,
              onChange: ({ value, resetField }) => {
                switch (value) {
                  case "CUSTOM":
                    resetField("youtubePlaylistUrl");
                    break;
                }
              },
            },
            {
              name: "youtubePlaylistUrl",
              type: "url",
              hidden: ({ data }) => Boolean(data.category !== "YOUTUBE"),
              disabled: ({ isUpdate }) => isUpdate || loading,
              help: ({ isUpdate, data }) =>
                isUpdate &&
                data.youtubePlaylistUrl &&
                t("admin.page.playlist.formYoutubePlaylistUrlHelp"),
              onChange: async ({ value, setValue }) => {
                const url = value.target.value;

                message.open({
                  type: "loading",
                  content: t("admin.message.fetchingYoutube"),
                  duration: 0,
                });

                try {
                  const response = await fetchYoutubePlaylistMeta({
                    variables: { url },
                  });
                  const { title, videos } = response.data.youtubePlaylistMeta;

                  setValue("name", title);
                  setValue("youtubeVideos", videos);

                  message.destroy();
                  message.success(t("admin.message.fetchYoutubeSuccess"));
                } catch (err) {
                  message.destroy();
                  message.error(
                    t("admin.message.fetchYoutubeFailure", {
                      message: err.message,
                    }),
                  );
                }
              },
            },
          ],
        },
        {
          type: "fieldset",
          labelKey: "admin.fieldset.content",
          fields: [
            {
              name: "name",
            },
          ],
        },
        {
          type: "fieldset",
          labelKey: "admin.fieldset.clips",
          hidden: ({ data }) => Boolean(data.category !== "CUSTOM"),
          fields: [
            {
              name: "entries",
              label: "",
              normalizer: (value) => ({
                entries: value?.map((entry, i) => ({
                  ...entry,
                  clip: entry.clip.id,
                  order: i + 1,
                })),
              }),
              render: ({ controllerField, data }) => (
                <PlaylistEntryEditor
                  {...controllerField}
                  baseFilter={{
                    group_Overlap: data.group ? [data.group] : undefined,
                  }}
                  disabled={data.category !== "CUSTOM" || !data.group}
                />
              ),
            },
          ],
        },
        {
          type: "fieldset",
          labelKey: "admin.fieldset.clips",
          hidden: ({ data }) => !data.youtubeVideos,
          fields: [
            {
              name: "youtubeVideos",
              label: "",
              readOnly: true,
              render: ({ controllerField: { value } }) => (
                <YoutubePlaylistVideoViewer videos={value} />
              ),
            },
          ],
        },
      ]}
      query={QUERY}
      createMutation={CREATE_MUTATION}
      updateMutation={UPDATE_MUTATION}
    />
  );
};

// ==

const FORM_FIELDS_FRAGMENT = gql`
  fragment PlaylistFormFields on Playlist {
    id
    group {
      id
    }
    category
    name
    youtubePlaylistUrl
    entries {
      id
      clip {
        id
        ...PlaylistEntryEditorClipFields
      }
      order
    }
  }
  ${PlaylistEntryEditor.fragments.clip}
`;

const QUERY = gql`
  query playlistForPlaylistForm($id: ID!) {
    playlist(filter: { id_Overlap: [$id] }) {
      ...PlaylistFormFields
    }
  }
  ${FORM_FIELDS_FRAGMENT}
`;

const CREATE_MUTATION = gql`
  mutation createPlaylistForPlaylistForm($data: PlaylistMutationInput!) {
    createPlaylist(data: $data) {
      ok
      errors {
        ...MutationErrorFields
      }
      object {
        ...PlaylistFormFields
      }
    }
  }
  ${FormPage.fragments.mutationError}
  ${FORM_FIELDS_FRAGMENT}
`;

const UPDATE_MUTATION = gql`
  mutation updatePlaylistForPlaylistForm($data: PlaylistMutationInput!, $id: ID!) {
    updatePlaylist(data: $data, id: $id, partial: true) {
      ok
      errors {
        ...MutationErrorFields
      }
      object {
        ...PlaylistFormFields
      }
    }
  }
  ${FormPage.fragments.mutationError}
  ${FORM_FIELDS_FRAGMENT}
`;

const YOUTUBE_PLAYLIST_META_QUERY = gql`
  query youtubePlaylistMeta($url: String!) {
    youtubePlaylistMeta(url: $url) {
      id
      title
      thumbnailUrl
      videos {
        id
        title
        description
        duration
        thumbnailUrl
      }
    }
  }
`;
