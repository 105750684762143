import { gql } from "@apollo/client";

import { FormPage } from "../../components/pages";

export const ContinentForm = () => (
  <FormPage
    resource="continent"
    fields={[
      {
        name: "code",
        helpKey: "admin.form.guide.codeContinent",
      },
      {
        name: "name",
      },
    ]}
    query={QUERY}
    createMutation={CREATE_MUTATION}
    updateMutation={UPDATE_MUTATION}
  />
);

// ==

const FORM_FIELDS_FRAGMENT = gql`
  fragment ContinentFormFields on Continent {
    id
    code
    name
  }
`;

const QUERY = gql`
  query continentForContinentForm($id: ID!) {
    continent(filter: { id_Overlap: [$id] }) {
      ...ContinentFormFields
    }
  }
  ${FORM_FIELDS_FRAGMENT}
`;

const CREATE_MUTATION = gql`
  mutation createContinentForContinentForm($data: ContinentMutationInput!) {
    createContinent(data: $data) {
      ok
      errors {
        ...MutationErrorFields
      }
      object {
        ...ContinentFormFields
      }
    }
  }
  ${FormPage.fragments.mutationError}
  ${FORM_FIELDS_FRAGMENT}
`;

const UPDATE_MUTATION = gql`
  mutation updateContinentForContinentForm($data: ContinentMutationInput!, $id: ID!) {
    updateContinent(data: $data, id: $id, partial: true) {
      ok
      errors {
        ...MutationErrorFields
      }
      object {
        ...ContinentFormFields
      }
    }
  }
  ${FormPage.fragments.mutationError}
  ${FORM_FIELDS_FRAGMENT}
`;
