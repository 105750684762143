import { Tag } from "antd";

import { useEnum } from "../../hooks/enum";
import { isEmpty } from "../../utils/helpers";

export const EnumField = ({ type, value, skeleton = "-", color = undefined }) => {
  const { values } = useEnum(type);

  const map = values && Object.fromEntries(values.map((e) => [e.name, e.description]));

  if (isEmpty(value)) {
    return null;
  }

  return <Tag color={color ?? colors[value]}>{map?.[value] ?? skeleton}</Tag>;
};

// ==

const colors = {
  ACTIVE: "green",
  OPEN: "green",
  OPENED: "green",
  RUNNING: "green",
  WIN: "green",
  WON: "green",
  EXCLUDED: "red",
  ABSENT: "red",
  ATTENDED: "green",
  // ==
  INACTIVE: "red",
  SUSPENDED: "red",
  BAN: "red",
  INSTANT: "red",
  // ==
  DELETED: "gold",
  SPARE: "gold",
  SCHEDULED: "gold",
  WAITLISTED: "gold",
  // ==
  UPCOMING: "blue",
  DELAYED: "blue",
  // ==
  RESTRICT: "orange",
  // ==
  REGISTERED: "purple",
  CALLED: "green",
  CANCELLED: "orange",
  NO_SHOW: "red",
  ENTERED: "blue",
  // ==
  READY: "orange",
  NOT_REQUIRED: "purple",
  // ==
  SUBMITTED: "green",
  IN_PROGRESS: "blue",
  AWAITING_REVIEW: "red",
  CLOSED: "purple",
  REJECTED: "volcano",
  // ==
  // OPENED: "green",
  ASSIGNED: "blue",
  TRANSLATING: "geekblue",
  UNDER_REVIEW: "cyan",
  REVIEWED: "orange",
  COMPLETED: "gold",
  // CLOSED: "dark",
  // REJECTED: "purple",
};
