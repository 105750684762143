import React, { forwardRef, useCallback, useContext, useMemo } from "react";

import { Input, InputRef } from "antd";

import { FieldsetContext } from "@/components/fields/context";
import { useFormContext } from "@/components/form/context";
import { LanguageTab } from "@/domain/language/components/LanguageTab";
import type { ServerEnum } from "@/hooks/enum";
import type { TranslatableField } from "@/types/field";
import type { FieldProps } from "@/types/form";
import { isString, keyBy, mapValues, resolveCallable } from "@/utils/helpers";

import "../../styles/MarkdownEditor.css";
import { MarkdownEditor } from "./MarkdownEditor";
import { HTMLEditor } from "./HTMLEditor";

type ChangeEvent = string | React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>;

export const TranslatableContentEditor = forwardRef<
  InputRef,
  FieldProps<TranslatableField>
>(
  (
    {
      value,
      onChange,
      rows = undefined,
      markdown = false,
      html = false,
      hidden: _,
      readOnly: __,
      disabled: resolvableDisabled,
      placeholder: resolvablePlaceholder,
      ...props
    },
    ref,
  ) => {
    const { data, editingLanguage, setEditingLanguage } = useFormContext();
    const fieldsetContext = useContext(FieldsetContext);
    const valueByLanguage = useMemo(
      () =>
        mapValues(
          keyBy(value?.values ?? [], (v) => v.language),
          (values) => values.value,
        ),
      [value],
    );
    const handleChange = useCallback(
      (language: string) => (e: ChangeEvent) => {
        const newValue = isString(e) ? e : e.target.value;
        const newValueMap = { ...valueByLanguage, [language]: newValue };
        onChange?.({
          ...value,
          values: Object.entries(newValueMap).map(([language, value]) => ({
            language,
            value,
          })),
        });
      },
      [valueByLanguage, onChange],
    );
    const disabled = resolveCallable(resolvableDisabled, { data });
    const placeholder = resolveCallable(resolvablePlaceholder, { data });
    const render = useCallback(
      (language: ServerEnum) => {
        const commonProps = {
          onChange: handleChange(language.name),
          value: valueByLanguage[language.name],
          disabled,
          placeholder,
          ...props,
          ref,
        };
        return markdown ? (
          <MarkdownEditor {...commonProps} />
        ) : html ? (
          <HTMLEditor {...commonProps} />
        ) : rows ? (
          <Input.TextArea rows={rows} {...commonProps} />
        ) : (
          <Input {...commonProps} />
        );
      },
      [valueByLanguage, handleChange],
    );

    return (
      <LanguageTab
        hideTab={fieldsetContext?.hideLanguageSelect}
        activeKey={editingLanguage}
        onChangeActiveKey={setEditingLanguage}
        render={render}
      />
    );
  },
);
