import { useMemo } from "react";

import type { BuiltField, FieldManipulator } from "@/types/field";
import { isContainerField } from "@/utils/field";

function applyFilter(
  fields: BuiltField[],
  filter: (field: BuiltField) => boolean,
): BuiltField[] {
  const filteredFields = fields.filter(filter);
  return filteredFields.map((field) => ({
    ...field,
    ...(isContainerField(field) ? { fields: applyFilter(field.fields, filter) } : {}),
  }));
}

function applyManipulator(
  field: BuiltField,
  manipulator: FieldManipulator,
): BuiltField {
  return {
    ...manipulator(field),
    ...(isContainerField(field)
      ? { fields: field.fields.map((f) => applyManipulator(f, manipulator)) }
      : {}),
  };
}

/**
 * Manipulate fields if fields and manipulators are ready (not null).
 */
export function useManipulatedFields(
  fields: BuiltField[] | null,
  options: {
    /** recursively manipulate field. if null, manipulator is not ready */
    manipulator?: FieldManipulator | null;
    /** recursively filter field. if null, filter is not ready */
    filter?: ((field: BuiltField) => boolean) | null;
  },
): BuiltField[] | null {
  const { manipulator, filter } = options;
  return useMemo(() => {
    let examinedFields = fields;
    if (!fields) return null;
    if (manipulator === null || filter === null) return null;
    if (filter !== undefined) {
      examinedFields = applyFilter(fields, filter);
    }
    if (manipulator !== undefined) {
      examinedFields = fields.map((f) => applyManipulator(f, manipulator));
    }
    return examinedFields;
  }, [fields, manipulator, filter]);
}
