import { gql } from "@apollo/client";

import { DetailPage } from "../../components/pages";
import { CommentListWidget } from "../../components/widgets";
import { toCamelCase } from "../../utils/helpers";

export const CommentDetail = (props) => (
  <DetailPage
    resource="comment"
    fields={[
      {
        name: "id",
      },
      {
        name: "group",
        type: "object",
        resource: "group",
      },
      {
        name: "body",
        translatable: true,
      },
      {
        name: "member",
        labelKey: "admin.field.nickname",
        type: "object",
        resource: "member",
      },
      {
        name: "isArtist",
        type: "boolean",
        getter: (obj) => Boolean(obj.member.artist),
      },
      {
        name: "targetType",
        type: "choice",
        enumType: "ModelDocumentType",
        getter: (obj) => obj.target.type,
      },
      {
        name: "target",
        type: "object",
        resource: ({ obj }) => toCamelCase(obj.target.type.toLowerCase()),
      },
      {
        name: "category",
        type: "choice",
        enumType: "CommentCategory",
      },
      {
        name: "descendantCount",
        type: "integer",
      },
      {
        name: "artistDescendantCount",
        type: "integer",
      },
      {
        name: "likeCount",
        type: "integer",
      },
      {
        name: "status",
        type: "choice",
        enumType: "CommentStatus",
        filterKey: "status_Overlap",
      },
      ...DetailPage.fields.canHide,
      ...DetailPage.fields.canActivate,
      ...DetailPage.fields.tracked,
    ]}
    query={QUERY}
    extraActions={(props) => (
      <>
        {DetailPage.extraActions.canActivate(props)}
        {DetailPage.extraActions.canHide(props)}
      </>
    )}
    tabs={[
      {
        path: "",
        labelKey: "admin.common.replies",
        permission: {
          action: "list",
          targetType: "COMMENT",
        },
        children: ({ obj }) => (
          <CommentListWidget
            baseFilter={{
              group_Overlap: [obj.group.id],
              targetType_Overlap: [obj.target.type],
              targetId_Overlap: [obj.target.id],
              parent_Overlap: [obj.id],
            }}
            createSearchParams={{
              group: obj.group.id,
              targetType: obj.target.type,
              targetId: obj.target.id,
              parent: obj.id,
            }}
            hiddenFields={["group", "targetType", "target", "parent"]}
          />
        ),
      },
      // {
      //   path: "likes",
      //   labelKey: "admin.resource.like.plural",
      //   permission: {
      //     action: "list",
      //     targetType: "LIKE",
      //   },
      //   children: ({ obj }) => (
      //     <LikeListWidget
      //       baseFilter={{
      //         targetType_Overlap: ["COMMENT"],
      //         targetId_Overlap: [obj.id],
      //       }}
      //       hiddenFields={["targetType", "target"]}
      //     />
      //   ),
      // },
    ]}
    hideUpdate
    {...props}
  />
);

// ==

const QUERY = gql`
  query commentForCommentDetail($id: ID!) {
    comment(filter: { id_Overlap: [$id] }) {
      id
      group {
        id
        name
      }
      body
      member {
        id
        nickname
        artist {
          id
        }
      }
      target {
        id
        type
        ... on Post {
          category
        }
      }
      category
      descendantCount
      artistDescendantCount
      likeCount
      status
      ...TrackedFields
      ...CanActivateFields
      ...CanHideFields
    }
  }
  ${DetailPage.fragments.tracked}
  ${DetailPage.fragments.canActivate}
  ${DetailPage.fragments.canHide}
`;
