import { createContext } from "react";

import dayjs from "dayjs";

import { default as nestedTimezones } from "../data/timezones.json";
import { useLocalStorage } from "../hooks/localStorage";

export const TimezoneContext = createContext();

export const TimezoneProvider = (props) => {
  const defaultTimezone = dayjs.tz.guess();
  const [timezone, setTimezone] = useLocalStorage("timezone", defaultTimezone);
  const details = timezones[timezone];

  return (
    <TimezoneContext.Provider value={{ timezone, setTimezone, details }} {...props} />
  );
};

// ==

const timezones = Object.fromEntries(
  nestedTimezones.reduce((acc, tz) => {
    return acc.concat(
      tz.utc.map((label) => [
        label,
        {
          ...tz,
          label,
        },
      ]),
    );
  }, []),
);
