import { gql } from "@apollo/client";

import { SendBulkEmailButton } from "../../components/actions";
import { DetailPage } from "../../components/pages";
import { Can } from "../../components/permission";

export const TermDetail = () => (
  <DetailPage
    resource="term"
    fields={[
      {
        name: "id",
      },
      {
        name: "titleContent",
        labelKey: "admin.field.title",
        type: "translatable",
      },
      {
        name: "bodyContent",
        labelKey: "admin.field.body",
        type: "translatable",
        html: true,
      },
      {
        name: "appliedOn",
        type: "date",
      },
      {
        name: "status",
        type: "choice",
        enumType: "AgreementStatus",
      },
      ...DetailPage.fields.canActivate,
      ...DetailPage.fields.tracked,
    ]}
    query={QUERY}
    extraActions={({ obj, refetch }) => (
      <>
        <SendBulkEmailButton template="term" onChange={() => refetch()} onlyIcon />

        {DetailPage.extraActions.canActivate}
      </>
    )}
  />
);

// ==

const QUERY = gql`
  query agreementForTermDetail($id: ID!) {
    agreement(filter: { id_Overlap: [$id] }) {
      id
      title
      titleContent {
        ...TranslatableContentFields
      }
      body
      bodyContent {
        ...TranslatableContentFields
      }
      appliedOn
      status
      ...TrackedFields
      ...CanActivateFields
    }
  }
  ${DetailPage.fragments.tracked}
  ${DetailPage.fragments.canActivate}
  ${DetailPage.fragments.translatableContent}
`;
