import { Fragment, useCallback, useMemo } from "react";
import { Link, useNavigate } from "react-router-dom";

import { EditOutlined, FileSearchOutlined } from "@ant-design/icons";
import { Button } from "antd";

import type { ArrayOrSingle } from "ts-essentials";

import { Can } from "@/components/permission";
import { useApp } from "@/hooks/app";
import type { ResourceName } from "@/resources";
import type { ExtraRowActionRenderer, RowActionRenderer } from "@/types/list";
import type { RecordType } from "@/types/primitives";
import { arrayify } from "@/utils/helpers";

export type RowActionOptions<Row extends RecordType> = {
  /** @default false */
  hideRowActions?: boolean;
  /** @default false */
  hideDetail: boolean;
  /**
   * if resource has no formComponent, strict to false
   * @default false
   */
  hideUpdate: boolean;
  /**
   * isomorphic resource (resource that has same `typeName`) based on the row object. detail / update url can be changed
   * @example resource: "post", rowTargetResource: (post: Post) => "fanPost" | "artistPost" | "post"
   * @example resource: "product", rowTargetResource: (product: Product) => "productBundle" | "product"
   */
  rowTargetResource?: (obj: Row) => ResourceName;
  extraRowActions?: ArrayOrSingle<ExtraRowActionRenderer<Row>>;
};

type UseRowActionOptions<Row extends RecordType> = RowActionOptions<Row> & {
  resource: ResourceName;
  refetch: () => void;
};

export const useRowAction = <Row extends RecordType>(
  options: UseRowActionOptions<Row>,
): RowActionRenderer<Row> | null => {
  const {
    hideRowActions = false,
    hideDetail = false,
    hideUpdate = false,
    rowTargetResource,
    refetch,
  } = options;
  const { resources } = useApp();
  const navigate = useNavigate();
  const extraRowActions = useMemo(
    () => arrayify(options.extraRowActions),
    [options.extraRowActions],
  );

  const render = useCallback(
    (row: Row) => {
      const resource = resources[rowTargetResource?.(row) ?? options.resource];
      const hasForm = "formComponent" in resource && resource.formComponent;
      return (
        <>
          {!hideDetail && (
            <Link to={resource.detailUrlGetter(row)}>
              <Button icon={<FileSearchOutlined />} size="small" />
            </Link>
          )}
          {!hideUpdate && hasForm && (
            <Can action="update" targetType={resource.typeName} targetId={row.id}>
              <Link to={resource.updateUrlGetter(row)}>
                <Button icon={<EditOutlined />} size="small" />
              </Link>
            </Can>
          )}
          {extraRowActions.map((extraRowAction, index) => (
            <Fragment key={index}>
              {extraRowAction?.({ obj: row, resource, refetch, navigate })}
            </Fragment>
          ))}
        </>
      );
    },
    [
      extraRowActions,
      hideDetail,
      hideUpdate,
      navigate,
      options.resource,
      refetch,
      resources,
      rowTargetResource,
    ],
  );

  if (hideRowActions) return null;
  return render;
};
