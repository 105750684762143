import React, { PropsWithChildren, useMemo } from "react";

import { useAbilities } from "@/hooks/permission";
import { CanInput } from "@/types/ability";

type Props = PropsWithChildren<
  CanInput & {
    fulfill?: React.ReactNode;
    reject?: React.ReactNode;
  }
>;

export const Can: React.FC<Props> = ({
  children,
  action,
  targetType = undefined,
  targetId = undefined,
  field = undefined,
  fulfill = undefined,
  reject = undefined,
}) => {
  const abilityKeys = useMemo(
    () => [
      {
        action,
        targetType,
        targetId,
        field,
      },
    ],
    [action, targetType, targetId, field],
  );
  const { abilities } = useAbilities(abilityKeys);
  if (abilities?.[0] === true) {
    return fulfill ?? children;
  } else {
    return reject;
  }
};
