import { gql } from "@apollo/client";

import { DetailPage } from "../../components/pages/index.js";

export const PersonalInformationCollectionDetail = () => (
  <DetailPage
    resource="personalInformationCollection"
    fields={[
      {
        name: "id",
      },
      {
        name: "titleContent",
        labelKey: "admin.field.title",
        type: "translatable",
      },
      {
        name: "bodyContent",
        labelKey: "admin.field.body",
        type: "translatable",
        html: true,
      },
      {
        name: "status",
        type: "choice",
        enumType: "AgreementStatus",
      },
      ...DetailPage.fields.canActivate,
      ...DetailPage.fields.tracked,
    ]}
    query={QUERY}
    extraActions={DetailPage.extraActions.canActivate}
  />
);

// ==

const QUERY = gql`
  query agreementForPrivacyDetail($id: ID!) {
    agreement(filter: { id_Overlap: [$id] }) {
      id
      title
      titleContent {
        ...TranslatableContentFields
      }
      body
      bodyContent {
        ...TranslatableContentFields
      }
      appliedOn
      status
      ...TrackedFields
      ...CanActivateFields
    }
  }
  ${DetailPage.fragments.tracked}
  ${DetailPage.fragments.canActivate}
  ${DetailPage.fragments.translatableContent}
`;
