import { gql } from "@apollo/client";

import { ListPage, ListPageWithGroup } from "../../components/pages";

export const NoticeList = () => (
  <ListPageWithGroup
    groupFilterKey="group_Overlap"
    resource="notice"
    subtitleKey="admin.page.notice.subtitle"
    fields={[
      {
        name: "id",
      },
      {
        name: "group",
        type: "object",
        resource: "group",
        filterKey: "group_Overlap",
      },
      {
        name: "title",
        truncate: true,
      },
      {
        name: "isHighlighted",
        type: "boolean",
        filterKey: "isHighlighted_Exact",
      },
      {
        name: "isAd",
        type: "boolean",
        filterKey: "isAd_Exact",
      },
      {
        name: "status",
        type: "choice",
        enumType: "NoticeStatus",
        filterKey: "status_Overlap",
      },
      {
        name: "activateAt",
        type: "datetime",
      },
      ...ListPage.fields.tracked,
    ]}
    query={QUERY}
    defaultSort={[
      {
        type: "IS_HIGHLIGHTED",
        direction: "DESC",
      },
      {
        type: "CREATED_AT",
        direction: "DESC",
      },
    ]}
    extraRowActions={ListPage.extraRowActions.canActivate}
  />
);

// ==

const QUERY = gql`
  query noticesForNoticeList(
    $filter: NoticeFilterInput
    $sort: [NoticeSortInput]
    $page: PageInput
  ) {
    notices(filter: $filter, sort: $sort, page: $page) {
      objects {
        id
        group {
          id
          name
        }
        title
        isHighlighted
        isAd
        status
        ...TrackedFields
        ...CanActivateFields
      }
      ...ListFields
    }
  }
  ${ListPage.fragments.list}
  ${ListPage.fragments.tracked}
  ${ListPage.fragments.canActivate}
`;
