import { useCallback } from "react";

import { type DocumentNode, useApolloClient } from "@apollo/client";

import { type OperationDefinitionNode, visit } from "graphql/language";

/**
 * Invalidate cache and refetch queries if currently watching state that match the given options.
 * @param fields - root field names
 */
export function useRevalidateQueryFields(...fields: string[]) {
  const client = useApolloClient();

  return useCallback(() => {
    const evicted = fields.map(
      (fieldName) =>
        [fieldName, client.cache.evict({ id: "ROOT_QUERY", fieldName })] as const,
    );
    const evictedFieldNames = evicted
      .filter(([_, evicted]) => evicted)
      .map(([fieldName, _]) => fieldName);

    if (evictedFieldNames.length > 0) {
      client.cache.gc();
      client.getObservableQueries().forEach((observableQuery) => {
        if (hasOneOfFieldNames(observableQuery.query, evictedFieldNames)) {
          observableQuery.refetch().then();
        }
      });
    }
  }, [client]);
}

function hasOneOfFieldNames(query: DocumentNode, fieldNames: string[]): boolean {
  let found = false;
  visit(query, {
    OperationDefinition(node: OperationDefinitionNode) {
      if (node.operation !== "query") return;
      const hasFieldName = node.selectionSet.selections
        .filter((selection) => selection.kind === "Field")
        .some((selection) => fieldNames.includes(selection.name.value));
      found = found || hasFieldName;
    },
  });
  return found;
}
