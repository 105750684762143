import { useTranslation } from "react-i18next";

import { gql } from "@apollo/client";

import { MediaLayoutSectionEditor } from "../../components/fields";
import { FormPage } from "../../components/pages";
import { MEDIA_LAYOUT_FRAGMENT } from "../../utils/gql/fragments";

export const MediaLayoutForm = () => {
  const { t } = useTranslation();
  return (
    <FormPage
      resource="mediaLayout"
      fields={[
        {
          type: "fieldset",
          fields: [
            {
              name: "group",
              type: "object",
              resource: "group",
              disabled: ({ isUpdate }) => isUpdate,
            },
            {
              name: "name",
            },
          ],
        },
        {
          type: "fieldset",
          labelKey: "admin.fieldset.sections",
          fields: [
            {
              name: "sections",
              label: "",
              getter: (obj) =>
                obj.sections ?? [
                  {
                    category: "CAROUSEL",
                    source: "LATEST",
                    titleContent: {
                      values: [
                        {
                          language: "ENGLISH",
                          value: "Latest Videos",
                        },
                        {
                          language: "KOREAN",
                          value: "최신 미디어",
                        },
                        {
                          language: "JAPANESE",
                          value: "最新",
                        },
                        {
                          language: "CHINESE",
                          value: "最新",
                        },
                      ],
                    },
                    isDefault: true,
                  },
                ],
              normalizer: (value) => ({
                sections: value?.map(
                  (
                    {
                      titleContent,
                      subtitleContent,
                      playlist,
                      clip,
                      premium,
                      isDefault,
                      ...section
                    },
                    i,
                  ) => ({
                    ...section,
                    titleContent:
                      titleContent?.values?.length > 0
                        ? { values: titleContent.values }
                        : undefined,
                    subtitleContent:
                      subtitleContent?.values?.length > 0
                        ? { values: subtitleContent.values }
                        : undefined,
                    playlist: playlist?.id,
                    clip: clip?.id,
                    premium: premium?.id,
                    order: i + 1,
                  }),
                ),
              }),
              render: ({ controllerField, data }) => (
                <MediaLayoutSectionEditor
                  {...controllerField}
                  baseFilter={{
                    group_Overlap: data.group ? [data.group] : undefined,
                  }}
                />
              ),
            },
          ],
        },
        ...FormPage.fields.canActivate,
      ]}
      query={QUERY}
      createMutation={CREATE_MUTATION}
      updateMutation={UPDATE_MUTATION}
    />
  );
};

// ==

const QUERY = gql`
  query mediaLayoutForMediaLayoutForm($id: ID!) {
    mediaLayout(filter: { id_Overlap: [$id] }) {
      id
      group {
        id
      }
      name
      sections {
        id
        ...MediaLayoutSectionEditorMediaLayoutSectionFields
      }
      ...CanActivateFields
    }
  }
  ${MediaLayoutSectionEditor.fragments.mediaLayoutSection}
  ${FormPage.fragments.canActivate}
`;

const CREATE_MUTATION = gql`
  mutation createMediaLayoutForMediaLayoutForm($data: MediaLayoutMutationInput!) {
    createMediaLayout(data: $data) {
      ok
      errors {
        ...MutationErrorFields
      }
      object {
        ...MediaLayoutFields
      }
    }
  }
  ${FormPage.fragments.mutationError}
  ${MEDIA_LAYOUT_FRAGMENT}
`;

const UPDATE_MUTATION = gql`
  mutation updateMediaLayoutForMediaLayoutForm(
    $data: MediaLayoutMutationInput!
    $id: ID!
  ) {
    updateMediaLayout(data: $data, id: $id, partial: true) {
      ok
      errors {
        ...MutationErrorFields
      }
      object {
        ...MediaLayoutFields
      }
    }
  }
  ${FormPage.fragments.mutationError}
  ${MEDIA_LAYOUT_FRAGMENT}
`;
