import { gql } from "@apollo/client";

import { ListPage } from "../../components/pages";

export const ReportedPostList = () => (
  <ListPage
    resource="reportedPost"
    subtitleKey="admin.page.reportedPost.subtitle"
    baseFilter={{ reportCount_Gt: 0 }}
    fields={[
      {
        name: "id",
      },
      {
        name: "group",
        type: "object",
        resource: "group",
        filterKey: "group_Overlap",
      },
      {
        name: "body",
        filterKey: "body_Text",
      },
      {
        name: "member",
        labelKey: "admin.field.nickname",
        type: "object",
        resource: "member",
        filterKey: "member_Overlap",
      },
      {
        name: "reportCount",
        type: "integer",
        filterKey: "reportCount_Range",
        sortKey: "REPORT_COUNT",
      },
      {
        name: "status",
        type: "choice",
        enumType: "PostStatus",
        sortKey: "REPORT_COUNT",
      },
      ...ListPage.fields.canHide,
      ...ListPage.fields.timestamped,
    ]}
    query={QUERY}
    extraRowActions={(props) => (
      <>
        {ListPage.extraRowActions.canHide(props)}
        {ListPage.extraRowActions.canActivate(props)}
      </>
    )}
  />
);

// ==

const QUERY = gql`
  query postsForReportedPostList(
    $filter: PostFilterInput
    $sort: [PostSortInput]
    $page: PageInput
  ) {
    posts(filter: $filter, sort: $sort, page: $page) {
      objects {
        id
        group {
          id
          name
        }
        body: bodySnippet
        member {
          id
          nickname
        }
        reportCount
        status
        ...TrackedFields
        ...CanActivateFields
        ...CanHideFields
      }
      ...ListFields
    }
  }
  ${ListPage.fragments.list}
  ${ListPage.fragments.tracked}
  ${ListPage.fragments.canActivate}
  ${ListPage.fragments.canHide}
`;
