import { gql } from "@apollo/client";

import { FormPage } from "../../components/pages";

export const PollOptionForm = () => (
  <FormPage
    resource="pollOption"
    fields={[
      {
        name: "poll",
        type: "object",
        resource: "poll",
        disabled: ({ isUpdate }) => isUpdate,
      },
      {
        name: "order",
        type: "integer",
      },
      {
        name: "body",
      },
      {
        name: "image",
        type: "imageMeta",
      },
    ]}
    query={QUERY}
    createMutation={CREATE_MUTATION}
    updateMutation={UPDATE_MUTATION}
  />
);

// ==

const FORM_FIELDS_FRAGMENT = gql`
  fragment PollOptionFormFields on PollOption {
    id
    poll {
      id
    }
    order
    body
    image {
      ...ImageFields
    }
  }
  ${FormPage.fragments.image}
`;

const QUERY = gql`
  query pollOptionForPollOptionForm($id: ID!) {
    pollOption(filter: { id_Overlap: [$id] }) {
      ...PollOptionFormFields
    }
  }
  ${FORM_FIELDS_FRAGMENT}
`;

const CREATE_MUTATION = gql`
  mutation createPollOptionForPollOptionForm($data: PollOptionMutationInput!) {
    createPollOption(data: $data) {
      ok
      errors {
        ...MutationErrorFields
      }
      object {
        ...PollOptionFormFields
      }
    }
  }
  ${FormPage.fragments.mutationError}
  ${FORM_FIELDS_FRAGMENT}
`;

const UPDATE_MUTATION = gql`
  mutation updatePollOptionForPollOptionForm(
    $data: PollOptionMutationInput!
    $id: ID!
  ) {
    updatePollOption(data: $data, id: $id, partial: true) {
      ok
      errors {
        ...MutationErrorFields
      }
      object {
        ...PollOptionFormFields
      }
    }
  }
  ${FormPage.fragments.mutationError}
  ${FORM_FIELDS_FRAGMENT}
`;
