import { gql } from "@apollo/client";

import { ActivateButton } from "../../components/actions";
import { ShareURLButton } from "../../components/actions";
import { DetailPage } from "../../components/pages";
import { Can } from "../../components/permission";
import {
  EventApplicationListWidget,
  EventAttendanceListWidget,
  EventNoticeWidget,
  EventPostListWidget,
  EventWinnerListWidget,
} from "../../components/widgets";
import config from "../../config";

export const EventDetail = () => (
  <DetailPage
    resource="event"
    fields={[
      {
        name: "id",
      },
      {
        name: "group",
        type: "object",
        resource: "group",
      },
      {
        name: "badge",
        labelKey: "admin.field.status",
        type: "choice",
        enumType: "EventBadge",
      },
      {
        name: "titleContent",
        labelKey: "admin.field.title",
        type: "translatable",
      },
      { name: "announceCategory", type: "choice", enumType: "EventAnnounceCategory" },
      { name: "announcedAt", type: "datetime" },
      { name: "submitCategory", type: "choice", enumType: "EventSubmitCategory" },
      { name: "drawCategory", type: "choice", enumType: "EventDrawCategory" },
      {
        name: "applicantGrade",
        type: "choice",
        enumType: "EventGradeCategory",
      },
      { name: "applyEndedAt", type: "datetime" },
      { name: "applyStartedAt", type: "datetime" },
      { name: "eventAt", type: "datetime" },
      { name: "eventLocation" },
      {
        name: "eventLocationCategory",
        type: "choice",
        resource: "eventLocationCategory",
        enumType: "EventLocationCategory",
        filterKey: "eventLocationCategory_Overlap",
        sortKey: "LOCATION_CATEGORY",
      },
      { name: "hasBirthDateQuestion", type: "boolean" },
      { name: "hasCountryQuestion", type: "boolean" },
      { name: "hasGenderQuestion", type: "boolean" },
      { name: "hasNameQuestion", type: "boolean" },
      { name: "hasPhoneQuestion", type: "boolean" },
      { name: "isHighlighted", type: "boolean" },
      { name: "maxAge", type: "integer" },
      { name: "minAge", type: "integer" },
      { name: "minPenaltyCount", type: "integer" },
      { name: "winnerCount", type: "integer" },
      { name: "spareCount", type: "integer" },
      ...DetailPage.fields.canActivate,
      ...DetailPage.fields.tracked,
    ]}
    query={QUERY}
    extraActions={({ obj, refetch, resource }) => {
      return (
        <>
          <ShareURLButton
            url={`${config.missaEndpoint}/community/${obj.group.code}/event/${obj.id}`}
          />
          <Can
            action={obj.isActive ? "inactivate" : "activate"}
            targetType={resource.typeName}
            targetId={obj.id}
          >
            <ActivateButton
              targetType={resource.typeName}
              targetId={obj.id}
              value={obj.isActive}
              onChange={() => refetch()}
            ></ActivateButton>
          </Can>
        </>
      );
    }}
    tabs={[
      {
        path: "",
        labelKey: "admin.resource.eventNotice.plural",
        children: ({ obj }) => (
          <EventNoticeWidget createSearchParams={{ event: obj.id }} />
        ),
      },
      {
        path: "applications",
        labelKey: "admin.resource.eventApplication.plural",
        children: ({ obj }) => (
          <EventApplicationListWidget
            event={obj}
            baseFilter={{
              event_Overlap: [obj.id],
            }}
            createSearchParams={{ eventApplication: obj.id }}
            defaultSort={[{ type: "APPLIED_AT", direction: "ASC" }]}
            hideList
            hideCreate
            hideDetail
          />
        ),
        hidden: ({ obj }) => obj.eventLocationCategory === "EXTERNAL",
      },
      {
        path: "winners",
        labelKey: "admin.resource.eventWinner.plural",
        children: ({ obj }) => (
          <EventWinnerListWidget
            event={obj}
            baseFilter={{
              event_Overlap: [obj.id],
            }}
            createSearchParams={{ eventWinner: obj.id }}
            defaultSort={[{ type: "ORDER", direction: "ASC" }]}
            hideList
            hideCreate
            hideDetail
          />
        ),
        hidden: ({ obj }) => obj.eventLocationCategory === "EXTERNAL",
      },
      {
        path: "attendance",
        labelKey: "admin.resource.eventAttendance.plural",
        children: ({ obj }) => (
          <EventAttendanceListWidget
            event={obj}
            baseFilter={{
              event_Overlap: [obj.id],
            }}
            createSearchParams={{ eventApplication: obj.id }}
            defaultSort={[{ type: "ORDER", direction: "ASC" }]}
            hideList
            hideCreate
            hideDetail
          />
        ),
        hidden: ({ obj }) => obj.eventLocationCategory !== "OFFLINE",
      },
      {
        path: "posts",
        labelKey: "admin.resource.eventPost.plural",
        children: ({ obj }) => (
          <EventPostListWidget
            event={obj}
            baseFilter={{
              event_Overlap: [obj.id],
            }}
            createSearchParams={{ event: obj.id }}
          />
        ),
        hidden: ({ obj }) => obj.eventLocationCategory !== "OFFLINE",
      },
    ]}
  />
);

// ==

const QUERY = gql`
  query eventForEventDetail($id: ID!) {
    event(filter: { id_Overlap: [$id] }) {
      id
      group {
        id
        name
        code
      }
      badge
      title
      titleContent {
        ...TranslatableContentFields
      }
      announceCategory
      announcedAt
      submitCategory
      drawCategory
      applicantGrade
      applyEndedAt
      applyStartedAt
      eventAt
      eventLocation
      eventLocationCategory
      hasBirthDateQuestion
      hasCountryQuestion
      hasGenderQuestion
      hasNameQuestion
      hasPhoneQuestion
      isHighlighted
      maxAge
      minAge
      minPenaltyCount
      winnerCount
      spareCount
      tags {
        id
        name
      }
      ...TrackedFields
      ...CanActivateFields
    }
  }
  ${DetailPage.fragments.tracked}
  ${DetailPage.fragments.canActivate}
  ${DetailPage.fragments.translatableContent}
`;
