import { useEffect, useState } from "react";

import { gql } from "@apollo/client";

import DataLoader from "dataloader";

import { CanInput } from "@/types/ability";
import { getApolloClient } from "@/utils/gql";

type CanResult = Array<boolean | null>;

export const useAbilities = (inputs: CanInput[] | null) => {
  const [count, setCount] = useState(0);
  const [values, setValues] = useState<CanResult>();

  useEffect(() => {
    if (inputs) {
      setCount((count) => count + 1);
      abilityLoader.loadMany(inputs).then((values) => {
        setValues(values as CanResult);
        setCount((count) => count - 1);
      });
    } else {
      setValues([]);
    }
  }, [inputs]);

  return { loading: count > 0, abilities: values };
};

export const clearAllAbilities = () => abilityLoader.clearAll();

// ==

const abilityLoader = new DataLoader(
  async (keys: readonly CanInput[]) => {
    const client = getApolloClient();
    const query = gql`
      query can($inputs: [CanInput!]!) {
        can(inputs: $inputs)
      }
    `;
    const variables = { inputs: keys };
    try {
      return (await client.query({ query, variables })).data.can as CanResult;
    } catch (_) {
      return keys.map(() => false);
    }
  },
  {
    cacheKeyFn: ({ action, targetType = "", targetId = "", field = "" }) =>
      `${action}:${targetType}:${targetId}:${field}`,
  },
);
