import type { BuiltField, BuiltFlatField } from "@/types/field";
import type { RecordType } from "@/types/primitives";
import { getByPath, isNullOrUndefined, unzip, zipObjectDeep } from "@/utils/helpers";

export function getFormDefaultValues(
  fields: BuiltFlatField[],
  obj: RecordType | null,
  searchParams: URLSearchParams,
  givenDefaultValues?: RecordType,
) {
  const entries = fields.flatMap((field) => {
    const { type, name } = field;
    const args = [field, obj, searchParams, givenDefaultValues] as const;
    if (type === "composite" && field.names)
      return field.names.map((n) => [n, getFormFieldDefaultValue(n, ...args)]);
    if (!name) return [];
    return [[name, getFormFieldDefaultValue(name, ...args)]];
  });
  const [names, values] = unzip(entries);
  return zipObjectDeep(names, values);
}

export function getFormFieldDefaultValue(
  name: string,
  field: BuiltField,
  obj: RecordType | null,
  searchParams: URLSearchParams,
  defaultValues?: RecordType,
) {
  const { type, getter, defaultValue } = field;

  // == 1. Get default value from search value
  const searchParam = searchParams.get(name);
  if (searchParam) {
    try {
      return JSON.parse(searchParam);
    } catch (e) {
      console.warn(`Failed to parse search param for field ${name}: ${e}`);
    }
  }

  // == 2. Get default value from given obj and field(single or composite) getter
  const valueFromGetter = getter(obj ?? {});
  if (valueFromGetter) {
    if (type === "composite") {
      const value = getByPath(valueFromGetter, name);
      if (!isNullOrUndefined(value)) return value;
    } else {
      return valueFromGetter;
    }
  }

  // == 3. Get default value from given defaultValues
  if (defaultValues) {
    const valueFromDefaultValues = getByPath(defaultValues, name);
    if (valueFromDefaultValues) return valueFromDefaultValues;
  }

  // == 4. Get default value from field defaultValue
  if (defaultValue) {
    if (type === "composite") return getByPath(defaultValue, name);
    return defaultValue;
  }
}
