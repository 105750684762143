import { gql } from "@apollo/client";

import { DuplicateMediaLayoutButton } from "../../components/actions";
import { DetailPage } from "../../components/pages";
import { MediaLayoutSectionListWidget } from "../../components/widgets";

export const MediaLayoutDetail = () => (
  <DetailPage
    resource="mediaLayout"
    fields={[
      {
        name: "id",
      },
      {
        name: "group",
        type: "object",
        resource: "group",
      },
      {
        name: "name",
      },
      {
        name: "status",
        type: "choice",
        enumType: "MediaLayoutStatus",
      },
      ...DetailPage.fields.canActivate,
      {
        name: "activateAt",
        type: "datetime",
      },
      ...DetailPage.fields.tracked,
    ]}
    query={QUERY}
    extraActions={(props) => (
      <>
        {DetailPage.extraActions.canActivate(props)}
        <DuplicateMediaLayoutButton mediaLayoutId={props.obj.id} />
      </>
    )}
    tabs={[
      {
        path: "",
        labelKey: "admin.common.sections",
        permission: {
          action: "list",
          targetType: "MEDIA_LAYOUT_SECTION",
        },
        children: ({ obj }) => (
          <MediaLayoutSectionListWidget
            baseFilter={{
              layout_Overlap: [obj.id],
            }}
            createSearchParams={{ layout: obj.id }}
            hiddenFields={["layout"]}
          />
        ),
      },
    ]}
  />
);

// ==

const QUERY = gql`
  query mediaLayoutForMediaLayoutDetail($id: ID!) {
    mediaLayout(filter: { id_Overlap: [$id] }) {
      id
      group {
        id
        name
      }
      name
      status
      ...TrackedFields
      ...CanActivateFields
    }
  }
  ${DetailPage.fragments.tracked}
  ${DetailPage.fragments.canActivate}
`;
