import { gql } from "@apollo/client";

import { ListWidget } from "../list";

export const GroupListWidget = (props) => (
  <ListWidget
    resource="group"
    fields={[
      {
        name: "id",
      },
      {
        name: "company",
        type: "object",
        resource: "company",
        filterKey: "company_Overlap",
      },
      {
        name: "code",
        filterKey: "code_Exact",
        sortKey: "CODE",
      },
      {
        name: "name",
        filterKey: "name_Text",
      },
      {
        name: "owner",
        type: "object",
        resource: "staff",
        filterKey: "owner_Overlap",
      },
      {
        name: "memberCount",
        type: "integer",
        filterKey: "memberCount_Range",
        sortKey: "MEMBER_COUNT",
      },
      {
        name: "postCount",
        type: "integer",
        filterKey: "postCount_Range",
        sortKey: "POST_COUNT",
      },
      {
        name: "commentCount",
        type: "integer",
        filterKey: "commentCount_Range",
        sortKey: "COMMENT_COUNT",
      },
      {
        name: "status",
        type: "choice",
        enumType: "GroupStatus",
        filterKey: "status_Overlap",
      },
      {
        name: "activateAt",
        type: "datetime",
      },
      ...ListWidget.fields.tracked,
    ]}
    query={QUERY}
    extraRowActions={ListWidget.extraRowActions.canActivate}
    {...props}
  />
);

// ==

const QUERY = gql`
  query groupsForGroupListWidget(
    $filter: GroupFilterInput
    $sort: [GroupSortInput]
    $page: PageInput
  ) {
    groups(filter: $filter, sort: $sort, page: $page) {
      objects {
        id
        company {
          id
          name
        }
        code
        name
        owner {
          id
          user {
            id
            email
            name
          }
        }
        memberCount
        postCount
        commentCount
        status
        ...TrackedFields
        ...CanActivateFields
      }
      ...ListFields
    }
  }
  ${ListWidget.fragments.list}
  ${ListWidget.fragments.tracked}
  ${ListWidget.fragments.canActivate}
`;
