import { gql } from "@apollo/client";

import { FormPage } from "../../components/pages";

export const EventV2PenaltyCategoryForm = () => (
  <FormPage
    resource="eventV2PenaltyCategory"
    fields={[
      {
        name: "nameContent",
        labelKey: "admin.field.name",
        type: "translatable",
      },
    ]}
    query={QUERY}
    createMutation={CREATE_MUTATION}
    updateMutation={UPDATE_MUTATION}
  />
);

// ==

const FORM_FIELDS_FRAGMENT = gql`
  fragment EventV2PenaltyCategoryFormFields on EventV2PenaltyCategory {
    id
    nameContent {
      ...TranslatableContentFields
    }
  }
  ${FormPage.fragments.translatableContent}
`;

const QUERY = gql`
  query eventV2PenaltyCategoryForEventV2PenaltyCategoryForm($id: ID!) {
    eventV2PenaltyCategory(filter: { id_Overlap: [$id] }) {
      ...EventV2PenaltyCategoryFormFields
    }
  }
  ${FORM_FIELDS_FRAGMENT}
`;

const CREATE_MUTATION = gql`
  mutation createEventV2PenaltyCategoryForEventV2PenaltyCategoryForm(
    $data: EventV2PenaltyCategoryMutationInput!
  ) {
    createEventV2PenaltyCategory(data: $data) {
      ok
      errors {
        ...MutationErrorFields
      }
      object {
        ...EventV2PenaltyCategoryFormFields
      }
    }
  }
  ${FormPage.fragments.mutationError}
  ${FORM_FIELDS_FRAGMENT}
`;

const UPDATE_MUTATION = gql`
  mutation updateEventV2PenaltyCategoryForEventV2PenaltyCategoryForm(
    $data: EventV2PenaltyCategoryMutationInput!
    $id: ID!
  ) {
    updateEventV2PenaltyCategory(data: $data, id: $id, partial: true) {
      ok
      errors {
        ...MutationErrorFields
      }
      object {
        ...EventV2PenaltyCategoryFormFields
      }
    }
  }
  ${FormPage.fragments.mutationError}
  ${FORM_FIELDS_FRAGMENT}
`;
