import { gql } from "@apollo/client";

import {
  DownloadMembersButton,
  SuspendMemberButton,
  UnsuspendMemberButton,
} from "../../components/actions";
import { ListPage, ListPageWithGroup } from "../../components/pages";
import { Can } from "../../components/permission";

export const MemberList = () => (
  <ListPageWithGroup
    groupFilterKey="group_Overlap"
    resource="member"
    subtitleKey="admin.page.member.subtitle"
    globalSearchKey="compositeAdmin_Search"
    baseFilter={{
      artist_Exists: false,
    }}
    tabs={[
      {
        path: "",
        labelKey: "admin.page.member.tabAll",
        tableProps: {
          query: ALL_QUERY,
          fields: [
            {
              name: "id",
            },
            {
              name: "group",
              type: "object",
              resource: "group",
              filterKey: "group_Overlap",
            },
            {
              name: "user.email",
              labelKey: "admin.field.email",
              filterKey: "user_Email_Wildcard",
              type: "email",
            },
            {
              name: "user.phone",
              labelKey: "admin.field.phone",
              filterKey: "user_Phone_Text",
              type: "phone",
            },
            {
              name: "nickname",
              filterKey: "auto",
              sortKey: "NICKNAME",
            },
            {
              name: "hasPremiums",
              type: "boolean",
              getter: (obj) => Boolean(obj.premiums?.length),
              filterKey: "premiums_Exists",
            },
            {
              name: "status",
              type: "choice",
              enumType: "MemberStatus",
              filterKey: "status_Overlap",
            },
            {
              name: "user.lastAuthenticatedAt",
              labelKey: "admin.field.lastAuthenticatedAt",
              type: "datetime",
            },
            {
              name: "createdAt",
              labelKey: "admin.field.groupJoinedAt",
              type: "datetime",
              sortKey: "CREATED_AT",
            },
          ],
          extraActions: ({ filter, sort, result }) => (
            <Can action="download" targetType="MEMBER">
              <DownloadMembersButton
                count={result?.count}
                filter={filter}
                sort={sort}
                type="text"
                onlyIcon
              />
            </Can>
          ),
          extraRowActions: ({ obj, refetch }) => (
            <Can action="suspend" targetType="MEMBER" targetId={obj.id}>
              <SuspendMemberButton
                memberId={obj.id}
                onChange={() => refetch()}
                size="small"
                onlyIcon
              ></SuspendMemberButton>
            </Can>
          ),
          hideCreate: true,
          hideUpdate: true,
        },
      },
      {
        path: "suspended",
        labelKey: "admin.page.member.tabSuspended",
        tableProps: {
          query: SUSPENDED_QUERY,
          baseFilter: {
            status_Overlap: ["SUSPENDED"],
          },
          fields: [
            {
              name: "id",
            },
            {
              name: "group",
              type: "object",
              resource: "group",
              filterKey: "group_Overlap",
            },
            {
              name: "user.email",
              labelKey: "admin.field.email",
              filterKey: "user_Email_Wildcard",
              type: "email",
            },
            {
              name: "user.phone",
              labelKey: "admin.field.phone",
              filterKey: "user_Phone_Text",
              type: "phone",
            },
            {
              name: "nickname",
              filterKey: "auto",
              sortKey: "NICKNAME",
            },
            {
              name: "suspensionCategory",
              type: "choice",
              enumType: "MemberSuspensionCategory",
              filterKey: "suspensionCategory_Overlap",
            },
            {
              name: "suspensionReason",
              type: "choice",
              enumType: "MemberSuspensionReason",
              filterKey: "suspensionReason_Overlap",
            },
            {
              name: "suspensionPeriod",
              type: "choice",
              enumType: "MemberSuspensionPeriod",
            },
            {
              name: "suspensionPeriodUntil",
              type: "datetime",
            },
            {
              name: "suspensionMemo",
              type: "text",
            },
            {
              name: "suspendedAt",
              type: "datetime",
            },
            {
              name: "suspendedBy",
              type: "object",
              resource: "user",
            },
          ],
          extraActions: ({ filter, sort, result }) => (
            <DownloadMembersButton
              count={result?.count}
              filter={filter}
              sort={sort}
              type="text"
              onlyIcon
            />
          ),
          extraRowActions: ({ obj, refetch }) => (
            <Can action="suspend" targetType="MEMBER" targetId={obj.id}>
              <UnsuspendMemberButton
                memberId={obj.id}
                onChange={() => refetch()}
                size="small"
                onlyIcon
              ></UnsuspendMemberButton>
            </Can>
          ),
          hideUpdate: true,
          hideCreate: true,
        },
      },
      {
        path: "inactivated",
        labelKey: "admin.page.member.tabInactivated",
        tableProps: {
          query: INACTIVE_QUERY,
          baseFilter: {
            status_Overlap: ["INACTIVATED"],
          },
          fields: [
            {
              name: "id",
            },
            {
              name: "group",
              type: "object",
              resource: "group",
              filterKey: "group_Overlap",
            },
            {
              name: "user.email",
              labelKey: "admin.field.email",
              filterKey: "user_Email_Wildcard",
              type: "email",
            },
            {
              name: "user.phone",
              labelKey: "admin.field.phone",
              filterKey: "user_Phone_Text",
              type: "phone",
            },
            {
              name: "nickname",
              filterKey: "auto",
              sortKey: "NICKNAME",
            },
            {
              name: "hasPremiums",
              type: "boolean",
              getter: (obj) => Boolean(obj.premiums?.length),
              filterKey: "premiums_Exists",
            },
            {
              name: "status",
              type: "choice",
              enumType: "MemberStatus",
              filterKey: "status_Overlap",
            },
            {
              name: "activationChangedAt",
              type: "datetime",
            },
          ],
          extraActions: ({ filter, sort, result }) => (
            <DownloadMembersButton
              count={result?.count}
              filter={filter}
              sort={sort}
              type="text"
              onlyIcon
            />
          ),
          hideCreate: true,
          hideUpdate: true,
        },
      },
      {
        path: "premium",
        labelKey: "admin.page.member.tabPremium",
        tableProps: {
          query: PREMIUM_QUERY,
          baseFilter: {
            premiums_Exists: true,
          },
          fields: [
            {
              name: "id",
            },
            {
              name: "group",
              type: "object",
              resource: "group",
              filterKey: "group_Overlap",
            },
            {
              name: "user.email",
              labelKey: "admin.field.email",
              filterKey: "user_Email_Wildcard",
              type: "email",
            },
            {
              name: "user.phone",
              labelKey: "admin.field.phone",
              filterKey: "user_Phone_Text",
              type: "phone",
            },
            {
              name: "nickname",
              filterKey: "auto",
              sortKey: "NICKNAME",
            },
            {
              name: "premiums",
              type: "object",
              resource: "premium",
              filterKey: "premiums_Overlap",
            },
            {
              name: "status",
              type: "choice",
              enumType: "MemberStatus",
              filterKey: "status_Overlap",
            },
          ],
          extraActions: ({ filter, sort, result }) => (
            <DownloadMembersButton
              count={result?.count}
              filter={filter}
              sort={sort}
              type="text"
              onlyIcon
            />
          ),
          hideCreate: true,
          hideUpdate: true,
        },
      },
    ]}
  />
);

// ==

const ALL_QUERY = gql`
  query membersForMemberListAll(
    $filter: MemberFilterInput
    $sort: [MemberSortInput]
    $page: PageInput
  ) {
    members(filter: $filter, sort: $sort, page: $page) {
      objects {
        id
        group {
          id
          name
        }
        user {
          id
          email
          phone
          lastAuthenticatedAt
        }
        nickname
        premiums {
          id
        }
        status
        ...TrackedFields
      }
      ...ListFields
    }
  }
  ${ListPage.fragments.list}
  ${ListPage.fragments.tracked}
`;

const SUSPENDED_QUERY = gql`
  query membersForMemberListSuspended(
    $filter: MemberFilterInput
    $sort: [MemberSortInput]
    $page: PageInput
  ) {
    members(filter: $filter, sort: $sort, page: $page) {
      objects {
        id
        group {
          id
          name
        }
        user {
          id
          email
          phone
        }
        nickname
        suspensionCategory
        suspensionReason
        suspensionPeriod
        suspensionPeriodUntil
        suspensionMemo
        suspendedAt
        suspendedBy {
          id
          email
          name
        }
      }
      ...ListFields
    }
  }
  ${ListPage.fragments.list}
`;

const INACTIVE_QUERY = gql`
  query membersForMemberListInactive(
    $filter: MemberFilterInput
    $sort: [MemberSortInput]
    $page: PageInput
  ) {
    members(filter: $filter, sort: $sort, page: $page) {
      objects {
        id
        group {
          id
          name
        }
        user {
          id
          email
          phone
          lastAuthenticatedAt
        }
        nickname
        premiums {
          id
        }
        status
        activationChangedAt
      }
      ...ListFields
    }
  }
  ${ListPage.fragments.list}
`;

const PREMIUM_QUERY = gql`
  query membersForMemberListPremium(
    $filter: MemberFilterInput
    $sort: [MemberSortInput]
    $page: PageInput
  ) {
    members(filter: $filter, sort: $sort, page: $page) {
      objects {
        id
        group {
          id
          name
        }
        user {
          id
          email
          phone
          lastAuthenticatedAt
        }
        nickname
        premiums {
          id
          name
        }
        status
      }
      ...ListFields
    }
  }
  ${ListPage.fragments.list}
`;
