import { forwardRef, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { EditOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Col, Row, Space } from "antd";

import { deepEqual } from "../../utils/helpers";
import { CreateOfflineItemAction } from "../actions";
import { SortableTable } from "../list";

export const OfflineStoreItemEditor = forwardRef(
  (
    { onChange, baseFilter = undefined, disabled = false, value: { items }, ...props },
    ref,
  ) => {
    const { t } = useTranslation();

    const [itemValue, setItemValue] = useState(items || []);

    useEffect(() => {
      if (itemValue.length === 0) {
        return;
      }
      if (!deepEqual(itemValue, items)) {
        onChange?.({ items: itemValue });
      }
    }, [itemValue, onChange, items]);

    const updateItems = (newItem) => {
      const index = itemValue.findIndex((item) => item.order === newItem.order);
      if (index !== -1) {
        const updatedItems = [...itemValue];
        updatedItems[index] = newItem;
        setItemValue([...updatedItems]);
      } else {
        newItem.order = itemValue.length;
        setItemValue((prevItems) => [...prevItems, newItem]);
      }
    };

    const updateItemOrder = (newItems) => {
      const updatedItems = newItems.map((item, index) => ({
        ...item,
        order: index,
      }));
      setItemValue(updatedItems);
    };

    return (
      <Space direction="vertical" style={styles.container} ref={ref}>
        <Row justify="space-between" align="top">
          <Col>
            <CreateOfflineItemAction
              onChange={updateItems}
              disabled={disabled}
              baseFilter={baseFilter}
              title={t("admin.common.actionTitle", {
                action: t("admin.common.add"),
                name: t("admin.resource.offlineStoreItem.singular"),
              })}
              okButtonText={t("admin.common.add")}
              drawerWidth={700}
            >
              {({ openDrawer, disabled }) => (
                <Button
                  type="link"
                  icon={<PlusOutlined />}
                  disabled={disabled}
                  onClick={openDrawer}
                >
                  {t("admin.common.actionTitle", {
                    action: t("admin.common.add"),
                    name: t("admin.resource.offlineStoreItem.singular"),
                  })}
                </Button>
              )}
            </CreateOfflineItemAction>
          </Col>
        </Row>
        <SortableTable
          resource="offlineStoreItem"
          fields={[
            {
              name: "order",
              labelKey: "admin.field.order",
              render: (_, item) => item.order + 1,
            },
            {
              name: "item.nameContent",
              labelKey: "admin.field.nameContent",
              render: (_, item) => item.nameContent?.values?.[0]?.value,
            },
            {
              name: "item.price",
              labelKey: "admin.field.price",
              render: (_, item) => item.price,
            },
            {
              name: "item.availability",
              labelKey: "admin.field.availability",
              render: (_, item) => item.availability,
            },
            {
              name: "isActive",
              labelKey: "admin.field.visibility",
              type: "boolean",
              getter: ({ isActive }) => isActive,
            },
          ]}
          extraActions={({ obj }) => (
            <CreateOfflineItemAction
              title={t("admin.common.actionTitle", {
                action: t("admin.common.edit"),
                name: t("admin.resource.offlineStoreItem.singular"),
              })}
              onChange={updateItems}
              disabled={disabled}
              baseFilter={baseFilter}
              okButtonText={t("admin.common.add")}
              drawerWidth={700}
              obj={obj}
            >
              {({ openDrawer }) => (
                <Button icon={<EditOutlined />} size="small" onClick={openDrawer} />
              )}
            </CreateOfflineItemAction>
          )}
          items={items}
          onChange={updateItemOrder}
          disabled={disabled}
          hideDelete={true}
          pagination={false}
          rowKey="id"
          size="small"
        />
      </Space>
    );
  },
);

const styles = {
  container: {
    width: "100%",
  },
};
