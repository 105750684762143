import { useTranslation } from "react-i18next";

import { gql } from "@apollo/client";

import dayjs from "dayjs";
import debounce from "debounce";

import { FormPage } from "../../components/pages";
import { useApp } from "../../hooks/app";
import { getApolloClient } from "../../utils/gql";

export const ClipForm = () => {
  const { t } = useTranslation();
  const { message } = useApp();

  return (
    <FormPage
      resource="clip"
      fields={[
        {
          type: "fieldset",
          fields: [
            {
              name: "group",
              type: "object",
              resource: "group",
              disabled: ({ isUpdate }) => isUpdate,
            },
            {
              name: "category",
              type: "choice",
              enumType: "ClipCategory",
              disabled: ({ isUpdate }) => isUpdate,
              onChange: ({ value, resetField }) => {
                switch (value) {
                  case "YOUTUBE":
                    resetField("images");
                    break;
                  case "IMAGE":
                    resetField("youtubeUrl");
                    break;
                }
              },
            },
            {
              name: "premium",
              type: "object",
              resource: "premium",
              baseFilter: ({ data }) => ({ group_Overlap: [data.group] }),
              onChange: ({ value, setValue }) => {
                if (value) {
                  setValue("isPremiumOnly", true);
                } else {
                  setValue("isPremiumOnly", false);
                }
              },
            },
            {
              name: "isPremiumOnly",
              type: "boolean",
              hidden: true,
            },
            {
              name: "doesAllowComment",
              type: "boolean",
            },
          ],
        },
        {
          type: "fieldset",
          labelKey: "admin.fieldset.content",
          fields: [
            {
              name: "images",
              type: "imageMeta",
              multiple: true,
              hidden: ({ data }) => Boolean(data.category !== "IMAGE"),
            },
            {
              name: "video",
              type: "videoMeta",
              hidden: ({ data }) => Boolean(data.category !== "VIDEO"),
              sync: false,
            },
            {
              name: "captions",
              type: "nested",
              multiple: true,
              hidden: ({ data }) => Boolean(data.category !== "VIDEO"),
              fields: [
                {
                  name: "id",
                  hidden: true,
                },
                {
                  name: "language",
                  type: "nestedObject",
                  resource: "language",
                  baseFilter: {
                    isActiveForCaption_Exact: true,
                  },
                },
                {
                  name: "caption",
                  type: "captionMeta",
                },
              ],
            },
            {
              name: "youtubeClipUrl",
              type: "url",
              hidden: ({ data }) => Boolean(data.category !== "YOUTUBE"),
              onChange: debounce(async ({ value, setValue }) => {
                const client = getApolloClient();
                const url = value.target.value;

                message.open({
                  type: "loading",
                  content: t("admin.message.fetchingYoutube"),
                  duration: 0,
                });

                try {
                  const response = await client.query({
                    query: YOUTUBE_VIDEO_META_QUERY,
                    variables: { url },
                  });
                  const {
                    title,
                    description,
                    thumbnailUrl,
                    duration,
                    firstActivatedAt,
                    localizations,
                  } = response.data.youtubeVideoMeta;

                  setValue("youtubeThumbnailUrl", thumbnailUrl);
                  setValue("youtubeClipDuration", duration);
                  setValue("firstActivatedAt", dayjs(firstActivatedAt).toISOString());

                  const titleContent = new Map([
                    ["ENGLISH", { language: "ENGLISH", value: title }],
                  ]);
                  const bodyContent = new Map([
                    ["ENGLISH", { language: "ENGLISH", value: description }],
                  ]);
                  if (localizations) {
                    const localizationsJson = JSON.parse(localizations);

                    for (const content of Object.values(localizationsJson)) {
                      titleContent.set(content.language_name, {
                        language: content.language_name,
                        value: content.title,
                      });
                      bodyContent.set(content.language_name, {
                        language: content.language_name,
                        value: content.description,
                      });
                    }
                  }
                  setValue("titleContent", {
                    values: [...titleContent.values()],
                  });
                  setValue("bodyContent", {
                    values: [...bodyContent.values()],
                  });

                  message.destroy();
                  message.success(t("admin.message.fetchYoutubeSuccess"));
                } catch (err) {
                  message.destroy();
                  message.error(
                    t("admin.message.fetchYoutubeFailure", {
                      message: err.message,
                    }),
                  );
                }
              }, 300),
            },
            {
              name: "youtubeClipDuration",
              type: "integer",
              labelKey: "admin.field.duration",
              disabled: true,
            },
            {
              name: "thumbnail",
              type: "imageMeta",
              helpKey: "admin.form.guide.thumbnailClip",
              hidden: ({ data }) => Boolean(data.youtubeThumbnailUrl),
            },
            {
              name: "youtubeThumbnailUrl",
              labelKey: "admin.field.thumbnail",
              helpKey: "admin.form.guide.thumbnailClip",
              hidden: ({ data }) => !data.youtubeThumbnailUrl,
              render: ({ controllerField: { value } }) => (
                <img src={value} style={{ maxWidth: "100%" }} />
              ),
            },
            {
              name: "titleContent",
              labelKey: "admin.field.title",
              type: "translatable",
            },
            {
              name: "bodyContent",
              labelKey: "admin.field.body",
              type: "translatable",
              rows: 10,
            },
          ],
        },
        ...FormPage.fields.canActivate,
      ]}
      query={QUERY}
      createMutation={CREATE_MUTATION}
      updateMutation={UPDATE_MUTATION}
    />
  );
};

// ==

const FORM_FIELDS_FRAGMENT = gql`
  fragment ClipFormFields on Clip {
    id
    group {
      id
    }
    category
    isPremiumOnly
    premium {
      id
    }
    doesAllowComment
    title
    titleContent {
      ...TranslatableContentFields
    }
    body
    bodyContent {
      ...TranslatableContentFields
    }
    youtubeClipUrl
    youtubeClipDuration
    thumbnail {
      ...ImageFields
    }
    images {
      ...ImageFields
    }
    video {
      ...VideoFields
    }
    captions {
      ...FormClipCaptionFields
    }
    ...CanActivateFields
  }
  ${FormPage.fragments.image}
  ${FormPage.fragments.clipCaption}
  ${FormPage.fragments.video}
  ${FormPage.fragments.canActivate}
  ${FormPage.fragments.translatableContent}
`;

const QUERY = gql`
  query clipForClipForm($id: ID!) {
    clip(filter: { id_Overlap: [$id] }) {
      ...ClipFormFields
    }
  }
  ${FORM_FIELDS_FRAGMENT}
`;

const CREATE_MUTATION = gql`
  mutation createClipForClipForm($data: ClipMutationInput!) {
    createClip(data: $data) {
      ok
      errors {
        ...MutationErrorFields
      }
      object {
        ...ClipFormFields
      }
    }
  }
  ${FormPage.fragments.mutationError}
  ${FORM_FIELDS_FRAGMENT}
`;

const UPDATE_MUTATION = gql`
  mutation updateClipForClipForm($data: ClipMutationInput!, $id: ID!) {
    updateClip(data: $data, id: $id, partial: true) {
      ok
      errors {
        ...MutationErrorFields
      }
      object {
        ...ClipFormFields
      }
    }
  }
  ${FormPage.fragments.mutationError}
  ${FORM_FIELDS_FRAGMENT}
`;

const YOUTUBE_VIDEO_META_QUERY = gql`
  query youtubeVideoMeta($url: String!) {
    youtubeVideoMeta(url: $url) {
      id
      title
      description
      duration
      thumbnailUrl
      firstActivatedAt
      localizations
    }
  }
`;
