import { gql } from "@apollo/client";

import { DetailPage } from "../../components/pages";

export const EventV2PenaltyDetail = () => (
  <DetailPage
    resource="eventV2Penalty"
    fields={[
      {
        name: "id",
      },
      {
        name: "event",
        type: "object",
        resource: "eventV2",
        filterKey: "event_Overlap",
      },
      {
        name: "member",
        type: "object",
        resource: "member",
        filterKey: "member_Overlap",
      },
      {
        name: "category",
        getter: (obj) => obj.category?.name,
      },
      {
        name: "count",
        type: "integer",
      },
      {
        type: "choice",
        name: "member.status",
        labelKey: "admin.field.memberStatus",
        enumType: "MemberStatus",
      },
      {
        name: "memo",
        type: "string",
        labelKey: "admin.field.memo",
      },
      ...DetailPage.fields.canActivate,
      ...DetailPage.fields.tracked,
    ]}
    query={QUERY}
    extraActions={DetailPage.extraActions.canActivate}
  />
);

// ==

const QUERY = gql`
  query eventV2PenaltyForEventV2PenaltyDetail($id: ID!) {
    eventV2Penalty(filter: { id_Overlap: [$id] }) {
      id
      event {
        id
        title
        group {
          id
          name
        }
      }
      category {
        id
        name
      }
      member {
        id
        nickname
        user {
          id
          name
        }
        status
      }
      count
      memo
      ...TrackedFields
      ...CanActivateFields
    }
  }
  ${DetailPage.fragments.tracked}
  ${DetailPage.fragments.canActivate}
`;
