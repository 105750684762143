import { gql } from "@apollo/client";

import { DetailPage } from "../../components/pages";

export const LanguageDetail = () => (
  <DetailPage
    resource="language"
    fields={[
      {
        name: "id",
      },
      {
        name: "code",
      },
      {
        name: "name",
      },
      {
        name: "nativeName",
      },
      {
        name: "order",
      },
      {
        name: "isActiveForService",
        type: "boolean",
      },
      {
        name: "isActiveForTranslation",
        type: "boolean",
      },
      {
        name: "isActiveForCaption",
        type: "boolean",
      },
    ]}
    query={QUERY}
  />
);

// ==

const QUERY = gql`
  query languageForLanguageDetail($id: ID!) {
    language(filter: { id_Overlap: [$id] }) {
      id
      code
      name
      nativeName
      order
      isActiveForService
      isActiveForTranslation
      isActiveForCaption
    }
  }
`;
