import { useTranslation } from "react-i18next";

import { isNil } from "es-toolkit";
import type { ArrayOrSingle } from "ts-essentials";

import type { CanBeNil, ResolvableValue } from "@/types/utils";
import { isString, resolveCallable } from "@/utils/helpers";

/**
 * Type-ignored the `t` function from `useTranslation` hook.
 * Currently, we get translation data from http backend
 * so there's no way to infer type for `TFunction` in i18next.
 * https://github.com/i18next/react-i18next/issues/1654
 */
export function useTranslate() {
  return useTranslation().t as (
    key: ArrayOrSingle<string>,
    data?: Record<string, unknown>,
  ) => string;
}

export function useResolvedTranslation<Value, Args>(
  resolvableValue?: ResolvableValue<Value, Args>,
  resolvableTranslateKey?: ResolvableValue<CanBeNil<string>, Args>,
  options?: {
    args?: Args;
    fallbackKey?: string;
    /** @default true */
    trim?: boolean;
  },
): Value | string | null {
  const t = useTranslate();
  const { args, fallbackKey, trim = true } = options || {};
  if (resolvableValue) {
    const value = resolveCallable<Value>(resolvableValue, args);
    if (trim && isString(value)) return value.trim();
    if (!isNil(value)) return value;
  }
  const translateKey = resolveCallable(resolvableTranslateKey, args);
  if (isString(translateKey)) {
    const translated = t(translateKey);
    if (trim) return translated.trim();
    return translated;
  }
  if (isString(fallbackKey)) {
    const fallback = t(fallbackKey);
    if (trim) return fallback.trim();
    return fallback;
  }
  return null;
}
