import { gql } from "@apollo/client";

import { FormPage } from "../../components/pages";

export const ExternalPremiumMembershipForm = () => (
  <FormPage
    resource="externalPremiumMembership"
    fields={[
      {
        name: "premium",
        type: "object",
        resource: "premium",
        disabled: ({ isUpdate }) => isUpdate,
      },
      {
        name: "provider",
        type: "choice",
        enumType: "ExternalPremiumMembershipProvider",
      },
      {
        name: "identifier",
      },
      {
        name: "username",
      },
      {
        name: "email",
        type: "email",
      },
      {
        name: "name",
      },
      {
        name: "birthDate",
        type: "date",
      },
    ]}
    query={QUERY}
    createMutation={CREATE_MUTATION}
    updateMutation={UPDATE_MUTATION}
  />
);

// ==

const FORM_FIELDS_FRAGMENT = gql`
  fragment ExternalPremiumMembershipFormFields on ExternalPremiumMembership {
    id
    premium {
      id
    }
    provider
    identifier
    username
    email
    name
    birthDate
  }
`;

const QUERY = gql`
  query externalPremiumMembershipForExternalPremiumMembershipForm($id: ID!) {
    externalPremiumMembership(filter: { id_Overlap: [$id] }) {
      ...ExternalPremiumMembershipFormFields
    }
  }
  ${FORM_FIELDS_FRAGMENT}
`;

const CREATE_MUTATION = gql`
  mutation createExternalPremiumMembershipForExternalPremiumMembershipForm(
    $data: ExternalPremiumMembershipMutationInput!
  ) {
    createExternalPremiumMembership(data: $data) {
      ok
      errors {
        ...MutationErrorFields
      }
      object {
        ...ExternalPremiumMembershipFormFields
      }
    }
  }
  ${FormPage.fragments.mutationError}
  ${FORM_FIELDS_FRAGMENT}
`;

const UPDATE_MUTATION = gql`
  mutation updateExternalPremiumMembershipForExternalPremiumMembershipForm(
    $data: ExternalPremiumMembershipMutationInput!
    $id: ID!
  ) {
    updateExternalPremiumMembership(data: $data, id: $id, partial: true) {
      ok
      errors {
        ...MutationErrorFields
      }
      object {
        ...ExternalPremiumMembershipFormFields
      }
    }
  }
  ${FormPage.fragments.mutationError}
  ${FORM_FIELDS_FRAGMENT}
`;
