import { useState } from "react";

import { gql, useLazyQuery } from "@apollo/client";

import { FileSearchOutlined } from "@ant-design/icons";
import { Button, Drawer, Typography } from "antd";

import { t } from "i18next";

import { useApp } from "../../hooks/app";

export const EventSurveyViewButton = ({ eventId, memberId }) => {
  const { message } = useApp();
  const [open, setOpen] = useState(false);

  const [fetchAnswer, { data, loading, error }] = useLazyQuery(QUERY, {
    variables: {
      eventId: eventId,
      memberId: memberId,
    },
  });

  const showDrawer = async () => {
    setOpen(true);
    await fetchAnswer();
  };

  const handleClose = () => {
    setOpen(false);
  };

  if (error) {
    console.error(error);
    message.error(JSON.stringify(error));
  }

  const defaultQuestionMapper = {
    NAME: t("admin.field.name"),
    BIRTHDAY: t("admin.field.birthDate"),
    AGE: t("admin.field.age"),
    GENDER: t("admin.field.gender"),
    PHONE: t("admin.field.phone"),
    COUNTRY: t("admin.field.country"),
  };

  const answers = data?.eventAnswers?.objects || [];

  return (
    <>
      <Button size="small" type="default" onClick={showDrawer} loading={loading}>
        <FileSearchOutlined />
      </Button>
      <Drawer title={t("admin.common.survey")} open={open} onClose={handleClose}>
        {answers.length > 0
          ? answers.map((answer) => (
              <div key={answer.id}>
                <Typography.Title level={5} style={styles.title} strong>
                  {answer?.question?.title || defaultQuestionMapper[answer.category]}
                </Typography.Title>
                {answer?.question?.description ? (
                  <Typography.Text style={styles.description} type="secondary">
                    {answer?.question?.description}
                  </Typography.Text>
                ) : null}
                {answer.question?.category === "FILE" ? (
                  <a href={answer?.file?.url} target="_blank" rel="noreferrer">
                    <img src={answer?.file?.url} style={styles.image} />
                  </a>
                ) : (
                  <Typography.Text style={styles.answer}>
                    {answer?.answer}
                  </Typography.Text>
                )}
              </div>
            ))
          : null}
      </Drawer>
    </>
  );
};

// ==

const QUERY = gql`
  query EventsAnswer($eventId: ID!, $memberId: ID!) {
    eventAnswers(filter: { event_Overlap: [$eventId], member_Overlap: [$memberId] }) {
      objects {
        id
        question {
          ...customEventSurveyForm
        }
        category
        answer
        createdAt
        updatedAt
        file {
          key
          mime
          size
          url
        }
      }
    }
  }
  fragment customEventSurveyForm on EventQuestion {
    id
    title
    description
    category
    placeholder
    isActive
    isRequired
    placeholder
    updatedAt
  }
`;

const styles = {
  title: {
    display: "block",
    marginTop: 0,
  },
  description: {
    display: "block",
    margintop: "12px",
    marginBottom: "12px",
  },
  image: {
    display: "block",
    width: "100%",
    marginBottom: "22px",
  },
  answer: {
    display: "block",
    marginBottom: "22px",
  },
};
