import { gql } from "@apollo/client";

import { FormPage } from "../../components/pages";
import { TRANSLATION_CODE_FRAGMENT } from "../../utils/gql/fragments";

export const TranslationCodeForm = () => (
  <FormPage
    resource="translationCode"
    fields={[
      {
        name: "code",
      },
      {
        name: "images",
        type: "imageMeta",
        multiple: true,
      },
      {
        name: "memo",
        rows: 3,
      },
    ]}
    query={QUERY}
    createMutation={CREATE_MUTATION}
    updateMutation={UPDATE_MUTATION}
  />
);

// ==

const QUERY = gql`
  query translationCodeForTranslationCodeForm($id: ID!) {
    translationCode(filter: { id_Overlap: [$id] }) {
      id
      code
      images {
        ...ImageFields
      }
      memo
    }
  }
  ${FormPage.fragments.image}
`;

const CREATE_MUTATION = gql`
  mutation createTranslationCodeForTranslationCodeForm(
    $data: TranslationCodeMutationInput!
  ) {
    createTranslationCode(data: $data) {
      ok
      errors {
        ...MutationErrorFields
      }
      object {
        ...TranslationCodeFields
      }
    }
  }
  ${FormPage.fragments.mutationError}
  ${TRANSLATION_CODE_FRAGMENT}
`;

const UPDATE_MUTATION = gql`
  mutation updateTranslationCodeForTranslationCodeForm(
    $data: TranslationCodeMutationInput!
    $id: ID!
  ) {
    updateTranslationCode(data: $data, id: $id, partial: true) {
      ok
      errors {
        ...MutationErrorFields
      }
      object {
        ...TranslationCodeFields
      }
    }
  }
  ${FormPage.fragments.mutationError}
  ${TRANSLATION_CODE_FRAGMENT}
`;
