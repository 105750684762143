import { gql } from "@apollo/client";

import { DetailPage } from "../../components/pages";

export const PlaylistEntryDetail = () => (
  <DetailPage
    resource="playlistEntry"
    fields={[
      {
        name: "id",
      },
      {
        name: "playlist",
        type: "object",
        resource: "playlist",
      },
      {
        name: "clip",
        type: "object",
        resource: "clip",
      },
      {
        name: "order",
        type: "integer",
      },
      ...DetailPage.fields.tracked,
    ]}
    query={QUERY}
    extraActions={DetailPage.extraActions.delete}
  />
);

// ==

const QUERY = gql`
  query playlistEntryForPlaylistEntryDetail($id: ID!) {
    playlistEntry(filter: { id_Overlap: [$id] }) {
      id
      playlist {
        id
        name
      }
      clip {
        id
        title
      }
      order
      ...TrackedFields
    }
  }
  ${DetailPage.fragments.tracked}
`;
