import type { BuiltField } from "@/types/field";
import type { BuiltResourceMap, ResourceNameHas } from "@/types/resource";

export function getSelectComponent(field: BuiltField, resources: BuiltResourceMap) {
  return "selectComponent" in field
    ? field.selectComponent
    : "resource" in field
    ? resources[field.resource as ResourceNameHas<"selectComponent">]?.selectComponent
    : undefined;
}
