import { gql } from "@apollo/client";

import { FormPage } from "../../components/pages";

export const ArtistForm = () => (
  <FormPage
    resource="artist"
    fields={[
      {
        type: "fieldset",
        fields: [
          {
            name: "group",
            type: "object",
            resource: "group",
            disabled: ({ isUpdate }) => isUpdate,
          },
          {
            name: "code",
            descriptionKey: "admin.form.guide.code",
          },
          {
            name: "nameContent",
            labelKey: "admin.field.stageName",
            type: "translatable",
          },
          {
            name: "birthDate",
            type: "date",
          },
        ],
      },
      {
        type: "fieldset",
        labelKey: "admin.fieldset.account",
        fields: [
          {
            name: "user",
            type: "nested",
            fields: [
              {
                name: "email",
                type: "email",
              },
              {
                name: "phone",
                type: "phone",
              },
              {
                name: "name",
              },
              {
                name: "country",
                type: "nestedObject",
                resource: "country",
              },
              {
                name: "serviceLanguage",
                type: "nestedObject",
                resource: "language",
                baseFilter: {
                  isActiveForService_Exact: true,
                },
              },
              {
                name: "translationLanguage",
                type: "nestedObject",
                resource: "language",
                baseFilter: {
                  isActiveForTranslation_Exact: true,
                },
              },
            ],
          },
        ],
      },
      {
        type: "fieldset",
        labelKey: "admin.fieldset.profile",
        fields: [
          {
            name: "nickname",
            helpKey: "admin.form.guide.nicknameArtist",
          },
          {
            name: "profileImage",
            type: "imageMeta",
            helpKey: "admin.form.guide.profileImageArtist",
          },
          {
            name: "coverImage",
            type: "imageMeta",
            helpKey: "admin.form.guide.coverImageArtist",
          },
        ],
      },
    ]}
    query={QUERY}
    createMutation={CREATE_MUTATION}
    updateMutation={UPDATE_MUTATION}
  />
);

// ==

const FORM_FIELDS_FRAGMENT = gql`
  fragment ArtistFormFields on Artist {
    id
    group {
      id
    }
    user {
      id
      email
      phone
      name
      country {
        id
      }
      serviceLanguage {
        id
      }
      translationLanguage {
        id
      }
    }
    code
    name
    nameContent {
      ...TranslatableContentFields
    }
    birthDate
    coverImage {
      ...ImageFields
    }
    nickname
    profileImage {
      ...ImageFields
    }
  }
  ${FormPage.fragments.image}
  ${FormPage.fragments.translatableContent}
`;

const QUERY = gql`
  query artistForArtistForm($id: ID!) {
    artist(filter: { id_Overlap: [$id] }) {
      ...ArtistFormFields
    }
  }
  ${FORM_FIELDS_FRAGMENT}
`;

const CREATE_MUTATION = gql`
  mutation createArtistForArtistForm($data: ArtistMutationInput!) {
    createArtist(data: $data) {
      ok
      errors {
        ...MutationErrorFields
      }
      object {
        ...ArtistFormFields
      }
    }
  }
  ${FormPage.fragments.mutationError}
  ${FORM_FIELDS_FRAGMENT}
`;

const UPDATE_MUTATION = gql`
  mutation updateArtistForArtistForm($data: ArtistMutationInput!, $id: ID!) {
    updateArtist(data: $data, id: $id, partial: true) {
      ok
      errors {
        ...MutationErrorFields
      }
      object {
        ...ArtistFormFields
      }
    }
  }
  ${FormPage.fragments.mutationError}
  ${FORM_FIELDS_FRAGMENT}
`;
