import { gql } from "@apollo/client";

import { DuplicateMediaLayoutButton } from "../../components/actions";
import { ListPage, ListPageWithGroup } from "../../components/pages";

export const MediaLayoutList = () => (
  <ListPageWithGroup
    groupFilterKey="group_Overlap"
    resource="mediaLayout"
    subtitleKey="admin.page.mediaLayout.subtitle"
    fields={[
      {
        name: "id",
      },
      {
        name: "group",
        type: "object",
        resource: "group",
        filterKey: "group_Overlap",
      },
      {
        name: "name",
        filterKey: "name_Text",
      },
      {
        name: "status",
        type: "choice",
        enumType: "MediaLayoutStatus",
        filterKey: "status_Overlap",
      },
      {
        name: "activateAt",
        type: "datetime",
      },
      ...ListPage.fields.tracked,
    ]}
    query={QUERY}
    extraRowActions={(props) => (
      <>
        <DuplicateMediaLayoutButton
          mediaLayoutId={props.obj.id}
          size="small"
          onlyIcon
        />
        {ListPage.extraRowActions.canActivate(props)}
      </>
    )}
  />
);

// ==

const QUERY = gql`
  query mediaLayoutsForMediaLayoutList(
    $filter: MediaLayoutFilterInput
    $sort: [MediaLayoutSortInput]
    $page: PageInput
  ) {
    mediaLayouts(filter: $filter, sort: $sort, page: $page) {
      objects {
        id
        group {
          id
          name
        }
        name
        status
        ...TrackedFields
        ...CanActivateFields
      }
      ...ListFields
    }
  }
  ${ListPage.fragments.list}
  ${ListPage.fragments.tracked}
  ${ListPage.fragments.canActivate}
`;
