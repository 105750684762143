import { useState } from "react";
import { useTranslation } from "react-i18next";

import { gql, useMutation } from "@apollo/client";

import { CloudUploadOutlined } from "@ant-design/icons";
import { Button, Modal, Typography } from "antd";

import { useApp } from "../../hooks/app";
import { MUTATION_ERROR_FRAGMENT } from "../../utils/gql/fragments";

export const PublishTranslationsButton = ({ onChange, ...props }) => {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [publish, { loading }] = useMutation(MUTATION);
  const { message } = useApp();

  // ==

  const onClick = () => setIsModalOpen(true);
  const onCancel = () => setIsModalOpen(false);
  const onOk = () =>
    publish({
      onCompleted: (response) => {
        const { ok, errors } = Object.values(response)[0];
        if (ok) {
          message.success(t("admin.message.processSuccess"));
          onChange?.();
          setIsModalOpen(false);
        } else {
          message.error(
            t("admin.message.processFailure", {
              message: errors[0].messages[0],
            }),
          );
        }
      },
    });

  return (
    <>
      <Button
        danger
        icon={<CloudUploadOutlined />}
        onClick={onClick}
        loading={loading}
        {...props}
      >
        {t("admin.common.publish")}
      </Button>
      <Modal
        title={t(`admin.common.confirmation`)}
        open={isModalOpen}
        confirmLoading={loading}
        okText={t("admin.common.publish")}
        okButtonProps={{
          icon: <CloudUploadOutlined />,
          danger: true,
        }}
        cancelText={t("admin.common.cancel")}
        onOk={onOk}
        onCancel={onCancel}
      >
        <Typography.Text>
          {t("admin.component.publishTranslationsButton.message")}
        </Typography.Text>
      </Modal>
    </>
  );
};

// ==

const MUTATION = gql`
  mutation publishTranslations {
    publishTranslations {
      ok
      errors {
        ...MutationErrorFields
      }
    }
  }
  ${MUTATION_ERROR_FRAGMENT}
`;
