import { useState } from "react";
import { useTranslation } from "react-i18next";

import { gql, useMutation } from "@apollo/client";

import { Button, Modal, Typography } from "antd";

import { useRevalidateQueryFields } from "../../hooks/apollo";
import { useApp } from "../../hooks/app";
import { MUTATION_ERROR_FRAGMENT } from "../../utils/gql/fragments";

export const AnnounceWinnersButton = ({ eventId, disabled }) => {
  const { t } = useTranslation();
  const { message } = useApp();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [validationError, setValidationError] = useState(null);
  const revalidate = useRevalidateQueryFields("eventV2Winners", "eventV2Applications");

  const [validateAnnounceWinner, { loading: loadingForValidation }] = useMutation(
    VALIDATE_MUTATION,
    {
      variables: { id: eventId },
      onCompleted: (response) => {
        const { ok, errors } = Object.values(response)[0];
        if (ok) {
          setValidationError(null);
        } else {
          const errMsg = errors[0].messages.join("\n");
          setValidationError(errMsg);
        }
        setIsModalOpen(true);
      },
    },
  );

  const [announceWinner, { loading: loadingForAnnounce }] = useMutation(
    ANNOUNCE_MUTATION,
    {
      variables: { id: eventId },
      onCompleted: (response) => {
        const { ok, errors } = Object.values(response)[0];
        if (ok) {
          message.success(t("admin.message.processSuccess"));
          revalidate();
        } else {
          const errMsg = errors[0].messages.join("\n");
          message.error(errMsg);
        }
        setIsModalOpen(false);
      },
    },
  );

  const onClick = () => validateAnnounceWinner();
  const onOk = () => announceWinner();
  const onCancel = () => setIsModalOpen(false);

  return (
    <>
      <Button loading={loadingForValidation} onClick={onClick} disabled={disabled}>
        {t("admin.component.announceWinnersButton.title")}
      </Button>
      <Modal
        title={t("admin.common.confirmation")}
        open={isModalOpen}
        confirmLoading={loadingForAnnounce}
        okText={t("admin.component.announceWinnersButton.confirm")}
        cancelText={t("admin.common.cancel")}
        onOk={onOk}
        onCancel={onCancel}
      >
        {validationError ? (
          <Typography.Text type="danger">{validationError}</Typography.Text>
        ) : (
          <Typography.Text>
            {t("admin.component.announceWinnersButton.confirmMessage")}
          </Typography.Text>
        )}
      </Modal>
    </>
  );
};

// Validate Mutation
const VALIDATE_MUTATION = gql`
  mutation validateAnnounceWinners($id: ID!) {
    validateAnnounceWinners(id: $id) {
      ok
      errors {
        ...MutationErrorFields
      }
    }
  }
  ${MUTATION_ERROR_FRAGMENT}
`;

// Announce Mutation
const ANNOUNCE_MUTATION = gql`
  mutation announceWinners($id: ID!) {
    announceWinners(id: $id) {
      ok
      errors {
        ...MutationErrorFields
      }
    }
  }
  ${MUTATION_ERROR_FRAGMENT}
`;
