import type { FC, ForwardedRef, PropsWithChildren, ReactNode, RefObject } from "react";
import {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { FormProvider, useForm } from "react-hook-form";

import { Alert, Divider, Drawer, Space } from "antd";

import { v4 as uuidv4 } from "uuid";

import config from "@/config";
import type { DrawerProps } from "@/hooks/drawer";
import { type BuiltFormFieldsMeta, useFieldConfirmations } from "@/hooks/fields";
import type { FormContext } from "@/types/form";
import type { RecordType } from "@/types/primitives";
import { isEmpty } from "@/utils/helpers";

import { Fields } from "./Fields";
import { FormActions, type FormActionsOptions } from "./FormActions";
import { FormScrollHelper } from "./FormScrollHelper";

/**
 * Basic form props.
 */
export type FormProps<FormValue extends RecordType> =
  // prettier-ignore
  & {
    /** the result of useFormFields */
    meta: BuiltFormFieldsMeta<FormValue>;
    /**
     * if form nested, it will not be wrapped in the <form> element
     * @default false
     */
    nested?: boolean;
    /** @default false */
    isUpdate?: boolean;
    defaultValues?: RecordType;
    /** @default false */
    loading?: boolean;
    nonFieldError?: ReactNode;
    onSubmit: (object: FormValue) => void;
  }
  & FormActionsOptions<FormValue>
  & FormModeVariantOptions;

/**
 * Form has "default" or "drawer" mode and has different props for each mode.
 */
type FormModeVariantOptions =
  | {
      mode?: "default";
      formScrollHelperRef?: RefObject<HTMLDivElement>;
    }
  | {
      mode: "drawer";
      title?: string;
      /** @default config.defaultDrawerWidth */
      drawerWidth?: number;
      drawerProps: DrawerProps;
    };

/**
 * Form reference. parent component can access form context
 */
export type FormRef = Pick<
  FormContext,
  "setError" | "setFocus" | "setValue" | "resetField" | "clearErrors" | "reset"
>;

const ModeVariantWrapper: FC<
  PropsWithChildren<
    FormModeVariantOptions & {
      action: ReactNode;
      setFocus: FormContext["setFocus"];
    }
  >
> = (props) => {
  const { mode, action, children } = props;
  if (mode === "drawer") {
    const {
      drawerWidth = config.defaultDrawerWidth,
      drawerProps,
      setFocus,
      title,
    } = props;
    return (
      <Drawer
        title={title}
        width={drawerWidth}
        extra={<Space>{action}</Space>}
        {...drawerProps}
        // FIXME: avoid using this pattern
        afterOpenChange={(open) => drawerProps.afterOpenChange?.(open, { setFocus })}
      >
        {children}
      </Drawer>
    );
  }

  return (
    <>
      {children}
      <Divider />
      <Space className="flex justify-start">{action}</Space>
    </>
  );
};

const FormWrapper: FC<
  PropsWithChildren<{ nested: boolean; id: string; onSubmit: () => Promise<void> }>
> = ({ nested, id, onSubmit, children }) => {
  if (nested) return children;
  return (
    <form id={id} onSubmit={onSubmit}>
      {children}
    </form>
  );
};

const Form = forwardRef(
  <FormValue extends RecordType>(
    props: FormProps<FormValue>,
    ref: ForwardedRef<FormRef>,
  ) => {
    const {
      nested = false,
      meta,
      isUpdate = false,
      loading = false,
      nonFieldError,
      submitButtonOptions,
      onSubmit,
      extraActions,
      ...conditionalProps
    } = props;
    const { mode } = conditionalProps;
    const formId = useRef(uuidv4());
    const [editingLanguage, setEditingLanguage] = useState();
    const { fields, flatFields, normalizeData, defaultValues } = meta;
    const formContext = useForm({ defaultValues });
    const { confirmFields, ConfirmationModal } = useFieldConfirmations(flatFields);
    const {
      clearErrors,
      formState: { errors },
      handleSubmit,
      reset,
      resetField,
      setError,
      setFocus,
      setValue,
      watch,
    } = formContext;

    const data = watch();
    const getFormData = useCallback(
      () => normalizeData({ data: data as FormValue, isUpdate }),
      [data, isUpdate, normalizeData],
    );
    const disabled = loading || !isEmpty(errors);
    const resetValue = useCallback(
      (fieldName: string) => setValue(fieldName, null),
      [setValue],
    );
    const validateSubmit = useCallback(
      async (data: RecordType) => {
        const normalizedData = normalizeData({ data: data as FormValue, isUpdate });
        const confirmed = await confirmFields(normalizedData);
        if (!confirmed) return;

        onSubmit(normalizedData);
      },
      [confirmFields, normalizeData, onSubmit, isUpdate],
    );
    const submit = useMemo(
      () => handleSubmit(validateSubmit),
      [handleSubmit, validateSubmit],
    );

    const extraFormContext = {
      data,
      defaultValues,
      errors,
      isUpdate,
      resetValue,
      editingLanguage,
      setEditingLanguage,
    };

    useImperativeHandle(
      ref,
      () => ({ setError, setFocus, setValue, resetField, clearErrors, reset }),
      [setError, setFocus, setValue, resetField, clearErrors, reset],
    );

    if (!fields) return null;

    return (
      <FormProvider {...formContext} {...extraFormContext}>
        <FormWrapper nested={nested} id={formId.current} onSubmit={submit}>
          <ModeVariantWrapper
            action={
              <FormActions
                nested={nested}
                formId={formId.current}
                loading={loading}
                getFormData={getFormData}
                submitButtonOptions={{
                  ...submitButtonOptions,
                  disabled: submitButtonOptions?.disabled || disabled,
                }}
                extraActions={extraActions}
                onSubmit={submit}
              />
            }
            setFocus={setFocus}
            {...conditionalProps}
          >
            <Fields fields={fields} />
            {nonFieldError && (
              <Alert className="mt-24" type="error" message={nonFieldError} />
            )}
          </ModeVariantWrapper>
        </FormWrapper>
        {mode !== "drawer" && conditionalProps.formScrollHelperRef ? (
          <FormScrollHelper
            portalRef={conditionalProps.formScrollHelperRef}
            fields={fields}
          />
        ) : null}
        {ConfirmationModal}
      </FormProvider>
    );
  },
);

// == for using generic in forwardRef components
const _Form = Form as <FormValue extends RecordType>(
  props: FormProps<FormValue> & { ref?: ForwardedRef<FormRef> },
) => ReactNode;

export { _Form as Form };
