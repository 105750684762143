import { Col, Row } from "antd";

import { TranslationRequestTargetContentForm } from "./TranslationRequestTargetContentForm";
import { TranslationRequestTargetContentRevisions } from "./TranslationRequestTargetContentRevisions";

export const TranslationRequestTargetContentPanel = ({ targetContent, showForm }) => {
  return (
    <Row gutter={16}>
      <Col span={showForm ? 12 : 24}>
        <TranslationRequestTargetContentRevisions targetContent={targetContent} />
      </Col>
      {showForm && (
        <Col span={12}>
          <TranslationRequestTargetContentForm targetContent={targetContent} />
        </Col>
      )}
    </Row>
  );
};
