import { gql } from "@apollo/client";

import dayjs from "dayjs";

import {
  OfflineStoreItemEditor,
  OfflineStoreOperatingScheduleEditor,
} from "../../components/fields";
import { GoogleMapUrlEditor } from "../../components/fields/GoogleMapUrlEditor";
import { FormPage } from "../../components/pages";
import { isArray, pick } from "../../utils/helpers";

const normalizeImageField = (value) =>
  value
    ? isArray(value)
      ? value.map(normalizeImageField)
      : pick(value, ["key", "mime", "size", "width", "height"])
    : value;

export const OfflineStoreForm = () => (
  <FormPage
    resource="offlineStore"
    fields={[
      {
        type: "fieldset",
        label: "",
        fields: [
          {
            name: "group",
            type: "object",
            resource: "group",
            disabled: ({ isUpdate }) => isUpdate,
          },
        ],
      },
      {
        type: "fieldset",
        labelKey: "admin.fieldset.content",
        fields: [
          {
            name: "nameContent",
            labelKey: "admin.field.name",
            type: "translatable",
          },
          {
            name: "descriptionContent",
            labelKey: "admin.field.description",
            type: "translatable",
            markdown: true,
            rows: 50,
          },
          {
            name: "location",
            labelKey: "admin.field.location",
            type: "text",
          },
          {
            name: "bannerImage",
            type: "imageMeta",
          },
        ],
      },
      {
        type: "fieldset",
        labelKey: "admin.fieldset.operatingSchedules",
        fields: [
          {
            name: "operatingSchedules",
            label: "",
            getter: ({ operatingSchedules, startDate, endDate }) => ({
              operatingSchedules,
              startDate,
              endDate,
            }),
            normalizer: ({ operatingSchedules, startDate, endDate }) => ({
              operatingSchedules,
              startDate,
              endDate,
            }),
            render: ({ controllerField }) => (
              <OfflineStoreOperatingScheduleEditor {...controllerField} />
            ),
          },
        ],
      },
      {
        type: "fieldset",
        labelKey: "admin.fieldset.googleMapUrl",
        fields: [
          {
            name: "googleMapUrl",
            label: "",
            getter: ({ googleMapUrl }) => ({
              googleMapUrl,
            }),
            normalizer: ({ googleMapUrl }) => ({
              googleMapUrl,
            }),
            render: ({ controllerField }) => (
              <GoogleMapUrlEditor {...controllerField} />
            ),
          },
        ],
      },
      {
        type: "fieldset",
        labelKey: "admin.fieldset.items",
        fields: [
          {
            name: "items",
            label: "",
            // FIXME: Make this beautiful
            getter: ({ items }) => ({
              items: items?.objects,
            }),
            normalizer: ({ items }) => ({
              items: items?.map((item) => ({
                ...item,
                image: normalizeImageField(item.image),
                isSingular: undefined,
              })),
            }),
            render: ({ controllerField }) => (
              <OfflineStoreItemEditor {...controllerField} />
            ),
          },
        ],
      },
      ...FormPage.fields.canActivate,
    ]}
    query={QUERY}
    createMutation={CREATE_MUTATION}
    updateMutation={UPDATE_MUTATION}
  />
);

// ==

const FORM_FIELDS_FRAGMENT = gql`
  fragment OfflineStoreFormFields on OfflineStore {
    id
    group {
      id
    }
    location
    nameContent {
      ...TranslatableContentFields
    }
    descriptionContent {
      ...TranslatableContentFields
    }
    bannerImage {
      ...ImageFields
    }
    startDate
    endDate
    operatingSchedules {
      scheduleCategory
      date
      openTime
      closeTime
    }
    googleMapUrl
    items(page: { first: 100 }) {
      objects {
        id
        nameContent {
          ...TranslatableContentFields
        }
        order
        price
        image {
          ...ImageFields
        }
        detailUrl
        availability
        restrictions {
          id
          category
          count
        }
        variants {
          id
          label
          availability
        }
        ...CanActivateFields
      }
    }
    ...CanActivateFields
  }
  ${FormPage.fragments.canActivate}
  ${FormPage.fragments.image}
  ${FormPage.fragments.translatableContent}
`;

const QUERY = gql`
  query offlineStoreForOfflineStoreForm($id: ID!) {
    offlineStore(filter: { id_Overlap: [$id] }) {
      ...OfflineStoreFormFields
    }
  }
  ${FORM_FIELDS_FRAGMENT}
`;

const CREATE_MUTATION = gql`
  mutation createOfflineStoreForOfflineStoreForm($data: OfflineStoreMutationInput!) {
    createOfflineStore(data: $data) {
      ok
      errors {
        ...MutationErrorFields
      }
      object {
        ...OfflineStoreFormFields
      }
    }
  }
  ${FormPage.fragments.mutationError}
  ${FORM_FIELDS_FRAGMENT}
`;

const UPDATE_MUTATION = gql`
  mutation updateOfflineStoreForOfflineStoreForm(
    $data: OfflineStoreMutationInput!
    $id: ID!
  ) {
    updateOfflineStore(data: $data, id: $id, partial: true) {
      ok
      errors {
        ...MutationErrorFields
      }
      object {
        ...OfflineStoreFormFields
      }
    }
  }
  ${FormPage.fragments.mutationError}
  ${FORM_FIELDS_FRAGMENT}
`;
