import { Typography } from "antd";

import dayjs from "dayjs";

import { useTimezone } from "../../hooks/tz";

const { Text } = Typography;

export const TimeField = ({ value, format = "HH:mm:ss", ...props }) => {
  const { timezone } = useTimezone();
  return <Text {...props}>{dayjs(value).tz(timezone).format(format)}</Text>;
};
