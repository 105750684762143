import { gql } from "@apollo/client";

import { ListWidget } from "../list";

export const CompanyListWidget = (props) => (
  <ListWidget
    resource="company"
    fields={[
      {
        name: "id",
      },
      {
        name: "code",
        filterKey: "code_Exact",
        sortKey: "CODE",
      },
      {
        name: "name",
        filterKey: "name_Text",
      },
      {
        name: "owner",
        type: "object",
        resource: "staff",
        filterKey: "owner_Overlap",
      },
      {
        name: "groupCount",
        type: "integer",
        filterKey: "groupCount_Range",
        sortKey: "GROUP_COUNT",
      },
      {
        name: "artistCount",
        type: "integer",
        filterKey: "artistCount_Range",
        sortKey: "ARTIST_COUNT",
      },
      ...ListWidget.fields.canActivate,
      ...ListWidget.fields.tracked,
    ]}
    query={QUERY}
    extraRowActions={ListWidget.extraRowActions.canActivate}
    {...props}
  />
);

// ==

const QUERY = gql`
  query companiesForCompanyListWidget(
    $filter: CompanyFilterInput
    $sort: [CompanySortInput]
    $page: PageInput
  ) {
    companies(filter: $filter, sort: $sort, page: $page) {
      objects {
        id
        code
        name
        owner {
          id
          user {
            id
            email
            name
          }
        }
        groupCount
        artistCount
        ...TrackedFields
        ...CanActivateFields
      }
      ...ListFields
    }
  }
  ${ListWidget.fragments.list}
  ${ListWidget.fragments.tracked}
  ${ListWidget.fragments.canActivate}
`;
