import { useTranslation } from "react-i18next";

import { gql } from "@apollo/client";

import { useDrawerState } from "../../hooks/drawer";
import { TRANSLATION_CODE_FRAGMENT } from "../../utils/gql/fragments";
import { FormDrawer } from "../drawers";

export const EditTranslationCodeAction = ({
  translationCodeId,
  onChange,
  disabled = false,
  children,
  ...props
}) => {
  const { t } = useTranslation();
  const { doesRender, openDrawer, closeDrawer, drawerProps } = useDrawerState();

  // ==

  const onSuccess = (value) => {
    onChange?.(value);
    closeDrawer();
  };

  return (
    <>
      {children?.({ openDrawer, disabled })}
      {doesRender && (
        <FormDrawer
          title={t("admin.common.actionTitle", {
            action: translationCodeId ? t("admin.common.edit") : t("admin.common.add"),
            name: t("admin.resource.translationCode.singular"),
          })}
          resource="translationCode"
          fields={[
            {
              name: "code",
              disabled: ({ isUpdate }) => isUpdate,
            },
            {
              name: "context",
              type: "choice",
              enumType: "TranslationContext",
              readOnly: true,
              disabled: true,
              control: "select",
              hidden: ({ isUpdate }) => !isUpdate,
            },
            {
              name: "memo",
              rows: 3,
            },
            {
              name: "images",
              type: "imageMeta",
              multiple: true,
            },
          ]}
          id={translationCodeId}
          query={QUERY}
          createMutation={CREATE_MUTATION}
          updateMutation={UPDATE_MUTATION}
          onSuccess={onSuccess}
          drawerProps={drawerProps}
          {...props}
        />
      )}
    </>
  );
};

// ==

const QUERY = gql`
  query translationCodeForEditTranslationCodeAction($id: ID!) {
    translationCode(filter: { id_Overlap: [$id] }) {
      id
      code
      context
      images {
        ...ImageFields
      }
      memo
    }
  }
  ${FormDrawer.fragments.image}
`;

const CREATE_MUTATION = gql`
  mutation createTranslationCodeForEditTranslationCodeAction(
    $data: TranslationCodeMutationInput!
  ) {
    createTranslationCode(data: $data) {
      ok
      errors {
        ...MutationErrorFields
      }
      object {
        ...TranslationCodeFields
      }
    }
  }
  ${FormDrawer.fragments.mutationError}
  ${TRANSLATION_CODE_FRAGMENT}
`;

const UPDATE_MUTATION = gql`
  mutation updateTranslationCodeForEditTranslationCodeAction(
    $data: TranslationCodeMutationInput!
    $id: ID!
  ) {
    updateTranslationCode(data: $data, id: $id, partial: true) {
      ok
      errors {
        ...MutationErrorFields
      }
      object {
        ...TranslationCodeFields
      }
    }
  }
  ${FormDrawer.fragments.mutationError}
  ${TRANSLATION_CODE_FRAGMENT}
`;
