import { gql } from "@apollo/client";

import { ListWidget } from "../list";

export const MemberEventV2ApplicationListWidget = ({ obj, filter, ...props }) => {
  return (
    <ListWidget
      resource="eventV2Application"
      fields={[
        {
          name: "createdAt",
          labelKey: "admin.field.appliedAt",
          type: "datetime",
        },
        {
          name: "event",
          type: "object",
          resource: "eventV2",
          filterKey: "event_Overlap",
        },
        {
          name: "event.category",
          labelKey: "admin.field.category",
          type: "choice",
          enumType: "EventV2Category",
        },
        {
          name: "event.status",
          type: "choice",
          labelKey: "admin.field.eventStatus",
          enumType: "EventV2Status",
        },
        {
          name: "isActive",
          type: "boolean",
          labelKey: "admin.field.applicationIsActive",
        },
        {
          name: "internalState",
          labelKey: "admin.field.applicationState",
          type: "choice",
          enumType: "EventV2ApplicationState",
          filterKey: "internalState_Overlap",
        },
        {
          name: "event.winnerAnnouncedAt",
          labelKey: "admin.field.winnerAnnouncedAt",
          type: "datetime",
        },
      ]}
      query={QUERY}
      filter={filter}
      {...props}
    />
  );
};

// ==

const QUERY = gql`
  query eventV2ApplicationsForMemberEventV2ApplicationListWidget(
    $filter: EventV2ApplicationFilterInput
    $sort: [EventV2ApplicationSortInput]
    $page: PageInput
  ) {
    eventV2Applications(filter: $filter, sort: $sort, page: $page) {
      objects {
        id
        member {
          id
          isActive
        }
        event {
          id
          title
          category
          status
          winnerAnnouncedAt
        }
        state
        internalState
        isActive
        ...TrackedFields
      }
      ...ListFields
    }
  }
  ${ListWidget.fragments.list}
  ${ListWidget.fragments.tracked}
`;
