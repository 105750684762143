import { PlayCircleOutlined } from "@ant-design/icons";

export const PlayableImage = ({ src, style = undefined, ...props }) => (
  <div style={{ ...styles.container, ...style }} {...props}>
    <PlayCircleOutlined style={styles.icon} />
    <img src={src} style={styles.image} />
  </div>
);

// ==

const styles = {
  container: {
    position: "relative",
  },
  icon: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginLeft: -16,
    marginTop: -16,
    fontSize: 32,
    color: "white",
  },
  image: {
    width: "100%",
  },
};
