import { useState } from "react";

import { gql, useMutation } from "@apollo/client";

import { Button, Col, Drawer, InputNumber, Row, Space } from "antd";
import TextArea from "antd/es/input/TextArea";

import { t } from "i18next";

import { SelectEventV2PenaltyCategory } from "../../components/selects";
import { useRevalidateQueryFields } from "../../hooks/apollo";
import { useApp } from "../../hooks/app";
import { MUTATION_ERROR_FRAGMENT } from "../../utils/gql/fragments";

export const SelectPenaltyButton = ({ eventId, selectedRowKeys, disabled = false }) => {
  const { message } = useApp();
  const [open, setOpen] = useState(false);
  const [memo, setMemo] = useState("");
  const [count, setCount] = useState(undefined);
  const [category, setCategory] = useState(undefined);
  const [applyPenalty, { loading, error }] = useMutation(MUTATION);
  const revalidate = useRevalidateQueryFields("eventV2Winners", "eventV2Applications");

  const handleOk = async () => {
    await applyPenalty({
      variables: {
        eventId: eventId,
        winnerIds: selectedRowKeys,
        memo: memo,
        categoryId: category,
        count: count,
      },
    });
    revalidate();
    message.success(t("admin.message.processSuccess"));
    handleDrawer(false);
  };

  const handleClose = () => {
    handleDrawer(false);
  };

  const showDrawer = () => {
    handleDrawer(true);
  };
  const handleCategoryChange = (value) => setCategory(value);

  const reset = () => {
    setMemo("");
    setCount(undefined);
    setCategory(undefined);
    selectedRowKeys = [];
  };

  const handleDrawer = (value) => {
    setOpen(value);
    reset();
  };

  return (
    <Space>
      <Button
        type="primary"
        danger
        onClick={showDrawer}
        loading={loading}
        disabled={disabled}
      >
        {t("admin.component.selectPenaltyButton.title")}
      </Button>
      <Drawer
        title={t("admin.menu.eventPenalty")}
        extra={
          <Space>
            <Button type="primary" onClick={() => handleOk()}>
              {t("admin.common.ok")}
            </Button>
          </Space>
        }
        open={open}
        onClose={handleClose}
      >
        <Row gutter={[16, 20]}>
          <Col span={24}>
            <SelectEventV2PenaltyCategory
              value={category}
              onChange={handleCategoryChange}
            />
          </Col>
          <Col span={24}>
            <InputNumber
              value={count}
              onChange={(count) => {
                setCount(count);
              }}
            ></InputNumber>
            <span style={{ color: "white" }}>{t("admin.form.guide.penaltyCount")}</span>
          </Col>
          <Col span={24}>
            <TextArea
              placeholder={t("admin.field.memo")}
              rows={4}
              value={memo}
              onChange={(e) => setMemo(e.target.value)}
            />
          </Col>
        </Row>
      </Drawer>
    </Space>
  );
};

// ==

const MUTATION = gql`
  mutation selectPenaltyForSelectPenaltyButton(
    $winnerIds: [ID!]!
    $eventId: ID!
    $memo: String
    $categoryId: ID!
    $count: Int!
  ) {
    selectPenalty(
      winnerIds: $winnerIds
      eventId: $eventId
      memo: $memo
      categoryId: $categoryId
      count: $count
    ) {
      ok
      errors {
        ...MutationErrorFields
      }
    }
  }
  ${MUTATION_ERROR_FRAGMENT}
`;
