import type { ComponentProps, ElementType, ReactNode } from "react";

import { useResolvedTranslation } from "@/hooks/i18n";
import type { CanBeNil, ResolvableValue } from "@/types/utils";

type Props<Args, Wrapper extends ElementType> = {
  value?: ResolvableValue<ReactNode, Args>;
  /** if there is value unresolved, valueKey will be resolved and then translated */
  valueKey?: ResolvableValue<CanBeNil<string>, Args>;
  /** even if translateKey unresolved, fallbackKey will be resolved and then translated */
  fallbackKey?: string;
  args?: Args;
  /** if resolved, wrapper will wrap resolved node */
  wrapper?: Wrapper;
  wrapperProps?: ComponentProps<Wrapper>;
};

export function ResolvedText<Args, Wrapper extends ElementType>(
  props: Props<Args, Wrapper>,
) {
  const { value, valueKey, fallbackKey, args, wrapper, wrapperProps } = props;
  const resolvedValue = useResolvedTranslation(value, valueKey, {
    fallbackKey,
    args,
  });
  if (!resolvedValue) return null;
  const Wrapper = wrapper || "span";
  return <Wrapper {...wrapperProps}>{resolvedValue}</Wrapper>;
}
