import { useCallback } from "react";

import { BuiltFlatField } from "@/types/field";
import type { RecordType } from "@/types/primitives";
import { getByPath, resolveCallable, stripDunderKeys } from "@/utils/helpers";

export function useFormNormalizer<Data extends RecordType>(
  flatFields: BuiltFlatField[],
) {
  return useCallback(
    (context: { data: Data; isUpdate?: boolean }) => {
      const { data } = context;
      return stripDunderKeys(
        Object.fromEntries(
          flatFields
            .filter(
              (field) =>
                !resolveCallable(field.forbidden, context) &&
                !resolveCallable(field.readOnly, context),
            )
            .flatMap(({ normalizer, name }) =>
              Object.entries(
                normalizer(name ? getByPath<RecordType, string>(data, name) : data),
              ),
            ),
        ),
      ) as Data;
    },
    [flatFields],
  );
}
