import React from "react";
import { createPortal } from "react-dom";

import { Anchor } from "antd";
import styled from "styled-components";

import { useFormContext } from "@/components/form/context";
import { BuiltField, FieldsetField, ToBuiltField } from "@/types/field";
import { resolveCallable } from "@/utils/helpers";

interface Props {
  fields: readonly BuiltField[];
  portalRef?: React.RefObject<HTMLDivElement>;
}

export const FormScrollHelper: React.FC<Props> = (props) => {
  const { fields, portalRef } = props;
  const { data } = useFormContext();

  if (!portalRef?.current) return;
  const fieldsets = fields.filter(
    (field): field is ToBuiltField<FieldsetField> =>
      field.type === "fieldset" &&
      !!field.label &&
      !resolveCallable(field.hidden, { field, data }),
  );

  if (fieldsets.length <= 1) return null;

  return createPortal(
    <StyledAnchor
      className="ant-card ant-card-bordered"
      items={fieldsets.map((fieldset) => ({
        key: fieldset.id,
        title: fieldset.label,
        href: `#${fieldset.id}`,
      }))}
    />,
    portalRef.current,
  );
};

const StyledAnchor = styled(Anchor).attrs({
  offsetTop: 64,
})`
  padding: 24px 0;
`;
