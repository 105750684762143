import { gql } from "@apollo/client";

import { MUTATION_ERROR_FRAGMENT } from "../../utils/gql/fragments";
import { DownloadButton } from "./DownloadButton";

export const DownloadPremiumMembershipsButton = (props) => (
  <DownloadButton mutation={MUTATION} {...props} />
);

// ==

const MUTATION = gql`
  mutation sendPremiumMembershipCsvForDownloadPremiumMembershipsButton(
    $filter: JSONString
    $sort: JSONString
  ) {
    sendPremiumMembershipCsv(filter: $filter, sort: $sort) {
      ok
      errors {
        ...MutationErrorFields
      }
    }
  }
  ${MUTATION_ERROR_FRAGMENT}
`;
