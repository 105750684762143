import { gql } from "@apollo/client";

import { ListPage } from "../../components/pages";

export const EventV2PenaltyCategoryList = () => (
  <ListPage
    resource="eventV2PenaltyCategory"
    subtitleKey="admin.page.eventV2PenaltyCategory.subtitle"
    fields={[
      {
        name: "id",
      },
      {
        name: "name",
      },
      ...ListPage.fields.tracked,
    ]}
    query={QUERY}
  />
);

// ==

const QUERY = gql`
  query eventV2PenaltiesForEventV2PenaltyCategoryList(
    $filter: EventV2PenaltyCategoryFilterInput
    $sort: [EventV2PenaltyCategorySortInput]
    $page: PageInput
  ) {
    eventV2PenaltyCategories(filter: $filter, sort: $sort, page: $page) {
      objects {
        id
        name
        ...TrackedFields
      }
      ...ListFields
    }
  }
  ${ListPage.fragments.list}
  ${ListPage.fragments.tracked}
`;
