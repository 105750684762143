import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { gql, useMutation } from "@apollo/client";

import {
  DownloadOutlined,
  EditOutlined,
  EyeOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { Breadcrumb, Button, Divider, Space, Typography } from "antd";

import dayjs from "dayjs";

import {
  EditTranslationCodeAction,
  EditTranslationValueAction,
  PublishTranslationsButton,
  ReviewTranslationValueButton,
  ViewImageAction,
} from "../../components/actions";
import { EnumField } from "../../components/fields";
import { ListTable } from "../../components/list";
import { Can } from "../../components/permission";
import { PageHeader } from "../../components/typography";
import { useApp } from "../../hooks/app";
import { useEnum } from "../../hooks/enum";
import { useAbilities } from "../../hooks/permission";
import {
  IMAGE_FRAGMENT,
  LIST_FRAGMENT,
  MUTATION_ERROR_FRAGMENT,
  TRACKED_FRAGMENT,
} from "../../utils/gql/fragments";

const { Text } = Typography;

export const TranslationEditor = () => {
  const { t } = useTranslation();
  const [download, { loading: downloading }] = useMutation(DOWNLOAD_MUTATION);
  const { values: languages } = useEnum("ServiceLanguage");
  const { message } = useApp();

  const { abilities } = useAbilities(permissions);
  const canReview = useMemo(() => abilities?.[0] ?? false, [abilities]);
  const canPublish = useMemo(() => abilities?.[1] ?? false, [abilities]);
  const canUpdate = useMemo(() => abilities?.[2] ?? false, [abilities]);

  return (
    <Space direction="vertical" size="middle" style={styles.container}>
      <Breadcrumb
        items={[
          { title: <Link to="/">{t("admin.common.home")}</Link> },
          { title: <Link to="">{t("admin.page.translationEditor.title")}</Link> },
        ]}
      />
      <PageHeader
        titleKey="admin.page.translationEditor.title"
        subtitleKey="admin.page.translationEditor.subtitle"
      />
      <Divider style={styles.divider} />
      {languages && (
        <ListTable
          resource="translationCode"
          glabalSearchKey="compositeAdmin_Search"
          fields={[
            {
              name: "id",
              fixed: "left",
            },
            {
              name: "code",
              filterKey: "searchCode_Text",
              fixed: "left",
              width: 300,
              sortKey: "CODE",
            },
            {
              name: "context",
              type: "choice",
              enumType: "TranslationContext",
              filterKey: "context_Overlap",
              fixed: "left",
            },
            {
              name: "images",
              type: "imageMeta",
              align: "center",
              fixed: "left",
              render: (_, row) =>
                row.images?.length > 0 && (
                  <ViewImageAction
                    images={row.images?.map((image) => image.url)}
                    width={Math.min.apply(
                      null,
                      row.images?.map((image) => image.width),
                    )}
                  >
                    {({ openModal }) => (
                      <Button
                        type="text"
                        size="small"
                        icon={<EyeOutlined />}
                        onClick={openModal}
                      />
                    )}
                  </ViewImageAction>
                ),
            },
            {
              name: "memo",
              filterKey: "memo_Text",
              fixed: "left",
            },
            ...(canUpdate
              ? [
                  {
                    name: "action",
                    label: "",
                    fixed: "left",
                    align: "center",
                    render: (_, row) => (
                      <EditTranslationCodeAction translationCodeId={row.id}>
                        {({ openDrawer }) => (
                          <Button
                            type="text"
                            size="small"
                            icon={<EditOutlined />}
                            onClick={openDrawer}
                          />
                        )}
                      </EditTranslationCodeAction>
                    ),
                  },
                ]
              : []),
            ...languages
              .map((language) => [
                {
                  name: `${language.name.toLowerCase()}Value`,
                  label: [language.description, t("admin.field.text")].join(" "),
                  width: 300,
                  type: "boolean",
                  filters: [
                    {
                      text: t("admin.field.filled"),
                      value: true,
                    },
                    {
                      text: t("admin.field.blank"),
                      value: false,
                    },
                  ],
                  filterKey: `${language.name.toLowerCase()}Status_IsNotNull`,
                  render: (_, row) => {
                    const { id, value, status, updatedAt, updatedBy } =
                      row.values.find((value) => value.language === language.name) ??
                      {};
                    const referenceValues = row.values
                      .filter((value) => value.language !== language.name)
                      .map((value) => value.value)
                      .filter(Boolean);
                    return (
                      <Space direction="vertical" size={0}>
                        <EditTranslationValueAction
                          translationValueId={id}
                          defaultValues={{
                            code: row.id,
                            language: language.name,
                            isReadyForReview: true,
                          }}
                          referenceValues={referenceValues}
                          afterOpenChange={(open, { setFocus }) =>
                            open && setFocus("value")
                          }
                          {...(!id && {
                            updateOnMutate: (cache, { data }) => {
                              const { ok } = Object.values(data)[0];
                              if (ok) {
                                cache.evict({
                                  id: "ROOT_QUERY",
                                  fieldName: "translationCodes",
                                });
                                cache.gc();
                              }
                            },
                          })}
                        >
                          {({ openDrawer }) => (
                            <div
                              {...(status !== "PUBLISHED" || canPublish
                                ? {
                                    onClick: openDrawer,
                                    style: { cursor: "pointer" },
                                  }
                                : {
                                    style: { cursor: "not-allowed" },
                                  })}
                            >
                              {value ? (
                                <Text>{value}</Text>
                              ) : (
                                <Text type="secondary" italic style={styles.empty}>
                                  {t("admin.page.translationEditor.empty")}
                                </Text>
                              )}
                            </div>
                          )}
                        </EditTranslationValueAction>
                        {updatedAt && (
                          <Text type="secondary" style={styles.meta}>
                            {updatedBy
                              ? t("admin.common.lastUpdatedBy", {
                                  ago: dayjs(updatedAt).fromNow(),
                                  name: updatedBy.name,
                                })
                              : t("admin.common.lastUpdated", {
                                  ago: dayjs(updatedAt).fromNow(),
                                })}
                          </Text>
                        )}
                      </Space>
                    );
                  },
                },
                {
                  name: `${language.name.toLowerCase()}Status`,
                  label: [language.description, t("admin.field.status")].join(" "),
                  type: "choice",
                  enumType: "TranslationStatus",
                  filterKey: `${language.name.toLowerCase()}Status_Overlap`,
                  render: (_, row) => {
                    const { status } =
                      row.values.find((value) => value.language === language.name) ??
                      {};
                    if (status) {
                      return (
                        <EnumField
                          type="TranslationStatus"
                          value={status}
                          color={
                            {
                              PENDING: "warning",
                              SUBMITTED: "volcano",
                              APPROVED: "success",
                              REJECTED: "error",
                            }[status]
                          }
                        />
                      );
                    }
                  },
                },
                ...(canReview
                  ? [
                      {
                        name: `${language.name}.action`,
                        label: "",
                        align: "center",
                        render: (_, row) => {
                          const { id, status } =
                            row.values.find(
                              (value) => value.language === language.name,
                            ) ?? {};
                          return ["SUBMITTED"].includes(status) ? (
                            <>
                              <ReviewTranslationValueButton
                                translationValueId={id}
                                status="APPROVED"
                              />
                              <ReviewTranslationValueButton
                                translationValueId={id}
                                status="REJECTED"
                              />
                            </>
                          ) : ["APPROVED", "REJECTED"].includes(status) ? (
                            <ReviewTranslationValueButton
                              translationValueId={id}
                              status="SUBMITTED"
                            />
                          ) : ["PUBLISHED"].includes(status) && canPublish ? (
                            <ReviewTranslationValueButton
                              translationValueId={id}
                              status="PENDING"
                            />
                          ) : null;
                        },
                      },
                    ]
                  : []),
              ])
              .flat(),
          ]}
          extraActions={({ refetch }) => (
            <Space style={styles.actions}>
              <Can action="publish" targetType="TRANSLATION_VALUE">
                <PublishTranslationsButton onChange={refetch} />
              </Can>
              <Can action="publish" targetType="TRANSLATION_VALUE">
                <Button
                  icon={<DownloadOutlined />}
                  disabled={downloading}
                  loading={downloading}
                  onClick={() =>
                    download().then(() =>
                      message.success(t("admin.message.requestSuccess")),
                    )
                  }
                >
                  {t("admin.common.download")}
                </Button>
              </Can>
              <Can action="create" targetType="TRANSLATION_CODE">
                <EditTranslationCodeAction
                  title="Add Translation Code"
                  onChange={refetch}
                >
                  {({ openDrawer }) => (
                    <Button icon={<PlusOutlined />} onClick={openDrawer}>
                      {t("admin.page.translationEditor.addCode")}
                    </Button>
                  )}
                </EditTranslationCodeAction>
              </Can>
            </Space>
          )}
          query={QUERY}
          hideCreate
          hideRowActions
        />
      )}
    </Space>
  );
};

// ==

const permissions = [
  {
    action: "review",
    targetType: "TRANSLATION_VALUE",
  },
  {
    action: "publish",
    targetType: "TRANSLATION_VALUE",
  },
  {
    action: "update",
    targetType: "TRANSLATION_CODE",
  },
];

// ==

const QUERY = gql`
  query translationCodesForTranslationEditor(
    $filter: TranslationCodeFilterInput
    $sort: [TranslationCodeSortInput]
    $page: PageInput
  ) {
    translationCodes(filter: $filter, sort: $sort, page: $page) {
      objects {
        id
        code
        context
        images {
          ...ImageFields
        }
        memo
        values {
          id
          language
          status
          value
          updatedAt
          updatedBy {
            id
            name
          }
        }
        ...TrackedFields
      }
      ...ListFields
    }
  }
  ${TRACKED_FRAGMENT}
  ${IMAGE_FRAGMENT}
  ${LIST_FRAGMENT}
`;

const DOWNLOAD_MUTATION = gql`
  mutation sendTranslationCsvForTranslationEditor {
    sendTranslationCsv {
      ok
      errors {
        ...MutationErrorFields
      }
    }
  }
  ${MUTATION_ERROR_FRAGMENT}
`;

// ==

const styles = {
  container: {
    display: "flex",
  },
  divider: {
    marginTop: 6,
    marginBottom: 6,
  },
  empty: {
    opacity: 0.5,
  },
  meta: {
    opacity: 0.8,
    fontSize: "0.9em",
  },
};
