import { useTranslation } from "react-i18next";

import { gql, useMutation } from "@apollo/client";

import { CheckOutlined, CloseOutlined, RedoOutlined } from "@ant-design/icons";
import { Button, theme } from "antd";

import { useApp } from "../../hooks/app";
import {
  MUTATION_ERROR_FRAGMENT,
  TRACKED_FRAGMENT,
  TRANSLATION_VALUE_FRAGMENT,
} from "../../utils/gql/fragments";

export const ReviewTranslationValueButton = ({
  translationValueId,
  status,
  onChange,
  ...props
}) => {
  const { t } = useTranslation();
  const [review, { loading }] = useMutation(MUTATION);
  const { message } = useApp();

  // ==

  const onClick = () =>
    review({
      variables: { id: translationValueId, status },
      onCompleted: (response) => {
        const { ok, errors, object } = Object.values(response)[0];
        if (ok) {
          message.success(t("admin.message.processSuccess"));
          onChange?.(object);
        } else {
          message.error(
            t("admin.message.processFailure", {
              message: errors[0].messages[0],
            }),
          );
        }
      },
    });

  return (
    <Button
      onClick={onClick}
      loading={loading}
      icon={
        {
          APPROVED: <CheckOutlined />,
          REJECTED: <CloseOutlined />,
          SUBMITTED: <RedoOutlined />,
          PENDING: <RedoOutlined />,
        }[status]
      }
      type="text"
      size="small"
      style={{
        color: {
          APPROVED: theme.defaultSeed.colorSuccess,
          REJECTED: theme.defaultSeed.colorError,
          SUBMITTED: theme.defaultSeed.colorWarning,
          PENDING: theme.defaultSeed.colorWarning,
        }[status],
      }}
      {...props}
    />
  );
};

// ==

const MUTATION = gql`
  mutation reviewTranslationValue($id: ID!, $status: TranslationStatus!) {
    reviewTranslationValue(id: $id, status: $status) {
      ok
      errors {
        ...MutationErrorFields
      }
      object {
        ...TranslationValueFields
      }
    }
  }
  ${MUTATION_ERROR_FRAGMENT}
  ${TRACKED_FRAGMENT}
  ${TRANSLATION_VALUE_FRAGMENT}
`;
