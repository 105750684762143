import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { Modal, Space } from "antd";

export const ViewImageAction = ({
  images,
  disabled = false,
  children,
  width = 520,
  ...props
}) => {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => setIsModalOpen(true);
  const onCancel = () => setIsModalOpen(false);

  return (
    <>
      {children?.({ openModal, disabled })}
      <Modal
        title={t(`admin.component.viewImageAction.title`)}
        open={isModalOpen}
        footer={null}
        width={Math.min(width, maxWidth)}
        onOk={onCancel}
        onCancel={onCancel}
        centered
        {...props}
      >
        <Space direction="vertical">
          {images.map((image, i) => (
            <Link key={i} to={image} target="_blank">
              <img src={image} style={{ width: "100%" }} />
            </Link>
          ))}
        </Space>
      </Modal>
    </>
  );
};

// ==

const maxWidth = 1000;
