import { Navigate, useLocation } from "react-router-dom";

import { useAuthentication } from "../../hooks/auth";

export const AnonymousRequired = ({ children }) => {
  const location = useLocation();
  const { isAuthenticated, user } = useAuthentication();

  if (isAuthenticated && user?.staff) {
    const next = new URLSearchParams(location.search).get("next") || "/";
    return <Navigate to={next} replace />;
  }

  return children;
};
