import { useTranslation } from "react-i18next";

import { Typography } from "antd";

export const PageHeader = ({
  title,
  titleKey = undefined,
  subtitle = undefined,
  subtitleKey = undefined,
  ...props
}) => {
  const { t } = useTranslation();
  return (
    <div {...props}>
      <Typography.Title level={2} style={styles.title}>
        {title ?? (titleKey ? t(titleKey) : null)}
      </Typography.Title>
      {(subtitle || subtitleKey) && (
        <Typography.Text type="secondary" style={styles.subtitle}>
          {subtitle ?? (subtitleKey ? t(subtitleKey) : null)}
        </Typography.Text>
      )}
    </div>
  );
};

// ==

const styles = {
  title: {
    margin: 0,
  },
  subtitle: {
    fontSize: "1.1rem",
  },
};
