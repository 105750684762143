import { useTranslation } from "react-i18next";

import { gql } from "@apollo/client";

import { Space } from "antd";

import bigThumbnailImage from "../../assets/mediaLayoutSection/bigThumbnail.png";
import carouselImage from "../../assets/mediaLayoutSection/carousel.png";
import listImage from "../../assets/mediaLayoutSection/list.png";
import { useDrawerState } from "../../hooks/drawer";
import { InlineFormDrawer } from "../drawers";

export const InlineEditMediaLayoutSectionAction = ({
  onChange,
  baseFilter = undefined,
  disabled = false,
  children,
  ...props
}) => {
  const { t } = useTranslation();
  const { doesRender, openDrawer, closeDrawer, drawerProps } = useDrawerState();

  // ==

  const onSuccess = (value) => {
    onChange?.(value);
    closeDrawer();
  };

  return (
    <>
      {children?.({ openDrawer, disabled })}
      {doesRender && (
        <InlineFormDrawer
          resource="mediaLayoutSection"
          fields={[
            {
              name: "category",
              type: "choice",
              enumType: "MediaLayoutSectionCategory",
              filterOptions:
                ({ data }) =>
                (options) =>
                  options.filter(
                    (option) => !data.isDefault || option.value !== "BIG_THUMBNAIL",
                  ),
              onChange: ({ value, setValue }) => {
                if (value === "BIG_THUMBNAIL") {
                  setValue("source", "CUSTOM");
                }
              },
              help: ({ data }) =>
                data.category === "BIG_THUMBNAIL" ? (
                  <Space direction="vertical">
                    <div>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                      eiusmod tempor incididunt ut labore et dolore magna aliqua.
                    </div>
                    <img src={bigThumbnailImage} style={styles.categoryHelpImage} />
                  </Space>
                ) : data.category === "CAROUSEL" ? (
                  <Space direction="vertical">
                    <div>
                      Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris
                      nisi ut aliquip ex ea commodo consequat.
                    </div>
                    <img src={carouselImage} style={styles.categoryHelpImage} />
                  </Space>
                ) : data.category === "LIST" ? (
                  <Space direction="vertical">
                    <div>
                      Duis aute irure dolor in reprehenderit in voluptate velit esse
                      cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                      cupidatat non proident, sunt in culpa qui officia deserunt mollit
                      anim id est laborum.
                    </div>
                    <img src={listImage} style={styles.categoryHelpImage} />
                  </Space>
                ) : null,
            },
            {
              name: "source",
              type: "choice",
              enumType: "MediaLayoutSectionSource",
              disabled: ({ data }) =>
                data.category === "BIG_THUMBNAIL" || data.isDefault,
            },
            {
              name: "premium",
              type: "object",
              resource: "premium",
              hidden: ({ data }) =>
                !(data.source === "PREMIUM_ONLY" && data.category !== "BIG_THUMBNAIL"),
              baseFilter,
            },
            {
              name: "playlist",
              type: "object",
              resource: "playlist",
              hidden: ({ data }) =>
                !(data.source === "CUSTOM" && data.category !== "BIG_THUMBNAIL"),
              baseFilter,
              descriptionKey: "admin.form.guide.playlistMediaSectionLayout",
            },
            {
              name: "clip",
              type: "object",
              resource: "clip",
              hidden: ({ data }) =>
                !(data.source === "CUSTOM" && data.category === "BIG_THUMBNAIL"),
              baseFilter,
            },
            {
              name: "titleContent",
              labelKey: "admin.field.title",
              type: "translatable",
            },
            {
              name: "subtitleContent",
              labelKey: "admin.field.subtitle",
              type: "translatable",
              hidden: ({ data }) => !(data.category === "BIG_THUMBNAIL"),
            },
            {
              name: "isDefault",
              hidden: true,
            },
          ]}
          mutation={MUTATION}
          onChange={onSuccess}
          drawerProps={drawerProps}
          {...props}
        />
      )}
    </>
  );
};

// ==

const MUTATION = gql`
  mutation validateMediaLayoutSection($data: MediaLayoutSectionMutationInput!) {
    validateMediaLayoutSection(data: $data) {
      ok
      errors {
        ...MutationErrorFields
      }
    }
  }
  ${InlineFormDrawer.fragments.mutationError}
`;

// ==

const styles = {
  categoryHelpImage: {
    height: 150,
  },
};
