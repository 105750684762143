import { useMemo } from "react";
import { useSearchParams } from "react-router-dom";

import { useApp } from "@/hooks/app";
import { useFlatFields } from "@/hooks/fields/useFlatFields";
import { useForceFieldset } from "@/hooks/fields/useForceFieldset";
import { useFormNormalizer } from "@/hooks/fields/useFormNormalizer";
import { useManipulatedFields } from "@/hooks/fields/useManipulatedFields";
import { useTranslate } from "@/hooks/i18n";
import type { ResourceName } from "@/resources";
import type { BuiltField, BuiltFlatField, Field } from "@/types/field";
import type { RecordType } from "@/types/primitives";
import { buildField, buildFormField, getFormDefaultValues } from "@/utils/field";

import { useFieldsPermission } from "./useFieldsPermission";

export * from "./useFieldConfirmations";

type UseFieldsOptions<Data extends RecordType> = {
  resource: ResourceName;
  fields: Field<Data>[];
  /** @default false */
  forceFieldset?: boolean;
};

type BuiltFieldsMeta = {
  fields: readonly BuiltField[] | null;
};

type UseFormFieldOptions<Data extends RecordType> = {
  fields: Field<Data>[];
  obj: RecordType | null;
  fieldPreProcessor?: (field: Field<Data>) => Field<Data>;
  fieldPostProcessor?: (field: BuiltField) => BuiltField;
  /** @default true */
  forceFieldset?: boolean;
  defaultValues?: RecordType;
  defaultFieldProps?: RecordType;
};

export type BuiltFormFieldsMeta<Data extends RecordType> = {
  /**
   * BuiltFields with fieldsets
   * If null, abilities are not evaluated yet
   */
  fields: readonly BuiltField[] | null;
  /** atomic built fields (without fieldsets) */
  flatFields: readonly BuiltFlatField[] | null;
  /** atomic built field names (without nested child names). error display purpose */
  flatFieldNames: readonly string[];
  /**
   * aggregated default values for the form
   * 1) search params 2) given default values 3) field definition
   */
  defaultValues: RecordType | undefined;
  /** Normalize data to be sent to the server */
  normalizeData: ReturnType<typeof useFormNormalizer<Data>>;
};

export function useFields<Data extends RecordType>({
  resource: resourceName,
  fields: originalFields,
  forceFieldset = false,
}: UseFieldsOptions<Data>): BuiltFieldsMeta {
  const t = useTranslate();
  const { resources } = useApp();
  const resource = resources[resourceName];
  const normalizedFields = useForceFieldset(originalFields, forceFieldset);
  const builtFields = useMemo(
    () => normalizedFields.map((field) => buildField(field, resource, resources, t)),
    [normalizedFields, resource, resources, t],
  );
  const { flatFields } = useFlatFields(builtFields);
  const permission = useFieldsPermission(flatFields, resource, false, null);
  const fields = useManipulatedFields(builtFields, {
    filter: permission.examined ? permission.isFieldForbidden : null,
  });

  return { fields };
}

export function useFormFields<Data extends RecordType>({
  resource: resourceName,
  fields: originalFields,
  obj = null,
  fieldPreProcessor = defaultProcessor,
  fieldPostProcessor = defaultProcessor,
  forceFieldset = true,
  defaultValues: originalDefaultValues = undefined,
}: UseFormFieldOptions<Data> & { resource: ResourceName }): BuiltFormFieldsMeta<Data> {
  const isUpdate = !!obj;
  const { resources } = useApp();
  const t = useTranslate();
  const [searchParams] = useSearchParams();
  const resource = resources[resourceName];
  const normalizedFields = useForceFieldset(originalFields, forceFieldset);

  // Recursively build form fields
  const buildFieldArgs = [
    t,
    resource,
    resources,
    fieldPreProcessor,
    fieldPostProcessor,
  ] as const;
  const builtFields = useMemo(
    () =>
      normalizedFields.map((field, index) =>
        buildFormField(field, `fs.${index}`, ...buildFieldArgs),
      ) as BuiltField[],
    [normalizedFields, ...buildFieldArgs],
  );

  // Recursively Flatten (sub) fieldsets and get editable fields based on the abilities
  const { flatFields, flatFieldNames } = useFlatFields(builtFields);
  const permission = useFieldsPermission(flatFields, resource, !isUpdate, obj);

  // Manipulate fields based on the abilities
  const fields = useManipulatedFields(builtFields, {
    manipulator: permission.examined ? permission.injectForbiddenToField : null,
  });

  // Get default values for the form
  const defaultValues = useMemo(() => {
    if (!fields) return undefined;
    return getFormDefaultValues(flatFields, obj, searchParams, originalDefaultValues);
  }, [fields, obj, searchParams, originalDefaultValues]);

  // Data normalizer to be sent to the server
  const normalizeData = useFormNormalizer<Data>(flatFields);

  return { fields, flatFields, flatFieldNames, normalizeData, defaultValues };
}

// ==
function defaultProcessor<F>(field: F): F {
  return field;
}
