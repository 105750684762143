import { gql } from "@apollo/client";

import { FormPage } from "../../components/pages";

export const UserForm = () => (
  <FormPage
    resource="user"
    fields={[
      {
        name: "email",
        type: "email",
      },
      {
        name: "phone",
        type: "phone",
      },
      {
        name: "name",
      },
      {
        name: "birthDate",
        type: "date",
      },
      {
        name: "country",
        type: "object",
        resource: "country",
      },
      {
        name: "serviceLanguage",
        type: "object",
        resource: "language",
        baseFilter: {
          isActiveForService_Exact: true,
        },
      },
      {
        name: "translationLanguage",
        type: "object",
        resource: "language",
        baseFilter: {
          isActiveForTranslation_Exact: true,
        },
      },
    ]}
    query={QUERY}
    createMutation={CREATE_MUTATION}
    updateMutation={UPDATE_MUTATION}
  />
);

// ==

const FORM_FIELDS_FRAGMENT = gql`
  fragment UserFormFields on User {
    id
    email
    phone
    name
    birthDate
    country {
      id
    }
    serviceLanguage {
      id
    }
    translationLanguage {
      id
    }
  }
`;

const QUERY = gql`
  query userForUserForm($id: ID!) {
    user(filter: { id_Overlap: [$id] }) {
      ...UserFormFields
    }
  }
  ${FORM_FIELDS_FRAGMENT}
`;

const CREATE_MUTATION = gql`
  mutation createUserForUserForm($data: UserMutationInput!) {
    createUser(data: $data) {
      ok
      errors {
        ...MutationErrorFields
      }
      object {
        ...UserFormFields
      }
    }
  }
  ${FormPage.fragments.mutationError}
  ${FORM_FIELDS_FRAGMENT}
`;

const UPDATE_MUTATION = gql`
  mutation updateUserForUserForm($data: UserMutationInput!, $id: ID!) {
    updateUser(data: $data, id: $id, partial: true) {
      ok
      errors {
        ...MutationErrorFields
      }
      object {
        ...UserFormFields
      }
    }
  }
  ${FormPage.fragments.mutationError}
  ${FORM_FIELDS_FRAGMENT}
`;
