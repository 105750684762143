import { gql } from "@apollo/client";

import { ListPage } from "../../components/pages";

export const PlaylistEntryList = () => (
  <ListPage
    resource="playlistEntry"
    fields={[
      {
        name: "id",
      },
      {
        name: "playlist",
        type: "object",
        resource: "playlist",
        filterKey: "playlist_Overlap",
      },
      {
        name: "clip",
        type: "object",
        resource: "clip",
        filterKey: "clip_Overlap",
      },
      {
        name: "order",
        type: "integer",
        filterKey: "order_Range",
        sortKey: "ORDER",
      },
      ...ListPage.fields.tracked,
    ]}
    query={QUERY}
    extraRowActions={ListPage.extraRowActions.delete}
  />
);

// ==

const QUERY = gql`
  query playlistEntriesForPlaylistEntryList(
    $filter: PlaylistEntryFilterInput
    $sort: [PlaylistEntrySortInput]
    $page: PageInput
  ) {
    playlistEntries(filter: $filter, sort: $sort, page: $page) {
      objects {
        id
        playlist {
          id
          name
        }
        clip {
          id
          title
        }
        order
        ...TrackedFields
      }
      ...ListFields
    }
  }
  ${ListPage.fragments.list}
  ${ListPage.fragments.tracked}
`;
