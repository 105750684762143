import { gql } from "@apollo/client";

import { FormPage } from "../../components/pages";

export const ExampleForm = () => (
  <FormPage
    resource="example"
    fields={[
      {
        name: "slug",
        help: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
      },
      {
        name: "char",
        placeholder: "Placeholder",
      },
      {
        name: "longText",
        rows: 5,
      },
      {
        name: "integer",
        type: "integer",
      },
      {
        name: "decimal",
        type: "float",
      },
      {
        name: "email",
        type: "email",
        placeholder: "example@example.com",
      },
      {
        name: "url",
        type: "url",
        placeholder: "http://example.com",
      },
      {
        name: "array",
        help: "Comma-separated",
        getter: (obj) => obj.array?.join(","),
        normalizer: (value) => (value ? value?.split(",") : null),
      },
      {
        name: "date",
        type: "date",
        placeholder: "YYYY-MM-DD",
      },
      {
        name: "datetime",
        type: "datetime",
        help: "Custom `DateTimePicker` component is not implemented yet",
      },
      {
        name: "phone",
        type: "phone",
        help: "Custom `PhoneInput` component is not implemented yet",
      },
      {
        name: "boolean",
        type: "boolean",
        controlLabel: "Custom checkbox label",
      },
      {
        name: "nullBoolean",
        type: "boolean",
        nullable: true,
        help: "Nullable boolean",
      },
      {
        name: "imageMeta",
        type: "imageMeta",
      },
      {
        name: "videoMeta",
        type: "videoMeta",
      },
      {
        name: "fileMeta",
        type: "fileMeta",
      },
      {
        name: "genericMeta",
        type: "genericMeta",
      },
      {
        name: "genericMetas",
        type: "genericMeta",
        multiple: true,
        sync: false,
      },
      {
        name: "textChoice",
        type: "choice",
        enumType: "ExampleTextChoice",
      },
      {
        name: "integerChoice",
        type: "choice",
        enumType: "ExampleIntegerChoice",
      },
      {
        name: "integerChoices",
        type: "choice",
        enumType: "ExampleIntegerChoice",
        multiple: true,
        help: "Multiple choices",
      },
      {
        name: "countryOne",
        type: "object",
        resource: "country",
      },
      {
        name: "countryForeign",
        type: "object",
        resource: "country",
      },
      {
        name: "countriesMany",
        type: "object",
        resource: "country",
        multiple: true,
        placeholder: "Multiple objects",
      },
    ]}
    query={QUERY}
    createMutation={CREATE_MUTATION}
    updateMutation={UPDATE_MUTATION}
  />
);

// ==

const FORM_FIELDS_FRAGMENT = gql`
  fragment ExampleFormFields on Example {
    id
    slug
    char
    longText
    integer
    decimal
    email
    url
    array
    date
    datetime
    phone
    boolean
    nullBoolean
    imageMeta {
      ...ImageFields
    }
    videoMeta {
      ...VideoFields
    }
    fileMeta {
      ...FileFields
    }
    genericMeta {
      ...MediaFields
    }
    genericMetas {
      ...MediaFields
    }
    textChoice
    integerChoice
    integerChoices
    countryOne {
      id
    }
    countryForeign {
      id
    }
    countriesMany {
      id
    }
  }
  ${FormPage.fragments.file}
  ${FormPage.fragments.image}
  ${FormPage.fragments.video}
  ${FormPage.fragments.media}
`;

const QUERY = gql`
  query exampleForExampleForm($id: ID!) {
    example(filter: { id_Overlap: [$id] }) {
      ...ExampleFormFields
    }
  }
  ${FORM_FIELDS_FRAGMENT}
`;

const CREATE_MUTATION = gql`
  mutation createExampleForExampleForm($data: ExampleMutationInput!) {
    createExample(data: $data) {
      ok
      errors {
        ...MutationErrorFields
      }
      object {
        ...ExampleFormFields
      }
    }
  }
  ${FormPage.fragments.mutationError}
  ${FORM_FIELDS_FRAGMENT}
`;

const UPDATE_MUTATION = gql`
  mutation updateExampleForExampleForm($data: ExampleMutationInput!, $id: ID!) {
    updateExample(data: $data, id: $id, partial: true) {
      ok
      errors {
        ...MutationErrorFields
      }
      object {
        ...ExampleFormFields
      }
    }
  }
  ${FormPage.fragments.mutationError}
  ${FORM_FIELDS_FRAGMENT}
`;
