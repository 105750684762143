import { useCallback, useRef } from "react";
import { Link, type To, useNavigate, useParams } from "react-router-dom";

import { type DocumentNode, type TypedDocumentNode, useQuery } from "@apollo/client";

import { ArrowLeftOutlined } from "@ant-design/icons";
import { Breadcrumb, Button, Col, Row, Space, Typography } from "antd";

import { ActivationControl } from "@/components/fields";
import { MutationForm } from "@/components/form";
import type { GqlMutationData } from "@/gql/types";
import { useRevalidateQueryFields } from "@/hooks/apollo";
import { useFormFields } from "@/hooks/fields";
import { useTranslate } from "@/hooks/i18n";
import { useResource } from "@/hooks/resource";
import type { ResourceName } from "@/resources";
import type { Field } from "@/types/field";
import type { FieldConfirmation } from "@/types/field";
import type { FormRequestLike, FormResponseLike } from "@/types/form";
import type { RecordType } from "@/types/primitives";
import {
  CAN_ACTIVATE_FRAGMENT,
  FILE_FRAGMENT,
  FORM_CLIP_CAPTION_FRAGMENT,
  IMAGE_FRAGMENT,
  MEDIA_FRAGMENT,
  MUTATION_ERROR_FRAGMENT,
  TRANSLATABLE_CONTENT_FRAGMENT,
  TRANSLATABLE_FILES_FRAGMENT,
  VIDEO_FRAGMENT,
} from "@/utils/gql/fragments";
import { capitalize, getSearchParam } from "@/utils/helpers";

type Props<
  Q extends DocumentNode,
  C extends TypedDocumentNode<FormResponseLike, FormRequestLike>,
  U extends TypedDocumentNode<FormResponseLike, FormRequestLike>,
  FormValue extends GqlMutationData<C> | GqlMutationData<U>,
> = {
  resource: ResourceName;
  query: Q;
  fields: Field<FormValue>[];
  createMutation: C;
  updateMutation: U;
  nextUrl?: To;
  defaultValues?: RecordType;
  colSpan?: number;
};

export const FormPage = <
  Q extends DocumentNode,
  C extends TypedDocumentNode<FormResponseLike, FormRequestLike>,
  U extends TypedDocumentNode<FormResponseLike, FormRequestLike>,
  FormValue extends GqlMutationData<C> | GqlMutationData<U>,
>({
  resource: resourceName,
  fields: originalFields,
  query,
  createMutation,
  updateMutation,
  nextUrl: originalNextUrl,
  defaultValues: originalDefaultValues,
  colSpan = 16,
}: Props<Q, C, U, FormValue>) => {
  const t = useTranslate();
  const navigate = useNavigate();
  const params = useParams();
  const isUpdate = !!params.id;

  const { resource } = useResource(resourceName);
  const { data } = useQuery(query, {
    skip: !isUpdate,
    variables: { id: params.id },
  });
  const revalidate = useRevalidateQueryFields(
    resource.typePlural,
    `all${capitalize(resource.typePlural)}`,
  );
  const obj = data ? (Object.values(data)[0] as RecordType) : null;

  const meta = useFormFields<FormValue>({
    resource: resourceName,
    fields: originalFields,
    obj,
    defaultValues: originalDefaultValues,
  });
  const { fields } = meta;
  const mutation = isUpdate ? updateMutation : createMutation;
  const handleSuccess = useCallback(
    (object: RecordType) => {
      const nextUrl = getSearchParam("nextUrl") || originalNextUrl;
      if (!isUpdate) revalidate();
      navigate(nextUrl || resource.detailUrlGetter(object));
    },
    [isUpdate, originalNextUrl, resource, navigate, revalidate],
  );
  const formScrollHelperRef = useRef<HTMLDivElement | null>(null);

  if (isUpdate && (!obj || !fields)) {
    return null;
  }

  return (
    <Space className="flex" direction="vertical" size="middle">
      <Breadcrumb
        items={[
          { title: <Link to="/">{t("admin.common.home")}</Link> },
          { title: <Link to={resource.listUrl}>{resource.labelPlural}</Link> },
          ...(!isUpdate
            ? [{ title: t("admin.common.add") }]
            : [
                {
                  title: (
                    <Link to={resource.detailUrlGetter(obj)}>
                      {resource.labelGetter(obj)}
                    </Link>
                  ),
                },
                { title: t("admin.common.edit") },
              ]),
        ]}
      />
      <Space align="center">
        <Link to="#" onClick={() => history.back()}>
          <Button type="text" icon={<ArrowLeftOutlined />} />
        </Link>
        <Typography.Title level={2} className="m-0">
          {t("admin.common.actionTitle", {
            action: isUpdate ? t("admin.common.edit") : t("admin.common.add"),
            name: resource.label,
          })}
        </Typography.Title>
      </Space>
      <Row gutter={24}>
        <Col span={24} lg={colSpan}>
          <MutationForm
            meta={meta}
            mutation={mutation}
            id={params.id}
            formScrollHelperRef={formScrollHelperRef}
            onSuccess={handleSuccess}
          />
        </Col>
        <Col xs={0} sm={0} md={0} lg={24 - colSpan}>
          <div ref={formScrollHelperRef} />
        </Col>
      </Row>
    </Space>
  );
};

// ==

FormPage.fragments = {
  file: FILE_FRAGMENT,
  clipCaption: FORM_CLIP_CAPTION_FRAGMENT,
  image: IMAGE_FRAGMENT,
  video: VIDEO_FRAGMENT,
  media: MEDIA_FRAGMENT,
  translatableContent: TRANSLATABLE_CONTENT_FRAGMENT,
  translatableFiles: TRANSLATABLE_FILES_FRAGMENT,
  mutationError: MUTATION_ERROR_FRAGMENT,
  canActivate: CAN_ACTIVATE_FRAGMENT,
};

const confirmPushWarning: FieldConfirmation = {
  shouldConfirm: (data: RecordType) => Boolean(data.doesSendPush),
  confirmLabelKey: "admin.component.doesSendPush.confirm",
  message: "admin.component.doesSendPush.confirmMessage",
};

FormPage.fields = {
  canActivate: [
    {
      type: "fieldset",
      labelKey: "admin.field.visibility",
      fields: [
        {
          type: "composite",
          names: ["isActive", "activateAt", "doesSendPush"],
          defaultValue: { isActive: false, doesSendPush: false },
          permission: ({ obj, resource }) => ({
            action: "activate",
            targetType: resource.typeName,
            targetId: obj?.id,
          }),
          render: ({ controllerField }) => <ActivationControl {...controllerField} />,
          confirmation: confirmPushWarning,
        },
      ],
    },
  ],
  canActivateFirstActivateAt: [
    {
      type: "fieldset",
      labelKey: "admin.field.visibility",
      fields: [
        {
          type: "composite",
          names: ["isActive", "activateAt", "doesSendPush", "firstActivatedAt"],
          defaultValue: { isActive: false, doesSendPush: false },
          permission: ({ obj, resource }) => ({
            action: "activate",
            targetType: resource.typeName,
            targetId: obj?.id,
          }),
          render: ({ controllerField }) => (
            <ActivationControl {...controllerField} canChangeFirstActivatedAt={true} />
          ),
          confirmation: confirmPushWarning,
        },
      ],
    },
  ],
  canActivateOrInactivate: [
    {
      type: "fieldset",
      labelKey: "admin.field.visibility",
      fields: [
        {
          type: "composite",
          names: ["isActive", "activateAt", "inactivateAt", "doesSendPush"],
          defaultValue: { isActive: false, doesSendPush: false },
          permission: ({ obj, resource }) => ({
            action: "activate",
            targetType: resource.typeName,
            targetId: obj?.id,
          }),
          render: ({ controllerField }) => (
            <ActivationControl {...controllerField} canScheduleInactivation={true} />
          ),
          confirmation: confirmPushWarning,
        },
      ],
    },
  ],
} as const;
