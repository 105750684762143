import { useContext, useEffect, useRef, useState } from "react";

import { LayoutContext } from "@/contexts/layout";
import { debounce } from "@/utils/helpers";

interface WindowDimensions {
  width: number;
  height: number;
  isWide: boolean;
}

export const useLayout = () => useContext(LayoutContext);

export const getWindowDimensions = (): WindowDimensions => {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
    isWide: width > 1024,
  };
};

export const useWindowDimensions = () => {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  const resizeRef = useRef(
    debounce(() => {
      setWindowDimensions(getWindowDimensions());
    }, 1000),
  );

  useEffect(() => {
    const handleResize = resizeRef.current;
    handleResize();

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
};
