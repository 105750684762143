import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { Space } from "antd";
import type { TableColumnType, TableProps } from "antd";
import type { FilterDropdownProps, SorterResult } from "antd/es/table/interface";

import {
  DatetimeFilter,
  ObjectFilter,
  RangeFilter,
  TextFilter,
} from "@/components/filter";
import { useApp } from "@/hooks/app";
import { type ServerEnumMapping, useEnums } from "@/hooks/enum";
import { type SetFilterState } from "@/hooks/filter/useFilterState";
import { useWindowDimensions } from "@/hooks/layout";
import type { BuiltListField } from "@/types/field";
import type {
  FilterInput,
  RowActionRenderer,
  SortInput,
  SortInputItem,
} from "@/types/list";
import type { RecordType } from "@/types/primitives";
import type { BuiltResourceMap } from "@/types/resource";
import type { NoNil, TranslateFn } from "@/types/utils";
import { fromAntdDirection, toAntdDirection } from "@/utils/antd";
import { getSelectComponent, getUniqueEnumTypes } from "@/utils/field";
import { isArray, isDefined, keyBy } from "@/utils/helpers";

type KeyMap = Record<string, string>;

const FILTER_TYPE_MAPPING = {
  text: "text",
  email: "text",
  phone: "text",
  url: "text",
  boolean: "boolean",
  choice: "choice",
  object: "object",
  integer: "range",
  float: "range",
  date: "date",
  datetime: "datetime",
} as const;

interface Options<Data extends RecordType> {
  fields: readonly BuiltListField<Data>[] | null;
  filter: FilterInput;
  sort: SortInput;
  set: SetFilterState;
  rowAction: RowActionRenderer<Data> | null;
}

interface Result<Data extends RecordType> {
  columns: TableColumnType<Data>[];
  filterKeyMapping: KeyMap;
  sortKeyMapping: KeyMap;
  handleTableChange: TableChangeFn<Data>;
}

type TableChangeFn<Data extends RecordType> = NoNil<TableProps<Data>["onChange"]>;

export function useAntdSortableFilterFields<Data extends RecordType>({
  fields,
  filter,
  sort,
  rowAction,
  set,
}: Options<Data>): Result<Data> {
  const { t } = useTranslation();
  const { isWide } = useWindowDimensions();

  const { resources } = useApp();
  const enumKeys = useMemo(() => getUniqueEnumTypes(fields), [fields]);
  const sortMapping = useMemo(() => keyBy(sort, (s) => s.type), [sort]);
  const { valueMap: enumMapping } = useEnums(enumKeys);
  const [columns, filterKeyMapping, sortKeyMapping] = useMemo(
    () =>
      buildColumns({
        fields,
        rowAction,
        enumMapping,
        sortMapping,
        filter,
        resources,
        isWide,
        t,
      }),
    [fields, rowAction, enumMapping, sortMapping, filter, resources, isWide, t],
  );

  const fromAntdSorter = useCallback(
    (sorter: SorterResult<Data>): SortInputItem => {
      const field = isArray(sorter.field) ? sorter.field[0] : sorter.field;
      const sortKey = sortKeyMapping[field];
      const direction = fromAntdDirection(sorter.order);

      return { type: sortKey, direction };
    },
    [sortKeyMapping],
  );

  const findAntdColumn = useCallback(
    (key: string) => columns.find((column) => column.dataIndex === key),
    [columns],
  );

  const handleTableChange = useCallback<TableChangeFn<Data>>(
    (_, filters, sorter) => {
      const newFilter: FilterInput = {};
      let newSort: SortInput;

      for (const key in filters) {
        const value = filters[key];
        const column = findAntdColumn(key)!;
        if (value) {
          newFilter[filterKeyMapping[key]] = column.filterMultiple ? value : value[0];
        }
      }

      if (isArray(sorter)) {
        newSort = sorter.map(fromAntdSorter);
      } else {
        newSort = sorter.order ? [fromAntdSorter(sorter)] : [];
      }

      set({ filter: newFilter, sort: newSort });
    },
    [filterKeyMapping, findAntdColumn, fromAntdSorter, set],
  );

  return {
    columns,
    filterKeyMapping,
    sortKeyMapping,
    handleTableChange,
  };
}

function buildColumns<Data extends RecordType>(args: {
  fields: readonly BuiltListField<Data>[] | null;
  rowAction: RowActionRenderer<Data> | null;
  // Prefetched
  enumMapping: ServerEnumMapping;
  // Filter State
  sortMapping: Record<string, SortInputItem>;
  filter: FilterInput;
  // Context
  resources: BuiltResourceMap;
  isWide: boolean;
  t: TranslateFn;
}): [TableColumnType<Data>[], KeyMap, KeyMap] {
  const { fields, enumMapping, sortMapping, filter, resources, isWide, t, rowAction } =
    args;

  /** field.name to filterKey */
  const filterKeyMapping: KeyMap = {};
  /** field.name to sortKey */
  const sortKeyMapping: KeyMap = {};

  if (!fields) return [[], filterKeyMapping, sortKeyMapping] as const;

  const columns = fields.map((field) => {
    const {
      name,
      label,
      sortKey,
      sortPriority,
      filterKey,
      filterType,
      nullable,
      type,
      ...restProps
    } = field;

    if (!name) throw new Error(`Field name is required. field=${field}`);

    const antdProps: TableColumnType<Data> = {
      ...restProps,
      title: label,
      dataIndex: name,
    };

    if (sortKey) {
      sortKeyMapping[name] = sortKey;
      antdProps.sorter = sortPriority ? { multiple: sortPriority } : true;
      const currentSort = sortMapping[sortKey];
      antdProps.sortOrder = toAntdDirection(currentSort?.direction);
    }

    if (filterKey) {
      const resolvedFilterType = filterType || inferFilterType(type);
      filterKeyMapping[name] = filterKey;
      const selectComponent = getSelectComponent(field, resources);

      switch (resolvedFilterType) {
        case "text":
          antdProps.filterMultiple = false;
          antdProps.filterDropdown = (props: FilterDropdownProps) => (
            <TextFilter title={String(antdProps.title)} {...props} />
          );
          break;
        case "range":
          antdProps.filterMultiple = true;
          antdProps.filterDropdown = (props: FilterDropdownProps) => (
            <RangeFilter {...props} />
          );
          break;
        case "boolean":
          // TODO: Add `null` option
          antdProps.filterMultiple = Boolean(nullable);
          antdProps.filters = [
            { text: t("admin.common.yes"), value: true },
            { text: t("admin.common.no"), value: false },
          ];
          break;
        case "choice":
          if (!("enumType" in field))
            throw new Error(`enumType is required. field=${field}`);
          antdProps.filters = (enumMapping[field.enumType] || []).map((item) => ({
            text: item.description,
            value: item.name,
          }));
          antdProps.filterMultiple = true;
          antdProps.filterSearch = true;
          break;
        case "object":
          if (!selectComponent)
            throw new Error(`selectComponent or resource is required. field=${field}`);
          antdProps.filterMultiple = true;
          antdProps.filterDropdown = (props: FilterDropdownProps) => (
            <ObjectFilter selectComponent={selectComponent} {...props} />
          );
          break;
        case "datetime":
          antdProps.filterMultiple = true;
          antdProps.filterDropdown = (props: FilterDropdownProps) => (
            <DatetimeFilter mode="datetime" {...props} />
          );
          break;
        case "date":
          antdProps.filterMultiple = true;
          antdProps.filterDropdown = (props: FilterDropdownProps) => (
            <DatetimeFilter mode="date" {...props} />
          );
          break;
        // Add other cases as needed
      }
      antdProps.filteredValue = isDefined(filter?.[filterKey])
        ? antdProps.filterMultiple
          ? filter[filterKey]
          : [filter[filterKey]]
        : null;
    }

    return antdProps;
  });

  if (rowAction) {
    const actionColumn: TableColumnType<Data> = {
      key: "action",
      width: "1%",
      render: (_, row) => <Space size="small">{rowAction(row)}</Space>,
      fixed: isWide ? "right" : undefined,
    };
    columns.push(actionColumn);
  }

  return [columns, filterKeyMapping, sortKeyMapping] as const;
}

function inferFilterType(type?: string): string {
  if (!type) return "text";
  const inferred = FILTER_TYPE_MAPPING[type];
  if (!inferred) throw new Error(`Unknown filter type for field type=${type}`);
  return inferred;
}
