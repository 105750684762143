import { gql } from "@apollo/client";

import { ListWidget } from "../list";

export const OfflineStoreItemVariantListWidget = ({ filter, ...props }) => (
  <ListWidget
    resource="offlineStoreItemVariant"
    filter={{
      ...filter,
    }}
    fields={[
      {
        name: "id",
      },
      {
        name: "label",
      },
      {
        name: "availability",
        enumType: "OfflineStoreItemVariantAvailability",
        filterKey: "availability_Overlap",
        type: "choice",
      },
      ...ListWidget.fields.tracked,
    ]}
    query={QUERY}
    hideDetail
    {...props}
  />
);

// ==

const QUERY = gql`
  query offlineStoreItemsForOfflineStoreItemVariantListWidget(
    $filter: OfflineStoreItemVariantFilterInput
    $sort: [OfflineStoreItemVariantSortInput]
    $page: PageInput
  ) {
    offlineStoreItemVariants(filter: $filter, sort: $sort, page: $page) {
      objects {
        id
        label
        availability
        ...TrackedFields
      }
      ...ListFields
    }
  }
  ${ListWidget.fragments.list}
  ${ListWidget.fragments.tracked}
`;
