import { gql } from "@apollo/client";

import { ListWidget } from "../list";

export const RoleListWidget = (props) => (
  <ListWidget
    resource="role"
    fields={[
      {
        name: "id",
      },
      {
        name: "staff",
        type: "object",
        resource: "staff",
        filterKey: "staff_Overlap",
      },
      {
        name: "staff.user.email",
        labelKey: "admin.field.email",
        type: "email",
      },
      {
        name: "category",
        type: "choice",
        enumType: "RoleCategory",
        filterKey: "category_Text",
        sortKey: "CATEGORY",
      },
      {
        name: "group",
        type: "object",
        resource: "group",
        filterKey: "group_Overlap",
      },
      {
        name: "company",
        type: "object",
        resource: "company",
        filterKey: "company_Overlap",
      },
      ...ListWidget.fields.tracked,
    ]}
    query={QUERY}
    extraRowActions={ListWidget.extraRowActions.delete}
    {...props}
  />
);

// ==

const QUERY = gql`
  query rolesForRoleListWidget(
    $filter: RoleFilterInput
    $sort: [RoleSortInput]
    $page: PageInput
  ) {
    roles(filter: $filter, sort: $sort, page: $page) {
      objects {
        id
        staff {
          id
          user {
            id
            email
            name
          }
        }
        category
        group {
          id
          name
        }
        company {
          id
          name
        }
        ...TrackedFields
      }
      ...ListFields
    }
  }
  ${ListWidget.fragments.list}
  ${ListWidget.fragments.tracked}
`;
