import { gql } from "@apollo/client";

import { DetailPage } from "../../components/pages";
import { toCamelCase } from "../../utils/helpers";

export const AuditDetail = (props) => (
  <DetailPage
    resource="audit"
    fields={[
      {
        name: "id",
      },
      {
        name: "group",
        type: "object",
        resource: "group",
      },
      {
        name: "user",
        type: "object",
        resource: "user",
      },
      {
        name: "target",
        type: "object",
        resource: ({ obj }) => toCamelCase(obj.target.type.toLowerCase()),
      },
      {
        name: "category",
        type: "choice",
        enumType: "AuditCategory",
      },
      {
        name: "memo",
      },
      {
        name: "diff",
      },
      {
        name: "createdAt",
      },
    ]}
    query={QUERY}
    {...props}
  />
);

const QUERY = gql`
  query auditForAuditDetail($id: ID!) {
    audit(filter: { id_Overlap: [$id] }) {
      id
      user {
        id
        name
      }
      targetGroup {
        id
        name
      }
      target {
        id
        type
      }
      category
      memo
      diff
      createdAt
    }
  }
`;
