import { gql } from "@apollo/client";

import { FormPage } from "../../components/pages";

export const HashtagForm = () => (
  <FormPage
    resource="hashtag"
    fields={[
      {
        name: "group",
        type: "object",
        resource: "group",
        disabled: ({ isUpdate }) => isUpdate,
      },
      {
        name: "name",
      },
      {
        name: "isHighlighted",
        type: "boolean",
      },
    ]}
    query={QUERY}
    createMutation={CREATE_MUTATION}
    updateMutation={UPDATE_MUTATION}
  />
);

// ==

const FORM_FIELDS_FRAGMENT = gql`
  fragment HashtagFormFields on Hashtag {
    id
    group {
      id
    }
    name
    isHighlighted
  }
`;

const QUERY = gql`
  query hashtagForHashtagForm($id: ID!) {
    hashtag(filter: { id_Overlap: [$id] }) {
      ...HashtagFormFields
    }
  }
  ${FORM_FIELDS_FRAGMENT}
`;

const CREATE_MUTATION = gql`
  mutation createHashtagForHashtagForm($data: HashtagMutationInput!) {
    createHashtag(data: $data) {
      ok
      errors {
        ...MutationErrorFields
      }
      object {
        ...HashtagFormFields
      }
    }
  }
  ${FormPage.fragments.mutationError}
  ${FORM_FIELDS_FRAGMENT}
`;

const UPDATE_MUTATION = gql`
  mutation updateHashtagForHashtagForm($data: HashtagMutationInput!, $id: ID!) {
    updateHashtag(data: $data, id: $id, partial: true) {
      ok
      errors {
        ...MutationErrorFields
      }
      object {
        ...HashtagFormFields
      }
    }
  }
  ${FormPage.fragments.mutationError}
  ${FORM_FIELDS_FRAGMENT}
`;
