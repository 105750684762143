import type { FC, PropsWithChildren } from "react";

import { CloseCircleOutlined, RedoOutlined, SearchOutlined } from "@ant-design/icons";
import { Button, Divider, Space } from "antd";

import { DebouncedInput } from "@/components/fields";
import { ResourceButton } from "@/components/list/action";
import type {
  ResourceButtonOption,
  ResourceButtonType,
} from "@/components/list/action";
import { useTranslate } from "@/hooks/i18n";
import type { ResourceName } from "@/resources";

type FilterControllerProps = {
  resource: ResourceName;
  loading: boolean;
  globalSearch?: boolean;
  searchText: string;
  isResettable: boolean;
  list?: false | ResourceButtonOption;
  onSearchTextChange: (text: string) => void;
  onReset: () => void;
  onRefetch: () => void;
};

type ActionProps = PropsWithChildren<{
  resource: ResourceName;
  resourceButtonType: ResourceButtonType;
  hide?: boolean;
  create?: false | ResourceButtonOption;
}>;

/**
 * Global search, reset and refetch buttons
 */
export const ListFilterController: FC<FilterControllerProps> = ({
  resource,
  list,
  loading,
  globalSearch,
  searchText,
  isResettable,
  onSearchTextChange,
  onReset,
  onRefetch,
}) => {
  const t = useTranslate();

  return (
    <Space className="reversible" size="middle">
      {globalSearch && (
        <DebouncedInput
          prefix={<SearchOutlined />}
          placeholder={t("admin.common.search")}
          value={searchText}
          onChange={onSearchTextChange}
        />
      )}
      {list && <ResourceButton action="list" resource={resource} {...list} />}
      <Button
        type="text"
        icon={<CloseCircleOutlined />}
        disabled={!isResettable}
        onClick={onReset}
      />
      <Button
        type="text"
        icon={<RedoOutlined />}
        disabled={loading}
        loading={loading}
        onClick={onRefetch}
      />
    </Space>
  );
};

/**
 * @prop children Extra actions
 */
export const ListAction: FC<ActionProps> = (props) => {
  const { resource, resourceButtonType, children, create } = props;
  const hide = props.hide || (!children && create === false);
  if (hide) return null;
  return (
    <Space className="reversible" size="middle">
      <Divider type="vertical" />
      {children}
      {create !== false && (
        <ResourceButton
          action="create"
          type={resourceButtonType}
          resource={resource}
          {...create}
        />
      )}
    </Space>
  );
};
