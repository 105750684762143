import { gql } from "@apollo/client";

import { ActivateButton, EventV2ApplicationViewButton } from "../../components/actions";
import {
  AnnounceWinnersButton,
  DownloadEventV2ApplicationButton,
  DownloadEventV2AttendanceButton,
  DownloadEventV2WinnerButton,
  ExcludeWinnersButton,
  SelectAbsentButton,
  SelectAttendanceButton,
  SelectPenaltyButton,
  SelectWinnersButton,
  SelectWinnersByCustomButton,
} from "../../components/actions";
import { DetailPage } from "../../components/pages";
import { Can } from "../../components/permission";
import {
  EventV2ApplicationListWidget,
  EventV2AttendanceListWidget,
  EventV2DescriptionWidget,
  EventV2NoticeListWidget,
  EventV2WinnerListWidget,
  EventV2WinnerQuestionAnswerListWidget,
} from "../../components/widgets";

export const EventV2Detail = () => {
  return (
    <DetailPage
      resource="eventV2"
      fields={[
        {
          name: "id",
        },
        {
          name: "group",
          type: "object",
          resource: "group",
        },
        {
          name: "category",
          type: "choice",
          enumType: "EventV2Category",
        },
        {
          name: "drawCategory",
          type: "choice",
          enumType: "EventV2DrawCategory",
        },
        {
          name: "location",
        },
        {
          name: "eventAt",
          type: "datetime",
        },
        {
          name: "applyStatus",
          type: "choice",
          enumType: "EventV2ApplyStatus",
        },
        {
          name: "applicationOpenAt",
          type: "datetime",
        },
        {
          name: "applicationCloseAt",
          type: "datetime",
        },
        {
          name: "submissionCategory",
          type: "choice",
          enumType: "EventV2SubmissionCategory",
        },
        {
          name: "isPremiumOnly",
          type: "boolean",
        },
        {
          name: "winnerCount",
          type: "integer",
        },
        {
          name: "waitlistCount",
          type: "integer",
        },
        {
          name: "restrictions",
          type: "restrictions",
        },
        {
          name: "winnerAnnouncedAt",
          type: "datetime",
        },
        ...DetailPage.fields.canActivate,
        ...DetailPage.fields.tracked,
      ]}
      query={QUERY}
      extraActions={({ obj, refetch, resource }) => {
        return (
          <>
            <Can
              action={obj.isActive ? "inactivate" : "activate"}
              targetType={resource.typeName}
              targetId={obj.id}
            >
              <ActivateButton
                targetType={resource.typeName}
                targetId={obj.id}
                value={obj.isActive}
                onChange={() => refetch()}
              ></ActivateButton>
            </Can>
          </>
        );
      }}
      tabs={[
        {
          path: "",
          labelKey: "admin.resource.eventV2.plural",
          children: ({ obj }) => (
            <EventV2DescriptionWidget createSearchParams={{ eventV2: obj.id }} />
          ),
        },
        {
          path: "application",
          labelKey: "admin.resource.eventV2Application.plural",
          children: ({ obj }) => (
            <EventV2ApplicationListWidget
              baseFilter={{
                event_Overlap: [obj.id],
                isActive_Exact: true,
              }}
              defaultSort={[
                { type: "CREATED_AT", direction: "ASC" },
                { type: "ID", direction: "ASC" },
              ]}
              createSearchParams={{ eventV2: obj.id }}
              hideList
              hideCreate
              hideDetail
              isRowSelectable={
                obj.drawCategory === "RANDOM" || obj.submissionCategory === "SURVEY"
              }
              extraRowActions={({ obj }) => {
                if (obj.event.submissionCategory === "SURVEY") {
                  return (
                    <Can action="read" targetType="EVENT_V2_APPLICATION">
                      <EventV2ApplicationViewButton
                        applicationId={obj.id}
                        hideWinners={true}
                      />
                    </Can>
                  );
                }
              }}
              extraActions={({ selectedRowKeys, refetch, filter, sort }) => (
                <>
                  <Can action="download" targetType="EVENT_V2">
                    <DownloadEventV2ApplicationButton
                      id={obj.id}
                      filter={filter}
                      sort={sort}
                      selectedRowKeys={selectedRowKeys}
                      disabled={obj.applyStatus !== "CLOSED"}
                    />
                  </Can>
                  <Can action="update_winners" targetType="EVENT_V2">
                    <SelectWinnersButton
                      eventId={obj.id}
                      category={obj.drawCategory}
                      disabled={obj.applyStatus !== "CLOSED" || obj.isWinnerAnnounced}
                      onChange={() => refetch()}
                    />
                  </Can>
                  <Can action="update_winners" targetType="EVENT_V2">
                    <SelectWinnersByCustomButton
                      eventId={obj.id}
                      selectedRowKeys={selectedRowKeys}
                      disabled={selectedRowKeys.length === 0 || obj.isWinnerAnnounced}
                      hidden={obj.submissionCategory !== "SURVEY"}
                      onChange={() => refetch()}
                    />
                  </Can>
                </>
              )}
            />
          ),
        },
        {
          path: "winner",
          labelKey: "admin.resource.eventV2Winner.plural",
          children: ({ obj }) => {
            return (
              <EventV2WinnerListWidget
                baseFilter={{
                  event_Overlap: [obj.id],
                  state_DoesNotContain: ["EXCLUDED"],
                }}
                defaultSort={[{ type: "ORDER", direction: "ASC" }]}
                createSearchParams={{ eventV2: obj.id }}
                hideList
                hideCreate
                hideDetail
                isRowSelectable={true}
                extraRowActions={({ obj }) => {
                  if (obj.event.submissionCategory === "SURVEY") {
                    return (
                      <Can action="read" targetType="EVENT_V2_APPLICATION">
                        <EventV2ApplicationViewButton
                          applicationId={obj.application.id}
                          hideWinners={obj.event.category !== "ONLINE"}
                        />
                      </Can>
                    );
                  }
                }}
                extraActions={({ selectedRowKeys, filter, sort }) => (
                  <>
                    <Can action="download" targetType="EVENT_V2">
                      <DownloadEventV2WinnerButton
                        id={obj.id}
                        filter={filter}
                        sort={sort}
                        selectedRowKeys={selectedRowKeys}
                        disabled={obj.applyStatus !== "CLOSED"}
                      />
                    </Can>
                    <Can action="update_winners" targetType="EVENT_V2">
                      <AnnounceWinnersButton
                        eventId={obj.id}
                        disabled={!obj.isWinnerSelected}
                      />
                    </Can>
                    <Can action="update_winners" targetType="EVENT_V2">
                      <ExcludeWinnersButton
                        eventId={obj.id}
                        selectedRowKeys={selectedRowKeys}
                        disabled={selectedRowKeys.length === 0 || obj.isWinnerAnnounced}
                      />
                    </Can>
                  </>
                )}
              />
            );
          },
        },
        {
          path: "winnerQuestionAnswer",
          labelKey: "admin.resource.eventV2WinnerQuestionAnswer.plural",
          hidden: ({ obj }) => !obj?.winnerQuestionSet,
          children: ({ obj }) => {
            return (
              <EventV2WinnerQuestionAnswerListWidget
                object={obj}
                baseFilter={{
                  event_Overlap: [obj.id],
                  state_DoesNotContain: ["EXCLUDED"],
                }}
                defaultSort={[{ type: "ORDER", direction: "ASC" }]}
                createSearchParams={{ eventV2: obj.id }}
                hideList
                hideCreate
                hideDetail
                isRowSelectable={true}
                extraRowActions={({ obj }) => {
                  if (obj.event.submissionCategory === "SURVEY") {
                    return (
                      <Can action="read" targetType="EVENT_V2_APPLICATION">
                        <EventV2ApplicationViewButton
                          applicationId={obj.application.id}
                        />
                      </Can>
                    );
                  }
                }}
                extraActions={({ selectedRowKeys, filter, sort }) => (
                  <>
                    <Can action="download" targetType="EVENT_V2">
                      <DownloadEventV2WinnerButton
                        id={obj.id}
                        filter={filter}
                        sort={sort}
                        selectedRowKeys={selectedRowKeys}
                        disabled={obj.applyStatus !== "CLOSED"}
                      />
                    </Can>
                  </>
                )}
              />
            );
          },
        },
        {
          path: "attendance",
          labelKey: "admin.resource.eventV2Attendance.plural",
          hidden: ({ obj }) => obj.category !== "OFFLINE",
          children: ({ obj }) => {
            return (
              <EventV2AttendanceListWidget
                baseFilter={{
                  event_Overlap: [obj.id],
                  state_DoesNotContain: ["EXCLUDED"],
                }}
                defaultSort={[{ type: "ORDER", direction: "ASC" }]}
                createSearchParams={{ eventV2: obj.id }}
                hideList
                hideCreate
                hideDetail
                isRowSelectable={true}
                extraActions={({ selectedRowKeys, refetch, filter, sort }) => (
                  <>
                    <Can action="download" targetType="EVENT_V2">
                      <DownloadEventV2AttendanceButton
                        id={obj.id}
                        filter={filter}
                        sort={sort}
                        selectedRowKeys={selectedRowKeys}
                        disabled={obj.applyStatus !== "CLOSED"}
                      />
                    </Can>
                    <Can action="update_winners" targetType="EVENT_V2">
                      <SelectAttendanceButton
                        selectedRowKeys={selectedRowKeys}
                        disabled={selectedRowKeys.length === 0}
                        eventId={obj.id}
                        onChange={() => refetch()}
                        onlyIcon
                      />
                    </Can>
                    <Can action="update_winners" targetType="EVENT_V2">
                      <SelectAbsentButton
                        selectedRowKeys={selectedRowKeys}
                        disabled={selectedRowKeys.length === 0}
                        eventId={obj.id}
                        onChange={() => refetch()}
                        onlyIcon
                      />
                    </Can>
                    <Can action="update_winners" targetType="EVENT_V2">
                      <SelectPenaltyButton
                        selectedRowKeys={selectedRowKeys}
                        disabled={selectedRowKeys.length === 0}
                        eventId={obj.id}
                        onChange={() => refetch()}
                        onlyIcon
                      />
                    </Can>
                  </>
                )}
              />
            );
          },
        },
        {
          path: "notice",
          labelKey: "admin.resource.eventV2Notice.plural",
          hidden: ({ obj }) => obj.category !== "OFFLINE",
          children: ({ obj }) => {
            return (
              <EventV2NoticeListWidget
                baseFilter={{
                  event_Overlap: [obj.id],
                }}
                createSearchParams={{ eventV2: obj.id }}
                hiddenFields={["eventV2"]}
                hideCreate={!obj.isWinnerAnnounced}
              />
            );
          },
        },
      ]}
    />
  );
};
// );

// ==

const QUERY = gql`
  query eventV2ForEventV2Detail($id: ID!) {
    eventV2(filter: { id_Overlap: [$id] }) {
      id
      group {
        name
      }
      title
      body
      category
      drawCategory
      location
      eventAt
      applyStatus
      applicationOpenAt
      applicationCloseAt
      submissionCategory
      isPremiumOnly
      isWinnerAnnounced
      isWinnerSelected
      winnerCount
      waitlistCount
      winnerAnnouncedAt
      status
      restrictions {
        id
        count
        category
      }
      winnerQuestionSet {
        answerableUntil
        questions {
          id
          title
        }
      }

      ...TrackedFields
      ...CanActivateFields
    }
  }
  ${DetailPage.fragments.tracked}
  ${DetailPage.fragments.canActivate}
`;
