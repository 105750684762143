import { useTranslation } from "react-i18next";

import { gql } from "@apollo/client";

import { SelectPenaltyActivateButton } from "../../components/actions";
import { ListPage, ListPageWithGroup } from "../../components/pages";
import { Can } from "../../components/permission";
import { getSafeValue } from "../../utils/helpers";

export const EventV2PenaltyList = () => {
  const { t } = useTranslation();

  return (
    <ListPageWithGroup
      groupFilterKey="event_Group_Overlap"
      resource="eventV2Penalty"
      subtitleKey="admin.page.eventV2Penalty.subtitle"
      fields={[
        {
          name: "id",
        },
        {
          name: "event",
          type: "object",
          resource: "eventV2",
          filterKey: "event_Overlap",
        },
        {
          name: "member",
          labelKey: "admin.field.nickname",
          type: "object",
          filterKey: "member_Overlap",
          resource: "member",
        },
        {
          name: "name",
          labelKey: "admin.field.name",
          filterKey: "name_Text",
          getter: (obj) =>
            getSafeValue(obj, "name", getSafeValue(obj, "member.user.name", "-")),
        },
        {
          name: "birthDate",
          labelKey: "admin.field.birthDate",
          type: "date",
        },
        {
          name: "premiumMembershipIdentifier",
          labelKey: "admin.field.membershipIdentifier",
          filterKey: "premiumMembershipIdentifier_Text",
          getter: (obj) => obj.premiumMembershipIdentifier ?? "-",
        },
        {
          name: "count",
          type: "integer",
          labelKey: "admin.field.penaltyCount",
          filterKey: "count_Range",
        },
        {
          name: "category",
          type: "object",
          resource: "eventV2PenaltyCategory",
          filterKey: "category_Overlap",
        },
        {
          name: "memo",
          type: "string",
          labelKey: "admin.field.memo",
          truncate: true,
        },
        {
          name: "member.status",
          type: "choice",
          labelKey: "admin.field.memberStatus",
          enumType: "MemberStatus",
        },
        {
          name: "isActive",
          labelKey: "admin.field.penaltyIsActive",
          filterKey: "isActive_Exact",
          type: "boolean",
        },
        ...ListPage.fields.tracked,
      ]}
      query={QUERY}
      defaultSort={[
        {
          type: "CREATED_AT",
          direction: "DESC",
        },
      ]}
      size={50}
      isRowSelectable={true}
      getCheckboxProps={(record) => ({
        disabled: record?.member?.status !== "ACTIVE",
      })}
      extraRowActions={ListPage.extraRowActions.canActivate}
      extraActions={({ refetch, selectedRowKeys, setSelectedRowKeys }) => {
        const penaltyIds = selectedRowKeys.map((item) => parseInt(item, 10));
        return (
          <Can action="update" targetType="EVENT_PENALTY">
            <SelectPenaltyActivateButton
              isActive={true}
              buttonText={t("admin.component.activateButton.activate")}
              penaltyIds={penaltyIds}
              setSelectedRowKeys={setSelectedRowKeys}
              refetch={refetch}
            />
            <SelectPenaltyActivateButton
              isActive={false}
              buttonText={t("admin.component.activateButton.inactivate")}
              penaltyIds={penaltyIds}
              setSelectedRowKeys={setSelectedRowKeys}
              refetch={refetch}
            />
          </Can>
        );
      }}
    />
  );
};

// ==

const QUERY = gql`
  query eventV2PenaltiesForEventV2PenaltyList(
    $filter: EventV2PenaltyFilterInput
    $sort: [EventV2PenaltySortInput]
    $page: PageInput
  ) {
    eventV2Penalties(filter: $filter, sort: $sort, page: $page) {
      objects {
        id
        member {
          id
          nickname
          status
          user {
            id
            name
            phone
            email
          }
        }
        category {
          id
          name
        }
        event {
          id
          title
        }
        memo
        count
        isActive
        name
        birthDate
        premiumMembershipIdentifier
        ...TrackedFields
        ...CanActivateFields
      }
      ...ListFields
    }
  }
  ${ListPage.fragments.list}
  ${ListPage.fragments.tracked}
  ${ListPage.fragments.canActivate}
`;
